import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { knowledgebaseService } from "app/services";
import moment from "moment";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import { IKnowledgebase } from "../../services/knowledgebase.service";
import KnowledgebaseStore from "./store";
import ShowKnowledgebase from "./show";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "../../store/ducks/setting.duck";
import { usePermissions } from "app/hooks/useRole";
import KnowledgebaseCategoryFilter from "./filter";
import EditIcon from "../../partials/icons/EditIcon";
import { useHistory } from "react-router-dom";

const Knowledgebase = () => {
  const intl = useIntl();
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const permissions = usePermissions();
  const history = useHistory();
  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<IKnowledgebase>({
      fetch: knowledgebaseService.getAll,
      delete: knowledgebaseService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return params.row?.title;
      },
      minWidth: 450,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 300,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ARTICLES" }),
      field: "articles",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => showKnowledgebaseItems(params.row?._id)}
            data-cy-class={"go_to_categories"}
            data-cy-id={params.row?._id}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M1 9.26448V17.4545C1.00303 19.9636 3.03634 21.9969 5.54547 22H18.2728C20.7819 21.9969 22.8152 19.9636 22.8182 17.4545V9.16357L1 9.26448Z"
                fill="#0D99FF"
              />
              <path
                d="M18.2727 3.81815H12.3382C12.1976 3.81909 12.0586 3.78798 11.9318 3.72726L9.06272 2.28726C8.68397 2.09865 8.26674 2.00034 7.84363 2H5.54543C3.03634 2.00298 1.00303 4.03629 1 6.54543V7.44633L22.6936 7.34542C22.2199 5.28441 20.3875 3.82237 18.2727 3.81815Z"
                fill="#0D99FF"
              />
            </svg>
          </IconButton>
        );
      },
      minWidth: 160,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"knowledgebase"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"knowledgebase_show"}
                data-cy-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"knowledgebase"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"knowledgebase_delete"}
                data-cy-id={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [knowledgebaseId, setKnowledgebaseId] = useState<string | null>(null);
  const show = (_id: string) => {
    setKnowledgebaseId(_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const showKnowledgebaseItems = (_id: string) => {
    const url = `/knowledgebase/${_id}/items`;
    history.push(url);
  };

  return (
    <>
      <PermissionsGate section={"knowledgebase"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete knowledge base?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this knowledge base?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"knowledgebase"} scope={"read"}>
        {knowledgebaseId ? (
          <ShowKnowledgebase
            _id={knowledgebaseId}
            open={Boolean(knowledgebaseId)}
            setDialogue={() => setKnowledgebaseId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"knowledgebase"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions &&
              hasPermission(permissions, "knowledgebase", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <KnowledgebaseStore
                        onDone={refresh}
                        open={props.open}
                        setDialogue={props.close}
                      />
                    );
                  }
                : undefined,
            filters: (props: { show: boolean }) => {
              return (
                <KnowledgebaseCategoryFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Knowledgebase);
