import React, { useState, FC, useEffect } from "react";
import { Box, Grid, Typography, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { ITodo } from "../services/todo.service";
import { Calendar } from "antd";
import type { Moment } from "moment";

interface IProps {
  todos: Array<ITodo>;
  onDateChange: Function;
  onRangeChange: Function;
}

export interface IDot {
  id: string;
  title: string;
  start: Date;
  end: Date;
  color: string;
  description: string;
}

const useStyles = makeStyles(() => ({
  day: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& button": {
      width: 20,
      height: 20,
    },
  },
  dotsContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: "-6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    marginTop: "2px",
  },
  dayWithDot: {
    width: "4px",
    height: "4px",
    borderRadius: "100%",
  },
  today: {
    borderRadius: 8,
    color: "#ffffff",
    backgroundColor: "#556EE6",
    "&:hover": {
      backgroundColor: "#556EE6",
    },
  },
  ant_calendar: {
    height: "100%",
    "& .ant-picker-calendar": {
      height: "100%",
      "& .ant-picker-panel": {
        height: "100%",
        borderTop: "none!important",
        "& .ant-picker-date-panel": {
          height: "100%",
          "& .ant-picker-body": {
            "& .ant-picker-content": {
              height: "100%",
              "& thead": {
                "& tr": {
                  "& th": {
                    fontWeight: "500!important",
                    fontStyle: "normal",
                  },
                },
              },
              "& tbody": {
                "& tr": {
                  "& .ant-picker-cell-selected": {
                    "& .ant-picker-cell-inner": {
                      backgroundColor: "#0D99FF",
                      borderRadius: "8px",
                      "&:before": {
                        border: "none",
                      },
                    },
                  },
                  "& .ant-picker-cell": {
                    "& .ant-picker-cell-inner": {
                      "& .ant-picker-calendar-date-value": {
                        display: "none!important",
                      },
                    },
                    fontWeight: "500!important",
                    fontStyle: "normal",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}));

const StaticDatePicker: FC<IProps> = (props) => {
  const classes = useStyles();
  const [_dots, _setDots] = useState<Array<IDot>>([]);
  const [month, setMonth] = useState<number>(
    +moment(new Date()).format("MM") - 1
  );
  const [year, setYear] = useState<number>(+moment(new Date()).format("YYYY"));

  useEffect(() => {
    handlePanelChange(moment());
  }, []);

  /**
   * https://dev.to/lico/antd-calendar-get-the-first-date-and-the-last-date-on-the-panel-4p54
   * @param date
   */
  const getFirstDateAndLastDateOnThePanel = (date: Moment) => {
    const firstDate = moment(date).startOf("month"),
      lastDate = moment(date).endOf("month"),
      firstDateDay = firstDate.day();

    firstDate.subtract(firstDateDay, "days");
    //we added two days for utc problem
    lastDate.add(42 - Number(lastDate.format("DD")) - firstDateDay, "days");

    return {
      firstDate,
      lastDate,
    };
  };

  const handlePanelChange = (
    date: Moment
  ): { firstDate: string; lastDate: string } => {
    const { firstDate, lastDate } = getFirstDateAndLastDateOnThePanel(date);

    props.onRangeChange(
      firstDate.format("YYYY-MM-DD"),
      lastDate.format("YYYY-MM-DD")
    );

    return {
      firstDate: firstDate.format("YYYY-MM-DD"),
      lastDate: lastDate.format("YYYY-MM-DD"),
    };
  };

  return (
    <Box
      className={`site-calendar-customize-header-wrapper ${classes.ant_calendar}`}
    >
      <Calendar
        onPanelChange={handlePanelChange}
        onSelect={(e) => {
          props.onDateChange(e);
        }}
        dateCellRender={(value) => {
          return (
            <>
              {moment(value).format("D")}

              <Box className={classes.dotsContainer}>
                {props.todos.map((todo) => {
                  return moment.unix(todo.date).local().format("DD/MM/YYYY") ===
                    moment(value).format("DD/MM/YYYY") ||
                    moment
                      .unix(todo.deadline_date)
                      .local()
                      .format("DD/MM/YYYY") ===
                      moment(value).format("DD/MM/YYYY") ? (
                    <Box
                      sx={{
                        backgroundColor: `${
                          todo.category && todo.category?.color?.length > 1
                            ? todo.category.color
                            : "#000"
                        }`,
                      }}
                      className={classes.dayWithDot}
                    />
                  ) : (
                    <></>
                  );
                })}
              </Box>
            </>
          );
        }}
        fullscreen={false}
        headerRender={({ value, onChange }) => {
          return (
            <div
              style={{
                padding: "0 8px",
              }}
            >
              <Grid container>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                  <IconButton
                    //previous month
                    sx={{
                      borderRadius: 1,
                      border: "1px solid #E6E6E6",
                      mt: 1,
                    }}
                    onClick={() => {
                      let newValue = value.clone();

                      if (value.month() === 0) {
                        setYear(value.year() - 1);
                        setMonth(11);
                      } else {
                        setMonth(month - 1);
                      }

                      onChange(newValue.month(month - 1));
                    }}
                  >
                    <svg
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5 0.625994C5.49985 0.791887 5.43303 0.950932 5.31422 1.06816L2.07486 4.26846C1.97681 4.36531 1.89903 4.48028 1.84597 4.60682C1.7929 4.73337 1.76559 4.869 1.76559 5.00597C1.76559 5.14294 1.7929 5.27857 1.84597 5.40511C1.89903 5.53166 1.97681 5.64663 2.07486 5.74347L5.31 8.9396C5.42537 9.05761 5.4892 9.21567 5.48776 9.37973C5.48632 9.54379 5.41971 9.70072 5.30228 9.81673C5.18485 9.93274 5.026 9.99855 4.85994 9.99998C4.69388 10.0014 4.53389 9.93833 4.41444 9.82436L1.1793 6.63073C0.744307 6.20013 0.5 5.61662 0.5 5.00826C0.5 4.3999 0.744307 3.81639 1.1793 3.3858L4.41867 0.183409C4.50724 0.0958441 4.62012 0.0362053 4.74301 0.0120387C4.86591 -0.012128 4.9933 0.000263628 5.10906 0.0476455C5.22483 0.0950275 5.32376 0.17527 5.39335 0.27822C5.46294 0.38117 5.50006 0.502199 5.5 0.625994Z"
                        fill="#2A3042"
                      />
                    </svg>
                  </IconButton>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography variant={"h6"}>
                      {moment().month(month).format("MMMM")}
                    </Typography>
                    <Typography variant={"body2"}>{year}</Typography>
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                      //next month
                      onClick={() => {
                        let newValue = value.clone();

                        if (value.month() === 11) {
                          setYear(value.year() + 1);
                          setMonth(0);
                        } else {
                          setMonth(month + 1);
                        }

                        onChange(newValue.month(month + 1));
                      }}
                      sx={{
                        borderRadius: 1,
                        border: "1px solid #E6E6E6",
                        mt: 1,
                      }}
                    >
                      <svg
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.5 0.625994C0.500148 0.791887 0.566972 0.950932 0.685781 1.06816L3.92514 4.26846C4.02319 4.36531 4.10097 4.48028 4.15403 4.60682C4.2071 4.73337 4.23441 4.869 4.23441 5.00597C4.23441 5.14294 4.2071 5.27857 4.15403 5.40511C4.10097 5.53166 4.02319 5.64663 3.92514 5.74347L0.690004 8.9396C0.574635 9.05761 0.510797 9.21567 0.512239 9.37973C0.513682 9.54379 0.580291 9.70072 0.697719 9.81673C0.815146 9.93274 0.973998 9.99855 1.14006 9.99998C1.30612 10.0014 1.46611 9.93833 1.58556 9.82436L4.8207 6.63073C5.25569 6.20013 5.5 5.61662 5.5 5.00826C5.5 4.3999 5.25569 3.81639 4.8207 3.3858L1.58133 0.183409C1.49276 0.0958441 1.37988 0.0362053 1.25699 0.0120387C1.13409 -0.012128 1.0067 0.000263628 0.890937 0.0476455C0.775172 0.0950275 0.676236 0.17527 0.606647 0.27822C0.537057 0.38117 0.499943 0.502199 0.5 0.625994Z"
                          fill="#2A3042"
                        />
                      </svg>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </div>
          );
        }}
      />
    </Box>
  );
};

export default StaticDatePicker;
