import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as i18n from "./ducks/i18n.duck";
import * as user from "./ducks/user.duck";
import * as setting from "./ducks/setting.duck";
import * as chat from "./ducks/chat.duck";
import * as socket from "./ducks/socket.duck";
import * as permission from "./ducks/permission.duck";
import * as notification from "./ducks/notification.duck";

import { metronic } from "_metronic";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: i18n.reducer,
  builder: metronic.builder.reducer,
  user: user.reducer,
  settingReducer: setting.reducer,
  chat: chat.reducer,
  socket: socket.reducer,
  permission: permission.reducer,
  notification: notification.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    setting.saga(),
    chat.saga(),
    permission.saga(),
    notification.saga(),
    i18n.saga(),
  ]);
}
