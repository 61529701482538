import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";

//TODO convert it to typescript https://react-redux.js.org/using-react-redux/usage-with-typescript

import { rootReducer, rootSaga } from "./rootDuck";
import { IAuthState } from "./ducks/auth.duck";
import { IRole } from "../interfaces";
import { ISettingsState } from "./ducks/setting.duck";
import { INotificationState } from "./ducks/notification.duck";
import { Ii18nState } from "./ducks/i18n.duck";
import { IUserState } from "./ducks/user.duck";
import { IChatState } from "./ducks/chat.duck";
import { ISocketState } from "./ducks/socket.duck";

const composeEnhancers = compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// export type RootState = ReturnType<typeof store.getState>
export type RootState = {
  auth: IAuthState;
  i18n: Ii18nState; // TODO move to ts
  builder: any; // TODO move to ts
  user: IUserState;
  settingReducer: ISettingsState;
  chat: IChatState;
  socket: ISocketState;
  triggerRoomsUpdate: boolean;
  permission: {
    roles: Array<IRole>;
    roles_pending: boolean;
  }; // TODO move to ts
  notification: INotificationState;
};
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
