import React, { useEffect, useMemo, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { taskService } from "app/services";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import EditIcon from "../../partials/icons/EditIcon";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import DeleteIcon from "app/partials/icons/DeleteIcon";
import { usePermissions } from "../../hooks/useRole";
import { ITask } from "../../services/task.service";
import { IUrlQuery, IUser } from "../../interfaces";
import UserAvatar from "../../partials/layout/UserAvatar";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import TaskShow from "./show";
import TaskStore from "./store";
import QuickCards from "./components/quickCards";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  ant: {
    "& .ant-progress-inner": {
      width: "40px!important",
      height: "40px!important",
      fontSize: "12px!important",
    },
  },
}));

const Tasks = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  let { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const projectId = query.get("project");
  const id = query.get("id");
  const history = useHistory();

  let params: IUrlQuery[] = [
    {
      name: "per_page",
      value: 5,
    },
  ];

  let projectParams: IUrlQuery | undefined = undefined;
  if (projectId) {
    projectParams = { name: "project", value: projectId };
    params.push(projectParams);
  }

  const { data, xhrLoading, updateQuery, refresh, remove, queries, setQuery } =
    useTable<ITask>({
      fetch: taskService.getAll,
      delete: taskService.delete,
      query: params,
    });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const [task, setTask] = useState<string | null>(id);
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.title}>
            <Typography variant="inherit">
              {params.row?.title.length > 10
                ? params.row?.title.substring(0, 10) + "..."
                : params.row?.title}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 180,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.PROJECT",
        defaultMessage: "Project",
      }),
      field: "project",
      renderCell: (params) => {
        return params.row?.project?.title;
      },
      minWidth: 20,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
      field: "status",
      renderCell: (params) => {
        return (
          <Typography
            variant={"body1"}
            sx={{ display: "flex", gap: "5px", alignItems: "center" }}
          >
            <i
              style={{ fontSize: "10px", color: params.row?.status?.color }}
              className={`fa fa-circle `}
            />

            {params.row?.status?.label}
          </Typography>
        );
      },
      minWidth: 120,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.DATE",
      }),
      field: "date",
      renderCell: (params) => {
        return (
          <div className="kt-timeline-v2 todo-timeline ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-success" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {params.row?.start
                    ? moment
                        .unix(params.row?.start)
                        .local()
                        .format("DD/MM/YYYY HH:mm")
                    : "No data"}
                </div>
              </div>
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-danger todo-timeline-circle-icon" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {params.row?.end
                    ? moment
                        .unix(params.row?.end)
                        .local()
                        .format("DD/MM/YYYY HH:mm")
                    : "No data"}
                </div>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 180,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.USERS" }),
      field: "users",
      renderCell: (params) => {
        return params.row.users?.map((user: IUser) => {
          if (!user) return;
          return <UserAvatar user={user} />;
        });
      },
      minWidth: 180,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.PRIORITY",
        defaultMessage: "Priority",
      }),
      field: "priority",
      renderCell: (params) => {
        const priority = params.row?.priority;
        let color: string;
        let title: string;

        if (priority > 0 && priority < 5) {
          color = "rgb(138, 94, 217)";
          title = "Low";
        } else if (priority >= 5 && priority < 8) {
          color = "rgb(253, 215, 77)";
          title = "Medium";
        } else if (priority >= 8 && priority <= 10) {
          color = "rgb(245, 54, 92)";
          title = "High";
        } else {
          color = "#035189";
          title = "No priority";
        }

        return priority ? (
          <Chip
            label={
              <>
                <Typography
                  sx={{
                    backgroundColor: "#fff",
                    color: "#000",
                    borderRadius: "50%",
                    width: 20,
                    height: 20,
                    opacity: "0.7",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  variant={"body1"}
                >
                  {priority}
                </Typography>
                <Typography variant={"body1"}>{title}</Typography>
              </>
            }
            sx={{
              backgroundColor: color,
              color: "#ffffff",
              ".MuiChip-label": {
                display: "flex",
                gap: "5px",
                paddingLeft: "10px",
                paddingRight: "10px",
              },
            }}
          />
        ) : (
          intl.formatMessage({
            id: "STANDARD.NO_PRIORITY",
            defaultMessage: "No priority",
          })
        );
      },
      minWidth: 125,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"tasks"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"tasks"} scope={"delete"}>
              <IconButton onClick={() => handleClick(params.row?._id)}>
                <DeleteIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (id: string) => {
    setTask(id);
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete task?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this task?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      <PermissionsGate section={"tasks"} scope={"read"}>
        {task ? (
          <TaskShow
            _id={task}
            open={Boolean(task)}
            onDone={refresh}
            setDialogue={() => {
              setTask(null);
              query.delete("id");
              history.replace({
                search: query.toString(),
              });
            }}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"tasks"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "tasks", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <TaskStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,

            customQuickCards: (
              <QuickCards
                queries={queries}
                setQuery={setQuery}
                params={projectParams}
              />
            ),
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default Tasks;
