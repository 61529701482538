import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { Chip, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { robotService } from "app/services";
import moment from "moment";
import { useTable } from "app/hooks/useTable";
import { GridTable } from "app/partials/layout/GridTable";
import { useIntl } from "react-intl";
import { hasPermission, PermissionsGate } from "app/permission/PermissionsGate";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { usePermissions } from "app/hooks/useRole";
import TodoFilter from "app/pages/Todos/filter";
import EditIcon from "app/partials/icons/EditIcon";
import RobotStore from "./store";
import { IRobot } from "../../../services/robot.service";
import ShowRobot from "./show";
import { IUser } from "../../../interfaces";
import { useHistory } from "react-router-dom";
import UserAvatar from "../../../partials/layout/UserAvatar";

const Robots = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<IRobot>({
      fetch: robotService.getAll,
      delete: robotService.delete,
    });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.USERS" }),
      field: "users",
      renderCell: (params) => {
        return params.row.users?.map((user: IUser) => {
          if (!user) return;
          return <UserAvatar user={user} />;
        });
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.title}>
            <Typography variant={"inherit"}>
              {params.row?.title.length > 16
                ? params.row?.title?.substring(0, 16) + ".."
                : params.row?.title}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      renderCell: (params) => {
        return (
          <div className="kt-timeline-v2 todo-timeline ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-success" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {moment
                    .unix(params.row?.start)
                    .local()
                    .format("DD/MM/YYYY HH:mm")}
                </div>
              </div>
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-danger todo-timeline-circle-icon" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {moment
                    .unix(params.row?.end)
                    .local()
                    .format("DD/MM/YYYY HH:mm")}
                </div>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 180,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TYPE" }),
      field: "type",
      renderCell: (params) => {
        return (
          <Chip
            label={
              params.row?.type?.title && params.row?.type?.title[intl.locale]
            }
            sx={{
              bgcolor: "primary.main",
              color: "#fff",
              height: 28,
              width: 66,
              borderRadius: "8px",
            }}
          />
        );
      },
      minWidth: 120,
    },
    {
      field: "category",
      headerName: intl.formatMessage({ id: "STANDARD.CATEGORY" }),
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.category?.title[intl.locale]}>
            <Typography variant={"inherit"}>
              {params.row?.category?.title[intl.locale].length > 16
                ? params.row?.category?.title[intl.locale]?.substring(0, 16) +
                  ".."
                : params.row?.category?.title[intl.locale]}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 170,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"todos"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"todo_show"}
                data-cy={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"todos"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"todo_remove"}
                data-cy={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [robotId, setRobotId] = useState<string | null>(null);

  const show = (_id: string) => {
    setRobotId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"todos"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete robot?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this robot?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"todos"} scope={"read"}>
        {robotId ? (
          <ShowRobot
            _id={robotId}
            open={Boolean(robotId)}
            setDialogue={() => setRobotId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"todos"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "todos", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <RobotStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            filters: (props: { show: boolean }) => {
              return (
                <TodoFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
        />
      </PermissionsGate>
    </>
  );
};

export default Robots;
