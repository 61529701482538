export default {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      {
        name: "home",
        title: "MENU.CONTROLPANEL",
        translate: "MENU.CONTROLPANEL",
        root: true,
        icon: "fi-sr-home",
        page: "",
        bullet: "dot",
      },
      {
        name: "calendar",
        title: "MENU.CALENDAR",
        translate: "MENU.CALENDAR",
        root: true,
        icon: "fi-sr-calendar-lines",
        page: "calendar",
        bullet: "dot",
      },
      {
        name: "cases",
        title: "MENU.CASES",
        translate: "MENU.CASES",
        icon: "fi-sr-suitcase-alt",
        bullet: "dot",
        submenu: [
          {
            title: "MENU.OVERVIEW",
            translate: "MENU.OVERVIEW",
            bullet: "line",
            page: "cases",
            name: "cases_overview",
          },
          {
            title: "MENU.CASE_DASHBOARD",
            translate: "MENU.CASE_DASHBOARD",
            bullet: "line",
            page: "cases/dashboard",
            name: "cases_dashboard",
          },
        ],
      },
      {
        name: "business",
        title: "Business",
        translate: "MENU.BUSINESS",
        root: true,
        icon: "fi-sr-business-time",
        page: "business",
        bullet: "dot",
      },
      {
        name: "projects",
        title: "Projects",
        translate: "MENU.PROJECTS",
        root: true,
        icon: "fi-sr-business-time",
        page: "projects",
        bullet: "dot",
      },
      // {
      //   name: "tasks",
      //   title: "Tasks",
      //   translate: "MENU.TASKS",
      //   root: true,
      //   icon: "fi-sr-business-time",
      //   page: "tasks",
      //   bullet: "dot",
      // },
      {
        name: "tasks",
        title: "MENU.TASKS",
        translate: "MENU.TASKS",
        icon: "fi-sr-suitcase-alt",
        bullet: "dot",
        submenu: [
          {
            title: "MENU.LIST",
            translate: "MENU.LIST",
            bullet: "line",
            page: "tasks",
            name: "tasks",
          },
          {
            title: "MENU.KANBAN",
            translate: "MENU.KANBAN",
            bullet: "line",
            page: "kanban",
            name: "kanban",
          },
        ],
      },
      {
        name: "accounting",
        title: "MENU.ACCOUNTING",
        translate: "MENU.ACCOUNTING",
        icon: "fi-sr-suitcase-alt",
        bullet: "dot",
        submenu: [
          {
            title: "MENU.OVERVIEW",
            translate: "MENU.OVERVIEW",
            bullet: "line",
            page: "accounting",
            name: "accounting_overview",
          },
          {
            title: "MENU.EXPENSES",
            translate: "MENU.EXPENSES",
            bullet: "line",
            page: "accounting/expenses",
            name: "accounting_expenses",
          },
          {
            title: "MENU.TEMPLATES",
            translate: "MENU.TEMPLATES",
            bullet: "line",
            page: "accounting/templates",
            name: "accounting_templates",
          },
          {
            title: "MENU.SUPPLIERS",
            translate: "MENU.SUPPLIERS",
            bullet: "line",
            page: "accounting/suppliers",
            name: "accounting_suppliers",
          },
          {
            title: "MENU.TAX_RATES",
            translate: "MENU.TAX_RATES",
            bullet: "line",
            page: "accounting/tax_rates",
            name: "accounting_tax_rates",
          },
        ],
      },

      {
        name: "settings",
        title: "MENU.SETTINGS",
        translate: "MENU.SETTINGS",
        icon: "fi-sr-settings",
        page: "settings",
        bullet: "dot",
      },
      {
        name: "todos",
        title: "MENU.TODOS",
        translate: "MENU.TODOS",
        icon: "fi-sr-list-check",
        bullet: "dot",
        submenu: [
          {
            title: "MENU.LIST",
            translate: "MENU.LIST",
            bullet: "line",
            page: "todos",
            name: "todos_list",
          },
          {
            title: "MENU.ROBOT",
            translate: "MENU.ROBOT",
            bullet: "line",
            page: "robots",
            name: "todos_robotos",
          },
        ],
      },
      {
        name: "notes",
        title: "MENU.NOTES",
        translate: "MENU.NOTES",
        root: true,
        icon: "fi-sr-edit",
        page: "folders",
        bullet: "dot",
      },
      // {
      //   name: 'partners',
      //   title: 'Partners',
      //   translate: 'MENU.PARTNERS',
      //   icon: 'fas fa-user-friends',
      //   bullet: 'dot',
      //   submenu: [
      //     {
      //       title: 'Overview',
      //       translate: 'MENU.OVERVIEW',
      //       bullet: 'line',
      //       page: 'partners',
      //     },
      //     {
      //       title: 'Social worker',
      //       translate: 'MENU.SOCIALWORKER',
      //       bullet: 'line',
      //       page: 'partner_users',
      //     },
      //   ],
      // },
      // {
      //   name: 'employees',
      //   title: 'Employees',
      //   translate: 'MENU.EMPLOYEES',
      //   icon: 'fas fa-user-tie',
      //   bullet: 'dot',
      //   submenu: [
      //     {
      //       title: 'Overview',
      //       translate: 'MENU.OVERVIEW',
      //       bullet: 'line',
      //       page: 'employees',
      //     },
      //     {
      //       title: 'Former employees',
      //       translate: 'MENU.FORMEREMPLOYEES',
      //       bullet: 'line',
      //       page: 'employees?former=1',
      //     },
      //   ],
      // },
      // {
      //   name: 'moderators',
      //   title: 'Admin & Moderators',
      //   translate: 'MENU.MODERATORS',
      //   root: true,
      //   icon: 'fas fa-user-cog',
      //   page: 'moderators',
      //   bullet: 'dot',
      // },
      {
        name: "users",
        title: "MENU.USERS",
        translate: "MENU.USERS",
        root: true,
        icon: "fi-sr-users-alt",
        page: "users",
        bullet: "dot",
      },
      {
        name: "communes",
        title: "PERMISSION.TITLE.COMMUNES",
        translate: "PERMISSION.TITLE.COMMUNES",
        root: true,
        icon: "fi-sr-badge-check",
        page: "communes",
        bullet: "dot",
      },
      {
        name: "vacancies",
        title: "MENU.VACANCIES",
        translate: "MENU.VACANCIES",
        root: true,
        icon: "fi-sr-address-book",
        page: "vacancies",
        bullet: "dot",
      },
      {
        name: "knowledgebase",
        title: "MENU.KNOWLEDGEBASE",
        translate: "MENU.KNOWLEDGEBASE",
        root: true,
        icon: "fi-sr-comment-info",
        page: "knowledgebase",
        bullet: "dot",
      },
      {
        name: "chat",
        title: "MENU.CHATS",
        translate: "MENU.CHATS",
        root: true,
        icon: "fi-sr-comment",
        page: "chats",
        bullet: "dot",
      },
      {
        name: "mailboxes",
        title: "MENU.MAILBOX",
        translate: "MENU.MAILBOX",
        root: true,
        icon: "fas fa-envelope",
        page: "mailboxes",
        bullet: "dot",
      },
      {
        name: "contract_templates",
        title: "MENU.CONTRACTS",
        translate: "MENU.CONTRACTS",
        root: true,
        icon: "fi-sr-document-signed",
        page: "contract-templates",
        bullet: "dot",
      },
      {
        name: "time_records",
        title: "MENU.TIMETRACKS",
        translate: "MENU.TIMETRACKS",
        root: true,
        icon: "fi-sr-time-fast",
        page: "timetracks",
        bullet: "dot",
      },
      {
        name: "surveys",
        title: "MENU.SURVEYS",
        translate: "MENU.SURVEYS",
        root: true,
        icon: "fi-sr-clipboard-list-check",
        page: "surveys",
        bullet: "dot",
      },
      {
        name: "offers",
        title: "MENU.OFFERS",
        translate: "MENU.OFFERS",
        icon: "fi-sr-document",
        bullet: "dot",
        submenu: [
          {
            title: "MENU.OVERVIEW",
            translate: "MENU.OVERVIEW",
            bullet: "line",
            page: "offers",
            name: "offers_overview",
          },
          {
            title: "MENU.INCOMING",
            translate: "MENU.INCOMING",
            bullet: "line",
            page: "incoming-offers",
            name: "incoming_offers",
          },
        ],
      },
      {
        name: "logs",
        title: "MENU.LOGS",
        translate: "MENU.LOGS",
        root: true,
        icon: "fi-sr-ballot",
        page: "logs",
        bullet: "dot",
      },
      {
        name: "reports",
        title: "MENU.REPORTS",
        translate: "MENU.REPORTS",
        root: true,
        icon: "fi-sr-file-chart-line",
        page: "reports",
        bullet: "dot",
      },
      {
        name: "guidelines",
        title: "MENU.GUIDELINES",
        translate: "MENU.GUIDELINES",
        root: true,
        icon: "fi-sr-book-bookmark",
        page: "guidelines",
        bullet: "dot",
      },
      {
        name: "values",
        title: "MENU.VALUES",
        translate: "MENU.VALUES",
        root: true,
        icon: "fi-sr-book-bookmark",
        page: "values",
        bullet: "dot",
      },
      {
        name: "exports",
        title: "MENU.EXPORTS",
        translate: "MENU.EXPORTS",
        root: true,
        icon: "fi-sr-inbox-out",
        page: "exports",
        bullet: "dot",
      },
    ],
  },
};
