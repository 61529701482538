import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.546 2.77498L7.38603 12.935C6.99798 13.3209 6.69035 13.78 6.48093 14.2857C6.27152 14.7913 6.16449 15.3335 6.16603 15.8808V17C6.16603 17.221 6.25383 17.433 6.41011 17.5892C6.56639 17.7455 6.77835 17.8333 6.99937 17.8333H8.11853C8.66583 17.8349 9.20801 17.7278 9.71366 17.5184C10.2193 17.309 10.6784 17.0014 11.0644 16.6133L21.2244 6.45331C21.7114 5.96512 21.9849 5.30371 21.9849 4.61415C21.9849 3.92458 21.7114 3.26317 21.2244 2.77498C20.7291 2.30156 20.0703 2.03735 19.3852 2.03735C18.7001 2.03735 18.0413 2.30156 17.546 2.77498ZM20.046 5.27498L9.88603 15.435C9.41614 15.902 8.78104 16.1649 8.11853 16.1666H7.8327V15.8808C7.83444 15.2183 8.09734 14.5832 8.56437 14.1133L18.7244 3.95331C18.9024 3.78327 19.139 3.68839 19.3852 3.68839C19.6314 3.68839 19.868 3.78327 20.046 3.95331C20.221 4.12874 20.3192 4.36639 20.3192 4.61415C20.3192 4.8619 20.221 5.09955 20.046 5.27498Z"
        fill="#0D99FF"
      />
      <path
        d="M21.1667 9.4825C20.9457 9.4825 20.7337 9.5703 20.5774 9.72658C20.4211 9.88286 20.3333 10.0948 20.3333 10.3158V14.5H17C16.337 14.5 15.7011 14.7634 15.2322 15.2322C14.7634 15.7011 14.5 16.337 14.5 17V20.3333H6.16667C5.50363 20.3333 4.86774 20.0699 4.3989 19.6011C3.93006 19.1323 3.66667 18.4964 3.66667 17.8333V6.16667C3.66667 5.50363 3.93006 4.86774 4.3989 4.3989C4.86774 3.93006 5.50363 3.66667 6.16667 3.66667H13.7017C13.9227 3.66667 14.1346 3.57887 14.2909 3.42259C14.4472 3.26631 14.535 3.05435 14.535 2.83333C14.535 2.61232 14.4472 2.40036 14.2909 2.24408C14.1346 2.0878 13.9227 2 13.7017 2H6.16667C5.062 2.00132 4.00297 2.44073 3.22185 3.22185C2.44074 4.00296 2.00132 5.062 2 6.16667L2 17.8333C2.00132 18.938 2.44074 19.997 3.22185 20.7782C4.00297 21.5593 5.062 21.9987 6.16667 22H15.6192C16.1666 22.0016 16.7089 21.8945 17.2147 21.6851C17.7205 21.4757 18.1797 21.1681 18.5658 20.78L20.7792 18.565C21.1673 18.1791 21.475 17.72 21.6846 17.2143C21.8941 16.7087 22.0013 16.1665 22 15.6192V10.3158C22 10.0948 21.9122 9.88286 21.7559 9.72658C21.5996 9.5703 21.3877 9.4825 21.1667 9.4825ZM17.3875 19.6017C17.0525 19.9358 16.6289 20.1672 16.1667 20.2683V17C16.1667 16.779 16.2545 16.567 16.4107 16.4107C16.567 16.2545 16.779 16.1667 17 16.1667H20.2708C20.1677 16.6279 19.9367 17.0508 19.6042 17.3867L17.3875 19.6017Z"
        fill="#0D99FF"
      />
    </svg>
  );
};

export default EditIcon;
