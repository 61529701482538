import React, { FC, useEffect, useRef, useState } from "react";
import { userService } from "app/services";
import { IColumns } from "../../../partials/layout/DataTable";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router";
import { useIntl } from "react-intl";
import { useTable } from "../../../hooks/subresource/useTable";
import { GridTable } from "../../../partials/layout/GridTable";
import moment from "moment";
import {
  hasPermission,
  PermissionsGate,
} from "../../../permission/PermissionsGate";
import ShowUserDocumentFolder from "./showDocument";
import UserDocumentStore from "../store/documentStore";
import { IUser } from "../../../interfaces";
import { IUserDocumentFolder } from "../../../services/user.service";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import { usePermissions } from "../../../hooks/useRole";
import UserDocumentFilter from "../filters/documentFilter";
import EditIcon from "../../../partials/icons/EditIcon";
import DeleteIcon from "../../../partials/icons/DeleteIcon";

const UserDocument: FC<IUser> = (props) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const permissions = usePermissions();

  const { data, xhrLoading, remove, refresh, setQuery, updateQuery, queries } =
    useTable<IUserDocumentFolder>({
      fetch: userService.getDocFolders,
      delete: userService.deleteDocFolder,
      param: id,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
    },
    {
      field: "title",
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.title}>
            <Typography variant={"inherit"}>{params.row?.title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "_created_at",
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      valueGetter: (params) => {
        return moment.unix(params.row?._created_at).utc().format("DD/MM/YYYY");
      },
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => show(params.row?._id)}
              data-cy-class={"case_document_show"}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleClick(params.row?._id)}
              data-cy-class={"case_document_delete"}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) {
      setInit(true);
    }
  }, [xhrLoading]);

  const [documentFolderId, setDocumentFolderId] = useState<string | null>(null);

  const show = (_id: string) => {
    setDocumentFolderId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"documents"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete document?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(props._id, confirmId);
            }}
          >
            Are you sure you want to delete this document?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"documents"} scope={"read"}>
        {documentFolderId ? (
          <ShowUserDocumentFolder
            _id={documentFolderId}
            user={props._id}
            open={Boolean(documentFolderId)}
            setDialogue={() => setDocumentFolderId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"documents"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "contracts", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <UserDocumentStore
                        user={id}
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            filters: (props: { show: boolean }) => {
              return (
                <UserDocumentFilter
                  id={id}
                  show={props.show}
                  queries={queries}
                  setQuery={setQuery}
                  updateQuery={updateQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default UserDocument;
