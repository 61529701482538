import React, { useState, useRef, useEffect } from "react";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles

import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";

const Summernote = (props) => {
  const [value, setValue] = useState(props.data); // Set the initial/default value here

  const summernoteRef = useRef(null);

  useEffect(() => {
    if (summernoteRef.current) {
      summernoteRef.current.editor.summernote("code", value);
    }
  }, []);
  return (
    <ReactSummernote
      ref={summernoteRef}
      options={{
        disableDragAndDrop: true,
        height: 200,
        toolbar: [
          ["style", ["style"]],
          ["fontsize", ["fontsize"]],
          ["font", ["bold", "underline", "clear"]],
          ["fontname", ["fontname"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["insert", ["link", "picture", "video"]],
          ["view", ["fullscreen", "codeview"]],
        ],
      }}
      onChange={(content) => {
        setValue(content);
        props.onChange(content);
      }}
    />
  );
};

export default Summernote;
