import {Box, Drawer, Paper} from "@mui/material";
import React, {FC} from "react";
import {IUser} from "../../interfaces";


interface IQuickChat {
  open: boolean,
  onClose: any,
  room?: number
  correspondent: IUser
}

const QuickChat: FC<IQuickChat> = (props) =>  {

  return (
    <Drawer
      // sx={{
      //   width: 20
      // }}
      anchor={'right'}
      open={props.open}
      onClose={() => {
        console.log("closing")
      }}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
      >

      </Box>
    </Drawer>
  );
}

export default QuickChat;
