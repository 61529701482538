import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from '@mui/material';
import React, {Dispatch, SetStateAction} from 'react';
import {TransitionProps} from "@mui/material/transitions";

type DProps = {
  title: string
  children: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>,
  onConfirm: Function
}

const ConfirmDialog = (props: DProps) => {

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog
      open={Boolean(+props.open)}
      onClose={handleClose}
      aria-labelledby={`responsive-dialog-title`}
    >
      <DialogTitle id={`responsive-dialog-title`}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Disagree
        </Button>
        <Button onClick={() => {
          props.setOpen(false)
          props.onConfirm()
        }}
                autoFocus
                data-cy-class={'agree'}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default ConfirmDialog;
