import { IMessage } from "./messenger/messenger";
import { IUser } from "../../interfaces";
import { IRoom } from "./rooms/rooms";

export const userJohn: any = {
  _id: "1",
  active: 1,
  address: "Baku",
  avatar: null,
  birthdate: "1995",
  businesses: [
    {
      _id: "",
      active: 0,
      slug: "",
      avatar: "",
      phone: "",
      description: "",
      address: "",
      vat_id: "",
      rn: "",
      an: "",
      currency: "",
      email: "",
      modules: [],
      name: "",
      file: undefined,
    },
  ],
  business: {
    _id: "",
    active: 0,
    slug: "",
    avatar: "",
    phone: "",
    description: "",
    address: "",

    vat_id: "",
    rn: "",
    an: "",
    currency: "",
    email: "",
    modules: [],
    name: "",
    file: undefined,
  },
  city: {
    _created_at: "",
    _id: "",
    _updated_at: "",
    active: 1,
    categories: undefined,
    color: "",
    content: "",
    default_lang: "",
    goal: undefined,
    index: 0,
    name: "cities",
    slug: "",
    title: {},
  },
  email: "",
  firstname: "John",
  fullname: "John Doe",
  gender: "male",
  languages: undefined,
  lastname: "Doe",
  payment_account_number: "",
  payment_registration_number: "",
  phone: "",
  place: "",
  private_email: "",
  time: 0,
  budget: 0,
  balance: 0,
  total_cases: 0,
  role: {
    _id: "",
    access_level: 0,
    color: "",
    permissions: {
      _id: "",
      structure: {
        activity_payments: { abilities: [], params: [], title: "" },
        case_evalreports: { abilities: [], params: [], title: "" },
        case_timerecords: { abilities: [], params: [], title: "" },
        case_tradingplans: { abilities: [], params: [], title: "" },
        accounting: { abilities: [], params: [], title: "" },
        cases: { abilities: [], params: [], title: "" },
        chat: { abilities: [], params: [], title: "" },
        communes: { abilities: [], params: [], title: "" },
        contract_templates: { abilities: [], params: [], title: "" },
        contracts: { abilities: [], params: [], title: "" },
        documents: { abilities: [], params: [], title: "" },
        exports: { abilities: [], params: [], title: "" },
        guidelines: { abilities: [], params: [], title: "" },
        values: { abilities: [], params: [], title: "" },
        incoming_offers: { abilities: [], params: [], title: "" },
        knowledgebase: { abilities: [], params: [], title: "" },
        logs: { abilities: [], params: [], title: "" },
        mailboxes: { abilities: [], params: [], title: "" },
        message: { abilities: [], params: [], title: "" },
        notes: { abilities: [], params: [], title: "" },
        offers: { abilities: [], params: [], title: "" },
        reports: { abilities: [], params: [], title: "" },
        settings: { abilities: [], params: [], title: "" },
        statistics: { abilities: [], params: [], title: "" },
        surveys: { abilities: [], params: [], title: "" },
        time_record_switch: { abilities: [], params: [], title: "" },
        time_records: { abilities: [], params: [], title: "" },
        todos: { abilities: [], params: [], title: "" },
        users: { abilities: [], params: [], title: "" },
        vacancies: { abilities: [], params: [], title: "" },
        projects: { abilities: [], params: [], title: "" },
        tasks: { abilities: [], params: [], title: "" },
        time_track_user: { abilities: [], params: [], title: "" },
        user_payment: { abilities: [], params: [], title: "" },
      },
      title: "",
    },
    slug: "",
    title: {},
  },
  sessions: undefined,
  signature: "",
  ssn: "",
  unread_messages: 0,
  zipcode: "",
};
export const userZeynal: any = {
  _id: "5b546f700964ac4d74795e73",
  active: 1,
  address: "Baku",
  avatar: null,
  birthdate: "1995",
  businesses: [
    {
      _id: "",
      active: 0,
      avatar: "",
      phone: "",
      slug: "",
      description: "",
      vat_id: "",
      address: "",

      rn: "",
      an: "",
      email: "",
      currency: "",
      modules: [],
      name: "",
      file: undefined,
    },
  ],
  business: {
    _id: "",
    active: 0,
    slug: "",
    avatar: "",
    description: "",
    phone: "",
    vat_id: "",
    rn: "",
    currency: "",
    an: "",
    address: "",

    email: "",
    modules: [],
    name: "",
    file: undefined,
  },
  city: {
    _created_at: "",
    _id: "",
    _updated_at: "",
    active: 1,
    categories: undefined,
    color: "",
    content: "",
    default_lang: "",
    goal: undefined,
    index: 0,
    name: "cities",
    slug: "",
    title: {},
  },
  email: "",
  firstname: "Zeynal",
  fullname: "Zeynal",
  gender: "male",
  languages: undefined,
  lastname: "Verdiyev",
  payment_account_number: "",
  payment_registration_number: "",
  phone: "",
  place: "",
  private_email: "",
  time: 0,
  budget: 0,
  balance: 0,
  total_cases: 0,
  role: {
    _id: "",
    access_level: 0,
    color: "",
    permissions: {
      _id: "",
      structure: {
        activity_payments: { abilities: [], params: [], title: "" },
        case_evalreports: { abilities: [], params: [], title: "" },
        case_timerecords: { abilities: [], params: [], title: "" },
        case_tradingplans: { abilities: [], params: [], title: "" },
        cases: { abilities: [], params: [], title: "" },
        accounting: { abilities: [], params: [], title: "" },
        chat: { abilities: [], params: [], title: "" },
        communes: { abilities: [], params: [], title: "" },
        contract_templates: { abilities: [], params: [], title: "" },
        contracts: { abilities: [], params: [], title: "" },
        documents: { abilities: [], params: [], title: "" },
        exports: { abilities: [], params: [], title: "" },
        guidelines: { abilities: [], params: [], title: "" },
        values: { abilities: [], params: [], title: "" },
        incoming_offers: { abilities: [], params: [], title: "" },
        knowledgebase: { abilities: [], params: [], title: "" },
        logs: { abilities: [], params: [], title: "" },
        mailboxes: { abilities: [], params: [], title: "" },
        message: { abilities: [], params: [], title: "" },
        notes: { abilities: [], params: [], title: "" },
        offers: { abilities: [], params: [], title: "" },
        reports: { abilities: [], params: [], title: "" },
        settings: { abilities: [], params: [], title: "" },
        statistics: { abilities: [], params: [], title: "" },
        surveys: { abilities: [], params: [], title: "" },
        time_record_switch: { abilities: [], params: [], title: "" },
        projects: { abilities: [], params: [], title: "" },
        tasks: { abilities: [], params: [], title: "" },
        time_track_user: { abilities: [], params: [], title: "" },
        user_payment: { abilities: [], params: [], title: "" },
        time_records: { abilities: [], params: [], title: "" },
        todos: { abilities: [], params: [], title: "" },
        users: { abilities: [], params: [], title: "" },
        vacancies: { abilities: [], params: [], title: "" },
      },
      title: "",
    },
    slug: "",
    title: {},
  },
  sessions: undefined,
  signature: "",
  ssn: "",
  unread_messages: 0,
  zipcode: "",
};
export const userAnnette: any = {
  _id: "2",
  active: 1,
  address: "Baku",
  file: {
    _id: "_123",
    name: "/media/dummy/image/annette.png",
    type: "img",
    base64: "",
    url: "",
    _created_at: "",
    _updated_at: "",
  },
  avatar: {
    _id: "_123",
    name: "/media/dummy/image/annette.png",
    _created_at: "",
    _updated_at: "",
  },
  birthdate: "1995",
  businesses: [
    {
      _id: "",
      active: 0,
      avatar: "",
      slug: "",
      description: "",
      phone: "",
      vat_id: "",
      rn: "",
      address: "",

      currency: "",
      an: "",
      email: "",
      modules: [],
      name: "",
      file: undefined,
    },
  ],
  business: {
    _id: "",
    active: 0,
    slug: "",
    avatar: "",
    description: "",
    phone: "",
    address: "",

    vat_id: "",
    rn: "",
    currency: "",
    an: "",
    email: "",
    modules: [],
    name: "",
    file: undefined,
  },
  city: {
    _created_at: "",
    _id: "",
    _updated_at: "",
    active: 1,
    categories: undefined,
    color: "",
    content: "",
    default_lang: "",
    goal: undefined,
    index: 0,
    name: "cities",
    slug: "",
    title: {},
  },
  email: "",
  firstname: "Annette",
  fullname: "Annette Black",
  gender: "male",
  languages: undefined,
  lastname: "Black",
  payment_account_number: "",
  payment_registration_number: "",
  phone: "",
  place: "",
  private_email: "",
  time: 0,
  budget: 0,
  balance: 0,
  total_cases: 0,
  role: {
    _id: "",
    access_level: 0,
    color: "",
    permissions: {
      _id: "",
      structure: {
        activity_payments: { abilities: [], params: [], title: "" },
        accounting: { abilities: [], params: [], title: "" },
        case_evalreports: { abilities: [], params: [], title: "" },
        case_timerecords: { abilities: [], params: [], title: "" },
        case_tradingplans: { abilities: [], params: [], title: "" },
        cases: { abilities: [], params: [], title: "" },
        chat: { abilities: [], params: [], title: "" },
        communes: { abilities: [], params: [], title: "" },
        contract_templates: { abilities: [], params: [], title: "" },
        contracts: { abilities: [], params: [], title: "" },
        documents: { abilities: [], params: [], title: "" },
        exports: { abilities: [], params: [], title: "" },
        guidelines: { abilities: [], params: [], title: "" },
        values: { abilities: [], params: [], title: "" },
        incoming_offers: { abilities: [], params: [], title: "" },
        knowledgebase: { abilities: [], params: [], title: "" },
        projects: { abilities: [], params: [], title: "" },
        tasks: { abilities: [], params: [], title: "" },
        time_track_user: { abilities: [], params: [], title: "" },
        user_payment: { abilities: [], params: [], title: "" },
        logs: { abilities: [], params: [], title: "" },
        mailboxes: { abilities: [], params: [], title: "" },
        message: { abilities: [], params: [], title: "" },
        notes: { abilities: [], params: [], title: "" },
        offers: { abilities: [], params: [], title: "" },
        reports: { abilities: [], params: [], title: "" },
        settings: { abilities: [], params: [], title: "" },
        statistics: { abilities: [], params: [], title: "" },
        surveys: { abilities: [], params: [], title: "" },
        time_record_switch: { abilities: [], params: [], title: "" },
        time_records: { abilities: [], params: [], title: "" },
        todos: { abilities: [], params: [], title: "" },
        users: { abilities: [], params: [], title: "" },
        vacancies: { abilities: [], params: [], title: "" },
      },
      title: "",
    },
    slug: "",
    title: {},
  },
  sessions: undefined,
  signature: "",
  ssn: "",
  unread_messages: 0,
  zipcode: "",
};
export const userSavannah: any = {
  _id: "3",
  active: 1,
  address: "Baku",
  file: {
    _id: "_123",
    name: "/media/dummy/image/annette.png",
    type: "img",
    base64: "",
    url: "",
    _created_at: "",
    _updated_at: "",
  },
  avatar: {
    _id: "_123",
    name: "/media/dummy/image/annette.png",
    _created_at: "",
    _updated_at: "",
  },
  birthdate: "1995",
  businesses: [
    {
      _id: "",
      active: 0,
      avatar: "",
      slug: "",
      currency: "",
      description: "",
      phone: "",
      address: "",

      vat_id: "",
      rn: "",
      an: "",
      email: "",
      modules: [],
      name: "",
      file: undefined,
    },
  ],
  business: {
    _id: "",
    active: 0,
    slug: "",
    address: "",
    phone: "",

    avatar: "",
    currency: "",
    description: "",
    vat_id: "",
    rn: "",
    an: "",
    email: "",
    modules: [],
    name: "",
    file: undefined,
  },
  city: {
    _created_at: "",
    _id: "",
    _updated_at: "",
    active: 1,
    categories: undefined,
    color: "",
    content: "",
    default_lang: "",
    goal: undefined,
    index: 0,
    name: "cities",
    slug: "",
    title: {},
  },
  email: "",
  firstname: "Savannah",
  fullname: "Savannah Nguyen",
  gender: "male",
  languages: undefined,
  lastname: "Nguyen",
  payment_account_number: "",
  payment_registration_number: "",
  phone: "",
  place: "",
  private_email: "",
  time: 0,
  budget: 0,
  balance: 0,
  total_cases: 0,
  role: {
    _id: "",
    access_level: 0,
    color: "",
    permissions: {
      _id: "",
      structure: {
        activity_payments: { abilities: [], params: [], title: "" },
        case_evalreports: { abilities: [], params: [], title: "" },
        case_timerecords: { abilities: [], params: [], title: "" },
        accounting: { abilities: [], params: [], title: "" },
        case_tradingplans: { abilities: [], params: [], title: "" },
        cases: { abilities: [], params: [], title: "" },
        chat: { abilities: [], params: [], title: "" },
        communes: { abilities: [], params: [], title: "" },
        contract_templates: { abilities: [], params: [], title: "" },
        contracts: { abilities: [], params: [], title: "" },
        documents: { abilities: [], params: [], title: "" },
        exports: { abilities: [], params: [], title: "" },
        guidelines: { abilities: [], params: [], title: "" },
        values: { abilities: [], params: [], title: "" },
        incoming_offers: { abilities: [], params: [], title: "" },
        knowledgebase: { abilities: [], params: [], title: "" },
        logs: { abilities: [], params: [], title: "" },
        mailboxes: { abilities: [], params: [], title: "" },
        message: { abilities: [], params: [], title: "" },
        notes: { abilities: [], params: [], title: "" },
        offers: { abilities: [], params: [], title: "" },
        reports: { abilities: [], params: [], title: "" },
        projects: { abilities: [], params: [], title: "" },
        tasks: { abilities: [], params: [], title: "" },
        time_track_user: { abilities: [], params: [], title: "" },
        user_payment: { abilities: [], params: [], title: "" },
        settings: { abilities: [], params: [], title: "" },
        statistics: { abilities: [], params: [], title: "" },
        surveys: { abilities: [], params: [], title: "" },
        time_record_switch: { abilities: [], params: [], title: "" },
        time_records: { abilities: [], params: [], title: "" },
        todos: { abilities: [], params: [], title: "" },
        users: { abilities: [], params: [], title: "" },
        vacancies: { abilities: [], params: [], title: "" },
      },
      title: "",
    },
    slug: "",
    title: {},
  },
  sessions: undefined,
  signature: "",
  ssn: "",
  unread_messages: 0,
  zipcode: "",
};
export const userMarvin: any = {
  _id: "4",
  active: 1,
  address: "Baku",
  file: {
    _id: "_123",
    name: "/media/dummy/image/annette.png",
    type: "img",
    base64: "",
    url: "",
    _created_at: "",
    _updated_at: "",
  },
  avatar: {
    _id: "_123",
    name: "/media/dummy/image/annette.png",
    _created_at: "",
    _updated_at: "",
  },
  birthdate: "1995",
  businesses: [
    {
      _id: "",
      active: 0,
      avatar: "",
      slug: "",
      description: "",
      phone: "",
      vat_id: "",
      rn: "",
      address: "",

      an: "",
      email: "",
      currency: "",
      modules: [],
      name: "",
      file: undefined,
    },
  ],
  business: {
    _id: "",
    active: 0,
    slug: "",
    avatar: "",
    currency: "",
    description: "",
    phone: "",
    address: "",

    vat_id: "",
    rn: "",
    an: "",
    email: "",
    modules: [],
    name: "",
    file: undefined,
  },
  city: {
    _created_at: "",
    _id: "",
    _updated_at: "",
    active: 1,
    categories: undefined,
    color: "",
    content: "",
    default_lang: "",
    goal: undefined,
    index: 0,
    name: "cities",
    slug: "",
    title: {},
  },
  email: "",
  firstname: "Marvin",
  fullname: "Marvin McKinney",
  gender: "male",
  languages: undefined,
  lastname: "McKinney",
  payment_account_number: "",
  payment_registration_number: "",
  phone: "",
  place: "",
  private_email: "",
  time: 0,
  budget: 0,
  balance: 0,
  total_cases: 0,
  role: {
    _id: "",
    access_level: 0,
    color: "",
    permissions: {
      _id: "",
      structure: {
        activity_payments: { abilities: [], params: [], title: "" },
        case_evalreports: { abilities: [], params: [], title: "" },
        accounting: { abilities: [], params: [], title: "" },
        case_timerecords: { abilities: [], params: [], title: "" },
        case_tradingplans: { abilities: [], params: [], title: "" },
        cases: { abilities: [], params: [], title: "" },
        chat: { abilities: [], params: [], title: "" },
        communes: { abilities: [], params: [], title: "" },
        contract_templates: { abilities: [], params: [], title: "" },
        contracts: { abilities: [], params: [], title: "" },
        documents: { abilities: [], params: [], title: "" },
        exports: { abilities: [], params: [], title: "" },
        guidelines: { abilities: [], params: [], title: "" },
        values: { abilities: [], params: [], title: "" },
        incoming_offers: { abilities: [], params: [], title: "" },
        knowledgebase: { abilities: [], params: [], title: "" },
        logs: { abilities: [], params: [], title: "" },
        mailboxes: { abilities: [], params: [], title: "" },
        message: { abilities: [], params: [], title: "" },
        notes: { abilities: [], params: [], title: "" },
        projects: { abilities: [], params: [], title: "" },
        tasks: { abilities: [], params: [], title: "" },
        time_track_user: { abilities: [], params: [], title: "" },
        user_payment: { abilities: [], params: [], title: "" },
        offers: { abilities: [], params: [], title: "" },
        reports: { abilities: [], params: [], title: "" },
        settings: { abilities: [], params: [], title: "" },
        statistics: { abilities: [], params: [], title: "" },
        surveys: { abilities: [], params: [], title: "" },
        time_record_switch: { abilities: [], params: [], title: "" },
        time_records: { abilities: [], params: [], title: "" },
        todos: { abilities: [], params: [], title: "" },
        users: { abilities: [], params: [], title: "" },
        vacancies: { abilities: [], params: [], title: "" },
      },
      title: "",
    },
    slug: "",
    title: {},
  },
  sessions: undefined,
  signature: "",
  ssn: "",
  unread_messages: 0,
  zipcode: "",
};
export const userAlbert: any = {
  _id: "5",
  active: 1,
  address: "Baku",
  file: {
    _id: "_123",
    name: "/media/dummy/image/annette.png",
    type: "img",
    base64: "",
    url: "",
    _created_at: "",
    _updated_at: "",
  },
  avatar: {
    _id: "_123",
    name: "/media/dummy/image/annette.png",
    _created_at: "",
    _updated_at: "",
  },
  birthdate: "1995",
  businesses: [
    {
      _id: "",
      active: 0,
      avatar: "",
      slug: "",
      description: "",
      phone: "",
      currency: "",
      vat_id: "",
      rn: "",
      an: "",
      email: "",
      address: "",

      modules: [],
      name: "",
      file: undefined,
    },
  ],
  business: {
    _id: "",
    active: 0,
    slug: "",
    avatar: "",
    currency: "",
    phone: "",
    address: "",

    description: "",
    vat_id: "",
    rn: "",
    an: "",
    email: "",
    modules: [],
    name: "",
    file: undefined,
  },
  city: {
    _created_at: "",
    _id: "",
    _updated_at: "",
    active: 1,
    categories: undefined,
    color: "",
    content: "",
    default_lang: "",
    goal: undefined,
    index: 0,
    name: "cities",
    slug: "",
    title: {},
  },
  email: "",
  firstname: "Albert",
  fullname: "Albert Flores",
  gender: "male",
  languages: undefined,
  lastname: "Flores",
  payment_account_number: "",
  payment_registration_number: "",
  phone: "",
  place: "",
  private_email: "",
  time: 0,
  budget: 0,
  balance: 0,
  total_cases: 0,
  role: {
    _id: "",
    access_level: 0,
    color: "",
    permissions: {
      _id: "",
      structure: {
        activity_payments: { abilities: [], params: [], title: "" },
        case_evalreports: { abilities: [], params: [], title: "" },
        accounting: { abilities: [], params: [], title: "" },
        case_timerecords: { abilities: [], params: [], title: "" },
        case_tradingplans: { abilities: [], params: [], title: "" },
        cases: { abilities: [], params: [], title: "" },
        projects: { abilities: [], params: [], title: "" },
        tasks: { abilities: [], params: [], title: "" },
        time_track_user: { abilities: [], params: [], title: "" },
        user_payment: { abilities: [], params: [], title: "" },
        chat: { abilities: [], params: [], title: "" },
        communes: { abilities: [], params: [], title: "" },
        contract_templates: { abilities: [], params: [], title: "" },
        contracts: { abilities: [], params: [], title: "" },
        documents: { abilities: [], params: [], title: "" },
        exports: { abilities: [], params: [], title: "" },
        guidelines: { abilities: [], params: [], title: "" },
        values: { abilities: [], params: [], title: "" },
        incoming_offers: { abilities: [], params: [], title: "" },
        knowledgebase: { abilities: [], params: [], title: "" },
        logs: { abilities: [], params: [], title: "" },
        mailboxes: { abilities: [], params: [], title: "" },
        message: { abilities: [], params: [], title: "" },
        notes: { abilities: [], params: [], title: "" },
        offers: { abilities: [], params: [], title: "" },
        reports: { abilities: [], params: [], title: "" },
        settings: { abilities: [], params: [], title: "" },
        statistics: { abilities: [], params: [], title: "" },
        surveys: { abilities: [], params: [], title: "" },
        time_record_switch: { abilities: [], params: [], title: "" },
        time_records: { abilities: [], params: [], title: "" },
        todos: { abilities: [], params: [], title: "" },
        users: { abilities: [], params: [], title: "" },
        vacancies: { abilities: [], params: [], title: "" },
      },
      title: "",
    },
    slug: "",
    title: {},
  },
  sessions: undefined,
  signature: "",
  ssn: "",
  unread_messages: 0,
  zipcode: "",
};

export const rooms: Array<IRoom> = [
  {
    _id: "1",
    image: "/media/dummy/image/annette.png",
    name: "Annette Black",
    files: undefined,
    last_message: null,
    unread: 0,
    users: [userJohn, userAnnette],
  },
  {
    _id: "2",
    image: "/media/dummy/image/savannah.png",
    name: "Savannah Nguyen",
    files: undefined,
    last_message: null,
    unread: 0,
    users: [userJohn, userSavannah],
  },
  {
    _id: "3",
    image: "/media/dummy/image/employees.png",
    name: "Employees",
    files: undefined,
    last_message: null,
    unread: 0,
    users: [userJohn, userAnnette, userSavannah],
  },
  {
    _id: "4",
    image: "/media/dummy/image/marvin.png",
    name: "Marvin McKinney",
    files: undefined,
    last_message: null,
    unread: 0,
    users: [userJohn, userMarvin],
  },
  {
    _id: "5",
    image: "/media/dummy/image/albert.png",
    name: "Albert Flores",
    files: undefined,
    last_message: null,
    unread: 0,
    users: [userJohn, userAlbert],
  },
];

export const messages: Array<IMessage> = [
  //files
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "8",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "report.doc",
        type: "document",
        base64: "",
        _created_at: Date.now(),
        url: "",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "8",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "receipt.doc",
        type: "document",
        base64: "",
        _created_at: Date.now(),
        url: "",
      },
      {
        _id: "",
        name: "may_receipt.doc",
        type: "document",
        base64: "",
        _created_at: Date.now(),
        url: "",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "8",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "monthly_audit-from-accountant_copy-2022-09-02.xlsx",
        type: "document",
        base64: "",
        _created_at: Date.now(),
        url: "",
      },
      {
        _id: "",
        name: "monthly_audit-2022-09-02.xlsx",
        type: "document",
        base64: "",
        _created_at: Date.now(),
        url: "",
      },
      {
        _id: "",
        name: "prices.xlsx",
        type: "document",
        base64: "",
        _created_at: Date.now(),
        url: "",
      },
      {
        _id: "",
        name: "presentation.doc",
        type: "document",
        base64: "",
        _created_at: Date.now(),
        url: "",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },

  //end files
  // images
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "8",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "8",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "8",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "8",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "8",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },

  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "8",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      },
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.w3schools.com/howto/img_forest.jpg",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  // end docs

  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "7",
    chat: "3",
    created_by: userAnnette,
    documents: [
      {
        _id: "",
        name: "image.jpg",
        type: "img",
        base64: "",
        _created_at: Date.now(),
        url: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
      },
    ],
    message: "File caption",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "6",
    replyMessage: {
      _created_at: Math.floor(new Date().getTime() / 1000),
      _id: "5",
      replyMessage: {
        _created_at: Math.floor(new Date().getTime() / 1000),
        _id: "4",
        chat: "3",
        created_by: userAnnette,
        message: "This messsage should be inside replied",
        pending: false,
        received: 1,
        sent: 1,
        uuid: "",
      },
      chat: "3",
      created_by: userJohn,
      message: "Container",
      pending: false,
      received: 1,
      sent: 1,
      uuid: "",
    },
    chat: "3",
    created_by: userAnnette,
    message: "container for reply",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "5",
    replyMessage: {
      _created_at: Math.floor(new Date().getTime() / 1000),
      _id: "4",
      chat: "3",
      created_by: userAnnette,
      message: "This messsage should be inside replied",
      pending: false,
      received: 1,
      sent: 1,
      uuid: "",
    },
    chat: "3",
    created_by: userJohn,
    message: "Container",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "4",
    chat: "3",
    created_by: userAnnette,
    message: "This messsage should be inside replied",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date("2012.08.10").getTime() / 1000),
    _id: "1",
    _updated_at: undefined,
    chat: "3",
    created_by: userJohn,
    message: "Hi Jenny",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date("2012.08.10").getTime() / 1000),
    _id: "2",
    _updated_at: undefined,
    chat: "3",
    created_by: userAnnette,
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
  {
    _created_at: Math.floor(new Date().getTime() / 1000),
    _id: "3",
    chat: "3",
    created_by: userJohn,
    message: "Lorem ipsum dolor sit amet,",
    pending: false,
    received: 1,
    sent: 1,
    uuid: "",
  },
];

export const images: Array<{ src: string; alt: string; id: string }> = [
  {
    id: "1",
    src: "https://burst.shopifycdn.com/photos/person-holds-a-book-over-a-stack-and-turns-the-page.jpg?width=1200&format=pjpg&exif=0&iptc=0",
    alt: "book",
  },
  {
    id: "2",
    src: "https://www.w3schools.com/howto/img_forest.jpg",
    alt: "forest",
  },
  {
    id: "3",
    src: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    alt: "tree",
  },
  {
    id: "4",
    src: "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
    alt: "environment",
  },
  {
    id: "5",
    src: "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80",
    alt: "man",
  },
  {
    id: "6",
    src: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    alt: "Breakfast",
  },
  {
    id: "1",
    src: "https://burst.shopifycdn.com/photos/person-holds-a-book-over-a-stack-and-turns-the-page.jpg?width=1200&format=pjpg&exif=0&iptc=0",
    alt: "book",
  },
  {
    id: "2",
    src: "https://www.w3schools.com/howto/img_forest.jpg",
    alt: "forest",
  },
  {
    id: "3",
    src: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    alt: "tree",
  },
  {
    id: "4",
    src: "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
    alt: "environment",
  },
  {
    id: "5",
    src: "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80",
    alt: "man",
  },
  {
    id: "6",
    src: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    alt: "Breakfast",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "1",
    src: "https://burst.shopifycdn.com/photos/person-holds-a-book-over-a-stack-and-turns-the-page.jpg?width=1200&format=pjpg&exif=0&iptc=0",
    alt: "book",
  },
  {
    id: "2",
    src: "https://www.w3schools.com/howto/img_forest.jpg",
    alt: "forest",
  },
  {
    id: "3",
    src: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    alt: "tree",
  },
  {
    id: "4",
    src: "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
    alt: "environment",
  },
  {
    id: "5",
    src: "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80",
    alt: "man",
  },
  {
    id: "6",
    src: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    alt: "Breakfast",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
  {
    id: "7",
    src: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    alt: "Burger",
  },
];

export const files: Array<{
  id: string;
  name: string;
  secondary: string;
}> = [
  {
    id: "1",
    name: "inforamtion.doc",
    secondary: "89.4 KB  * 16/11/2022 01:13",
  },
  {
    id: "2",
    name: "presentation.pptx",
    secondary: "111.4 KB  * 19/09/2022 04:25",
  },
  {
    id: "3",
    name: "report_daily.xls",
    secondary: "0.4 KB  * 09/09/2022 16:23",
  },
  {
    id: "4",
    name: "test_document.xls",
    secondary: "194.4 KB  * 10/08/2022 12:03",
  },
  {
    id: "5",
    name: "test_document.pdf",
    secondary: "44.4 KB  * 29/07/2022 23:03",
  },
  {
    id: "6",
    name: "book.doc",
    secondary: "133.4 KB  * 17/06/2022 11:27",
  },
  {
    id: "7",
    name: "example.doc",
    secondary: "36.4 KB  * 16/06/2022 17:12",
  },
];

export const links: Array<{
  id: string;
  title: string;
  secondary: string;
  url: string;
  extracted_image: string;
}> = [
  {
    id: "1",
    title: "Future Cars Worth Waiting For: 2022–2025",
    secondary: "Lorem ipsum dolor sit amet consectetur",
    url: "https://www.Lorem ipsum dolor sit.com",
    extracted_image:
      "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
  },
  {
    id: "2",
    title: "Future Cars Worth Waiting For: 2022–2025",
    secondary: "Lorem ipsum dolor sit amet consectetur",
    url: "https://www.Lorem ipsum dolor sit.com",
    extracted_image:
      "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
  },
  {
    id: "3",
    title: "Future Cars Worth Waiting For: 2022–2025",
    secondary: "Lorem ipsum dolor sit amet consectetur",
    url: "https://www.Lorem ipsum dolor sit.com",
    extracted_image:
      "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
  },
];

export const voices: Array<{
  id: string;
  name: string;
  secondary: string;
  url: string;
  active: boolean;
  audio: HTMLAudioElement;
}> = [
  {
    id: "1",
    name: "You",
    secondary: "0:13 * 18/06/2022 11:27",
    url: "https://commondatastorage.googleapis.com/codeskulptor-demos/pyman_assets/ateapill.ogg",
    active: false,
    audio: new Audio(
      "https://commondatastorage.googleapis.com/codeskulptor-demos/pyman_assets/ateapill.ogg"
    ),
  },
  {
    id: "2",
    name: "Marvin McKinney",
    secondary: "0:13  * 17/06/2022 11:27",
    url: "https://codeskulptor-demos.commondatastorage.googleapis.com/pang/paza-moduless.mp3",
    active: false,
    audio: new Audio(
      "https://codeskulptor-demos.commondatastorage.googleapis.com/pang/paza-moduless.mp3"
    ),
  },
  {
    id: "3",
    name: "You",
    secondary: "1:12  * 09/09/2022 16:23",
    url: "https://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/menu.ogg",
    active: false,
    audio: new Audio(
      "https://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/menu.ogg"
    ),
  },
];

export const members: Array<{
  id: string;
  avatar: string;
  name: string;
  secondary: string;
}> = [
  {
    id: "1",
    avatar: "/media/dummy/image/annette.png",
    name: "Annette Black",
    secondary: "online",
  },
  {
    id: "2",
    avatar: "/media/dummy/image/john.png",
    name: "John Doe",
    secondary: "online",
  },
  {
    id: "3",
    avatar: "/media/dummy/image/marvin.png",
    name: "Marvin McKinney",
    secondary: "last seen time ago",
  },
];
