import React, {
  ChangeEventHandler,
  FC,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Chip,
  Divider,
  Box,
  Theme,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { isDocument } from "@testing-library/user-event/dist/utils";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleReply?: () => void;
  handleCopy?: () => void;
  handleForward?: () => void;
  handleSelect?: () => void;
  handleDelete?: () => void;
  anchorEl: null | HTMLElement;
  pos: { x: number; y: number };
  isDocument: boolean;
};

const useStyles = makeStyles((theme) => ({
  ctxMenu: {
    "& .MuiPaper-root": {
      borderRadius: 12,
      p: 1,
    },
    "& .MuiMenuItem-root": {
      py: theme.spacing(1.5),
    },
  },
  ctxList: {
    display: "flex",
    justifyContent: "center",
    gap: "6px",
    "& i": {
      color: "#767C91",
      lineHeight: "0.5em",
      verticalAlign: "-0.15em",
      display: "inline-flex",
    },
  },
}));

const ContextMenu: FC<Props> = (props) => {
  const {
    open,
    handleClose,
    handleForward,
    handleReply,
    handleSelect,
    handleDelete,
    handleCopy,
    anchorEl,
    pos,
    isDocument,
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Menu
      className={classes.ctxMenu}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={{
        left: pos.x,
        top: pos.y,
      }}
    >
      {handleReply && (
        <MenuItem onClick={handleReply}>
          <Box className={classes.ctxList}>
            <i className="fi fi-sr-undo" />
            <Typography fontSize={10}>
              {intl.formatMessage({
                id: "CHAT.REPLY",
              })}
            </Typography>
          </Box>
        </MenuItem>
      )}
      {handleCopy && !isDocument && (
        <MenuItem onClick={handleCopy}>
          <Box className={classes.ctxList}>
            <i className="fi fi-sr-copy" />
            <Typography fontSize={10}>
              {intl.formatMessage({
                id: "CHAT.COPY",
              })}
            </Typography>
          </Box>
        </MenuItem>
      )}
      {handleForward && (
        <MenuItem onClick={handleForward}>
          <Box className={classes.ctxList}>
            <i className="fi fi-sr-redo" />
            <Typography fontSize={10}>
              {intl.formatMessage({
                id: "CHAT.FORWARD",
              })}
            </Typography>
          </Box>
        </MenuItem>
      )}
      {/*{handleSelect && (*/}
      {/*  <MenuItem onClick={handleSelect}>*/}
      {/*    <Box className={classes.ctxList}>*/}
      {/*      <i className='fi fi-sr-angle-circle-down'/>*/}
      {/*      <Typography fontSize={10}>*/}
      {/*        {intl.formatMessage({*/}
      {/*          id: "CHAT.SELECT",*/}
      {/*        })}*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}

      {/*  </MenuItem>*/}
      {/*)}*/}
      {handleDelete && (
        <MenuItem onClick={handleDelete}>
          <Box className={classes.ctxList}>
            <i className="fi fi-sr-trash" />
            <Typography fontSize={10}>
              {intl.formatMessage({
                id: "CHAT.DELETE",
              })}
            </Typography>
          </Box>
        </MenuItem>
      )}
    </Menu>
  );
};

export default ContextMenu;
