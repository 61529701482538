import React, { FC, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, IconButton, Paper, Skeleton, Tab } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Information from "./show/information";
import Cases from "./show/cases";
import Contracts from "./show/contracts";
import Penalties from "./show/penalties";
import Password from "./show/password";
import ProfileUserCard from "../../widgets/ProfileUserCard";
import { IUser } from "../../interfaces";
import { userService } from "../../services";
import { useParams } from "react-router";
import Snackbar from "../../widgets/Snackbar";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import UserNotes from "./show/notes";
import UserDocument from "./show/documents";
import FixedSideButton from "../../partials/layout/FixedSideButton";
import UserOverview from "./show/overview";
import EditUser from "./show/edit";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Siblings from "./show/siblings";
import UserTimeTracks from "./show/time_track";
import UserFinance from "./show/finance";
import { usePermissions } from "../../hooks/useRole";

const useStyle = makeStyles(() => ({
  tabPanel: {
    "& .MuiTabPanel-root": {
      padding: "20px 0 0 0",
    },
  },
}));

const User: FC<any> = () => {
  const permissions = usePermissions();
  const intl = useIntl();
  const classes = useStyle();
  const { id } = useParams<{ id: string }>();
  const [section, setSection] = useState<string>("0");
  const [user, setUser] = useState<IUser>();
  const [editDialogue, setEditDialogue] = useState<boolean>(false);
  const { user: me } = useSelector(({ auth }: RootState) => auth);
  const [granted, setGranted] = useState<boolean>(true);

  useEffect(() => {
    if (
      me?.role.slug === "citizen" ||
      (me?.role.slug === "social-worker" && me?._id != id)
    )
      setGranted(false);
  }, [me]);

  useEffect(() => {
    getUser();

    if (
      me?.role?.slug === "moderator" ||
      me?.role?.slug === "super-admin" ||
      me?._id === id
    ) {
      FixedSideButton.put(<StickyButtons />);
      FixedSideButton.show();
    }

    return () => FixedSideButton.clear();
  }, []);

  const getUser = () => {
    userService
      .getOne(id)
      .then((response) => {
        if ("error" in response) {
          throw new Error(response.error.message);
        }

        let { data: _user } = response;
        setUser(_user);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const StickyButtons = () => {
    return (
      <>
        {/*  block button  */}
        <IconButton
          sx={{
            backgroundColor: "#0D99FF",
            position: "fixed",
            zIndex: 100,
            width: 40,
            height: 40,
            borderRadius: 0,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            right: 0,
            top: 165,
            "&:hover": { backgroundColor: "#0D99FF" },
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8611 7.51666V6.52776C14.8611 3.84306 12.6847 1.66666 9.99998 1.66666C7.31524 1.66666 5.13888 3.84306 5.13888 6.52776V7.51666C3.87501 8.06825 3.05736 9.31545 3.05554 10.6944V14.8611C3.05782 16.7778 4.61104 18.331 6.52774 18.3333H13.4722C15.3889 18.331 16.9421 16.7778 16.9444 14.8611V10.6944C16.9426 9.31545 16.1249 8.06825 14.8611 7.51666ZM10.6944 13.4722C10.6944 13.8558 10.3835 14.1667 9.99998 14.1667C9.61645 14.1667 9.30554 13.8558 9.30554 13.4722V12.0833C9.30554 11.6998 9.61645 11.3889 9.99998 11.3889C10.3835 11.3889 10.6944 11.6998 10.6944 12.0833V13.4722V13.4722ZM13.4722 7.22222H6.52774V6.52779C6.52774 4.61015 8.0823 3.05556 9.99998 3.05556C11.9177 3.05556 13.4722 4.61011 13.4722 6.52779V7.22222V7.22222Z"
              fill="white"
            />
          </svg>
        </IconButton>

        {/*  edit icon  */}
        <IconButton
          sx={{
            backgroundColor: "#FBB73C",
            position: "fixed",
            zIndex: 100,
            width: 40,
            height: 40,
            borderRadius: 0,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            right: 0,
            top: 217,
            "&:hover": { backgroundColor: "#FBB73C" },
          }}
          onClick={() => setEditDialogue(true)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6223 2.31247L6.1556 10.7791C5.83222 11.1008 5.57586 11.4833 5.40135 11.9047C5.22684 12.3261 5.13764 12.7779 5.13893 13.234V14.1666C5.13893 14.3508 5.21209 14.5275 5.34233 14.6577C5.47256 14.7879 5.6492 14.8611 5.83337 14.8611H6.76601C7.2221 14.8624 7.67391 14.7732 8.09529 14.5987C8.51667 14.4242 8.89925 14.1678 9.22087 13.8444L17.6875 5.37775C18.0934 4.97092 18.3213 4.41975 18.3213 3.84511C18.3213 3.27048 18.0934 2.7193 17.6875 2.31247C17.2748 1.91795 16.7259 1.69778 16.1549 1.69778C15.584 1.69778 15.035 1.91795 14.6223 2.31247ZM16.7056 4.39581L8.23893 12.8625C7.84735 13.2517 7.3181 13.4707 6.76601 13.4722H6.52782V13.234C6.52927 12.6819 6.74835 12.1527 7.13754 11.7611L15.6042 3.29442C15.7525 3.15272 15.9498 3.07365 16.1549 3.07365C16.36 3.07365 16.5573 3.15272 16.7056 3.29442C16.8514 3.44061 16.9333 3.63865 16.9333 3.84511C16.9333 4.05158 16.8514 4.24961 16.7056 4.39581Z"
              fill="white"
            />
            <path
              d="M17.6389 7.90207C17.4547 7.90207 17.278 7.97524 17.1478 8.10547C17.0176 8.23571 16.9444 8.41234 16.9444 8.59652V12.0833H14.1666C13.6141 12.0833 13.0842 12.3028 12.6935 12.6935C12.3028 13.0842 12.0833 13.6141 12.0833 14.1667V16.9444H5.13885C4.58631 16.9444 4.05641 16.7249 3.66571 16.3342C3.27501 15.9435 3.05552 15.4136 3.05552 14.8611V5.13888C3.05552 4.58634 3.27501 4.05644 3.66571 3.66574C4.05641 3.27504 4.58631 3.05555 5.13885 3.05555H11.418C11.6022 3.05555 11.7788 2.98238 11.9091 2.85215C12.0393 2.72191 12.1125 2.54528 12.1125 2.3611C12.1125 2.17692 12.0393 2.00029 11.9091 1.87005C11.7788 1.73982 11.6022 1.66666 11.418 1.66666H5.13885C4.2183 1.66776 3.33576 2.03394 2.68483 2.68486C2.03391 3.33579 1.66773 4.21833 1.66663 5.13888L1.66663 14.8611C1.66773 15.7817 2.03391 16.6642 2.68483 17.3151C3.33576 17.966 4.2183 18.3322 5.13885 18.3333H13.0159C13.4721 18.3346 13.924 18.2454 14.3455 18.0709C14.767 17.8964 15.1497 17.6401 15.4715 17.3167L17.3159 15.4708C17.6394 15.1492 17.8958 14.7666 18.0704 14.3453C18.2451 13.9239 18.3344 13.4721 18.3333 13.016V8.59652C18.3333 8.41234 18.2601 8.23571 18.1299 8.10547C17.9997 7.97524 17.823 7.90207 17.6389 7.90207ZM14.4895 16.3347C14.2104 16.6132 13.8574 16.806 13.4722 16.8903V14.1667C13.4722 13.9825 13.5453 13.8058 13.6756 13.6756C13.8058 13.5454 13.9824 13.4722 14.1666 13.4722H16.8923C16.8064 13.8566 16.6139 14.209 16.3368 14.4889L14.4895 16.3347Z"
              fill="white"
            />
          </svg>
        </IconButton>
      </>
    );
  };

  return (
    <PermissionsGate section={"users"} scope={"read"}>
      <>
        <EditUser
          open={editDialogue}
          setDialogue={setEditDialogue}
          user={user}
          _id={id}
          userRefresh={getUser}
        />
        {!user ? (
          <Skeleton />
        ) : (
          <>
            <Paper
              sx={{
                borderBottom: "1px solid #F0F2F5",
              }}
            >
              <ProfileUserCard user={user} />
            </Paper>

            {(me?.role?.slug === "super-admin" ||
              me?.role?.slug === "moderator" ||
              me?._id === user._id) && (
              <Box className={classes.tabPanel}>
                <TabContext value={section}>
                  <Box>
                    <Paper
                      sx={{
                        boxShadow: "unset!important",
                        borderRadius: 0,
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                        padding: "0 24px",
                      }}
                    >
                      <TabList
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={(e, newValue) => setSection(newValue)}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label={intl.formatMessage({ id: "CASE.OVERVIEW" })}
                          value={"0"}
                        />
                        <Tab
                          label={intl.formatMessage({
                            id: "CASE.INFORMATION",
                          })}
                          value={"1"}
                        />
                        <Tab
                          label={intl.formatMessage({ id: "MENU.CASES" })}
                          value={"2"}
                        />
                        <Tab
                          label={intl.formatMessage({ id: "MENU.CONTRACTS" })}
                          value={"3"}
                        />
                        <Tab
                          label={intl.formatMessage({
                            id: "MENU.SIBLINGS",
                            defaultMessage: "Siblings",
                          })}
                          value={"4"}
                        />
                        <Tab
                          label={intl.formatMessage({ id: "CASE.DOCUMENTS" })}
                          value={"5"}
                        />
                        <Tab
                          label={intl.formatMessage({ id: "CASE.NOTES" })}
                          value={"6"}
                        />
                        {(me?.role?.slug === "super-admin" ||
                          me?.role?.slug === "moderator") && (
                          <Tab
                            label={intl.formatMessage({
                              id: "USERS.PENALTIES",
                            })}
                            value={"7"}
                          />
                        )}
                        <Tab
                          label={intl.formatMessage({ id: "USERS.PASSWORD" })}
                          value={"8"}
                        />
                        {permissions &&
                          hasPermission(
                            permissions,
                            "time_track_user",
                            "read"
                          ) && (
                            <Tab
                              label={intl.formatMessage({
                                id: "MENU.TIMETRACKS",
                              })}
                              value={"9"}
                            />
                          )}
                        {permissions &&
                          hasPermission(
                            permissions,
                            "user_payment",
                            "read"
                          ) && (
                            <Tab
                              label={intl.formatMessage({
                                id: "CASE.ACTIVTYPAYMENT",
                              })}
                              value={"10"}
                            />
                          )}
                      </TabList>
                    </Paper>
                  </Box>
                  <TabPanel value={"0"}>
                    <UserOverview user={user} />
                  </TabPanel>
                  <TabPanel value={"1"}>
                    <Information user={user} />
                  </TabPanel>
                  <TabPanel value={"2"}>
                    <Cases />
                  </TabPanel>
                  <TabPanel value={"3"}>
                    <Contracts />
                  </TabPanel>
                  <TabPanel value={"4"}>
                    <Siblings {...user} />
                  </TabPanel>
                  <TabPanel value={"5"}>
                    <UserDocument {...user} />
                  </TabPanel>
                  <TabPanel value={"6"}>
                    <UserNotes />
                  </TabPanel>
                  <TabPanel value={"7"}>
                    <Penalties {...user} />
                  </TabPanel>
                  <TabPanel value={"8"}>
                    <Password />
                  </TabPanel>
                  <TabPanel value={"9"}>
                    <UserTimeTracks user={user} />
                  </TabPanel>
                  <TabPanel value={"10"}>
                    <UserFinance user={user} />
                  </TabPanel>
                </TabContext>
              </Box>
            )}
          </>
        )}
      </>
    </PermissionsGate>
  );
};

export default User;
