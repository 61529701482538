import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import BarChartIcon from "@mui/icons-material/BarChart";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import WorkIcon from "@mui/icons-material/Work";
import FlagIcon from "@mui/icons-material/Flag";
import { useHistory } from "react-router-dom";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EmailIcon from "@mui/icons-material/Email";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useIntl } from "react-intl";

const QuickActionsPanel = (props: any) => {
  const { icon, useSVG, bgImage, iconType, gridNavSkin } = props;
  const { user } = useSelector(({ auth }: RootState) => auth);
  const history = useHistory();
  const intl = useIntl();

  return (
    <Dropdown className="kt-header__topbar-item" drop="down" alignRight>
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-quick-actions-panel-toggle"
      >
        <span
          className={clsx(`kt-header__topbar-icon`, {
            "kt-header__topbar-icon--warning": iconType === "warning",
          })}
        >
          {!useSVG && <i className={icon} />}

          {useSVG && (
            <span
              className={clsx("kt-svg-icon", {
                "kt-svg-icon--warning": iconType === "warning",
              })}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33332 2H7.83332C9.67426 2 11.1666 3.49238 11.1666 5.33332V7.83332C11.1666 9.67426 9.67426 11.1666 7.83332 11.1666H5.33332C3.49238 11.1667 2 9.6743 2 7.83332V5.33332C2 3.49238 3.49238 2 5.33332 2Z"
                  fill="#0D99FF"
                />
                <path
                  d="M16.1667 2H18.6667C20.5076 2 22 3.49238 22 5.33332V7.83332C22 9.67426 20.5076 11.1666 18.6667 11.1666H16.1667C14.3258 11.1666 12.8334 9.67426 12.8334 7.83332V5.33332C12.8333 3.49238 14.3257 2 16.1667 2Z"
                  fill="#0D99FF"
                />
                <path
                  d="M5.33332 12.8333H7.83332C9.67426 12.8333 11.1666 14.3257 11.1666 16.1666V18.6666C11.1666 20.5076 9.67426 22 7.83332 22H5.33332C3.49238 22 2 20.5076 2 18.6667V16.1667C2 14.3257 3.49238 12.8333 5.33332 12.8333Z"
                  fill="#0D99FF"
                />
                <path
                  d="M16.1667 12.8333H18.6667C20.5076 12.8333 22 14.3257 22 16.1666V18.6666C22 20.5076 20.5076 22 18.6667 22H16.1667C14.3258 22 12.8334 20.5076 12.8334 18.6667V16.1667C12.8333 14.3257 14.3257 12.8333 16.1667 12.8333Z"
                  fill="#C3CDFF"
                />
              </svg>
            </span>
          )}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <form className="flex-column p-0">
          {/* begin: Head */}
          <div
            className={`kt-head kt-head--skin-${{ gridNavSkin }}`}
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <h3 className="kt-head__title text-white">
              {intl.formatMessage({
                id: "STANDARD.USERQUICKACTIONS",
                defaultMessage: "User Quick Actions",
              })}
              <span className="kt-space-15" />
              <button
                type="button"
                className="btn btn-success btn-sm btn-bold btn-font-md"
              >
                23{" "}
                {intl.formatMessage({
                  id: "STANDARD.TASKSPENDING",
                  defaultMessage: "tasks pending",
                })}
              </button>
            </h3>
          </div>
          {/* end: Head */}

          <div className={`kt-grid-nav kt-grid-nav--skin-${gridNavSkin}`}>
            {user?.role?.slug === "moderator" ||
            user?.role?.slug === "super-admin" ? (
              <>
                <div className="kt-grid-nav__row">
                  <a
                    className="kt-grid-nav__item"
                    onClick={() => history.push("/offers")}
                  >
                    <LocalOfferIcon
                      sx={{ color: "#3DCA81" }}
                      className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                    />
                    <span className="kt-grid-nav__title">
                      {intl.formatMessage({
                        id: "MENU.OFFERS",
                        defaultMessage: "Offers",
                      })}
                    </span>
                    <span className="kt-grid-nav__desc">
                      {intl.formatMessage({
                        id: "STANDARD.NEW",
                        defaultMessage: "New",
                      })}{" "}
                      {intl.formatMessage({
                        id: "MENU.OFFERS",
                        defaultMessage: "Offers",
                      })}
                    </span>
                  </a>

                  <a
                    className="kt-grid-nav__item"
                    onClick={() => history.push("/mailboxes")}
                  >
                    <div className="d-flex align-items-center flex-column">
                      <span className="kt-grid-nav__icon">
                        <EmailIcon
                          sx={{ color: "#3DCA81" }}
                          className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                        />
                      </span>
                      <span className="kt-grid-nav__title">
                        {intl.formatMessage({
                          id: "MENU.MAILBOX",
                          defaultMessage: "Mail",
                        })}
                      </span>
                      <span className="kt-grid-nav__desc">
                        {intl.formatMessage({
                          id: "STANDARD.CHECKOUT_MAILBOX",
                          defaultMessage: "Checkout mailbox",
                        })}
                      </span>
                    </div>
                  </a>
                </div>

                <div className="kt-grid-nav__row">
                  <a
                    className="kt-grid-nav__item"
                    onClick={() => history.push("/cases")}
                  >
                    <div className="d-flex align-items-center flex-column">
                      <span className="kt-grid-nav__icon">
                        <WorkIcon
                          sx={{ color: "#3DCA81" }}
                          className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                        />
                      </span>
                      <span className="kt-grid-nav__title">
                        {intl.formatMessage({
                          id: "PERMISSION.TITLE.CASES",
                          defaultMessage: "CASES",
                        })}
                      </span>
                      <span className="kt-grid-nav__desc">
                        {intl.formatMessage({
                          id: "ECOMMERCE.COMMON.ALL",
                          defaultMessage: "All",
                        })}{" "}
                        {intl.formatMessage({
                          id: "PERMISSION.TITLE.CASES",
                          defaultMessage: "CASES",
                        })}
                      </span>
                    </div>
                  </a>

                  <a
                    className="kt-grid-nav__item"
                    onClick={() => history.push("/contracts")}
                  >
                    <div className="d-flex align-items-center flex-column">
                      <span className="kt-grid-nav__icon">
                        <ListAltIcon
                          sx={{ color: "#3DCA81" }}
                          className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                        />
                      </span>
                      <span className="kt-grid-nav__title">
                        {intl.formatMessage({
                          id: "MENU.CONTRACTS",
                          defaultMessage: "Contracts",
                        })}
                      </span>
                      <span className="kt-grid-nav__desc">
                        {intl.formatMessage({
                          id: "STANDARD.LATEST",
                          defaultMessage: "Latest",
                        })}{" "}
                        {intl.formatMessage({
                          id: "MENU.CONTRACTS",
                          defaultMessage: "Contracts",
                        })}
                      </span>
                    </div>
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className="kt-grid-nav__row">
                  <a
                    className="kt-grid-nav__item"
                    onClick={() => history.push("/cases/dashboard")}
                  >
                    <BarChartIcon
                      sx={{ color: "#3DCA81" }}
                      className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                    />
                    <span className="kt-grid-nav__title">
                      {intl.formatMessage({
                        id: "HOME.LASTCASESTATISTICS",
                        defaultMessage: "Case statistics",
                      })}
                    </span>
                    <span className="kt-grid-nav__desc">
                      {intl.formatMessage({
                        id: "STANDARD.YOURCASEOVERVIEW",
                        defaultMessage: "Your case overview",
                      })}
                    </span>
                  </a>

                  <a
                    className="kt-grid-nav__item"
                    onClick={() => history.push("/chats")}
                  >
                    <div className="d-flex align-items-center flex-column">
                      <span className="kt-grid-nav__icon">
                        <MarkUnreadChatAltIcon
                          sx={{ color: "#3DCA81" }}
                          className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                        />
                      </span>
                      <span className="kt-grid-nav__title">
                        {intl.formatMessage({
                          id: "MENU.CHATS",
                          defaultMessage: "Chats",
                        })}
                      </span>
                      <span className="kt-grid-nav__desc">
                        <span className="kt-grid-nav__desc">
                          {intl.formatMessage({
                            id: "STANDARD.CHECKOUTYOURMESSAGES",
                            defaultMessage: "Checkout your messages",
                          })}
                        </span>
                      </span>
                    </div>
                  </a>
                </div>

                <div className="kt-grid-nav__row">
                  <a
                    className="kt-grid-nav__item"
                    onClick={() => history.push("/cases")}
                  >
                    <div className="d-flex align-items-center flex-column">
                      <span className="kt-grid-nav__icon">
                        <WorkIcon
                          sx={{ color: "#3DCA81" }}
                          className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                        />
                      </span>
                      <span className="kt-grid-nav__title">
                        {intl.formatMessage({
                          id: "PERMISSION.TITLE.CASES",
                          defaultMessage: "CASES",
                        })}
                      </span>
                      <span className="kt-grid-nav__desc">
                        {intl.formatMessage({
                          id: "STANDARD.YOUR_CASES",
                          defaultMessage: "Your cases",
                        })}
                      </span>
                    </div>
                  </a>

                  <a
                    className="kt-grid-nav__item"
                    onClick={() => history.push("/reports")}
                  >
                    <div className="d-flex align-items-center flex-column">
                      <span className="kt-grid-nav__icon">
                        <FlagIcon
                          sx={{ color: "#3DCA81" }}
                          className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                        />
                      </span>
                      <span className="kt-grid-nav__title">
                        {" "}
                        {intl.formatMessage({
                          id: "MENU.REPORTS",
                          defaultMessage: "Reports",
                        })}
                      </span>
                      <span className="kt-grid-nav__desc">
                        {intl.formatMessage({
                          id: "STANDARD.LATEST_REPORTS",
                          defaultMessage: "Latest reports",
                        })}
                      </span>
                    </div>
                  </a>
                </div>
              </>
            )}
          </div>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default QuickActionsPanel;
