import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core";
import { activitiesService } from "../../services";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { getDate } from "../../pages/Chat/rooms/rooms";
import reactStringReplace from "react-string-replace";

const useStyle = makeStyles(() => ({
  listItem: {
    "& .MuiListItemButton-root": {
      "&:hover": {
        backgroundColor: "#fff!important",
      },
      "&:active": {
        backgroundColor: "#fff!important",
      },
    },
  },
}));

function Notifications() {
  const classes = useStyle();
  const intl = useIntl();
  const history = useHistory();
  const [activities, setActivities] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      activitiesService
        .getAll([
          {
            name: "per_page",
            value: "5",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }
          const { data: activities } = data;

          setActivities(activities);
        });

      activitiesService.readAll().then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        setCount(0);
      });
    }
  }, [open]);

  useEffect(() => {
    const interval = setInterval(() => {
      activitiesService.count().then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        const { data: count } = data;

        setCount(count.count);
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const Title = (replacement: any, index: number) => {
    if (replacement.is_link) {
      switch (replacement.type) {
        case "case":
          console.log("replacement.name", replacement.name);
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/cases/${replacement.id}`}
            >
              {replacement.name || "Case Name"}
            </Link>
          );
        case "plan":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/cases/${replacement.case}?section=plan&plan=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "transaction":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/cases/${replacement.case}?section=finance`}
            >
              {replacement.name}
            </Link>
          );
        case "contract":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/contracts?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "project":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/projects?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "task":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/tasks?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "todo":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/todos?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "incoming_offer":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/incoming-offers`}
            >
              {replacement.name}
            </Link>
          );
        case "note":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/notes`}
            >
              {replacement.name}
            </Link>
          );
        case "who":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/users/${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "column":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/kanban?project=${replacement.project}`}
            >
              {replacement.name}
            </Link>
          );
        default:
          return `Unexpected type: ${replacement.type}`;
      }
    } else {
      return replacement.name;
    }
  };

  const getReplaceDescriptions = (activity: any) => {
    let text = intl.formatMessage({ id: activity.lang_key });
    let replacements = activity.replacements;

    if (text) {
      replacements.forEach((replacement: any, index: number) => {
        // @ts-ignore
        text = reactStringReplace(text, `{${replacement.key}}`, () =>
          Title(replacement, index)
        );
      });
    }

    return text;
  };

  return (
    <Dropdown
      className="kt-header__topbar-item"
      drop="down"
      alignRight
      onToggle={(isOpen: boolean) => {
        if (!isOpen) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }}
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-quick-actions-panel-toggle"
      >
        <span
          className={clsx(
            `kt-header__topbar-icon`,
            "kt-header__topbar-icon--warning"
          )}
        >
          {count > 0 ? (
            <Badge badgeContent={count} color="error">
              <span className={clsx("kt-svg-icon", "kt-svg-icon--warning")}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.49295 17H18.5063C18.9068 17 19.3014 16.9033 19.6568 16.7181C20.0121 16.5328 20.3177 16.2645 20.5477 15.9358C20.7777 15.6071 20.9254 15.2277 20.9782 14.8297C21.031 14.4316 20.9874 14.0267 20.8511 13.6492L18.4805 7.07833C18.0757 5.61809 17.2049 4.33099 16.0013 3.41389C14.7977 2.49679 13.3277 2.00018 11.8159 2V2C10.2422 1.99998 8.71558 2.53799 7.48802 3.52521C6.26047 4.51244 5.40542 5.88984 5.06405 7.43L3.11072 13.7633C2.99571 14.1373 2.96986 14.533 3.03523 14.9188C3.10061 15.3045 3.25539 15.6695 3.48713 15.9844C3.71887 16.2992 4.02108 16.5551 4.36946 16.7315C4.71784 16.9079 5.10266 16.9999 5.49295 17V17Z"
                    fill="#0D99FF"
                  />
                  <path
                    d="M8 19C8.18736 19.8467 8.68794 20.6078 9.41693 21.1546C10.1459 21.7013 11.0585 22 12 22C12.9415 22 13.8541 21.7013 14.5831 21.1546C15.3121 20.6078 15.8126 19.8467 16 19H8Z"
                    fill="#C3CDFF"
                  />
                </svg>
              </span>
            </Badge>
          ) : (
            <span className={clsx("kt-svg-icon", "kt-svg-icon--warning")}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.49295 17H18.5063C18.9068 17 19.3014 16.9033 19.6568 16.7181C20.0121 16.5328 20.3177 16.2645 20.5477 15.9358C20.7777 15.6071 20.9254 15.2277 20.9782 14.8297C21.031 14.4316 20.9874 14.0267 20.8511 13.6492L18.4805 7.07833C18.0757 5.61809 17.2049 4.33099 16.0013 3.41389C14.7977 2.49679 13.3277 2.00018 11.8159 2V2C10.2422 1.99998 8.71558 2.53799 7.48802 3.52521C6.26047 4.51244 5.40542 5.88984 5.06405 7.43L3.11072 13.7633C2.99571 14.1373 2.96986 14.533 3.03523 14.9188C3.10061 15.3045 3.25539 15.6695 3.48713 15.9844C3.71887 16.2992 4.02108 16.5551 4.36946 16.7315C4.71784 16.9079 5.10266 16.9999 5.49295 17V17Z"
                  fill="#0D99FF"
                />
                <path
                  d="M8 19C8.18736 19.8467 8.68794 20.6078 9.41693 21.1546C10.1459 21.7013 11.0585 22 12 22C12.9415 22 13.8541 21.7013 14.5831 21.1546C15.3121 20.6078 15.8126 19.8467 16 19H8Z"
                  fill="#C3CDFF"
                />
              </svg>
            </span>
          )}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #E6E6E6",
          }}
        >
          <Typography
            sx={{ fontWeight: 500, fontSize: "16px" }}
            variant={"body1"}
          >
            {intl.formatMessage({
              id: "STANDARD.NOTIFICATIONS",
              defaultMessage: "Notifications",
            })}
          </Typography>
        </Box>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              width: "2px",
              height: "92%",
              backgroundColor: "#E5EAEE",
              position: "absolute",
              zIndex: 990,
              ml: 4,
              mt: 2.5,
            }}
          />
          <Box>
            <List
              sx={{
                width: "100%",
                padding: "0px",
                bgcolor: "background.paper",
              }}
            >
              {activities?.map((activity: any) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    alignItems="flex-start"
                    sx={{ padding: "0px" }}
                  >
                    <ListItemButton
                      disableRipple={true}
                      disableTouchRipple={true}
                    >
                      <ListItemAvatar
                        sx={{
                          minWidth: "42px",
                          zIndex: 999,
                          padding: "8px 0px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "#0D99FF",
                            width: 32,
                            height: 32,
                          }}
                          src={activity.avatar ? activity.avatar : ""}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ mt: "10px!important" }}
                        primary={
                          <Box sx={{ display: "flex" }}>
                            <Box component="div" sx={{ flex: 1 }}>
                              {getReplaceDescriptions(activity)}
                            </Box>
                            {/*<Box>*/}
                            {/*  <CircleIcon*/}
                            {/*    sx={{ width: 6, height: 6, color: "#0D99FF" }}*/}
                            {/*  />*/}
                            {/*</Box>*/}
                          </Box>
                        }
                        secondary={
                          <React.Fragment>
                            {getDate(activity._created_at)}
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: "1px solid #E6E6E6",
          }}
        >
          <Button
            sx={{
              p: 2,
              width: "100%",
              color: "#0D99FF",
              fontWeight: 500,
              fontSize: 14,
            }}
            onClick={() => {
              history.push("/notifications");
            }}
            variant="text"
          >
            {intl.formatMessage({
              id: "STANDARD.SHOWALL",
              defaultMessage: "Show all",
            })}
          </Button>
        </Box>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Notifications;
