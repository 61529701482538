import {
  IUrlQuery,
  IUser,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { INotification } from "./notification.service";
import { INote } from "./note.service";
import {
  ICase,
  ICaseDocumentFolderStore,
  ICaseTimetrackFeedback,
} from "./case.service";
import { IContract } from "./contractTemplate.service";
import { IDocument } from "./document.service";
import { ISetting } from "./setting.service";
import { ITodo } from "./todo.service";
import { ITimeTrack } from "./timeTrack.service";

const RECOMMENDATIONS = API_ROUTES.recommendations;

const SIBLINGS = API_ROUTES.siblings;
const DELETE_SIBLING = API_ROUTES.sibling;

const INDEX = API_ROUTES.users;
const MIN_LIST = API_ROUTES.users_minlist;
const SHOW = API_ROUTES.user;
const UPDATE = API_ROUTES.user;
const ADD_DELETE_REASON = API_ROUTES.user_reason;
const DELETE = API_ROUTES.user;
const RESTORE = API_ROUTES.user_restore;
const RESTORE_CONTRACT = API_ROUTES.user_contract_restore;
const CONTRACT_NOTES = API_ROUTES.user_contract_notes;
const CONTRACT_NOTE = API_ROUTES.user_contract_note;
const STORE = API_ROUTES.users;
const CHANGE_PASSWORD = API_ROUTES.user_change_password;
const NOTIFICATION_INDEX = API_ROUTES.user_notifications;
const SWITCH_USER = API_ROUTES.switchUser;

const USER_TODOS = API_ROUTES.user_todos;

const USER_CASE = API_ROUTES.user_cases;
const USER_CASE_DELETE = API_ROUTES.user_case;

const USER_CONTRACTS = API_ROUTES.user_contracts;
const USER_CONTRACT_STORE = API_ROUTES.user_contracts;
const USER_CONTRACT_DELETE = API_ROUTES.user_contract;
const USER_CONTRACTS_ARCHIVE = API_ROUTES.user_contracts_archive;
const USER_CONTRACTS_SIGN = API_ROUTES.user_contract_sign;

const USER_DOCUMENT_FOLDERS = API_ROUTES.user_document_folders;
const USER_DOCUMENT_FOLDER = API_ROUTES.user_document_folder;

const USER_PENALTIES = API_ROUTES.user_penalties;
const USER_PENALTY = API_ROUTES.user_penalty;

const USER_PENALTY_DOCS = API_ROUTES.user_penalty_docs;

const USER_NOTES = API_ROUTES.user_notes;
const USER_NOTE = API_ROUTES.user_note;
const USER_NOTE_STORE = API_ROUTES.user_notes;

const QUICK_CARDS_COUNT = API_ROUTES.users_count;
const QUICK_CARDS_CONTACT_COUNT = API_ROUTES.user_contracts_count;

const USER_UPLOAD_PHOTO = API_ROUTES.user_upload;

const TIMETRACK_INDEX = API_ROUTES.user_timeTracks;
const TIMETRACK_UPDATE = API_ROUTES.user_timeTrack;
const FEEDBACK_INDEX = API_ROUTES.user_feedbacks;

const TIMETRACK_SUM_YEAR = API_ROUTES.user_timeTrack_sum_year;
const TIMETRACK_SUM_WEEK = API_ROUTES.user_timeTrack_sum_week;
const TIMETRACK_SUM_MONTH = API_ROUTES.user_timeTrack_sum_month;

const FINANCE_INDEX = API_ROUTES.user_finances;
const FINANCE_STORE = API_ROUTES.user_finances;
const FINANCE_SHOW = API_ROUTES.user_finance;
const FINANCE_UPDATE = API_ROUTES.user_finance;
const FINANCE_DELETE = API_ROUTES.user_finance;
const FINANCE_EXPORT = API_ROUTES.user_finance_export;
const FINANCES_UPDATE_STATUS = API_ROUTES.user_finances_status_update;

export interface IUserDocumentFolder {
  _id: string;
  title: string;
  category: ISetting;
  documents: Array<IDocument>;

  _created_at: string;
  _updated_at: string;
}

export interface IUserPenalty {
  _id: string;
  user: IUser;
  penalty_option1: boolean;
  penalty_option2: boolean;
  penalty_option3: boolean;
  penalty_option4: boolean;
  created_by: IUser;
  documents: Array<IDocument>;

  _created_at: string;
  _updated_at: string;
}

export interface IUserPenaltyStore {
  penalty_option1: boolean;
  penalty_option2: boolean;
  penalty_option3: boolean;
  penalty_option4: boolean;
}

export interface IUserQuickCardsCount {
  data: {
    former: number;
    active_employees: number;
    active_citizens: number;
    active_partners: number;
  };
}

export interface IUserService {
  getSiblings(
    userId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IUser> | IRestApiError>;
  deleteSibling(
    userId: string,
    _id: string
  ): Promise<IRestApiCollection<IUser> | IRestApiError>;
  getRecommendations(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IUser> | IRestApiError>;
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IUser> | IRestApiError>;
  getMinlist(
    queries?: Array<IUrlQuery>
  ): Promise<{ data: any } | IRestApiError>;
  getQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<IUserQuickCardsCount | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IUser> | IRestApiError>;
  update(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IUser> | IRestApiError>;
  addDeleteReason(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IUser> | IRestApiError>;
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>;
  switchUser(data: any): Promise<any>;
  delete(_id: string): Promise<IRestApiCollection<IUser> | IRestApiError>;
  restore(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IUser> | IRestApiError>;
  changePassword(data: any): Promise<IRestApiResource<IUser> | IRestApiError>;
  getNotifications(
    userId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INotification> | IRestApiError>;
  getTodos(
    userId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITodo> | IRestApiError>;
  getCases(
    userId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ICase> | IRestApiError>;
  deleteCase(
    userId: string,
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ICase> | IRestApiError>;
  getContracts(
    userId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IContract> | IRestApiError>;
  addContract(
    userId: string,
    data: IContract
  ): Promise<IRestApiResource<IContract> | IRestApiError>;
  signContract(
    userId: string,
    _id: string
  ): Promise<IRestApiResource<IContract> | IRestApiError>;
  deleteContract(
    userId: string,
    _id: string
  ): Promise<IRestApiCollection<IContract> | IRestApiError>;
  archiveContracts(
    userId: string,
    data: { ids: string[] }
  ): Promise<IRestApiCollection<IContract> | IRestApiError>;
  getContractQuickCardsCount(
    userId: string,
    query?: Array<IUrlQuery>
  ): Promise<{ data: { archived: number } } | IRestApiError>;
  restoreContract(
    userId: string,
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IContract> | IRestApiError>;
  getContractNotes(
    userId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<INote> | IRestApiError>;
  postContractNotes(
    userId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<INote> | IRestApiError>;
  updateContractNote(
    userId: string,
    contract: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INote> | IRestApiError>;
  deleteContractNote(
    userId: string,
    contract: string,
    _id: string
  ): Promise<IRestApiCollection<INote> | IRestApiError>;
  getNotes(
    userId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<INote> | IRestApiError>;
  getNote(
    userId: string,
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INote> | IRestApiError>;
  updateNote(
    userId: string,
    _id: string,
    data: INote
  ): Promise<IRestApiResource<INote> | IRestApiError>;
  addNote(
    userId: string,
    data: INote
  ): Promise<IRestApiResource<INote> | IRestApiError>;
  deleteNote(
    userId: string,
    _id: string
  ): Promise<IRestApiCollection<INote> | IRestApiError>;
  getDocFolders(
    userId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IUserDocumentFolder> | IRestApiError>;
  postDocFolder(
    _id: string,
    data: ICaseDocumentFolderStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IUserDocumentFolder> | IRestApiError>;
  getDocFolder(
    userId: string,
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IUserDocumentFolder> | IRestApiError>;
  updateDocFolder(
    userId: string,
    _id: string,
    data: ICaseDocumentFolderStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IUserDocumentFolder> | IRestApiError>;
  deleteDocFolder(
    userId: string,
    _id: string
  ): Promise<IRestApiCollection<IUserDocumentFolder> | IRestApiError>;
  getPenalties(
    userId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IUserPenalty> | IRestApiError>;
  getPenalty(
    userId: string,
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IUserPenalty> | IRestApiError>;
  updatePenalty(
    userId: string,
    _id: string,
    data: any,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IUserPenalty> | IRestApiError>;
  postPenalty(
    userId: string,
    data: IUserPenaltyStore
  ): Promise<IRestApiResource<IUserPenalty> | IRestApiError>;
  getPenaltyDocs(
    userId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IDocument> | IRestApiError>;
  postPenaltyDocs(
    _id: string,
    data: any,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IUser> | IRestApiError>;
  uploadPhoto(
    userId: string,
    file: string
  ): Promise<IRestApiResource<IUser> | IRestApiError>;

  // time track
  getTimeTracks(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITimeTrack> | IRestApiError>;

  postTimeTrackFeedback(
    userId: string,
    data: ICaseTimetrackFeedback,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  getTimeTracksSumWeek(
    id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<{ spent: number }> | IRestApiError>;

  getTimeTracksSumYear(
    id: string,
    query?: Array<IUrlQuery>
  ): Promise<
    IRestApiResource<{ spent: number; total: number }[]> | IRestApiError
  >;
  getTimeTracksSumMonth(
    id: string,
    query?: Array<IUrlQuery>
  ): Promise<
    | IRestApiResource<
        {
          [key: string]: {
            hour: number;
            color: string;
          };
        }[]
      >
    | IRestApiError
  >;

  postTimeTrack(
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  updateTimeTrack(
    user: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ITimeTrack> | IRestApiError>;

  deleteTimeTrack(
    user: string,
    _id: string
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  // finance
  getFinances(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  postFinance(
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  updateFinancesStatus(
    data: { ids: string[]; status: string },
    query?: Array<IUrlQuery>
  ): Promise<{ status: string } | IRestApiError>;

  getOneFinance(
    userId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  updateFinance(
    caseId: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  deleteFinance(
    caseId: string,
    _id: string
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  exportFinance(
    caseId: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
}

const userService: IUserService = {
  async getSiblings(userId: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(SIBLINGS).replace(
        ":_id",
        userId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async deleteSibling(userId: string, _id: string) {
    return http(
      `${getRoute(DELETE_SIBLING)
        .replace(":_id", userId.toString())
        .replace(":sibling", _id.toString())}`,
      "delete"
    );
  },

  async getRecommendations(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(RECOMMENDATIONS)}${queriesString}`, "get");
  },

  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getMinlist(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(MIN_LIST)}${queriesString}`, "get");
  },

  async getQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(QUICK_CARDS_COUNT)}${queryString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async addDeleteReason(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(ADD_DELETE_REASON).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async add(data: { password: string }) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

  async switchUser(data: { _id: string }) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(SWITCH_USER), "post", formData);
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async restore(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(RESTORE.replace(":_id", _id.toString()))}${queriesString}`,
      "get"
    );
  },

  async changePassword(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(CHANGE_PASSWORD), "post", formData);
  },

  async getNotifications(userId: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTIFICATION_INDEX).replace(
        ":userId",
        userId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async getCases(userId: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_CASE).replace(
        ":userId",
        userId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async getTodos(userId: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_TODOS).replace(
        ":userId",
        userId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async deleteCase(userId: string, _id: string) {
    return http(
      `${getRoute(USER_CASE_DELETE)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}`,
      "delete"
    );
  },

  async getContracts(userId: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_CONTRACTS).replace(
        ":userId",
        userId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async addContract(userId: string, data: IContract) {
    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(USER_CONTRACT_STORE).replace(":userId", userId.toString())}`,
      "post",
      formData
    );
  },

  async signContract(userId: string, id: string) {
    return http(
      `${getRoute(USER_CONTRACTS_SIGN)
        .replace(":userId", userId.toString())
        .replace(":_id", id.toString())}`,
      "post"
    );
  },

  async deleteContract(userId: string, _id: string) {
    return http(
      `${getRoute(USER_CONTRACT_DELETE)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}`,
      "delete"
    );
  },

  async archiveContracts(userId: string, data: { ids: string[] }) {
    let formData = generateFormData(new FormData(), data);

    return http(
      `${getRoute(USER_CONTRACTS_ARCHIVE).replace(
        ":userId",
        userId.toString()
      )}`,
      "post",
      formData
    );
  },

  async getContractQuickCardsCount(userId: string, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(QUICK_CARDS_CONTACT_COUNT).replace(
        ":userId",
        userId.toString()
      )}${queryString}`,
      "get"
    );
  },

  async restoreContract(
    userId: string,
    _id: string,
    queries?: Array<IUrlQuery>
  ) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(RESTORE_CONTRACT)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queriesString}`,
      "get"
    );
  },

  async getContractNotes(userId, _id, data, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(CONTRACT_NOTES)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async postContractNotes(userId, _id, data, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(CONTRACT_NOTES)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async updateContractNote(
    userId,
    contract,
    _id,
    data,
    query?: Array<IUrlQuery>
  ) {
    let queryString = query ? `?${queryToString(query)}` : "";
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(CONTRACT_NOTE)
        .replace(":userId", userId.toString())
        .replace(":contract", contract.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async deleteContractNote(userId, contract, _id, query?: Array<IUrlQuery>) {
    return http(
      getRoute(
        CONTRACT_NOTE.replace(":userId", userId.toString())
          .replace(":contract", contract.toString())
          .replace(":_id", _id.toString())
      ),
      "delete"
    );
  },

  async getNotes(userId: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_NOTES).replace(
        ":userId",
        userId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async getNote(userId: string, _id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_NOTE)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queriesString}`,
      "get"
    );
  },

  async updateNote(userId, _id, data: INote) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(USER_NOTE)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async addNote(userId: string, data: INote) {
    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(USER_NOTE_STORE).replace(":userId", userId.toString())}`,
      "post",
      formData
    );
  },

  async deleteNote(userId: string, _id: string) {
    return http(
      `${getRoute(USER_NOTE)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}`,
      "delete"
    );
  },

  async getDocFolders(userId: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_DOCUMENT_FOLDERS).replace(
        ":userId",
        userId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async postDocFolder(
    _id: string,
    data: ICaseDocumentFolderStore,
    query?: Array<IUrlQuery>
  ) {
    let formData = generateFormData(new FormData(), data);

    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(USER_DOCUMENT_FOLDERS).replace(
        ":userId",
        _id.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  async getDocFolder(userId: string, _id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_DOCUMENT_FOLDER)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queriesString}`,
      "get"
    );
  },

  async deleteDocFolder(userId: string, _id: string) {
    return http(
      `${getRoute(USER_DOCUMENT_FOLDER)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}`,
      "delete"
    );
  },

  async updateDocFolder(
    userId: string,
    _id: string,
    data: ICaseDocumentFolderStore,
    query?: Array<IUrlQuery>
  ) {
    /**
     * TODO _method PUT is Laravel put content bug. check google for it
     */
    let queryString = query
      ? `?${queryToString([
          ...query,
          {
            name: "_method",
            value: "PUT",
          },
        ])}`
      : "?_method=PUT";

    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(USER_DOCUMENT_FOLDER)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async getPenalties(userId: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_PENALTIES).replace(
        ":userId",
        userId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async getPenalty(userId: string, _id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_PENALTY)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queriesString}`,
      "get"
    );
  },

  async postPenalty(userId: string, data: IUserPenaltyStore) {
    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(USER_PENALTIES).replace(":userId", userId.toString())}`,
      "post",
      formData
    );
  },

  async getPenaltyDocs(userId: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USER_PENALTY_DOCS).replace(
        ":userId",
        userId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async updatePenalty(
    userId: string,
    _id: string,
    data: any,
    queries?: Array<IUrlQuery>
  ) {
    let queriesString = queries
      ? `?${queryToString([
          ...queries,
          {
            name: "_method",
            value: "PUT",
          },
        ])}`
      : "?_method=PUT";
    let formData = generateFormData(new FormData(), data);

    return http(
      `${getRoute(USER_PENALTY)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queriesString}`,
      "post",
      formData
    );
  },

  async postPenaltyDocs(_id, data: any, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(USER_PENALTY_DOCS).replace(
        ":userId",
        _id.toString()
      )}${queriesString}`,
      "post",
      formData
    );
  },

  async uploadPhoto(userId: string, file: string) {
    let formData = generateFormData(new FormData(), { file });

    return http(
      `${getRoute(USER_UPLOAD_PHOTO).replace(":userId", userId.toString())}`,
      "post",
      formData
    );
  },

  // time track
  async getTimeTracks(_id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_INDEX) //TODO feedback api isnt ready yet.
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async postTimeTrackFeedback(
    caseId: string,
    data: ICaseTimetrackFeedback,
    query?: Array<IUrlQuery>
  ) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FEEDBACK_INDEX).replace(
        ":_id",
        caseId.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  async getTimeTracksSumWeek(id: string, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_SUM_WEEK).replace(
        ":id",
        id.toString()
      )}${queryString}`,
      "get"
    );
  },

  async getTimeTracksSumMonth(id: string, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_SUM_MONTH).replace(
        ":id",
        id.toString()
      )}${queryString}`,
      "get"
    );
  },

  async getTimeTracksSumYear(id: string, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_SUM_YEAR).replace(
        ":id",
        id.toString()
      )}${queryString}`,
      "get"
    );
  },

  async postTimeTrack(_id, data, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_INDEX).replace(
        ":_id",
        _id.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  async updateTimeTrack(user, _id, data, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(TIMETRACK_UPDATE)
        .replace(":user", user.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async deleteTimeTrack(user, _id) {
    return http(
      getRoute(
        TIMETRACK_UPDATE.replace(":user", user.toString()).replace(
          ":_id",
          _id.toString()
        )
      ),
      "delete"
    );
  },

  // finance
  async getFinances(_id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCE_INDEX).replace(
        ":_id",
        _id.toString()
      )}${queryString}`,
      "get"
    );
  },

  async postFinance(_id, data: any, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCE_STORE).replace(
        ":_id",
        _id.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  async updateFinancesStatus(
    data: { ids: string[]; status: string },
    query?: Array<IUrlQuery>
  ) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCES_UPDATE_STATUS)}${queryString}`,
      "post",
      formData
    );
  },

  async getOneFinance(userId, _id, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCE_SHOW)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updateFinance(userId, _id, data: any, query?: Array<IUrlQuery>) {
    /**
     * TODO _method PUT is Laravel put content bug. check google for it
     */
    let queryString = query
      ? `?${queryToString([
          ...query,
          {
            name: "_method",
            value: "PUT",
          },
        ])}`
      : "?_method=PUT";

    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(FINANCE_UPDATE)
        .replace(":userId", userId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },
  async deleteFinance(userId, _id) {
    return http(
      getRoute(
        FINANCE_DELETE.replace(":userId", userId.toString()).replace(
          ":_id",
          _id.toString()
        )
      ),
      "delete"
    );
  },

  async exportFinance(userId, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCE_EXPORT).replace(
        ":userId",
        userId.toString()
      )}${queryString}`,
      "get"
    );
  },
};

export { userService };
