import { Grid, Typography } from "@mui/material";
import React from "react";

type IProps = {
  height?: number;
};

const NoData = (props: IProps) => {
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: props.height,
        pb: 2,
        gap: 2,
        flexDirection: "column",
      }}
    >
      <svg
        width="154"
        height="100"
        viewBox="0 0 154 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2545_34292)">
          <path
            opacity="0.47"
            d="M152.74 71.1863H125.771C125.073 71.1863 124.51 70.6313 124.51 69.9437V40.9671C124.51 40.2795 125.073 39.7244 125.771 39.7244H152.74C153.437 39.7244 154 40.2795 154 40.9671V69.9437C154 70.6313 153.434 71.1863 152.74 71.1863Z"
            fill="white"
          />
          <path
            d="M147.386 45.623H131.121C130.819 45.623 130.575 45.3827 130.575 45.0845V42.8532C130.575 42.5549 130.819 42.3147 131.121 42.3147H147.386C147.689 42.3147 147.933 42.5549 147.933 42.8532V45.0845C147.933 45.3827 147.689 45.623 147.386 45.623Z"
            fill="white"
          />
          <path
            d="M147.386 48.1857H131.121C130.819 48.1857 130.575 47.9454 130.575 47.6472V47.6279C130.575 47.3296 130.819 47.0894 131.121 47.0894H147.386C147.689 47.0894 147.933 47.3296 147.933 47.6279V47.6472C147.933 47.9454 147.689 48.1857 147.386 48.1857Z"
            fill="white"
          />
          <path
            d="M147.386 50.7152H131.121C130.819 50.7152 130.575 50.475 130.575 50.1767V50.1574C130.575 49.8592 130.819 49.6189 131.121 49.6189H147.386C147.689 49.6189 147.933 49.8592 147.933 50.1574V50.1767C147.933 50.4722 147.689 50.7152 147.386 50.7152Z"
            fill="white"
          />
          <path
            d="M131.696 54.4433C131.696 55.2054 131.068 55.824 130.295 55.824C129.522 55.824 128.895 55.2054 128.895 54.4433C128.895 53.6811 129.522 53.0625 130.295 53.0625C131.068 53.0625 131.696 53.6811 131.696 54.4433Z"
            fill="white"
          />
          <path
            d="M131.696 60.6981C131.696 61.4603 131.068 62.0789 130.295 62.0789C129.522 62.0789 128.895 61.4603 128.895 60.6981C128.895 59.9359 129.522 59.3174 130.295 59.3174C131.068 59.3174 131.696 59.9359 131.696 60.6981Z"
            fill="white"
          />
          <path
            d="M131.696 66.7707C131.696 67.5329 131.068 68.1514 130.295 68.1514C129.522 68.1514 128.895 67.5329 128.895 66.7707C128.895 66.0085 129.522 65.3899 130.295 65.3899C131.068 65.3899 131.696 66.0085 131.696 66.7707Z"
            fill="white"
          />
          <path
            d="M145.882 53.9075H132.489C132.41 53.9075 132.349 53.8468 132.349 53.7695C132.349 53.6921 132.41 53.6314 132.489 53.6314H145.882C145.961 53.6314 146.022 53.6921 146.022 53.7695C146.022 53.8468 145.961 53.9075 145.882 53.9075Z"
            fill="white"
          />
          <path
            d="M139.184 55.3131H132.486C132.407 55.3131 132.346 55.2524 132.346 55.1751C132.346 55.0977 132.407 55.037 132.486 55.037H139.184C139.262 55.037 139.324 55.0977 139.324 55.1751C139.324 55.2496 139.262 55.3131 139.184 55.3131Z"
            fill="white"
          />
          <path
            d="M145.882 60.3695H132.489C132.41 60.3695 132.349 60.3087 132.349 60.2314C132.349 60.1541 132.41 60.0933 132.489 60.0933H145.882C145.961 60.0933 146.022 60.1541 146.022 60.2314C146.022 60.306 145.961 60.3695 145.882 60.3695Z"
            fill="white"
          />
          <path
            d="M139.184 61.7723H132.486C132.407 61.7723 132.346 61.7116 132.346 61.6343C132.346 61.5569 132.407 61.4962 132.486 61.4962H139.184C139.262 61.4962 139.324 61.5569 139.324 61.6343C139.324 61.7116 139.262 61.7723 139.184 61.7723Z"
            fill="white"
          />
          <path
            d="M145.882 66.2681H132.489C132.41 66.2681 132.349 66.2073 132.349 66.13C132.349 66.0527 132.41 65.9919 132.489 65.9919H145.882C145.961 65.9919 146.022 66.0527 146.022 66.13C146.022 66.2073 145.961 66.2681 145.882 66.2681Z"
            fill="white"
          />
          <path
            d="M139.184 67.6737H132.486C132.407 67.6737 132.346 67.613 132.346 67.5356C132.346 67.4583 132.407 67.3976 132.486 67.3976H139.184C139.262 67.3976 139.324 67.4583 139.324 67.5356C139.324 67.6102 139.262 67.6737 139.184 67.6737Z"
            fill="white"
          />
          <path
            opacity="0.53"
            d="M92.7817 0H127.889C128.213 0 128.477 0.259582 128.477 0.579918V11.9601C128.477 12.2805 128.213 12.54 127.889 12.54H102.6L96.8072 18.2536V12.5428H92.7817C92.4567 12.5428 92.1934 12.2832 92.1934 11.9629V0.579918C92.1906 0.259582 92.4567 0 92.7817 0Z"
            fill="#4C65BA"
          />
          <path
            d="M97.8298 3.80538H122.773C122.93 3.80538 123.056 3.72806 123.056 3.6314C123.056 3.53475 122.93 3.45743 122.773 3.45743H97.8298C97.6729 3.45743 97.5469 3.53475 97.5469 3.6314C97.5469 3.72806 97.6729 3.80538 97.8298 3.80538Z"
            fill="white"
          />
          <path
            d="M97.8298 9.61284H122.773C122.93 9.61284 123.056 9.53552 123.056 9.43887C123.056 9.34222 122.93 9.26489 122.773 9.26489H97.8298C97.6729 9.26489 97.5469 9.34222 97.5469 9.43887C97.5469 9.53552 97.6729 9.61284 97.8298 9.61284Z"
            fill="white"
          />
          <path
            d="M109.736 6.59176H122.776C122.933 6.59176 123.059 6.51443 123.059 6.41778C123.059 6.32113 122.933 6.2438 122.776 6.2438H109.736C109.579 6.2438 109.453 6.32113 109.453 6.41778C109.453 6.51443 109.579 6.59176 109.736 6.59176Z"
            fill="white"
          />
          <path
            d="M101.609 6.59176H107.279C107.436 6.59176 107.562 6.51443 107.562 6.41778C107.562 6.32113 107.436 6.2438 107.279 6.2438H101.609C101.452 6.2438 101.326 6.32113 101.326 6.41778C101.326 6.51443 101.452 6.59176 101.609 6.59176Z"
            fill="white"
          />
          <path
            opacity="0.47"
            d="M116.274 36.0295H134.021C134.813 36.0295 135.461 35.3916 135.461 34.6101V29.0539C135.461 28.2724 134.813 27.6345 134.021 27.6345H116.274C115.481 27.6345 114.834 28.2724 114.834 29.0539V34.6101C114.834 35.3916 115.484 36.0295 116.274 36.0295Z"
            fill="#599AF2"
          />
          <path
            d="M119.717 29.4101H140.892C141.531 29.4101 142.055 28.8937 142.055 28.2641V21.8684C142.055 21.2388 141.531 20.7224 140.892 20.7224H119.717C119.078 20.7224 118.554 21.2388 118.554 21.8684V28.2641C118.551 28.8937 119.075 29.4101 119.717 29.4101Z"
            fill="white"
          />
          <path
            opacity="0.74"
            d="M119.717 29.4101H140.892C141.531 29.4101 142.055 28.8937 142.055 28.2641V21.8684C142.055 21.2388 141.531 20.7224 140.892 20.7224H119.717C119.078 20.7224 118.554 21.2388 118.554 21.8684V28.2641C118.551 28.8937 119.075 29.4101 119.717 29.4101Z"
            fill="#EF929C"
          />
          <path
            d="M134.595 26.0742C134.027 26.0742 133.562 25.6186 133.562 25.0552C133.562 24.4946 134.024 24.0362 134.595 24.0362C135.164 24.0362 135.629 24.4919 135.629 25.0552C135.629 25.6158 135.164 26.0742 134.595 26.0742ZM134.595 24.3124C134.181 24.3124 133.842 24.6438 133.842 25.0552C133.842 25.4667 134.181 25.7981 134.595 25.7981C135.01 25.7981 135.349 25.4639 135.349 25.0552C135.349 24.6465 135.01 24.3124 134.595 24.3124Z"
            fill="white"
          />
          <path
            d="M130.382 26.0742C129.813 26.0742 129.348 25.6186 129.348 25.0552C129.348 24.4946 129.81 24.0362 130.382 24.0362C130.951 24.0362 131.416 24.4919 131.416 25.0552C131.416 25.6158 130.953 26.0742 130.382 26.0742ZM130.382 24.3124C129.967 24.3124 129.628 24.6438 129.628 25.0552C129.628 25.4667 129.967 25.7981 130.382 25.7981C130.796 25.7981 131.135 25.4639 131.135 25.0552C131.135 24.6465 130.796 24.3124 130.382 24.3124Z"
            fill="white"
          />
          <path
            d="M126.457 26.0742C125.888 26.0742 125.423 25.6186 125.423 25.0552C125.423 24.4946 125.886 24.0362 126.457 24.0362C127.026 24.0362 127.491 24.4919 127.491 25.0552C127.488 25.6158 127.026 26.0742 126.457 26.0742ZM126.457 24.3124C126.042 24.3124 125.703 24.6438 125.703 25.0552C125.703 25.4667 126.042 25.7981 126.457 25.7981C126.872 25.7981 127.211 25.4639 127.211 25.0552C127.211 24.6465 126.872 24.3124 126.457 24.3124Z"
            fill="white"
          />
          <path
            opacity="0.47"
            d="M35.059 82.6604H8.09021C7.39267 82.6604 6.82959 82.1054 6.82959 81.4178V52.4412C6.82959 51.7536 7.39267 51.1985 8.09021 51.1985H35.059C35.7566 51.1985 36.3197 51.7536 36.3197 52.4412V81.4178C36.3197 82.1054 35.7566 82.6604 35.059 82.6604Z"
            fill="white"
          />
          <path
            d="M29.7085 57.0971H13.4437C13.1412 57.0971 12.8975 56.8568 12.8975 56.5586V54.3273C12.8975 54.029 13.1412 53.7888 13.4437 53.7888H29.7085C30.0111 53.7888 30.2548 54.029 30.2548 54.3273V56.5586C30.2548 56.8568 30.0083 57.0971 29.7085 57.0971Z"
            fill="white"
          />
          <path
            d="M29.7085 59.6625H13.4437C13.1412 59.6625 12.8975 59.4223 12.8975 59.1241V59.1047C12.8975 58.8065 13.1412 58.5662 13.4437 58.5662H29.7085C30.0111 58.5662 30.2548 58.8065 30.2548 59.1047V59.1241C30.2548 59.4195 30.0083 59.6625 29.7085 59.6625Z"
            fill="white"
          />
          <path
            d="M29.7085 62.1893H13.4437C13.1412 62.1893 12.8975 61.9491 12.8975 61.6508V61.6315C12.8975 61.3332 13.1412 61.093 13.4437 61.093H29.7085C30.0111 61.093 30.2548 61.3332 30.2548 61.6315V61.6508C30.2548 61.9491 30.0083 62.1893 29.7085 62.1893Z"
            fill="white"
          />
          <path
            d="M14.0152 65.9174C14.0152 66.6796 13.3877 67.2981 12.6146 67.2981C11.8414 67.2981 11.2139 66.6796 11.2139 65.9174C11.2139 65.1552 11.8414 64.5366 12.6146 64.5366C13.3877 64.5366 14.0152 65.1552 14.0152 65.9174Z"
            fill="white"
          />
          <path
            d="M14.0152 72.1749C14.0152 72.9371 13.3877 73.5557 12.6146 73.5557C11.8414 73.5557 11.2139 72.9371 11.2139 72.1749C11.2139 71.4128 11.8414 70.7942 12.6146 70.7942C13.3877 70.7942 14.0152 71.4128 14.0152 72.1749Z"
            fill="white"
          />
          <path
            d="M14.0152 78.2448C14.0152 79.0069 13.3877 79.6255 12.6146 79.6255C11.8414 79.6255 11.2139 79.0069 11.2139 78.2448C11.2139 77.4826 11.8414 76.864 12.6146 76.864C13.3877 76.864 14.0152 77.4826 14.0152 78.2448Z"
            fill="white"
          />
          <path
            d="M28.2014 65.3816H14.808C14.7296 65.3816 14.668 65.3209 14.668 65.2435C14.668 65.1662 14.7296 65.1055 14.808 65.1055H28.2014C28.2799 65.1055 28.3415 65.1662 28.3415 65.2435C28.3415 65.3209 28.2799 65.3816 28.2014 65.3816Z"
            fill="white"
          />
          <path
            d="M21.5061 66.7873H14.808C14.7296 66.7873 14.668 66.7265 14.668 66.6492C14.668 66.5719 14.7296 66.5111 14.808 66.5111H21.5061C21.5846 66.5111 21.6462 66.5719 21.6462 66.6492C21.6462 66.7265 21.5846 66.7873 21.5061 66.7873Z"
            fill="white"
          />
          <path
            d="M28.2014 71.8436H14.808C14.7296 71.8436 14.668 71.7828 14.668 71.7055C14.668 71.6282 14.7296 71.5674 14.808 71.5674H28.2014C28.2799 71.5674 28.3415 71.6282 28.3415 71.7055C28.3415 71.7828 28.2799 71.8436 28.2014 71.8436Z"
            fill="white"
          />
          <path
            d="M21.5061 73.2492H14.808C14.7296 73.2492 14.668 73.1885 14.668 73.1111C14.668 73.0338 14.7296 72.9731 14.808 72.9731H21.5061C21.5846 72.9731 21.6462 73.0338 21.6462 73.1111C21.6462 73.1885 21.5846 73.2492 21.5061 73.2492Z"
            fill="white"
          />
          <path
            d="M28.2014 77.7422H14.808C14.7296 77.7422 14.668 77.6814 14.668 77.6041C14.668 77.5268 14.7296 77.466 14.808 77.466H28.2014C28.2799 77.466 28.3415 77.5268 28.3415 77.6041C28.3415 77.6814 28.2799 77.7422 28.2014 77.7422Z"
            fill="white"
          />
          <path
            d="M21.5061 79.1478H14.808C14.7296 79.1478 14.668 79.087 14.668 79.0097C14.668 78.9324 14.7296 78.8716 14.808 78.8716H21.5061C21.5846 78.8716 21.6462 78.9324 21.6462 79.0097C21.6462 79.087 21.5846 79.1478 21.5061 79.1478Z"
            fill="white"
          />
          <path
            opacity="0.79"
            d="M23.6212 36.0571H0.389391C0.173685 36.0571 0 36.2283 0 36.4409V43.9716C0 44.1842 0.173685 44.3554 0.389391 44.3554H17.1248L20.9599 48.136V44.3554H23.624C23.8397 44.3554 24.0134 44.1842 24.0134 43.9716V36.4409C24.0134 36.2311 23.8369 36.0571 23.6212 36.0571Z"
            fill="#3E479B"
          />
          <path
            d="M20.2821 38.5756H3.77363C3.66998 38.5756 3.58594 38.5231 3.58594 38.4596C3.58594 38.3961 3.66998 38.3436 3.77363 38.3436H20.2821C20.3858 38.3436 20.4698 38.3961 20.4698 38.4596C20.4698 38.5231 20.3858 38.5756 20.2821 38.5756Z"
            fill="white"
          />
          <path
            d="M20.2821 42.4196H3.77363C3.66998 42.4196 3.58594 42.3672 3.58594 42.3036C3.58594 42.2401 3.66998 42.1877 3.77363 42.1877H20.2821C20.3858 42.1877 20.4698 42.2401 20.4698 42.3036C20.4698 42.3672 20.3858 42.4196 20.2821 42.4196Z"
            fill="white"
          />
          <path
            d="M12.4019 40.4203H3.77363C3.66998 40.4203 3.58594 40.3678 3.58594 40.3043C3.58594 40.2408 3.66998 40.1883 3.77363 40.1883H12.4019C12.5055 40.1883 12.5896 40.2408 12.5896 40.3043C12.5896 40.3678 12.5055 40.4203 12.4019 40.4203Z"
            fill="white"
          />
          <path
            d="M17.7805 40.4203H14.0295C13.9258 40.4203 13.8418 40.3678 13.8418 40.3043C13.8418 40.2408 13.9258 40.1883 14.0295 40.1883H17.7805C17.8842 40.1883 17.9682 40.2408 17.9682 40.3043C17.9682 40.3678 17.8842 40.4203 17.7805 40.4203Z"
            fill="white"
          />
          <path
            opacity="0.47"
            d="M46.0435 30.9759H15.2395C13.8669 30.9759 12.7407 29.8686 12.7407 28.5127V18.8722C12.7407 17.5191 13.8641 16.4089 15.2395 16.4089H46.0435C47.4161 16.4089 48.5423 17.5163 48.5423 18.8722V28.5154C48.5423 29.8686 47.4189 30.9759 46.0435 30.9759Z"
            fill="#599AF2"
          />
          <path
            d="M40.0711 19.488H3.31423C2.20488 19.488 1.29443 18.5933 1.29443 17.497V6.39843C1.29443 5.30487 2.20208 4.40738 3.31423 4.40738H40.0711C41.1804 4.40738 42.0909 5.30211 42.0909 6.39843V17.497C42.0909 18.5933 41.1804 19.488 40.0711 19.488Z"
            fill="white"
          />
          <path
            opacity="0.74"
            d="M40.0711 19.488H3.31423C2.20488 19.488 1.29443 18.5933 1.29443 17.497V6.39843C1.29443 5.30487 2.20208 4.40738 3.31423 4.40738H40.0711C41.1804 4.40738 42.0909 5.30211 42.0909 6.39843V17.497C42.0909 18.5933 41.1804 19.488 40.0711 19.488Z"
            fill="#EF929C"
          />
          <path
            d="M14.2454 13.5949C13.3153 13.5949 12.5562 12.8466 12.5562 11.9298C12.5562 11.0129 13.3153 10.2646 14.2454 10.2646C15.1754 10.2646 15.9346 11.0129 15.9346 11.9298C15.9346 12.8493 15.1754 13.5949 14.2454 13.5949ZM14.2454 10.5407C13.4694 10.5407 12.8363 11.1648 12.8363 11.9298C12.8363 12.6947 13.4694 13.3188 14.2454 13.3188C15.0214 13.3188 15.6545 12.6947 15.6545 11.9298C15.6545 11.1648 15.0214 10.5407 14.2454 10.5407Z"
            fill="white"
          />
          <path
            d="M21.5569 13.5949C20.6268 13.5949 19.8677 12.8466 19.8677 11.9298C19.8677 11.0129 20.624 10.2646 21.5569 10.2646C22.487 10.2646 23.2461 11.0129 23.2461 11.9298C23.2461 12.8493 22.487 13.5949 21.5569 13.5949ZM21.5569 10.5407C20.7809 10.5407 20.1478 11.1648 20.1478 11.9298C20.1478 12.6947 20.7809 13.3188 21.5569 13.3188C22.3329 13.3188 22.966 12.6947 22.966 11.9298C22.966 11.1648 22.3329 10.5407 21.5569 10.5407Z"
            fill="white"
          />
          <path
            d="M28.3728 13.5949C27.4428 13.5949 26.6836 12.8466 26.6836 11.9298C26.6836 11.0129 27.4428 10.2646 28.3728 10.2646C29.3029 10.2646 30.0621 11.0129 30.0621 11.9298C30.0621 12.8493 29.3029 13.5949 28.3728 13.5949ZM28.3728 10.5407C27.5968 10.5407 26.9637 11.1648 26.9637 11.9298C26.9637 12.6947 27.5968 13.3188 28.3728 13.3188C29.1488 13.3188 29.7819 12.6947 29.7819 11.9298C29.7819 11.1648 29.1488 10.5407 28.3728 10.5407Z"
            fill="white"
          />
          <path
            d="M81.2627 82.0474H62.9165V95.2889H81.2627V82.0474Z"
            fill="#3E479B"
          />
          <path
            d="M115.311 77.8499H28.8657C27.5883 77.8499 26.5518 76.8281 26.5518 75.5689V25.1077C26.5518 23.8484 27.5883 22.8267 28.8657 22.8267H115.311C116.588 22.8267 117.624 23.8484 117.624 25.1077V75.5689C117.627 76.8281 116.591 77.8499 115.311 77.8499Z"
            fill="#3E479B"
          />
          <path
            d="M114.918 83.3398H29.2578C27.7618 83.3398 26.5488 81.9314 26.5488 80.1944V72.0369H117.624V80.1944C117.627 81.9314 116.414 83.3398 114.918 83.3398Z"
            fill="#4C65BA"
          />
          <path
            d="M113.198 71.8381H30.9807C30.7062 71.8381 30.4849 71.6199 30.4849 71.3493V27.1153C30.4849 26.8447 30.7062 26.6265 30.9807 26.6265H113.198C113.473 26.6265 113.694 26.8447 113.694 27.1153V71.3493C113.694 71.6171 113.473 71.8381 113.198 71.8381Z"
            fill="white"
          />
          <path
            opacity="0.81"
            d="M107.439 68.8087H36.7405C36.4659 68.8087 36.2446 68.5905 36.2446 68.3199V30.3435C36.2446 30.0729 36.4659 29.8547 36.7405 29.8547H107.439C107.713 29.8547 107.935 30.0729 107.935 30.3435V68.3171C107.935 68.5878 107.713 68.8087 107.439 68.8087Z"
            fill="#ECF1F7"
          />
          <path
            d="M72.088 81.056C70.2503 81.056 68.7544 79.5814 68.7544 77.7698C68.7544 75.9583 70.2503 74.4836 72.088 74.4836C73.9257 74.4836 75.4217 75.9583 75.4217 77.7698C75.4217 79.5814 73.9257 81.056 72.088 81.056ZM72.088 74.7598C70.4044 74.7598 69.0345 76.1101 69.0345 77.7698C69.0345 79.4295 70.4044 80.7799 72.088 80.7799C73.7717 80.7799 75.1415 79.4295 75.1415 77.7698C75.1415 76.1101 73.7717 74.7598 72.088 74.7598Z"
            fill="white"
          />
          <path
            d="M91.5801 97.5506H52.5962V96.99C52.5962 95.1591 54.1005 93.6761 55.9578 93.6761H88.2213C90.0786 93.6761 91.5829 95.1591 91.5829 96.99V97.5506H91.5801Z"
            fill="#4C65BA"
          />
          <path
            opacity="0.9"
            d="M72.0877 42.5135C73.4322 42.5135 74.5221 41.4391 74.5221 40.1138C74.5221 38.7884 73.4322 37.714 72.0877 37.714C70.7432 37.714 69.6533 38.7884 69.6533 40.1138C69.6533 41.4391 70.7432 42.5135 72.0877 42.5135Z"
            fill="white"
          />
          <path
            opacity="0.15"
            d="M72.0877 60.7506C73.4322 60.7506 74.5221 59.6762 74.5221 58.3508C74.5221 57.0255 73.4322 55.9511 72.0877 55.9511C70.7432 55.9511 69.6533 57.0255 69.6533 58.3508C69.6533 59.6762 70.7432 60.7506 72.0877 60.7506Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M81.3382 51.6321C82.6827 51.6321 83.7726 50.5577 83.7726 49.2323C83.7726 47.907 82.6827 46.8326 81.3382 46.8326C79.9937 46.8326 78.9038 47.907 78.9038 49.2323C78.9038 50.5577 79.9937 51.6321 81.3382 51.6321Z"
            fill="white"
          />
          <path
            d="M62.8377 51.6321C64.1822 51.6321 65.2721 50.5577 65.2721 49.2323C65.2721 47.907 64.1822 46.8326 62.8377 46.8326C61.4932 46.8326 60.4033 47.907 60.4033 49.2323C60.4033 50.5577 61.4932 51.6321 62.8377 51.6321Z"
            fill="white"
          />
          <path
            opacity="0.7"
            d="M80.349 44.4825C79.3993 45.4186 77.8586 45.4186 76.9061 44.4825C75.9564 43.5463 75.9564 42.0275 76.9061 41.0886C77.8558 40.1524 79.3965 40.1524 80.349 41.0886C81.3015 42.0247 81.3015 43.5436 80.349 44.4825Z"
            fill="white"
          />
          <path
            opacity="0.1"
            d="M65.5467 58.0802C66.8912 58.0802 67.9811 57.0058 67.9811 55.6804C67.9811 54.3551 66.8912 53.2807 65.5467 53.2807C64.2022 53.2807 63.1123 54.3551 63.1123 55.6804C63.1123 57.0058 64.2022 58.0802 65.5467 58.0802Z"
            fill="white"
          />
          <path
            opacity="0.3"
            d="M76.909 57.376C75.9594 56.4399 75.9594 54.9183 76.909 53.9821C77.8587 53.046 79.3994 53.046 80.3519 53.9821C81.3044 54.9183 81.3016 56.4399 80.3519 57.376C79.4022 58.3122 77.8587 58.3122 76.909 57.376Z"
            fill="white"
          />
          <path
            d="M63.8265 44.4825C62.8768 43.5463 62.8768 42.0275 63.8265 41.0886C64.7762 40.1524 66.3197 40.1524 67.2694 41.0886C68.2191 42.0247 68.2191 43.5436 67.2694 44.4825C66.3197 45.4186 64.7762 45.4186 63.8265 44.4825Z"
            fill="white"
          />
          <path
            opacity="0.47"
            d="M113.198 31.2521H30.9807C30.7062 31.2521 30.4849 31.0339 30.4849 30.7633V27.1153C30.4849 26.8447 30.7062 26.6265 30.9807 26.6265H113.198C113.473 26.6265 113.694 26.8447 113.694 27.1153V30.7633C113.694 31.0339 113.473 31.2521 113.198 31.2521Z"
            fill="#599AF2"
          />
          <path
            d="M105.716 28.8661C105.716 29.0981 105.525 29.2886 105.287 29.2886C105.052 29.2886 104.858 29.1008 104.858 28.8661C104.858 28.6314 105.052 28.4436 105.287 28.4436C105.525 28.4436 105.716 28.6341 105.716 28.8661Z"
            fill="#ECF1F7"
          />
          <path
            d="M110.277 28.8661C110.277 29.0981 110.086 29.2886 109.848 29.2886C109.613 29.2886 109.419 29.1008 109.419 28.8661C109.419 28.6314 109.61 28.4436 109.848 28.4436C110.083 28.4436 110.277 28.6341 110.277 28.8661Z"
            fill="#ECF1F7"
          />
          <path
            d="M107.567 29.2886C107.804 29.2886 107.996 29.0995 107.996 28.8661C107.996 28.6328 107.804 28.4436 107.567 28.4436C107.331 28.4436 107.139 28.6328 107.139 28.8661C107.139 29.0995 107.331 29.2886 107.567 29.2886Z"
            fill="#ECF1F7"
          />
          <path
            d="M33.1265 75.7567L33.5663 74.5085C33.7624 73.9561 34.2919 73.5833 34.8858 73.5833H45.8419C46.6123 73.5833 47.237 74.1992 47.237 74.9586V76.679V79.5151V96.4542C47.237 97.2136 46.6123 97.8295 45.8419 97.8295H12.7857C12.0153 97.8295 11.3906 97.2136 11.3906 96.4542V78.0542C11.3906 77.2948 12.0153 76.679 12.7857 76.679H31.8099C32.4009 76.679 32.9304 76.309 33.1265 75.7567Z"
            fill="#599AF2"
          />
          <path
            opacity="0.12"
            d="M33.936 77.0242L34.3506 75.8478C34.5355 75.3259 35.0342 74.9779 35.5945 74.9779H45.9231C46.6487 74.9779 47.2398 75.5578 47.2398 76.2758V77.8968V80.57V96.5343C47.2398 97.2495 46.6515 97.8322 45.9231 97.8322H14.7634C14.0379 97.8322 13.4468 97.2523 13.4468 96.5343V79.192C13.4468 78.4767 14.0351 77.8941 14.7634 77.8941H32.695C33.2553 77.8941 33.7539 77.5461 33.936 77.0242Z"
            fill="white"
          />
          <path
            opacity="0.21"
            d="M88.0442 73.009C98.8326 73.009 107.578 64.3877 107.578 53.7529C107.578 43.1181 98.8326 34.4969 88.0442 34.4969C77.2559 34.4969 68.5103 43.1181 68.5103 53.7529C68.5103 64.3877 77.2559 73.009 88.0442 73.009Z"
            fill="white"
          />
          <path
            opacity="0.81"
            d="M90.0891 46.2933C92.3651 45.7873 93.794 43.5583 93.2807 41.3148C92.7674 39.0712 90.5063 37.6626 88.2303 38.1686C85.9544 38.6746 84.5255 40.9035 85.0388 43.1471C85.552 45.3907 87.8132 46.7992 90.0891 46.2933Z"
            fill="#ECF1F7"
          />
          <path
            opacity="0.58"
            d="M88.0445 73.5613C76.965 73.5613 67.9502 64.6747 67.9502 53.7529C67.9502 42.8311 76.965 33.9445 88.0445 33.9445C99.1239 33.9445 108.139 42.8311 108.139 53.7529C108.139 64.6747 99.1239 73.5613 88.0445 73.5613ZM88.0445 35.0492C77.5813 35.0492 69.0707 43.4386 69.0707 53.7529C69.0707 64.0672 77.5813 72.4566 88.0445 72.4566C98.5048 72.4566 107.018 64.0672 107.018 53.7529C107.015 43.4386 98.5048 35.0492 88.0445 35.0492Z"
            fill="#3E479B"
          />
          <path
            d="M119.308 81.0836L106.438 68.6568C106.186 68.411 105.895 68.2288 105.587 68.1017C108.867 64.208 110.842 59.2096 110.842 53.7529C110.842 41.3426 100.637 31.2824 88.0472 31.2824C75.4578 31.2824 65.2524 41.3426 65.2524 53.7529C65.2524 66.1631 75.4578 76.2233 88.0472 76.2233C93.3166 76.2233 98.163 74.456 102.023 71.4984C102.158 71.8519 102.368 72.1805 102.659 72.4622L115.529 84.889C116.055 85.3971 116.736 85.6484 117.42 85.6484C118.12 85.6484 118.82 85.3805 119.35 84.8476C120.389 83.7982 120.372 82.1137 119.308 81.0836ZM68.5104 53.7529C68.5104 43.1183 77.2563 34.4968 88.0444 34.4968C98.8325 34.4968 107.578 43.1183 107.578 53.7529C107.578 64.3875 98.8325 73.0089 88.0444 73.0089C77.2563 73.0089 68.5104 64.3875 68.5104 53.7529Z"
            fill="#3E479B"
          />
          <path
            d="M133.071 91.7955L114.344 73.3348C113.876 72.8736 106.965 79.6531 107.45 80.1309L126.177 98.5916C128.082 100.469 131.166 100.469 133.071 98.5916C134.973 96.7165 134.973 93.6733 133.071 91.7955ZM131.656 94.9271C131.656 95.8605 130.889 96.6143 129.945 96.6143C128.998 96.6143 128.233 95.8577 128.233 94.9271C128.233 93.9937 129.001 93.2398 129.945 93.2398C130.889 93.237 131.656 93.9937 131.656 94.9271Z"
            fill="#599AF2"
          />
          <g opacity="0.1">
            <path
              d="M130.889 96.3327C130.617 96.5094 130.292 96.6143 129.942 96.6143C128.995 96.6143 128.23 95.8577 128.23 94.9271C128.23 94.5625 128.351 94.2229 128.55 93.9467C124.275 89.5918 117.966 83.1906 114.159 79.3355C112.192 77.3445 110.895 76.0328 110.893 76.0355C109.041 77.8222 107.186 79.8768 107.447 80.1336L126.174 98.5943C128.079 100.472 131.163 100.472 133.068 98.5943C133.08 98.5833 132.242 97.7162 130.889 96.3327Z"
              fill="white"
            />
          </g>
          <path
            d="M115.369 74.5057L108.609 81.1692C108.301 81.473 107.8 81.473 107.492 81.1692L106.273 79.9679C105.965 79.6642 105.965 79.1699 106.273 78.8661L113.033 72.2026C113.341 71.8988 113.842 71.8988 114.151 72.2026L115.369 73.4038C115.677 73.7076 115.677 74.2019 115.369 74.5057Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2545_34292">
            <rect width="154" height="100" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Typography
        sx={{ fontSize: 13, fontWeight: 400, color: "#767C91" }}
        variant={"body1"}
      >
        No data
      </Typography>
    </Grid>
  );
};

export default NoData;
