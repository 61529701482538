import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { IModule } from "./module.service";
import { IFile } from "./file.service";

export interface IBusiness {
  _id: string;
  name: string;
  slug: string;
  description: string;
  address: string;
  phone: string;
  vat_id: string;
  rn: string;
  an: string;
  currency: string;
  email: string;
  avatar: string;
  template?: string;
  modules: Array<IModule>;
  active: number;
  file: IFile | undefined;
}

const INDEX = API_ROUTES.businesses;
const SHOW = API_ROUTES.business;
const UPDATE = API_ROUTES.business;
const DELETE = API_ROUTES.business;

export interface IBusinessService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IBusiness> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IBusiness> | IRestApiError>;
  update(
    _id: string | undefined,
    data: IBusiness,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<IBusiness> | IRestApiError>;
  add(data: IBusiness): Promise<IRestApiResource<IBusiness> | IRestApiError>;
}

const businessService: IBusinessService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: IBusiness, query?: Array<IUrlQuery>) {
    let jsonData = JSON.stringify(data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async add(data: IBusiness) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(INDEX), "post", formData);
  },
};

export { businessService };
