import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/store";

export default class MenuSubmenu extends React.Component {
  render() {
    const { item, currentUrl, layoutConfig, user } = this.props;
    return (
      <ul className="kt-menu__subnav">
        {item.submenu.map((child, index) => {
          if (
            user?.role.slug === "social-worker" &&
            child.name === "todos_robotos"
          )
            return;

          return (
            <React.Fragment key={index}>
              {child.section && (
                <MenuSection
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.separator && (
                <MenuItemSeparator
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.title && (
                <MenuItem
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                  layoutConfig={layoutConfig}
                />
              )}
            </React.Fragment>
          );
        })}
      </ul>
    );
  }
}
