import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { IAction, ISecureUser, IUser } from "../../interfaces";

export interface IUserState {
  online_users: Array<ISecureUser>;
}

export const actionTypes = {
  OnlineUsersListUpdate: "List of online users changed",
};

const initialAuthState: IUserState = {
  online_users: [],
};

export const reducer = persistReducer(
  { storage, key: "users", whitelist: ["online_users"] },
  (state: IUserState = initialAuthState, action: IAction) => {
    switch (action.type) {
      case actionTypes.OnlineUsersListUpdate: {
        let { users }: { users: Array<ISecureUser> } = action.payload;
        return { online_users: [...users] };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  updateOnlineUsers: (users: Array<ISecureUser>) => ({
    type: actionTypes.OnlineUsersListUpdate,
    payload: { users },
  }),
};
