import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import {
    AppBar,
    Button, Checkbox,
    Dialog,
    DialogContent, FormControlLabel,
    Grid,
    IconButton,
    SelectChangeEvent,
    Skeleton,
    Slide,
    Toolbar,
    Typography
} from "@mui/material";
import {useForm} from "app/hooks/useForm";
import {TransitionProps} from "@mui/material/transitions";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import {useSelector} from "react-redux";
import {RootState} from "app/store/store";
import {offerService} from "app/services";
import {FormattedMessage, useIntl} from "react-intl";


interface IFormProps {
    open: boolean,
    setDialogue: Dispatch<SetStateAction<boolean>>,
    onDone: Function
}

export type FormStateValues = {
    content: string
    email: string
    phone: string
    is_secure: boolean
    is_read: boolean
    focus_area: number
    mail_response: string
    sms_response: string
    mail_content: string
}

export const initValues = {
    content: "",
    email: "",
    phone: "",
    is_secure: false,
    is_read: false,
    focus_area: 1,
    mail_response: "",
    sms_response: "",
    mail_content: "",
} as FormStateValues;

const OfferStore: FC<IFormProps> = (props) => {
    const intl = useIntl();
    const form = useForm<FormStateValues>(initValues);
    const loaded = useSelector((state: RootState) => state.settingReducer && state.permission);
    const {focus_areas} = useSelector((state: RootState) => state.settingReducer);
    const {offer_templates} = useSelector((state: RootState) => state.settingReducer);

    const [focusTypeOfferTemplates, setFocusTypeOffers] = useState<Array<any>>()
    const [offerTemplateContent, setOfferTemplateContent] = useState<string>()

    const handleFocusAreaSelection = (e: SelectChangeEvent<any>): void => {
        // if (typeof e.target.value !== "string") return;

        let template = offer_templates?.filter((offer_template: any) => offer_template.focus_area === e.target.value) ?? undefined

        if (template) setFocusTypeOffers([...template]);
        form.values.focus_area = +e.target.value
    }

    const handleTemplateSelection = (e: SelectChangeEvent<any>): void => {
        // if (typeof e.target.value !== "string") return;

        let data = offer_templates?.filter((offer_template: any) => offer_template._id === e.target.value)[0] ?? undefined

        if (data) setOfferTemplateContent(data.content);
    }

    const handleClose = () => {
        props.setDialogue(false);
    };

    const handleSave = () => {
        let validated = form.validate();

        if (!validated){
            Snackbar.error(intl.formatMessage({id: 'SNACKBAR.STOREERROR'}))
            return;
        }

        offerService.add(validated).then((data) => {
            if ("error" in data){
                throw new Error(data.error.message);
            }

            Snackbar.success(intl.formatMessage({id: 'SNACKBAR.ADDED'}))

            setTimeout(() => {
                props.setDialogue(false);
                props.onDone();
                form.clear()
            }, 1000)

        }).catch((e) => {
            Snackbar.error(e.message)
        });
    }


    return(
        <>
            {
                <Dialog
                    open={props.open}
                    fullWidth={true}
                    maxWidth={'xl'}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseOutlinedIcon/>
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }}
                                        variant="h6"
                                        component="div">
                                <FormattedMessage id={'OFFER.ADD'}
                                                  defaultMessage={'OFFER.ADD'}
                                />
                            </Typography>
                            <Button autoFocus
                                    color="inherit"
                                    type={'submit'}
                                    form={'offer_form'}>
                                <FormattedMessage id={'DIALOGUE.SAVE'}
                                                  defaultMessage={'DIALOGUE.SAVE'}
                                />
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form id={'offer_form'} onSubmit={(e) => form.handleSubmit(e, handleSave)}>
                            <Grid container
                                  spacing={2}
                                  mb={2}>
                                <Grid item
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      lg={6}
                                      xl={6}>
                                    {
                                        loaded ?
                                          <Controls.Select name={'focus_area'}
                                                           formId={'focus_area'}
                                                           options={focus_areas?.map(focus_area => {
                                                               return {
                                                                   id: focus_area._id.toString(),
                                                                   title: focus_area.title['en']
                                                               }
                                                           })}
                                                           label={intl.formatMessage({id: 'CASE.FOCUS_AREA'})}
                                                           onChange={(event) => {
                                                                       handleFocusAreaSelection(event)
                                                                   }}
                                          />
                                          :
                                          <Skeleton width={'100%'}>
                                              <Controls.Select name={'focus_area'}
                                                               options={[]}
                                                               label={intl.formatMessage({id: 'CASE.FOCUS_AREA'})}
                                                               onChange={() => {}}
                                              />
                                          </Skeleton>
                                    }
                                </Grid>
                                <Grid item
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      lg={6}
                                      xl={6}>
                                    {
                                        loaded ?
                                          <Controls.Select name={'offer_templates'}
                                                           formId={'offer_templates'}
                                                           options={focusTypeOfferTemplates &&
                                                                     focusTypeOfferTemplates.map(
                                                                       focusTypeOfferTemplate => {
                                                                       return {
                                                                           id: focusTypeOfferTemplate._id.toString(),
                                                                           title: focusTypeOfferTemplate.title['en']
                                                                       }
                                                                   })}
                                                           label={intl.formatMessage({id: 'MENU.OFFER_TEMPLATES'})}
                                                           onChange={(event) => {
                                                                       handleTemplateSelection(event)
                                                                   }}
                                          />
                                          :
                                          <Skeleton width={'100%'}>
                                              <Controls.Select name={'offer_templates'}
                                                               options={[]}
                                                               label={intl.formatMessage({id: 'MENU.OFFER_TEMPLATES'})}
                                                               onChange={() => {}}
                                              />
                                          </Skeleton>
                                    }
                                </Grid>
                                <Grid item
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      lg={5}
                                      xl={5}>
                                    {
                                        loaded ?
                                          <Controls.Input
                                            name={'email'}
                                            label={intl.formatMessage({id: 'CASE.INFORMATION.EMAIL'})}
                                            onChange={(event) => {

                                                if (!event.target.value) return;

                                                form.values.email =  event.target.value;

                                                event.preventDefault();
                                            }}
                                          />
                                          :
                                          <Skeleton width={'100%'}>
                                              <Controls.Input
                                                name={'email'}
                                                label={intl.formatMessage({id: 'CASE.INFORMATION.EMAIL'})}
                                                onChange={() => {}}/>
                                          </Skeleton>
                                    }
                                </Grid>
                                <Grid item
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      lg={5}
                                      xl={5}>
                                    {
                                        loaded ?
                                          <Controls.Input
                                            name={'phone'}
                                            label={intl.formatMessage({id: 'USERS.PHONENUMBER'})}
                                            onChange={(event) => {

                                                if (!event.target.value) return;

                                                form.values.phone =  event.target.value;

                                                event.preventDefault();
                                            }}
                                          />
                                          :
                                          <Skeleton width={'100%'}>
                                              <Controls.Input
                                                name={'phone'}
                                                label={intl.formatMessage({id: 'USERS.PHONENUMBER'})}
                                                onChange={() => {}}/>
                                          </Skeleton>
                                    }
                                </Grid>
                                <Grid item
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      lg={2}
                                      xl={2}>
                                    {
                                        loaded ?
                                          <FormControlLabel control={
                                              <Checkbox onChange={(event) => {

                                                  if(!event.target.checked) return;

                                                  form.values.is_secure = event.target.checked;
                                              }}
                                                        id={'is_secure'}
                                              />}
                                                            label={intl.formatMessage({id: 'STANDARD.ISSECURE'})}
                                                            name={'is_secure'}
                                          />
                                          :
                                          <Skeleton width={'100%'}>
                                              <FormControlLabel control={<Checkbox/>}
                                                                label={intl.formatMessage({id: 'STANDARD.ISSECURE'})}
                                                                name={'is_secure'}
                                              />
                                          </Skeleton>
                                    }
                                </Grid>
                                <Grid item
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      xl={12}>
                                    {
                                        loaded ?
                                          <Controls.Editor name={'content'}
                                                           label={intl.formatMessage({id: 'STANDARD.CONTENT'})}
                                                           data={offerTemplateContent}
                                                           onChange={ (data: any) => {
                                                               form.values.content = data
                                                           } }
                                          />
                                        :
                                          <Skeleton width={'100%'}>
                                              <Controls.Editor name={'content'}
                                                               label={intl.formatMessage({id: 'STANDARD.CONTENT'})}
                                                              onChange={() => {}}
                                              />
                                          </Skeleton>
                                    }
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default OfferStore;
