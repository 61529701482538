import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SelectChangeEvent } from "@mui/material";
import Typography from "@mui/material/Typography";
import Controls from "../../../../widgets/uncontrolled";
import React, { FC, memo, useEffect, useRef, useState } from "react";
import { ISetting } from "../../../../services/setting.service";
import { FormattedMessage, useIntl } from "react-intl";
import { ICase } from "../../../../services/case.service";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

export interface IFormFocusValues {
  focus_area?: string;
  focus_type?: string;
  employee_position?: string;
  employee_rate?: string;
  report?: boolean;
  report_type?: string;
  report_interval?: string;
}
interface _ISettings extends ISetting {
  amount?: string;
}

interface IFormUsersProps extends IFormFocusValues {
  case?: ICase;
  focus_areas: Array<ISetting>;
  focus_types: Array<ISetting>;
  positions: Array<_ISettings>;
  report_types: Array<ISetting>;
  report_intervals: Array<ISetting>;
  touched: boolean;
  handleChange: any;
  values: IFormFocusValues;
}

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const Focus: FC<IFormUsersProps> = (props) => {
  const intl = useIntl();
  const [report, setReport] = useState<boolean>(true);
  const [focusTypeOptions, setFocusTypeOptions] = useState<Array<ISetting>>();
  const classes = useStyles();
  const rateInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.case && rateInputRef.current) {
      rateInputRef.current.value = props.case.employee_rate;
      props.values.employee_rate = props.case.employee_rate;
    }
  }, [props.case, rateInputRef]);

  useEffect(() => {
    props.values.report = report;

    let f =
      props.focus_types.filter(
        (f_t: any) => f_t.focus_area === props.case?.focus_area?._id
      ) ?? undefined;

    if (f) setFocusTypeOptions([...f]);
  }, [report]);

  const handleFASelection = (e: SelectChangeEvent<any>): void => {
    // if (typeof e.target.value !== "string") return;

    let focus_area = props.focus_areas.find(
      (r) => r._id.toString() === e.target.value
    );

    let f =
      props.focus_types.filter(
        (f_t: any) => f_t.focus_area === focus_area?._id
      ) ?? undefined;

    if (f) setFocusTypeOptions([...f]);

    if (focus_area) {
      props.values.focus_area = focus_area._id;
    }
  };

  console.log("props.", props.case);
  const handleFTSelection = (e: SelectChangeEvent<any>): void => {
    // if (typeof e.target.value !== "string") return;

    let focus_type = props.focus_types.find((r) => r._id === e.target.value);

    if (focus_type) {
      props.values.focus_type = focus_type._id;
    }
  };

  const handlePSelection = (e: SelectChangeEvent<any>): void => {
    // if (typeof e.target.value !== "string") return;

    let position = props.positions.find((p) => p._id === e.target.value);

    if (position) {
      props.values.employee_position = position._id;
      if (rateInputRef.current) {
        rateInputRef.current.value = position.amount ?? "";
        props.values.employee_rate = position.amount ?? "";
      }
    }
  };

  const handleRTSelection = (e: SelectChangeEvent<any>): void => {
    props.values.report_type = e.target.value;
  };

  const handleRISelection = (e: SelectChangeEvent<any>): void => {
    props.values.report_interval = e.target.value;
  };
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FOCUS_AREA"}
              defaultMessage={"CASE.FOCUS_AREA"}
            />
          </Typography>
          <Controls.Select
            onChange={(e) => handleFASelection(e)}
            label={""}
            formId={"focus_area_pick"}
            name={"focus_area"}
            defaultValue={props.case?.focus_area?._id}
            options={props.focus_areas?.map((m) => ({
              id: m._id.toString(),
              title: m.title ? m.title[intl.locale] : "",
            }))}
            error={""}
          />
        </Box>
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <Box sx={{ mt: 2 }} className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FOCUS_TYPE"}
              defaultMessage={"CASE.FOCUS_TYPE"}
            />
          </Typography>
          <Controls.Select
            onChange={(e) => handleFTSelection(e)}
            label={""}
            formId={"focus_type_pick"}
            name={"focus_type"}
            defaultValue={props.case?.focus_type?._id}
            options={
              focusTypeOptions &&
              focusTypeOptions.map((focusTypeOption) => {
                return {
                  id: focusTypeOption._id,
                  title: focusTypeOption.title[intl.locale],
                };
              })
            }
            error={""}
          />
        </Box>
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <Box sx={{ mt: 2 }} className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.EMPLOYEE_POSITION"}
              defaultMessage={"Employee position"}
            />
          </Typography>
          <Controls.Select
            onChange={(e) => handlePSelection(e)}
            label={""}
            formId={"employee_position_pick"}
            name={"employee_position"}
            defaultValue={props.case?.employee_position?._id}
            options={
              props.positions &&
              props.positions.map((position) => {
                return {
                  id: position._id,
                  title: position.title[intl.locale],
                };
              })
            }
            error={""}
          />
        </Box>
      </Grid>
      {me?.role?.slug === "super-admin" && (
        <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
          <Box sx={{ mt: 2 }} className={classes.input}>
            <Typography variant={"body1"} sx={{ mb: 1 }}>
              <FormattedMessage
                id={"CASE.EMPLOYEE_RATE"}
                defaultMessage={"Employee rate"}
              />
            </Typography>
            <Controls.Input
              inputRef={rateInputRef}
              onChange={(e) => {
                props.values.employee_rate = e.target.value;
              }}
              label={""}
              name={"budget_limit"}
              error={false}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default memo(Focus);
