import React from "react";
import { makeStyles } from "@material-ui/core";

type ModuleProps = {
  title: string;
  children?: React.ReactChild | React.ReactChild[];
};

const useStyles = makeStyles((theme) => ({
  canvas: {
    "& canvas": {
      height: "100%",
    },
  },
}));

const Module = ({ title, children }: ModuleProps) => {
  const classes = useStyles();

  return (
    <div className="kt-portlet kt-portlet-height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">{title}</h3>
        </div>
      </div>
      <div
        style={{
          padding: 0,
        }}
        className={`kt-portlet__body ${classes.canvas}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Module;
