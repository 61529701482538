import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../../partials/layout/DataTable";
import { connect } from "react-redux";
import { Chip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { knowledgebaseService } from "app/services";
import moment from "moment";
import { useParams } from "react-router";
import KnowledgebaseItemStore from "./store";
import ShowKnowledgebaseItem from "./show";
import { useTable } from "../../../hooks/subresource/useTable";
import { IKnowledgebase } from "../../../services/knowledgebase.service";
import { GridTable } from "../../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../../permission/PermissionsGate";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import { Ii18nState } from "../../../store/ducks/i18n.duck";
import { ISettingsState } from "../../../store/ducks/setting.duck";
import KnowledgebaseItemFilter from "./filter";
import { usePermissions } from "../../../hooks/useRole";
import EditIcon from "../../../partials/icons/EditIcon";
import { useHistory } from "react-router-dom";

const KnowledgebaseItem = () => {
  const intl = useIntl();
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();
  const permissions = usePermissions();
  const history = useHistory();

  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<IKnowledgebase>({
      fetch: knowledgebaseService.getItems,
      delete: knowledgebaseService.deleteOneItem,
      param: id,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return params.row?.title;
      },
      minWidth: 450,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.CREATED_BY" }),
      field: "created_by",
      renderCell: (params) => {
        return (
          <div>
            <Chip
              key={params.row?.created_by?._id}
              label={params.row?.created_by?.fullname}
              sx={{
                bgcolor: params.row?.created_by?.role?.color,
                color: "#fff",
              }}
              clickable
              onClick={() => {
                history.push(`/users/${params.row?.created_by?._id}`);
              }}
            />
          </div>
        );
      },
      minWidth: 300,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 160,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"knowledgebase"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"item_show"}
                data-cy-category-id={id}
                data-cy-item-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"knowledgebase"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"knowledgebase_item_delete"}
                data-cy-category-id={id}
                data-cy-item-id={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [itemId, setItemId] = useState<string | null>(null);

  const show = (_id: string) => {
    setItemId(_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"knowledgebase"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete kowledge base item?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(id, confirmId);
            }}
          >
            Are you sure you want to delete this kowledge base item?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"knowledgebase"} scope={"read"}>
        {itemId ? (
          <ShowKnowledgebaseItem
            knowledgebaseCategoryId={id}
            _id={itemId}
            open={Boolean(itemId)}
            setDialogue={() => setItemId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"knowledgebase"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions &&
              hasPermission(permissions, "knowledgebase", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <KnowledgebaseItemStore
                        knowledgebaseCategoryId={id}
                        open={props.open}
                        onDone={refresh}
                        setDialogue={props.close}
                      />
                    );
                  }
                : undefined,
            filters: (props: { show: boolean }) => {
              return (
                <KnowledgebaseItemFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgebaseItem);
