import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

const INDEX = API_ROUTES.settings;
const SHOW = API_ROUTES.setting;
const UPDATE = API_ROUTES.setting;
const DELETE = API_ROUTES.setting_delete;

export type SettingsType =
  | "focus_areas"
  | "focus_types"
  | "currencies"
  | "system_languages"
  | "languages"
  | "questions"
  | "goals"
  | "cities"
  | "questions_categories"
  | "todo_categories"
  | "colors"
  | "activity_statuses"
  | "trading_plan_statuses"
  | "report_statuses"
  | "folder_categories"
  | "vacancy_folders"
  | "calendar_statuses"
  | "vacancy_cities"
  | "vacancy_languages"
  | "user_session_types"
  | "vacancy_statuses"
  | "offer_statuses"
  | "pdf_front_page_exports"
  | "time_tracks_types"
  | "report_types"
  | "report_intervals"
  | "expense_categories"
  | "meeting_duration_types"
  | "budget_intervals"
  | "time_track_types"
  | "offer_templates"
  | "finance_statuses"
  | "plan_statuses"
  | "plan_types"
  | "question_categories"
  | "plan_intervals"
  | "todo_statuses"
  | "contract_statuses"
  | "labels"
  | "transaction_types"
  | "todo_types"
  | "positions"
  | "notice_commune";

enum EActive {
  "inactive",
  "active",
}

export interface ISetting {
  _id: string;
  title: { [key: string]: string };
  active: EActive;
  index: number;
  default_lang: string;
  slug: string;
  short_code: string;
  flag: string;
  slug_unchangeable?: string;
  color: string;
  content: string;
  name: SettingsType;
  _focus_area?: Array<string>;
  categories?: Array<ISetting>;
  goal?: ISetting;
  currency_title?: string;
  symbol?: string;
  _created_at: string;
  _updated_at: string;
}

export interface ISettingService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<Array<IRestApiCollection<ISetting> | IRestApiError>>;

  add(
    data: any,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  get(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ISetting> | IRestApiError>;

  getOne(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ISetting> | IRestApiError>;

  update(
    _id: string,
    data: any,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ISetting> | IRestApiError>;

  delete(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ISetting> | IRestApiError>;
}

const settingService: ISettingService = {
  /** TODO save this for future implementation of promise multi with throttle
   *  let requests = Settings.map((setting, i) => {
   *     timeout for avoiding throttling in backend
   *     //   // return new Promise((resolve) => {
   *     //   //   setTimeout(() => {
   *     //   //     resolve(http(`${getRoute(INDEX)}?type=${setting}`, "get"))
   *     //   //   }, 1000*i)
   *     //   // })
   *     // });
   * @param queries
   */
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get", undefined);
  },

  async get(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(SHOW.replace(":_id", _id.toString()))}${queriesString}`,
      "get"
    );
  },

  async add(data: any, queries?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "post", formData);
  },

  async update(_id: string, data: any, queries?: Array<IUrlQuery>) {
    let jsonData = JSON.stringify(data);
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(UPDATE.replace(":_id", _id.toString()))}${queriesString}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },
};

export { settingService };
