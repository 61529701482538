import React, { FC } from "react";
import { Card, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface IProps {
  title: string;
  body: JSX.Element;
  ratio: string;
  onClick?: () => void;
}

const ChartCard: FC<IProps> = ({ title, body, ratio, onClick }) => {
  return (
    <Card
      sx={{
        border: "1px solid #E6E6E6",
        borderRadius: 2,
        width: "100%",
        paddingTop: ratio,
        position: "relative",
        height: "100%",
      }}
    >
      <Box
        sx={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {body}
        </Box>
      </Box>
    </Card>
  );
};

export default ChartCard;
