import { useCallback, useEffect, useState } from "react";
import Snackbar from "app/widgets/Snackbar";
import { IRestApiCollection, IRestApiError, IUrlQuery } from "app/interfaces";

interface Props<T> {
  query?: Array<IUrlQuery>;
  param: string;
  fetch: (
    id: string,
    queries?: Array<IUrlQuery>
  ) => Promise<IRestApiCollection<T> | IRestApiError>;
  delete?: (
    id: string,
    subId: string,
    queries?: Array<IUrlQuery>
  ) => Promise<IRestApiCollection<T> | IRestApiError>;
}

export const useTable = <T>(props: Props<T>) => {
  const [data, setData] = useState<IRestApiCollection<T>>();
  const [queries, setQuery] = useState<Array<IUrlQuery> | undefined>(
    props?.query
  );
  const [xhrLoading, setLoading] = useState<boolean>(true);
  const [xhrError, setError] = useState<any>();

  useEffect(() => {
    xhr();
  }, [queries]);

  const xhr = () => {
    setLoading(true);

    props
      .fetch(props.param, queries)
      .then((data: IRestApiCollection<any> | IRestApiError) => {
        setLoading(false);

        if ("error" in data) {
          throw new Error(data.error.message);
        }

        setData(data);
      })
      .catch((e: any) => {
        setLoading(false);
        Snackbar.error(e.message);
      });
  };

  const _remove = (id: string, subId: string) => {
    if (!props.delete) return;

    props
      .delete(id, subId, queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        } else setData(data);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const refresh = useCallback(() => xhr(), [queries]);
  const remove = useCallback(
    (id: string, subId: string) => _remove(id, subId),
    [queries]
  );
  const updateQuery = useCallback(
    (_queries: Array<IUrlQuery>) => {
      if (queries) {
        let queryToUpdate = [...queries];

        for (let _query of _queries) {
          let sameQuery = queryToUpdate.find((q) => q.name === _query.name);

          if (sameQuery) sameQuery.value = _query.value;
          else queryToUpdate.push(_query);
        }

        setQuery(queryToUpdate);
      } else setQuery(_queries);
    },
    [queries]
  );

  return {
    data,
    setData,
    refresh,
    remove,
    setQuery,
    updateQuery,
    xhrLoading,
    xhrError,
    queries,
  };
};
