import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { IUrlQuery } from "app/interfaces";
import { Box, Grid, Theme } from "@mui/material";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.css";
import { makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";

const useStyle = makeStyles((theme: Theme) => ({
  rangePicker: {
    width: "100%",
    height: 40,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const UserDocumentFilter = (props: {
  id: string;
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
  queries: Array<IUrlQuery> | undefined;
  setQuery: Dispatch<SetStateAction<IUrlQuery[] | undefined>>;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [from, setFrom] = useState<string>("");
  const [to, setTo] = useState<string>("");
  const classes = useStyle();
  const { RangePicker } = DatePicker;
  const intl = useIntl();
  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  const handleFromToChange = (dateString: Array<string>) => {
    setFrom(dateString[0]);

    if (dateString[1].length > 0) setTo(dateString[1]);
  };

  useEffect(() => {
    if (from) handleQuery("from", from);

    if (to) handleQuery("to", to);
  }, [from, to]);

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Space className={classes.antSpace} direction="vertical" size={12}>
            <RangePicker
              allowEmpty={[true, true]}
              className={classes.rangePicker}
              placeholder={[
                intl.formatMessage({
                  id: "STANDARD.FROM",
                  defaultMessage: "From",
                }),
                intl.formatMessage({
                  id: "STANDARD.TO",
                  defaultMessage: "To",
                }),
              ]}
              separator={"―"}
              onCalendarChange={(value, dateString) => {
                if (!dateString[0] && !dateString[1]) {
                  let _queries = props.queries?.filter(
                    (q) => q.name !== "from" && q.name !== "to"
                  );

                  props.setQuery(_queries);

                  setFrom("");
                  setTo("");

                  return;
                }

                handleFromToChange(dateString);
              }}
            />
          </Space>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDocumentFilter;
