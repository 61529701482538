import React, { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { IColumns } from "app/partials/layout/DataTable";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTable } from "app/hooks/useTable";
import { GridTable } from "app/partials/layout/GridTable";
import ShowCommune from "app/pages/Communes/show";
import CommuneStore from "app/pages/Communes/store";
import { useIntl } from "react-intl";
import { hasPermission, PermissionsGate } from "app/permission/PermissionsGate";
import { communeService, ICommune } from "app/services/comunne.service";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { Typography } from "@mui/material";
import { usePermissions } from "app/hooks/useRole";
import EditIcon from "../../partials/icons/EditIcon";

type Props = {
  lang: object;
  settings_loading: boolean;
};

const Communes: FC<Props> = (props) => {
  const intl = useIntl();
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const permissions = usePermissions();
  const { data, xhrLoading, refresh, remove, updateQuery, queries, setQuery } =
    useTable<ICommune>({
      fetch: communeService.getAll,
      delete: communeService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 50,
    },
    {
      field: "name",
      headerName: intl.formatMessage({ id: "CASE.INFORMATION.COMMUNE.NAME" }),
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.name}>
            <Typography variant="inherit">{params.row?.name}</Typography>
          </Tooltip>
        );
      },
      minWidth: 400,
    },
    {
      field: "phone",
      headerName: intl.formatMessage({ id: "USERS.PHONENUMBER" }),
      minWidth: 400,
    },

    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"communes"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"communes"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"commune_delete"}
                data-cy-id={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [communeId, setCommuneId] = useState<string | null>(null);

  const show = (_id: string) => {
    setCommuneId(_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const [quickCardsCount, setQuickCardsCount] = useState<{
    former: number;
  }>();

  useEffect(() => {
    communeService.getQuickCardsCount().then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const { data: counts } = data;

      setQuickCardsCount(counts);
    });
  }, [xhrLoading]);

  const resetCardQuery = (isActive: boolean, name: "former") => {
    let _queries = queries?.filter((q) => q.name !== "former");

    if (!_queries) return;

    if (isActive && queries && _queries) {
      let __queries = _queries.filter((q) => q.name !== name);
      setQuery(__queries);
    } else {
      setQuery([
        ..._queries,
        {
          name: name,
          value: "1",
        },
      ]);
    }
  };

  return (
    <>
      <PermissionsGate section={"communes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete commune?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this commune?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"communes"} scope={"read"}>
        {communeId ? (
          <ShowCommune
            _id={communeId}
            open={Boolean(communeId)}
            setDialogue={() => setCommuneId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"communes"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "communes", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <CommuneStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            quickCards: [
              {
                title: intl.formatMessage({ id: "QUICK_CARD_FORMERS" }),
                onClick: (isActive: boolean) =>
                  resetCardQuery(isActive, "former"),
                icon: "fi-sr-inbox",
                value: quickCardsCount ? quickCardsCount.former : 0,
              },
            ],
          }}
        />
      </PermissionsGate>
    </>
  );
};

const mapStateToProps = ({ i18n, settingReducer }: any) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Communes);
