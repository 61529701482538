import React, { FC } from "react";
import SearchDropdown from "app/partials/layout/SearchDropdown";
import UserNotifications from "app/partials/layout/UserNotifications";
import LanguageSelector from "app/partials/layout/LanguageSelector";
import UserProfile from "app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../utils/utils";
import Notifications from "app/partials/layout/Notifications";
import QuickActionsPanel from "../../../app/partials/layout/QuickActionsPanel";

const Topbar = () => {
  return (
    <div className="kt-header__topbar">
      {/*<SearchDropdown useSVG={true}/>*/}
      <LanguageSelector iconType={""} />

      <QuickActionsPanel
        bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
        skin="dark"
        iconType=""
        useSVG="true"
        gridNavSkin="light"
      />

      {/*<UserNotifications*/}
      {/*  bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}*/}
      {/*  pulseLight={false}*/}
      {/*  skin="dark"*/}
      {/*  iconType=""*/}
      {/*  type="success"*/}
      {/*  useSVG={true}*/}
      {/*  dot={false}*/}
      {/*  icon=" "*/}
      {/*/>*/}

      <Notifications />

      {/*<QuickActionsPanel
          bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
          skin="dark"
          iconType=""
          useSVG="true"
          gridNavSkin="light"
        />*/}

      {/*<MyCart
          iconType=""
          useSVG="true"
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
        />*/}

      {/*<QuickPanelToggler />*/}

      <UserProfile />
    </div>
  );
};
export default Topbar;
