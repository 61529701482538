import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import Snackbar from "../../../widgets/Snackbar";
import { settingService } from "../../../services";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Controls from "../../../widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import * as setting from "app/store/ducks/setting.duck";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  title: { [key: string]: string } | null;
  active: number;
  amount: string;
  amount_type: string;
};

const initValues = {
  title: null,
  active: 1,
  amount: "",
  amount_type: "",
} as FormStateValues;

const PositionStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const { user: me } = useSelector(({ auth }: RootState) => auth);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );

  const dispatch = useDispatch();
  const addSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Add, payload: s }),
    [dispatch]
  );

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    settingService
      .add(validated, [
        {
          name: "type",
          value: "positions",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        let { data: setting } = data;
        addSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"STANDARD.NEW"}
                  defaultMessage={"STANDARD.NEW"}
                />
              </Typography>
              <Button
                form={"setting_focus_type_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"setting_focus_type_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Input
                      name={"title_en"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        if (!form.values.title) {
                          form.values.title = {};
                        }

                        form.values.title["en"] = event.target.value;

                        event.preventDefault();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">EN</InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title_en"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Input
                      name={"title_da"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        if (!form.values.title) {
                          form.values.title = {};
                        }

                        form.values.title["da"] = event.target.value;
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">DA</InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title_da"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {me?.role.slug === "super-admin" && (
                  <>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      {loaded ? (
                        <Controls.Input
                          name={"amount"}
                          label={intl.formatMessage({ id: "STANDARD.AMOUNT" })}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.amount = event.target.value;
                          }}
                        />
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            name={"amount"}
                            label={intl.formatMessage({
                              id: "STANDARD.AMOUNT",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      {loaded ? (
                        <Controls.Select
                          name={"amount_type"}
                          formId={"amount_type"}
                          options={[
                            {
                              id: "1_hour",
                              title: intl.formatMessage({
                                id: "STANDARD.ONE_HOUR",
                              }),
                            },
                            {
                              id: "1_month",
                              title: intl.formatMessage({
                                id: "STANDARD.ONE_MONTH",
                              }),
                            },
                          ]}
                          label={intl.formatMessage({
                            id: "STANDARD.AMOUNT_TYPE",
                          })}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.amount_type = event.target.value;
                          }}
                        />
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Select
                            name={"active"}
                            options={[]}
                            label={intl.formatMessage({
                              id: "STANDARD.STATUS",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  </>
                )}

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Select
                      name={"active"}
                      formId={"status"}
                      options={[
                        {
                          id: "1",
                          title: intl.formatMessage({ id: "STANDARD.ACTIVE" }),
                        },
                        {
                          id: "0",
                          title: intl.formatMessage({
                            id: "STANDARD.DEACTIVE",
                          }),
                        },
                      ]}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.active = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"active"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default PositionStore;
