import React, { FC } from "react";
import { Box, Card, Typography } from "@mui/material";
import { ICase } from "app/services/case.service";
import { FormattedMessage, useIntl } from "react-intl";

const WorkInfo: FC<ICase> = (props) => {
  const intl = useIntl();

  return (
    <Card
      sx={{
        p: 2,
        borderRadius: 2,
        border: "1px solid #E6E6E6",
        height: "100%",
      }}
    >
      <Typography variant={"h5"}>
        <FormattedMessage
          id={"CASE.INFORMATION.WORK"}
          defaultMessage={"CASE.INFORMATION.WORK"}
        />
      </Typography>
      <Box
        sx={{
          p: 2.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.935 32.8933C43.3817 27.8983 39.1383 24 34 24C21.4767 24.3233 20.505 42.1867 32.83 43.925C39.1083 44.7567 44.7217 39.175 43.935 32.8917V32.8933ZM37.3333 37.3333C36.6817 37.985 35.6283 37.985 34.9767 37.3333L32.8217 35.1783C32.5083 34.865 32.3333 34.4417 32.3333 34V30.6667C32.3433 28.4867 35.6583 28.4883 35.6667 30.6667V33.31L37.3333 34.9767C37.985 35.6283 37.985 36.6817 37.3333 37.3333ZM34 20.6667C37.9883 20.6667 41.5567 22.4267 44 25.2017V19C44 14.405 40.2617 10.6667 35.6667 10.6667H33.8317C33.0567 6.86833 29.6917 4 25.6667 4H22.3333C18.31 4 14.9433 6.86833 14.1683 10.6667H12.3333C7.73833 10.6667 4 14.405 4 19V24H25.2017C27.55 21.9317 30.625 20.6667 34 20.6667ZM22.3333 7.33333H25.6667C27.8367 7.33333 29.67 8.73167 30.36 10.6667H17.6417C18.3317 8.73167 20.165 7.33333 22.335 7.33333H22.3333ZM25.2017 44H12.3333C7.73833 44 4 40.2617 4 35.6667V27.3333H22.465C19.2333 32.515 20.5783 40.2083 25.2017 44Z"
            fill="#3DCA81"
          />
        </svg>
      </Box>
      <Box>
        <Typography variant={"body1"} sx={{ color: "#767C91" }}>
          <FormattedMessage
            id={"CASE.INFORMATION.ADMINISTRATION"}
            defaultMessage={"CASE.INFORMATION.ADMINISTRATION"}
          />
        </Typography>
        <Typography variant={"h6"}>
          {props.administration_work / 3600 / 1000}h
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant={"body1"} sx={{ color: "#767C91" }}>
          <FormattedMessage
            id={"CASE.INFORMATION.FACETOFACE"}
            defaultMessage={"CASE.INFORMATION.FACETOFACE"}
          />
        </Typography>
        <Typography variant={"h6"}>
          {props.face_to_face / 3600 / 1000}h
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant={"body1"} sx={{ color: "#767C91" }}>
          <FormattedMessage
            id={"CASE.INFORMATION.MEETINGDURATIONTYPE"}
            defaultMessage={"CASE.INFORMATION.MEETINGDURATIONTYPE"}
          />
        </Typography>
        <Typography variant={"h6"}>
          {props.meeting_duration_type?.title
            ? props.meeting_duration_type?.title[intl.locale]
            : null}
        </Typography>
      </Box>
    </Card>
  );
};

export default WorkInfo;
