import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import * as _setting from "app/store/ducks/setting.duck";
import Skeleton from "@mui/lab/Skeleton";
import CaseStore from "./store/caseStore";
import { ICommune } from "app/services/comunne.service";
import { caseService, communeService } from "app/services";
import Snackbar from "../../widgets/Snackbar";
import { IRole } from "../../interfaces";
import { ISetting } from "../../services/setting.service";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { ISettingsState } from "app/store/ducks/setting.duck";
import { useForm } from "../../hooks/useForm";
import { IFormUsersValues } from "./store/parts/users";
import { IFormFocusValues } from "./store/parts/focus";
import { IFormFinanceValues } from "./store/parts/finance";
import { IFormDatesValues } from "./store/parts/dates";
import { IFormConditionValues } from "./store/parts/condition";
import { IFormCommuneValues } from "./store/parts/commune";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";

interface IStore {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
  getSettings: Function;
  focus_areas: Array<ISetting>;
  focus_types: Array<ISetting>;
  positions: Array<ISetting>;
  communes: Array<any>;
  reports: Array<any>;
  report_types: Array<ISetting>;
  report_intervals: Array<ISetting>;
  meeting_duration_types: Array<ISetting>;
  budget_intervals: Array<ISetting>;
  roles: Array<IRole>;
  roles_pending: boolean;
}

export type FormStateValues = IFormUsersValues &
  IFormFocusValues &
  IFormFinanceValues &
  IFormDatesValues &
  IFormConditionValues &
  IFormCommuneValues;

let initValues: FormStateValues = {
  administration_work: 1,
  init_budget: 1200,
  budget_interval: "",
  budget_limit: 1200,
  commune: "",
  expire_date_commune: moment(new Date()).format("YYYY-MM-DD"),
  face_to_face: 2,
  focus_area: "",
  employee_position: "",
  employee_rate: "",
  focus_type: "",
  meeting_duration_type: "",
  report: true,
  report_type: "",
  report_interval: "",
  start_date_case: moment(new Date()).format("YYYY-MM-DD"),
  start_date_employee: moment(new Date()).format("YYYY-MM-DD"),
  start_date_finance: moment(new Date()).format("YYYY-MM-DD"),
  start_date_report: moment(new Date()).format("YYYY-MM-DD"),
  users: [],
  users_titles: [],
};

const NewCase: FC<IStore> = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const form = useForm<FormStateValues>();
  const [communes, setCommunes] = useState<Array<ICommune>>([]);
  const [confirm, setConfirm] = useState<boolean>(false);

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        let { data: _case } = data;

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));
        form.clear();

        setTimeout(() => {
          history.push(`/cases/${_case._id}`);
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleClose = () => {
    if (
      form.values.administration_work ||
      form.values.init_budget ||
      form.values.budget_interval ||
      form.values.budget_limit ||
      form.values.commune ||
      form.values.expire_date_commune ||
      form.values.face_to_face ||
      form.values.focus_area ||
      form.values.focus_type ||
      form.values.meeting_duration_type ||
      form.values.report_type ||
      form.values.report_interval ||
      form.values.start_date_case ||
      form.values.start_date_employee ||
      form.values.start_date_finance ||
      form.values.start_date_report ||
      (form.values.users && form.values.users.length > 0)
    ) {
      setConfirm(true);
    } else {
      props.setDialogue(false);
      form.clear();
    }
  };

  useEffect(() => {
    if (props.open) {
      communeService
        .getAll([
          {
            name: "pagination",
            value: "0",
          },
          {
            name: "alphabetical",
            value: "true",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: communes } = data;
          setCommunes(communes);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [props.open]);

  const saveUsers = (users: Array<string>) => {
    form.values.users = users;
  };

  return (
    <>
      {props.roles_pending ? (
        <Skeleton></Skeleton>
      ) : (
        <>
          {confirm ? (
            <ConfirmDialog
              title="Close window?"
              open={confirm}
              setOpen={() => setConfirm(false)}
              onConfirm={() => {
                props.setDialogue(false);
                form.clear();
              }}
            >
              Are you sure you want to close this window?
            </ConfirmDialog>
          ) : (
            <></>
          )}
          <form
            id={"case_form"}
            onSubmit={(e) => form.handleSubmit(e, handleSave)}
          >
            <Dialog
              fullWidth={true}
              maxWidth={"lg"}
              open={props.open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <Box
                sx={{
                  position: "relative",
                  backgroundColor: "#f5f5f5",
                  boxShadow: "none",
                  pr: 2,
                }}
              >
                <Toolbar>
                  <Typography
                    sx={{ flex: 1, color: "#000000" }}
                    variant="h6"
                    component="div"
                  >
                    <FormattedMessage
                      id={"CASE.ADD"}
                      defaultMessage={"CASE.ADD"}
                    />
                  </Typography>
                  <Button
                    form={"case_form"}
                    autoFocus
                    color="inherit"
                    type={"submit"}
                    sx={{
                      backgroundColor: "#0D99FF",
                      borderRadius: 2,
                      color: "#ffffff",
                    }}
                  >
                    <FormattedMessage
                      id={"DIALOGUE.SAVE"}
                      defaultMessage={"DIALOGUE.SAVE"}
                    />
                  </Button>
                </Toolbar>
              </Box>
              {
                <DialogContent>
                  <CaseStore
                    {...props}
                    communes={communes}
                    form={form}
                    saveUsers={saveUsers}
                  />
                </DialogContent>
              }
            </Dialog>
          </form>
        </>
      )}
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  settingReducer,
  permission,
}: {
  settingReducer: ISettingsState;
  permission: {
    roles: Array<IRole>;
    roles_pending: boolean;
  };
}) => {
  return {
    focus_areas: settingReducer.focus_areas ?? [],
    focus_types: settingReducer.focus_types ?? [],
    report_types: settingReducer.report_types ?? [],
    positions: settingReducer.positions ?? [],
    report_intervals: settingReducer.report_intervals ?? [],
    budget_intervals: settingReducer.budget_intervals ?? [],
    communes: [],
    reports: [],
    meeting_duration_types: settingReducer.meeting_duration_types ?? [],
    settings_loading: settingReducer.loading ?? [],
    roles_pending: permission.roles_pending,
    roles: permission.roles,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCase);
