import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Paper, Skeleton } from "@mui/material";
import Controls from "../../widgets/uncontrolled";
import { IRole, IUser } from "../../interfaces";
import { RootState } from "../../store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { useForm } from "../../hooks/useForm";
import Snackbar from "../../widgets/Snackbar";
import { exportService, userService } from "../../services";
import moment from "moment/moment";
import { DatePicker, Select, Space } from "antd";
import UsersGroupedSelect from "../../partials/layout/UsersGroupedSelect";

type Props = {
  lang: object;
  settings_loading: boolean;
};

const useStyles = makeStyles(() => ({
  table: {
    width: "100%",
    paddingTop: 100,
    paddingBottom: 100,
    paddingLeft: 30,
    paddingRight: 30,
  },
  submitBtn: {
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #66bb6a",
      color: "#66bb6a",
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    borderColor: "#E6E6E6",
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

type FormStateValues = {
  user: string;
  from: string;
  to: string;
};

const initValues = {
  user: "",
  from: moment.utc(new Date()).format("YYYY-MM-DD"),
  to: moment.utc(new Date()).format("YYYY-MM-DD"),
} as FormStateValues;

const Exports: FC<Props> = () => {
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>(initValues);
  const { RangePicker } = DatePicker;

  const { roles } = useSelector((state: RootState) => state.permission);
  const [employee, setEmployee] = useState<string>();
  const timeOut = useRef<any>();
  const [users, setUsers] = useState<IUser[]>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: userSearch,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  useEffect(() => {
    if (loaded) {
      const _value = roles.find((r: IRole) => r.slug === "employee")?._id;
      if (!_value) return;

      setEmployee(_value);
    }
  }, [loaded, roles]);

  const handleSubmit = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    exportService
      .getCSV([
        {
          name: "user",
          value: validated.user,
        },
        {
          name: "from",
          value: validated.from,
        },
        {
          name: "to",
          value: validated.to,
        },
      ])
      .then((data) => {
        console.log("data", data);
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const url = "data:application/vnd.ms-excel;base64," + data.data;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${validated.from}/${validated.to}.xls`);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.DOWNLOADED" }));

        setTimeout(() => {
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      })
      .finally(() => {});
  };

  return (
    <>
      <Box>
        <Paper className={classes.table}>
          <form
            id={"export_form"}
            onSubmit={(e) => {
              e.preventDefault();
              form.handleSubmit(e, handleSubmit);
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                xl={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {loaded && employee ? (
                  <Select
                    placeholder={intl.formatMessage({
                      id: "STANDARD.USER",
                    })}
                    showSearch
                    onChange={(value: string) => {
                      form.values.user = value;
                    }}
                    onSearch={(value) => {
                      sendQuery(value);
                    }}
                    filterOption={false}
                    style={{ width: "100%" }}
                    allowClear={true}
                    options={
                      users &&
                      users.map((user) => {
                        return {
                          value: user._id.toString(),
                          label: user.firstname + " " + user.lastname,
                        };
                      })
                    }
                    className={classes.antSelect}
                    onClear={() => {
                      sendQuery(" ");
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Select
                      name={"user"}
                      options={[]}
                      label={intl.formatMessage({ id: "STANDARD.USER" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {loaded ? (
                  <Space
                    className={classes.antSpace}
                    direction="vertical"
                    size={12}
                  >
                    <RangePicker
                      placeholder={[
                        intl.formatMessage({
                          id: "STANDARD.STARTDATE",
                          defaultMessage: "Start date",
                        }),
                        intl.formatMessage({
                          id: "STANDARD.ENDDATE",
                          defaultMessage: "End date",
                        }),
                      ]}
                      className={classes.datePicker}
                      onCalendarChange={(value, dateString) => {
                        if (value && dateString[0] && dateString[1]) {
                          form.values.from = dateString[0];
                          form.values.to = dateString[1];
                        }
                      }}
                    />
                  </Space>
                ) : (
                  <Skeleton width={"100%"}>
                    <Space
                      className={classes.antSpace}
                      direction="vertical"
                      size={12}
                    >
                      <RangePicker />
                    </Space>
                  </Skeleton>
                )}
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  type={"submit"}
                  form={"export_form"}
                  className={classes.submitBtn}
                  sx={{
                    bgcolor: "success.main",
                    color: "white.main",
                  }}
                >
                  <FormattedMessage
                    id={"DIALOGUE.SAVE"}
                    defaultMessage={"DIALOGUE.SAVE"}
                  />
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </>
  );
};

export default Exports;
