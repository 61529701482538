import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getRoles } from "../../crud/permission.crud";

export const actionTypes = {
  GetRoles: "Get roles",
  GetRoles_PENDING: "Get roles pending",
  GetRoles_SUCCESS: "Get roles success",
  GetRoles_ERROR: "Get roles error",
};

const initialState = {
  roles: [],
  roles_pending: false,
};

export const reducer = persistReducer(
  { storage, key: "permission", whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionTypes.GetRoles_PENDING: {
        return { ...state, roles_pending: true };
      }

      case actionTypes.GetRoles_SUCCESS: {
        return { ...state, roles_pending: false, roles: action.payload };
      }

      case actionTypes.GetRoles_ERROR: {
        return { ...state, roles_pending: false };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  getRoles: () => ({ type: actionTypes.GetRoles }),
};

export function* saga() {

  yield takeLatest(actionTypes.GetRoles, function* getRolesSaga() {

    yield put({ type: actionTypes.GetRoles_PENDING });

    try {
      const { data: roles } = yield getRoles();
      yield put({ type: actionTypes.GetRoles_SUCCESS, payload: roles });
    } catch (error) {
      yield put({ type: actionTypes.GetRoles_ERROR, error });
    }
  });

}
