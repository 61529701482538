import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { IconButton } from "@mui/material";
import { guidelineService } from "app/services";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import { IGuideline } from "../../services/guideline.service";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import EditIcon from "../../partials/icons/EditIcon";
import GuidelineShow from "./show";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import DeleteIcon from "app/partials/icons/DeleteIcon";
import { usePermissions } from "../../hooks/useRole";
import GuidelineStore from "./store";

const Guidelines = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  const { data, xhrLoading, updateQuery, refresh, remove } =
    useTable<IGuideline>({
      fetch: guidelineService.getAll,
      delete: guidelineService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const [guideline, setGuideline] = useState<string | null>(null);
  const [confirmId, setConfirmId] = useState<string | null>(null);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return params.row?.title;
      },
      minWidth: 300,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"guidelines"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"guidelines"} scope={"delete"}>
              <IconButton onClick={() => handleClick(params.row?._id)}>
                <DeleteIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (id: string) => {
    setGuideline(id);
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete guideline?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this guideline?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      <PermissionsGate section={"guidelines"} scope={"read"}>
        {guideline ? (
          <GuidelineShow
            _id={guideline}
            open={Boolean(guideline)}
            onDone={refresh}
            setDialogue={() => setGuideline(null)}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"guidelines"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "guidelines", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <GuidelineStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default Guidelines;
