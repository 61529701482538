import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { queryToString } from "../utils/http";
import { ISetting } from "./setting.service";
import { ICase } from "./case.service";

export interface IReport {
  _id: string;
  status: ISetting;
  next_status?: { status: ISetting; step: number };
  current_status_step?: number;
  case: ICase;
  content: string;
  employee_review?: number;
  moderator_review?: number;
  assigned?: IUser;
  report_date: number;
  _created_at: string;
  _updated_at: string;
}

export interface IReportStore {
  status: string;
  content: string;
  report_date: string;
  case: string;
}

const INDEX = API_ROUTES.reports;
const SHOW = API_ROUTES.report;
const UPDATE = API_ROUTES.report;
const DELETE = API_ROUTES.report_delete;

export interface IReportService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IReport> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IReport> | IRestApiError>;
  update(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IReport> | IRestApiError>;
  delete(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IReport> | IRestApiError>;
}

const reportService: IReportService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },
};

export { reportService };
