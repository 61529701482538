import { Box, Grid, Typography } from "@mui/material";
import React, { FC, memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ICase } from "../../../../services/case.service";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

export interface IFormDatesValues {
  start_date_employee?: string;
  start_date_case?: string;
  start_date_finance?: string;
  start_date_report?: string;
}

interface IDatesProps {
  case?: ICase;
  touched: boolean;
  handleChange: any;
  values: IFormDatesValues;
}

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const Dates: FC<IDatesProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FORM.STARTDATEEMPLOYEE"}
              defaultMessage={"CASE.FORM.STARTDATEEMPLOYEE"}
            />
          </Typography>
          <DatePicker
            placeholder={intl.formatMessage({
              id: "STANDARD.SELECTDATE",
              defaultMessage: "Select date",
            })}
            className={classes.datePicker}
            dropdownClassName={classes.antDropdown}
            onChange={(value, dateString) => {
              props.values.start_date_employee = dateString;
            }}
            defaultValue={
              props.case?.start_date_employee
                ? moment(props.case?.start_date_employee, "X").local()
                : undefined
            }
          />
        </Box>
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FORM.STARTDATECASE"}
              defaultMessage={"CASE.FORM.STARTDATECASE"}
            />
          </Typography>
          <DatePicker
            placeholder={intl.formatMessage({
              id: "STANDARD.SELECTDATE",
              defaultMessage: "Select date",
            })}
            className={classes.datePicker}
            dropdownClassName={classes.antDropdown}
            onChange={(value, dateString) => {
              props.values.start_date_case = dateString;
            }}
            defaultValue={
              props.case?.start_date_case
                ? moment(props.case?.start_date_case, "X").local()
                : undefined
            }
          />
        </Box>
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FORM.STARTDATEFINANCE"}
              defaultMessage={"CASE.FORM.STARTDATEFINANCE"}
            />
          </Typography>
          <Space className={classes.antSpace} direction="vertical" size={12}>
            <DatePicker
              placeholder={intl.formatMessage({
                id: "STANDARD.SELECTDATE",
                defaultMessage: "Select date",
              })}
              className={classes.datePicker}
              dropdownClassName={classes.antDropdown}
              onChange={(value, dateString) => {
                props.values.start_date_finance = dateString;
              }}
              defaultValue={
                props.case?.start_date_finance
                  ? moment(props.case?.start_date_finance, "X").local()
                  : undefined
              }
            />
          </Space>
        </Box>
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FORM.STARTDATEREPORT"}
              defaultMessage={"CASE.FORM.STARTDATEREPORT"}
            />
          </Typography>
          <Space className={classes.antSpace} direction="vertical" size={12}>
            <DatePicker
              placeholder={intl.formatMessage({
                id: "STANDARD.SELECTDATE",
                defaultMessage: "Select date",
              })}
              className={classes.datePicker}
              dropdownClassName={classes.antDropdown}
              onChange={(value, dateString) => {
                props.values.start_date_report = dateString;
              }}
              defaultValue={
                props.case?.start_date_report
                  ? moment(props.case?.start_date_report, "X").local()
                  : undefined
              }
            />
          </Space>
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(Dates);
