import React, { ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  Theme,
  ListSubheader,
} from "@mui/material";
import { SxProps } from "@mui/system";

type ComponentProps = {
  name: string;
  label?: string;
  formId?: string;
  _maxWidth?: string;
  height?: string;
  sx?: SxProps<Theme> | undefined;
  labelSx?: SxProps<Theme> | undefined;
  disableText?: string;
  value?: number | string;
  disabled?: boolean;
  defaultValue?: string | number;
  error?: string;
  onChange: (event: SelectChangeEvent<any>, child: ReactNode) => void;
  options?: Array<{
    label: string;
    values: Array<{ id: string; title: string }>;
  }>;
  inputRef?: React.Ref<any>;
};

const GroupedSelect = (props: ComponentProps) => {
  const {
    name,
    label = null,
    sx = null,
    labelSx = null,
    defaultValue,
    error = null,
    onChange,
    options,
    disabled,
    formId,
    inputRef,
  } = props;

  return (
    <>
      <FormControl disabled={disabled ?? false} id={formId} fullWidth>
        <InputLabel sx={labelSx} htmlFor="grouped-select">
          {label}
        </InputLabel>
        <MuiSelect
          inputRef={inputRef}
          sx={sx}
          defaultValue={defaultValue}
          id="grouped-select"
          label={label}
          name={name}
          onChange={onChange}
        >
          <MenuItem value="">None</MenuItem>
          {options &&
            options.map((_option) => {
              const items = _option.values.map((o) => {
                return (
                  <MenuItem sx={{ pl: 3 }} key={o.id} value={o.id}>
                    {o.title}
                  </MenuItem>
                );
              });
              return [
                <ListSubheader
                  sx={{
                    pl: 1,
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: 1.5,
                  }}
                >
                  {_option.label}
                </ListSubheader>,
                items,
              ];
            })}
        </MuiSelect>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default GroupedSelect;
