import React, { FC } from "react";
import { connect, useSelector } from "react-redux";
import objectPath from "object-path";
import * as builder from "_metronic/ducks/builder";
import { RootState } from "app/store/store";

type FooterType = {
  footerClasses: string;
  footerContainerClasses: string;
};

const Footer: FC<FooterType> = ({ footerClasses, footerContainerClasses }) => {
  const today = new Date().getFullYear();
  const businessName = useSelector(
    (state: RootState) => state.auth.business?.name
  );
  return (
    <div
      className={`kt-footer ${footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
      id="kt_footer"
    >
      <div className={`kt-container ${footerContainerClasses}`}>
        <div
          className="kt-footer__copyright"
          style={{ display: "flex", alignItems: "center" }}
        >
          {businessName}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
