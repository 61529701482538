import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import objectPath from "object-path";
import KTToggle from "../../_assets/js/toggle";
import * as builder from "../../ducks/builder";
import { ReactComponent as AngleDoubleLeftIcon } from "../assets/layout-svg-icons/Angle-double-left.svg";
import { ReactComponent as AngleDoubleRightIcon } from "../assets/layout-svg-icons/Angle-double-right.svg";
import { RootState } from "app/store/store";
import { makeStyles } from "@material-ui/core";
import { Theme } from "@mui/material";
import Typography from "@mui/material/Typography";

type BrandType = {
  toggleOptions: object;
  brandClasses: string;
  asideSelfMinimizeToggle: boolean;
};

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center"
  },
}));

const Brand: FC<BrandType> = ({
  toggleOptions,
  brandClasses,
  asideSelfMinimizeToggle,
}) => {
  const ktToggleRef = React.createRef<any>();
  const canvasRef = React.createRef<any>();
  const [image, setImage] = useState("");
  const avatar = useSelector((state: RootState) => state.auth.business?.file);
  const businessName = useSelector(
    (state: RootState) => state.auth.business?.name
  );
  const [clicked, setClicked] = useState(false);

  // constructor() {
  //   super();
  //   this.state = {
  //     image64: ""
  //   }
  // }

  useEffect(() => {
    new KTToggle(ktToggleRef.current, toggleOptions);
    runBase64();
  }, []);

  const runBase64 = () => {
    // @ts-ignore
    const c = canvasRef.current;
    const ctx = c.getContext("2d");
    ctx.font = "18px Poppins, Helvetica, sans-serif";
    ctx.fillStyle = "white";

    ctx.fillText(businessName, 0, 18);
    let data = c.toDataURL();
    setImage(data);
  };

  const classes = useStyle();

  return (
    <div
      className={`kt-aside__brand kt-grid__item ${brandClasses}`}
      id="kt_aside_brand"
    >
      <div className="kt-aside__brand-logo" style={{ height: "100%" }}>
        <Link to="" style={{ height: "100%" }}>
          <div>
            <canvas
              id="myCanvas"
              ref={canvasRef}
              width="140"
              height="25"
              style={{ border: "1px solid #d3d3d3", display: "none" }}
            />
          </div>
          {avatar ? (
            <div style={{ width: 110, height: "100%" }}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                alt="logo"
                src={avatar.base64}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="h5">
                <span>{businessName}</span>
              </Typography>
            </div>
          )}
        </Link>
      </div>

      {asideSelfMinimizeToggle && (
        <div className="kt-aside__brand-tools">
          <button
            onClick={() => {
              setClicked(!clicked);
            }}
            ref={ktToggleRef}
            className="kt-aside__brand-aside-toggler"
            id="kt_aside_toggler"
          >
            <span>
              <AngleDoubleLeftIcon />
            </span>
            <span>
              <AngleDoubleRightIcon />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: any) => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true,
    }),
    asideSelfMinimizeToggle: objectPath.get(
      store.builder.layoutConfig,
      "aside.self.minimize.toggle"
    ),
    headerLogo: builder.selectors.getLogo(store),
    headerStickyLogo: builder.selectors.getStickyLogo(store),
    toggleOptions: {
      target: "body",
      targetState: "kt-aside--minimize",
      togglerState: "kt-aside__brand-aside-toggler--active",
    },
  };
};

export default connect(mapStateToProps)(Brand);
