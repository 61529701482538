import React, { Dispatch, SetStateAction } from "react";
import TimeTrackShowDialogue from "app/partials/dialogues/show/time_track";
import Snackbar from "app/widgets/Snackbar";
import { caseService } from "app/services";
import { useIntl } from "react-intl";

interface FormProps {
  _case: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  start_date_time: string;
  end_date_time: string;
  case?: string;
  start_location: string;
  end_location: string;
  type: string;
  related: string;
};

const ShowTimeTrack = (props: FormProps) => {
  const intl = useIntl();
  const handleSave = (validated: FormStateValues, callback: () => void) => {
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .updateTimeTrack(props._case, props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          callback();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <TimeTrackShowDialogue
      _id={props._id}
      open={props.open}
      setDialogue={props.setDialogue}
      save={handleSave}
      case_needed={false}
    />
  );
};

export default ShowTimeTrack;
