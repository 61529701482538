import { IUrlQuery } from "../interfaces";

export function queryToString(params : IUrlQuery[]) : string {

  let ret = "";
  params.map((param, i, row) => {

    ret += `${param['name']}=${param['value']}`;

    if (i + 1 < row.length)ret += `&`
  })

  return ret;
}


export const generateFormData = (f: FormData, data: any) => {

  for (let key in data) {
    let is_array = Array.isArray(data[key]);
    let is_object = typeof data[key] === 'object';
    let is_bool = typeof data[key] === 'boolean';

    if (key === 'documents'){
      let documents = data[key];
      documents.map((d: File) => f.append('documents[]', d))
    }

    if (key === 'file'){
      let document = data[key];
      f.append('document', document)
    }

    if (is_array){
      let arr = data[key];
      let name = key + '[]';
      for (let i of arr){
        if (typeof i === 'object') i = JSON.stringify(i);
        f.append(name, i);
      }
    } else if (is_bool) {
      f.append(key, data[key] ? '1' : '0');
    }else if (is_object) {
      f.append(key, JSON.stringify(data[key]));
      // let obj = data[key];
      // let name = key + '[]';
      // for (let i in obj){
      //   f.append(i, JSON.stringify(obj[i]));
      // }
    } else {
      f.append(key, data[key]);
    }
  }

  return f;
}
