import React, { FC, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import Messenger from "./messenger/messenger";
import Rooms, { IRoom } from "./rooms/rooms";
import { MessengerHeaderProps } from "./messenger/Header";
import { MessengerBodyProps } from "./messenger/Body";
import { MessengerComposerType } from "./messenger/Composer";
import { ChatProfileProps } from "./messenger/Profile";

interface Props {
  me: string;
  RoomProps?: {
    active: string | null;
    onSearch?: (text: string) => void;
    onClick: (room: IRoom) => void;
    onBarClick: () => void;
    lastData: Array<IRoom>;
    onArchiveListOpen: Function;
  };
  Composer: MessengerComposerType;
  Body: MessengerBodyProps;
  Header: MessengerHeaderProps;
  Profile: ChatProfileProps;
}

const Chat: FC<Props> = (props) => {
  const { Composer, Body, RoomProps, Header, Profile, me } = props;
  const { active, onClick, onSearch, lastData, onArchiveListOpen, onBarClick } =
    RoomProps ?? {};

  const [_open, setOpen] = useState<boolean>(false);

  const memoizedRooms = useMemo(
    () => (
      <Rooms
        lastData={lastData ?? []}
        active={active ?? null}
        onArchiveListOpen={(open: boolean) => {
          onArchiveListOpen && onArchiveListOpen(open);
          setOpen(open);
        }}
        onChange={(room: IRoom) => {
          onClick && onClick(room);
        }}
        onSearch={(text: string) => {
          onSearch && onSearch(text);
        }}
        onBarClick={onBarClick}
      />
    ),
    [
      lastData,
      active,
      onArchiveListOpen,
      setOpen,
      onClick,
      onSearch,
      onBarClick,
    ]
  );

  return (
    <>
      <Grid item xs={3.5} xl={3.5}>
        {memoizedRooms}
      </Grid>
      <Grid item xs={8.5} xl={8.5} sx={{ pl: 1 }}>
        <Messenger
          Body={Body}
          Header={Header}
          Composer={Composer}
          me={me}
          Profile={Profile}
          archiveOpen={_open}
          updateRoom={Body.updateRoom}
        />
      </Grid>
    </>
  );
};

export default Chat;
