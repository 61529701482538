import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
  item: {
    icon: string;
    translate: string;
    title: string;
    badge: {
      value: string;
      type: string;
    };
    submenu: string;
  };
  parentItem: {
    bullet: string;
  };
};

const MenuItemText: FC<Props> = (props) => {
  return (
    <>
      {props.item.icon && (
        <i
          style={{ fontSize: "16px", color: "#767C91" }} //TODO: change to theme provider's palette
          className={`fi ${props.item.icon} mr-3 d-flex`}
        />
      )}

      {props.parentItem && props.parentItem.bullet === "dot" && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
          <span />
        </i>
      )}

      {props.parentItem && props.parentItem.bullet === "line" && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
          <span />
        </i>
      )}

      <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }}>
        {!props.item.translate ? (
          props.item.title
        ) : (
          <FormattedMessage
            id={props.item.translate}
            defaultMessage={props.item.title}
          />
        )}
      </span>

      {props.item.badge && (
        <span className="kt-menu__link-badge">
          <span className={`kt-badge ${props.item.badge.type}`}>
            {props.item.badge.value}
          </span>
        </span>
      )}

      {props.item.submenu && (
        <i className="kt-menu__ver-arrow la la-angle-right" />
      )}
    </>
  );
};

export default MenuItemText;
