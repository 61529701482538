import { useIntl } from "react-intl";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ListItem from "@mui/material/ListItem";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import Badge from "@mui/material/Badge";
import { makeStyles } from "@material-ui/core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { statisticService } from "../services";
import Snackbar from "./Snackbar";
import { ITimeTrackWithUsers } from "../services/statistic.service";
import moment from "moment/moment";
import NoData from "../partials/NoData";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import { IRole } from "../interfaces";

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    minWidth: 330,
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.25)",
    height: 136,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    padding: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
  iconButtonMessage: {
    "&:hover": {
      backgroundColor: "#556EE6",
    },
  },
  iconButtonProfile: {
    "&:hover": {
      backgroundColor: "#E1E6FB",
    },
  },
  popper: {
    left: "-5px!important",
  },
}));

type UserTooltipProps = {
  _id: string;
  fullname: string;
  file?: string | undefined;
  role: IRole;
};

export const UserTooltipCard = (props: UserTooltipProps) => {
  let classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, popper: classes.popper }}
      placement={"bottom-start"}
      disableFocusListener
      title={
        <Grid
          sx={{
            width: "100%",
            maxWidth: 360,
            backgroundColor: "#fff",
            color: "#4A4A4A",
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
          }}
        >
          <Grid sx={{ mt: 1, mx: 2 }}>
            <Grid container alignItems="center">
              <Grid item sm={2} xs={2} md={2} lg={2} xl={2}>
                <Avatar
                  style={{
                    backgroundColor: props?.role?.color ?? "#0D99FF",
                  }}
                  src={props.file}
                  alt={""}
                  sx={{
                    width: 36,
                    height: 36,
                    fontSize: 80,
                    borderRadius: "8px",
                  }}
                  variant="square"
                />
              </Grid>
              <Grid item sm={10} xs={10} md={10} lg={10} xl={10}>
                <Grid
                  sx={{
                    height: 36,
                    marginLeft: 2,
                  }}
                >
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 13, fontWeight: 400 }}
                  >
                    {props.fullname}
                  </Typography>
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 12, fontWeight: 400, color: "#9FA5BB" }}
                  >
                    {props?.role?.title[intl.locale]}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ m: 2 }}>
            <Grid container spacing={1}>
              <Grid item sm={5} xs={5} md={5} lg={5} xl={5}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className={classes.iconButtonMessage}
                  sx={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    backgroundColor: "#556EE6",
                  }}
                >
                  <svg
                    className={"mr-1"}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4982 2.28802L4.21497 12.5685C4.43728 12.6769 4.68112 12.7338 4.92842 12.7351H6.68957C6.8369 12.7347 6.97825 12.7933 7.08212 12.8978L8.03655 13.8516C8.55413 14.3728 9.2581 14.6661 9.99258 14.6667C10.2958 14.6664 10.597 14.6167 10.8843 14.5196C11.8746 14.1949 12.5948 13.3364 12.7426 12.3048L14.6187 3.39348C14.7083 3.02217 14.6657 2.63128 14.4982 2.28802Z"
                      fill="white"
                    />
                    <path
                      d="M12.6216 1.37634L3.73191 3.24912C2.21298 3.4578 1.1508 4.85831 1.35948 6.37724C1.44191 6.97709 1.71817 7.53358 2.1462 7.96184L3.10007 8.91572C3.20434 9.01995 3.26287 9.16137 3.26276 9.30881V11.07C3.26404 11.3173 3.32098 11.5611 3.42933 11.7834L13.7109 1.50018C13.373 1.33413 12.9881 1.29038 12.6216 1.37634Z"
                      fill="white"
                    />
                  </svg>
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 12, fontWeight: 400, color: "#fff" }}
                  >
                    Message
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item sm={5} xs={5} md={5} lg={5} xl={5}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    history.push(`users/${props._id}`);
                  }}
                  className={classes.iconButtonProfile}
                  sx={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    backgroundColor: "#E1E6FB",
                  }}
                >
                  <svg
                    className={"mr-1"}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.2381 8.00001C10.044 8.00001 11.5079 6.50763 11.5079 4.66668C11.5079 2.82573 10.044 1.33334 8.2381 1.33334C6.43222 1.33334 4.96826 2.82573 4.96826 4.66668C4.96826 6.50763 6.43222 8.00001 8.2381 8.00001Z"
                      fill="#2A3042"
                    />
                    <path
                      d="M8.23807 9.11118C5.53049 9.11425 3.33633 11.351 3.33331 14.1112C3.33331 14.418 3.5773 14.6667 3.87828 14.6667H12.5978C12.8988 14.6667 13.1428 14.418 13.1428 14.1112C13.1398 11.351 10.9456 9.11422 8.23807 9.11118Z"
                      fill="#2A3042"
                    />
                  </svg>
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 12, fontWeight: 400, color: "#2A3042" }}
                  >
                    Go to profile
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item sm={2} xs={2} md={2} lg={2} xl={2}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className={classes.iconButtonProfile}
                  sx={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    backgroundColor: "#E1E6FB",
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <Badge
        color={"primary"}
        overlap="circular"
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: "#44b700",
            color: "#44b700",
            right: 7,
            bottom: 4,
            "&::after": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              animation: "ripple 1.2s infinite ease-in-out",
              border: "1px solid currentColor",
              content: '""',
            },
          },
          "@keyframes ripple": {
            "0%": {
              transform: "scale(.8)",
              opacity: 1,
            },
            "100%": {
              transform: "scale(2.4)",
              opacity: 0,
            },
          },
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        <Avatar
          style={{
            backgroundColor: props?.role?.color ?? "#0D99FF",
            border: "1px solid " + props?.role?.color ?? "#0D99FF",
          }}
          src={props.file}
          alt={""}
          sx={{
            width: 36,
            height: 36,
            fontSize: 80,
            borderRadius: "50%",
          }}
          variant="square"
        />
      </Badge>
    </Tooltip>
  );
};

const RenderRow = (
  props: ListChildComponentProps<Array<ITimeTrackWithUsers>>
) => {
  const { index, style, data } = props;
  const [counter, setCounter] = useState<number | null>();
  const interval = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (!data[index].end_date_time && data) {
      let tictac =
        Math.round(moment().utc().valueOf() / 1000) -
        data[index].start_date_time;
      interval.current = setInterval(() => {
        tictac++;
        setCounter(tictac);
      }, 1000);
    } else {
      if (interval.current) clearInterval(interval.current);
      setCounter(null);
    }
  }, [data]);

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <Grid container spacing={1}>
        <Grid item sm={2} xs={2} md={2} lg={2} xl={2}>
          <UserTooltipCard
            _id={data[index].created_by._id}
            fullname={
              data[index].created_by.firstname +
              " " +
              data[index].created_by.lastname
            }
            file={data[index].created_by.file?.base64}
            role={data[index].created_by.role}
          />
        </Grid>
        <Grid item sm={6} xs={6} md={6} lg={6} xl={6}>
          <Box
            sx={{
              height: 36,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant={"inherit"}
              sx={{ fontSize: 16, fontWeight: 400 }}
            >
              {data[index].created_by.firstname +
                " " +
                data[index].created_by.lastname}
            </Typography>
            <Typography
              variant={"inherit"}
              sx={{ fontSize: 13, fontWeight: 400, color: "#9FA5BB" }}
            >
              {data[index]?.case?.key}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} xs={4} md={4} lg={4} xl={4}>
          <Box
            sx={{
              height: 36,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {counter ? (
              <Typography
                variant={"inherit"}
                sx={{ fontSize: 14, fontWeight: 500, color: "#9FA5BB" }}
              >
                {`
                    ${
                      Math.floor(counter / 3600) % 60 < 10
                        ? "0" + (Math.floor(counter / 3600) % 60)
                        : Math.floor(counter / 3600) % 60
                    }h.${
                  Math.floor(counter / 60) % 60 < 10
                    ? "0" + (Math.floor(counter / 60) % 60)
                    : Math.floor(counter / 60) % 60
                }m.${counter % 60 < 10 ? "0" + (counter % 60) : counter % 60}s`}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const ActiveUsers = () => {
  const intl = useIntl();
  const [timeTracks, setTimeTracks] = useState<Array<ITimeTrackWithUsers>>();

  useEffect(() => {
    statisticService
      .getActiveTimeTrackWithUsers()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: timeTracks } = data;

        setTimeTracks(timeTracks);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  return (
    <Card sx={{ padding: "0 20px", height: "316px" }}>
      <CardHeader
        titleTypographyProps={{
          fontSize: 16,
        }}
        sx={{ padding: "12px 0 12px 0" }}
        title={intl.formatMessage({ id: "CARD_HEADER.ACTIVE_USERS" })}
      />
      {timeTracks ? (
        <CardContent sx={{ padding: 0 }}>
          {timeTracks.length > 0 ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FixedSizeList
                  height={260}
                  width={360}
                  itemSize={53}
                  itemCount={timeTracks ? timeTracks.length : 0}
                  itemData={timeTracks ?? []}
                  overscanCount={5}
                >
                  {/*{renderRow}*/}
                  {RenderRow}
                </FixedSizeList>
              </Box>
            </>
          ) : (
            <NoData height={240} />
          )}
        </CardContent>
      ) : (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 240,
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Card>
  );
};

export default ActiveUsers;
