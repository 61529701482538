import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import * as _setting from "app/store/ducks/setting.duck";
import {
  Grid,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
  Box,
  ListItemAvatar,
  Avatar,
  Button,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import Controls from "../../widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import Snackbar from "../../widgets/Snackbar";
import { Ii18nState } from "../../store/ducks/i18n.duck";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

type FormStateValues = {
  answer: string;
  //documents: Array<File>;
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  activityLine: {
    "&, &:before": {
      content: "",
      position: "absolute",
      left: "11px",
      width: "0.214rem",
      top: "42px",
      bottom: "5px",
      height: "calc(22% - 5px)",
      backgroundColor: "#E7E9F5",
    },
  },
}));

const EditPlanAnswers = (props: IFormProps) => {
  const form = useForm<FormStateValues>();
  const classes = useStyles();
  const intl = useIntl();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    fetch("https://dummyjson.com/products/1", {
      method: "PUT" /* or PATCH */,
      headers: {},
      body: JSON.stringify({
        title: "",
      }),
    })
      .then((res) => res.json())
      .then(console.log);

    Snackbar.success("Updated");
    setTimeout(() => {
      props.setDialogue(false);
    }, 1000);
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage id={"CASE.EDIT"} defaultMessage={"CASE.EDIT"} />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              type={"submit"}
              form={"answer_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id={"answer_form"}
            onSubmit={(e) => {
              handleSave();
              e.preventDefault();
            }}
          >
            <Grid container sx={{ mb: 3 }}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <>
                    <Typography
                      variant={"body1"}
                      sx={{ mb: 0.5, color: "#6D6D6D", fontWeight: "500" }}
                    >
                      Question
                    </Typography>
                    <Typography
                      variant={"body1"}
                      sx={{ color: "#0D99FF", fontWeight: "500" }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
                      aliquam?
                    </Typography>
                  </>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Select
                      name={"questions"}
                      options={[]}
                      onChange={() => {}}
                      label={""}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
            <Grid container sx={{ mb: 3 }}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      Answer
                    </Typography>
                    <Box className={classes.input}>
                      <Controls.Input
                        name={"answer"}
                        rows={5}
                        multiline={true}
                        defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet
                      luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non"
                        label={""}
                        onChange={(e) => {
                          form.values.answer = e.target.value;
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"answer"}
                      onChange={() => {}}
                      label={""}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
            <>
              <Typography sx={{ fontWeight: 500 }} variant={"body1"}>
                Recent Activities
              </Typography>
              <Box sx={{ position: "relative" }}>
                <Box className={classes.activityLine}></Box>
                <Box>
                  <List>
                    <ListItem
                      alignItems="flex-start"
                      sx={{ padding: "0px!important" }}
                    >
                      <ListItemAvatar sx={{ minWidth: "36px!important" }}>
                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                          alt="John Smith"
                          src="/static/images/avatar/1.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              sx={{ color: "#0D99FF", fontWeight: 500 }}
                              component={"span"}
                            >
                              John Smith
                            </Typography>
                            <Typography component={"span"}>
                              {" "}
                              changed questions
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="#767C91"
                            >
                              Today 16:23
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem
                      alignItems="flex-start"
                      sx={{ padding: "0px!important" }}
                    >
                      <ListItemAvatar sx={{ minWidth: "36px!important" }}>
                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                          alt="Hikmat Aliyev"
                          src="/static/images/avatar/1.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              sx={{ color: "#0D99FF", fontWeight: 500 }}
                              component={"span"}
                            >
                              Hikmat Aliyev
                            </Typography>
                            <Typography component={"span"}>
                              {" "}
                              answer questions
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="#767C91"
                            >
                              29/09/2022 16:23
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: any;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPlanAnswers);
