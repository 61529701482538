import { Box, Checkbox, Grid } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import React, { FC, useEffect, useRef, useState } from "react";

interface IPalette {
  onPick: (color: string | null) => void;
  active?: number;
  defaultValue?: string | null;
}

export const Palette: FC<IPalette> = (props) => {
  const [active, setActive] = useState<number | null>();
  const colors = useRef<Array<string>>([
    "#035189",
    "#0D99FF",
    "#CBE9FF",
    "#2A3042",
    "#767C91",
    "#4F5468",
    "#2A8D5A",
    "#3DCA81",
    "#2FFF93",
    "#D78C04",
    "#FBB73C",
    "#FBC563",
    "#A52123",
    "#EC3033",
    "#F68082",
    "#C3CDFF",
    "#C9D2FF",
    "#ED6F51",
    "#F68082",
    "#1E1F20",
    "#3B3B3B",
    "#B6B6B6",
    "#D4DBF9",
    "#E4E4E4",
    "#F4F4F4",
    "#FF5858",
    "#7978FF",
    "#9E7676",
    "#C8DBBE",
    "#FFCACA",
    "#647E68",
    "#367E18",
    "#E80F88",
    "#ECB390",
    "#C9BBCF",
  ]);

  useEffect(() => {
    if (props.defaultValue) {
      let i = colors.current.indexOf(props.defaultValue);

      if (i) setActive(i);
    }
  }, []);

  return (
    <Grid container sx={{ marginTop: 1 }}>
      {colors.current.map((color, index) => {
        return (
          <Box sx={{ backgroundColor: color, width: "50px", height: "50px" }}>
            <Checkbox
              icon={<Grid sx={{ width: "50px", height: "50px" }} />}
              id={"color_" + index}
              checkedIcon={
                <CheckOutlinedIcon
                  sx={{
                    fontSize: 50,
                    border: "1px solid white",
                    color: "white !important",
                  }}
                />
              }
              checked={active !== null && index === active}
              sx={{
                backgroundColor: "",
                padding: 0,
                color: "white",
                "& .MuiSvgIcon-root": { fontSize: 50, color: "white" },
              }}
              onChange={() => {
                if (index === active) {
                  setActive(null);
                  props.onPick(null);
                } else {
                  setActive(index);
                  props.onPick(color);
                }
              }}
            />
          </Box>
        );
      })}
    </Grid>
  );
};
