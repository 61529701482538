import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import Controls from "../../../../widgets/uncontrolled";
import { useIntl } from "react-intl";
import { DatePicker, Select, Space } from "antd";
import { makeStyles } from "@material-ui/core";
import { PermissionsGate } from "../../../../permission/PermissionsGate";
import UsersGroupedSelect from "../../../../partials/layout/UsersGroupedSelect";
import { IUser } from "../../../../interfaces";
import { userService } from "../../../../services";
import Snackbar from "../../../../widgets/Snackbar";

const useStyles = makeStyles(() => ({
  calendar: {
    zIndex: 9999,
  },
  dateRangeInput: {
    width: "100%",
    borderRadius: 8,
    border: "none!important",
    "& .ant-picker-focused": {
      boxShadow: "none!important",
    },
    "& .ant-picker": {
      border: "1px solid #E6E6E6!important",
      height: 40,
      backgroundColor: "#fff",
      borderRadius: 8,
      width: "100%",
      color: "#000000!important",
      "& .ant-picker-input input::placeholder": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiInputLabel-root": {
        top: "-5px",
      },
      height: 40,
      backgroundColor: "#fff",
      border: "1px solid #E6E6E6",
      borderRadius: 8,
      m: () => ({}),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
    },
  },
  antSelect: {
    "& .ant-select-selector": {
      height: 40,
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
  },
}));

const ReportFilter = (props: { show: boolean }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const timeOut = useRef<any>();
  const [users, setUsers] = useState<IUser[]>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  useEffect(() => {
    if (userSearch.length > 0) {
      let queries = [
        {
          name: "pagination",
          value: "1",
        },
        {
          name: "keyword",
          value: userSearch,
        },
      ];

      userService
        .getAll(queries)
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: users } = data;

          setUsers(users);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserChange = (value: string | string[]) => {
    // handleQuery("user", value.toString());
  };

  return (
    <>
      <PermissionsGate section={"case_evalreports"} scope={"create"}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </PermissionsGate>
      <Box
        sx={{
          overflow: "hidden",
          transition: ".2s all",
          height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
        }}
        ref={contentRef}
      >
        <Grid container spacing={1}>
          <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
            <Select
              mode="multiple"
              placeholder="Users"
              defaultValue={[]}
              onChange={handleUserChange}
              onSearch={(value) => {
                sendQuery(value);
              }}
              onClear={() => {
                sendQuery("");
              }}
              allowClear={true}
              filterOption={false}
              style={{ width: "100%" }}
              options={
                users &&
                users.map((user) => {
                  return {
                    value: user._id.toString(),
                    label: user.firstname + " " + user.lastname,
                  };
                })
              }
              className={classes.antSelect}
              maxTagCount={"responsive"}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
            <Space direction="vertical" className={classes.dateRangeInput}>
              <DatePicker
                placeholder={intl.formatMessage({ id: "STANDARD.CREATEDAT" })}
                dropdownClassName={classes.calendar}
              />
            </Space>
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
            <Select
              mode="multiple"
              placeholder="Users"
              defaultValue={[]}
              onChange={handleUserChange}
              onSearch={(value) => {
                sendQuery(value);
              }}
              filterOption={false}
              style={{ width: "100%" }}
              options={
                users &&
                users.map((user) => {
                  return {
                    value: user._id.toString(),
                    label: user.firstname + " " + user.lastname,
                  };
                })
              }
              className={classes.antSelect}
              maxTagCount={"responsive"}
              onClear={() => {
                sendQuery("");
              }}
              allowClear={true}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
            <Box className={classes.input}>
              <Controls.Input
                name={"title"}
                label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                onChange={(event) => {
                  if (!event.target.value) return;
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReportFilter;
