import React from "react";
import { usePermissions } from "app/hooks/useRole";
import { IPermission } from "../../interfaces";

export type PermissionsType =
  | "settings"
  | "vacancies"
  | "mailboxes"
  | "surveys"
  | "logs"
  | "reports"
  | "exports"
  | "statistics"
  | "cases"
  | "contracts"
  | "case_tradingplans"
  | "case_evalreports"
  | "activity_payments"
  | "case_timerecords"
  | "users"
  | "chat"
  | "message"
  | "todos"
  | "accounting"
  | "notes"
  | "documents"
  | "guidelines"
  | "values"
  | "knowledgebase"
  | "communes"
  | "contract_templates"
  | "time_records"
  | "time_record_switch"
  | "offers"
  | "projects"
  | "tasks"
  | "time_track_user"
  | "incoming_offers"
  | "user_payment";

export type PermissionsFilterType =
  | "viewAny_attached_todo"
  | "update_attached_todo"
  | "delete_attached_todo"
  | "viewAny_todo_note"
  | "update_todo_note"
  | "delete_todo_note"
  | "viewAny_contract_note"
  | "update_contract_note"
  | "delete_contract_note"
  | "viewAny_vacancy_note"
  | "update_vacancy_note"
  | "delete_vacancy_note"
  | "viewAny_case_trading_plan_note"
  | "update_case_trading_plan_note"
  | "delete_case_trading_plan_note"
  | "viewAny_activity_payment_note"
  | "update_activity_payment_note"
  | "delete_activity_payment_note"
  | "viewAny_attached_note"
  | "update_attached_note"
  | "delete_attached_note"
  | "viewAny_attached_case"
  | "update_attached_case"
  | "delete_attached_case"
  | "viewAny_attached_chat"
  | "update_attached_chat"
  | "delete_attached_chat"
  | "viewAny_contract_auto_todo"
  | "update_contract_auto_todo"
  | "delete_contract_auto_todo"
  | "viewAny_himself"
  | "update_himself"
  | "delete_himself"
  | "viewAny_created_by"
  | "update_created_by"
  | "delete_created_by";

export type Abilities =
  | "create"
  | "read"
  | "update"
  | "delete"
  | "leave"
  | "remove_user"
  | "sign";

export const hasPermission = (
  permissions: IPermission,
  section: PermissionsType,
  scope: Abilities
): boolean | null => {
  return (
    permissions?.structure &&
    permissions.structure[section]?.abilities.includes(scope)
  );
};

export const hasPermissionFilter = ({
  permissions,
  section,
  params,
}: {
  permissions: IPermission;
  section: PermissionsType;
  params: PermissionsFilterType;
}): boolean | null => {
  return (
    permissions?.structure &&
    permissions.structure[section]?.params.includes(params)
  );
};

export const PermissionsGate = ({
  children,
  section,
  scope,
  error = false,
}: {
  children: JSX.Element;
  section: PermissionsType;
  scope: Abilities;
  error?: boolean;
}) => {
  const permissions = usePermissions();

  if (!permissions) return <>Permission error</>;

  const permissionGranted = hasPermission(permissions, section, scope);

  if (!permissionGranted) return <></>;

  return <>{children}</>;
};
