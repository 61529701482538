import { IUrlQuery, IRestApiCollection, IRestApiError, IRestApiResource, IRole } from "../interfaces";
import {http} from "../lib/http";
import {API_ROUTES, getRoute} from "../config/apiRoutes";
import {generateFormData, queryToString} from "../utils/http";

const INDEX = API_ROUTES.roles;
const SHOW = API_ROUTES.role;
const UPDATE = API_ROUTES.role;
const STORE = API_ROUTES.roles;
const DELETE = API_ROUTES.role;

export interface IRoleService {
  getAll(queries?: Array<IUrlQuery>): Promise<IRestApiCollection<IRole> | IRestApiError>,
  getOne(_id: string): Promise<IRestApiResource<IRole> | IRestApiError>,
  update(_id: string, data: IRole): Promise<IRestApiResource<IRole> | IRestApiError>,
  delete(_id: string): Promise<IRestApiCollection<IRole> | IRestApiError>,
  add(data: any,queries?: Array<IUrlQuery>): Promise<IRestApiResource<IRole> | IRestApiError>,
}

const roleService : IRoleService = {

  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `${queryToString(queries)}` : '';
    return  http(`${getRoute(INDEX)}?${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return  http(`${getRoute(SHOW).replace(':_id', _id.toString())}`, "get");
  },

  async update(_id: string, data: IRole) {
    let jsonData = JSON.stringify(data);
    return  http(`${getRoute(UPDATE).replace(':_id', _id.toString())}`, "put", jsonData);
  },

  async delete(_id: string,queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `${queryToString(queries)}` : '';

    return  http(`${getRoute(DELETE).replace(':_id', _id.toString())}?${queriesString}`, "delete");
  },

  async add(data: IRole) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

}

export {roleService};
