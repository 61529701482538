import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  Slider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import {
  columnService,
  projectService,
  taskCommentService,
  taskService,
} from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "../../hooks/useForm";
import moment from "moment";
import UserAvatar from "../../partials/layout/UserAvatar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";
import SelectUsers, { SelectedUsers } from "../../partials/SelectUsers";
import { ITask } from "../../services/task.service";
import LabelsModal from "./labels";
import { IProject } from "app/services/project.service";
import AttachmentsInfo from "../Cases/show/parts/attachmentsInfo";
import FogitoChecklist from "./components/Checklist";
import { ITaskComment } from "../../services/taskComments.service";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import { IColumn } from "../../services/column.service";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
  iconButtonAdd: {
    "&:hover": {
      backgroundColor: "#556EE6!important",
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
}));

interface IFormProps {
  _id: string;
  open: boolean;
  onDone: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

type FormStateValues = {
  title: string;
  description: string;
  project_id: string;
  status: string;
  users: string[];
  files: string[];
  labels: string[];
  checklist: any[];
  start: string;
  end: string;
  text?: string;
  priority: number;
};

const TaskShow: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { user: me } = useSelector(({ auth }: RootState) => auth);
  const [labelDialog, setLabelDialogue] = useState<boolean>(false);
  const [task, setTask] = useState<ITask>();
  const [taskComments, setTaskComments] = useState<ITaskComment[]>();
  const form = useForm<FormStateValues>();
  const [usersDialog, setUsersDialog] = useState<boolean | null>(false);
  const [closeModal, setCloseModal] = useState<boolean | null>(false);
  const [selectedUsers, setSelectedUsers] = useState<Array<SelectedUsers>>([]);
  const [sliderColor, setSliderColor] = useState<string>("#035189");
  const [moveTo, setMoveTo] = useState<string | null>(null);
  const [moveToCol, setMoveToCol] = useState<string | null>(null);
  const [positions, setPositions] = useState<number[]>();
  const [position, setPosition] = useState<number>();
  const [initComment, setInitComment] = useState<string>("");
  const [moveModal, setMoveModal] = useState<boolean>(false);
  const [confirmDeleteComment, setConfirmDeleteComment] = useState<
    string | null
  >(null);
  const [editComment, setEditComment] = useState<number | undefined>(undefined);
  const { labels } = useSelector((state: RootState) => state.settingReducer);
  const [_labels, setLabels] = useState<string[]>([]);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [params, setParams] = useState<any>({ checklist: [] });
  const inputRef = useRef<any>();

  const handleClose = () => {
    setCloseModal(true);
  };

  useEffect(() => {
    form.values.files = files?.attachments?.map((file: any) => file._id);
  }, [files]);

  useEffect(() => {
    if (params.checklist) form.values.checklist = params.checklist;
  }, [params]);

  useEffect(() => {
    form.values.users = selectedUsers.map((selectedUser) => selectedUser._id);
  }, [selectedUsers]);

  useEffect(() => {
    if (_labels) form.values.labels = _labels.map((label) => label);
  }, [_labels]);

  useEffect(() => {
    if (props.open) {
      projectService
        .getMinList()
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }
          const { data: projects } = data;

          setProjects(projects);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [props.open]);

  useEffect(() => {
    if (moveTo) {
      columnService
        .getAll([{ name: "project", value: moveTo }])
        .then((data) => {
          if ("error" in data) throw new Error(data.error.message);

          const { data: columns } = data;

          const curr =
            columns.find((column: IColumn) => column._id === task?.column) ??
            columns.find((column: IColumn) => column._id === moveToCol);

          if (curr) {
            const numbers = Array.from(
              { length: curr.cards_count },
              (_, i) => i + 1
            );

            setPositions(numbers);
          }
          setColumns(columns);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [moveTo]);

  useEffect(() => {
    const curr = columns.find((column: IColumn) => column._id === moveToCol);
    if (curr) {
      const count = curr.cards_count === 0 ? 1 : curr.cards_count;
      const numbers = Array.from({ length: count }, (_, i) => i + 1);

      setPositions(numbers);
    }
  }, [moveToCol]);

  const getTask = () => {
    taskService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: task } = data;
        setTask(task);

        if (task.project?._id) setMoveTo(task.project._id);
        if (task.column) setMoveToCol(task.column);
        if (task.position) setPosition(task.position);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const loadComments = () => {
    taskCommentService
      .getAll(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: taskComments } = data;
        setTaskComments(taskComments);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };
  useEffect(() => {
    getTask();
    loadComments();
  }, [props._id]);

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    taskService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const valueText = (value: number) => {
    let result: string = "Low (0)";
    setSliderColor("#035189");
    if (value > 0 && value < 5) {
      result = "Low (" + value + ")";
      setSliderColor("rgb(138, 94, 217)");
    } else if (value >= 5 && value < 8) {
      result = "Medium (" + value + ")";
      setSliderColor("rgb(253, 215, 77)");
    } else if (value >= 8 && value <= 10) {
      result = "High (" + value + ")";
      setSliderColor("rgb(245, 54, 92)");
    }

    return result;
  };

  useEffect(() => {
    if (task) {
      form.values.title = task.title;
      form.values.description = task.description;
      form.values.project_id = task.project?._id;
      form.values.users = task.users?.map((user) => user._id);
      form.values.labels = task.labels?.map((label) => label);
      form.values.status = task.status.value;
      form.values.start = (
        moment.unix(+task.start).utc().valueOf() / 1000
      ).toString();
      form.values.end = (
        moment.unix(+task.end).utc().valueOf() / 1000
      ).toString();
      form.values.priority = task.priority;
      setSelectedUsers(
        task.users?.map((user) => {
          return {
            _id: user._id,
            firstname: user.firstname,
            lastname: user.lastname,
            role: user.role,
            file: user.file ?? undefined,
          };
        })
      );
      setLabels(
        task.labels?.map((label) => {
          return label;
        })
      );
      setParams({ checklist: task.checklist });
      form.values.checklist = task.checklist;
    }
  }, [task]);

  const handleComment = (text: string) => {
    if (task) {
      taskCommentService.add(task._id, { text: text }).then((data) => {
        if ("error" in data) throw new Error(data.error.message);

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));
        setInitComment("");
        if (inputRef.current) {
          inputRef.current.value = "";
        }
        loadComments();
      });
    }
  };

  const updateComment = (commentId: string, text: string | undefined) => {
    if (task && text) {
      taskCommentService
        .update(task._id, commentId, { text: text })
        .then((data) => {
          if ("error" in data) throw new Error(data.error.message);

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
          loadComments();
          setTimeout(() => {
            setEditComment(undefined);
          }, 1000);
        });
    }
  };

  const handleDeleteComment = (commentId: string) => {
    if (task) {
      taskCommentService.delete(task._id, commentId).then((data) => {
        if ("error" in data) throw new Error(data.error.message);

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.DELETED" }));
        loadComments();
        setTimeout(() => {
          setEditComment(undefined);
        }, 1000);
      });
    }
  };

  const handleLeave = () => {
    if (task) {
      taskService.leave(task._id).then((data) => {
        if ("error" in data) {
          Snackbar.error(data.error.message);
        }

        getTask();
      });
    }
  };

  const handleMove = () => {
    if (moveTo && task && moveToCol && position) {
      // taskService.move(task._id, { to: moveTo }).then((data) => {
      //   if ("error" in data) {
      //     Snackbar.error(data.error.message);
      //   }
      //
      //   props.setDialogue(false);
      //   props.onDone();
      // });

      columnService
        .moveCard({
          id: task._id,
          project: moveTo,
          column: moveToCol,
          position: position,
        })
        .then((data) => {
          if ("error" in data) {
            Snackbar.error(data.error.message);
          }

          props.setDialogue(false);
          props.onDone();
        });
    } else {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.INCOMPLETE_REQUEST",
          defaultMessage: "Incomplete request",
        })
      );
    }
  };

  const handleComplete = () => {
    if (task) {
      taskService.complete(task._id).then((data) => {
        if ("error" in data) {
          Snackbar.error(data.error.message);
        }

        getTask();
      });
    }
  };

  const handleStart = () => {
    if (task) {
      taskService.start(task._id).then((data) => {
        if ("error" in data) {
          Snackbar.error(data.error.message);
        }

        getTask();
      });
    }
  };

  return (
    <>
      <Dialog
        open={moveModal}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={() => setMoveModal(false)}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage id={"MOVE.TASK"} defaultMessage={"Move task"} />
          </Typography>
          <Button
            form={"move-task"}
            autoFocus
            color="inherit"
            type={"submit"}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage id={"DIALOGUE.MOVE"} defaultMessage={"Move"} />
          </Button>
        </Toolbar>
        <DialogContent>
          <form
            id={"move-task"}
            onSubmit={(e) => form.handleSubmit(e, handleMove)}
          >
            <Grid container spacing={1}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                <Box className={classes.input}>
                  <Controls.Select
                    options={
                      projects &&
                      projects.map((project) => {
                        return {
                          id: project._id.toString(),
                          title: project.title,
                        };
                      })
                    }
                    formId={"status_pick"}
                    defaultValue={task?.project?._id}
                    name={"status"}
                    label={""}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      setMoveTo(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                <Box className={classes.input}>
                  <Controls.Select
                    options={
                      columns &&
                      columns.map((column) => {
                        return {
                          id: column._id.toString(),
                          title: column.title,
                        };
                      })
                    }
                    formId={"status_pick"}
                    defaultValue={task?.column}
                    name={"status"}
                    label={""}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      setMoveToCol(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                <Box className={classes.input}>
                  <Controls.Select
                    options={
                      positions &&
                      positions.map((column) => {
                        return {
                          id: column,
                          title: column.toString(),
                        };
                      })
                    }
                    formId={"status_pick"}
                    defaultValue={task?.position}
                    name={"status"}
                    label={""}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      setPosition(+event.target.value);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {closeModal && (
        <ConfirmDialog
          title="Close modal?"
          open={Boolean(closeModal)}
          setOpen={() => setCloseModal(false)}
          onConfirm={() => {
            props.setDialogue(false);
            props.onDone();
            setCloseModal(false);
          }}
        >
          Are you sure you want to close modal?
        </ConfirmDialog>
      )}
      {confirmDeleteComment && (
        <ConfirmDialog
          title="Delete comment?"
          open={Boolean(confirmDeleteComment)}
          setOpen={() => setConfirmDeleteComment(null)}
          onConfirm={() => {
            handleDeleteComment(confirmDeleteComment);
          }}
        >
          Are you sure you want to delete this task comment?
        </ConfirmDialog>
      )}
      {labelDialog && (
        <LabelsModal
          open={labelDialog}
          labels={_labels}
          setDialogue={() => setLabelDialogue(false)}
          onClose={(labels: string[]) => {
            setLabels(labels);
          }}
        />
      )}
      <SelectUsers
        open={Boolean(usersDialog)}
        Title={
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage id={"CASE.USERS"} defaultMessage={"CASE.USERS"} />
          </Typography>
        }
        handleClose={() => {
          setUsersDialog(null);
        }}
        handleSave={(selectedUsers: Array<SelectedUsers>) => {}}
        onUserChange={(users) => {
          setSelectedUsers([...users]);
        }}
        users={selectedUsers}
      />
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"TASK.SHOW"}
                  defaultMessage={"Show task"}
                />
              </Typography>
              <Button
                form={"task_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"task_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && task ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        defaultValue={task.title}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;

                          event.preventDefault();
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && task ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.PROJECTS",
                            defaultMessage: "Projects",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Select
                        options={
                          projects &&
                          projects.map((project) => {
                            return {
                              id: project._id.toString(),
                              title: project.title,
                            };
                          })
                        }
                        formId={"status_pick"}
                        defaultValue={task.project?._id}
                        name={"status"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.project_id = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        options={[]}
                        name={"status"}
                        label={intl.formatMessage({
                          id: "STANDARD.STATUS",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && task ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.DESCRIPTION"}
                          defaultMessage={"STANDARD.DESCRIPTION"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"description"}
                        defaultValue={task.description}
                        label={""}
                        multiline={true}
                        rows={6}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.description = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({ id: "CASE.USERS" })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"LABEL.SELECTUSER"}
                        defaultMessage={"LABEL.SELECTUSER"}
                      />
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
                      {selectedUsers &&
                        selectedUsers.map((user) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UserAvatar user={user} />
                              <IconButton
                                onClick={() => {
                                  setSelectedUsers(
                                    selectedUsers.filter(
                                      (s_user) => s_user._id !== user._id
                                    )
                                  );
                                }}
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  padding: "5px",
                                  backgroundColor: "#EC3033",
                                  "&:hover": { backgroundColor: "#EC3033" },
                                }}
                              >
                                <CloseOutlinedIcon
                                  sx={{
                                    color: "#fff",
                                    width: 10,
                                    height: 10,
                                  }}
                                />
                              </IconButton>
                            </Box>
                          );
                        })}
                      <Avatar sx={{ width: 44, height: 44 }}>
                        <Button
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "100%",
                            p: "14px!important",
                            backgroundColor: "#0D99FF",
                          }}
                          variant="contained"
                          onClick={() => {
                            setUsersDialog(true);
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.75 10.75H13.25V3.25C13.25 2.55965 12.6904 2 12 2C11.3096 2 10.75 2.55965 10.75 3.25V10.75H3.25C2.55965 10.75 2 11.3096 2 12C2 12.6904 2.55965 13.25 3.25 13.25H10.75V20.75C10.75 21.4404 11.3096 22 12 22C12.6904 22 13.25 21.4404 13.25 20.75V13.25H20.75C21.4404 13.25 22 12.6904 22 12C22 11.3096 21.4404 10.75 20.75 10.75Z"
                              fill="white"
                            />
                          </svg>
                        </Button>
                      </Avatar>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}></Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && task ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.PRIORITY"}
                          defaultMessage={"STANDARD.PRIORITY"}
                        />
                      </Typography>
                      <Box
                        sx={{
                          height: 50,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0 15px",
                        }}
                      >
                        <Slider
                          aria-label="Temperature"
                          defaultValue={+task.priority}
                          getAriaValueText={valueText}
                          valueLabelFormat={valueText}
                          valueLabelDisplay="auto"
                          step={1}
                          min={0}
                          max={10}
                          onChange={(event, value) => {
                            if (!value) return;

                            form.values.priority = +value;
                          }}
                          sx={{
                            color: sliderColor,
                            transition: "all 0.3s",
                            "&.MuiSlider-rail": {
                              transition: "all 0.3s",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        options={[]}
                        name={"status"}
                        label={intl.formatMessage({
                          id: "STANDARD.STATUS",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Typography variant={"body1"} sx={{ mb: 1 }}>
                    <FormattedMessage
                      id={"STANDARD.CHECKLIST"}
                      defaultMessage={"Checklist"}
                    />
                  </Typography>
                  <FogitoChecklist {...{ params, setParams }} />
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && task ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.LABEL"}
                          defaultMessage={"Label"}
                        />
                      </Typography>
                      <Box
                        sx={{
                          height: 50,
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        {_labels?.map((label) => {
                          const currentLabel = labels?.find((lab) => {
                            return lab._id === label;
                          });
                          return (
                            currentLabel && (
                              <>
                                <Box sx={{ position: "relative" }}>
                                  <Box
                                    sx={{
                                      backgroundColor: currentLabel.color,
                                      width: "fit-content",
                                      height: "30px",
                                      borderRadius: 1,
                                      color: "#fff",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      padding: "0 10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setLabelDialogue(true)}
                                  >
                                    {currentLabel.title["en"]}
                                  </Box>
                                  <IconButton
                                    onClick={() => {
                                      setLabels(
                                        _labels.filter((x) => x !== label)
                                      );
                                    }}
                                    sx={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "-5px",
                                      padding: "5px",
                                      backgroundColor: "#EC3033",
                                      "&:hover": { backgroundColor: "#EC3033" },
                                    }}
                                  >
                                    <CloseOutlinedIcon
                                      sx={{
                                        color: "#fff",
                                        width: 10,
                                        height: 10,
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              </>
                            )
                          );
                        })}
                        <IconButton
                          className={`${classes.iconButtonAdd}`}
                          sx={{
                            width: "60px",
                            height: "30px",
                            display: "flex",
                            backgroundColor: "#556EE6",
                            borderRadius: 1,
                          }}
                          onClick={() => setLabelDialogue(true)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75V9.25H4.75C4.33579 9.25 4 9.58579 4 10C4 10.4142 4.33579 10.75 4.75 10.75H9.25V15.25C9.25 15.6642 9.58579 16 10 16C10.4142 16 10.75 15.6642 10.75 15.25V10.75H15.25C15.6642 10.75 16 10.4142 16 10C16 9.58579 15.6642 9.25 15.25 9.25H10.75V4.75Z"
                              fill="white"
                            />
                          </svg>
                        </IconButton>
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        options={[]}
                        name={"status"}
                        label={intl.formatMessage({
                          id: "STANDARD.STATUS",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && task ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1, mt: 2.1 }}>
                        <FormattedMessage
                          id={"CASE.TIMETRACK.STARTDATE"}
                          defaultMessage={"CASE.TIMETRACK.STARTDATE"}
                        />
                      </Typography>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          showTime={{ format: "HH:mm" }}
                          format={"DD-MM-YYYY HH:mm"}
                          className={classes.datePicker}
                          dropdownClassName={classes.antDropdown}
                          defaultValue={
                            task.start
                              ? moment(task.start, "X").local()
                              : undefined
                          }
                          onChange={(value, dateString) => {
                            form.values.start = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                          }}
                        />
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && task ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1, mt: 2.1 }}>
                        <FormattedMessage
                          id={"CASE.TIMETRACK.ENDDATE"}
                          defaultMessage={"CASE.TIMETRACK.ENDDATE"}
                        />
                      </Typography>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          showTime={{ format: "HH:mm" }}
                          format={"DD-MM-YYYY HH:mm"}
                          className={classes.datePicker}
                          defaultValue={
                            task.end ? moment(task.end, "X").local() : undefined
                          }
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.end = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                          }}
                        />
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && task ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"STANDARD.FILES"}
                          defaultMessage={"STANDARD.FILES"}
                        />
                      </Typography>
                      <AttachmentsInfo setParams={setFiles} params={task} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "STANDARD.ACTIONS",
                        defaultMessage: "Actions",
                      })}
                      sx={{
                        backgroundColor: "#0D99FF",
                        color: "#ffffff",
                      }}
                    />
                  </Divider>
                </Grid>
                <Grid item sm={12} xs={12} md={8} lg={8} xl={8}>
                  <Box className={classes.input} sx={{ position: "relative" }}>
                    <Typography
                      variant={"body1"}
                      sx={{ mb: 1, mt: 2.1 }}
                      fontWeight={600}
                    >
                      <FormattedMessage
                        id={"STANDARD.COMMENTS"}
                        defaultMessage={"Comments"}
                      />
                    </Typography>
                    <Controls.Input
                      name={"description"}
                      label={""}
                      multiline={true}
                      inputRef={inputRef}
                      value={initComment}
                      rows={4}
                      placeholder={intl.formatMessage({
                        id: "STANDARD.WRITECOMMENT",
                        defaultMessage: "Write a comment",
                      })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        setInitComment(event.target.value);
                      }}
                      InputProps={{
                        // @ts-ignore
                        "data-cy": "todo_description",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: "#0D99FF",
                        }}
                        onClick={() => handleComment(initComment)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill={"#FFF"}
                        >
                          <path d="M5.521,19.9h5.322l3.519,3.515a2.035,2.035,0,0,0,1.443.6,2.1,2.1,0,0,0,.523-.067,2.026,2.026,0,0,0,1.454-1.414L23.989,1.425Z" />
                          <path d="M4.087,18.5,22.572.012,1.478,6.233a2.048,2.048,0,0,0-.886,3.42l3.495,3.492Z" />
                        </svg>
                      </IconButton>
                    </Box>
                    <Box>
                      {taskComments?.map((taskComment, index) => {
                        return (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                gap: "10px",
                                mt: 1,
                              }}
                            >
                              <Box sx={{ width: "10%" }}>
                                <UserAvatar user={taskComment.created_by} />
                              </Box>
                              <Box sx={{ width: "90%" }}>
                                <Typography
                                  variant={"body1"}
                                  sx={{ fontWeight: 600, mb: 1 }}
                                >
                                  {taskComment.created_by.firstname +
                                    " " +
                                    taskComment.created_by.lastname +
                                    " - " +
                                    moment
                                      .unix(+taskComment.created_at)
                                      .local()
                                      .format("DD/MM/YYYY HH:mm")}
                                </Typography>
                                {editComment == index ? (
                                  <Box className={classes.input}>
                                    <Controls.Input
                                      name={"description"}
                                      label={""}
                                      multiline={true}
                                      rows={4}
                                      defaultValue={taskComment.text}
                                      placeholder={intl.formatMessage({
                                        id: "STANDARD.WRITECOMMENT",
                                        defaultMessage: "Write a comment",
                                      })}
                                      onChange={(event) => {
                                        if (!event.target.value) return;

                                        form.values.text = event.target.value;
                                      }}
                                      InputProps={{
                                        // @ts-ignore
                                        "data-cy": "todo_description",
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      backgroundColor: "#f0f2f5",
                                      borderRadius: "10px",
                                      padding: "10px",
                                    }}
                                  >
                                    {taskComment.text}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                mt: 1,
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {me?.role.slug == "super-admin" ||
                              me?.role.slug == "moderator" ||
                              me?._id == taskComment.created_by._id ? (
                                editComment == index ? (
                                  <>
                                    <Button
                                      onClick={() =>
                                        updateComment(
                                          taskComment._id,
                                          form.values.text
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      onClick={() => setEditComment(undefined)}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      onClick={() => setEditComment(index)}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        setConfirmDeleteComment(taskComment._id)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </>
                                )
                              ) : (
                                <></>
                              )}
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                  <Box>
                    <Typography
                      variant={"body1"}
                      fontWeight={600}
                      sx={{ mb: 1 }}
                    >
                      {intl.formatMessage({
                        id: "STANDARD.ACTIONS",
                        defaultMessage: "Actions",
                      })}
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "#f0f2f5",
                        mb: 1,
                        padding: "10px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleLeave()}
                    >
                      <svg
                        id="Layer_1"
                        height="14"
                        viewBox="0 0 24 24"
                        width="14"
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                        style={{ marginRight: "10px" }}
                      >
                        <path d="m17 24a1 1 0 0 1 -1-1 7 7 0 0 0 -14 0 1 1 0 0 1 -2 0 9 9 0 0 1 18 0 1 1 0 0 1 -1 1zm6-11h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zm-14-1a6 6 0 1 1 6-6 6.006 6.006 0 0 1 -6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0 -4-4z" />
                      </svg>
                      Leave
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "#f0f2f5",
                        mb: 1,
                        padding: "10px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => setMoveModal(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="arrow-circle-down"
                        viewBox="0 0 24 24"
                        width="14"
                        height="14"
                        style={{ marginRight: "10px" }}
                      >
                        <path d="M23.351,10.253c-.233-.263-.462-.513-.619-.67L20.487,7.3a1,1,0,0,0-1.426,1.4l2.251,2.29L21.32,11H13V2.745l2.233,2.194a1,1,0,0,0,1.4-1.426l-2.279-2.24c-.163-.163-.413-.391-.674-.623A2.575,2.575,0,0,0,12.028.006.28.28,0,0,0,12,0l-.011,0a2.584,2.584,0,0,0-1.736.647c-.263.233-.513.462-.67.619L7.3,3.513A1,1,0,1,0,8.7,4.939l2.29-2.251L11,2.68V11H2.68l.015-.015L4.939,8.7A1,1,0,1,0,3.513,7.3L1.274,9.577c-.163.163-.392.413-.624.675A2.581,2.581,0,0,0,0,11.989L0,12c0,.01.005.019.006.029A2.573,2.573,0,0,0,.65,13.682c.233.262.461.512.618.67l2.245,2.284a1,1,0,0,0,1.426-1.4L2.744,13H11v8.32l-.015-.014L8.7,19.062a1,1,0,1,0-1.4,1.425l2.278,2.239c.163.163.413.391.675.624a2.587,2.587,0,0,0,3.43,0c.262-.233.511-.46.669-.619l2.284-2.244a1,1,0,1,0-1.4-1.425L13,21.256V13h8.256l-2.2,2.233a1,1,0,1,0,1.426,1.4l2.239-2.279c.163-.163.391-.413.624-.675A2.589,2.589,0,0,0,23.351,10.253Z" />
                      </svg>
                      Move
                    </Box>
                    {task && task.status.value == "not_started" && (
                      <Box
                        sx={{
                          backgroundColor: "#f0f2f5",
                          mb: 1,
                          padding: "10px",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleStart();
                        }}
                      >
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            style={{ marginRight: "10px" }}
                            width="14"
                            height="14"
                          >
                            <path d="m12.823,8.883l-.294.235c-.315.251-.764.252-1.08.002l-.3-.237c-1.026-.812-1.732-1.684-2.099-2.592-.108-.268-.076-.572.085-.811.162-.239.431-.383.72-.383h2.132s2.131,0,2.131,0c.289,0,.559.144.72.383.161.239.193.543.085.811-.366.904-1.073,1.777-2.1,2.593Zm8.129,10.242c.186,1.236-.18,2.493-1.001,3.447-.834.968-2.043,1.523-3.319,1.523H7.368c-1.275,0-2.485-.556-3.318-1.523-.823-.956-1.188-2.213-1.002-3.452.382-2.542,1.704-4.899,3.936-7.025-2.232-2.125-3.554-4.482-3.936-7.025-.186-1.238.179-2.496,1.002-3.451C4.883.652,6.093.097,7.368.097h9.264c1.275,0,2.485.555,3.319,1.522.822.955,1.187,2.211,1.001,3.448-.379,2.535-1.702,4.894-3.94,7.029,2.238,2.136,3.562,4.494,3.94,7.03Zm-2.967.444c-.334-2.234-1.745-4.354-4.191-6.3-.358-.284-.566-.717-.566-1.174s.208-.89.566-1.174c2.447-1.946,3.857-4.065,4.191-6.299.076-.51-.16-.875-.308-1.046-.263-.306-.644-.481-1.045-.481H7.368c-.401,0-.782.176-1.045.481-.148.172-.385.537-.308,1.048.337,2.244,1.747,4.362,4.189,6.294.359.284.569.718.569,1.176s-.21.892-.569,1.176c-2.442,1.933-3.852,4.05-4.189,6.294-.077.512.16.876.309,1.048.263.306.644.481,1.045.481h9.264c.401,0,.782-.175,1.045-.48.148-.171.384-.536.308-1.045Z" />
                          </svg>
                          Start
                        </>
                      </Box>
                    )}
                    <Box
                      sx={{
                        backgroundColor: "#f0f2f5",
                        mb: 1,
                        padding: "10px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleComplete();
                      }}
                    >
                      {task && task.status.value == "in_progress" ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 507.506 507.506"
                            style={{ marginRight: "10px" }}
                            width="14"
                            height="14"
                          >
                            <g>
                              <path d="M163.865,436.934c-14.406,0.006-28.222-5.72-38.4-15.915L9.369,304.966c-12.492-12.496-12.492-32.752,0-45.248l0,0   c12.496-12.492,32.752-12.492,45.248,0l109.248,109.248L452.889,79.942c12.496-12.492,32.752-12.492,45.248,0l0,0   c12.492,12.496,12.492,32.752,0,45.248L202.265,421.019C192.087,431.214,178.271,436.94,163.865,436.934z" />
                            </g>
                          </svg>
                          Complete
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 511.991 511.991"
                            style={{ marginRight: "10px" }}
                            width="14"
                            height="14"
                          >
                            <g>
                              <path d="M286.161,255.867L505.745,36.283c8.185-8.474,7.951-21.98-0.523-30.165c-8.267-7.985-21.375-7.985-29.642,0   L255.995,225.702L36.411,6.118c-8.475-8.185-21.98-7.95-30.165,0.524c-7.985,8.267-7.985,21.374,0,29.641L225.83,255.867   L6.246,475.451c-8.328,8.331-8.328,21.835,0,30.165l0,0c8.331,8.328,21.835,8.328,30.165,0l219.584-219.584l219.584,219.584   c8.331,8.328,21.835,8.328,30.165,0l0,0c8.328-8.331,8.328-21.835,0-30.165L286.161,255.867z" />
                            </g>
                          </svg>
                          Uncompleted
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default TaskShow;
