import React from "react";
import { Button, Grid, Skeleton } from "@mui/material";
import Controls from "../../../widgets/uncontrolled";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useForm } from "../../../hooks/useForm";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import Snackbar from "../../../widgets/Snackbar";
import { userService } from "../../../services";
import { useParams } from "react-router";

type FormStateValues = {
  new_password: string;
  repeat_new_password: string;
  user_id: string;
};

const Password = () => {
  const { id } = useParams<{ id: string }>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const form = useForm<FormStateValues>();
  const intl = useIntl();

  const handleSave = () => {
    form.values.user_id = id;

    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    if (validated.new_password != validated.repeat_new_password) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.PASSWORD.NOT.MATCH" }));
      return;
    }

    userService
      .changePassword(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.CHANGED" }));

        setTimeout(() => {
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      <form
        id={"password_form"}
        onSubmit={(e) => form.handleSubmit(e, handleSave)}
      >
        <Portlet fluidHeight={true} {...({} as any)}>
          <PortletHeader
            title={intl.formatMessage({ id: "USERS.CHANGEPASSWORD" })}
            {...({} as any)}
          />
          <PortletBody {...({} as any)}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                {loaded ? (
                  <Controls.Input
                    name={"password"}
                    label={intl.formatMessage({ id: "STANDARD.NEWPASSWORD" })}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.new_password = event.target.value.toString();
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"password"}
                      label={intl.formatMessage({ id: "STANDARD.NEWPASSWORD" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                {loaded ? (
                  <Controls.Input
                    name={"password"}
                    label={intl.formatMessage({
                      id: "STANDARD.NEWPASSWORD.REPEAT",
                    })}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.repeat_new_password =
                        event.target.value.toString();
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"password"}
                      label={intl.formatMessage({
                        id: "STANDARD.NEWPASSWORD.REPEAT",
                      })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  type={"submit"}
                  form={"password_form"}
                >
                  <FormattedMessage
                    id={"DIALOGUE.SAVE"}
                    defaultMessage={"DIALOGUE.SAVE"}
                  />
                </Button>
              </Grid>
            </Grid>
          </PortletBody>
        </Portlet>
      </form>
    </>
  );
};

export default Password;
