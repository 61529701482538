import React, { FC } from "react";
import {
  Avatar,
  Box,
  FormControl,
  Grid,
  Input,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useIntl } from "react-intl";

const Info: FC<{ image: string; title: string }> = (props) => {
  const { image, title } = props;
  const theme = useTheme();
  const intl = useIntl();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: 100,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 4.6,
          marginBottom: 2.3,
        }}
      >
        <Box
          sx={{
            height: "inherit",
          }}
        >
          <Avatar sx={{ height: 100, width: 100 }} src={image} />
        </Box>
      </Box>
      <Box
        sx={{
          marginBottom: 3,
          width: "100%",
        }}
      >
        <Typography
          fontSize={12}
          color={"#6D6D6D"}
          sx={{ marginBottom: "8px" }}
        >
          {intl.formatMessage({ id: "CHAT.GROUP_NAME" })}
        </Typography>
        <OutlinedInput
          defaultValue={title}
          fullWidth
          inputProps={{
            style: {
              margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
            },
          }}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0, borderColor: "#E6E6E6", borderRadius: 2 },
          }}
        />
      </Box>
    </>
  );
};

export default Info;
