import React, { FC, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { IColumns } from "app/partials/layout/DataTable";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTable } from "app/hooks/useTable";
import { GridTable } from "app/partials/layout/GridTable";
import ShowBusiness from "app/pages/Business/show";
import { useIntl } from "react-intl";
import { hasPermission, PermissionsGate } from "app/permission/PermissionsGate";
import { businessService, IBusiness } from "app/services/business.service";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { Typography } from "@mui/material";
import { usePermissions } from "../../hooks/useRole";
import BusinessStore from "./store";
import { userService } from "../../services";
import * as auth from "../../store/ducks/auth.duck";
import { RootState } from "../../store/store";
import EditIcon from "../../partials/icons/EditIcon";

type Props = {
  lang: object;
  settings_loading: boolean;
};

const Businesses: FC<Props> = (props) => {
  const intl = useIntl();
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const permissions = usePermissions();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }: RootState) => auth);
  const { roles } = useSelector((state: RootState) => state.permission);

  const superAdmin = roles?.find((role) => role.slug === "super-admin")?._id;

  console.log("super", superAdmin, user);
  const { data, xhrLoading, refresh, remove, updateQuery } =
    useTable<IBusiness>({
      fetch: businessService.getAll,
      delete: businessService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 50,
    },
    {
      field: "logo",
      headerName: intl.formatMessage({ id: "STANDARD.LOGO" }),
      renderCell: (params) => {
        return (
          <Avatar
            sx={{
              backgroundColor: params.row?.file?.base64
                ? "transparent"
                : "#0D99FF",
              lineHeight: "0!important",
              width: 32,
              height: 32,
            }}
            src={params.row?.file?.base64}
          >
            {params.row?.name?.charAt(0)}
          </Avatar>
        );
      },
      minWidth: 50,
    },
    {
      field: "name",
      headerName: intl.formatMessage({ id: "ECOMMERCE.COMMON.BUSINESS" }),
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.name}>
            <Typography variant="inherit">{params.row?.name}</Typography>
          </Tooltip>
        );
      },
      minWidth: 250,
    },
    {
      field: "email",
      headerName: intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" }),
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.email}>
            <Typography variant="inherit">{params.row?.email}</Typography>
          </Tooltip>
        );
      },
      minWidth: 250,
    },
    {
      field: "description",
      headerName: intl.formatMessage({ id: "STANDARD.DESCRIPTION" }),
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.description}>
            <Typography variant="inherit">
              {params.row?.description.substring(0, 140)}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 250,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => show(params.row?._id)}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleClick(params.row?._id)}
              data-cy-class={"commune_delete"}
              data-cy-id={params.row?._id}
            >
              <DeleteIcon sx={{ color: "error.main" }} />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [businessId, setBusinessId] = useState<string | null>(null);

  const show = (_id: string) => {
    setBusinessId(_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const updateUser = () => {
    if (user) {
      userService.getOne(user?._id).then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }
        dispatch(auth.actions.fulfillUser(data.data));
      });
    }
  };

  return (
    <>
      <PermissionsGate section={"communes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete business?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
              setTimeout(() => updateUser(), 2000);
            }}
          >
            Are you sure you want to delete this business?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"communes"} scope={"read"}>
        {businessId ? (
          <ShowBusiness
            id={businessId}
            open={Boolean(businessId)}
            setDialogue={() => setBusinessId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      {user?.role?.slug === "super-admin" && (
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "cases", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <BusinessStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
          }}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ i18n, settingReducer }: any) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Businesses);
