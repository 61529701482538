import React, { useEffect, useMemo, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { contractService } from "app/services";
import Snackbar from "app/widgets/Snackbar";
import moment from "moment";
import { useParams } from "react-router";
import { useTable } from "app/hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import ContractShow from "./show";
import { IContract } from "../../services/contractTemplate.service";
import { useIntl } from "react-intl";
import { PermissionsGate } from "../../permission/PermissionsGate";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "../../store/ducks/setting.duck";
import ContractFilter from "./filter";
import { useHistory, useLocation } from "react-router-dom";
import EditIcon from "../../partials/icons/EditIcon";

type Props = {
  _id: string;
  lang: string;
  settings_loading: boolean;
};

const _Contracts = (props: Props) => {
  const intl = useIntl();
  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);
  const parameter = query.get("status") ?? "";
  const id = query.get("id") ?? "";

  const { data, xhrLoading, updateQuery, setQuery, queries } =
    useTable<IContract>({
      fetch: contractService.getContracts,
      query: [
        {
          name: "per_page",
          value: 5,
        },
        {
          name: "status",
          value: parameter,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.SUBJECT" }),
      field: "subject",
      renderCell: (params) => {
        return params.row?.subject;
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 160,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"contracts"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"contract_show"}
                data-cy-contract-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const remove = (_id: string) => {
    Snackbar.info("Coming soon");
  };

  const [contractId, setContractId] = useState<string | null>(id);

  const show = (_id: string) => {
    setContractId(_id);
  };

  const history = useHistory();
  return (
    <>
      <PermissionsGate section={"contracts"} scope={"read"}>
        {contractId ? (
          <ContractShow
            _id={contractId}
            open={Boolean(contractId)}
            setDialogue={() => {
              setContractId(null);

              query.delete("id");
              history.replace({
                search: query.toString(),
              });
            }}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"contracts"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
          filterToolbar={{
            filters: (props: { show: boolean }) => {
              return (
                <ContractFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(_Contracts);
