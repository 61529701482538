import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 5.33333V3.66667C16.5 3.22464 16.3244 2.80072 16.0118 2.48816C15.6993 2.17559 15.2754 2 14.8333 2H9.83333C9.39131 2 8.96738 2.17559 8.65482 2.48816C8.34226 2.80072 8.16667 3.22464 8.16667 3.66667V5.33333H4V7H5.66667V19.5C5.66667 20.163 5.93006 20.7989 6.3989 21.2678C6.86774 21.7366 7.50363 22 8.16667 22H16.5C17.163 22 17.7989 21.7366 18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5V7H20.6667V5.33333H16.5ZM11.5 16.1667H9.83333V11.1667H11.5V16.1667ZM14.8333 16.1667H13.1667V11.1667H14.8333V16.1667ZM14.8333 5.33333H9.83333V3.66667H14.8333V5.33333Z"
        fill="#EC3033"
      />
    </svg>
  );
};

export default DeleteIcon;
