import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { caseService } from "app/services";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { PermissionsGate } from "../../../permission/PermissionsGate";
import CaseTodoNoteStore from "../store/todoNoteStore";
import { INote } from "../../../services/note.service";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import EditNotes, { FormStateValues } from "../../../partials/layout/EditNotes";
import PerfectScrollbar from "react-perfect-scrollbar";
import { TransitionProps } from "@mui/material/transitions";
import Snackbar from "../../../widgets/Snackbar";
import { stringAvatar } from "app/widgets/AvatarBackground";

type Props = {
  caseId: string;
  todoId: string;
  open: boolean;
  onDone: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
};

const ActionPanel = (props: {
  _id: string;
  todoId: string;
  refresh: Function;
}) => {
  const [addForm, setAddForm] = useState<boolean>(false);

  const handleClick = () => {
    setAddForm(true);
  };

  return (
    <PermissionsGate section={"notes"} scope={"create"}>
      <>
        <Button
          color={"primary"}
          data-cy-id={"new_todo_note"}
          data-cy-case-id={props._id}
          data-cy-todo-id={props.todoId}
          onClick={handleClick}
        >
          <FormattedMessage
            id={"CASE.NOTE.ADD"}
            defaultMessage={"CASE.NOTE.ADD"}
          />
        </Button>
        <CaseTodoNoteStore
          _id={props._id}
          todoId={props.todoId}
          open={addForm}
          setDialogue={setAddForm}
          onDone={() => props.refresh()}
        />
      </>
    </PermissionsGate>
  );
};

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const ShowCaseTodoNotes: FC<Props> = (props) => {
  const intl = useIntl();
  const [editNote, setEditNote] = useState<INote | null>(null);
  const [notes, setNotes] = useState<Array<INote>>();
  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const loadNotes = () => {
    caseService
      .getTodoNotes(props.caseId, props.todoId)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: notes } = data;
        setNotes(notes);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    loadNotes();
  }, [props.open]);

  const getDate = (date: number) => {
    let ret;
    if (moment().unix() - date < 1728000) {
      // less than 20 days
      ret = moment(new Date(date * 1000))
        .local()
        .fromNow();
    } else {
      ret = moment(new Date(date * 1000))
        .local()
        .format("DD-MM-YYYY HH:mm");
    }

    return ret;
  };

  const handleSave = (validated: FormStateValues, callback: () => void) => {
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    editNote &&
      caseService
        .updateTodoNote(props.caseId, props.todoId, editNote._id, validated)
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }

          setEditNote(null);
          callback();
          loadNotes();
          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const handleDelete = (noteId: string) => {
    caseService
      .deleteTodoNote(props.caseId, props.todoId, noteId)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        loadNotes();
        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.DELETED" }));
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      <PermissionsGate section={"notes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete note?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              handleDelete(confirmId);
            }}
          >
            Are you sure you want to delete this case todo note ?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      {editNote && (
        <EditNotes
          note={editNote}
          open={Boolean(editNote)}
          setDialogue={() => setEditNote(null)}
          save={handleSave}
        />
      )}
      <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"PERMISSION.TITLE.NOTES"}
                defaultMessage={"PERMISSION.TITLE.NOTES"}
              />
            </Typography>
          </Toolbar>
        </Box>
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          style={{ maxHeight: "430px", position: "relative" }}
        >
          <DialogContent>
            {notes && notes.length > 0 ? (
              notes.map((note, index) => {
                return (
                  <Box sx={{ display: "flex", mb: 1 }}>
                    <>
                      <Typography
                        sx={{ color: "#0D99FF", fontWeight: 500 }}
                        variant={"body1"}
                      >
                        {index + 1}.
                      </Typography>
                    </>
                    <Box sx={{ ml: 1, width: "100%" }}>
                      <Grid container>
                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                          <Typography
                            sx={{ color: "#0D99FF", fontWeight: 500 }}
                            variant={"body1"}
                          >
                            {note.title}
                          </Typography>
                          <Typography sx={{ mt: 1 }} variant={"body1"}>
                            {note.description}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box>
                            <IconButton
                              onClick={() => {
                                handleClick(note._id);
                              }}
                              sx={{
                                padding: "0px 15px!important",
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.5 5.33333V3.66667C16.5 3.22464 16.3244 2.80072 16.0118 2.48816C15.6993 2.17559 15.2754 2 14.8333 2H9.83333C9.39131 2 8.96738 2.17559 8.65482 2.48816C8.34226 2.80072 8.16667 3.22464 8.16667 3.66667V5.33333H4V7H5.66667V19.5C5.66667 20.163 5.93006 20.7989 6.3989 21.2678C6.86774 21.7366 7.50363 22 8.16667 22H16.5C17.163 22 17.7989 21.7366 18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5V7H20.6667V5.33333H16.5ZM11.5 16.1667H9.83333V11.1667H11.5V16.1667ZM14.8333 16.1667H13.1667V11.1667H14.8333V16.1667ZM14.8333 5.33333H9.83333V3.66667H14.8333V5.33333Z"
                                  fill="#EC3033"
                                />
                              </svg>
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setEditNote(note);
                              }}
                              sx={{ padding: "0px 15px!important" }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M19.178 2C18.4295 2 17.7118 2.29732 17.1825 2.82654L8.59354 11.4155C8.47767 11.5314 8.39547 11.6766 8.35573 11.8356L7.45162 15.452C7.3746 15.7601 7.46487 16.086 7.68943 16.3106C7.91399 16.5351 8.23991 16.6254 8.54801 16.5484L12.1644 15.6443C12.3234 15.6045 12.4686 15.5223 12.5844 15.4064L21.1734 6.81745C21.7027 6.28823 22 5.57044 22 4.822C22 4.07356 21.7027 3.35577 21.1734 2.82654C20.6442 2.29732 19.9264 2 19.178 2ZM18.4611 4.10514C18.6513 3.91502 18.9091 3.80821 19.178 3.80821C19.4469 3.80821 19.7047 3.91502 19.8948 4.10514C20.085 4.29526 20.1918 4.55313 20.1918 4.822C20.1918 5.09087 20.085 5.34874 19.8948 5.53886L11.4829 13.9508L9.5713 14.4287L10.0492 12.5171L18.4611 4.10514Z"
                                  fill="#FBB73C"
                                />
                                <path
                                  d="M4.71231 3.91791C3.99296 3.91791 3.30308 4.20367 2.79442 4.71233C2.28576 5.22099 2 5.91087 2 6.63022V19.2877C2 20.007 2.28576 20.6969 2.79442 21.2056C3.30308 21.7142 3.99296 22 4.71231 22H17.3698C18.0891 22 18.779 21.7142 19.2877 21.2056C19.7963 20.6969 20.0821 20.007 20.0821 19.2877V12.959C20.0821 12.4596 19.6773 12.0549 19.178 12.0549C18.6787 12.0549 18.2739 12.4596 18.2739 12.959V19.2877C18.2739 19.5275 18.1786 19.7574 18.0091 19.927C17.8395 20.0965 17.6096 20.1918 17.3698 20.1918H4.71231C4.47253 20.1918 4.24257 20.0965 4.07301 19.927C3.90346 19.7574 3.80821 19.5275 3.80821 19.2877V6.63022C3.80821 6.39044 3.90346 6.16048 4.07301 5.99093C4.24257 5.82137 4.47253 5.72612 4.71231 5.72612H11.041C11.5404 5.72612 11.9451 5.32134 11.9451 4.82202C11.9451 4.32269 11.5404 3.91791 11.041 3.91791H4.71231Z"
                                  fill="#FBB73C"
                                />
                              </svg>
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <List>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ padding: "0px!important" }}
                        >
                          <ListItemAvatar sx={{ minWidth: "32px!important" }}>
                            <Avatar
                              style={{
                                backgroundColor:
                                  note?.created_by?.role?.color ?? "#0D99FF",
                              }}
                              sx={{
                                width: 24,
                                height: 24,
                              }}
                              alt={note.created_by?.fullname}
                              src={`/storage/${note.created_by?.avatar?._id}/${note.created_by?.avatar?.name}`}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              note._updated_at ? (
                                <>
                                  <Typography
                                    sx={{ color: "#0D99FF", fontWeight: 500 }}
                                    component={"span"}
                                  >
                                    {note.updated_by?.fullname}
                                  </Typography>
                                  <Typography component={"span"}>
                                    {" "}
                                    updated note
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    sx={{ color: "#0D99FF", fontWeight: 500 }}
                                    component={"span"}
                                  >
                                    {note.created_by?.fullname}
                                  </Typography>
                                  <Typography component={"span"}>
                                    {" "}
                                    created note
                                  </Typography>
                                </>
                              )
                            }
                            secondary={
                              <>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="#767C91"
                                >
                                  {note._updated_at
                                    ? getDate(note._updated_at)
                                    : getDate(note._created_at)}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box
                sx={{
                  background: "#ffffff",
                  padding: 4,
                  borderRadius: 2,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <svg
                    width="264"
                    height="176"
                    viewBox="0 0 264 176"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M77.399 43.0361L37.9043 69.4556L76.8064 127.611L116.301 101.191L77.399 43.0361Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M95.8922 98.4404L95.4451 101.214L75.8105 98.3841L76.2576 95.6104L95.8922 98.4404Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M86.0047 88.5454L88.8207 88.9502L85.6984 108.282L82.8789 107.874L86.0047 88.5454Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M67.9359 63.9761C67.9641 64.0148 64.0076 66.7147 59.0972 69.9953C54.1868 73.2759 50.1846 75.9089 50.1564 75.8666C50.1282 75.8244 54.0847 73.1281 58.9951 69.8439C63.9055 66.5598 67.8902 63.9338 67.9359 63.9761Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M82.4451 60.3294C82.4733 60.3716 75.8909 64.8209 67.7456 70.2699C59.6003 75.7188 52.9616 80.1012 52.9405 80.0625C52.9194 80.0238 59.4877 75.5675 67.6435 70.1185C75.7994 64.6696 82.417 60.2872 82.4451 60.3294Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M85.3563 64.68C85.3844 64.7222 78.802 69.175 70.6567 74.624C62.5115 80.073 55.8727 84.4553 55.8516 84.4131C55.8305 84.3709 62.4059 79.9216 70.5547 74.4691C78.7035 69.0166 85.3281 64.6378 85.3563 64.68Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M88.2677 69.0345C88.2959 69.0767 81.7135 73.5295 73.5682 78.975C65.4229 84.4204 58.7842 88.8098 58.7842 88.7676C58.7842 88.7254 65.3349 84.2726 73.4837 78.8236C81.6325 73.3746 88.2395 68.9922 88.2677 69.0345Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M91.1681 73.3885C91.1963 73.4307 84.6139 77.88 76.4686 83.329C68.3233 88.7779 61.6846 93.1603 61.667 93.1181C61.6494 93.0758 68.2283 88.6266 76.3841 83.1776C84.54 77.7286 91.1681 73.3462 91.1681 73.3885Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M209.464 82.0038L176.295 104.192L208.965 153.031L242.135 130.843L209.464 82.0038Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M224.999 128.543L224.622 130.874L208.131 128.498L208.511 126.167L224.999 128.543Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M216.694 120.232L219.06 120.574L216.437 136.808L214.068 136.467L216.694 120.232Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M201.506 99.6159C201.534 99.6582 198.215 101.929 194.089 104.685C189.964 107.441 186.599 109.644 186.57 109.613C186.542 109.581 189.865 107.304 193.987 104.544C198.109 101.784 201.478 99.5561 201.506 99.6159Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M213.703 96.536C213.731 96.5782 208.208 100.324 201.383 104.896C194.558 109.468 188.972 113.15 188.943 113.108C188.915 113.066 194.438 109.321 201.263 104.745C208.089 100.169 213.664 96.4938 213.703 96.536Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M216.148 100.19C216.177 100.232 210.654 103.977 203.828 108.553C197.003 113.129 191.417 116.804 191.389 116.762C191.361 116.72 196.866 112.992 203.709 108.416C210.552 103.84 216.127 100.151 216.148 100.19Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M218.592 103.84C218.62 103.882 213.094 107.628 206.272 112.204C199.45 116.78 193.86 120.454 193.832 120.412C193.804 120.37 199.327 116.628 206.152 112.049C212.977 107.469 218.567 103.805 218.592 103.84Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M221.057 107.504C221.085 107.547 215.562 111.288 208.737 115.864C201.911 120.44 196.325 124.115 196.297 124.076C196.269 124.038 201.792 120.289 208.617 115.713C215.442 111.137 221.011 107.462 221.057 107.504Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M156.989 45.1755L120.797 25.8184L92.2941 79.1097L128.486 98.4668L156.989 45.1755Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M122.292 81.7942L119.997 82.5792L114.168 66.4682L116.467 65.6797L122.292 81.7942Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M125.811 70.2344L126.649 72.547L110.647 78.0277L109.81 75.715L125.811 70.2344Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M137.182 46.464C137.154 46.5168 133.486 44.609 128.984 42.2048C124.482 39.8007 120.86 37.8048 120.888 37.752C120.916 37.6992 124.587 39.6071 129.089 42.0112C133.592 44.4154 137.21 46.4077 137.182 46.464Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M145.869 56.0492C145.84 56.102 139.765 52.9058 132.299 48.9141C124.833 44.9225 118.807 41.6418 118.835 41.589C118.863 41.5362 124.935 44.7289 132.401 48.7241C139.867 52.7193 145.897 55.9964 145.869 56.0492Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M143.736 60.037C143.708 60.0898 137.632 56.8971 130.166 52.9019C122.7 48.9067 116.674 45.6296 116.702 45.5768C116.73 45.524 122.802 48.7202 130.268 52.7119C137.734 56.7035 143.764 59.9842 143.736 60.037Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M141.603 64.0254C141.575 64.0782 135.499 60.8856 128.033 56.8939C120.568 52.9022 114.541 49.6322 114.569 49.5688C114.598 49.5054 120.67 52.7086 128.136 56.7003C135.601 60.692 141.631 63.9726 141.603 64.0254Z"
                      fill="#E0E0E0"
                    />
                    <path
                      d="M139.469 68.0134C139.441 68.0662 133.366 64.8736 125.9 60.8819C118.434 56.8902 112.404 53.6096 112.436 53.5568C112.467 53.504 118.536 56.6966 126.002 60.6883C133.468 64.68 139.497 67.9606 139.469 68.0134Z"
                      fill="#E0E0E0"
                    />
                    <path
                      opacity="0.2"
                      d="M139.656 0H138.748V15.481H139.656V0Z"
                      fill="black"
                    />
                    <path
                      d="M118.001 34.2107H160.403C160.403 34.2107 156.489 15.4878 138.882 15.4878C123.221 15.4878 118.001 34.2107 118.001 34.2107Z"
                      fill="#556EE6"
                    />
                    <path
                      d="M139.656 0H138.748V15.481H139.656V0Z"
                      fill="#556EE6"
                    />
                    <path
                      d="M233.611 154.82C233.611 154.869 188.115 154.912 132.003 154.912C75.8907 154.912 30.3877 154.88 30.3877 154.82C30.3877 154.76 75.8767 154.729 132.003 154.729C188.129 154.729 233.611 154.767 233.611 154.82Z"
                      fill="#263238"
                    />
                    <path
                      d="M105.248 82.5581L92.7872 146.552L91.168 154.929L174.24 154.528C177.971 154.51 181.28 151.198 182.473 146.277L197.208 85.8634C198.12 82.1287 196.12 78.2426 193.29 78.2461L109.19 78.3341C107.36 78.3306 105.72 80.0624 105.248 82.5581Z"
                      fill="#556EE6"
                    />
                    <g opacity="0.5">
                      <path
                        d="M105.248 82.5581L92.7872 146.552L91.168 154.929L174.24 154.528C177.971 154.51 181.28 151.198 182.473 146.277L197.208 85.8634C198.12 82.1287 196.12 78.2426 193.29 78.2461L109.19 78.3341C107.36 78.3306 105.72 80.0624 105.248 82.5581Z"
                        fill="black"
                      />
                    </g>
                    <path
                      d="M166.281 142.208L157.481 71.3118C157.129 68.4078 154.809 66.2395 152.074 66.25L132.785 66.3099C132.068 66.318 131.36 66.4719 130.705 66.7624C130.049 67.0529 129.46 67.4738 128.972 67.9995L119.82 77.7393L66.1757 78.028C62.8986 78.0456 60.3782 81.1502 60.8112 84.635L68.9811 150.16C69.3331 153.06 71.6528 155.225 74.3808 155.222L167.89 155.035C178.302 155.25 179.298 152.177 179.298 152.177C167.344 154.82 166.281 142.226 166.281 142.208Z"
                      fill="#556EE6"
                    />
                    <g opacity="0.5">
                      <path
                        d="M125.959 128.03L122.806 131.416L98.5176 109.564L101.671 106.178L125.959 128.03Z"
                        fill="white"
                      />
                      <path
                        d="M121.486 105.428L124.971 108.564L102.995 132.166L99.5068 129.029L121.486 105.428Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <Typography
                    variant="body1"
                    style={{ color: "#6D6D6D", textAlign: "center" }}
                  >
                    No data
                  </Typography>
                </Box>
              </Box>
            )}
          </DialogContent>
        </PerfectScrollbar>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowCaseTodoNotes;
