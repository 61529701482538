import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Snackbar from "../../../widgets/Snackbar";
import { permissionService } from "../../../services";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Controls from "../../../widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IPermission } from "../../../interfaces";
import { FormattedMessage, useIntl } from "react-intl";
import { PermissionStructureType, TEMPLATE } from "app/permission/permissions";
import { checkKey } from "../../../utils/standart";
import { Abilities, PermissionsType } from "app/permission/PermissionsGate";
import * as setting from "../../../store/ducks/setting.duck";

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  structure: PermissionStructureType | null;
};

interface IPermissionRow {
  rowTitle: PermissionsType;
  title: string;
  abilities: Array<Abilities>;
}

const ShowPermission = (props: FormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [mode, setMode] = useState<string>("show");
  const [permission, setPermission] = useState<IPermission>();
  const [template, setTemplate] = useState<Array<IPermissionRow> | null>(null);
  const dispatch = useDispatch();
  const updateSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Update, payload: s }),
    [dispatch]
  );

  useEffect(() => {
    const rows: Array<IPermissionRow> = [];

    for (let key in TEMPLATE) {
      if (!checkKey<PermissionStructureType>(key, TEMPLATE)) {
        continue;
      }
      const _v = TEMPLATE[key];
      rows.push({
        rowTitle: key,
        title: _v.title,
        abilities: _v.abilities,
      });
    }
    setTemplate(rows);
  }, []);

  useEffect(() => {
    permissionService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        let { data: permission } = data;
        setPermission(permission);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    if (permission) {
      permissionService
        .update(permission._id, validated)
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

          let { data: setting } = data;
          updateSetting(setting);

          setTimeout(() => {
            props.setDialogue(false);
            props.onDone();
            form.clear();
          }, 1000);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  };

  useEffect(() => {
    if (permission) {
      form.values.title = permission.title ?? "";
      form.values.structure = permission.structure;
    }
  }, [permission]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          // fullScreen={true}
          maxWidth={"lg"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"DIALOGUE.EDIT"}
                  defaultMessage={"DIALOGUE.EDIT"}
                />
              </Typography>
              <Button
                form={"permission_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"permission_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && permission ? (
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      defaultValue={permission?.title}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.title = event.target.value;

                        event.preventDefault();
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <TableContainer component={Paper}>
                      <Table stickyHeader aria-label="sticky collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell align={"center"}>
                              <FormattedMessage
                                id={"create"}
                                defaultMessage={"Create"}
                              />
                            </TableCell>
                            <TableCell align={"center"}>
                              <FormattedMessage
                                id={"read"}
                                defaultMessage={"Read"}
                              />
                            </TableCell>
                            <TableCell align={"center"}>
                              <FormattedMessage
                                id={"update"}
                                defaultMessage={"Update"}
                              />
                            </TableCell>
                            <TableCell align={"center"}>
                              <FormattedMessage
                                id={"delete"}
                                defaultMessage={"Delete"}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {template?.map((row, key) => {
                            return (
                              <>
                                <TableRow key={key}>
                                  <TableCell component="th" scope="row">
                                    <FormattedMessage
                                      id={row.title}
                                      defaultMessage={row.title}
                                    />
                                  </TableCell>
                                  {["create", "read", "update", "delete"].map(
                                    (value, index) => {
                                      if (!permission?.structure) return;
                                      return (
                                        <TableCell align={"center"}>
                                          <Checkbox
                                            id={`${row.rowTitle}_${row.abilities[index]}`}
                                            onChange={(event) => {
                                              let { structure } = form.values;

                                              if (
                                                !structure ||
                                                !structure[row.rowTitle]
                                              )
                                                return;

                                              if (event.target.checked) {
                                                structure[
                                                  row.rowTitle
                                                ].abilities.push(
                                                  value as Abilities
                                                );
                                              } else {
                                                structure[
                                                  row.rowTitle
                                                ].abilities = structure[
                                                  row.rowTitle
                                                ].abilities.filter(
                                                  (ability) => ability !== value
                                                );
                                              }
                                            }}
                                            defaultChecked={Boolean(
                                              permission?.structure[
                                                row.rowTitle
                                              ].abilities?.includes(
                                                value as Abilities
                                              )
                                            )}
                                          />
                                        </TableCell>
                                      );
                                    }
                                  )}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"titles"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const GrantedStatusIcon = ({ status }: { status: boolean }) => {
  return status ? (
    <svg height="14" width="14">
      <circle cx="7" cy="7" r="6" fill="#28CC70" />
    </svg>
  ) : (
    <svg height="14" width="14">
      <circle
        cx="7"
        cy="7"
        r="6"
        stroke="red"
        stroke-width="1"
        fill={"white"}
      />
    </svg>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowPermission;
