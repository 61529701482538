import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "./LayoutContext";
import * as builder from "_metronic/ducks/builder";
import MenuConfig from "_metronic/layout/MenuConfig";
import { usePermissions } from "app/hooks/useRole";
import { cloneDeep } from "lodash";

/**
 * Used to synchronize current layouts `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */
export default function LayoutInitializer({
  children,
  user,
  layoutConfig,
  htmlClassService,
}) {
  const dispatch = useDispatch();
  const builderState = useSelector(({ builder }) => builder);
  const modules = useSelector(({ auth }) => auth.business.modules);
  const { roles } = useSelector((state) => state.permission);
  const superAdmin = roles?.find((role) => role.slug === "super-admin")?._id;
  const permissions = usePermissions();

  useEffect(() => {
    let menuConfig = cloneDeep(MenuConfig);
    let items = [];
    let settings = [];
    let { aside } = menuConfig;

    for (let item of aside.items) {
      if (moduleCheck(item.name) && menuCheck(item.name)) {
        items.push(item);
        let _s = settingsCheck(item);

        if (_s) {
          settings.push(..._s);
        }
      }
    }

    aside.items = items;
    dispatch(builder.actions.setMenuConfig(menuConfig, user));

    // settingsCheck(menuConfig)
  }, [dispatch, permissions]);

  useEffect(() => {
    if (layoutConfig.demo !== builderState.layoutConfig.demo) {
      dispatch(builder.actions.setLayoutConfigs(layoutConfig));
    }
  }, [dispatch, builderState, layoutConfig]);

  useEffect(() => {
    dispatch(builder.actions.setHtmlClassService(htmlClassService));
  }, [dispatch, htmlClassService]);

  const moduleCheck = (menu) => {
    let granted = false;

    if (
      menu === "home" ||
      menu === "calendar" ||
      menu === "users" ||
      menu === "settings"
    )
      granted = true;

    if (
      modules?.find((m) => {
        return m && m.title === menu;
      })
    )
      granted = true;

    return granted;
  };

  const settingsCheck = (submenu) => {
    let moduleSubMenus = {
      "MENU.CASES": [
        "MENU.FOCUS_AREAS",
        "MENU.FOCUS_TYPES",
        "MENU.MEETING_DURATION_TYPES",
        "MENU.REPORT_TYPES",
        "MENU.REPORT_INTERVALS",
        "MENU.REPORT_STATUSES",
      ],
      "MENU.VACANCIES": [
        "MENU.VACANCY_CITIES",
        "MENU.VACANCY_LANGUAGES",
        "MENU.VACANCY_STATUSES",
        "MENU.VACANCY_FOLDERS",
      ],
      "MENU.NOTES": ["MENU.FOLDERCATEGORIES"],
      "MENU.OFFERS": ["MENU.OFFER_STATUSES", "MENU.OFFER_TEMPLATES"],
      "MENU.TODOS": [
        "MENU.TODO_CATEGORIES",
        "MENU.TODO_STATUSES",
        "MENU.TODO_TYPES",
      ],
      "MENU.TIMETRACKS": ["MENU.TIME_TRACK_TYPES"],
      "MENU.SETTINGS": [
        "MENU.COLORS",
        "MENU.PDF_FRONT_PAGE",
        "MENU.GOALS",
        "MENU.SYSTEM_LANGUAGES",
        "MENU.TRANSLATION_FORM",
        "MENU.ROLES",
        "MENU.PERMISSIONS",
        "MENU.TRANSLATION_FORM",
        "MENU.LANGUAGES",
        "MENU.SYSTEM_LANGUAGES",
        "MENU.QUESTIONS",
        "MENU.QUESTION_CATEGORIES",
        "MENU.PLAN_STATUSES",
        "MENU.PLAN_TYPES",
        "MENU.CITIES",
        "MENU.FINANCESTATUSES",
        "MENU.TRANSACTION_TYPES",
      ],
    };

    if (moduleSubMenus[submenu.title]) return moduleSubMenus[submenu.title];
    else return false;
  };

  const menuCheck = (menu) => {
    if (!permissions || !permissions) return false;

    let permissionGranted;

    if (menu === "business" && user?.role?.id === superAdmin)
      permissionGranted = true;

    if (menu === "home" || menu === "calendar") permissionGranted = true;

    if (menu === "todos" && user?.role?.slug === "citizen") {
      permissionGranted = false;
    } else if (permissions.structure[menu]) {
      permissionGranted =
        permissions.structure[menu].abilities.includes("read");
    }

    return permissionGranted;
  };

  return htmlClassService === builderState.htmlClassServiceObjects ? (
    // Render content when `htmlClassService` synchronized with redux store.
    <>{children}</>
  ) : (
    // Otherwise sow loading splash screen.
    <LayoutSplashScreen />
  );
}
