import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useIntl } from "react-intl";
import { useForm } from "app/hooks/useForm";
import { useParams } from "react-router";
import { caseService, userService } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Snackbar from "../../widgets/Snackbar";

type IFormProps = {
  type: string;
  time_track: string;
  open: boolean;
  save?: Function;
  _id?: string;
  onDone: Function;
};

type FormStateValues = {
  time_track: string;
  user: string;
  emoji: number;
  comment: string;
};

const initValues = {
  emoji: 0,
  comment: "",
} as FormStateValues;

const FeedbackDialogue: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const form = useForm<FormStateValues>(initValues);
  const [close, setClose] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(props.open);
  const { user } = useSelector(({ auth }: RootState) => auth);
  const [comment, setComment] = useState<string>("");

  const feedbackEmojis = [
    {
      path: "/media/smiles/angry.svg",
      index: -2,
    },
    {
      path: "/media/smiles/sad.svg",
      index: -1,
    },
    {
      path: "/media/smiles/neutral.svg",
      index: 0,
    },
    {
      path: "/media/smiles/good.svg",
      index: 1,
    },
    {
      path: "/media/smiles/excellent.svg",
      index: 2,
    },
  ];

  useEffect(() => {
    if (close) {
      setOpen(false);
    } else {
      setOpen(props.open);
    }
  });

  const handleClose = () => {
    setClose(true);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    validated["type"] = props.type;
    if (props.type === "case") {
      caseService
        .postTimeTrackFeedback(id, validated)
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));
          handleClose();
          form.clear();
          props.onDone();
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    } else {
      userService
        .postTimeTrackFeedback(id, validated)
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));
          handleClose();
          form.clear();
          props.onDone();
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  };

  return (
    <form
      id={"feedback_form"}
      onSubmit={(e) => form.handleSubmit(e, handleSave)}
    >
      <Dialog
        onClose={handleClose}
        open={open}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            Rate Session
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            {feedbackEmojis.map((emoji) => {
              return (
                <Button
                  onClick={() => {
                    form.values.emoji = emoji.index;
                  }}
                >
                  <img
                    style={{
                      height: 45,
                      marginTop: "20px",
                      marginBottom: "30px",
                      width: 45,
                      textAlign: "center",
                    }}
                    src={emoji.path}
                    alt={""}
                  />
                </Button>
              );
            })}
          </Box>
          <TextField
            id="standard-basic"
            onChange={(e) => {
              setComment(e.target.value);
            }}
            sx={{ width: "100%" }}
            multiline
            rows={4}
            label="Leave comment"
            variant="standard"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <>
              <Button
                onClick={() => {
                  handleClose();
                }}
                sx={{ marginRight: "10px" }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                form={"feedback_form"}
                onClick={() => {
                  if (user) {
                    form.values.user = user._id;
                    form.values.time_track = props.time_track;
                    form.values.comment = comment;
                  }
                }}
                variant="contained"
              >
                Submit
              </Button>
            </>
          </Box>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default FeedbackDialogue;
