import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "../../hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "../../widgets/uncontrolled";
import Snackbar from "../../widgets/Snackbar";
import { mailboxService } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { FormattedMessage, useIntl } from "react-intl";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  subject: string;
  email: string;
  phone: string;
  is_secure: boolean;
  is_read: boolean;
  mail_response: boolean;
  sms_response: boolean;
  content: string;
  mail_content: string;
  type: string;
};

const initValues = {
  subject: "",
  email: "",
  phone: "",
  is_secure: false,
  is_read: false,
  mail_response: false,
  sms_response: false,
  content: "",
  mail_content: "",
  type: "mail",
} as FormStateValues;

const MailboxStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    mailboxService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (props.open) {
      form.values.subject = "";
      form.values.email = "";
      form.values.phone = "";
      form.values.is_secure = false;
      form.values.is_read = false;
      form.values.mail_response = false;
      form.values.sms_response = false;
      form.values.content = "";
      form.values.mail_content = "";
      form.values.type = "mail";
    }
  }, [props.open]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"MAILBOX.ADD"}
                  defaultMessage={"MAILBOX.ADD"}
                />
              </Typography>
              <Button
                form={"mailbox_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    d="m.172,3.708C-.216,2.646.076,1.47.917.713,1.756-.041,2.951-.211,3.965.282l18.09,8.444c.97.454,1.664,1.283,1.945,2.273H4.048L.229,3.835c-.021-.041-.04-.084-.057-.127Zm3.89,9.292L.309,20.175c-.021.04-.039.08-.054.122-.387,1.063-.092,2.237.749,2.993.521.467,1.179.708,1.841.708.409,0,.819-.092,1.201-.279l18.011-8.438c.973-.456,1.666-1.288,1.945-2.28H4.062Z"
                    fill={"#0D99FF"}
                  />
                </svg>
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"mailbox_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <Controls.Input
                      name={"subject"}
                      label={intl.formatMessage({ id: "STANDARD.SUBJECT" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.subject = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"subject"}
                        label={intl.formatMessage({ id: "STANDARD.SUBJECT" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <Controls.Input
                      name={"email"}
                      label={intl.formatMessage({
                        id: "CASE.INFORMATION.EMAIL",
                      })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.email = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"email"}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.EMAIL",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <Controls.Input
                      name={"phone"}
                      label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.phone = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"phone"}
                        label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.is_secure = event.target.checked;
                          }}
                        />
                      }
                      label={intl.formatMessage({ id: "STANDARD.ISSECURE" })}
                      name={"is_secure"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: "STANDARD.ISSECURE" })}
                        name={"is_secure"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Editor
                      name={"content"}
                      label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                      onChange={(data: any) => {
                        form.values.content = data;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"content"}
                        label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default MailboxStore;
