import { CircularProgress } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { Backdrop as _B } from "@mui/material";
import { VariantType } from "notistack";

let BackDropRef: {
  off: () => void;
  on: () => void;
};
export const BackdropUI = () => {
  const [open, setOpen] = useState<boolean>(false);

  BackDropRef = {
    off: () => setOpen(false),
    on: () => setOpen(true),
  };

  return (
    <_B
      sx={{ color: "#0D99FF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </_B>
  );
};

export default {
  off() {
    BackDropRef.off();
  },
  on() {
    BackDropRef.on();
  },
};
