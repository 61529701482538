import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import * as _setting from "app/store/ducks/setting.duck";
import { Skeleton } from "@mui/lab";
import { knowledgebaseService } from "app/services";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { IKnowledgebase } from "../../services/knowledgebase.service";
import { FormattedMessage, useIntl } from "react-intl";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "app/store/ducks/setting.duck";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

interface FormValues {
  title: string;
}

const ShowKnowledgebase = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormValues>();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [knowledgebase, setKnowledgebase] = useState<IKnowledgebase>();

  useEffect(() => {
    knowledgebaseService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: knowledgebase } = data;
        setKnowledgebase(knowledgebase);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    knowledgebaseService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          pr: 2,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"KNOWLEDGEBASECATEGORY.SHOW"}
              defaultMessage={"KNOWLEDGEBASECATEGORY.SHOW"}
            />
          </Typography>
          <Button
            form={"knowledgebase_form"}
            autoFocus
            color="inherit"
            type={"submit"}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage
              id={"DIALOGUE.SAVE"}
              defaultMessage={"DIALOGUE.SAVE"}
            />
          </Button>
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id={"knowledgebase_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && knowledgebase ? (
                <Controls.Input
                  name={"title"}
                  label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                  defaultValue={knowledgebase?.title}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.title = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"title"}
                    label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowKnowledgebase);
