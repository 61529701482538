import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { IUrlQuery, IUser } from "app/interfaces";
import { Box, Grid, Theme } from "@mui/material";
import { DatePicker, Select, Space } from "antd";
import "antd/dist/antd.css";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useIntl } from "react-intl";
import Controls from "../../widgets/uncontrolled";
import { caseService, ICase } from "app/services/case.service";
import Snackbar from "../../widgets/Snackbar";
import UsersGroupedSelect from "../../partials/layout/UsersGroupedSelect";
import { userService } from "../../services";

const useStyle = makeStyles((theme: Theme) => ({
  rangePicker: {
    width: "100%",
    height: 40,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "40px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const ReportFilter = (props: {
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
  queries: Array<IUrlQuery> | undefined;
  setQuery: Dispatch<SetStateAction<IUrlQuery[] | undefined>>;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const [from, setFrom] = useState<string>("");
  const [to, setTo] = useState<string>("");
  const [cases, setCases] = useState<Array<ICase>>();
  const { report_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const classes = useStyle();
  const timeOut = useRef<any>();
  const [users, setUsers] = useState<IUser[]>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: userSearch,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const { RangePicker } = DatePicker;
  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  useEffect(() => {
    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const handleFromToChange = (dateString: Array<string>) => {
    setFrom(dateString[0]);

    if (dateString[1].length > 0) setTo(dateString[1]);
  };

  useEffect(() => {
    if (from) handleQuery("from", from);

    if (to) handleQuery("to", to);
  }, [from, to]);

  const handleUserChange = (value: string) => {
    if (!value) {
      handleQuery("user", "");
    } else {
      handleQuery("user", value);
    }
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Space className={classes.antSpace} direction="vertical" size={12}>
            <RangePicker
              allowEmpty={[true, true]}
              className={classes.rangePicker}
              placeholder={[
                intl.formatMessage({
                  id: "STANDARD.FROM",
                  defaultMessage: "From",
                }),
                intl.formatMessage({
                  id: "STANDARD.TO",
                  defaultMessage: "To",
                }),
              ]}
              separator={"―"}
              onCalendarChange={(value, dateString) => {
                if (!dateString[0] && !dateString[1]) {
                  let _queries = props.queries?.filter(
                    (q) => q.name !== "from" && q.name !== "to"
                  );

                  props.setQuery(_queries);

                  setFrom("");
                  setTo("");

                  return;
                }

                handleFromToChange(dateString);
              }}
            />
          </Space>
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"status"}
            options={
              report_statuses &&
              report_statuses.map((report_status) => {
                return {
                  id: report_status._id,
                  title: report_status.title[intl.locale],
                };
              })
            }
            label={intl.formatMessage({ id: "STANDARD.STATUS" })}
            onChange={(e) => {
              handleQuery("status", e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"case"}
            options={
              cases &&
              cases.map((_case) => {
                return {
                  id: _case._id,
                  title: _case.key,
                };
              })
            }
            label={intl.formatMessage({
              id: "CASE.INFORMATION.CASE",
            })}
            onChange={(e) => {
              handleQuery("case", e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Select
            placeholder={intl.formatMessage({
              id: "CASE.TODO.LEAD",
            })}
            showSearch
            onChange={handleUserChange}
            onSearch={(value) => {
              sendQuery(value);
            }}
            filterOption={false}
            style={{ width: "100%" }}
            allowClear={true}
            options={
              users &&
              users.map((user) => {
                return {
                  value: user._id.toString(),
                  label: user.firstname + " " + user.lastname,
                };
              })
            }
            className={classes.antSelect}
            onClear={() => {
              sendQuery(" ");
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportFilter;
