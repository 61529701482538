import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { permissionService, roleService } from "app/services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { IPermission } from "../../../interfaces";
import { FormattedMessage, useIntl } from "react-intl";
import { Palette } from "../../../widgets/Palette";
import * as setting from "../../../store/ducks/setting.duck";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: { [key: string]: string } | null;
  permissions: string;
  access_level: number;
  color: string;
};

const initValues = {
  title: null,
  permissions: "",
  access_level: 1,
  color: "#000",
} as FormStateValues;

const RoleStore: FC<IFormProps> = (props) => {
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [permissions, setPermissions] = useState<Array<IPermission>>();
  const [permission, setPermission] = useState<IPermission | undefined>();
  const intl = useIntl();
  const colorRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const addSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Add, payload: s }),
    [dispatch]
  );

  const getPermissions = () => {
    permissionService
      .getAll()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: permissions } = data;
        setPermissions(permissions);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleGeneratePermission = () => {
    if (form.values.title) {
      permissionService
        .generate({ title: form.values.title["en"] })
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: permission } = data;
          setPermission(permission);
          form.values.permissions = permission._id;
          setPermissions([]);
          getPermissions();
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    } else {
      Snackbar.error("Please provide titles");
    }
  };

  useEffect(() => {
    if (props.open) getPermissions();
  }, [props.open]);

  const handleClose = () => {
    props.setDialogue(false);

    if (form.values.permissions) {
      setPermission(undefined);
      setPermissions([]);
      permissionService
        .delete(form.values.permissions)
        .then((data) => {
          if ("error" in data) throw new Error(data.error.message);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });

      form.clear();
    }
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    roleService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        let { data: setting } = data;
        addSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
          setPermission(undefined);
          setPermissions([]);
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={props.open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"STANDARD.NEW"}
                  defaultMessage={"STANDARD.NEW"}
                />
              </Typography>
              <Button
                form={"role_store"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"role_store"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"title_en"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        if (!form.values.title) {
                          form.values.title = {};
                        }

                        form.values.title["en"] = event.target.value;

                        event.preventDefault();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">EN</InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title_en"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"title_da"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        if (!form.values.title) {
                          form.values.title = {};
                        }

                        form.values.title["da"] = event.target.value;

                        event.preventDefault();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">DA</InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title_da"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={2} lg={2} xl={2}>
                  {loaded ? (
                    <>
                      <IconButton
                        sx={{
                          backgroundColor: "primary.main",
                          borderRadius: "8px",
                          "&:hover": {
                            backgroundColor: "primary.main",
                          },
                        }}
                        onClick={() => handleGeneratePermission()}
                      >
                        <Button
                          autoFocus
                          sx={{
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "primary.main",
                            },
                          }}
                          type={"button"}
                        >
                          Generate permission
                        </Button>
                      </IconButton>
                    </>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"permission"}
                        label={intl.formatMessage({
                          id: "SETTINGS.PERMISSION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                  {loaded ? (
                    <>
                      {permissions && permissions.length ? (
                        <Controls.Select
                          name={"permission"}
                          formId={"permission"}
                          options={permissions?.map((permission) => {
                            return {
                              id: permission._id,
                              title: permission.title,
                            };
                          })}
                          defaultValue={permission && permission._id}
                          label={intl.formatMessage({
                            id: "SETTINGS.PERMISSION",
                          })}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.permissions = event.target.value;
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"permission"}
                        label={intl.formatMessage({
                          id: "SETTINGS.PERMISSION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Select
                      name={"access_level"}
                      formId={"access_level"}
                      options={[
                        {
                          id: "0",
                          title: "0 - user, only own data",
                        },
                        {
                          id: "1",
                          title:
                            "1 - moderator, data of other users. can`t change permissions",
                        },
                        {
                          id: "2",
                          title:
                            "2 - moderator, data of other users. can change permission of lower",
                        },
                        {
                          id: "3",
                          title:
                            "3 - super-admin, data of users, can change admins permission. multi-business",
                        },
                      ]}
                      label={intl.formatMessage({ id: "SETTINGS.ACCESSLEVEL" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.access_level = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"access_level"}
                        label={intl.formatMessage({
                          id: "SETTINGS.ACCESSLEVEL",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={12} xl={12}>
                  {loaded ? (
                    <>
                      <Controls.Input
                        name={"color"}
                        label={intl.formatMessage({ id: "STANDARD.COLOR" })}
                        inputRef={colorRef}
                        defaultValue={form.values.color}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.color = event.target.value;

                          event.preventDefault();
                        }}
                      />
                      <Grid container>
                        <Palette
                          onPick={(color) => {
                            if (color) {
                              form.values.color = color;
                              if (colorRef.current)
                                colorRef.current.value = color;
                            } else {
                              form.values.color = "";
                              if (colorRef.current) colorRef.current.value = "";
                            }
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"color"}
                        label={intl.formatMessage({ id: "STANDARD.COLOR" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default RoleStore;
