import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "../../../widgets/uncontrolled";
import Snackbar from "../../../widgets/Snackbar";
import { userService } from "../../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import Attachments from "../../Cases/store/parts/attachments";

interface IFormProps {
  user: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  category: string | null;
  files: Array<string>;
};

const initValues = {
  title: "",
  category: null,
  files: [],
} as FormStateValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const UserDocumentStore: FC<IFormProps> = (props) => {
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const classes = useStyles();
  const intl = useIntl();
  const { folder_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );
  
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    form.values.files = files?.file_ids?.map((file: any) => file._id);
  }, [files]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    userService
      .postDocFolder(props.user.toString(), validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.DOCUMENT.ADD"}
                  defaultMessage={"CASE.DOCUMENT.ADD"}
                />
              </Typography>
              <Button
                form={"document_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"document_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography
                        variant={"h4"}
                        fontWeight={500}
                        fontSize={13}
                        sx={{ mb: 1 }}
                      >
                        <FormattedMessage
                          id={"STANDARD.TITLE"}
                          defaultMessage={"STANDARD.TITLE"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"title"}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography
                        variant={"h4"}
                        fontWeight={500}
                        fontSize={13}
                        sx={{ mb: 1 }}
                      >
                        <FormattedMessage
                          id={"STANDARD.CATEGORY"}
                          defaultMessage={"STANDARD.CATEGORY"}
                        />
                      </Typography>

                      <Controls.Select
                        options={folder_categories?.map((f_c) => {
                          return {
                            id: f_c._id.toString(),
                            title: f_c.title ? f_c.title[intl.locale] : "",
                          };
                        })}
                        formId={"category"}
                        name={"category"}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.category = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        options={[]}
                        name={"category"}
                        label={intl.formatMessage({ id: "STANDARD.CATEGORY" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        Files
                      </Typography>
                      <Attachments {...{ setFiles, files }} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        options={[]}
                        name={"documents"}
                        label={intl.formatMessage({ id: "STANDARD.CATEGORY" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default UserDocumentStore;
