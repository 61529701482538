import React, { FC, useRef, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { getFileExtIcon } from "app/utils/standart";
import { toAbsoluteUrl } from "app/../_metronic";
import { VoicesProps } from "./tabs";
import ReactAudioPlayer from "react-audio-player";

const Voices: FC<VoicesProps> = (props) => {
  const { items, onClick } = props;
  const [playing, setPlaying] = useState<boolean>(false);
  const [_index, setIndex] = useState<number>();
  const toggle = () => {
    if (!playing) {
      audioRef.current?.play();
    } else {
    }
  };

  const playVoice = (index: number, audio: HTMLAudioElement) => {
    // console.log("_index", _index);
    // if (_index === index) {
    //   console.log("index", _index);
    setIndex(index);

    setPlaying(true);
    audio.pause();
    audio.currentTime = 0;
    console.log("play");
    audio.play();

    audio.onended = function () {
      setPlaying(false);
    };
    // }
  };

  const audioRef = useRef<HTMLAudioElement | null>(null);
  return (
    <List sx={{ width: "100%", p: 0 }} dense={true}>
      {items.map((item, index) => {
        return (
          <ListItem sx={{ p: 0, marginTop: 2 }} disableGutters>
            <ListItemAvatar
              sx={{
                minWidth: "44px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              onClick={() => {
                // setIndex(index);
              }}
            >
              <i
                style={{
                  cursor: "pointer",
                }}
                className={
                  playing && _index === index
                    ? "custom_icon-pause"
                    : "custom_icon-play"
                }
                onClick={() => {
                  let audio = new Audio(
                    process.env.REACT_APP_PROXY_API +
                      `/storage/${item._id}/${item.name}`
                  );

                  playVoice(index, audio);
                  // onClick && onClick(item._id);
                  // setIndex(index);
                  // let audio = new Audio(`/storage/${item._id}/${item.name}`);
                  //
                  // if (!playing) {
                  //   audio.play();
                  //   toggle();
                  // } else {
                  //   audio.pause();
                  //   audio.currentTime = 0;
                  //   toggle();
                  // }
                }}
              />
              {/*<ReactAudioPlayer*/}
              {/*  ref={audioRef}*/}
              {/*  src="my_audio_file.ogg"*/}
              {/*  autoPlay*/}
              {/*  controls*/}
              {/*  style={{display: "none"}}*/}
              {/*/>*/}
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              // secondary={item.secondary}
              sx={{
                m: 0,
              }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default Voices;
