import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { accountingService } from "app/services";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import EditIcon from "../../partials/icons/EditIcon";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import DeleteIcon from "app/partials/icons/DeleteIcon";
import { usePermissions } from "../../hooks/useRole";
import moment from "moment";
import AccountingStore from "./store";
import { IAccounting } from "../../services/accounting.service";
import InvoiceShow from "./show";
import Snackbar from "../../widgets/Snackbar";
import QuickCards from "./quickCards";
import CreditNote from "./credit_note";

const Accounting = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  const { data, xhrLoading, updateQuery, refresh, remove, queries, setQuery } =
    useTable<IAccounting>({
      fetch: accountingService.getAll,
      delete: accountingService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<string | null>(null);
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const [sendConfirmId, setSendConfirmId] = useState<string | null>(null);
  const [creditNoteId, setCreditNoteId] = useState<string | null>(null);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.INVOICENUMBER",
        defaultMessage: "Invoice №",
      }),
      field: "number",
      renderCell: (params) => {
        let credit_note = false;
        if (params.row?.credit_note) credit_note = true;

        let class_ = "";
        if (params.row?.status.value == "paid") {
          class_ = "kt-font-success";
        } else if (params.row?.status.value == "draft") {
          class_ = "kt-font-danger";
        } else {
          class_ = "kt-font-warning";
        }
        return (
          <>
            <Tooltip title={params.row?.status?.label}>
              <i
                style={{ fontSize: "10px", marginRight: "10px" }}
                className={`fa fa-circle ${class_}`}
              />
            </Tooltip>
            <Tooltip title={params.row?.number}>
              <Typography variant="inherit">
                {params.row?.number}

                {credit_note ? (
                  <IconButton sx={{ widht: 30, height: 30 }}>C</IconButton>
                ) : (
                  <></>
                )}
              </Typography>
            </Tooltip>
          </>
        );
      },
      minWidth: 80,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.FROM",
        defaultMessage: "From",
      }),
      field: "from",
      renderCell: (params) => {
        return params.row?.from?.name;
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.TO",
        defaultMessage: "To",
      }),
      field: "to",
      renderCell: (params) => {
        return params.row?.to_business
          ? params.row?.to_business.name
          : params.row?.to_user
          ? params.row?.to_user?.firstname + " " + params.row?.to_user?.lastname
          : params.row?.to_commune?.name;
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.TOTAL",
        defaultMessage: "Total",
      }),
      field: "total",
      renderCell: (params) => {
        return (
          (params.row?.is_credit_note ? "-" : "") +
          (params.row?.total + " " + params.row?.currency)
        );
      },
      minWidth: 20,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.PAIDWITH",
        defaultMessage: "Paid with",
      }),
      field: "paid_with",
      renderCell: (params) => {
        return params.row?.paid_with ?? "---";
      },
      minWidth: 20,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.PAYMENTDATE",
        defaultMessage: "Payment date",
      }),
      field: "payment_date",
      renderCell: (params) => {
        return params.row?.paid_at
          ? moment.unix(params.row?.paid_at).local().format("DD/MM/YYYY")
          : "---";
      },
      minWidth: 20,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.DUEDATE",
        defaultMessage: "DUE date",
      }),
      field: "due_date",
      renderCell: (params) => {
        return moment
          .unix(params.row?.overdue_date)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 100,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            {
              <IconButton onClick={() => window.open(params.row?.url)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  fill={"#EC3033"}
                >
                  <path d="M7.98,16.11c0,.47-.41,.86-.89,.86h-.83s0-1.72,0-1.72h.84c.48,0,.89,.39,.89,.86Zm7.02-8.11h6.54c-.35-.91-.88-1.75-1.59-2.46l-3.48-3.49c-.71-.71-1.55-1.24-2.46-1.59V7c0,.55,.45,1,1,1Zm-2.91,7.25h-.84v3.5s.84,0,.84,0c.48,0,.89-.39,.89-.86v-1.78c0-.47-.41-.86-.89-.86Zm9.91-4.76v8.51c0,2.76-2.24,5-5,5H7c-2.76,0-5-2.24-5-5V5C2,2.24,4.24,0,7,0h4.51c.16,0,.32,.01,.49,.02V7c0,1.65,1.35,3,3,3h6.98c.01,.16,.02,.32,.02,.49Zm-12.77,5.62c0-1.16-.96-2.11-2.14-2.11h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.62,.62,.62s.62-.28,.62-.62v-1.22h.84c1.18,0,2.14-.95,2.14-2.11Zm5,0c0-1.16-.96-2.11-2.14-2.11h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.56,.62,.56s1.46,0,1.46,0c1.18,0,2.14-.95,2.14-2.11v-1.78Zm4.79-1.48c0-.35-.28-.62-.62-.62h-2.31c-.35,0-.62,.28-.62,.62v4.81c0,.35,.28,.62,.62,.62s.62-.28,.62-.62v-1.8h1.24c.35,0,.62-.28,.62-.62s-.28-.62-.62-.62h-1.24v-1.14h1.69c.35,0,.62-.28,.62-.62Z" />
                </svg>
              </IconButton>
            }
            {!params.row?.is_credit_note && !params.row?.credit_note && (
              <IconButton
                onClick={() => {
                  setCreditNoteId(params.row?._id);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  fill={"#f9bc13"}
                >
                  <path d="m18,7h2.666c-1.943-3.389-5.697-5.375-9.776-4.941C6.32,2.546,2.598,6.247,2.069,10.812c-.7,6.042,4.026,11.188,9.931,11.188,5.514,0,10-4.486,10-10,0-.553.447-1,1-1s1,.447,1,1c0,6.774-5.641,12.255-12.473,11.991C5.355,23.752.248,18.646.009,12.475-.256,5.642,5.226,0,12,0c4.104,0,7.805,2.034,9.995,5.345l.005-2.345c0-.553.447-1,1-1s1,.447,1,1v3.991c0,1.109-.899,2.009-2.009,2.009h-3.991c-.553,0-1-.447-1-1s.447-1,1-1Zm-6,12c.552,0,1-.447,1-1v-1c1.654,0,3-1.346,3-3,0-1.359-.974-2.51-2.315-2.733l-3.041-.506c-.373-.062-.644-.382-.644-.761,0-.552.449-1,1-1h2.268c.356,0,.688.191.867.501.274.478.886.642,1.366.364.478-.276.642-.888.364-1.366-.534-.925-1.53-1.499-2.598-1.499h-.268v-1c0-.553-.448-1-1-1s-1,.447-1,1v1c-1.654,0-3,1.346-3,3,0,1.359.974,2.51,2.315,2.733l3.04.506c.374.062.645.382.645.761,0,.552-.448,1-1,1h-2.268c-.356,0-.688-.191-.867-.501-.277-.479-.889-.643-1.366-.364-.479.276-.642.888-.365,1.366.535.925,1.531,1.499,2.598,1.499h.268v1c0,.553.448,1,1,1Z" />
                </svg>
              </IconButton>
            )}
            {params.row?.status.value !== "paid" && (
              <>
                <IconButton
                  onClick={() => {
                    setSendConfirmId(params.row?._id);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512.308 512.308"
                    width="18"
                    height="18"
                    fill={"#5ECC62"}
                  >
                    <g>
                      <path d="M505.878,36.682L110.763,431.69c8.542,4.163,17.911,6.351,27.413,6.4h67.669c5.661-0.015,11.092,2.236,15.083,6.251   l36.672,36.651c19.887,20.024,46.936,31.295,75.157,31.317c11.652-0.011,23.224-1.921,34.261-5.653   c38.05-12.475,65.726-45.46,71.403-85.099l72.085-342.4C513.948,64.89,512.311,49.871,505.878,36.682z" />
                      <path d="M433.771,1.652L92.203,73.61C33.841,81.628-6.971,135.44,1.047,193.802c3.167,23.048,13.782,44.43,30.228,60.885   l36.651,36.651c4.006,4.005,6.255,9.439,6.251,15.104v67.669c0.049,9.502,2.237,18.872,6.4,27.413L475.627,6.41   C462.645,0.03,447.853-1.651,433.771,1.652z" />
                    </g>
                  </svg>
                </IconButton>
                <PermissionsGate section={"accounting"} scope={"read"}>
                  <IconButton onClick={() => show(params.row?._id)}>
                    <EditIcon />
                  </IconButton>
                </PermissionsGate>
              </>
            )}

            <PermissionsGate section={"accounting"} scope={"delete"}>
              <IconButton onClick={() => handleClick(params.row?._id)}>
                <DeleteIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (id: string) => {
    setInvoice(id);
  };

  const handleSend = (id: string) => {
    let url = window.location.hostname;

    if (window.location.port) {
      url += ":" + window.location.port;
    }

    accountingService.sendInvoice(id, { url: url }).then((data: any) => {
      if ("error" in data) {
        Snackbar.error(data.error.message);
        return;
      }

      Snackbar.success(
        intl.formatMessage({
          id: "SNACKBAR.SEND",
          defaultMessage: "Send successfully",
        })
      );
      refresh();
    });
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete invoice?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this invoice?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      {sendConfirmId ? (
        <ConfirmDialog
          title="Send invoice?"
          open={Boolean(sendConfirmId)}
          setOpen={() => setSendConfirmId(null)}
          onConfirm={() => {
            handleSend(sendConfirmId);
          }}
        >
          Are you sure you want to send this invoice?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      <PermissionsGate section={"accounting"} scope={"read"}>
        <>
          {creditNoteId ? (
            <CreditNote
              _id={creditNoteId}
              open={Boolean(creditNoteId)}
              onDone={refresh}
              setDialogue={() => setCreditNoteId(null)}
            />
          ) : (
            <></>
          )}
          {invoice ? (
            <InvoiceShow
              _id={invoice}
              open={Boolean(invoice)}
              onDone={refresh}
              setDialogue={() => setInvoice(null)}
            />
          ) : (
            <></>
          )}
        </>
      </PermissionsGate>
      <PermissionsGate section={"accounting"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "accounting", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <AccountingStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            customQuickCards: (
              <QuickCards queries={queries} setQuery={setQuery} />
            ),
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default Accounting;
