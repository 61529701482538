import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { reportService } from "app/services";
import { useTable } from "../../hooks/useTable";
import { IReport } from "../../services/report.service";
import { GridTable } from "../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import { PermissionsGate } from "../../permission/PermissionsGate";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import ReportFilter from "./filter";
import EditIcon from "../../partials/icons/EditIcon";
import { IUser } from "../../interfaces";
import { useHistory } from "react-router-dom";
import ShowReport from "./show";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import UserAvatar from "../../partials/layout/UserAvatar";

const Reports = () => {
  const intl = useIntl();
  const [reportId, setReportId] = useState<string | null>(null);
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  const { data, xhrLoading, remove, refresh, updateQuery, setQuery, queries } =
    useTable<IReport>({
      fetch: reportService.getAll,
      delete: reportService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 120,
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "CASE.INFORMATION.CASE" }),
      field: "case",
      valueGetter: (params) => {
        return params.row?.case?.key;
      },
      minWidth: 100,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.USERS" }),
      field: "users",
      renderCell: (params) => {
        return params.row.users.map((user: IUser) => {
          return <UserAvatar user={user} />;
        });
      },
      minWidth: 200,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "REPORT.LASTUPDATE" }),
      field: "last_report_date",
      valueGetter: (params) => {
        return null; // TODO api not yet done
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "REPORT.NEXTREPORTDATE" }),
      field: "next_report_date",
      valueGetter: (params) => {
        return null; // TODO api not yet done
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "REPORT.REMAININGDAY" }),
      field: "remaining_day",
      renderCell: (params) => {
        let bgColor;
        let txtColor = "white.main";
        if (params.row?.daysRemain >= 14) {
          bgColor = "success.main";
        } else if (params.row?.daysRemain < 14 && params.row?.daysRemain >= 7) {
          bgColor = "warning.main";
        } else if (params.row?.daysRemain < 7) {
          bgColor = "error.main";
        }
        return (
          <Button sx={{ bgcolor: bgColor, color: txtColor }}>
            {params.row?.daysRemain}
          </Button>
        );
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
      field: "status",
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row align-items-center">
            <i
              className="fa fa-genderless mr-1 fs-40"
              style={{ color: params.row?.status?.color }}
            />
            <span>
              {params.row?.status?.title &&
                params.row?.status?.title[intl.locale]}
            </span>
          </div>
        );
      },
      minWidth: 190,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        let granted = false;

        if (me?.role?.slug !== "employee") {
          granted = true;
        }
        if (me?.role?.slug === "employee") {
          granted =
            params.row?.current_status_step === 1 ||
            params.row?.current_status_step === 3;
        }
        return (
          <>
            {granted && (
              <PermissionsGate section={"reports"} scope={"read"}>
                <IconButton onClick={() => show(params.row?._id)}>
                  <EditIcon />
                </IconButton>
              </PermissionsGate>
            )}
            <PermissionsGate section={"reports"} scope={"delete"}>
              <IconButton onClick={() => handleClick(params.row?._id)}>
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (_id: string) => {
    setReportId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"reports"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete report?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this report?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"reports"} scope={"read"}>
        <>
          {reportId ? (
            <ShowReport
              _id={reportId}
              open={Boolean(reportId)}
              setDialogue={() => setReportId(null)}
              onDone={refresh}
            />
          ) : (
            <></>
          )}
          <GridTable
            loading={xhrLoading}
            skeleton={!init}
            pageSize={data?.per_page}
            count={data?.total}
            columns={columns}
            rows={data?.data ?? []}
            tableRef={tableRef}
            filterModel={undefined}
            query={(queries) => updateQuery(queries)}
            filterToolbar={{
              filters: (props: { show: boolean }) => {
                return (
                  <ReportFilter
                    show={props.show}
                    updateQuery={updateQuery}
                    queries={queries}
                    setQuery={setQuery}
                  />
                );
              },
            }}
          />
        </>
      </PermissionsGate>
    </>
  );
};

export default Reports;
