import React, { ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  Tooltip,
  Theme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useIntl } from "react-intl";

type ComponentProps = {
  name: string;
  label?: string;
  formId?: string;
  _maxWidth?: string;
  height?: string;
  sx?: SxProps<Theme> | undefined;
  labelSx?: SxProps<Theme> | undefined;
  disableText?: string;
  value?: number | string;
  disabled?: boolean;
  defaultValue?: string | number;
  error?: string;
  onChange: (event: SelectChangeEvent<any>, child: ReactNode) => void;
  options?: Array<
    { id: string | number; title: string; "data-cy"?: string } | undefined
  >;
  inputRef?: React.Ref<any>;
};

const Select = (props: ComponentProps) => {
  const {
    name,
    label = null,
    sx = null,
    labelSx = null,
    defaultValue,
    error = null,
    onChange,
    options,
    disabled,
    disableText,
    formId,
    inputRef,
  } = props;
  const intl = useIntl();

  return (
    <FormControl
      variant="outlined"
      id={formId}
      fullWidth
      disabled={disabled ?? false}
      {...(error && { error: true })}
    >
      <InputLabel sx={labelSx}>{label}</InputLabel>
      <Tooltip title={disabled ? disableText ?? "" : ""}>
        <MuiSelect
          inputRef={inputRef}
          sx={sx}
          fullWidth
          defaultValue={defaultValue ?? ""}
          label={label}
          name={name}
          onChange={onChange}
        >
          <MenuItem value="">
            {intl.formatMessage({
              id: "STANDARD.NONE",
              defaultMessage: "None",
            })}
          </MenuItem>
          {options &&
            options.map(
              (item) =>
                item && (
                  <MenuItem
                    key={item.id}
                    data-cy={item["data-cy"]}
                    value={item.id}
                  >
                    {item.title}
                  </MenuItem>
                )
            )}
        </MuiSelect>
      </Tooltip>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
