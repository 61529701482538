import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "../../widgets/uncontrolled";
import moment from "moment";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useForm } from "../../hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { IVacancy, vacancyService } from "../../services/vacancy.service";
import Snackbar from "../../widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";
import AttachmentsInfo from "../Cases/show/parts/attachmentsInfo";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

interface FormStateValues {
  full_name: string;
  birthdate: string;
  gender: string;
  email: string;
  phone: string;
  address: string;
  post_number: string;
  city: string;
  languages: Array<string>;
  education_1: string;
  education_2: string;
  education_3: string;
  work_position: string;
  work_experience: string;
  adhd: boolean;
  autism: boolean;
  borderline: boolean;
  ptsd: boolean;
  ocd: boolean;
  criminal: boolean;
  drug_and_alcohol_abuse: boolean;
  physically_vulnerable: boolean;
  handicap: boolean;
  diabetes: boolean;
  application_year: string;
  last_updated: string;
  age: string;
  status: string;
  files?: Array<string>;
}

const initValues = {
  full_name: "",
  birthdate: "",
  gender: "",
  email: "",
  phone: "",
  address: "",
  post_number: "",
  city: "",
  languages: [],
  education_1: "",
  education_2: "",
  education_3: "",
  work_position: "",
  work_experience: "",
  adhd: false,
  autism: false,
  borderline: false,
  ptsd: false,
  ocd: false,
  criminal: false,
  drug_and_alcohol_abuse: false,
  physically_vulnerable: false,
  handicap: false,
  diabetes: false,
  application_year: "",
  last_updated: "",
  age: "",
  status: "",
  files: [],
} as FormStateValues;

const useStyles = makeStyles(() => ({
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.23)",
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
}));

const ShowVacancy: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [vacancy, setVacancy] = useState<IVacancy>();
  const { vacancy_cities } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { vacancy_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { vacancy_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    form.values.files = files?.attachments?.map((file: any) => file._id);
  }, [files]);

  useEffect(() => {
    vacancyService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: vacancy } = data;
        setVacancy(vacancy);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    vacancyService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (vacancy) {
      form.values.full_name = vacancy.full_name;
      form.values.birthdate = moment
        .unix(vacancy.birthdate)
        .local()
        .format("YYYY-MM-DD");
      form.values.age = vacancy.age;
      form.values.gender = vacancy.gender;
      form.values.email = vacancy.email;
      form.values.phone = vacancy.phone;
      form.values.address = vacancy.address;
      form.values.post_number = vacancy.post_number;
      form.values.city = vacancy.city?._id.toString();
      form.values.languages = vacancy.languages.map((v_l) => v_l._id);
      form.values.education_1 = vacancy.education_1;
      form.values.education_2 = vacancy.education_2;
      form.values.education_3 = vacancy.education_3;
      form.values.work_position = vacancy.work_position;
      form.values.work_experience = vacancy.work_experience;
      form.values.adhd = vacancy.adhd;
      form.values.autism = vacancy.autism;
      form.values.borderline = vacancy.borderline;
      form.values.ptsd = vacancy.ptsd;
      form.values.ocd = vacancy.ocd;
      form.values.criminal = vacancy.criminal;
      form.values.drug_and_alcohol_abuse = vacancy.drug_and_alcohol_abuse;
      form.values.physically_vulnerable = vacancy.physically_vulnerable;
      form.values.handicap = vacancy.handicap;
      form.values.diabetes = vacancy.diabetes;
      form.values.application_year = vacancy.application_year;
      form.values.last_updated = vacancy.last_updated;
      form.values.status = vacancy.status?._id;
      form.values.files = files?.file_ids?.map((file: any) => file._id);

      setFiles(vacancy.documents);
    }
  }, [vacancy]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                {intl.formatMessage({ id: "VACANCY.EDIT" })}
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type={"submit"}
                form={"vacancy_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"vacancy_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"full_name"}
                      label={intl.formatMessage({ id: "VACANCY.FULLNAME" })}
                      defaultValue={vacancy?.full_name}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.full_name = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"full_name"}
                        label={intl.formatMessage({ id: "VACANCY.FULLNAME" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Space
                      className={classes.antSpace}
                      direction="vertical"
                      size={12}
                    >
                      <DatePicker
                        className={classes.datePicker}
                        dropdownClassName={classes.antDropdown}
                        onChange={(value, dateString) => {
                          form.values.birthdate = dateString;
                        }}
                        defaultValue={moment(vacancy?.birthdate, "X").local()}
                      />
                    </Space>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Select
                      name={"gender"}
                      formId={"gender"}
                      options={[
                        {
                          id: "male",
                          title: intl.formatMessage({ id: "USERS.MALE" }),
                        },
                        {
                          id: "female",
                          title: intl.formatMessage({ id: "USERS.FEMALE" }),
                        },
                      ]}
                      defaultValue={vacancy?.gender}
                      label={intl.formatMessage({ id: "USERS.GENDER" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.gender = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"gender"}
                        options={[]}
                        label={intl.formatMessage({ id: "USERS.GENDER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"age"}
                      defaultValue={vacancy?.age}
                      label={intl.formatMessage({ id: "VACANCY.AGE" })}
                      type={"number"}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.age = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"age"}
                        label={intl.formatMessage({ id: "VACANCY.AGE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"email"}
                      defaultValue={vacancy?.email}
                      label={intl.formatMessage({
                        id: "CASE.INFORMATION.EMAIL",
                      })}
                      type={"email"}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.email = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"email"}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.EMAIL",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"phone"}
                      label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                      defaultValue={vacancy?.phone}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.phone = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"phone"}
                        label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"address"}
                      label={intl.formatMessage({ id: "STANDARD.ADDRESS" })}
                      defaultValue={vacancy?.address}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.address = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"address"}
                        label={intl.formatMessage({ id: "STANDARD.ADDRESS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"post_number"}
                      label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                      defaultValue={vacancy?.post_number}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.post_number = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"post_number"}
                        label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Select
                      formId={"city"}
                      name={"city"}
                      options={vacancy_cities?.map((city) => {
                        return {
                          id: city._id.toString(),
                          title: city.title[intl.locale],
                        };
                      })}
                      label={intl.formatMessage({ id: "USERS.CITY" })}
                      defaultValue={vacancy?.city?._id}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.city = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"city"}
                        options={[]}
                        label={intl.formatMessage({ id: "USERS.CITY" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.MultipleSelect
                      name={"languages"}
                      formId={"languages"}
                      options={vacancy_languages?.map((language) => {
                        return {
                          id: language._id,
                          title: language.title[intl.locale],
                        };
                      })}
                      defaultValue={vacancy?.languages.map(
                        (vacancy_language) => vacancy_language._id
                      )}
                      label={intl.formatMessage({ id: "USERS.LANGUAGES" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.languages = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"languages"}
                        options={[]}
                        label={intl.formatMessage({ id: "USERS.LANGUAGES" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"education_1"}
                      label={intl.formatMessage({ id: "VACANCY.EDUCATION1" })}
                      defaultValue={vacancy?.education_1}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.education_1 = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"education_1"}
                        label={intl.formatMessage({ id: "VACANCY.EDUCATION1" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"education_2"}
                      label={intl.formatMessage({ id: "VACANCY.EDUCATION2" })}
                      defaultValue={vacancy?.education_2}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.education_2 = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"education_2"}
                        label={intl.formatMessage({ id: "VACANCY.EDUCATION2" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"education_3"}
                      label={intl.formatMessage({ id: "VACANCY.EDUCATION3" })}
                      defaultValue={vacancy?.education_3}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.education_3 = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"education_3"}
                        label={intl.formatMessage({ id: "VACANCY.EDUCATION3" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"work_position"}
                      label={intl.formatMessage({
                        id: "VACANCY.WORKPOSITION",
                      })}
                      defaultValue={vacancy?.work_position}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.work_position = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"work_position"}
                        label={intl.formatMessage({
                          id: "VACANCY.WORKPOSITION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"work_experience"}
                      label={intl.formatMessage({
                        id: "VACANCY.WORKEXPERIENCE",
                      })}
                      defaultValue={vacancy?.work_experience}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.work_experience = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"work_experience"}
                        label={intl.formatMessage({
                          id: "VACANCY.WORKEXPERIENCE",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"application_year"}
                      label={intl.formatMessage({
                        id: "VACANCY.APPLICATIONYEAR",
                      })}
                      defaultValue={vacancy?.application_year}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.application_year = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"application_year"}
                        label={intl.formatMessage({
                          id: "VACANCY.APPLICATIONYEAR",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Input
                      name={"last_updated"}
                      label={intl.formatMessage({
                        id: "VACANCY.LASTUPDATED",
                      })}
                      defaultValue={vacancy?.last_updated}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.last_updated = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"last_updated"}
                        label={intl.formatMessage({
                          id: "VACANCY.LASTUPDATED",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && vacancy ? (
                    <Controls.Select
                      name={"status"}
                      formId={"status"}
                      options={vacancy_statuses?.map((vacancy_status) => {
                        return {
                          id: vacancy_status._id.toString(),
                          title: vacancy_status.title[intl.locale],
                        };
                      })}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      defaultValue={vacancy?.status?._id}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.status = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"status"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded && vacancy ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.adhd = event.target.checked;
                          }}
                          id={"adhd"}
                          defaultChecked={Number(vacancy?.adhd) === 1}
                        />
                      }
                      label="ADHD"
                      name={"adhd"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="ADHD"
                        name={"adhd"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded && vacancy ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.autism = event.target.checked;
                          }}
                          id={"autism"}
                          defaultChecked={Number(vacancy?.autism) === 1}
                        />
                      }
                      label={intl.formatMessage({ id: "VACANCY.AUTISM" })}
                      name={"autism"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: "VACANCY.AUTISM" })}
                        name={"autism"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded && vacancy ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.borderline = event.target.checked;
                          }}
                          id={"borderline"}
                          defaultChecked={Number(vacancy?.borderline) === 1}
                        />
                      }
                      label={intl.formatMessage({ id: "VACANCY.BORDERLINE" })}
                      name={"borderline"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: "VACANCY.BORDERLINE" })}
                        name={"borderline"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded && vacancy ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.ptsd = event.target.checked;
                          }}
                          id={"ptsd"}
                          defaultChecked={Number(vacancy?.ptsd) === 1}
                        />
                      }
                      label="PTSD"
                      name={"ptsd"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="PTSD"
                        name={"ptsd"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded && vacancy ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.ocd = event.target.checked;
                          }}
                          id={"ocd"}
                          defaultChecked={Number(vacancy?.ocd) === 1}
                        />
                      }
                      label="OCD"
                      name={"ocd"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="OCD"
                        name={"ocd"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded && vacancy ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.criminal = event.target.checked;
                          }}
                          id={"criminal"}
                          defaultChecked={Number(vacancy?.criminal) === 1}
                        />
                      }
                      label={intl.formatMessage({ id: "VACANCY.CRIMINAL" })}
                      name={"criminal"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: "VACANCY.CRIMINAL" })}
                        name={"criminal"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded && vacancy ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.drug_and_alcohol_abuse =
                              event.target.checked;
                          }}
                          id={"drug_and_alcohol_abuse"}
                          defaultChecked={
                            Number(vacancy?.drug_and_alcohol_abuse) === 1
                          }
                        />
                      }
                      label={intl.formatMessage({ id: "VACANCY.DAAB" })}
                      name={"drug_and_alcohol_abuse"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: "VACANCY.DAAB" })}
                        name={"drug_and_alcohol_abuse"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded && vacancy ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.physically_vulnerable =
                              event.target.checked;
                          }}
                          id={"physically_vulnerable"}
                          defaultChecked={
                            Number(vacancy?.physically_vulnerable) === 1
                          }
                        />
                      }
                      label={intl.formatMessage({
                        id: "VACANCY.PHYSICALLYVULNERABLE",
                      })}
                      name={"physically_vulnerable"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({
                          id: "VACANCY.PHYSICALLYVULNERABLE",
                        })}
                        name={"physically_vulnerable"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded && vacancy ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.diabetes = event.target.checked;
                          }}
                          id={"diabetes"}
                          defaultChecked={Number(vacancy?.diabetes) === 1}
                        />
                      }
                      label={intl.formatMessage({
                        id: "VACANCY.RELATEDDISORDERS",
                      })}
                      name={"diabetes"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({
                          id: "VACANCY.RELATEDDISORDERS",
                        })}
                        name={"diabetes"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && vacancy ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"STANDARD.FILES"}
                          defaultMessage={"STANDARD.FILES"}
                        />
                      </Typography>
                      <AttachmentsInfo setParams={setFiles} params={vacancy} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"files"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowVacancy;
