import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";

type ComponentProps = {
  label: string;
  name: string;
  value?: Date | string;
  variant?: string;
  onChange: (
    date: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
};

const DateWithTimePicker = (props: ComponentProps) => {
  const { label, value, name, onChange, variant } = props;

  const convertToDefEventPara = (name: string, value: any) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={label}
        inputFormat="dd/MM/yyyy HH:mm"
        ampm={false}
        value={value ?? new Date()}
        renderInput={(params: any) => (
          <TextField
            {...params}
            name={name}
            defaultValue={new Date()}
            variant={variant ?? "outlined"}
            fullWidth
          />
        )}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};

export default DateWithTimePicker;
