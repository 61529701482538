import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as _setting from "../../store/ducks/setting.duck";
import TimeTrackShowDialogue from "../../partials/dialogues/show/time_track";
import Snackbar from "../../widgets/Snackbar";
import { caseService, timeTrackService } from "../../services";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "../../store/ducks/setting.duck";
import { ICase } from "../../services/case.service";

type ShowTimeTrackProps = {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
};

type FormStateValues = {
  start_date_time: string;
  end_date_time: string;
  case?: string;
  start_location: string;
  end_location: string;
  type: string;
  related: string;
};

const ShowTimeTrack = (props: ShowTimeTrackProps) => {
  const [cases, setCases] = useState<Array<ICase>>();

  useEffect(() => {
    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props.open]);

  const handleSave = (validated: FormStateValues, callback: () => void) => {
    if (!validated) {
      Snackbar.error("Store error");
      return;
    }

    timeTrackService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error("Something went wrong");
        }

        Snackbar.info("Added");

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          callback();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <TimeTrackShowDialogue
      _id={props._id}
      open={props.open}
      setDialogue={props.setDialogue}
      save={handleSave}
      case_needed={true}
      cases={cases}
    />
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowTimeTrack);
