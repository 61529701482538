import React from "react";
import { TextField, TextFieldProps, Theme, Tooltip } from "@mui/material";
import { SxProps } from "@mui/system";

const Input = (
  props: TextFieldProps & {
    disableText?: string;
    dataCy?: string;
    sx?: SxProps<Theme> | undefined;
  }
) => {
  const {
    name,
    label,
    defaultValue,
    rows,
    error,
    sx,
    onChange,
    onKeyDown,
    onBlur,
    onKeyPress,
    disabled,
    helperText,
    disableText,
    type,
    multiline,
    InputProps,
    inputRef,
    dataCy,
  } = props;

  return (
    <Tooltip title={disabled ? disableText ?? "" : ""}>
      <TextField
        data-cy={dataCy}
        inputRef={inputRef}
        variant="outlined"
        fullWidth
        sx={sx}
        defaultValue={defaultValue ?? ""}
        label={label}
        type={type ?? undefined}
        multiline={multiline ?? false}
        disabled={disabled ?? false}
        name={name ?? ""}
        onChange={onChange}
        onKeyDown={onKeyDown ?? undefined}
        onBlur={onBlur ?? undefined}
        rows={rows ?? 1}
        {...(error && { error: true, helperText: helperText })}
        InputProps={InputProps ?? undefined}
        onKeyPress={onKeyPress}
      />
    </Tooltip>
  );
};

export default Input;
