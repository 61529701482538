import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { IPermission, IUser } from "../interfaces";

export const useRole = (): null | string | undefined => {
  const { user } = useSelector(({ auth }: RootState) => auth);
  return user && user.role.title["en"]; // TODO change it to user slug
};

export const usePermissions = (): null | undefined | IPermission => {
  const { user } = useSelector(({ auth }: RootState) => auth);
  return user && user.role.permissions;
};
