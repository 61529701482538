import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { contractTemplateService, userService } from "../../../services";
import Snackbar from "../../../widgets/Snackbar";
import { ICase } from "../../../services/case.service";
import { useForm } from "../../../hooks/useForm";
import { IContractTemplate } from "../../../services/contractTemplate.service";
import moment from "moment";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  case: string;
  offerer: string;
  counterparty: string;
  template: number;
  subject: string;
  status: string;
  content: string;
  start_date: string;
  end_date: string;
};

const initValues = {
  case: "",
  start_date: moment().format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  status: "",
  content: "",
  subject: "",
} as FormStateValues;

const useStyles = makeStyles(() => ({
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.23)",
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const UserContractStore: FC<IFormProps> = (props) => {
  const form = useForm<FormStateValues>(initValues);
  const classes = useStyles();
  const intl = useIntl();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [cases, setCases] = useState<Array<ICase>>();
  const [contractTemplates, setContractTemplates] =
    useState<Array<IContractTemplate>>();
  const [contractTemplate, setContractTemplate] = useState<IContractTemplate>();
  const { contract_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleClose = () => {
    props.setDialogue(false);
  };

  useEffect(() => {
    userService
      .getCases(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });

    contractTemplateService
      .getAll()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: templates } = data;
        setContractTemplates(templates);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props.open]);

  const handleTemplateSelection = (templateId: string) => {
    let selectedTemplate =
      contractTemplates &&
      contractTemplates.find((c_t) => c_t._id === templateId);
    setContractTemplate(selectedTemplate);
  };

  const handleSave = () => {
    form.values.content = contractTemplate?.content ?? "";
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    userService
      .addContract(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.ADDED" }));
        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CONTRACT.ADD"}
                  defaultMessage={"CONTRACT.ADD"}
                />
              </Typography>
              <Button
                form={"contract_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"contract_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Select
                      name={"template"}
                      options={
                        contractTemplates &&
                        contractTemplates.map((template) => {
                          return {
                            id: template._id,
                            title: template.title,
                          };
                        })
                      }
                      label={intl.formatMessage({
                        id: "PERMISSION.TITLE.CONTRACT_TEMPLATE",
                      })}
                      onChange={(e) => {
                        if (!e.target.value) return;

                        handleTemplateSelection(e.target.value);
                        form.values.template = e.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"template"}
                        options={[]}
                        onChange={() => {}}
                        label={intl.formatMessage({
                          id: "PERMISSION.TITLE.CONTRACT_TEMPLATE",
                        })}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"subject"}
                      label={"Subject"}
                      onChange={(e) => {
                        if (!e.target.value) return;

                        form.values.subject = e.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"subject"}
                        label={"Subject"}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Select
                      name={"case"}
                      options={
                        cases &&
                        cases.map((_case) => {
                          return {
                            id: _case._id,
                            title: _case.key,
                          };
                        })
                      }
                      label={intl.formatMessage({
                        id: "CASE.INFORMATION.CASE",
                      })}
                      onChange={(e) => {
                        if (!e.target.value) return;

                        form.values.case = e.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"case"}
                        options={[]}
                        onChange={() => {}}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.CASE",
                        })}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  {loaded ? (
                    <Space
                      className={classes.antSpace}
                      direction="vertical"
                      size={12}
                    >
                      <DatePicker
                        placeholder={intl.formatMessage({
                          id: "CASE.TIMETRACK.STARTDATE",
                        })}
                        className={classes.datePicker}
                        dropdownClassName={classes.antDropdown}
                        onChange={(value, dateString) => {
                          form.values.start_date = dateString;
                        }}
                      />
                    </Space>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  {loaded ? (
                    <Space
                      className={classes.antSpace}
                      direction="vertical"
                      size={12}
                    >
                      <DatePicker
                        placeholder={intl.formatMessage({
                          id: "CASE.TIMETRACK.ENDDATE",
                        })}
                        className={classes.datePicker}
                        dropdownClassName={classes.antDropdown}
                        onChange={(value, dateString) => {
                          form.values.end_date = dateString;
                        }}
                      />
                    </Space>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Typography
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="body1"
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: contractTemplate?.content ?? "",
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Editor
                        name={"content"}
                        label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default UserContractStore;
