import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { caseService } from "app/services";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { IReport } from "../../../services/report.service";
import { FormStateValues } from "./report";
import moment from "moment/moment";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface IFormProps {
  _case: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type TAdditionalFormStateValues = {
  content: string;
} & FormStateValues;

const ShowReport = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<TAdditionalFormStateValues>();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [report, setReport] = useState<IReport>();

  useEffect(() => {
    caseService
      .getOneReport(props._case, props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: report } = data;
        setReport(report);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._case, props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .updateReport(props._case, props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (report) {
      form.values.status = report?.status?._id;
      form.values.report_date = moment
        .unix(report?.report_date)
        .utc()
        .format("YYYY-MM-DD");
      form.values.content = report?.content;
    }
  }, [report]);

  const handleNextStep = () => {
    caseService
      .reportNextStep(props._id)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        props.setDialogue(false);
        props.onDone();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullScreen={true}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
            p: "0px 20px",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseOutlinedIcon />
            </IconButton>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"CASE.REPORT.SHOW"}
                defaultMessage={"CASE.REPORT.SHOW"}
              />
            </Typography>
            {report && (
              <>
                {report.status.slug !== "sent_to_socialworker" ? (
                  <>
                    <Button
                      autoFocus
                      data-cy-class={""}
                      data-cy-case-id={props._case}
                      data-cy-finance-id={props._id}
                      color="inherit"
                      type={"button"}
                      sx={{
                        backgroundColor: report.status?.color,
                        borderRadius: 2,
                        color: "#ffffff",
                        mr: 2,
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      {report.status?.title[intl.locale]} -{" "}
                      {report.current_status_step}
                    </Button>
                    <Button
                      autoFocus
                      data-cy-class={""}
                      data-cy-case-id={props._case}
                      data-cy-finance-id={props._id}
                      color="inherit"
                      type={"button"}
                      onClick={handleNextStep}
                      sx={{
                        backgroundColor: "#0D99FF",
                        borderRadius: 2,
                        color: "#ffffff",
                        mr: 2,
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <FormattedMessage
                        id={"NEXT_STEP"}
                        defaultMessage={"NEXT_STEP"}
                      />{" "}
                      - {report && report.next_status?.step}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="15"
                        height="15"
                      >
                        <path
                          fill="#FFF"
                          d="M11.832,24a1.5,1.5,0,0,1-1.061-2.561l7.672-7.671a2.5,2.5,0,0,0,0-3.536L10.771,2.561A1.5,1.5,0,0,1,12.893.439l7.671,7.672a5.5,5.5,0,0,1,0,7.778l-7.671,7.672A1.5,1.5,0,0,1,11.832,24Z"
                        />
                        <path
                          fill="#FFF"
                          d="M2.287,24a1.5,1.5,0,0,1-1.06-2.561l9.085-9.085a.5.5,0,0,0,0-.708L1.227,2.561A1.5,1.5,0,0,1,3.348.439l9.086,9.086a3.507,3.507,0,0,1,0,4.949L3.348,23.561A1.5,1.5,0,0,1,2.287,24Z"
                        />
                      </svg>
                    </Button>
                    <Button
                      autoFocus
                      data-cy-class={""}
                      data-cy-case-id={props._case}
                      data-cy-finance-id={props._id}
                      color="inherit"
                      type={"button"}
                      onClick={handleNextStep}
                      sx={{
                        backgroundColor: report.next_status?.status?.color,
                        borderRadius: 2,
                        color: "#ffffff",
                        mr: 2,
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      {report.next_status?.status?.title[intl.locale]}
                    </Button>
                  </>
                ) : (
                  <Button
                    autoFocus
                    data-cy-class={""}
                    data-cy-case-id={props._case}
                    data-cy-finance-id={props._id}
                    color="inherit"
                    type={"button"}
                    sx={{
                      backgroundColor: "#0D99FF",
                      borderRadius: 2,
                      color: "#ffffff",
                      mr: 2,
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    Completed
                  </Button>
                )}
              </>
            )}

            <Button
              autoFocus
              data-cy-class={"report_form"}
              data-cy-case-id={props._case}
              data-cy-finance-id={props._id}
              color="inherit"
              type={"submit"}
              form={"report_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id={"report_form"}
            onSubmit={(e) => form.handleSubmit(e, handleSave)}
          >
            <Grid container spacing={2} mb={2}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded && report ? (
                  <Controls.Editor
                    name={"content"}
                    label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                    data={report?.content}
                    onChange={(data: string) => {
                      form.values.content = data;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Editor
                      name={"content"}
                      label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowReport;
