import React, { FC, useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { IMessage } from "../messenger";
import Message from "./message";
import moment from "moment";
import DateMessage from "../helpers/dateMessage";
import { FormattedMessage, useIntl } from "react-intl";
import NoSelectedChatRoom from "../../../../partials/icons/NoSelectedChatRoom";
import { chatService } from "../../../../services/chat.service";
import Snackbar from "../../../../widgets/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import * as chat from "../../../../store/ducks/chat.duck";
import { usePermissions } from "../../../../hooks/useRole";
import {
  hasPermission,
  PermissionsGate,
} from "../../../../permission/PermissionsGate";
import ConfirmDialog from "../../../../partials/dialogues/confirmDialogue";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { userService } from "../../../../services";
import { IUser } from "../../../../interfaces";
import { IRoom } from "../../rooms/rooms";
import { messages as dummyMessages } from "../../dummy";
import { Socket } from "socket.io-client";
import { put, select } from "redux-saga/effects";
import { actionTypes } from "../../../../store/ducks/chat.duck";

const useStyle = makeStyles((theme: Theme) => ({
  body: {
    display: "flex",
    flexDirection: "column !important" as any,
    justifyContent: "flex-end",
    overflowY: "scroll",
    flexGrow: 1,
    position: "relative",
  },
  scrollLayout: {
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column-reverse",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  horizontal: {
    minWidth: 40,
    paddingBottom: 18,
    paddingLeft: 16,
    paddingRight: 16,
    display: "flex",
  },
}));

export type MessengerBodyProps = {
  messages: Array<IMessage>;
  active: boolean;
  room: string;
  archiveOpen: boolean;
  needResetMessages: boolean;
  horizontalLineDBLClick: (message: IMessage) => void;
  updateRoom: (room: IRoom) => void;
  users: IUser[];
};

const Body: FC<MessengerBodyProps & { me: string }> = (props) => {
  const {
    me,
    horizontalLineDBLClick,
    active,
    room: _room,
    archiveOpen,
    needResetMessages,
    updateRoom,
    users: roomUsers,
  } = props;
  const [lastData, setLastData] = useState<Array<IMessage>>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [messagesLoading, setMessagesLoading] = useState<Boolean>(false);
  const [usersLoading, setUsersLoading] = useState<Boolean>(false);
  const [confirm, setConfirm] = useState<string | null>(null);
  const classes = useStyle();
  const intl = useIntl();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const loader = useRef<HTMLDivElement | null>(null);
  const userLoader = useRef<HTMLDivElement | null>(null);
  const [skip, setSkip] = useState<number>(0);
  const limit = 15;
  const { messages } = useSelector((state: RootState) => state.chat);
  const permissions = usePermissions();
  const [drawer, setDrawer] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [userSkip, setUserSkip] = useState<number>(0);
  const [userLastData, setUserLastData] = useState<Array<IUser>>([]);
  const [forwardMessage, setForwardMessage] = useState<IMessage | null>(null);
  const { type, roomsSkip, roomsLimit, archived, search } = useSelector(
    (state: RootState) => state.chat
  );

  const { socket }: { socket: Socket | {} } = useSelector(
    (state: RootState) => state.socket
  );
  const dispatch = useDispatch();

  const helperChecker = (
    currentMessage: IMessage,
    prevMessage: IMessage
  ): JSX.Element => {
    const currentDate = moment.unix(currentMessage._created_at);
    const nextDate = moment.unix(prevMessage._created_at);
    const isSameDay: boolean = currentDate.isSame(nextDate, "day");
    const isToday: boolean = nextDate.isSame(moment(), "day");
    let ret: JSX.Element = <></>;

    if (!isSameDay) {
      ret = (
        <DateMessage
          date={
            isToday
              ? intl.formatMessage({
                  id: "STANDARD.TODAY",
                  defaultMessage: "Today",
                })
              : moment
                  .unix(prevMessage._created_at)
                  .local()
                  .format("DD/MM/YYYY")
          }
        />
      );
    }

    return ret;
  };

  useEffect(() => {
    if (needResetMessages) dispatch(chat.actions.setMessages([], null));
  }, [needResetMessages]);

  const getRoomMessages = (type: string, id: string) => {
    if (type === "load") {
      dispatch(chat.actions.setMessages([], null));
      setMessagesLoading(true);
      setLoading(true);
      setSkip(0);
    }

    let queries = [
      {
        name: "skip",
        value: type === "load" ? 0 : skip,
      },
      {
        name: "limit",
        value: limit,
      },
    ];

    if (archiveOpen) {
      queries.push({
        name: "archive",
        value: 1,
      });
    }

    if (active) {
      chatService
        .getMessages(id, queries)
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          let { data: _messages } = data;
          setLoading(false);

          if (type === "load") {
            dispatch(chat.actions.setMessages(_messages, id));
          } else {
            dispatch(chat.actions.setMessages([...messages, ..._messages], id));
          }

          setLastData(_messages);
          setMessagesLoading(false);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  };

  useEffect(() => {
    getRoomMessages("load", _room);
  }, [_room]);

  useEffect(() => {
    if (skip && lastData?.length > 14) {
      getRoomMessages("scroll", _room);
    }
  }, [skip]);

  useEffect(() => {
    if (limit && loader.current) {
      new IntersectionObserver(
        (entities) => {
          if (entities[0].isIntersecting) {
            setSkip((prevSkip) => prevSkip + limit);
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0,
        }
      ).observe(loader.current);
    }
  }, [loading]);

  useEffect(() => {
    if (contentRef.current && _room) {
      contentRef.current.scrollTop = 0;
    }
  }, [_room]);

  const _horizontalLineDBLClick = (id: string) => {
    let message = messages.find((m) => m._id === id);
    if (message) horizontalLineDBLClick(message);
  };

  const handleDelete = (message: string) => {
    chatService.deleteMessage(_room, message).then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      getRoomMessages("load", _room);
    });
  };

  const getBoundedUsers = (type: string) => {
    if (type === "load") {
      setUsersLoading(true);
      setSkip(0);
    }

    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "bounded_users",
        value: "1",
      },
      {
        name: "skip",
        value: type === "load" ? 0 : userSkip,
      },
      {
        name: "limit",
        value: limit,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        if (type === "load") {
          setUsers(users);
        } else {
          setUsers((prevUsers) => [...prevUsers, ...users]);
        }

        setUserLastData(users);
        setUsersLoading(false);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (drawer) getBoundedUsers("load");
  }, [drawer]);

  useEffect(() => {
    if (userSkip && userLastData?.length > 14) {
      getBoundedUsers("scroll");
    }
  }, [userSkip]);

  useEffect(() => {
    if (limit && userLoader.current) {
      new IntersectionObserver(
        (entities) => {
          if (entities[0].isIntersecting) {
            setUserSkip((prevSkip) => prevSkip + limit);
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0,
        }
      ).observe(userLoader.current);
    }
  }, [usersLoading]);

  const onForward = (user: string) => {
    let userIds: string[] = [user];

    if (forwardMessage) {
      chatService
        .createRoom(userIds, forwardMessage.message, forwardMessage._id)
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }
          const { data: room } = data;

          if (updateRoom) {
            updateRoom(room);
          }

          // dispatch(chat.actions.setRooms([]));
          //
          // dispatch(
          //   chat.actions.getRooms({
          //     type,
          //     limit: roomsLimit,
          //     skip: roomsSkip,
          //     archived,
          //     search,
          //   })
          // );
          dispatch(chat.actions.getMessages(room._id));
        });
    }

    setDrawer(false);
  };

  return (
    <>
      <PermissionsGate section={"chat"} scope={"delete"}>
        {confirm ? (
          <ConfirmDialog
            title="Delete message?"
            open={Boolean(confirm)}
            setOpen={() => setConfirm(null)}
            onConfirm={() => {
              handleDelete(confirm);
            }}
          >
            Are you sure you want to delete this message?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <Grid className={classes.body}>
        <Grid className={classes.scrollLayout} ref={contentRef}>
          {active ? (
            !messagesLoading ? (
              messages.map((message: IMessage, index: number) => {
                let prevMessage: IMessage | undefined = messages[index - 1];
                let position: "left" | "right" =
                  message?.created_by?._id === me ? "right" : "left";

                return (
                  <>
                    {prevMessage && helperChecker(message, prevMessage)}
                    <Grid
                      itemID={index.toString()}
                      className={classes.horizontal}
                      sx={
                        position === "left"
                          ? {
                              justifyContent: "flex-start",
                            }
                          : {
                              justifyContent: "flex-end",
                            }
                      }
                      onDoubleClick={() => _horizontalLineDBLClick(message._id)}
                    >
                      <Message
                        {...message}
                        onReplyClick={() =>
                          _horizontalLineDBLClick(message._id)
                        }
                        onDeleteClick={() => {
                          permissions &&
                          hasPermission(permissions, "chat", "delete")
                            ? setConfirm(message._id)
                            : Snackbar.error("Permission Denied");
                        }}
                        onForwardClick={() => {
                          setForwardMessage(message);
                          setDrawer(true);
                        }}
                        position={position}
                      />
                    </Grid>
                  </>
                );
              })
            ) : (
              <Grid
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Grid>
            )
          ) : (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <NoSelectedChatRoom />
              <Typography fontSize={17} variant={"body1"}>
                <FormattedMessage
                  id={"SELECT.CHAT"}
                  defaultMessage={"SELECT.CHAT"}
                />
              </Typography>
            </Grid>
          )}
          {!loading && limit && skip + limit && (
            <Grid
              ref={loader}
              sx={{ display: "flex", justifyContent: "center", p: 2 }}
            >
              {lastData.length > 14 && <CircularProgress />}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid key={"right"}>
        <Drawer
          sx={{ "& .MuiDrawer-paper": { width: "20%" } }}
          anchor={"right"}
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          <List sx={{ height: "100%" }}>
            {users.length > 0 ? (
              users.map((user) => (
                <ListItem key={user._id} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      onForward(user._id);
                    }}
                  >
                    <ListItemText
                      primary={user.firstname + " " + user.lastname}
                      secondary={"Online"}
                      primaryTypographyProps={{
                        color: "#0D99FF",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <Grid
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Grid>
            )}
            {!usersLoading && limit && skip + limit && (
              <Grid
                ref={userLoader}
                sx={{ display: "flex", justifyContent: "center", p: 2 }}
              >
                {userLastData.length > 14 && <CircularProgress />}
              </Grid>
            )}
          </List>
        </Drawer>
      </Grid>
    </>
  );
};

export default Body;
