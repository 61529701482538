import React, { FC } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import ChartCard from "./ChartCard";
import NoData from "../partials/NoData";

interface IProps {
  onClick?: Function;
  spent: number;
  total: number;
}

const WeeklyStatisticsChart: FC<IProps> = (props) => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  // @ts-ignore
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

  const weekNumber = Math.ceil(days / 7);

  const spent = +(props.spent / 3600).toFixed(0);
  const remain = props.total / 3600 - spent;
  const series = [spent, remain];

  let color: string;

  const _percent = +((spent / (props.total / 3600)) * 100).toFixed(0);

  if (_percent <= 33) {
    color = "#ffcc00";
  } else if (_percent > 34 && _percent <= 66) {
    color = "#0D99FF";
  } else {
    color = "#2FFF93";
  }

  const onClick = () => {
    props.onClick && props.onClick();
  };

  return (
    <ChartCard
      title={"Time - progress"}
      ratio={"100%"}
      body={
        <Box
          sx={{
            height: "calc(100% + 60px - 31%)",
            width: "66%",
            paddingBottom: "4%",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <ReactApexChart
            options={{
              grid: {
                padding: {
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0,
                },
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              tooltip: {
                followCursor: true,
                fillSeriesColor: false,
                theme: "light",
                y: {
                  formatter: function (value) {
                    return value + " hours";
                  },
                },
              },
              labels: ["Used", "Remain"],
              colors: [color, "#F4F4F4"],
              stroke: {
                width: 2,
                lineCap: "round",
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "75%",
                    labels: {
                      show: true,
                      value: {
                        show: true,
                        fontSize: "20px",
                        fontWeight: 600,
                      },
                      total: {
                        show: true,
                        showAlways: true,
                        color: "#666!important",
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        formatter: function (value) {
                          return (
                            +((spent / (props.total / 3600)) * 100).toFixed(0) +
                            "%"
                          );
                        },
                        label: "Week " + weekNumber,
                      },
                    },
                  },
                },
              },
            }}
            series={series}
            type="donut"
            height={"100%"}
            width={"100%"}
          />
        </Box>
      }
    />
  );
};

export default WeeklyStatisticsChart;
