import React, { MouseEventHandler } from "react";
import { Button as MuiButton } from '@mui/material';
import { makeStyles } from "@material-ui/styles";

type ComponentProps = {
  text: string,
  size: 'small' | 'medium' | 'large',
  color:  "inherit" | "error" | "primary" | "secondary" | "success" | "info" | "warning" | undefined,
  variant: 'text' | 'outlined' | 'contained',
  error?: string,
  onClick: MouseEventHandler<HTMLButtonElement>,
}

const useStyles = makeStyles((theme : any) => ({
  root: {
    margin: theme.spacing(0.5)
  },
  label: {
    textTransform: 'none'
  }
}))

export const Button = (props : ComponentProps) => {


  const { text, size, color, variant, onClick, } = props

  return (
    <MuiButton variant={variant || "contained"}
               size={size || "large"}
               color={color || "primary"}
               onClick={onClick}>
      {text}
    </MuiButton>
  )
}

export default Button;
