import React, { useEffect, useMemo, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { connect, useSelector } from "react-redux";
import { Box, Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { caseService, offerService } from "app/services";
import moment from "moment";
import { useTable } from "../../hooks/useTable";
import { IOffer } from "../../services/offer.service";
import { GridTable } from "../../partials/layout/GridTable";
import OfferStore from "./store";
import ShowOffer from "./show";
import { FormattedMessage, useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "../../store/ducks/setting.duck";
import OfferFilter from "./filter";
import { usePermissions } from "../../hooks/useRole";
import { useLocation } from "react-router-dom";
import EditIcon from "../../partials/icons/EditIcon";
import Controls from "../../widgets/uncontrolled";
import { RootState } from "../../store/store";
import Snackbar from "../../widgets/Snackbar";

type Props = {
  lang: string;
  settings_loading: boolean;
};

const Offers = (props: Props) => {
  const intl = useIntl();
  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);
  const parameter = query.get("status") ?? "";
  const { offer_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [status, setStatus] = useState<string | null>(null);
  const permissions = usePermissions();
  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<IOffer>({
      fetch: offerService.getAll,
      delete: offerService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
        {
          name: "status",
          value: parameter,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const [quickCardsCount, setQuickCardsCount] = useState<{
    accepted: number;
    waiting: number;
    cancelled: number;
  }>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleStatus = () => {
    if (status) {
      if (!selectedIds.length) Snackbar.error("Choose least 1 row");

      offerService.updateStatuses({ ids: selectedIds, status }).then((data) => {
        if ("status" in data) {
          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
          refresh();
        }
      });
    }
  };

  useEffect(() => {
    offerService.getQuickCardsCount().then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const { data: counts } = data;

      setQuickCardsCount(counts);
    });
  }, [xhrLoading]);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({ id: "CASE.FOCUS_AREA" }),
      field: "focus_area",
      renderCell: (params) => {
        return (
          params.row?.focus_area?.title &&
          (params.row?.focus_area?.title[intl.locale] ?? "")
        );
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" }),
      field: "email",
      valueGetter: (params) => {
        return params.row?.email;
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
      field: "status",
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row align-items-center">
            <i
              className="fa fa-genderless mr-1 fs-40"
              style={{ color: params.row?.status.color }}
            />
            <span>
              {params.row?.status?.title &&
                params.row?.status.title[intl.locale]}
            </span>
          </div>
        );
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => showNotes(params.row?._id)}
              data-cy-class={"offer_note"}
              data-cy-id={params.row?._id}
            >
              <FolderIcon sx={{ color: "warning.main" }} />
            </IconButton>
            <PermissionsGate section={"offers"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"offer_show"}
                data-cy-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"offers"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"offer_delete"}
                data-cy-id={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const showNotes = (_id: string) => {
    const url = `/offers/${_id}/notes`;
    window.open(url);
  };

  const [offerId, setOfferId] = useState<string | null>(null);

  const show = (_id: string) => {
    setOfferId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const resetCardQuery = (
    isActive: boolean,
    name: "accepted" | "waiting" | "cancelled"
  ) => {
    let _queries = queries?.filter(
      (q) =>
        q.name !== "accepted" && q.name !== "waiting" && q.name !== "cancelled"
    );

    if (!_queries) return;

    if (isActive && queries && _queries) {
      let __queries = _queries.filter((q) => q.name !== name);
      setQuery(__queries);
    } else {
      setQuery([
        ..._queries,
        {
          name: name,
          value: "1",
        },
      ]);
    }
  };

  return (
    <>
      <PermissionsGate section={"offers"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete offer?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this offer?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"offers"} scope={"read"}>
        {offerId ? (
          <ShowOffer
            _id={offerId}
            open={Boolean(offerId)}
            setDialogue={() => setOfferId(null)}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"offers"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
          actionIds={(ids) => {
            setSelectedIds(ids);
          }}
          actionToolbar={{
            status: () => {
              return (
                <Box
                  sx={{
                    marginRight: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box sx={{ width: 124 }}>
                    <Controls.Select
                      labelSx={{ top: "-5px" }}
                      sx={{
                        width: 124,
                        height: 40,
                        backgroundColor: "#fff",
                        border: "1px solid #E6E6E6",
                        borderRadius: 2,
                        m: () => ({}),
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "unset",
                        },
                      }}
                      name={"status"}
                      options={offer_statuses?.map((o_s) => {
                        return {
                          id: o_s._id.toString(),
                          title: o_s.title[intl.locale],
                        };
                      })}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      onChange={(event) => {
                        setStatus(event.target.value);
                      }}
                    />
                  </Box>
                  <Box sx={{ width: 124 }}>
                    <Button
                      autoFocus
                      color="inherit"
                      type={"button"}
                      sx={{
                        width: 124,
                        backgroundColor: "#0D99FF",
                        borderRadius: 2,
                        color: "#ffffff",
                        height: 40,
                      }}
                      onClick={() => {
                        handleStatus();
                      }}
                    >
                      <FormattedMessage
                        id={"STANDARD.EXECUTE"}
                        defaultMessage={"Execute"}
                      />
                    </Button>
                  </Box>
                </Box>
              );
            },
          }}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "offers", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <OfferStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            quickCards: [
              {
                title: intl.formatMessage({ id: "QUICK_CARD_ACCEPTED" }),
                onClick: (isActive: boolean) =>
                  resetCardQuery(isActive, "accepted"),
                icon: "fi-sr-check",
                value: quickCardsCount ? quickCardsCount.accepted : 0,
              },
              {
                title: intl.formatMessage({ id: "QUICK_CARD_WAITING" }),
                onClick: (isActive: boolean) =>
                  resetCardQuery(isActive, "waiting"),
                icon: "fi-sr-hourglass-end",
                value: quickCardsCount ? quickCardsCount.waiting : 0,
              },
              {
                title: intl.formatMessage({ id: "QUICK_CARD_CANCELLED" }),
                onClick: (isActive: boolean) =>
                  resetCardQuery(isActive, "cancelled"),
                icon: "fi-sr-cross-circle",
                value: quickCardsCount ? quickCardsCount.cancelled : 0,
              },
            ],
            filters: (props: { show: boolean }) => {
              return (
                <OfferFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
