import React, { Dispatch, SetStateAction, useRef } from "react";
import { useIntl } from "react-intl";
import { IUrlQuery } from "../../interfaces";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import Controls from "../../widgets/uncontrolled";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const VacancyFilter = (props: {
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
  queries: Array<IUrlQuery> | undefined;
  setQuery: Dispatch<SetStateAction<IUrlQuery[] | undefined>>;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { roles } = useSelector((state: RootState) => state.permission);
  const { vacancy_cities } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { vacancy_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  const checkQuery = (checked: boolean, name: string) => {
    if (checked) {
      handleQuery(name, "1");
    } else {
      let _queries = props.queries?.filter((q) => q.name !== name);

      props.setQuery(_queries);
    }
  };

  const timeOut = useRef<any>();

  const sendInputQuery = (name: string, value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      handleQuery(name, value);
    }, 500);
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"gender"}
            formId={"gender"}
            options={[
              {
                id: "male",
                title: intl.formatMessage({ id: "USERS.MALE" }),
              },
              {
                id: "female",
                title: intl.formatMessage({ id: "USERS.FEMALE" }),
              },
            ]}
            label={intl.formatMessage({ id: "USERS.GENDER" })}
            onChange={(event) => {
              handleQuery("gender", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"city"}
            formId={"city"}
            options={vacancy_cities?.map((c) => {
              return {
                id: c._id.toString(),
                title: c.title[intl.locale] ?? "Undefined",
              };
            })}
            label={intl.formatMessage({ id: "USERS.CITY" })}
            onChange={(event) => {
              handleQuery("city", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.MultipleSelect
            name={"languages"}
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            formId={"languages"}
            options={vacancy_languages?.map((language) => {
              return {
                id: language._id,
                title: language.title[intl.locale] ?? "Undefined",
              };
            })}
            label={intl.formatMessage({ id: "USERS.LANGUAGES" })}
            onChange={(event) => {
              handleQuery("_languages", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Input
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"email"}
            label={intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" })}
            onChange={(event) => {
              sendInputQuery("email", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Input
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"phone"}
            label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
            onChange={(event) => {
              sendInputQuery("phone", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Input
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"work_position"}
            label={intl.formatMessage({ id: "VACANCY.WORKPOSITION" })}
            onChange={(event) => {
              sendInputQuery("work_position", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Input
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"full_name"}
            label={intl.formatMessage({ id: "VACANCY.FULLNAME" })}
            onChange={(event) => {
              sendInputQuery("full_name", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Input
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"post_number"}
            label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
            onChange={(event) => {
              sendInputQuery("post_number", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Input
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"work_experience"}
            label={intl.formatMessage({ id: "VACANCY.WORKEXPERIENCE" })}
            onChange={(event) => {
              sendInputQuery("work_experience", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Input
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"address"}
            label={intl.formatMessage({ id: "STANDARD.ADDRESS" })}
            onChange={(event) => {
              sendInputQuery("address", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Grid container spacing={1}>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              <Controls.Input
                sx={{
                  height: 40,
                  backgroundColor: "#fff",
                  border: "1px solid #E6E6E6",
                  borderRadius: 2,
                  m: () => ({}),
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "unset",
                  },
                  "& .MuiFormLabel-root": {
                    top: "-5px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "9.5px 14px",
                  },
                }}
                name={"min_age"}
                label={intl.formatMessage({ id: "VACANCY.MIN_AGE" })}
                onChange={(event) => {
                  sendInputQuery("min_age", event.target.value);
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              <Controls.Input
                sx={{
                  height: 40,
                  backgroundColor: "#fff",
                  border: "1px solid #E6E6E6",
                  borderRadius: 2,
                  m: () => ({}),
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "unset",
                  },
                  "& .MuiFormLabel-root": {
                    top: "-5px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "9.5px 14px",
                  },
                }}
                name={"max_age"}
                label={intl.formatMessage({ id: "VACANCY.MAX_AGE" })}
                onChange={(event) => {
                  sendInputQuery("max_age", event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Controls.Input
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
              "& .MuiFormLabel-root": {
                top: "-5px",
              },
              "& .MuiInputBase-input": {
                padding: "9.5px 14px",
              },
            }}
            name={"education_1"}
            label={intl.formatMessage({ id: "VACANCY.EDUCATION" })}
            onChange={(event) => {
              sendInputQuery("education_1", event.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ marginBottom: "10px" }}
        >
          <Grid container spacing={1} sx={{ marginBottom: "10px" }}>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              <Controls.Input
                sx={{
                  height: 40,
                  backgroundColor: "#fff",
                  border: "1px solid #E6E6E6",
                  borderRadius: 2,
                  m: () => ({}),
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "unset",
                  },
                  "& .MuiFormLabel-root": {
                    top: "-5px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "9.5px 14px",
                  },
                }}
                name={"min_application_year"}
                label={intl.formatMessage({
                  id: "VACANCY.MIN_APPLICATION_YEAR",
                })}
                onChange={(event) => {
                  sendInputQuery("min_application_year", event.target.value);
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              <Controls.Input
                sx={{
                  height: 40,
                  backgroundColor: "#fff",
                  border: "1px solid #E6E6E6",
                  borderRadius: 2,
                  m: () => ({}),
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "unset",
                  },
                  "& .MuiFormLabel-root": {
                    top: "-5px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "9.5px 14px",
                  },
                }}
                name={"max_application_year"}
                label={intl.formatMessage({
                  id: "VACANCY.MAX_APPLICATION_YEAR",
                })}
                onChange={(event) => {
                  sendInputQuery("max_application_year", event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "adhd");
                  }}
                  id={"adhd"}
                />
              }
              label="ADHD"
              name={"adhd"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "autism");
                  }}
                  id={"autism"}
                />
              }
              label={intl.formatMessage({ id: "VACANCY.AUTISM" })}
              name={"autism"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "borderline");
                  }}
                  id={"borderline"}
                />
              }
              label={intl.formatMessage({ id: "VACANCY.BORDERLINE" })}
              name={"borderline"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "ptsd");
                  }}
                  id={"PTSD"}
                />
              }
              label="PTSD"
              name={"ptsd"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "ocd");
                  }}
                  id={"ocd"}
                />
              }
              label="OCD"
              name={"ocd"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "criminal");
                  }}
                  id={"criminal"}
                />
              }
              label={intl.formatMessage({ id: "VACANCY.CRIMINAL" })}
              name={"criminal"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "drug_and_alcohol_abuse");
                  }}
                  id={"drug_and_alcohol_abuse"}
                />
              }
              label={intl.formatMessage({ id: "VACANCY.DAAB" })}
              name={"drug_and_alcohol_abuse"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "physically_vulnerable");
                  }}
                  id={"physically_vulnerable"}
                />
              }
              label={intl.formatMessage({ id: "VACANCY.PHYSICALLYVULNERABLE" })}
              name={"physically_vulnerable"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "diabetes");
                  }}
                  id={"diabetes"}
                />
              }
              label={intl.formatMessage({ id: "VACANCY.RELATEDDISORDERS" })}
              name={"diabetes"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "eating_disorders");
                  }}
                  id={"eating_disorders"}
                />
              }
              label={intl.formatMessage({
                id: "VACANCY.EATINGDISORDERS",
              })}
              name={"eating_disorders"}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: "#fff",
                height: 40,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                color: "rgba(0, 0, 0, 0.6)",
                margin: "0 0 0 10px",
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    let checked = event.target.checked;

                    checkQuery(checked, "handicap");
                  }}
                  id={"handicap"}
                />
              }
              label="Handicap"
              name={"handicap"}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VacancyFilter;
