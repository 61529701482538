import React, {Dispatch, FC, SetStateAction} from 'react';
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton, Skeleton,
    Slide,
    Toolbar,
    Typography
} from "@mui/material";
import {useForm} from "../../../hooks/useForm";
import {TransitionProps} from "@mui/material/transitions";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Controls from "../../../widgets/uncontrolled";
import {IFormUsersValues} from "../../Cases/store/parts/users";
import Snackbar from "../../../widgets/Snackbar";
import {offerService} from "../../../services";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {FormattedMessage, useIntl} from "react-intl";


interface IFormProps {
    _id: string,
    open: boolean,
    setDialogue: Dispatch<SetStateAction<boolean>>,
    onDone: Function
}

type FormStateValues = {
    title: string,
    description: string,
    users: Array<string>
} & IFormUsersValues


const initValues = {
    title: "",
    description: "",
    users: [],
} as FormStateValues;

const OfferNoteStore: FC<IFormProps> = (props) => {
    const intl = useIntl();
    const form = useForm<FormStateValues>(initValues);
    const loaded = useSelector((state: RootState) => state.settingReducer && state.permission);

    const handleClose = () => {
        props.setDialogue(false);
    };

    const handleSave = () => {
        let validated = form.validate();

        if (!validated){
            Snackbar.error(intl.formatMessage({id: 'SNACKBAR.STOREERROR'}))
            return;
        }

        offerService.addNote(props._id, validated).then((data) => {
            if ("error" in data){
                throw new Error(data.error.message);
            }

            Snackbar.success(intl.formatMessage({id: 'SNACKBAR.ADDED'}))

            setTimeout(() => {
                props.setDialogue(false);
                props.onDone();
                form.clear()
            }, 1000)

        }).catch((e) => {
            Snackbar.error(e.message)
        });
    }


    return(
        <>
            {
                <Dialog
                    open={props.open}
                    fullWidth={true}
                    maxWidth={'md'}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseOutlinedIcon/>
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }}
                                        variant="h6"
                                        component="div">
                                <FormattedMessage id={'CASE.NOTE.ADD'}
                                                  defaultMessage={'CASE.NOTE.ADD'}
                                />
                            </Typography>
                            <Button autoFocus
                                    color="inherit"
                                    type={'submit'}
                                    form={'offer_note_form'}>
                                <FormattedMessage id={'DIALOGUE.SAVE'}
                                                  defaultMessage={'DIALOGUE.SAVE'}
                                />
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form id={'offer_note_form'}
                              onSubmit={(e) => form.handleSubmit(e, handleSave)}>
                            <Grid container
                                  spacing={2}
                                  mb={2}>
                                <Grid item
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      xl={12}>
                                    {
                                        loaded ?
                                          <Controls.Input name={'title'}
                                                          label={intl.formatMessage({id: 'STANDARD.TITLE'})}
                                                          onChange={(event) => {

                                                              if(!event.target.value) return;

                                                              form.values.title = event.target.value;
                                                          }}
                                          />
                                        :
                                          <Skeleton width={'100%'}>
                                              <Controls.Input name={'title'}
                                                              label={intl.formatMessage({id: 'STANDARD.TITLE'})}
                                                              onChange={() => {}}
                                              />
                                          </Skeleton>
                                    }
                                </Grid>
                                <Grid item
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      xl={12}>
                                    {
                                        loaded ?
                                          <Controls.Input multiline={true}
                                                          rows={5}
                                                          name={'description'}
                                                          label={intl.formatMessage({id: 'STANDARD.DESCRIPTION'})}
                                                          onChange={(event) => {

                                                              if(!event.target.value) return;

                                                              form.values.description = event.target.value;
                                                          }}
                                          />
                                        :
                                          <Skeleton width={'100%'}>
                                              <Controls.Input rows={5}
                                                              name={'description'}
                                                              label={intl.formatMessage({id: 'STANDARD.DESCRIPTION'})}
                                                              onChange={() => {}}
                                              />
                                          </Skeleton>
                                    }
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default OfferNoteStore;
