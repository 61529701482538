import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { IUrlQuery } from "app/interfaces";
import { communeService, ICommune } from "app/services/comunne.service";
import Snackbar from "app/widgets/Snackbar";
import { Box, Grid } from "@mui/material";
import Controls from "app/widgets/uncontrolled";

const UserCaseFilter = (props: {
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { focus_areas } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { focus_types } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const [communes, setCommunes] = useState<Array<ICommune>>();
  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  useEffect(() => {
    communeService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: communes } = data;
        setCommunes(communes);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"focus_area"}
            options={focus_areas?.map((focus_area) => {
              return {
                id: focus_area._id.toString(),
                title: focus_area.title[intl.locale],
              };
            })}
            label={intl.formatMessage({ id: "CASE.FOCUS_AREA" })}
            onChange={(event) => {
              handleQuery("focus_area", event.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"focus_type"}
            options={focus_types?.map((focus_type) => {
              return {
                id: focus_type._id.toString(),
                title: focus_type.title[intl.locale],
              };
            })}
            label={intl.formatMessage({ id: "CASE.FOCUS_TYPE" })}
            onChange={(event) => {
              if (!event.target.value) return;

              handleQuery("focus_type", event.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"commune"}
            options={
              communes &&
              communes.map((commune) => {
                return {
                  id: commune._id,
                  title: `${commune.name}`,
                };
              })
            }
            label={intl.formatMessage({
              id: "CASE.INFORMATION.COMMUNE.COMMUNE",
            })}
            onChange={(event) => {
              if (!event.target.value) return;

              handleQuery("commune", event.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserCaseFilter;
