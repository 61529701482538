import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { caseService, documentService } from "app/services";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { IUser } from "../../../interfaces";
import { IFormUsersValues } from "../store/parts/users";
import { INote } from "../../../services/note.service";
import { FormattedMessage, useIntl } from "react-intl";
import { PermissionsGate } from "../../../permission/PermissionsGate";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { stringAvatar } from "../../../widgets/AvatarBackground";

interface IFormProps {
  _case: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  description: string;
  users: Array<IUser>;
  documents?: Array<File>;
} & IFormUsersValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const ShowCaseNote = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const classes = useStyles();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [note, setNote] = useState<INote>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (loading) return;
    caseService
      .getOneNote(props._case, props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: note } = data;
        setNote(note);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._case, props._id, loading]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const deleteDocument = (documentId: string) => {
    setLoading(true);
    documentService
      .delete(documentId)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
      })
      .catch((e) => {
        Snackbar.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .updateNote(props._case, props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  useEffect(() => {
    if (note) {
      form.values.title = note.title;
      form.values.description = note.description;
    }
  }, [note]);

  return (
    <>
      <PermissionsGate section={"notes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete document?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              deleteDocument(confirmId);
            }}
          >
            Are you sure you want to delete this document?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
            p: "0px 20px",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"CASE.NOTE.SHOW"}
                defaultMessage={"CASE.NOTE.SHOW"}
              />
            </Typography>
            <Button
              autoFocus
              data-cy-class={"note_form"}
              data-cy-case-id={props._case}
              data-cy-finance-id={props._id}
              color="inherit"
              type={"submit"}
              form={"note_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id={"note_form"}
            onSubmit={(e) => form.handleSubmit(e, handleSave)}
          >
            <Grid container spacing={2} mb={2}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded && note ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.TITLE"}
                        defaultMessage={"STANDARD.TITLE"}
                      />
                    </Typography>
                    <Controls.Input
                      name={"title"}
                      label={""}
                      defaultValue={note?.title}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.title = event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded && note ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.DESCRIPTION"}
                        defaultMessage={"STANDARD.DESCRIPTION"}
                      />
                    </Typography>
                    <Controls.Input
                      multiline={true}
                      rows={5}
                      name={"description"}
                      label={""}
                      defaultValue={note?.description}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.description = event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      rows={5}
                      name={"description"}
                      label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              {note?.activities && (
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Box className=" kt-timeline-v2-forcase">
                    <Box className="kt-timeline-v2__items-forcase">
                      {note?.activities.map((activity) => {
                        const description = intl.formatMessage({
                          id: activity.title,
                        });
                        const descriptionArray = description.split("-");
                        const title = descriptionArray[0].replace(
                          "{who}",
                          activity.replacement.title
                        );
                        return (
                          <Box className="kt-timeline-v2__item">
                            <Box className="kt-timeline-v2__item-cricle">
                              <Avatar
                                variant="circular"
                                sx={{ width: 24, height: 24 }}
                                src={activity.replacement.avatar}
                                alt={title}
                              />
                            </Box>
                            <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                              <Typography variant={"body1"}>
                                <b style={{ color: "#556EE6" }}>
                                  <a
                                    onClick={() => {
                                      history.push(
                                        `/users/${activity.replacement.id}`
                                      );
                                    }}
                                  >
                                    {title}
                                  </a>
                                </b>
                                {" - "} {descriptionArray[1]}
                              </Typography>
                              <Typography>
                                {moment
                                  .unix(activity.created_at)
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowCaseNote;
