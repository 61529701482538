import React, { useEffect, useState } from "react";
import { userService } from "../../services";
import Snackbar from "../../widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Controls from "../../widgets/uncontrolled";
import { SxProps } from "@mui/system";
import { Skeleton, Theme } from "@mui/material";
import { IUrlQuery } from "../../interfaces";
import { useIntl } from "react-intl";

type Props = {
  _id?: string;
  sx?: SxProps<Theme> | undefined;
  labelSx?: SxProps<Theme> | undefined;
  label?: string;
  formId?: string;
  name: string;
  onChange: Function;
  query: Array<IUrlQuery>;
};

const UsersGroupedSelect = (props: Props) => {
  const intl = useIntl();
  const { roles } = useSelector((state: RootState) => state.permission);
  const [users, setUsers] = useState<
    Array<{
      label: string;
      values: Array<{
        id: string;
        title: string;
      }>;
    }>
  >();

  useEffect(() => {
    userService
      .getAll(props.query)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;
        let modifiedUsers: Array<{
          label: string;
          values: Array<{
            id: string;
            title: string;
          }>;
        }> = [];
        roles.map((role) => {
          let _users: Array<{ id: string; title: string }> = [];
          let role_users = users.filter((user) => role?._id === user.role?._id);

          role_users.map((role_user) => {
            _users?.push({ id: role_user?._id, title: role_user.fullname });
          });

          if (_users.length > 0)
            modifiedUsers?.push({
              label: role.title[intl.locale],
              values: [..._users],
            });
        });

        setUsers(modifiedUsers);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [roles]);

  return users ? (
    <Controls.GroupedSelect
      sx={props.sx}
      labelSx={props.labelSx}
      name={props.name}
      formId={props.formId}
      options={users?.map((user) => {
        return {
          label: user.label,
          values: user.values,
        };
      })}
      defaultValue={props._id}
      label={props.label}
      onChange={(event) => {
        if (!event.target.value) return;

        props.onChange(event.target.value);
      }}
    />
  ) : (
    <Skeleton width={"100%"}>
      <Controls.Select
        name={"lead"}
        options={[]}
        label={intl.formatMessage({ id: "CASE.TODO.LEAD" })}
        onChange={() => {}}
      />
    </Skeleton>
  );
};

export default UsersGroupedSelect;
