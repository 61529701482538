import React, { FC } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import ChartCard from "./ChartCard";
import NoData from "../partials/NoData";

interface IProps {
  onClick?: Function;
  titles: string[];
  series: number[];
  colors: string[];
}

const MonthlyStatisticsChart: FC<IProps> = (props) => {
  const date = new Date();
  const month = date.toLocaleString("default", { month: "long" });

  const onClick = () => {
    props.onClick && props.onClick();
  };

  const series = props.series;

  return (
    <ChartCard
      title={"Time - statistics"}
      ratio={"100%"}
      body={
        <Box
          sx={{
            height: "calc(100% + 60px - 31%)",
            width: "66%",
            paddingBottom: "4%",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          {series.length ? (
            <ReactApexChart
              options={{
                grid: {
                  padding: {
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: 0,
                  },
                },
                legend: {
                  show: false,
                },
                dataLabels: {
                  enabled: false,
                },
                tooltip: {
                  followCursor: true,
                  fillSeriesColor: false,
                  theme: "light",
                  y: {
                    formatter: function (value) {
                      return value + " hours";
                    },
                  },
                },
                labels: props.titles,
                colors: props.colors,
                stroke: {
                  width: 2,
                  lineCap: "round",
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: "75%",
                      labels: {
                        show: true,
                        value: {
                          show: true,
                          fontSize: "20px",
                          fontWeight: 600,
                        },
                        total: {
                          show: true,
                          label: month,
                          color: "#666!important",
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          formatter: function (w: any) {
                            return (
                              w.globals.seriesTotals.reduce(
                                (a: any, b: any) => {
                                  return a + b;
                                },
                                0
                              ) + "h"
                            );
                          },
                        },
                      },
                    },
                  },
                },
              }}
              series={series}
              type="donut"
              height={"100%"}
              width={"100%"}
            />
          ) : (
            <ReactApexChart
              options={{
                grid: {
                  padding: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                  },
                },
                legend: {
                  show: false,
                },
                dataLabels: {
                  enabled: false,
                },
                tooltip: {
                  followCursor: true,
                  fillSeriesColor: false,
                  theme: "light",
                  y: {
                    formatter: function (value) {
                      return value + " hours";
                    },
                  },
                },
                labels: ["Used", "Remain"],
                colors: ["F4F4F4", "#F4F4F4"],
                stroke: {
                  width: 2,
                  lineCap: "round",
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: "75%",
                      labels: {
                        show: true,
                        value: {
                          show: true,
                          fontSize: "20px",
                          fontWeight: 600,
                        },
                        total: {
                          show: true,
                          showAlways: true,
                          color: "#666!important",
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          formatter: function () {
                            return "No data";
                          },
                          label: "No data",
                        },
                      },
                    },
                  },
                },
              }}
              series={[0, 100]}
              type="donut"
              height={"100%"}
              width={"100%"}
            />
          )}
        </Box>
      }
    />
  );
};

export default MonthlyStatisticsChart;
