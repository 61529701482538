import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IRole,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

export interface IContractTemplate {
  _id: string;
  title: string;
  content: string;
  for: IRole;
  _created_at: string;
  _updated_at: string;
}

export interface IContract {
  _id: string;
  slug: string;
  subject: string;
  content: string;
  _created_at: string;
  _updated_at: string;
}

export interface IContractTemplateStore {
  title: string;
  content: string;
  for: string;
}

const CONTRACTS_INDEX = API_ROUTES.contracts;
const CONTRACTS_SHOW = API_ROUTES.contract;

const CONTRACTS = API_ROUTES._contracts;
const CONTRACTS_ONE = API_ROUTES._contract;
const CONTRACT_EXPORT = API_ROUTES._contract_export;

export interface IContractService {
  getContracts(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;
  getOneContract(
    folderId: string,
    _id: string
  ): Promise<IRestApiResource<IContract> | IRestApiError>;
  getSingleContract(
    _id: string
  ): Promise<IRestApiResource<IContract> | IRestApiError>;
  store(
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
  export(_id: string): Promise<IRestApiResource<any> | IRestApiError>;
}

const contractService: IContractService = {
  async getContracts(query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(CONTRACTS)}${queryString}`, "get");
  },

  async getOneContract(templateId: string, _id: string) {
    return http(
      `${getRoute(CONTRACTS_SHOW).replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async getSingleContract(_id: string) {
    return http(
      `${getRoute(CONTRACTS_ONE).replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async store(data: any, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(CONTRACTS)}${queryString}`, "post", formData);
  },

  async export(_id: string) {
    return http(
      `${getRoute(CONTRACT_EXPORT).replace(":_id", _id.toString())}`,
      "get"
    );
  },
};

export { contractService };
