import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SxProps, TextField, Theme } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { makeStyles } from "@material-ui/core";

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: "",
    },
    "&:hover $notchedOutline": {
      borderColor: "blue",
    },
    "&$focused $notchedOutline": {
      borderColor: "green",
    },
  },
  focused: {},
  notchedOutline: {},
}));

type ComponentProps = {
  label: string;
  name: string;
  value?: Date | null;
  sx?: SxProps<Theme> | undefined;
  variant?: string;
  onChange: (
    date: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
};

const DatePicker = (props: ComponentProps) => {
  const { label, value, name, onChange, sx, variant } = props;
  const outlinedInputClasses = useOutlinedInputStyles();
  const convertToDefEventPara = (name: string, value: any) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        inputFormat="dd/MM/yyyy"
        value={value ?? ""}
        renderInput={(params: any) => (
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)!important",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.6)!important",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.6)!important",
                },
              },
              "& .MuiInputLabel-root": {
                color: "rgba(0, 0, 0, 0.6)!important",
              },
              ...sx,
            }}
            {...params}
            name={name}
            defaultValue={new Date().toDateString()}
            variant={variant ?? "outlined"}
            fullWidth
          />
        )}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
