import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { IUrlQuery } from "app/interfaces";
import { Box, Grid } from "@mui/material";
import Controls from "app/widgets/uncontrolled";

const QuestionFilter = (props: {
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { question_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { goals } = useSelector((state: RootState) => state.settingReducer);

  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"focus_area"}
            options={question_categories?.map((question_category) => {
              return {
                id: question_category._id.toString(),
                title: question_category.title[intl.locale],
              };
            })}
            label={intl.formatMessage({ id: "SETTINGS.QUESTION.CATEGORIES" })}
            onChange={(event) => {
              handleQuery("question_category", event.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"goal"}
            options={goals?.map((goal) => {
              return {
                id: goal._id,
                title: goal.title[intl.locale],
              };
            })}
            label={intl.formatMessage({ id: "CASE.PLAN.GOAL" })}
            onChange={(event) => {
              handleQuery("goal", event.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuestionFilter;
