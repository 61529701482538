import React, { ChangeEventHandler, FC, useEffect } from "react";
import { Chip, Divider, Grid, Theme } from "@mui/material";
import { makeStyles } from "@material-ui/core";

type Props = {
  date: string;
};

const useStyles = makeStyles(() => ({
  border: {
    "&:before": {
      borderTop: "0.5px solid #E6E6E6",
    },
    "&:after": {
      borderTop: "0.5px solid #E6E6E6",
    },
  },
}));

const DateMessage: FC<Props> = ({ date }) => {
  const classes = useStyles();

  return (
    <Grid sx={{ height: "inherit", my: 2 }}>
      <Divider className={classes.border}>
        <Chip
          label={date}
          color={"primary"}
          sx={{ height: 28, fontWeight: "400" }}
        />
      </Divider>
    </Grid>
  );
};

export default DateMessage;
