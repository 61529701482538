import React, { FC } from "react";
import { Card, Typography } from "@mui/material";
import { Box } from "@mui/material";

type Props = {
  ratio: string;
};

const RatioCard: FC<Props> = ({ children, ratio }) => {
  return (
    <Card
      sx={{
        border: "1px solid #E6E6E6",
        borderRadius: 2,
        width: "100%",
        paddingTop: ratio,
        position: "relative",
      }}
    >
      <Box
        sx={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Box>
    </Card>
  );
};

export default RatioCard;
