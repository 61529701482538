import React, { ChangeEvent, ReactNode } from "react";
import { Switch as MuiSwitch, FormControlLabel, FormGroup, FormControl } from "@material-ui/core";


type ComponentProps = {
  label: string,
  value: string,
}

export const Switch = (props : ComponentProps) => {


  const { label, value, ...other } = props

  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel control={<MuiSwitch value={value} />} label={props.label} />
      </FormGroup>
    </FormControl>
  )
}

export default Switch;
