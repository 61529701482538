import React, { Component } from "react";
import * as auth from "../../store/ducks/auth.duck";
import * as socket from "../../store/ducks/socket.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
    if (this.props.socket?.disconnect) this.props.socket.disconnect();
  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

const mapStateToProps = ({ i18n, socket, auth }) => {
  return {
    me: auth.user,
    hasAuthToken: Boolean(auth.authToken),
    socket: socket.socket,
  };
};

export default connect(mapStateToProps, {
  ...auth.actions,
  ...socket.actions,
})(Logout);
