import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, select, takeLatest } from "redux-saga/effects";
import { uuid } from "../../utils/standart";
import moment from "moment";
import { chatService } from "../../services/chat.service";
import Snackbar from "../../widgets/Snackbar";
import { IMessage } from "../../pages/Chat/messenger/messenger";
import { IAction, IUser } from "../../interfaces";
import { IRoom } from "../../pages/Chat/rooms/rooms";
import { RootState } from "../store";
import { Socket } from "socket.io-client";
import { useSelector } from "react-redux";

export interface IChat {
  _id: string;
  last_message: IMessage | null;
  users: Array<IUser>;
  image: string;
  _created_at: string;
  _updated_at: string;
}

export interface IChatState {
  rooms: Array<IRoom>;
  lastData: Array<IRoom>;
  roomsLimit: number;
  roomsSkip: number;
  archived?: boolean;
  roomsLoading: boolean;
  type: string;
  search: string;
  roomHotReload: boolean;
  active: string | null; // id
  messages: Array<IMessage>;
}

export const actionTypes = {
  GetRooms: "Get rooms",
  UpdateRooms: "Update rooms",
  SetRooms: "Set rooms",
  GetRooms_PENDING: "Get rooms pending",
  GetRooms_SUCCESS: "Get rooms done",
  GetRooms_ERROR: "Get rooms failed",

  TriggerHotReload: "Trigger Rooms Hot Reload",
  HotReload:
    "Rooms Hot Reload (difference between trigger and hot reload is for run it inside component)",
  HotReload_SUCCESS:
    "Rooms Hot Reload API Success (difference between trigger and hot reload is for run it inside component)",

  SetSearch: "Set search",

  GetMessages: "Get messages",
  SetMessages: "Set messages",
  GetMessages_PENDING: "Get messages pending",
  GetMessages_SUCCESS: "Get messages done",
  GetMessages_ERROR: "Get messages failed",

  SendMessage: "Send message",
  SendMessage_PENDING: "Send message pending",
  SendMessage_SUCCESS: "Send message done",
  SendMessage_ERROR: "Send message failed",

  //Socket
  EmitNewMessage: "Send new message from socket",
  EmitRefreshRooms: "Refresh rooms from socket",
  SocketNewMessage: "Received new message from socket",

  OpenQuickChatWith: "Open quick chat with correspondent",
  CloseQuickChat: "close quick chat",

  DeleteMessage: "Delete message",
};

const initialState: IChatState = {
  rooms: [],
  lastData: [],
  roomsLimit: 15,
  roomsSkip: 0,
  archived: false,
  roomsLoading: false,
  active: "",
  type: "load",
  search: "",
  messages: [],
  roomHotReload: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: "chat",
    whitelist: [
      "rooms",
      "lastData",
      "messages",
      "active",
      "roomsLimit",
      "roomsSkip",
      "archived",
      "roomsLoading",
      "type",
      "search",
    ],
  },
  (state: IChatState = initialState, action: IAction) => {
    switch (action.type) {
      case actionTypes.GetRooms_PENDING: {
        const { search } = action.payload;

        return { ...state, search, roomsLoading: true };
      }
      case actionTypes.SetSearch: {
        const { search } = action.payload;
        return { ...state, search };
      }
      case actionTypes.GetRooms_SUCCESS: {
        const {
          rooms,
          roomsSkip,
          roomsLimit,
          lastData,
          type,
          archived,
          search,
        } = action.payload;

        let newRooms: IRoom[] = [];
        if (type === "load") {
          newRooms = rooms;
        } else {
          newRooms = [...state.rooms, ...rooms];
        }

        return {
          ...state,
          rooms: newRooms,
          lastData,
          roomsLimit,
          roomsSkip,
          type,
          archived,
          roomsLoading: false,
          search,
        };
      }
      case actionTypes.HotReload_SUCCESS: {
        const { rooms, type } = action.payload;

        let newRooms: IRoom[] = rooms;

        return {
          ...state,
          rooms: newRooms,
        };
      }
      case actionTypes.TriggerHotReload: {
        return {
          ...state,
          roomHotReload: !state.roomHotReload,
        };
      }
      case actionTypes.SetRooms: {
        const { rooms, search } = action.payload;

        return { ...state, rooms, lastData: [], search, roomsSkip: 0 };
      }

      case actionTypes.UpdateRooms: {
        const { room, rooms, message } = action.payload;

        rooms.map((x: IRoom) => {
          if (x._id === room._id) {
            x.last_message = message;
          }
        });

        rooms.sort(
          (curr: IRoom, next: IRoom) =>
            (next?.last_message?._created_at ?? 0) -
            (curr?.last_message?._created_at ?? 0)
        );

        return {
          ...state,
          rooms,
          lastData: [],
          roomsSkip: 0,
          active: room._id,
        };
      }
      case actionTypes.GetRooms_ERROR: {
        return { ...state, users: [], roomsLoading: false };
      }
      case actionTypes.GetMessages_PENDING: {
        return { ...state, messages_loading: true };
      }
      case actionTypes.GetMessages_SUCCESS: {
        const messages: Array<IMessage> = action.payload;
        let difference = messages.filter(
          (m) => !state.messages.find((sm) => sm._id === m._id)
        );
        let final_messages = [...difference, ...state.messages];

        final_messages.sort(function (a, b) {
          if (
            moment.unix(a._created_at).unix() <
            moment.unix(b._created_at).unix()
          ) {
            return 1;
          }

          if (
            moment.unix(a._created_at).unix() >
            moment.unix(b._created_at).unix()
          ) {
            return -1;
          }

          return 0;
        });

        return { ...state, messages: final_messages, messages_loading: false };
      }
      case actionTypes.SetMessages: {
        const { messages } = action.payload;
        const { active } = action.payload;

        // let _messages: any = [];
        // if (active && state.active === active) _messages = messages;

        return { ...state, messages, active };
      }
      case actionTypes.GetMessages_ERROR: {
        return { ...state, messages: [], messages_loading: false };
      }
      case actionTypes.SendMessage_PENDING: {
        let { params: data, uuid } = action.payload;
        let { user } = data;

        let pending_message: IMessage = {
          _id: "",
          uuid: uuid,
          chat: data.room,
          message: data.message,
          pending: true,
          sent: 0,
          received: 0,
          created_by: user,
          _created_at: Math.floor(new Date().getTime() / 1000),
          _updated_at: Math.floor(new Date().getTime() / 1000),
        };

        let messages = [pending_message, ...state.messages];

        return { ...state, messages };
      }
      case actionTypes.SendMessage_SUCCESS: {
        let { message } = action.payload;
        let messages = state.messages.filter((m) => {
          if (!m.pending) return true;
          return m.uuid !== message.uuid;
        });
        // messages.unshift(message);

        return { ...state };
      }

      case actionTypes.SendMessage_ERROR: {
        return { ...state };
      }
      case actionTypes.SocketNewMessage: {
        let { message, chat } = action.payload;

        // sometimes socket connection got duplications and sends message twice.
        // TODO: figure it out why in socket side and delete it
        let messageAlreadyThere = false;

        let messages = state.messages.filter((m) => {
          if (m.uuid === message.uuid && !m.pending) messageAlreadyThere = true;

          if (!m.pending) return true;

          return m.uuid !== message.uuid;
        });

        if (!messageAlreadyThere && state.active === message.chat)
          messages.unshift(message);

        return { ...state, messages };
      }
      case actionTypes.OpenQuickChatWith: {
        let correspondent = action.payload.correspondent;
        return {
          ...state,
          quickChatCorrespondent: correspondent,
          quickChat: true,
        };
      }
      case actionTypes.CloseQuickChat: {
        return { ...state, quickChatCorrespondent: null, quickChat: false };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  triggerHotReload: () => ({
    type: actionTypes.TriggerHotReload,
    payload: true,
  }),
  roomsHotReload: () => ({
    type: actionTypes.HotReload,
  }),
  getRooms: ({
    type,
    limit,
    skip,
    archived,
    search,
  }: {
    limit?: number;
    skip?: number;
    type?: string;
    archived?: boolean;
    search?: string;
  }) => ({
    type: actionTypes.GetRooms,
    payload: { limit, skip, archived, type, search },
  }),
  setRooms: (rooms: Array<IRoom>) => ({
    type: actionTypes.SetRooms,
    payload: { rooms },
  }),
  updateRooms: (
    room: string,
    rooms: Array<IRoom>,
    message: IMessage | null
  ) => ({
    type: actionTypes.UpdateRooms,
    payload: { room, rooms, message },
  }),
  getMessages: (room: string) => ({
    type: actionTypes.GetMessages,
    payload: room,
  }),
  setSearch: (search: string) => ({
    type: actionTypes.SetSearch,
    payload: { search },
  }),
  send: (
    room: IRoom,
    message: string,
    files: Array<File> | undefined,
    voice: Blob | undefined,
    user: IUser,
    replyMessage: string | undefined,
    users: Array<IUser>
  ) => ({
    type: actionTypes.SendMessage,
    payload: { room, message, replyMessage, user, files, voice, users },
  }),
  socketNewMessage: (message: IMessage, chat: string) => ({
    type: actionTypes.SocketNewMessage,
    payload: { message, chat },
  }),
  deleteMessage: (room: string, users: IUser[]) => ({
    type: actionTypes.DeleteMessage,
    payload: { room, users },
  }),
  setMessages: (messages: Array<IMessage>, active: string | null) => ({
    type: actionTypes.SetMessages,
    payload: { messages, active },
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.HotReload,
    function* roomsHotReloadSaga(params: IAction) {
      const { roomsSkip, roomsLimit, search } = yield select(
        (state: RootState) => state.chat
      );

      console.log("roomsHotReload");

      try {
        let queries = [
          {
            name: "skip",
            value: roomsSkip,
          },
          {
            name: "limit",
            value: roomsLimit,
          },
          {
            name: "search",
            value: search ?? "",
          },
        ];
        const { data: rooms } = yield chatService.getRooms(queries);
        yield put({
          type: actionTypes.HotReload_SUCCESS,
          payload: {
            rooms,
          },
        });
      } catch (error) {
        yield put({ type: actionTypes.GetRooms_ERROR, error });
      }
    }
  );
  yield takeLatest(
    actionTypes.GetRooms,
    function* getRoomsSaga(params: IAction) {
      const { limit, skip, archived, type, silent } = params.payload;
      const { roomsSkip, roomsLimit, search } = yield select(
        (state: RootState) => state.chat
      );

      try {
        let queries = [
          {
            name: "skip",
            value: (type === "load" ? 0 : +skip) !== 0 ? skip : roomsSkip,
          },
          {
            name: "limit",
            value: limit ?? roomsLimit,
          },
          {
            name: "archive",
            value: archived,
          },
          {
            name: "search",
            value: search ?? "",
          },
        ];
        const { data: rooms } = yield chatService.getRooms(queries);
        yield put({
          type: actionTypes.GetRooms_SUCCESS,
          payload: {
            rooms,
            lastData: rooms,
            roomsSkip: skip,
            roomsLimit: 15,
            type,
            archived,
            search,
          },
        });
      } catch (error) {
        yield put({ type: actionTypes.GetRooms_ERROR, error });
      }
    }
  );

  yield takeLatest(
    actionTypes.DeleteMessage,
    function* deleteMessageSaga(params: IAction) {
      // yield put({ type: actionTypes.GetRooms_PENDING });

      const { socket }: { socket: Socket | {} } = yield select(
        (state: RootState) => state.socket
      );

      const { users, room } = params.payload;

      try {
        if ("connected" in socket) {
          socket?.emit("delete", {
            users,
            room,
          });
        }
      } catch (error) {
        yield put({ type: actionTypes.SendMessage_ERROR, error });
      }
    }
  );

  yield takeLatest(
    actionTypes.GetMessages,
    function* getMessagesSaga(params: IAction) {
      yield put({ type: actionTypes.GetMessages_PENDING });
      const { active } = yield select((state: RootState) => state.chat);

      const roomId = params.payload.roomId ?? params.payload;

      try {
        const { data: messages } = yield chatService.getMessages(roomId, [
          { name: "skip", value: 0 },
          { name: "limit", value: 15 },
        ]);

        if (roomId && roomId === active) {
          yield put({
            type: actionTypes.SetMessages,
            payload: { messages, active: roomId },
          });
        }
      } catch (error) {
        yield put({ type: actionTypes.GetMessages_ERROR, error });
      }
    }
  );

  yield takeLatest(
    actionTypes.SendMessage,
    function* sendMessageSaga(params: IAction) {
      const { rooms } = yield select((state: RootState) => state.chat);
      const { user } = yield select((state: RootState) => state.auth);

      try {
        const { room, message, files, voice, users, replyMessage } =
          params.payload;

        const roomId = room._id;
        const _uuidKey = uuid();

        console.log("user === ", user);
        yield put({
          type: actionTypes.SendMessage_PENDING,
          payload: {
            params: { ...params.payload, user: user },
            uuid: _uuidKey,
          },
        });

        let sentMessage: any = yield onFunction(
          room,
          message,
          files,
          voice,
          users,
          replyMessage,
          rooms,
          _uuidKey
        )
          .then((data) => {
            if ("error" in data) {
              throw new Error(data.error.message);
            }

            return data;
          })
          .catch((e) => {
            Snackbar.error(e.message);
          });

        // yield put({
        //   type: actionTypes.SendMessage_SUCCESS,
        //   payload: { message: sentMessage },
        // });

        yield put({
          type: actionTypes.EmitNewMessage,
          payload: { users, message: sentMessage },
        });

        yield put({
          type: actionTypes.GetMessages,
          payload: { roomId },
        });

        yield put({
          type: actionTypes.UpdateRooms,
          payload: { room, rooms, message: sentMessage },
        });
      } catch (error) {
        yield put({ type: actionTypes.SendMessage_ERROR, error });
      }
    }
  );

  async function onFunction(
    room: IRoom,
    message: string | null,
    files: Array<File> | undefined,
    voice: Blob | undefined,
    users: Array<IUser>,
    replyMessage: string,
    rooms: Array<IRoom>,
    _uuid: string
  ) {
    const _uuidKey = uuid();

    let sentMessage: any;
    if (files && files.length) {
      sentMessage = await chatService
        .sendMessage(
          room._id,
          message ?? "",
          files[0],
          undefined,
          _uuid,
          replyMessage
        )
        .then((data) => {
          console.log("data", data);

          if ("error" in data) {
            throw new Error(data.error.message);
          }
          return data.data;
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });

      if (sentMessage) {
        setTimeout(async () => {
          files.shift();
          onFunction(
            room,
            message,
            files,
            voice,
            users,
            replyMessage,
            rooms,
            _uuidKey
          );
        }, 500);
      }
    } else if (message) {
      sentMessage = await chatService
        .sendMessage(
          room._id,
          message,
          undefined,
          undefined,
          _uuid,
          replyMessage
        )
        .then((data) => {
          console.log("data", data);
          if ("error" in data) {
            throw new Error(data.error.message);
          }
          return data.data;
        })
        .catch((e) => {
          throw new Error(e.message);
        });
    } else if (voice) {
      sentMessage = await chatService
        .sendMessage(room._id, "", undefined, voice, _uuid, replyMessage)
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }
          return data.data;
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }

    return sentMessage;
  }

  yield takeLatest(
    actionTypes.EmitNewMessage,
    function* emitMessageSaga(params: IAction) {
      const { socket }: { socket: Socket | {} } = yield select(
        (state: RootState) => state.socket
      );

      const { users, message } = params.payload;

      try {
        if ("connected" in socket) {
          socket?.emit("message", {
            users,
            message,
          });
        }
      } catch (error) {
        yield put({ type: actionTypes.SendMessage_ERROR, error });
      }
    }
  );

  yield takeLatest(
    actionTypes.EmitRefreshRooms,
    function* emitRefreshRoomsSaga(params: IAction) {
      const { socket }: { socket: Socket | {} } = yield select(
        (state: RootState) => state.socket
      );

      const { users, room, rooms, message } = params.payload;
      try {
        if ("connected" in socket) {
          socket?.emit("chats", {
            users,
            room,
            rooms,
            message,
          });
        }
      } catch (error) {
        yield put({ type: actionTypes.SendMessage_ERROR, error });
      }
    }
  );
}
