import React, { Dispatch, SetStateAction } from "react";
import { connect, useSelector } from "react-redux";
import * as _setting from "app/store/ducks/setting.duck";
import {
  Grid,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import Controls from "../../widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import Snackbar from "../../widgets/Snackbar";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

type FormStateValues = {
  status: string;
  textStyle: string;
  text: string;
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          top: 0,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const EditReport = (props: IFormProps) => {
  const form = useForm<FormStateValues>();
  const classes = useStyles();
  const intl = useIntl();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    fetch("https://dummyjson.com/products/1", {
      method: "PUT" /* or PATCH */,
      headers: {},
      body: JSON.stringify({
        title: "",
      }),
    })
      .then((res) => res.json())
      .then(console.log);

    Snackbar.success("Updated");
    setTimeout(() => {
      props.setDialogue(false);
    }, 1000);
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
            padding: "0px 12px",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"CASE.EDIT_REPORT"}
                defaultMessage={"CASE.EDIT_REPORT"}
              />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              type={"submit"}
              form={"report_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent sx={{ p: 4.5 }}>
          <form
            id={"report_form"}
            onSubmit={() => {
              handleSave();
            }}
          >
            <Grid container sx={{ mb: 3 }}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.STATUS"}
                        defaultMessage={"STANDARD.STATUS"}
                      />
                    </Typography>
                    <Box className={classes.input}>
                      <Controls.Select
                        name={"status"}
                        label={""}
                        onChange={(e) => {}}
                      />
                    </Box>
                  </>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Select
                      name={"status"}
                      options={[]}
                      onChange={(e) => {
                        form.values.status = e.target.value;
                      }}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
            <Grid container sx={{ mb: 3 }}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <Box
                    sx={{
                      border: "1px solid #E6E6E6!important",
                      borderRadius: 2,
                    }}
                  >
                    <Grid
                      container
                      sx={{ p: 1, backgroundColor: "#E6E6E6!important" }}
                    >
                      <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                        <Box
                          sx={{
                            "& .MuiFormControl-root": {
                              "& .MuiOutlinedInput-root": {
                                "& .MuiSelect-select": {
                                  p: "14.5px 14px!important",
                                },
                                borderRadius: 2,
                                "& fieldset": {
                                  border: "1px solid #E6E6E6!important",
                                  borderRadius: 2,
                                },
                              },
                            },
                          }}
                        >
                          <Controls.Select
                            name={"status"}
                            sx={{ backgroundColor: "#ffffff" }}
                            label={"Text style"}
                            onChange={(e) => {
                              form.values.textStyle = e.target.value;
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        "& .MuiFormControl-root": {
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none!important",
                              borderRadius: 0,
                            },
                          },
                        },
                      }}
                    >
                      <Controls.Input
                        name={"note"}
                        rows={9}
                        multiline={true}
                        label={""}
                        onChange={(e) => {
                          form.values.text = e.target.value;
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"textstyle"}
                      onChange={() => {}}
                      label={""}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({ i18n, settingReducer }: any) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditReport);
