import React, { FC, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as socketReducer from "../store/ducks/socket.duck";
import * as userReducer from "../store/ducks/user.duck";
import * as chatReduce from "../store/ducks/chat.duck";
import { ISecureUser, IUser } from "../interfaces";
import { Dispatch } from "redux";
import { IMessage } from "../pages/Chat/messenger/messenger";
import { Ii18nState } from "../store/ducks/i18n.duck";
import { ISocketState } from "../store/ducks/socket.duck";
import { IAuthState } from "../store/ducks/auth.duck";
import { Manager, Socket } from "socket.io-client";
import * as chat from "../store/ducks/chat.duck";
import { RootState } from "../store/store";
import { IRoom } from "../pages/Chat/rooms/rooms";

type SocketProviderType = {
  connect: (s: Socket) => {};
  disconnect: () => {};
  updateChats: (room: string, rooms: Array<IRoom>, sentMessage: IMessage) => {};
  roomsHotReload: () => {};
  addChat: (silent?: boolean) => {};
  updateMessages: (chat: string, silent?: boolean) => {};
  newMessage: (message: IMessage, chat: string) => {};
  deleteMessage: (room: string) => {};
  updateOnlineUsers: (users: Array<IUser>) => {};
  connection: boolean;
  me: IUser | undefined;
  auth: boolean;
};

const SocketProvider: FC<SocketProviderType> = (props) => {
  const socketRef = useRef();

  useEffect(() => {
    if (!props.me || socketRef.current) return;

    const manager = new Manager(process.env.REACT_APP_API_URL);
    let socket = manager.socket("/", {
      auth: {
        user: { ...props.me } as ISecureUser,
      },
    });

    socket.on("connect", () => {
      props.connect(socket);
    });

    socket.on("disconnect", () => {
      socket.removeAllListeners();
      props.disconnect();

      props.connect(socket);
    });

    socket.on(
      "chats",
      (data: { room: string; rooms: Array<IRoom>; sentMessage: IMessage }) => {
        let {
          room,
          rooms,
          sentMessage,
        }: { room: string; rooms: Array<IRoom>; sentMessage: IMessage } = data;
        console.log("chats");
        props.updateChats(room, rooms, sentMessage);
        // props.triggerRoomRefresh();
      }
    );

    socket.on("delete", (data: { chat: string }) => {
      let { chat }: { chat: string } = data;
      props.deleteMessage(chat);
    });

    socket.on("messages", (chat: any) => {
      props.updateMessages(chat, true);
    });

    socket.on("message", (data: { chat: string; message: IMessage }) => {
      console.log("message");

      let { chat, message }: { chat: string; message: IMessage } = data;
      props.newMessage(message, chat);
      props.roomsHotReload();
    });

    //users than online
    socket.on("onlineUsers", (onlineUsers: Array<IUser>) => {
      props.updateOnlineUsers(onlineUsers);
    });

    socket.onAny((event: string, ...args: Array<any>) => {
      console.log(
        `%c SOCKET INFO` + `%c ${event}`,
        "background: #222; color: #bada55",
        "color: green",
        args
      );
    });
  }, [props.me, props.connect]);

  return <div>{props.children}</div>;
};

const mapStateToProps = ({
  i18n,
  socket,
  auth,
}: {
  i18n: Ii18nState;
  socket: ISocketState;
  auth: IAuthState;
}) => {
  return {
    lang: i18n.lang,
    connection: socket.connection,
    me: auth.user,
    auth: Boolean(auth.authToken),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    connect: (socket: Socket) =>
      dispatch(socketReducer.actions.connect(socket)),
    disconnect: () => dispatch(socketReducer.actions.disconnect()),
    roomsHotReload: () => dispatch(chat.actions.roomsHotReload()),
    updateChats: (room: string, rooms: Array<IRoom>, sentMessage: IMessage) =>
      dispatch(chat.actions.updateRooms(room, rooms, sentMessage)),
    newMessage: (message: IMessage, chat: string) =>
      dispatch(chatReduce.actions.socketNewMessage(message, chat)),
    updateMessages: (chat: string) =>
      dispatch(chatReduce.actions.getMessages(chat)),
    deleteMessage: (room: string) =>
      dispatch(chatReduce.actions.getMessages(room)),
    updateOnlineUsers: (users: Array<ISecureUser>) =>
      dispatch(userReducer.actions.updateOnlineUsers(users)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocketProvider);
