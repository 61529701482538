import React, { useRef } from "react";
import { Box, Grid } from "@mui/material";
import Controls from "../../../../widgets/uncontrolled";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useIntl } from "react-intl";

const PlanFilter = (props: { show: boolean }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { goals } = useSelector((state: RootState) => state.settingReducer);
  const { plan_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const intl = useIntl();

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"title"}
            options={goals?.map((g) => {
              return {
                id: g._id.toString(),
                title: g.title ? g.title[intl.locale] : "",
              };
            })}
            label={intl.formatMessage({ id: "CASE.PLAN.GOAL" })}
            onChange={(event) => {
              if (!event.target.value) return;
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"status"}
            options={plan_statuses?.map((p_s) => {
              return {
                id: p_s._id.toString(),
                title: p_s.title ? p_s.title[intl.locale] : "",
              };
            })}
            label={intl.formatMessage({ id: "MENU.PLAN_STATUSES" })}
            onChange={(event) => {
              if (!event.target.value) return;
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlanFilter;
