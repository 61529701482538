import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { useIntl } from "react-intl";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import { PermissionsGate } from "../../permission/PermissionsGate";
import moment from "moment";
import { activitiesService } from "app/services/activities.service";
import reactStringReplace from "react-string-replace";
import Link from "@mui/material/Link";

const _Notifications = () => {
  const getReplaceDescriptions = (activity: any) => {
    let text = intl.formatMessage({ id: activity.lang_key });
    let replacements = activity.replacements;

    if (text) {
      replacements.forEach((replacement: any, index: number) => {
        // @ts-ignore
        text = reactStringReplace(text, `{${replacement.key}}`, () =>
          Title(replacement, index)
        );
      });
    }

    return text;
  };
  const Title = (replacement: any, index: number) => {
    if (replacement.is_link) {
      switch (replacement.type) {
        case "case":
          console.log("replacement.name", replacement.name);
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/cases/${replacement.id}`}
            >
              {replacement.name || "Case Name"}
            </Link>
          );
        case "plan":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/cases/${replacement.case}?section=plan&plan=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "transaction":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/cases/${replacement.case}?section=finance`}
            >
              {replacement.name}
            </Link>
          );
        case "contract":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/contracts?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "project":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/projects?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "task":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/tasks?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "todo":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/todos?id=${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "incoming_offer":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/incoming-offers`}
            >
              {replacement.name}
            </Link>
          );
        case "note":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/notes`}
            >
              {replacement.name}
            </Link>
          );
        case "who":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/users/${replacement.id}`}
            >
              {replacement.name}
            </Link>
          );
        case "column":
          return (
            <Link
              key={index}
              className="text-primary-alternative"
              href={`/kanban?project=${replacement.project}`}
            >
              {replacement.name}
            </Link>
          );
        default:
          return `Unexpected type: ${replacement.type}`;
      }
    } else {
      return replacement.name;
    }
  };

  const intl = useIntl();
  const { data, xhrLoading, updateQuery } = useTable<any>({
    fetch: activitiesService.getAll,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DESCRIPTION" }),
      field: "notification",
      minWidth: 400,
      renderCell: (params) => {
        return getReplaceDescriptions(params.row);
      },
    },
    {
      field: "_created_at",
      minWidth: 400,
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY HH:mm");
      },
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  return (
    <PermissionsGate section={"logs"} scope={"read"}>
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
      />
    </PermissionsGate>
  );
};
export default _Notifications;
