import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
  Box,
  ListItemAvatar,
  Avatar,
  Button,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import EditNotes, { FormStateValues } from "./EditNotes";
import { userService } from "../../services";
import Snackbar from "../../widgets/Snackbar";
import { INote } from "../../services/note.service";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ConfirmDialog from "../dialogues/confirmDialogue";
import { PermissionsGate } from "../../permission/PermissionsGate";
import UserContractNoteStore from "../../pages/Contracts/storeNote";

interface IFormProps {
  user: string;
  contract: string;
  open: boolean;
  onDone: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const UserContractNotes = (props: IFormProps) => {
  const [notes, setNotes] = useState<INote[]>();
  const intl = useIntl();
  const history = useHistory();
  const [editNote, setEditNote] = useState<INote | null>(null);
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const [noteStoreDialogue, setNoteStoreDialogue] = useState<boolean>(false);

  useEffect(() => {
    loadNotes();
  }, [props.open]);

  const loadNotes = () => {
    userService
      .getContractNotes(props.user, props.contract)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: notes } = data;
        setNotes(notes);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleSave = (validated: FormStateValues, callback: () => void) => {
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    editNote &&
      userService
        .updateContractNote(props.user, props.contract, editNote._id, validated)
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }

          setEditNote(null);
          callback();
          loadNotes();
          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
  };

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleDelete = (note: string) => {
    userService
      .deleteContractNote(props.user, props.contract, note)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        loadNotes();
        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.DELETED" }));
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      <PermissionsGate section={"notes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete Note?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              handleDelete(confirmId);
            }}
          >
            Are you sure you want to delete this note?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      {editNote ? (
        <EditNotes
          note={editNote}
          open={Boolean(editNote)}
          setDialogue={() => setEditNote(null)}
          save={handleSave}
        />
      ) : (
        <></>
      )}
      {noteStoreDialogue ? (
        <UserContractNoteStore
          user={props.user}
          contract={props.contract}
          open={noteStoreDialogue}
          setDialogue={() => setNoteStoreDialogue(false)}
          onDone={loadNotes}
        />
      ) : (
        <></>
      )}
      <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"PERMISSION.TITLE.NOTES"}
                defaultMessage={"PERMISSION.TITLE.NOTES"}
              />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setNoteStoreDialogue(true)}
              type={"button"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"CASE.NOTE.ADD"}
                defaultMessage={"CASE.NOTE.ADD"}
              />
            </Button>
          </Toolbar>
        </Box>
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          style={{ maxHeight: "430px", position: "relative" }}
        >
          <DialogContent>
            {notes?.length &&
              notes.map((note, index) => {
                let description = null;
                let title = null;
                let descriptionArray = null;

                if (note.activities) {
                  description = intl.formatMessage({
                    id: note.activities[0].title,
                  });
                  descriptionArray = description.split("-");
                  title = descriptionArray[0].replace(
                    "{who}",
                    note.activities[0]?.replacement.title
                  );
                }

                return (
                  <Box sx={{ display: "flex", mb: 1 }}>
                    <>
                      <Typography
                        sx={{ color: "#0D99FF", fontWeight: 500 }}
                        variant={"body1"}
                      >
                        {index + 1}.
                      </Typography>
                    </>
                    <Box sx={{ ml: 1, width: "100%" }}>
                      <Grid container>
                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                          <Typography
                            sx={{ color: "#0D99FF", fontWeight: 500 }}
                            variant={"body1"}
                          >
                            {note.title}
                          </Typography>
                          <Typography sx={{ mt: 1 }} variant={"body1"}>
                            {note.description}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box>
                            <IconButton
                              onClick={() => {
                                setConfirmId(note._id);
                                //delete note
                              }}
                              sx={{
                                padding: "0px 15px!important",
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.5 5.33333V3.66667C16.5 3.22464 16.3244 2.80072 16.0118 2.48816C15.6993 2.17559 15.2754 2 14.8333 2H9.83333C9.39131 2 8.96738 2.17559 8.65482 2.48816C8.34226 2.80072 8.16667 3.22464 8.16667 3.66667V5.33333H4V7H5.66667V19.5C5.66667 20.163 5.93006 20.7989 6.3989 21.2678C6.86774 21.7366 7.50363 22 8.16667 22H16.5C17.163 22 17.7989 21.7366 18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5V7H20.6667V5.33333H16.5ZM11.5 16.1667H9.83333V11.1667H11.5V16.1667ZM14.8333 16.1667H13.1667V11.1667H14.8333V16.1667ZM14.8333 5.33333H9.83333V3.66667H14.8333V5.33333Z"
                                  fill="#EC3033"
                                />
                              </svg>
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setEditNote(note);
                              }}
                              sx={{ padding: "0px 15px!important" }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M19.178 2C18.4295 2 17.7118 2.29732 17.1825 2.82654L8.59354 11.4155C8.47767 11.5314 8.39547 11.6766 8.35573 11.8356L7.45162 15.452C7.3746 15.7601 7.46487 16.086 7.68943 16.3106C7.91399 16.5351 8.23991 16.6254 8.54801 16.5484L12.1644 15.6443C12.3234 15.6045 12.4686 15.5223 12.5844 15.4064L21.1734 6.81745C21.7027 6.28823 22 5.57044 22 4.822C22 4.07356 21.7027 3.35577 21.1734 2.82654C20.6442 2.29732 19.9264 2 19.178 2ZM18.4611 4.10514C18.6513 3.91502 18.9091 3.80821 19.178 3.80821C19.4469 3.80821 19.7047 3.91502 19.8948 4.10514C20.085 4.29526 20.1918 4.55313 20.1918 4.822C20.1918 5.09087 20.085 5.34874 19.8948 5.53886L11.4829 13.9508L9.5713 14.4287L10.0492 12.5171L18.4611 4.10514Z"
                                  fill="#FBB73C"
                                />
                                <path
                                  d="M4.71231 3.91791C3.99296 3.91791 3.30308 4.20367 2.79442 4.71233C2.28576 5.22099 2 5.91087 2 6.63022V19.2877C2 20.007 2.28576 20.6969 2.79442 21.2056C3.30308 21.7142 3.99296 22 4.71231 22H17.3698C18.0891 22 18.779 21.7142 19.2877 21.2056C19.7963 20.6969 20.0821 20.007 20.0821 19.2877V12.959C20.0821 12.4596 19.6773 12.0549 19.178 12.0549C18.6787 12.0549 18.2739 12.4596 18.2739 12.959V19.2877C18.2739 19.5275 18.1786 19.7574 18.0091 19.927C17.8395 20.0965 17.6096 20.1918 17.3698 20.1918H4.71231C4.47253 20.1918 4.24257 20.0965 4.07301 19.927C3.90346 19.7574 3.80821 19.5275 3.80821 19.2877V6.63022C3.80821 6.39044 3.90346 6.16048 4.07301 5.99093C4.24257 5.82137 4.47253 5.72612 4.71231 5.72612H11.041C11.5404 5.72612 11.9451 5.32134 11.9451 4.82202C11.9451 4.32269 11.5404 3.91791 11.041 3.91791H4.71231Z"
                                  fill="#FBB73C"
                                />
                              </svg>
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <List>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ padding: "0px!important" }}
                        >
                          <ListItemAvatar sx={{ minWidth: "32px!important" }}>
                            <Avatar
                              sx={{
                                width: 24,
                                height: 24,
                              }}
                              alt="John Smith"
                              src={
                                note.activities
                                  ? note.activities[0]?.replacement.avatar
                                  : ""
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography variant={"body1"}>
                                  <b style={{ color: "#0D99FF" }}>
                                    <a
                                      onClick={() => {
                                        note.activities &&
                                          history.push(
                                            `/users/${note.activities[0].replacement.id}`
                                          );
                                      }}
                                    >
                                      {title}
                                    </a>
                                  </b>
                                  {" - "}{" "}
                                  {descriptionArray && descriptionArray[1]}
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography>
                                  {note.activities
                                    ? moment
                                        .unix(note.activities[0].created_at)
                                        .local()
                                        .format("DD-MM-YYYY HH:mm")
                                    : "Undefined"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                );
              })}
          </DialogContent>
        </PerfectScrollbar>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default UserContractNotes;
