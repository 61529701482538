import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { IProject } from "./project.service";

export interface IColumn {
  _id: string;
  title: string;
  position: number;
  cards_count: number;
  _created_at: string;
  _updated_at: string;
}

export interface IColumnStore {
  title: string;
  project_id: string;
}

const INDEX = API_ROUTES.columns;
const SHOW = API_ROUTES.column;
const LIST = API_ROUTES.column_list;
const LEAVE = API_ROUTES.task_leave;
const COMPLETE = API_ROUTES.task_complete;
const START = API_ROUTES.task_start;
const MOVE = API_ROUTES.card_move;
const MOVE_COLUMN = API_ROUTES.column_move;
const UPDATE = API_ROUTES.column;
const DELETE = API_ROUTES.column;

export interface IColumnService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IColumn> | IRestApiError>;
  list(queries?: Array<IUrlQuery>): Promise<
    | IRestApiResource<{
        tasks: { [key in string]: { id: string; content: string } }[];
        columns: {
          [key in string]: {
            id: string;
            content: string;
            taskIds: Array<string>;
          };
        }[];
        columnOrder: string[];
      }>
    | IRestApiError
  >;
  add(data: IColumnStore): Promise<IRestApiResource<IColumn> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IColumn> | IRestApiError>;
  leave(_id: string): Promise<IRestApiResource<IColumn> | IRestApiError>;
  complete(_id: string): Promise<IRestApiResource<IColumn> | IRestApiError>;
  start(_id: string): Promise<IRestApiResource<IColumn> | IRestApiError>;
  move(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IColumn> | IRestApiError>;
  moveCard(data: any): Promise<IRestApiResource<any> | IRestApiError>;
  moveColumn(data: any): Promise<IRestApiResource<any> | IRestApiError>;
  update(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IColumn> | IRestApiError>;
  delete(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IColumn> | IRestApiError>;
}

const columnService: IColumnService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async list(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(LIST)}${queriesString}`, "get");
  },

  async add(data: IColumnStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(INDEX), "post", formData);
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async leave(_id: string) {
    return http(`${getRoute(LEAVE).replace(":_id", _id.toString())}`, "get");
  },

  async complete(_id: string) {
    return http(`${getRoute(COMPLETE).replace(":_id", _id.toString())}`, "get");
  },

  async start(_id: string) {
    return http(`${getRoute(START).replace(":_id", _id.toString())}`, "get");
  },

  async move(_id: string, data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(MOVE).replace(":_id", _id.toString())}`,
      "post",
      formData
    );
  },

  async moveCard(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(`${getRoute(MOVE)}`, "post", formData);
  },

  async moveColumn(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(`${getRoute(MOVE_COLUMN)}`, "post", formData);
  },
};

export { columnService };
