import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import QuickChat from "../partials/layout/QuickChat";
import { IUser } from "../interfaces";
import { IChat } from "../store/ducks/chat.duck";

interface IQuickChat {
  me: IUser;
  auth: boolean;
  chat: IChat;
  quickChat: boolean;
  quickChatCorrespondent: IUser;
}

const ChatProvider: FC<IQuickChat> = (props) => {
  return (
    <div>
      <QuickChat
        correspondent={props.quickChatCorrespondent}
        onClose={() => {}}
        open={props.quickChat}
        room={1}
      />
      {props.children}
    </div>
  );
};

const mapStateToProps = ({ i18n, socket, auth, chat }: any) => {
  return {
    lang: i18n.lang,
    session: socket.session,
    connection: socket.connection,
    me: auth.user,
    auth: Boolean(auth.authToken),
    chat: chat,
    quickChat: chat.quickChat,
    quickChatCorrespondent: chat.quickChatCorrespondent,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatProvider);
