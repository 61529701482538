import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { connect } from "react-redux";
import { Avatar, IconButton, Stack } from "@mui/material";
import { contractTemplateService } from "app/services";
import moment from "moment";
import { useParams } from "react-router";
import { useTable } from "../../hooks/subresource/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import ContractShow from "./show";
import { IContract } from "../../services/contractTemplate.service";
import { useIntl } from "react-intl";
import { PermissionsGate } from "../../permission/PermissionsGate";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "../../store/ducks/setting.duck";
import ContractFilter from "./filter";
import EditIcon from "../../partials/icons/EditIcon";
import { IUser } from "../../interfaces";
import { useHistory } from "react-router-dom";
import { stringAvatar } from "../../widgets/AvatarBackground";
import UserAvatar from "../../partials/layout/UserAvatar";

const Contracts = () => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { data, xhrLoading, updateQuery, setQuery, queries } =
    useTable<IContract>({
      fetch: contractTemplateService.getContracts,
      param: id,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "CASE.INFORMATION.CASE" }),
      field: "case",
      valueGetter: (params) => {
        return params.row?.case?.key;
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.SUBJECT" }),
      field: "subject",
      renderCell: (params) => {
        return params.row?.subject;
      },
      minWidth: 300,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 150,
    },
    {
      field: "users",
      headerName: intl.formatMessage({ id: "STANDARD.USERS" }),
      renderCell: (params) => {
        return params.row?.case?.users?.map((user: IUser) => {
          return <UserAvatar user={user} />;
        });
      },
      minWidth: 200,
    },
    {
      field: "Status",
      headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row align-items-center">
            <span style={{ color: params.row?.status?.color }}>
              {params.row?.status?.title &&
                params.row?.status?.title[intl.locale]}
            </span>
          </div>
        );
      },
      minWidth: 100,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"contracts"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"contract_show"}
                data-cy-template-id={id}
                data-cy-contract-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [contractId, setContractId] = useState<string | null>(null);

  const show = (_id: string) => {
    setContractId(_id);
  };

  return (
    <>
      <PermissionsGate section={"contracts"} scope={"read"}>
        {contractId ? (
          <ContractShow
            templateId={id}
            _id={contractId}
            open={Boolean(contractId)}
            setDialogue={() => setContractId(null)}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"contracts"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
          filterToolbar={{
            filters: (props: { show: boolean }) => {
              return (
                <ContractFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
