import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import GroupedSelect from "./GroupedSelect";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import Switch from "./Switch";
import MultipleSelect from "./MultipleSelect";
import Editor from "./Editor";
import Summernote from "./Summernote";
import DateWithTimePicker from "./DateTimePicker";
import MyDropzone from "./Dropzone";
import TinyEditor from "./TinyEditor";

const Controls = {
  Input,
  RadioGroup,
  Select,
  GroupedSelect,
  MultipleSelect,
  Checkbox,
  DatePicker,
  DateWithTimePicker,
  Button,
  Switch,
  Summernote,
  TinyEditor,
  Editor,
  MyDropzone,
};

export default Controls;
