import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "../../../partials/layout/DataTable";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import { userService } from "../../../services";
import moment from "moment";
import { useTable } from "../../../hooks/subresource/useTable";
import { GridTable } from "../../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { IUserPenalty } from "../../../services/user.service";
import { IUser } from "../../../interfaces";
import ShowUserPenalty from "./showPenalty";
import UserPenaltyStore from "../store/penaltyStore";
import { Ii18nState } from "../../../store/ducks/i18n.duck";
import { ISettingsState } from "../../../store/ducks/setting.duck";
import { hasPermission } from "../../../permission/PermissionsGate";
import { usePermissions } from "../../../hooks/useRole";
import UserPenaltyFilter from "../filters/penaltyFilter";
import EditIcon from "../../../partials/icons/EditIcon";
import UserPenaltyDocumentShow from "./penaltyDocumentShow";
import Snackbar from "../../../widgets/Snackbar";

const UserPenalties: FC<IUser> = (props) => {
  const intl = useIntl();
  const permissions = usePermissions();
  const { id } = useParams<{ id: string }>();
  const [penaltyDocs, setPenaltyDocs] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();

  const getUser = () => {
    userService
      .getOne(id)
      .then((response) => {
        if ("error" in response) {
          throw new Error(response.error.message);
        }

        let { data: _user } = response;
        setUser(_user);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };
  useEffect(() => {
    getUser();
  }, [props._id]);
  const { data, xhrLoading, refresh, updateQuery, queries, setQuery } =
    useTable<IUserPenalty>({
      fetch: userService.getPenalties,
      param: id,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.USER" }),
      field: "title",
      renderCell: (params) => {
        return (
          params.row?.created_by?.firstname +
          " " +
          params.row?.created_by?.lastname
        );
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 160,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => show(params.row?._id)}>
              <EditIcon />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [penaltyId, setPenaltyId] = useState<string | null>(null);

  const show = (_id: string) => {
    setPenaltyId(_id);
  };

  return (
    <>
      {penaltyId ? (
        <ShowUserPenalty
          user={id}
          _id={penaltyId}
          open={Boolean(penaltyId)}
          setDialogue={() => setPenaltyId(null)}
          onDone={refresh}
        />
      ) : (
        <></>
      )}
      {penaltyDocs && user ? (
        <UserPenaltyDocumentShow
          user={id}
          open={Boolean(penaltyDocs)}
          setDialogue={() => setPenaltyDocs(false)}
          onDone={getUser}
          userModel={user}
        />
      ) : (
        <></>
      )}
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
        search={(queries) => updateQuery(queries)}
        filterToolbar={{
          actionPanel:
            permissions && hasPermission(permissions, "users", "read")
              ? (props: { open: boolean; close: () => void }) => {
                  return (
                    <UserPenaltyStore
                      _id={id}
                      open={props.open}
                      setDialogue={props.close}
                      onDone={refresh}
                    />
                  );
                }
              : undefined,
          filters: (props: { show: boolean }) => {
            return (
              <UserPenaltyFilter
                show={props.show}
                queries={queries}
                setQuery={setQuery}
                updateQuery={updateQuery}
              />
            );
          },
          penaltyDocuments: () => setPenaltyDocs(true),
        }}
      />
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPenalties);
