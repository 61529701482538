import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "../../../../widgets/uncontrolled";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useForm } from "../../../../hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { TransitionProps } from "@mui/material/transitions";
import { ISetting, settingService } from "../../../../services/setting.service";
import Snackbar from "../../../../widgets/Snackbar";
import { FormattedMessage, useIntl } from "react-intl";
import Flags from "../../../Flags";

interface IFormProps {
  _id: string;
  open: boolean;
  flags?: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  section: string;
  settingValue: string;
  save: Function;
}

export type FormStateValues = {
  title: { [key: string]: string } | null;
  short_code: string;
  flag: string;
  active: number;
};

const ShowSettingDialogue1: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [resource, setResource] = useState<ISetting>();

  const handleClose = () => {
    props.setDialogue(false);
  };

  const save = () => {
    props.save(form.validate(), () => {
      form.clear();
    });
  };

  const onFlagChange = (fileName: string) => {
    form.values.flag = fileName;
  };

  useEffect(() => {
    settingService
      .getOne(props._id, [
        {
          name: "type",
          value: props.settingValue,
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        let { data: resource } = data;
        setResource(resource);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id, props.settingValue]);

  useEffect(() => {
    if (resource) {
      form.values.title = resource.title;
      form.values.active = resource.active;
      form.values.short_code = resource.short_code;
      form.values.flag = resource.flag;
    }
  }, [resource]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"DIALOGUE.EDIT"}
                  defaultMessage={"DIALOGUE.EDIT"}
                />
              </Typography>
              <Button
                form={"setting_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"setting_form"}
              onSubmit={(e) => form.handleSubmit(e, save)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && resource ? (
                    <Controls.Input
                      name={"title_en"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      defaultValue={resource?.title["en"]}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        if (!form.values.title) {
                          form.values.title = {};
                        }

                        form.values.title["en"] = event.target.value;

                        event.preventDefault();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">EN</InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title_en"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && resource ? (
                    <Controls.Input
                      name={"title_da"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      defaultValue={resource?.title["da"]}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        if (!form.values.title) {
                          form.values.title = {};
                        }

                        form.values.title["da"] = event.target.value;
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">DA</InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title_da"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && resource ? (
                    <Controls.Select
                      name={"active"}
                      formId={"status"}
                      defaultValue={resource?.active}
                      options={[
                        {
                          id: "1",
                          title: intl.formatMessage({
                            id: "STANDARD.ACTIVE",
                          }),
                        },
                        {
                          id: "0",
                          title: intl.formatMessage({
                            id: "STANDARD.DEACTIVE",
                          }),
                        },
                      ]}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.active = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"active"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {props.flags && (
                  <>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      {loaded && resource ? (
                        <Controls.Input
                          name={"short_code"}
                          label={intl.formatMessage({
                            id: "STANDARD.SHORT_CODE",
                            defaultMessage: "Short code",
                          })}
                          defaultValue={resource?.short_code}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.short_code = event.target.value;
                            event.preventDefault();
                          }}
                        />
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            name={"title_da"}
                            label={"Title"}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      {loaded ? (
                        <Flags onChange={onFlagChange} flag={resource?.flag} />
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Select
                            name={"status"}
                            options={[]}
                            label={intl.formatMessage({
                              id: "STANDARD.STATUS",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowSettingDialogue1;
