import React, { Dispatch, FC, SetStateAction } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
  Chip,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { contractTemplateService } from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { IRole } from "../../../interfaces";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  content: string;
  for: string;
};

const initValues = {
  title: "",
  content: "",
  for: "",
} as FormStateValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const ContractTemplateStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const classes = useStyles();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { roles } = useSelector((state: RootState) => state.permission);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    contractTemplateService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          // fullScreen
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 3,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CONTRACTTEMPLATE.ADD"}
                  defaultMessage={"CONTRACTTEMPLATE.ADD"}
                />
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type={"submit"}
                form={"contract_template_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"contract_template_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Divider>
                    <Chip
                      label={
                        <Typography variant={"body1"} sx={{ fontWeight: 400 }}>
                          Title&For
                        </Typography>
                      }
                    />
                  </Divider>
                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"STANDARD.TITLE"}
                          defaultMessage={"STANDARD.TITLE"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"STANDARD.FOR"}
                          defaultMessage={"STANDARD.FOR"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"for"}
                        label={""}
                        options={roles.map((m: IRole) => {
                          return {
                            id: m._id.toString(),
                            title: m.title ? m.title[intl.locale] : "",
                          };
                        })}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.for = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"for"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  <Divider sx={{ mt: 2 }}>
                    <Chip
                      label={
                        <Typography variant={"body1"} sx={{ fontWeight: 400 }}>
                          <FormattedMessage
                            id={"CASE.INFORMATION.CITIZEN"}
                            defaultMessage={"CASE.INFORMATION.CITIZEN"}
                          />
                        </Typography>
                      }
                    />
                  </Divider>

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{citizen_name}"}
                      </Typography>
                      <Controls.Input
                        name={""}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"citizen_name"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{citizen_email}"}
                      </Typography>
                      <Controls.Input
                        name={"{citizen_email}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"citizen_email"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{citizen_phone}"}
                      </Typography>
                      <Controls.Input
                        name={"{citizen_phone}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"citizen_phone"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{citizen_city}"}
                      </Typography>
                      <Controls.Input
                        name={"{citizen_city}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"citizen_city"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{citizen_ssn}"}
                      </Typography>
                      <Controls.Input
                        name={"{citizen_ssn}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"citizen_ssn"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{citizen_place}"}
                      </Typography>
                      <Controls.Input
                        name={"{citizen_place}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"citizen_place"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Divider>
                    <Chip
                      label={
                        <Typography variant={"body1"} sx={{ fontWeight: 400 }}>
                          <FormattedMessage
                            id={"CASE.INFORMATION.EMPLOYEE"}
                            defaultMessage={"CASE.INFORMATION.EMPLOYEE"}
                          />
                        </Typography>
                      }
                    />
                  </Divider>
                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{employee_name}"}
                      </Typography>
                      <Controls.Input
                        name={"{employee_name}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{employee_name}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{employee_email}"}
                      </Typography>
                      <Controls.Input
                        name={"{employee_email}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{employee_email}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{employee_phone}"}
                      </Typography>
                      <Controls.Input
                        name={"{employee_phone}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{employee_phone}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{employee_city}"}
                      </Typography>
                      <Controls.Input
                        name={"{employee_city}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{employee_city}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{employee_ssn}"}
                      </Typography>
                      <Controls.Input
                        name={"{employee_ssn}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{employee_ssn}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{employee_address}"}
                      </Typography>
                      <Controls.Input
                        name={"{employee_address}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{employee_address}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{employee_zipcode}"}
                      </Typography>
                      <Controls.Input
                        name={"{employee_zipcode}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{employee_zipcode}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{employee_registration_number}"}
                      </Typography>
                      <Controls.Input
                        name={"{employee_registration_number}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{employee_registration_number}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{employee_account_number}"}
                      </Typography>
                      <Controls.Input
                        name={"{employee_account_number}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{employee_account_number}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Divider>
                    <Chip
                      label={
                        <Typography variant={"body1"} sx={{ fontWeight: 400 }}>
                          <FormattedMessage
                            id={"CASE.INFORMATION.CASE"}
                            defaultMessage={"CASE.INFORMATION.CASE"}
                          />
                        </Typography>
                      }
                    />
                  </Divider>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_title}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_title}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_title}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_report_type}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_report_type}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_report_type}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_focustype}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_focustype}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_focustype}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_focusarea}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_focusarea}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_focusarea}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_partner}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_partner}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_partner}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_partner_ean}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_partner_ean}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_partner_ean}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_social_worker}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_social_worker}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_social_worker}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_social_worker_email}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_social_worker_email}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_social_worker_email}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_evaluation_report}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_evaluation_report}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_evaluation_report}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_meeting_hours}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_meeting_hours}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_meeting_hours}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_activity_budget}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_activity_budget}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_activity_budget}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{case_start_date}"}
                      </Typography>
                      <Controls.Input
                        name={"{case_start_date}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{case_start_date}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Divider>
                    <Chip
                      label={
                        <Typography variant={"body1"} sx={{ fontWeight: 400 }}>
                          <FormattedMessage
                            id={"STANDARD.DATE"}
                            defaultMessage={"STANDARD.DATE"}
                          />
                        </Typography>
                      }
                    />
                  </Divider>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{start_date}"}
                      </Typography>
                      <Controls.Input
                        name={"{start_date}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{start_date}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{end_date}"}
                      </Typography>
                      <Controls.Input
                        name={"{end_date}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{end_date}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Divider>
                    <Chip
                      label={
                        <Typography variant={"body1"} sx={{ fontWeight: 400 }}>
                          <FormattedMessage
                            id={"USERS.SIGNATURE"}
                            defaultMessage={"USERS.SIGNATURE"}
                          />
                        </Typography>
                      }
                    />
                  </Divider>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{subject}"}
                      </Typography>
                      <Controls.Input
                        name={"{subject}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{subject}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{signatures_on_footer}"}
                      </Typography>
                      <Controls.Input
                        name={"{signatures_on_footer}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{signature_on_footer}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{moderator_sign}"}
                      </Typography>
                      <Controls.Input
                        name={"{moderator_sign}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{moderator_sign}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}

                  {loaded ? (
                    <Box sx={{ mt: 2 }} className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {"{recipient_sign}"}
                      </Typography>
                      <Controls.Input
                        name={"{recipient_sign}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"{recipient_sign}"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Editor
                      name={"content"}
                      label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                      onChange={(data: any) => {
                        form.values.content = data;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Editor
                        name={"content"}
                        label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ContractTemplateStore;
