import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Snackbar from "app/widgets/Snackbar";
import { todoService } from "app/services";
import { useIntl } from "react-intl";
import { ITodo } from "app/services/todo.service";
import TodoShowDialogue from "app/partials/dialogues/show/todo";

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
  _todo?: ITodo | null;
  save?: Function;
}

export type FormStateValues = {
  type: string;
  title: string | null;
  category: string;
  description: string;
  case: string;
  lead: string;
  users: Array<string>;
  date: string;
  start_time: string;
  deadline_date: string;
  end_time: number;
  status: string;
};

const ShowTodo: FC<FormProps> = (props) => {
  const intl = useIntl();

  const [todo, setTodo] = useState<ITodo | null>(null);

  useEffect(() => {
    todoService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: todo } = data;
        setTodo(todo);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleSave = (validated: FormStateValues, callback: () => void) => {
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    todoService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          callback();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };
  return (
    <TodoShowDialogue
      _id={props._id}
      _todo={todo}
      open={props.open}
      setDialogue={props.setDialogue}
      save={handleSave}
      case_needed={false}
      show_note={true}
    />
  );
};
export default ShowTodo;
