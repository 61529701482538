import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { IUrlQuery } from "app/interfaces";
import { Box, Grid, SelectChangeEvent } from "@mui/material";
import Controls from "app/widgets/uncontrolled";
import { ISetting } from "../../../services/setting.service";

const OfferTemplateFilter = (props: {
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const [focusTypeOptions, setFocusTypeOptions] = useState<Array<ISetting>>();
  const { focus_areas } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const { focus_types } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleFocusAreaSelection = (e: SelectChangeEvent<any>): void => {
    let f =
      focus_types?.filter((f_t: any) => f_t.focus_area === e.target.value) ??
      undefined;

    if (f) setFocusTypeOptions([...f]);
  };

  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"focus_area"}
            options={focus_areas?.map((focus_area) => {
              return {
                id: focus_area._id.toString(),
                title: focus_area.title[intl.locale],
              };
            })}
            label={intl.formatMessage({ id: "CASE.FOCUS_AREA" })}
            onChange={(event) => {
              handleFocusAreaSelection(event);
              handleQuery("focus_area", event.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"focus_type"}
            options={
              focusTypeOptions &&
              focusTypeOptions.map((focusTypeOption) => {
                return {
                  id: focusTypeOption._id,
                  title: focusTypeOption.title[intl.locale],
                };
              })
            }
            label={intl.formatMessage({ id: "CASE.FOCUS_TYPE" })}
            onChange={(event) => {
              handleQuery("focus_type", event.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OfferTemplateFilter;
