import React, { FC, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/utils/utils";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@material-ui/core";
import { Avatar, Grid, ListItemAvatar } from "@mui/material";

interface IFlagProps {
  onChange: Function;
  flag?: string;
}

const useStyle = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  container: {
    width: "100%",
    padding: "0px",
    display: "flex",
    flexFlow: "column wrap",
    height: 250, // set the height limit to your liking
  },
  item: {
    width: "auto",
  },
}));

const flags = [
  { fileName: "042-aruba.svg" },
  { fileName: "232-yemen.svg" },
  { fileName: "199-sudan.svg" },
  { fileName: "051-cayman-islands.svg" },
  { fileName: "097-san-marino.svg" },
  { fileName: "152-kyrgyzstan.svg" },
  { fileName: "212-moldova.svg" },
  { fileName: "192-philippines.svg" },
  { fileName: "229-turkmenistan.svg" },
  { fileName: "156-costa-rica.svg" },
  { fileName: "187-vanuatu.svg" },
  { fileName: "110-guinea.svg" },
  { fileName: "143-norway.svg" },
  { fileName: "230-singapore.svg" },
  { fileName: "195-france.svg" },
  { fileName: "105-cameroon.svg" },
  { fileName: "091-slovakia.svg" },
  { fileName: "181-trinidad-and-tobago.svg" },
  { fileName: "180-french-polynesia.svg" },
  { fileName: "056-guinea-bissau.svg" },
  { fileName: "030-north-korea.svg" },
  { fileName: "023-cocos-island.svg" },
  { fileName: "166-morocco.svg" },
  { fileName: "085-solomon-islands.svg" },
  { fileName: "039-monaco.svg" },
  { fileName: "041-paraguay.svg" },
  { fileName: "113-greenland.svg" },
  { fileName: "255-brazil.svg" },
  { fileName: "001-mauritius.svg" },
  { fileName: "136-iran.svg" },
  { fileName: "099-albania.svg" },
  { fileName: "189-equatorial-guinea.svg" },
  { fileName: "044-latvia.svg" },
  { fileName: "100-pakistan.svg" },
  { fileName: "170-greece.svg" },
  { fileName: "081-bermuda.svg" },
  { fileName: "106-panama.svg" },
  { fileName: "203-sahrawi-arab-democratic-republic.svg" },
  { fileName: "076-suriname.svg" },
  { fileName: "231-libya.svg" },
  { fileName: "188-peru.svg" },
  { fileName: "215-falkland-islands.svg" },
  { fileName: "139-venezuela.svg" },
  { fileName: "155-israel.svg" },
  { fileName: "083-somalia.svg" },
  { fileName: "090-burkina-faso.svg" },
  { fileName: "057-burundi.svg" },
  { fileName: "175-rapa-nui.svg" },
  { fileName: "025-anguilla.svg" },
  { fileName: "245-jersey.svg" },
  { fileName: "019-ceuta.svg" },
  { fileName: "258-mongolia.svg" },
  { fileName: "202-taiwan.svg" },
  { fileName: "084-barbados.svg" },
  { fileName: "217-virgin-islands.svg" },
  { fileName: "261-kiribati.svg" },
  { fileName: "241-st-vincent-and-the-grenadines.svg" },
  { fileName: "204-guernsey.svg" },
  { fileName: "018-lebanon.svg" },
  { fileName: "209-indonesia.svg" },
  { fileName: "191-tonga.svg" },
  { fileName: "132-bosnia-and-herzegovina.svg" },
  { fileName: "037-jamaica.svg" },
  { fileName: "038-cape-verde.svg" },
  { fileName: "240-azores-islands.svg" },
  { fileName: "028-puerto-rico.svg" },
  { fileName: "223-turks-and-caicos.svg" },
  { fileName: "194-malta.svg" },
  { fileName: "134-liechtenstein.svg" },
  { fileName: "047-dominican-republic.svg" },
  { fileName: "146-gambia.svg" },
  { fileName: "073-togo.svg" },
  { fileName: "071-serbia.svg" },
  { fileName: "020-iraq.svg" },
  { fileName: "138-bahrain.svg" },
  { fileName: "117-angola.svg" },
  { fileName: "211-poland.svg" },
  { fileName: "233-transnistria.svg" },
  { fileName: "244-south-sudan.svg" },
  { fileName: "224-portugal.svg" },
  { fileName: "190-uzbekistn.svg" },
  { fileName: "043-montserrat.svg" },
  { fileName: "260-united-kingdom.svg" },
  { fileName: "145-ukraine.svg" },
  { fileName: "225-maldives.svg" },
  { fileName: "179-ireland.svg" },
  { fileName: "160-northern-marianas-islands.svg" },
  { fileName: "150-bolivia.svg" },
  { fileName: "017-christmas-island.svg" },
  { fileName: "157-palau.svg" },
  { fileName: "006-tanzania.svg" },
  { fileName: "169-liberia.svg" },
  { fileName: "135-belarus.svg" },
  { fileName: "129-ossetia.svg" },
  { fileName: "111-afghanistan.svg" },
  { fileName: "012-sao-tome-and-prince.svg" },
  { fileName: "005-ethiopia.svg" },
  { fileName: "003-austria.svg" },
  { fileName: "075-antigua-and-barbuda.svg" },
  { fileName: "163-papua-new-guinea.svg" },
  { fileName: "040-bhutan.svg" },
  { fileName: "198-argentina.svg" },
  { fileName: "087-aland-islands.svg" },
  { fileName: "158-egypt.svg" },
  { fileName: "197-melilla.svg" },
  { fileName: "054-canary-islands.svg" },
  { fileName: "126-botswana.svg" },
  { fileName: "065-eritrea.svg" },
  { fileName: "120-bahamas.svg" },
  { fileName: "252-mexico.svg" },
  { fileName: "086-nigeria.svg" },
  { fileName: "124-vatican-city.svg" },
  { fileName: "256-georgia.svg" },
  { fileName: "077-jordan.svg" },
  { fileName: "089-sint-maarten.svg" },
  { fileName: "173-mali.svg" },
  { fileName: "014-wales.svg" },
  { fileName: "131-chile.svg" },
  { fileName: "222-niger.svg" },
  { fileName: "200-south-africa.svg" },
  { fileName: "174-denmark.svg" },
  { fileName: "061-saba-island.svg" },
  { fileName: "213-gibraltar.svg" },
  { fileName: "226-united-states.svg" },
  { fileName: "063-japan.svg" },
  { fileName: "123-british-columbia.svg" },
  { fileName: "059-gabon.svg" },
  { fileName: "164-croatia.svg" },
  { fileName: "070-tuvalu-1.svg" },
  { fileName: "165-belgium.svg" },
  { fileName: "149-czech-republic.svg" },
  { fileName: "237-netherlands.svg" },
  { fileName: "247-somaliland.svg" },
  { fileName: "045-andorra.svg" },
  { fileName: "178-palau.svg" },
  { fileName: "227-senegal.svg" },
  { fileName: "176-lesotho.svg" },
  { fileName: "053-ghana.svg" },
  { fileName: "033-saint-kitts-and-nevis.svg" },
  { fileName: "116-curacao.svg" },
  { fileName: "098-guatemala.svg" },
  { fileName: "007-nicaragua.svg" },
  { fileName: "026-qatar.svg" },
  { fileName: "046-micronesia.svg" },
  { fileName: "068-djibouti.svg" },
  { fileName: "153-cuba.svg" },
  { fileName: "096-mozambique.svg" },
  { fileName: "182-niue.svg" },
  { fileName: "235-tokelau.svg" },
  { fileName: "036-central-african-republic.svg" },
  { fileName: "101-northern-cyprus.svg" },
  { fileName: "196-tajikistan.svg" },
  { fileName: "201-martinique.svg" },
  { fileName: "249-democratic-republic-of-congo.svg" },
  { fileName: "257-sardinia.svg" },
  { fileName: "184-sweden.svg" },
  { fileName: "137-fiji.svg" },
  { fileName: "008-estonia.svg" },
  { fileName: "248-russia.svg" },
  { fileName: "238-thailand.svg" },
  { fileName: "016-nepal.svg" },
  { fileName: "052-kosovo.svg" },
  { fileName: "095-pitcairn-islands.svg" },
  { fileName: "029-comoros.svg" },
  { fileName: "079-st-barts.svg" },
  { fileName: "103-marshall-island.svg" },
  { fileName: "210-grenada.svg" },
  { fileName: "144-algeria.svg" },
  { fileName: "118-malasya.svg" },
  { fileName: "151-united-arab-emirates.svg" },
  { fileName: "236-republic-of-macedonia.svg" },
  { fileName: "162-germany.svg" },
  { fileName: "208-palestine.svg" },
  { fileName: "185-haiti.svg" },
  { fileName: "159-cambodia.svg" },
  { fileName: "133-saudi-arabia.svg" },
  { fileName: "048-abkhazia.svg" },
  { fileName: "015-el-salvador.svg" },
  { fileName: "242-madagascar.svg" },
  { fileName: "082-united-nations.svg" },
  { fileName: "080-iceland.svg" },
];

const Flags: FC<IFlagProps> = (props) => {
  const [selectedFlag, setSelectedFlag] = useState<string | null>(null);
  const classes = useStyle();

  useEffect(() => {
    if (props.flag) setSelectedFlag(props.flag);
  }, [props.flag]);

  const handleCheckboxChange = (fileName: string) => {
    setSelectedFlag((prevState) => (prevState === fileName ? null : fileName));
    props.onChange(fileName);
  };

  return (
    <Grid container spacing={2} mb={2}>
      {flags.map((flag, index) => {
        const labelId = `checkbox-list-label-${index}`;
        const isChecked = selectedFlag === flag.fileName;

        return (
          <Grid item sm={12} xs={12} md={12} lg={4} xl={4} key={labelId}>
            <ListItem disablePadding className={classes.item}>
              <ListItemButton
                sx={{ padding: "0px 2px" }}
                role={undefined}
                onClick={() => handleCheckboxChange(flag.fileName)}
                dense
              >
                <ListItemIcon
                  sx={{
                    minWidth: 34,
                    "& .Mui-checked": {
                      color: "#0D99FF!important",
                    },
                  }}
                >
                  <Checkbox
                    edge="start"
                    checked={isChecked}
                    tabIndex={-1}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${index + 1}`}
                    src={toAbsoluteUrl(`/media/flags/${flag.fileName}`)}
                  />
                </ListItemAvatar>
              </ListItemButton>
            </ListItem>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Flags;
