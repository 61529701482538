import React from "react";
import { createTheme, PaletteOptions } from "@mui/material/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store/store";

const ThemeProvider = (props: any) => {
  const { uiMode } = useSelector(
    ({ settingReducer }: RootState) => settingReducer
  );

  const colors: { [key in "dark" | "light"]: PaletteOptions } = {
    dark: {
      mode: "dark",
      contrastThreshold: 2,
      success: {
        main: "#66bb6a",
      },
      primary: {
        main: "#2A3042",
        light: "#9FA5BB",
      },
      warning: {
        main: "#ffa726",
      },
      secondary: {
        main: "#0abb87",
        contrastText: "#ffffff",
      },
      error: {
        main: "#f50000",
      },
    },
    light: {
      mode: "light",
      contrastThreshold: 2,
      success: {
        main: "#3DCA81",
        dark: "#2a8d5a",
        light: "#2FFF93",
      },
      primary: {
        main: "#0D99FF",
        dark: "#035189",
        light: "#E0F2FF",
      },
      warning: {
        dark: "#ED6F51",
        main: "#FBB73C",
        light: "#fbc563",
      },
      secondary: {
        dark: "#3b4da1",
        main: "#556EE6",
        light: "#D4DBF9",
        contrastText: "#ffffff",
      },
      error: {
        dark: "#a52123",
        main: "#EC3033",
        light: "#F68082",
      },
    },
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins",
    },

    palette: colors[uiMode],
    components: {
      MuiTooltip: {
        styleOverrides: {
          // Name of the slot
          tooltip: {
            // Some CSS
            fontSize: "1rem",
          },
        },
      },
    },
  });

  const { children } = props;
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
