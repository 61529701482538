// @ts-nocheck
import React, { useReducer } from "react";
import uuid from "react-uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Item from "./components/Item";
import useOutsideAlerter from "./components/useOutsideAlerter";
import AddChecklist from "./components/AddChecklist";
import { Textarea } from "fogito-core-ui";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { FormattedMessage } from "react-intl";

const FogitoChecklist = React.memo(({ params, setParams }) => {
  const contentRef = React.useRef();
  const [state, setState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      title: "Checklist",
      add: false,
      addItems: false,
      editTitle: false,
      editItemsTitle: false,
    }
  );

  const onAddChecklist = async (e) => {
    e.preventDefault();
    if (state.title.trim() !== "") {
      let obj = {
        id: uuid(),
        title: state.title,
        items: [],
      };
      setParams({
        ...params,
        checklist: params.checklist.concat([obj]),
      });
      setState({
        editTitle: false,
        title: "",
        add: false,
      });
      setTimeout(() => {
        setState({
          addItems: params.checklist.length,
        });
      }, 20);
    }
  };

  const onEdit = async (e, row, key) => {
    e.preventDefault();
    if (state.title.trim() !== "") {
      setParams({
        ...params,
        checklist: params.checklist.map((x, i) => {
          if (i == key) {
            return { ...x, title: state.title };
          }
          return x;
        }),
      });
      setState({
        editTitle: false,
        title: "",
      });
    }
  };

  const onDeleteChecklist = (index) => {
    setParams({
      ...params,
      checklist: params.checklist.filter((row, key) => key != index),
    });
  };

  const onReorder = async ({ type, source, destination }) => {
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "task") {
      let columnFrom = params.checklist.find(
        (item) => item.id === source.droppableId
      );
      let columnTo = params.checklist.find(
        (item) => item.id === destination.droppableId
      );

      if (columnFrom === columnTo) {
        let sorted = Array.from(columnFrom.items);
        let [card] = sorted.splice(source.index, 1);
        sorted.splice(destination.index, 0, card);
        setParams({
          ...params,
          checklist: params.checklist.map((item) => {
            if (item.id === columnFrom.id) {
              item.items = sorted;
            }
            return item;
          }),
        });
      } else {
        let sortedFrom = Array.from(columnFrom.items);
        let sortedTo = Array.from(columnTo.items);
        let [card] = sortedFrom.splice(source.index, 1);
        card.items = columnTo.id;
        sortedTo.splice(destination.index, 0, card);
        setParams({
          ...params,
          checklist: params.checklist.map((item) => {
            if (item.id === columnFrom.id) {
              item.items = sortedFrom;
            }
            if (item.id === columnTo.id) {
              item.items = sortedTo;
            }
            return item;
          }),
        });
      }
    } else {
      let sorted = Array.from(params.checklist);
      let [column] = sorted.splice(source.index, 1);
      sorted.splice(destination.index, 0, column);
      setParams({ ...params, checklist: sorted });
    }
  };

  useOutsideAlerter(contentRef, function () {
    setState({
      add: false,
      editItemsTitle: false,
      editTitle: false,
    });
  });

  const escButton = (event) => {
    if (event.keyCode === 27) {
      setState({
        add: false,
        editItemsTitle: false,
        editTitle: false,
        addItems: false,
        title: "",
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", (e) => escButton(e), false);
    return () => {
      document.addEventListener("keydown", (e) => escButton(e), false);
    };
  }, []);

  return (
    <>
      <AddChecklist
        state={state}
        setState={setState}
        contentRef={contentRef}
        onAddChecklist={onAddChecklist}
      />
      <DragDropContext onDragEnd={onReorder}>
        <Droppable droppableId="columns" direction="vertical" type="column">
          {(provided) => (
            <div
              className="d-flex flex-column"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {params.checklist?.map((row, key) => {
                if (row.items) {
                  let itemsLength = row.items.length;
                  let itemsCompleted = row.items.filter(
                    (x) => !!x.completed
                  ).length;
                  let percent =
                    itemsLength > 0 ? (itemsCompleted / itemsLength) * 100 : 0;
                  return (
                    <div className="position-relative" key={key}>
                      <Draggable draggableId={row.id} index={key} key={row.id}>
                        {(provided, snapshot) => {
                          // if (snapshot.isDragging) {
                          //   provided.draggableProps.style.left =
                          //     provided.draggableProps.style.offsetLeft;
                          //   provided.draggableProps.style.top =
                          //     provided.draggableProps.style.offsetTop;
                          // }
                          return (
                            <div
                              key={key}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              className="checklist"
                            >
                              <button
                                className="btn rounded-circle feather feather-x checklist-delete-icon"
                                style={{
                                  backgroundColor: "#EC3033",
                                }}
                                onClick={() => onDeleteChecklist(key)}
                              >
                                <CloseOutlinedIcon />
                              </button>
                              {state.editTitle === key ? (
                                <form
                                  style={{ marginLeft: "2.6rem" }}
                                  ref={contentRef}
                                >
                                  <div className="form-group mb-2">
                                    <Textarea
                                      rows="1"
                                      maxLength="300"
                                      defaultValue={row.title}
                                      onChange={(e) =>
                                        setState({ title: e.target.value })
                                      }
                                      placeholder={"Title"}
                                      className="form-control"
                                      autoFocus
                                      onFocus={(e) => {
                                        let length = e.target.value.length;
                                        e.target.setSelectionRange(
                                          length,
                                          length
                                        );
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                          e.preventDefault();
                                          onEdit(e, row, key);
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex">
                                    <button
                                      onClick={(e) => {
                                        onEdit(e, row, key);
                                      }}
                                      className="btn btn-primary px-5"
                                      style={{
                                        backgroundColor: "#0D99FF",
                                        borderColor: "#0D99FF",
                                      }}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="btn btn-secondary px-5"
                                      onClick={() =>
                                        setState({
                                          editTitle: false,
                                          title: row.title,
                                        })
                                      }
                                      type="button"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              ) : (
                                <div className="checklist-title">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                    width="20"
                                    height="20"
                                  >
                                    <path d="M10.5,17a2,2,0,0,1-1.416-.586L5.3,12.715a1,1,0,0,1,1.4-1.43l3.793,3.707,6.806-6.7a1,1,0,0,1,1.4,1.424l-6.793,6.707A1.983,1.983,0,0,1,10.5,17ZM24,19V12.34A12.209,12.209,0,0,0,12.836.028,12,12,0,0,0,.029,12.854C.471,19.208,6.082,24,13.083,24H19A5.006,5.006,0,0,0,24,19ZM12.7,2.024A10.2,10.2,0,0,1,22,12.34V19a3,3,0,0,1-3,3H13.083C7.049,22,2.4,18.1,2.025,12.716A10,10,0,0,1,12.016,2C12.243,2,12.472,2.009,12.7,2.024Z" />
                                  </svg>
                                  <button
                                    className="btn shadow-none bg-transparent p-0 mb-0 ml-2 cursor-pointer"
                                    onClick={() => setState({ editTitle: key })}
                                    type="button"
                                  >
                                    {row.title}
                                  </button>
                                </div>
                              )}

                              <div className="d-flex align-items-center mt-1">
                                <span
                                  className="fw-500 text-nowrap"
                                  style={{ minWidth: "2.2rem" }}
                                >
                                  {parseInt(percent)}%
                                </span>
                                <div className="checklist-progress ml-2">
                                  <div
                                    className="percent"
                                    style={{
                                      width: `${percent}%`,
                                      backgroundColor:
                                        parseInt(percent) == 100
                                          ? "#2dce89"
                                          : "#6CB2E7",
                                    }}
                                  />
                                </div>
                              </div>

                              <Droppable droppableId={row.id} type="task">
                                {(provided) => (
                                  <div
                                    className="body p-3"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Item
                                      row={row}
                                      index={key}
                                      params={params}
                                      setParams={setParams}
                                      addItemsToggle={state.addItems}
                                      closeAddItemsToggle={() =>
                                        setState({ addItems: false })
                                      }
                                    />
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>

                              <div
                                className="d-flex justify-content-between"
                                style={{
                                  padding: ".5rem",
                                  marginLeft: "2.2rem",
                                }}
                              >
                                {state.addItems === key ? (
                                  <div />
                                ) : (
                                  <button
                                    className="btn btn-secondary px-4 py-2"
                                    type="button"
                                    onClick={() => setState({ addItems: key })}
                                  >
                                    <FormattedMessage
                                      id={"AddAnItem"}
                                      defaultMessage={"Add an item"}
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    </div>
                  );
                }
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
});

export default FogitoChecklist;
