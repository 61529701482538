import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

export interface ITaskComment {
  _id: string;
  text: string;
  created_by: IUser;
  created_at: string;
  _updated_at: string;
}

export interface ITaskCommentStore {
  text: string;
}

const INDEX = API_ROUTES.task_comments;
const SHOW = API_ROUTES.task_comment;
const UPDATE = API_ROUTES.task_comment;
const DELETE = API_ROUTES.task_comment;

export interface ITaskService {
  getAll(
    task: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITaskComment> | IRestApiError>;
  add(
    task: string,
    data: ITaskCommentStore
  ): Promise<IRestApiResource<ITaskComment> | IRestApiError>;
  getOne(
    task: string,
    _id: string
  ): Promise<IRestApiResource<ITaskComment> | IRestApiError>;
  update(
    task: string,
    _id: string,
    data: any
  ): Promise<IRestApiResource<ITaskComment> | IRestApiError>;
  delete(
    task: string,
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITaskComment> | IRestApiError>;
}

const taskCommentService: ITaskService = {
  async getAll(task: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(INDEX).replace(":task", task.toString())}${queriesString}`,
      "get"
    );
  },

  async add(task: string, data: ITaskCommentStore) {
    let formData = generateFormData(new FormData(), data);
    return http(
      getRoute(INDEX).replace(":task", task.toString()),
      "post",
      formData
    );
  },

  async getOne(task: string, _id: string) {
    return http(
      `${getRoute(SHOW)
        .replace(":task", task.toString())
        .replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async update(task: string, _id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE)
        .replace(":task", task.toString())
        .replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(task: string, _id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(
        DELETE.replace(":task", task.toString()).replace(":_id", _id.toString())
      )}${queriesString}`,
      "delete"
    );
  },
};

export { taskCommentService };
