import React from "react";
import { Grid } from "@mui/material";
import CaseChart from "../../widgets/CaseChart";
import CaseSurveyChart from "../../widgets/CaseSurveyChart";
import FeedbackChart from "../../widgets/Feedback";
import CasePerformanceChart from "../../widgets/CasePerformanceChart";

const CaseDashboard = () => {
  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
          <CaseChart source={"case_dashboard"} />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
          <CaseSurveyChart />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
          <FeedbackChart />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={8} xl={8}>
          <CasePerformanceChart />
        </Grid>
      </Grid>
    </>
  );
};

export default CaseDashboard;
