import React, { FC, useEffect, useRef, useState } from "react";
import { Grid, IconButton, Theme, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import CloseIcon from "@mui/icons-material/Close";
import { useReactMediaRecorder } from "react-media-recorder";
import VoiceRecorder from "./VoiceRecorder";

type SenderComposerType = {
  onSend: Function;
  onEmojiChange: Function;
  onVoiceUpload: Function;
};

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    gap: 8,
  },
  icon: {
    lineHeight: "0.5em",
    verticalAlign: "-0.15em",
    display: "inline-flex",
  },
  emoji: {
    position: "absolute",
    bottom: 65,
    right: 0,
  },
}));

export const Sender: FC<SenderComposerType> = (props) => {
  const classes = useStyle();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [openEmoji, setOpenEmoji] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const handleSend = () => {
    props.onSend();
  };

  const handleEmoji = () => {
    setOpenEmoji(!openEmoji);
  };

  const onEmojiChange = (pickedEmoji: EmojiClickData) => {
    props.onEmojiChange(pickedEmoji.emoji);
  };

  const onVoiceUpload = (blobUrl: string, blob: Blob) => {
    props.onVoiceUpload(blobUrl, blob);
  };

  return (
    <>
      <Grid className={classes.emoji} ref={contentRef}>
        {openEmoji && (
          <EmojiPicker
            onEmojiClick={(emoji) => onEmojiChange(emoji)}
            previewConfig={{ showPreview: false }}
          />
        )}
      </Grid>

      <Grid className={classes.container}>
        {isRecording ? (
          <VoiceRecorder
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            onVoiceUpload={onVoiceUpload}
          />
        ) : (
          <IconButton
            onClick={() => {
              setIsRecording(!isRecording);
              setIsRecording(true);
            }}
            size="large"
          >
            <i
              className={`fi fi-sr-${isRecording ? "stop" : "microphone"} ${
                classes.icon
              }`}
            />
          </IconButton>
        )}
        <IconButton onClick={handleEmoji} size="large">
          {openEmoji ? (
            <CloseIcon />
          ) : (
            <i className={`fi fi-sr-smile ${classes.icon}`} />
          )}
        </IconButton>
        <IconButton onClick={handleSend} size="large" sx={{ mr: -1.5 }}>
          <i className={`fi fi-sr-paper-plane ${classes.icon}`} />
        </IconButton>
      </Grid>
    </>
  );
};

export default Sender;
