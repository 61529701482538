import React, { Dispatch, FC, SetStateAction } from "react";
import Snackbar from "../../../widgets/Snackbar";
import { caseService, userService } from "../../../services";
import TimeTrackStoreDialogue, {
  FormStateValues,
} from "../../../partials/dialogues/store/time_track";
import { useIntl } from "react-intl";

interface IFormProps {
  _id: string;
  session_type?: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

const TimeTrackStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const handleSave = (validated: FormStateValues, callback: () => void) => {
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    validated["relation_type"] = "user";
    validated["related"] = props._id;

    userService
      .postTimeTrack(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          callback();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <TimeTrackStoreDialogue
      _id={props._id}
      open={props.open}
      setDialogue={props.setDialogue}
      case_needed={false}
      save={handleSave}
      session_type={"user"}
    />
  );
};

export default TimeTrackStore;
