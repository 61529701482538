import React, { Dispatch, SetStateAction, useCallback } from "react";
import Snackbar from "../../../widgets/Snackbar";
import { settingService } from "../../../services";
import ShowSettingDialogue2, {
  FormStateValues,
} from "../../../partials/dialogues/show/Settings/settingDialogue2";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as setting from "app/store/ducks/setting.duck";

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

const ShowLabel = (props: FormProps) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const updateSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Update, payload: s }),
    [dispatch]
  );

  const handleSave = (validated: FormStateValues, callback: () => void) => {
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    settingService
      .update(props._id, validated, [
        {
          name: "type",
          value: "labels",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        let { data: setting } = data;
        updateSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          callback();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <ShowSettingDialogue2
      _id={props._id}
      open={props.open}
      setDialogue={props.setDialogue}
      section={intl.formatMessage({
        id: "SETTINGS.LABELS",
        defaultMessage: "Labels",
      })}
      settingValue={"labels"}
      save={handleSave}
    />
  );
};

export default ShowLabel;
