import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../store/store";
import * as setting from "../store/ducks/setting.duck";
import * as chat from "../store/ducks/chat.duck";
import * as permission from "../store/ducks/permission.duck";
import * as user from "../store/ducks/user.duck";
import * as notification from "../store/ducks/notification.duck";
import * as auth from "../store/ducks/auth.duck";
import * as i18n from "../store/ducks/i18n.duck";
import SplashScreen from "../partials/layout/SplashScreen";
import { chatService } from "../services/chat.service";

const SettingsLoading: FC = (props) => {
  const [updating, setUpStatus] = useState<boolean>(true);
  const {
    isAuthorized,
    updated,
    notifications,
    settings,
    lang,
  }: {
    isAuthorized: boolean;
    updated: boolean;
    notifications: boolean;
    settings: boolean;
    lang: boolean;
  } = useSelector(
    ({ auth, notification, settingReducer, i18n }: RootState) => ({
      isAuthorized: auth.user != null,
      updated: !auth.refresh,
      notifications: !notification.loading,
      settings: !settingReducer.loading,
      lang: i18n.loading,
    })
  );

  useEffect(() => {
    if (isAuthorized) {
      const splashScreen = document.getElementById("splash-screen");

      if (splashScreen) splashScreen.classList.remove("hidden");

      store.dispatch(auth.actions.requestUser());
      store.dispatch(setting.actions.get());
      // store.dispatch(chat.actions.getRooms());
      store.dispatch(permission.actions.getRoles());
      store.dispatch(notification.actions.getNotifications());
      store.dispatch(i18n.actions.getMessages());

      // just wait for dispatch resolve
      setTimeout(() => {
        setUpStatus(false);
      }, 1000);
    }
  }, [isAuthorized]);

  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");

    setTimeout(() => {
      if (
        (!updating && notifications && settings && updated && !lang) ||
        !isAuthorized
      ) {
        if (splashScreen) splashScreen.classList.add("hidden");
      }
    }, 5000);
  }, [updating, notifications, settings, updated, isAuthorized, lang]);

  return <>{props.children}</>;
};

export default SettingsLoading;
