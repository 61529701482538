import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Snackbar from "../../../widgets/Snackbar";
import { settingService } from "../../../services";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Controls from "../../../widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ISetting } from "../../../services/setting.service";
import { FormattedMessage, useIntl } from "react-intl";
import * as setting from "../../../store/ducks/setting.duck";

interface _ISettings extends ISetting {
  focus_area?: ISetting;
  focus_type?: ISetting;
}

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  title: { [key: string]: string } | null;
  active: number;
  focus_area: string;
  focus_type: string;
  content: string;
};

const ShowOfferTemplate = (props: FormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { focus_areas } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { focus_types } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [offerTemplateOptions, setFocusTypeOptions] = useState<Array<any>>();
  const [offerTemplate, setOfferTemplate] = useState<_ISettings>();
  const dispatch = useDispatch();
  const updateSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Update, payload: s }),
    [dispatch]
  );

  const handleFocusAreaSelection = (e: SelectChangeEvent<any>): void => {
    // if (typeof e.target.value !== "number") return;

    let f =
      focus_types?.filter((f_t: any) => f_t.focus_area === e.target.value) ??
      undefined;

    if (f) setFocusTypeOptions([...f]);
  };

  useEffect(() => {
    settingService
      .getOne(props._id, [
        {
          name: "type",
          value: "offer_templates",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        let { data: offerTemplate } = data;
        setOfferTemplate(offerTemplate);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  useEffect(() => {
    let f_type =
      focus_types?.filter(
        (f_t: any) => f_t.focus_area === offerTemplate?.focus_area?._id
      ) ?? undefined;

    if (f_type) setFocusTypeOptions([...f_type]);
  }, [focus_types, offerTemplate]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    settingService
      .update(props._id, validated, [
        {
          name: "type",
          value: "offer_templates",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        let { data: setting } = data;
        updateSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (offerTemplate) {
      form.values.title = offerTemplate.title;
      form.values.focus_area = offerTemplate.focus_area
        ? offerTemplate.focus_area._id
        : "";
      form.values.focus_type = offerTemplate.focus_type
        ? offerTemplate.focus_type._id
        : "";
      form.values.active = offerTemplate.active;
      form.values.content = offerTemplate.content;
    }
  }, [offerTemplate]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"DIALOGUE.EDIT"}
                  defaultMessage={"DIALOGUE.EDIT"}
                />
              </Typography>
              <Button
                form={"setting_offer_template_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"setting_offer_template_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && offerTemplate ? (
                    <Controls.Input
                      name={"title_en"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      defaultValue={offerTemplate?.title["en"]}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        if (!form.values.title) {
                          form.values.title = {};
                        }

                        form.values.title["en"] = event.target.value;

                        event.preventDefault();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">EN</InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title_en"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && offerTemplate ? (
                    <Controls.Input
                      name={"title_da"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      defaultValue={offerTemplate?.title["da"]}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        if (!form.values.title) {
                          form.values.title = {};
                        }

                        form.values.title["da"] = event.target.value;
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">DA</InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title_da"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && offerTemplate ? (
                    <Controls.Select
                      name={"focus_area"}
                      formId={"focus_area"}
                      options={focus_areas?.map((f_a) => {
                        return {
                          id: f_a._id,
                          title: f_a.title["en"],
                        };
                      })}
                      defaultValue={offerTemplate?.focus_area?._id}
                      label={intl.formatMessage({ id: "CASE.FOCUS_AREA" })}
                      onChange={(e) => {
                        handleFocusAreaSelection(e);

                        form.values.focus_area = e.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"focus_area"}
                        options={[]}
                        label={intl.formatMessage({ id: "CASE.FOCUS_AREA" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && offerTemplate ? (
                    <Controls.Select
                      name={"focus_type"}
                      formId={"focus_type"}
                      options={
                        offerTemplateOptions &&
                        offerTemplateOptions.map((offerTemplateOption) => {
                          return {
                            id: offerTemplateOption._id,
                            title: offerTemplateOption.title["en"],
                          };
                        })
                      }
                      label={intl.formatMessage({ id: "CASE.FOCUS_TYPE" })}
                      defaultValue={offerTemplate?.focus_type?._id}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.focus_type = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"focus_type"}
                        options={[]}
                        label={intl.formatMessage({ id: "CASE.FOCUS_TYPE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && offerTemplate ? (
                    <Controls.Editor
                      name={"content"}
                      label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                      data={offerTemplate?.content}
                      onChange={(data: any) => {
                        form.values.content = data;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"content"}
                        label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && offerTemplate ? (
                    <Controls.Select
                      name={"active"}
                      formId={"status"}
                      defaultValue={offerTemplate?.active}
                      options={[
                        {
                          id: "1",
                          title: intl.formatMessage({
                            id: "STANDARD.ACTIVE",
                          }),
                        },
                        {
                          id: "0",
                          title: intl.formatMessage({
                            id: "STANDARD.DEACTIVE",
                          }),
                        },
                      ]}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.active = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"active"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowOfferTemplate;
