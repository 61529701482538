import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import * as _setting from "app/store/ducks/setting.duck";
import { Skeleton } from "@mui/lab";
import { caseService } from "app/services";
import {
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { ICaseFeedback } from "../../../services/case.service";
import { Ii18nState } from "../../../store/ducks/i18n.duck";
import { ISettingsState } from "app/store/ducks/setting.duck";

interface IFormProps {
  _case: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

const ShowCaseFeedback = (props: IFormProps) => {
  const intl = useIntl();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [feedback, setFeedback] = useState<ICaseFeedback>();

  useEffect(() => {
    caseService
      .getOneFeedback(props._case, props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: feedback } = data;
        setFeedback(feedback);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._case, props._id]);

  const getFeedbackSmile = (value: number | undefined) => {
    let path;

    if (value === -2) {
      path = "/media/smiles/angry.svg";
    } else if (value === -1) {
      path = "/media/smiles/sad.svg";
    } else if (value === 0) {
      path = "/media/smiles/neutral.svg";
    } else if (value === 1) {
      path = "/media/smiles/good.svg";
    } else if (value === 2) {
      path = "/media/smiles/excellent.svg";
    }

    return (
      <Box
        component="img"
        sx={{
          height: 45,
          width: 45,
          textAlign: "center",
        }}
        alt={feedback?.comment}
        src={path}
      />
    );
  };

  const handleClose = () => {
    props.setDialogue(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"CASE.FEEDBACK.SHOW"}
              defaultMessage={"CASE.FEEDBACK.SHOW"}
            />
          </Typography>
          <IconButton autoFocus color="inherit" onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Toolbar>
      </Box>
      <DialogContent>
        <form id={"feedback_form"} onSubmit={() => {}}>
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body1"
                  component="div"
                >
                  <Chip
                    label={`${intl.formatMessage({ id: "STANDARD.USER" })} :`}
                    sx={{
                      bgcolor: "#f5f5f5",
                      color: "#000000",
                      fontWeight: "600",
                      mr: "10px",
                    }}
                  />
                  {feedback?.user?.firstname} {feedback?.user?.lastname}
                </Typography>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"user"}
                    label={intl.formatMessage({ id: "STANDARD.USER" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body1"
                  component="div"
                >
                  <Chip
                    label={`Emoji:`}
                    sx={{
                      bgcolor: "#f5f5f5",
                      color: "#000000",
                      fontWeight: "600",
                      mr: "10px",
                    }}
                  />
                  {getFeedbackSmile(feedback?.emoji)}
                </Typography>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    rows={5}
                    name={"smile"}
                    label={`Emoji`}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body1"
                  component="div"
                >
                  <Chip
                    label={`${intl.formatMessage({
                      id: "CASE.FEEDBACK.COMMENT",
                    })} :`}
                    sx={{
                      bgcolor: "#f5f5f5",
                      color: "#000000",
                      fontWeight: "600",
                      mr: "10px",
                    }}
                  />
                  {feedback?.comment}
                </Typography>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    rows={5}
                    name={"comment"}
                    label={intl.formatMessage({ id: "CASE.FEEDBACK.COMMENT" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowCaseFeedback);
