import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { connect, useSelector } from "react-redux";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import moment from "moment";
import { GridTable } from "app/partials/layout/GridTable";
import { useTable } from "app/hooks/subresource/useTable";
import { INote, noteService } from "app/services/note.service";
import { useParams } from "react-router";
import FolderNoteStore from "./Modal/store";
import ShowNote from "./Modal/show";
import { useIntl } from "react-intl";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { Ii18nState } from "../../../store/ducks/i18n.duck";
import { ISettingsState } from "../../../store/ducks/setting.duck";
import FolderNoteFilter from "./filter";
import { hasPermission } from "app/permission/PermissionsGate";
import { usePermissions } from "app/hooks/useRole";
import { RootState } from "../../../store/store";
import { stringAvatar } from "../../../widgets/AvatarBackground";
type Props = {
  lang: string;
  settings_loading: boolean;
};

const Notes: FC<Props> = () => {
  const intl = useIntl();
  const { folderId } = useParams<{ folderId: string }>();
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const permissions = usePermissions();
  const { user } = useSelector(({ auth }: RootState) => auth);
  const [quickCardsCount, setQuickCardsCount] = useState<{
    moderator: number;
    employee: number;
  }>();
  const resetCardQuery = (
    isActive: boolean,
    name: "moderator" | "employee"
  ) => {
    let _queries = queries?.filter(
      (q) => q.name !== "moderator" && q.name !== "employee"
    );

    if (!_queries) return;

    if (isActive && queries && _queries) {
      let __queries = _queries.filter((q) => q.name !== name);
      setQuery(__queries);
    } else {
      setQuery([
        ..._queries,
        {
          name: name,
          value: "1",
        },
      ]);
    }
  };

  const { data, xhrLoading, refresh, remove, updateQuery, queries, setQuery } =
    useTable<INote>({
      fetch: noteService.getAll,
      delete: noteService.delete,
      param: folderId,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  useEffect(() => {
    noteService
      .getQuickCardsCount([{ name: "folder", value: folderId }])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: counts } = data;

        setQuickCardsCount(counts);
      });
  }, [queries]);

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return params.row?.title;
      },
      minWidth: 320,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 160,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.CREATED_BY" }),
      field: "created_by",
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                backgroundColor:
                  params?.row?.created_by?.role?.color ?? "#0D99FF",
                border:
                  "2px solid " + params?.row?.created_by?.role?.color ??
                  "#0D99FF",
              }}
              alt="Remy Sharp"
              sx={{
                backgroundColor: "#0D99FF",
                lineHeight: "0!important",
                width: 44,
                height: 44,
              }}
              src={params.row?.created_by?.file?.base64}
            >
              {params.row?.created_by?.firstname?.charAt(0) +
                params.row?.created_by?.lastname?.charAt(0)}
            </Avatar>
            <Typography sx={{ mt: 2 }} variant={"body1"}>
              {params.row?.created_by?.firstname +
                " " +
                params.row?.created_by?.lastname}
            </Typography>
          </Stack>
        );
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => show(params.row?._id)}>
              <OpenInNewIcon sx={{ color: "warning.main" }} />
            </IconButton>
            <IconButton onClick={() => handleClick(params.row?._id)}>
              <DeleteIcon sx={{ color: "error.main" }} />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [noteId, setNoteId] = useState<string | null>(null);

  const show = (_id: string) => {
    setNoteId(_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const quickCards = [
    {
      title: intl.formatMessage({ id: "EMPLOYEE_NOTES" }),
      onClick: (isActive: boolean) => resetCardQuery(isActive, "employee"),
      icon: "fi-sr-time-delete",
      value: quickCardsCount ? quickCardsCount.employee : 0,
    },
  ];

  if (user?.role?.slug === "moderator" || user?.role?.slug === "super-admin") {
    quickCards.push({
      title: intl.formatMessage({ id: "MODERATOR_NOTES" }),
      onClick: (isActive: boolean) => resetCardQuery(isActive, "moderator"),
      icon: "fi-sr-inbox",
      value: quickCardsCount ? quickCardsCount.moderator : 0,
    });
  }

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete note?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(folderId, confirmId);
          }}
        >
          Are you sure you want to delete this case?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      {noteId ? (
        <ShowNote
          folderId={folderId}
          _id={noteId}
          open={Boolean(noteId)}
          setDialogue={() => setNoteId(null)}
          onDone={refresh}
        />
      ) : (
        <></>
      )}
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
        search={(queries) => updateQuery(queries)}
        filterToolbar={{
          actionPanel:
            permissions && hasPermission(permissions, "notes", "create")
              ? (props: { open: boolean; close: () => void }) => {
                  return (
                    <FolderNoteStore
                      _id={folderId}
                      open={props.open}
                      setDialogue={props.close}
                      onDone={refresh}
                    />
                  );
                }
              : undefined,
          quickCards,
          filters: (props: { show: boolean }) => {
            return (
              <FolderNoteFilter
                show={props.show}
                updateQuery={updateQuery}
                queries={queries}
                setQuery={setQuery}
              />
            );
          },
        }}
      />
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
