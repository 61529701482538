import { Box, Grid, Typography } from "@mui/material";
import Controls from "../../../../widgets/uncontrolled";
import React, { FC, memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ICase } from "../../../../services/case.service";
import { ISetting } from "../../../../services/setting.service";
import { makeStyles } from "@material-ui/core";

export interface IFormFinanceValues {
  init_budget?: number;
  currency?: string;
  budget_interval?: string;
  budget_limit?: number;
}

interface IFormFinanceProps {
  case?: ICase;
  touched: boolean;
  handleChange: any;
  values: IFormFinanceValues;
  budget_intervals: Array<ISetting>;
}

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const Finance: FC<IFormFinanceProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Grid container sx={{ mt: "10px" }} spacing={2}>
      <Grid item sm={6} xs={6} md={6} lg={6} xl={6}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FORM.BUDGET"}
              defaultMessage={"CASE.FORM.BUDGET"}
            />
          </Typography>
          <Controls.Input
            onChange={(e) => {
              props.values.init_budget = +e.target.value;
            }}
            label={""}
            name={"budget"}
            error={false}
            defaultValue={props.case?.init_budget}
            helperText={""}
          />
        </Box>
      </Grid>
      <Grid item sm={6} xs={6} md={6} lg={6} xl={6}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"STANDARD.CURRENCY"}
              defaultMessage={"Currency"}
            />
          </Typography>
          <Controls.Select
            onChange={(e) => {
              props.values.currency = e.target.value;
            }}
            label={""}
            formId={"budget_currency"}
            name={"budget_currency"}
            defaultValue={props.case?.budget?.currency}
            options={[
              {
                id: "USD",
                title: "USD",
              },
              {
                id: "EURO",
                title: "EURO",
              },
              {
                id: "DKK",
                title: "DKK",
              },
            ]}
            error={""}
          />
        </Box>
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FORM.BUDGETCALCULATIONINTERVAL"}
              defaultMessage={"CASE.FORM.BUDGETCALCULATIONINTERVAL"}
            />
          </Typography>
          <Controls.Select
            onChange={(e) => {
              props.values.budget_interval = e.target.value;
            }}
            label={""}
            formId={"budget_interval_pick"}
            name={"budget_interval"}
            defaultValue={props.case?.budget_interval?._id}
            options={props.budget_intervals.map((b) => ({
              id: b._id,
              title: b.title && b.title[intl.locale],
            }))}
            error={""}
          />
        </Box>
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FORM.BUDGETLIMITFORINTERVAL"}
              defaultMessage={"CASE.FORM.BUDGETLIMITFORINTERVAL"}
            />
          </Typography>
          <Controls.Input
            onChange={(e) => {
              props.values.budget_limit = +e.target.value;
            }}
            defaultValue={props.case?.budget_limit}
            label={""}
            name={"budget_limit"}
            error={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(Finance);
