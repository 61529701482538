import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { IconButton } from "@mui/material";
import { valueService } from "app/services";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import EditIcon from "../../partials/icons/EditIcon";
import ValueShow from "./show";
import { IValue } from "../../services/value.service";
import ValueStore from "./store";
import { usePermissions } from "../../hooks/useRole";
import DeleteIcon from "../../partials/icons/DeleteIcon";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";

const Values = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  const [confirmId, setConfirmId] = useState<string | null>(null);

  const { data, xhrLoading, updateQuery, refresh, remove } = useTable<IValue>({
    fetch: valueService.getAll,
    delete: valueService.delete,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(null);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return params.row?.title;
      },
      minWidth: 300,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"values"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"values"} scope={"delete"}>
              <IconButton onClick={() => handleClick(params.row?._id)}>
                <DeleteIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (id: string) => {
    setValue(id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete value?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this value?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      <PermissionsGate section={"values"} scope={"read"}>
        {value ? (
          <ValueShow
            _id={value}
            open={Boolean(value)}
            setDialogue={() => setValue(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"values"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "values", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <ValueStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default Values;
