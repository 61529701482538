import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  Slider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { projectService } from "app/services";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { FormattedMessage, useIntl } from "react-intl";
import SelectUsers, { SelectedUsers } from "../../partials/SelectUsers";
import UserAvatar from "../../partials/layout/UserAvatar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { makeStyles } from "@material-ui/core";
import { DatePicker, Space } from "antd";
import moment from "moment";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  title: string;
  status: string;
  users: string[];
  start: string;
  end: string;
  priority: number;
};

const initValues = {
  title: "",
  status: "in_progress",
  users: [],
  start: "",
  end: "",
  priority: 0,
} as FormStateValues;

const ProjectStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [sliderColor, setSliderColor] = useState<string>("#035189");
  const [usersDialog, setUsersDialog] = useState<boolean | null>(false);
  const [selectedUsers, setSelectedUsers] = useState<Array<SelectedUsers>>([]);

  useEffect(() => {
    form.values.status = "in_progress";
    form.values.priority = 0;
  }, [props.open]);

  useEffect(() => {
    if (selectedUsers)
      form.values.users = selectedUsers.map((user) => user._id);
  }, [selectedUsers]);

  const valueText = (value: number) => {
    let result: string = "Low (0)";
    setSliderColor("#035189");
    if (value > 0 && value < 5) {
      result = "Low (" + value + ")";
      setSliderColor("rgb(138, 94, 217)");
    } else if (value >= 5 && value < 8) {
      result = "Medium (" + value + ")";
      setSliderColor("rgb(253, 215, 77)");
    } else if (value >= 8 && value <= 10) {
      result = "High (" + value + ")";
      setSliderColor("rgb(245, 54, 92)");
    }

    return result;
  };

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    projectService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      <SelectUsers
        open={Boolean(usersDialog)}
        Title={
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage id={"CASE.USERS"} defaultMessage={"CASE.USERS"} />
          </Typography>
        }
        handleClose={() => {
          setUsersDialog(null);
        }}
        handleSave={() => {}}
        onUserChange={(users) => {
          setSelectedUsers([...users]);
        }}
        users={selectedUsers}
      />
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage id={"ADD"} defaultMessage={"Add"} />
              </Typography>
              <Button
                form={"project_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"project_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;

                          event.preventDefault();
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({ id: "CASE.USERS" })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"LABEL.SELECTUSER"}
                        defaultMessage={"LABEL.SELECTUSER"}
                      />
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
                      {selectedUsers &&
                        selectedUsers.map((user) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UserAvatar user={user} />;
                              <IconButton
                                onClick={() => {
                                  setSelectedUsers(
                                    selectedUsers.filter(
                                      (s_user) => s_user._id !== user._id
                                    )
                                  );
                                }}
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  padding: "5px",
                                  backgroundColor: "#EC3033",
                                  "&:hover": { backgroundColor: "#EC3033" },
                                }}
                              >
                                <CloseOutlinedIcon
                                  sx={{
                                    color: "#fff",
                                    width: 10,
                                    height: 10,
                                  }}
                                />
                              </IconButton>
                            </Box>
                          );
                        })}
                      <Avatar sx={{ width: 44, height: 44 }}>
                        <Button
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "100%",
                            p: "14px!important",
                            backgroundColor: "#0D99FF",
                          }}
                          variant="contained"
                          onClick={() => {
                            setUsersDialog(true);
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.75 10.75H13.25V3.25C13.25 2.55965 12.6904 2 12 2C11.3096 2 10.75 2.55965 10.75 3.25V10.75H3.25C2.55965 10.75 2 11.3096 2 12C2 12.6904 2.55965 13.25 3.25 13.25H10.75V20.75C10.75 21.4404 11.3096 22 12 22C12.6904 22 13.25 21.4404 13.25 20.75V13.25H20.75C21.4404 13.25 22 12.6904 22 12C22 11.3096 21.4404 10.75 20.75 10.75Z"
                              fill="white"
                            />
                          </svg>
                        </Button>
                      </Avatar>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.STATUS"}
                          defaultMessage={"STANDARD.STATUS"}
                        />
                      </Typography>
                      <Controls.Select
                        options={[
                          {
                            id: "in_progress",
                            title: "In progress",
                          },
                          {
                            id: "completed",
                            title: "Completed",
                          },
                        ]}
                        formId={"status_pick"}
                        defaultValue={"in_progress"}
                        name={"status"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.status = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        options={[]}
                        name={"status"}
                        label={intl.formatMessage({
                          id: "STANDARD.STATUS",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.PRIORITY"}
                          defaultMessage={"STANDARD.PRIORITY"}
                        />
                      </Typography>
                      <Box
                        sx={{
                          height: 50,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0 15px",
                        }}
                      >
                        <Slider
                          aria-label="Temperature"
                          defaultValue={0}
                          getAriaValueText={valueText}
                          valueLabelFormat={valueText}
                          valueLabelDisplay="auto"
                          step={1}
                          min={0}
                          max={10}
                          onChange={(event, value) => {
                            if (!value) return;

                            form.values.priority = +value;
                          }}
                          sx={{
                            color: sliderColor,
                            transition: "all 0.3s",
                            "&.MuiSlider-rail": {
                              transition: "all 0.3s",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        options={[]}
                        name={"status"}
                        label={intl.formatMessage({
                          id: "STANDARD.STATUS",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1, mt: 2.1 }}>
                        <FormattedMessage
                          id={"CASE.TIMETRACK.STARTDATE"}
                          defaultMessage={"CASE.TIMETRACK.STARTDATE"}
                        />
                      </Typography>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          showTime={{ format: "HH:mm" }}
                          format={"DD-MM-YYYY HH:mm"}
                          className={classes.datePicker}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.start = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                          }}
                        />
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1, mt: 2.1 }}>
                        <FormattedMessage
                          id={"CASE.TIMETRACK.ENDDATE"}
                          defaultMessage={"CASE.TIMETRACK.ENDDATE"}
                        />
                      </Typography>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          showTime={{ format: "HH:mm" }}
                          format={"DD-MM-YYYY HH:mm"}
                          className={classes.datePicker}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.end = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                          }}
                        />
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ProjectStore;
