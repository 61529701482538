import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { connect, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridRowId } from "@mui/x-data-grid";
import snackbar from "../../widgets/Snackbar";
import { surveyService } from "../../services";
import { useIntl } from "react-intl";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import { PermissionsGate } from "../../permission/PermissionsGate";
import { RootState } from "../../store/store";
import { ISurvey } from "../../services/survey.service";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import EditIcon from "../../partials/icons/EditIcon";

const Surveys: FC = () => {
  const intl = useIntl();
  const { data, xhrLoading, remove, refresh, updateQuery } = useTable<ISurvey>({
    fetch: surveyService.getAll,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      align: "center",
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"surveys"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"surveys"} scope={"delete"}>
              <IconButton onClick={() => remove(params.row?._id)}>
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (_id: string) => {
    snackbar.info("Coming soon");
  };

  return (
    <PermissionsGate section={"surveys"} scope={"read"}>
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
      />
    </PermissionsGate>
  );
};

export default Surveys;
