import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import * as _setting from "app/store/ducks/setting.duck";
import {
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { FormStateValues } from "app/pages/Cases/store/planStore";
import { RootState } from "app/store/store";
import { TransitionProps } from "@mui/material/transitions";
import { useIntl } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import EditPlanAnswers from "./EditPlanAnswers";
import { Ii18nState } from "../../store/ducks/i18n.duck";

interface IFormProps {
  _case: string;
  open: boolean;
  onDone: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const PlanAnswers = (props: IFormProps) => {
  const form = useForm<FormStateValues>();
  const intl = useIntl();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [editAnswer, setEditAnswer] = useState<boolean | null>(false);

  const handleClose = () => {
    props.setDialogue(false);
  };

  return (
    <>
      <EditPlanAnswers
        open={Boolean(editAnswer)}
        setDialogue={() => setEditAnswer(null)}
      />
      <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              Answers
            </Typography>
          </Toolbar>
        </Box>
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          style={{ maxHeight: "430px", position: "relative" }}
        >
          <DialogContent>
            <Box sx={{ display: "flex", mb: 4 }}>
              <>
                <Typography
                  sx={{ color: "#0D99FF", fontWeight: 500 }}
                  variant={"body1"}
                >
                  1.
                </Typography>
              </>
              <Box sx={{ ml: 1 }}>
                <Grid container>
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <Typography
                      sx={{ color: "#0D99FF", fontWeight: 500 }}
                      variant={"body1"}
                    >
                      Lorem ipsum dolor sit amet, consectetur adispiscing elit
                      ut aliquam?
                    </Typography>
                    <Typography sx={{ mt: 1 }} variant={"body1"}>
                      Lorem ipsum dolor sit amet, consectetur adispiscing elit
                      ut aliquam purus sit amet luctus venenatis,lectus magna
                      fringilla urna porttitor.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Box>
                      <IconButton
                        onClick={() => {
                          //delete note
                        }}
                        sx={{
                          padding: "0px 15px!important",
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 5.33333V3.66667C16.5 3.22464 16.3244 2.80072 16.0118 2.48816C15.6993 2.17559 15.2754 2 14.8333 2H9.83333C9.39131 2 8.96738 2.17559 8.65482 2.48816C8.34226 2.80072 8.16667 3.22464 8.16667 3.66667V5.33333H4V7H5.66667V19.5C5.66667 20.163 5.93006 20.7989 6.3989 21.2678C6.86774 21.7366 7.50363 22 8.16667 22H16.5C17.163 22 17.7989 21.7366 18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5V7H20.6667V5.33333H16.5ZM11.5 16.1667H9.83333V11.1667H11.5V16.1667ZM14.8333 16.1667H13.1667V11.1667H14.8333V16.1667ZM14.8333 5.33333H9.83333V3.66667H14.8333V5.33333Z"
                            fill="#EC3033"
                          />
                        </svg>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setEditAnswer(true);
                        }}
                        sx={{ padding: "0px 15px!important" }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.178 2C18.4295 2 17.7118 2.29732 17.1825 2.82654L8.59354 11.4155C8.47767 11.5314 8.39547 11.6766 8.35573 11.8356L7.45162 15.452C7.3746 15.7601 7.46487 16.086 7.68943 16.3106C7.91399 16.5351 8.23991 16.6254 8.54801 16.5484L12.1644 15.6443C12.3234 15.6045 12.4686 15.5223 12.5844 15.4064L21.1734 6.81745C21.7027 6.28823 22 5.57044 22 4.822C22 4.07356 21.7027 3.35577 21.1734 2.82654C20.6442 2.29732 19.9264 2 19.178 2ZM18.4611 4.10514C18.6513 3.91502 18.9091 3.80821 19.178 3.80821C19.4469 3.80821 19.7047 3.91502 19.8948 4.10514C20.085 4.29526 20.1918 4.55313 20.1918 4.822C20.1918 5.09087 20.085 5.34874 19.8948 5.53886L11.4829 13.9508L9.5713 14.4287L10.0492 12.5171L18.4611 4.10514Z"
                            fill="#FBB73C"
                          />
                          <path
                            d="M4.71231 3.91791C3.99296 3.91791 3.30308 4.20367 2.79442 4.71233C2.28576 5.22099 2 5.91087 2 6.63022V19.2877C2 20.007 2.28576 20.6969 2.79442 21.2056C3.30308 21.7142 3.99296 22 4.71231 22H17.3698C18.0891 22 18.779 21.7142 19.2877 21.2056C19.7963 20.6969 20.0821 20.007 20.0821 19.2877V12.959C20.0821 12.4596 19.6773 12.0549 19.178 12.0549C18.6787 12.0549 18.2739 12.4596 18.2739 12.959V19.2877C18.2739 19.5275 18.1786 19.7574 18.0091 19.927C17.8395 20.0965 17.6096 20.1918 17.3698 20.1918H4.71231C4.47253 20.1918 4.24257 20.0965 4.07301 19.927C3.90346 19.7574 3.80821 19.5275 3.80821 19.2877V6.63022C3.80821 6.39044 3.90346 6.16048 4.07301 5.99093C4.24257 5.82137 4.47253 5.72612 4.71231 5.72612H11.041C11.5404 5.72612 11.9451 5.32134 11.9451 4.82202C11.9451 4.32269 11.5404 3.91791 11.041 3.91791H4.71231Z"
                            fill="#FBB73C"
                          />
                        </svg>
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ display: "flex", mb: 4 }}>
              <>
                <Typography
                  sx={{ color: "#0D99FF", fontWeight: 500 }}
                  variant={"body1"}
                >
                  1.
                </Typography>
              </>
              <Box sx={{ ml: 1 }}>
                <Grid container>
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <Typography
                      sx={{ color: "#0D99FF", fontWeight: 500 }}
                      variant={"body1"}
                    >
                      Lorem ipsum dolor sit amet, consectetur adispiscing elit
                      ut aliquam?
                    </Typography>
                    <Typography sx={{ mt: 1 }} variant={"body1"}>
                      Lorem ipsum dolor sit amet, consectetur adispiscing elit
                      ut aliquam purus sit amet luctus venenatis,lectus magna
                      fringilla urna porttitor.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Box>
                      <IconButton
                        onClick={() => {
                          //delete note
                        }}
                        sx={{
                          padding: "0px 15px!important",
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 5.33333V3.66667C16.5 3.22464 16.3244 2.80072 16.0118 2.48816C15.6993 2.17559 15.2754 2 14.8333 2H9.83333C9.39131 2 8.96738 2.17559 8.65482 2.48816C8.34226 2.80072 8.16667 3.22464 8.16667 3.66667V5.33333H4V7H5.66667V19.5C5.66667 20.163 5.93006 20.7989 6.3989 21.2678C6.86774 21.7366 7.50363 22 8.16667 22H16.5C17.163 22 17.7989 21.7366 18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5V7H20.6667V5.33333H16.5ZM11.5 16.1667H9.83333V11.1667H11.5V16.1667ZM14.8333 16.1667H13.1667V11.1667H14.8333V16.1667ZM14.8333 5.33333H9.83333V3.66667H14.8333V5.33333Z"
                            fill="#EC3033"
                          />
                        </svg>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setEditAnswer(true);
                        }}
                        sx={{ padding: "0px 15px!important" }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.178 2C18.4295 2 17.7118 2.29732 17.1825 2.82654L8.59354 11.4155C8.47767 11.5314 8.39547 11.6766 8.35573 11.8356L7.45162 15.452C7.3746 15.7601 7.46487 16.086 7.68943 16.3106C7.91399 16.5351 8.23991 16.6254 8.54801 16.5484L12.1644 15.6443C12.3234 15.6045 12.4686 15.5223 12.5844 15.4064L21.1734 6.81745C21.7027 6.28823 22 5.57044 22 4.822C22 4.07356 21.7027 3.35577 21.1734 2.82654C20.6442 2.29732 19.9264 2 19.178 2ZM18.4611 4.10514C18.6513 3.91502 18.9091 3.80821 19.178 3.80821C19.4469 3.80821 19.7047 3.91502 19.8948 4.10514C20.085 4.29526 20.1918 4.55313 20.1918 4.822C20.1918 5.09087 20.085 5.34874 19.8948 5.53886L11.4829 13.9508L9.5713 14.4287L10.0492 12.5171L18.4611 4.10514Z"
                            fill="#FBB73C"
                          />
                          <path
                            d="M4.71231 3.91791C3.99296 3.91791 3.30308 4.20367 2.79442 4.71233C2.28576 5.22099 2 5.91087 2 6.63022V19.2877C2 20.007 2.28576 20.6969 2.79442 21.2056C3.30308 21.7142 3.99296 22 4.71231 22H17.3698C18.0891 22 18.779 21.7142 19.2877 21.2056C19.7963 20.6969 20.0821 20.007 20.0821 19.2877V12.959C20.0821 12.4596 19.6773 12.0549 19.178 12.0549C18.6787 12.0549 18.2739 12.4596 18.2739 12.959V19.2877C18.2739 19.5275 18.1786 19.7574 18.0091 19.927C17.8395 20.0965 17.6096 20.1918 17.3698 20.1918H4.71231C4.47253 20.1918 4.24257 20.0965 4.07301 19.927C3.90346 19.7574 3.80821 19.5275 3.80821 19.2877V6.63022C3.80821 6.39044 3.90346 6.16048 4.07301 5.99093C4.24257 5.82137 4.47253 5.72612 4.71231 5.72612H11.041C11.5404 5.72612 11.9451 5.32134 11.9451 4.82202C11.9451 4.32269 11.5404 3.91791 11.041 3.91791H4.71231Z"
                            fill="#FBB73C"
                          />
                        </svg>
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ display: "flex", mb: 4 }}>
              <>
                <Typography
                  sx={{ color: "#0D99FF", fontWeight: 500 }}
                  variant={"body1"}
                >
                  1.
                </Typography>
              </>
              <Box sx={{ ml: 1 }}>
                <Grid container>
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <Typography
                      sx={{ color: "#0D99FF", fontWeight: 500 }}
                      variant={"body1"}
                    >
                      Lorem ipsum dolor sit amet, consectetur adispiscing elit
                      ut aliquam?
                    </Typography>
                    <Typography sx={{ mt: 1 }} variant={"body1"}>
                      Lorem ipsum dolor sit amet, consectetur adispiscing elit
                      ut aliquam purus sit amet luctus venenatis,lectus magna
                      fringilla urna porttitor.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Box>
                      <IconButton
                        onClick={() => {
                          //delete note
                        }}
                        sx={{
                          padding: "0px 15px!important",
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 5.33333V3.66667C16.5 3.22464 16.3244 2.80072 16.0118 2.48816C15.6993 2.17559 15.2754 2 14.8333 2H9.83333C9.39131 2 8.96738 2.17559 8.65482 2.48816C8.34226 2.80072 8.16667 3.22464 8.16667 3.66667V5.33333H4V7H5.66667V19.5C5.66667 20.163 5.93006 20.7989 6.3989 21.2678C6.86774 21.7366 7.50363 22 8.16667 22H16.5C17.163 22 17.7989 21.7366 18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5V7H20.6667V5.33333H16.5ZM11.5 16.1667H9.83333V11.1667H11.5V16.1667ZM14.8333 16.1667H13.1667V11.1667H14.8333V16.1667ZM14.8333 5.33333H9.83333V3.66667H14.8333V5.33333Z"
                            fill="#EC3033"
                          />
                        </svg>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setEditAnswer(true);
                        }}
                        sx={{ padding: "0px 15px!important" }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.178 2C18.4295 2 17.7118 2.29732 17.1825 2.82654L8.59354 11.4155C8.47767 11.5314 8.39547 11.6766 8.35573 11.8356L7.45162 15.452C7.3746 15.7601 7.46487 16.086 7.68943 16.3106C7.91399 16.5351 8.23991 16.6254 8.54801 16.5484L12.1644 15.6443C12.3234 15.6045 12.4686 15.5223 12.5844 15.4064L21.1734 6.81745C21.7027 6.28823 22 5.57044 22 4.822C22 4.07356 21.7027 3.35577 21.1734 2.82654C20.6442 2.29732 19.9264 2 19.178 2ZM18.4611 4.10514C18.6513 3.91502 18.9091 3.80821 19.178 3.80821C19.4469 3.80821 19.7047 3.91502 19.8948 4.10514C20.085 4.29526 20.1918 4.55313 20.1918 4.822C20.1918 5.09087 20.085 5.34874 19.8948 5.53886L11.4829 13.9508L9.5713 14.4287L10.0492 12.5171L18.4611 4.10514Z"
                            fill="#FBB73C"
                          />
                          <path
                            d="M4.71231 3.91791C3.99296 3.91791 3.30308 4.20367 2.79442 4.71233C2.28576 5.22099 2 5.91087 2 6.63022V19.2877C2 20.007 2.28576 20.6969 2.79442 21.2056C3.30308 21.7142 3.99296 22 4.71231 22H17.3698C18.0891 22 18.779 21.7142 19.2877 21.2056C19.7963 20.6969 20.0821 20.007 20.0821 19.2877V12.959C20.0821 12.4596 19.6773 12.0549 19.178 12.0549C18.6787 12.0549 18.2739 12.4596 18.2739 12.959V19.2877C18.2739 19.5275 18.1786 19.7574 18.0091 19.927C17.8395 20.0965 17.6096 20.1918 17.3698 20.1918H4.71231C4.47253 20.1918 4.24257 20.0965 4.07301 19.927C3.90346 19.7574 3.80821 19.5275 3.80821 19.2877V6.63022C3.80821 6.39044 3.90346 6.16048 4.07301 5.99093C4.24257 5.82137 4.47253 5.72612 4.71231 5.72612H11.041C11.5404 5.72612 11.9451 5.32134 11.9451 4.82202C11.9451 4.32269 11.5404 3.91791 11.041 3.91791H4.71231Z"
                            fill="#FBB73C"
                          />
                        </svg>
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ display: "flex", mb: 4 }}>
              <>
                <Typography
                  sx={{ color: "#0D99FF", fontWeight: 500 }}
                  variant={"body1"}
                >
                  1.
                </Typography>
              </>
              <Box sx={{ ml: 1 }}>
                <Grid container>
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <Typography
                      sx={{ color: "#0D99FF", fontWeight: 500 }}
                      variant={"body1"}
                    >
                      Lorem ipsum dolor sit amet, consectetur adispiscing elit
                      ut aliquam?
                    </Typography>
                    <Typography sx={{ mt: 1 }} variant={"body1"}>
                      Lorem ipsum dolor sit amet, consectetur adispiscing elit
                      ut aliquam purus sit amet luctus venenatis,lectus magna
                      fringilla urna porttitor.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Box>
                      <IconButton
                        onClick={() => {
                          //delete note
                        }}
                        sx={{
                          padding: "0px 15px!important",
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 5.33333V3.66667C16.5 3.22464 16.3244 2.80072 16.0118 2.48816C15.6993 2.17559 15.2754 2 14.8333 2H9.83333C9.39131 2 8.96738 2.17559 8.65482 2.48816C8.34226 2.80072 8.16667 3.22464 8.16667 3.66667V5.33333H4V7H5.66667V19.5C5.66667 20.163 5.93006 20.7989 6.3989 21.2678C6.86774 21.7366 7.50363 22 8.16667 22H16.5C17.163 22 17.7989 21.7366 18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5V7H20.6667V5.33333H16.5ZM11.5 16.1667H9.83333V11.1667H11.5V16.1667ZM14.8333 16.1667H13.1667V11.1667H14.8333V16.1667ZM14.8333 5.33333H9.83333V3.66667H14.8333V5.33333Z"
                            fill="#EC3033"
                          />
                        </svg>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setEditAnswer(true);
                        }}
                        sx={{ padding: "0px 15px!important" }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.178 2C18.4295 2 17.7118 2.29732 17.1825 2.82654L8.59354 11.4155C8.47767 11.5314 8.39547 11.6766 8.35573 11.8356L7.45162 15.452C7.3746 15.7601 7.46487 16.086 7.68943 16.3106C7.91399 16.5351 8.23991 16.6254 8.54801 16.5484L12.1644 15.6443C12.3234 15.6045 12.4686 15.5223 12.5844 15.4064L21.1734 6.81745C21.7027 6.28823 22 5.57044 22 4.822C22 4.07356 21.7027 3.35577 21.1734 2.82654C20.6442 2.29732 19.9264 2 19.178 2ZM18.4611 4.10514C18.6513 3.91502 18.9091 3.80821 19.178 3.80821C19.4469 3.80821 19.7047 3.91502 19.8948 4.10514C20.085 4.29526 20.1918 4.55313 20.1918 4.822C20.1918 5.09087 20.085 5.34874 19.8948 5.53886L11.4829 13.9508L9.5713 14.4287L10.0492 12.5171L18.4611 4.10514Z"
                            fill="#FBB73C"
                          />
                          <path
                            d="M4.71231 3.91791C3.99296 3.91791 3.30308 4.20367 2.79442 4.71233C2.28576 5.22099 2 5.91087 2 6.63022V19.2877C2 20.007 2.28576 20.6969 2.79442 21.2056C3.30308 21.7142 3.99296 22 4.71231 22H17.3698C18.0891 22 18.779 21.7142 19.2877 21.2056C19.7963 20.6969 20.0821 20.007 20.0821 19.2877V12.959C20.0821 12.4596 19.6773 12.0549 19.178 12.0549C18.6787 12.0549 18.2739 12.4596 18.2739 12.959V19.2877C18.2739 19.5275 18.1786 19.7574 18.0091 19.927C17.8395 20.0965 17.6096 20.1918 17.3698 20.1918H4.71231C4.47253 20.1918 4.24257 20.0965 4.07301 19.927C3.90346 19.7574 3.80821 19.5275 3.80821 19.2877V6.63022C3.80821 6.39044 3.90346 6.16048 4.07301 5.99093C4.24257 5.82137 4.47253 5.72612 4.71231 5.72612H11.041C11.5404 5.72612 11.9451 5.32134 11.9451 4.82202C11.9451 4.32269 11.5404 3.91791 11.041 3.91791H4.71231Z"
                            fill="#FBB73C"
                          />
                        </svg>
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </DialogContent>
        </PerfectScrollbar>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: any;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanAnswers);
