import React, { FC } from "react";
import { Grid, Divider, Chip } from "@mui/material";
import { Users, Focus, Dates, Condition, Finance, Commune } from "./parts";
import { IRole } from "../../../interfaces";
import { IForm } from "../../../hooks/useForm";
import { ISetting } from "../../../services/setting.service";
import { useIntl } from "react-intl";
import Report from "./parts/report";
import { FormStateValues } from "../store";
import { ICommune } from "../../../services/comunne.service";

export interface IFormProps {
  focus_areas: Array<ISetting>;
  positions: Array<ISetting>;
  focus_types: Array<ISetting>;
  report_types: Array<ISetting>;
  report_intervals: Array<ISetting>;
  meeting_duration_types: Array<ISetting>;
  budget_intervals: Array<ISetting>;
  roles: Array<IRole>;
  communes: Array<ICommune>;
  form: IForm<FormStateValues>;
  saveUsers: Function;
}

const CaseStore: FC<IFormProps> = (props) => {
  const intl = useIntl();

  return (
    <Grid container spacing={4}>
      <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "CASE.FORM.FOCUSAREATYPE" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Focus
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />

        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({
              id: "CASE.INFORMATION.COMMUNE.COMMUNE",
            })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Commune
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />

        <Report
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />

        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "STANDARD.DATE" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Dates
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />
      </Grid>

      <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
        <Divider sx={{ my: 2, mb: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "STANDARD.USERS" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Users
          count={3}
          handleChange={props.form.handleChange}
          values={props.form.values}
          title
        />

        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "CASE.FORM.WORKCONDITIONS" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Condition
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />

        <Divider sx={{ my: 2 }}>
          <Chip
            label={intl.formatMessage({ id: "CASE.INFORMATION.FINANCE" })}
            sx={{ bgcolor: "#f5f5f5", color: "#000000", fontWeight: 600 }}
          />
        </Divider>
        <Finance
          {...props}
          handleChange={props.form.handleChange}
          values={props.form.values}
          touched={props.form.touched}
        />
      </Grid>
    </Grid>
  );
};

export default CaseStore;
