import React, { ChangeEvent, ReactNode } from "react";
import { Switch as MuiSwitch, FormControlLabel, FormGroup, FormControl } from '@mui/material';


type ComponentProps = {
  label: string,
  value: string,
}

export const Switch = (props : ComponentProps) => {


  const { label, value, ...other } = props

  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel control={<MuiSwitch defaultValue={''} value={value} />} label={props.label} />
      </FormGroup>
    </FormControl>
  )
}

export default Switch;
