export const headerHeight = () => {
  let kt_header: HTMLElement | null = document.querySelector("#kt_header");

  if (!kt_header) return null;

  return kt_header.offsetHeight;
}

export const footerHeight = () => {
  let kt_footer: HTMLElement | null = document.querySelector(".kt-footer");

  if (!kt_footer) return null;

  return kt_footer.offsetHeight;
};

export const subHeaderHeight = () => {
  let kt_subheader: HTMLElement | null =
    document.querySelector("#kt_subheader");

  if (!kt_subheader) return null;

  return kt_subheader.offsetHeight;
};

export const contentHeight = () => {
  let h = headerHeight();

  if (!h) return null;

  /**
   * - 20 is padding from header
   * - 30 is FIGMA bottom padding
   */

  return window.innerHeight - h - 30 - 20;
};


type IDevice = "App" | "Web";

export const getPlatform = (info: string): IDevice | null => {
  let ret: IDevice | null;

  if (info === "MacIntel" || info === "Win32") {
    ret = "Web";
  } else {
    ret = "App";
  }

  return ret;
};
