import React, { FC } from "react";
import { Grid } from "@mui/material";
import { ICase } from "app/services/case.service";
import FocusStatusInfo from "app/partials/layout/FocusStatusInfo";
import BudgetInfo from "app/partials/layout/BudgetInfo";
import StartDateInfo from "app/partials/layout/StartDateInfo";
import CommuneInfo from "app/partials/layout/CommuneInfo";
import WorkInfo from "app/partials/layout/WorkInfo";

const Information: FC<ICase> = (props) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <FocusStatusInfo {...props} />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
          <BudgetInfo {...props} />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
          <StartDateInfo {...props} />
        </Grid>

        <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
          <CommuneInfo {...props} />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
          <WorkInfo {...props} />
        </Grid>
      </Grid>
    </>
  );
};

export default Information;
