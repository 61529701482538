import React, { FC, useEffect, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { Grid, IconButton, Theme, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

type VoiceRecorderType = {
  isRecording: boolean;
  setIsRecording: (params: any) => void;
  onVoiceUpload: Function;
};

const useStyle = makeStyles((theme: Theme) => ({
  icon: {
    lineHeight: "0.5em",
    verticalAlign: "-0.15em",
    display: "inline-flex",
  },
}));

const VoiceRecorder: FC<VoiceRecorderType> = ({
  isRecording,
  setIsRecording,
  onVoiceUpload,
}) => {
  const [timer, setTimer] = useState("00:00");
  const [counter, setCounter] = useState(0);
  const { startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder(
    {
      audio: isRecording,
      onStop: (blobUrl, blob) => onVoiceUpload(blobUrl, blob),
    }
  );
  const classes = useStyle();

  const stopTimer = () => {
    setIsRecording(false);
    stopRecording();
    setTimer("00:00");
    setCounter(0);
  };

  useEffect(() => {
    if (isRecording) {
      startRecording();
    }
  }, [isRecording]);

  useEffect(() => {
    let intervalId: any;

    if (isRecording) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setCounter((counter) => counter + 1);

        setTimer(`${computedMinute}:${computedSecond}`);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRecording, counter]);

  return (
    <div>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant={"body1"}>{timer}</Typography>
        <IconButton onClick={stopTimer} size="large">
          <i className={`fi fi-sr-stop ${classes.icon}`} />
        </IconButton>
      </Grid>
    </div>
  );
};

export default VoiceRecorder;
