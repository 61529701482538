import React, { FC } from "react";
import { Card, Typography } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";

interface IProps {
  data?: { spent: number; total: number }[];
}

const TimeColumnChart: FC<IProps> = (props) => {
  let spent: number[] = [];
  let total: number[] = [];

  props.data?.map((d) => {
    spent.push(+d.spent.toFixed(0));
    total.push(d.total);
  });

  const series = [
    {
      name: "Used",
      data: spent,
    },
    {
      name: "Goal",
      data: total,
    },
  ];

  return (
    <Card
      sx={{
        border: "1px solid #E6E6E6",
        borderRadius: 2,
        position: "relative",
      }}
    >
      <Box sx={{ padding: "16px 16px 0 16px" }}>
        <Typography variant="h5">Time</Typography>
      </Box>
      <Box>
        <ReactApexChart
          options={{
            chart: {
              type: "bar",
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            grid: {
              show: true,
              strokeDashArray: 5,
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            states: {
              hover: {
                filter: {
                  type: "none",
                },
              },
            },
            tooltip: {
              enabled: true,
              y: {
                formatter: function (value) {
                  return value + " hours";
                },
              },
              x: {
                show: false,
              },
              marker: {
                show: false,
              },
              followCursor: true,
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                borderRadius: 3,
              },
            },
            stroke: {
              colors: ["transparent"],
              width: 5,
            },
            xaxis: {
              categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              crosshairs: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return value ? value.toFixed(1) + " h" : "0 h";
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: { show: false },
            },
            colors: ["#0D99FF", "#E0F2FF"],
            legend: {
              show: false,
            },
          }}
          series={series}
          type="bar"
          height={215}
        />
      </Box>
    </Card>
  );
};

export default TimeColumnChart;
