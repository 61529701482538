import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { IModule } from "./module.service";

export interface IExport {
  user: string;
  from: string;
  to: string;
}

const INDEX = API_ROUTES.export;

export interface IExportService {
  getCSV(query?: Array<IUrlQuery>): Promise<any | IRestApiError>;
}

const exportService: IExportService = {
  async getCSV(query?: Array<IUrlQuery>) {
    let queriesString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },
};

export { exportService };
