import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { queryToString } from "../utils/http";
import { IMessage } from "../pages/Chat/messenger/messenger";
import { IRoom } from "../pages/Chat/rooms/rooms";

const INDEX = API_ROUTES.chats;
const SHOW = API_ROUTES.chat;
const READ = API_ROUTES.chat_read;
const REMOVE_USER = API_ROUTES.chat_remove_user;
const ARCHIVE = API_ROUTES.chat_archive;

const MESSAGE_INDEX = API_ROUTES.chat_messages;
const MESSAGE_STORE = API_ROUTES.chat_messages;
const MESSAGE_DELETE = API_ROUTES.chat_message_delete;

export interface IChatService {
  getRooms(
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IRoom> | IRestApiError>;
  getMessages(
    room: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IMessage> | IRestApiError>;
  createRoom(
    users: string[],
    message: string,
    messageId?: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IRoom> | IRestApiError>;
  setRead(
    room: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IRoom> | IRestApiError>;
  deleteMessage(
    room: string,
    message: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IMessage> | IRestApiError>;
  archive(
    room: string,
    action: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IRoom> | IRestApiError>;
  deleteRoom(
    room: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<Array<IRoom>> | IRestApiError>;
  removeUser(
    room: string,
    user: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IRoom> | IRestApiError>;
  sendMessage(
    room: string,
    message: string,
    file: File | undefined,
    voice: Blob | undefined,
    uuid: string,
    replyMessage?: string
  ): Promise<IRestApiResource<IMessage> | IRestApiError>;
  // uploadFile(query: Array<IUrlParams>): Promise<IRestApiCollection<any> | IRestApiError>,
}

const chatService: IChatService = {
  async getRooms(
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError> {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(INDEX)}${queryString}`, "get", null);
  },

  async createRoom(
    users: Array<string>,
    message: string,
    messageId?: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IRoom> | IRestApiError> {
    let fData = new FormData();
    for (let i = 0; i < users.length; i++) {
      fData.append("users[]", users[i]);
    }
    fData.append("message", message);

    if (messageId) fData.append("messageId", messageId);

    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(INDEX)}${queryString}`, "post", fData);
  },

  async getMessages(
    room: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError> {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(MESSAGE_INDEX).replace(
        ":_id",
        room.toString()
      )}${queryString}`,
      "get",
      null
    );
  },

  async setRead(
    room: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError> {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(READ).replace(":_id", room.toString())}${queryString}`,
      "get",
      null
    );
  },

  async deleteMessage(
    room: string,
    message: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IMessage> | IRestApiError> {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(MESSAGE_DELETE)
        .replace(":_id", room.toString())
        .replace(":_message", message.toString())}${queryString}`,
      "delete"
    );
  },

  async archive(
    room: string,
    action: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError> {
    let fData = new FormData();

    fData.append("action", action);

    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(ARCHIVE).replace(":_id", room.toString())}${queryString}`,
      "post",
      fData
    );
  },

  async deleteRoom(
    room: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<Array<IRoom>> | IRestApiError> {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(SHOW).replace(":_id", room.toString())}${queryString}`,
      "delete",
      null
    );
  },

  async removeUser(
    room: string,
    user: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError> {
    let queryString = query ? `?${queryToString(query)}` : "";
    let fData = new FormData();

    fData.append("user", user);

    return http(
      `${getRoute(REMOVE_USER).replace(":_id", room.toString())}${queryString}`,
      "post",
      fData
    );
  },

  async sendMessage(
    room,
    message,
    file,
    voice,
    uuid,
    replyMessage
  ): Promise<IRestApiResource<IMessage> | IRestApiError> {
    let fData = new FormData();

    fData.append("message", message);
    fData.append("uuid", uuid);

    if (file) fData.append("file", file);
    if (voice) fData.append("voice", voice, "voice.mp3");

    if (replyMessage) fData.append("replyMessage", replyMessage);

    return http(
      `${getRoute(MESSAGE_STORE).replace(":_id", room.toString())}`,
      "post",
      fData
    );
  },
};

export { chatService };
