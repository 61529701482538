import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";

const MyDropzone = (props: { onDrop: (files: Array<File>) => void }) => {
  const { isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "image/*": [".png", ".jpeg", ".jpg"] },
  });

  const intl = useIntl();

  const onDrop = useCallback((acceptedFiles) => {
    props.onDrop(acceptedFiles);
  }, []);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    justifyContent: "center",
    height: 200,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => onDrop(files),
  });

  const files = acceptedFiles.map((file: File) => {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ pt: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.389 6.52786V1.98619C12.0314 2.22879 12.6149 2.60516 13.1008 3.09036L15.5202 5.51119C16.006 5.99653 16.3827 6.57987 16.6251 7.2223H12.0834C11.8993 7.2223 11.7226 7.14914 11.5924 7.01891C11.4622 6.88867 11.389 6.71204 11.389 6.52786ZM16.9446 8.948V14.8612C16.9435 15.7817 16.5773 16.6643 15.9263 17.3152C15.2754 17.9661 14.3929 18.3323 13.4723 18.3334H6.52789C5.60733 18.3323 4.7248 17.9661 4.07387 17.3152C3.42294 16.6643 3.05677 15.7817 3.05566 14.8612V5.13897C3.05677 4.21842 3.42294 3.33589 4.07387 2.68496C4.7248 2.03403 5.60733 1.66785 6.52789 1.66675L9.6633 1.66675C9.7765 1.66675 9.8883 1.67578 10.0001 1.68341V6.52786C10.0001 7.08039 10.2196 7.6103 10.6103 8.001C11.001 8.3917 11.5309 8.61119 12.0834 8.61119H16.9279C16.9355 8.723 16.9446 8.8348 16.9446 8.948ZM11.389 14.8612C11.389 14.677 11.3158 14.5004 11.1856 14.3701C11.0554 14.2399 10.8787 14.1667 10.6946 14.1667H7.22233C7.03815 14.1667 6.86152 14.2399 6.73128 14.3701C6.60105 14.5004 6.52789 14.677 6.52789 14.8612C6.52789 15.0454 6.60105 15.222 6.73128 15.3522C6.86152 15.4825 7.03815 15.5556 7.22233 15.5556H10.6946C10.8787 15.5556 11.0554 15.4825 11.1856 15.3522C11.3158 15.222 11.389 15.0454 11.389 14.8612ZM13.4723 12.0834C13.4723 11.8992 13.3992 11.7226 13.2689 11.5924C13.1387 11.4621 12.9621 11.389 12.7779 11.389H7.22233C7.03815 11.389 6.86152 11.4621 6.73128 11.5924C6.60105 11.7226 6.52789 11.8992 6.52789 12.0834C6.52789 12.2676 6.60105 12.4442 6.73128 12.5745C6.86152 12.7047 7.03815 12.7779 7.22233 12.7779H12.7779C12.9621 12.7779 13.1387 12.7047 13.2689 12.5745C13.3992 12.4442 13.4723 12.2676 13.4723 12.0834Z"
              fill="#767C91"
            />
          </svg>
          <Typography variant={"h4"} fontWeight={500} fontSize={13}>
            {file.name} - {file.size} bytes
          </Typography>
        </Box>
      </Grid>
    );
  });

  // console.log("path", paths);

  return (
    <section className="container" data-cy-class={"dropzone"}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          {intl.formatMessage({
            id: "STANDARD.DRAG_N_DROP",
            defaultMessage:
              "Drag 'n' drop some files here, or click to select files",
          })}
        </p>
      </div>
      <aside>
        <h4>
          {intl.formatMessage({
            id: "STANDARD.FILES",
            defaultMessage: "Files",
          })}
        </h4>
        {/*<ul>{files}</ul>*/}
      </aside>
    </section>
  );
};

export default MyDropzone;
