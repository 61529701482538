import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { caseService, userService } from "app/services";
import {
  Grid,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
} from "@mui/material";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { IUserPenalty } from "../../../services/user.service";
import { useForm } from "../../../hooks/useForm";

interface IFormProps {
  user: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  files: Array<string>;
};

const ShowUserPenalty = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [penalty, setPenalty] = useState<IUserPenalty>();

  useEffect(() => {
    userService
      .getPenalty(props.user, props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: penalty } = data;
        setPenalty(penalty);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props.user, props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    userService
      .updatePenalty(props.user, props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        props.setDialogue(false);
        props.onDone();
        // form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          pr: 2,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"CASE.PENALTY.SHOW"}
              defaultMessage={"CASE.PENALTY.SHOW"}
            />
          </Typography>
          {/*<Button*/}
          {/*  autoFocus*/}
          {/*  color="inherit"*/}
          {/*  type={"submit"}*/}
          {/*  form={"penalty_form"}*/}
          {/*  sx={{*/}
          {/*    backgroundColor: "#0D99FF",*/}
          {/*    borderRadius: 2,*/}
          {/*    color: "#ffffff",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <FormattedMessage*/}
          {/*    id={"DIALOGUE.SAVE"}*/}
          {/*    defaultMessage={"DIALOGUE.SAVE"}*/}
          {/*  />*/}
          {/*</Button>*/}
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id={"penalty_form"}
          // onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant={"h4"}
                    fontWeight={500}
                    fontSize={13}
                    sx={{ mb: 1 }}
                  >
                    {intl.formatMessage({ id: "STANDARD.USER" })}:
                  </Typography>
                  <Typography
                    variant={"h4"}
                    fontWeight={400}
                    fontSize={13}
                    sx={{ mb: 1, ml: 1 }}
                  >
                    {penalty ? (
                      penalty.created_by?.firstname +
                      " " +
                      penalty.created_by?.lastname
                    ) : (
                      <Skeleton width={"50%"} />
                    )}
                  </Typography>
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"user"}
                    label={intl.formatMessage({ id: "STANDARD.USER" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      id={"penalty_option1"}
                      checked={Boolean(penalty?.penalty_option1)}
                    />
                  }
                  label={intl.formatMessage({ id: "USER.PENALTYOPTION.1" })}
                  name={"penalty_option1"}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Penalty option 1"
                    name={"penalty_option1"}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      id={"penalty_option2"}
                      checked={Boolean(penalty?.penalty_option2)}
                    />
                  }
                  label={intl.formatMessage({ id: "USER.PENALTYOPTION.2" })}
                  name={"penalty_option2"}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Penalty option 2"
                    name={"penalty_option2"}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      id={"penalty_option3"}
                      checked={Boolean(penalty?.penalty_option3)}
                    />
                  }
                  label={intl.formatMessage({ id: "USER.PENALTYOPTION.3" })}
                  name={"penalty_option3"}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Penalty option 3"
                    name={"penalty_option3"}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      id={"penalty_option4"}
                      checked={Boolean(penalty?.penalty_option4)}
                    />
                  }
                  label={intl.formatMessage({ id: "USER.PENALTYOPTION.4" })}
                  name={"penalty_option4"}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Penalty option 4"
                    name={"penalty_option4"}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowUserPenalty;
