import React, { ChangeEvent, ReactNode, UIEventHandler, useRef } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  SelectChangeEvent
} from "@mui/material";
import { Tooltip } from "@mui/material";

type ComponentProps = {
  name: string,
  label: string,
  formId?: string,
  value: number | string | undefined,
  disabled?: boolean,
  disableText?: string,
  error?: string,
  onChange: (event: SelectChangeEvent<any>, child: ReactNode) => void,
  options?: Array<{ id: string, title: string, 'data-cy'?: string } | undefined>
  inputRef?: React.Ref<any>,
  onEndReached?: () => void
}

const Select = (props: ComponentProps) => {

  const { name, label, value, error = null, onChange, disableText,
    options, inputRef, formId, disabled, onEndReached } = props;

  const listRef = useRef<HTMLDivElement>(null);

  return (
    <FormControl variant="outlined"
                 id={formId}
                 fullWidth
                 disabled={disabled ?? false}
                 {...(error && { error: true })}>
      <InputLabel>{label}</InputLabel>
      <Tooltip title={disabled ? (disableText ?? "") : ""}>
        <MuiSelect
          inputRef={inputRef}
          fullWidth
          label={label}
          name={name}
          value={value ?? ""}
          MenuProps={{
            PaperProps: {
              ref: listRef
            },
            onScroll: () => {
              if (listRef && listRef.current && onEndReached) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                if (scrollHeight - (scrollTop + clientHeight) < 10 ) {
                  return onEndReached();
                }
              }
            }
          }}
          onChange={onChange}>
          <MenuItem value="">None</MenuItem>
          {
            options && options.map(
              item => (item && <MenuItem key={item.id} data-cy={item['data-cy']} value={item.id}>{item.title}</MenuItem>)
            )
          }
        </MuiSelect>
      </Tooltip>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
