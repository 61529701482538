import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../../partials/layout/DataTable";
import { IconButton } from "@mui/material";
import { roleService } from "../../../services";
import { useTable } from "../../../hooks/useTable";
import { GridTable } from "../../../partials/layout/GridTable";
import RoleStore from "./store";
import { IRole } from "../../../interfaces";
import ShowRole from "./show";
import { useIntl } from "react-intl";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import EditIcon from "../../../partials/icons/EditIcon";
import DeleteIcon from "../../../partials/icons/DeleteIcon";
import PermissionIcon from "../../../partials/icons/PermissionIcon";
import { hasPermission } from "../../../permission/PermissionsGate";
import { usePermissions } from "../../../hooks/useRole";
import ShowPermission from "./permission";

const Role = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  const { data, refresh, remove, xhrLoading, updateQuery } = useTable<IRole>({
    fetch: roleService.getAll,
    delete: roleService.delete,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      headerName: "ID",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      minWidth: 200,
    },
    {
      field: "title",
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      valueGetter: (params) => {
        return params.row?.title && params.row?.title[intl.locale];
      },
      minWidth: 250,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => permission(params.row)}>
              <PermissionIcon />
            </IconButton>
            <IconButton
              onClick={() => show(params.row?._id)}
              data-cy-class={"role_show"}
              data-cy-id={params.row?._id}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleClick(params.row?._id)}
              data-cy-class={"role_delete"}
              data-cy-id={params.row?._id}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [roleId, setRoleId] = useState<string | null>(null);
  const [role, setRole] = useState<IRole | null>(null);

  const show = (_id: string) => {
    setRoleId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const [rolePermissionId, setRolePermissionId] = useState<string | null>(null);

  const permission = (role: IRole) => {
    setRolePermissionId(role.permissions._id);
    setRole(role);
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete role?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this role?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      {roleId ? (
        <ShowRole
          _id={roleId}
          open={Boolean(roleId)}
          setDialogue={() => setRoleId(null)}
          onDone={refresh}
        />
      ) : (
        <></>
      )}
      {rolePermissionId ? (
        <ShowPermission
          _id={rolePermissionId}
          role={role ?? null}
          open={Boolean(rolePermissionId)}
          setDialogue={() => setRolePermissionId(null)}
          onDone={refresh}
        />
      ) : (
        <></>
      )}
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
        search={(queries) => updateQuery(queries)}
        filterToolbar={{
          actionPanel:
            permissions && hasPermission(permissions, "settings", "create")
              ? (props: { open: boolean; close: () => void }) => {
                  return (
                    <RoleStore
                      open={props.open}
                      setDialogue={props.close}
                      onDone={refresh}
                    />
                  );
                }
              : undefined,
        }}
      />
    </>
  );
};

export default Role;
