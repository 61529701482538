import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { FormattedMessage, useIntl } from "react-intl";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { moduleService, settingService } from "../../services";
import Snackbar from "../../widgets/Snackbar";
import { ISetting } from "../../services/setting.service";
import { makeStyles } from "@material-ui/core";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CheckIcon from "@mui/icons-material/Check";
import ShowSettingDialogue2, {
  FormStateValues,
} from "../../partials/dialogues/show/Settings/settingDialogue2";
import * as setting from "../../store/ducks/setting.duck";
import Controls from "../../widgets/uncontrolled";
import SettingDialogue2 from "../../partials/dialogues/store/Settings/settingDialogue2";

interface IFormProps {
  _id?: string;
  open: boolean;
  labels?: string[];
  onDone?: Function;
  onClose?: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
  },
}));

const LabelsModal: FC<IFormProps> = (props) => {
  const [labels, setLabels] = useState<ISetting[]>([]);
  const [label, setLabel] = useState<string | null>(null);
  const [keyword, setKeyword] = useState<string | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>(
    props.labels ?? []
  );
  const [addLabel, setAddLabel] = useState<boolean>(false);
  const [editIcon, setEditIcon] = useState<boolean>(false);
  const intl = useIntl();
  const classes = useStyles();

  const handleClose = () => {
    props.setDialogue(false);
    props.onClose && props.onClose(selectedLabels);
  };

  const getLabels = () => {
    settingService
      .get([
        {
          name: "type",
          value: "labels",
        },
        {
          name: "lang",
          value: intl.locale,
        },
        {
          name: "keyword",
          value: keyword ?? "",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: labels } = data;
        setLabels(labels);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (props.open) getLabels();
  }, [props.open]);

  useEffect(() => {
    if (keyword) getLabels();
  }, [keyword]);

  useEffect(() => {
    props.onClose && props.onClose(selectedLabels);
  }, [selectedLabels]);

  const dispatch = useDispatch();
  const updateSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Update, payload: s }),
    [dispatch]
  );

  const handleUpdateLabel = (
    validated: FormStateValues,
    callback: () => void
  ) => {
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    if (label) {
      settingService
        .update(label, validated, [
          {
            name: "type",
            value: "labels",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

          let { data: setting } = data;
          updateSetting(setting);

          setTimeout(() => {
            setLabel(null);
            getLabels();
            callback();
          }, 1000);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  };
  const timeOut = useRef<any>();

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setKeyword(value);
    }, 500);
  };

  const addSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Add, payload: s }),
    [dispatch]
  );

  const handleSaveLabel = (
    validated: FormStateValues,
    callback: () => void
  ) => {
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    settingService
      .add(validated, [
        {
          name: "type",
          value: "labels",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        let { data: setting } = data;
        addSetting(setting);

        setTimeout(() => {
          setAddLabel(false);
          callback();
        }, 1000);

        getLabels();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {label && (
        <ShowSettingDialogue2
          _id={label}
          open={Boolean(label)}
          setDialogue={() => setLabel(null)}
          section={intl.formatMessage({
            id: "SETTINGS.LABELS",
            defaultMessage: "Labels",
          })}
          settingValue={"labels"}
          save={handleUpdateLabel}
        />
      )}
      {addLabel && (
        <SettingDialogue2
          open={addLabel}
          setDialogue={() => setAddLabel(false)}
          section={intl.formatMessage({
            id: "SETTINGS.LABELS",
            defaultMessage: "Labels",
          })}
          save={handleSaveLabel}
        />
      )}
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"MENU.LABELS"}
                  defaultMessage={"Labels"}
                />
              </Typography>
              <Button
                onClick={() => handleClose()}
                autoFocus
                color="inherit"
                type={"button"}
                sx={{
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <CloseOutlinedIcon color={"error"} />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <Grid container spacing={1}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                  marginBottom: "15px",
                }}
              >
                <Box sx={{ width: "90%" }}>
                  <Controls.Input
                    name={"search"}
                    label={intl.formatMessage({
                      id: "STANDARD.SEARCH",
                      defaultMessage: "Search",
                    })}
                    onChange={(e) => {
                      let value = e.target.value;

                      sendQuery(value);
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "10%",
                    display: "flex",
                    height: 51,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      backgroundColor: "#556EE6",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                    }}
                    onClick={() => setAddLabel(true)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75V9.25H4.75C4.33579 9.25 4 9.58579 4 10C4 10.4142 4.33579 10.75 4.75 10.75H9.25V15.25C9.25 15.6642 9.58579 16 10 16C10.4142 16 10.75 15.6642 10.75 15.25V10.75H15.25C15.6642 10.75 16 10.4142 16 10C16 9.58579 15.6642 9.25 15.25 9.25H10.75V4.75Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                </Box>
              </Box>
              {labels.map((label) => {
                const exists = selectedLabels.find(
                  (selectedLabel) => selectedLabel === label._id
                );
                return (
                  <Grid
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    mb={1}
                    container
                    onMouseLeave={() => setEditIcon(false)}
                    onMouseEnter={() => setEditIcon(true)}
                  >
                    <Grid
                      item
                      sm={editIcon ? 11 : 12}
                      xs={editIcon ? 11 : 12}
                      md={editIcon ? 11 : 12}
                      lg={editIcon ? 11 : 12}
                      xl={editIcon ? 11 : 12}
                      sx={{
                        backgroundColor: label.color,
                        height: 40,
                        borderRadius: editIcon ? "8px 0 0 8px" : "8px",
                        display: "flex",
                        alignItems: "center",
                        padding: "0 20px",
                        cursor: "pointer",
                        filter: "brightness(100%)",
                        width: editIcon ? "90%" : "100%",
                        "&:hover": {
                          filter: "brightness(130%)",
                          width: "90%",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={() => {
                          if (exists) {
                            setSelectedLabels(
                              selectedLabels.filter((x) => x !== label._id)
                            );
                          } else {
                            setSelectedLabels(
                              selectedLabels.concat([label._id])
                            );
                          }
                        }}
                      >
                        <Typography variant={"body1"} sx={{ color: "#fff" }}>
                          {label.title[intl.locale]}
                        </Typography>
                        {exists && <CheckIcon sx={{ color: "#fff" }} />}
                      </Box>
                    </Grid>
                    {editIcon && (
                      <Grid
                        item
                        sm={1}
                        xs={1}
                        md={1}
                        lg={1}
                        xl={1}
                        sx={{
                          backgroundColor: label.color,
                          height: 40,
                          borderRadius: "0 8px 8px 0",
                          borderLeft: "1px solid #fff",
                          padding: "0 20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          filter: "brightness(100%)",
                          width: "10%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() => setLabel(label._id)}
                        >
                          <BorderColorIcon sx={{ color: "#fff" }} />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default LabelsModal;
