import React from "react";

const PermissionIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1667 2H7.83342C5.53591 2 3.66675 3.86917 3.66675 6.16667V17.8333C3.66675 20.1308 5.53591 22 7.83342 22H16.1667C18.4642 22 20.3334 20.1308 20.3334 17.8333V6.16667C20.3334 3.86917 18.4642 2 16.1667 2ZM10.3334 17.8333C10.3334 18.2933 9.96008 18.6667 9.50008 18.6667H7.83342C7.37341 18.6667 7.00008 18.2933 7.00008 17.8333V16.1667C7.00008 15.7067 7.37341 15.3333 7.83342 15.3333H9.50008C9.96008 15.3333 10.3334 15.7067 10.3334 16.1667V17.8333ZM10.3334 12.8333C10.3334 13.2933 9.96008 13.6667 9.50008 13.6667H7.83342C7.37341 13.6667 7.00008 13.2933 7.00008 12.8333V11.1667C7.00008 10.7067 7.37341 10.3333 7.83342 10.3333H9.50008C9.96008 10.3333 10.3334 10.7067 10.3334 11.1667V12.8333ZM10.3334 7.83333C10.3334 8.29333 9.96008 8.66667 9.50008 8.66667H7.83342C7.37341 8.66667 7.00008 8.29333 7.00008 7.83333V6.16667C7.00008 5.70667 7.37341 5.33333 7.83342 5.33333H9.50008C9.96008 5.33333 10.3334 5.70667 10.3334 6.16667V7.83333ZM16.1667 17.8333H12.8334C11.7434 17.8283 11.7442 16.1717 12.8334 16.1667H16.1667C17.2567 16.1717 17.2559 17.8283 16.1667 17.8333ZM16.1667 12.8333H12.8334C11.7434 12.8283 11.7442 11.1717 12.8334 11.1667H16.1667C17.2567 11.1717 17.2559 12.8283 16.1667 12.8333ZM16.1667 7.83333H12.8334C11.7434 7.82833 11.7442 6.17167 12.8334 6.16667H16.1667C17.2567 6.17167 17.2559 7.82833 16.1667 7.83333Z"
        fill="#0D99FF"
      />
    </svg>
  );
};

export default PermissionIcon;
