import React, { FC } from "react";
import { Avatar, Card, Typography } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import { Box } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../_metronic";
import ReactApexChart from "react-apexcharts";
import ChartCard from "./ChartCard";

interface IProps {}

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const Activities: FC<IProps> = () => {
  return (
    <ChartCard
      title={"Recent Activities"}
      ratio={"100%"}
      body={
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          style={{
            position: "relative",
            overflow: "hidden",
            width: "100%",
            padding: "0 4px",
          }}
        >
          <Box className=" kt-timeline-v2-forcase">
            <Box className="kt-timeline-v2__items-forcase">
              <Box className="kt-timeline-v2__item">
                <Box className="kt-timeline-v2__item-cricle">
                  <Avatar
                    variant="circular"
                    sx={{ width: 24, height: 24 }}
                    src={toAbsoluteUrl("/media/users/100_4.jpg")}
                    alt="user firstname"
                  ></Avatar>
                </Box>
                <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                  <Typography variant={"body1"}>
                    <b style={{ color: "#556EE6" }}>John Smith</b> changes case
                    settings
                  </Typography>
                  <Typography>Today 16:23</Typography>
                </Box>
              </Box>
              <Box className="kt-timeline-v2__item">
                <Box className="kt-timeline-v2__item-cricle">
                  <Avatar
                    variant="circular"
                    sx={{ width: 24, height: 24 }}
                    alt="user firstname"
                  ></Avatar>
                </Box>
                <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                  <Typography variant={"body1"}>
                    <b style={{ color: "#556EE6" }}>Hikmet Aliyev</b> changes
                    case settings
                  </Typography>
                  <Typography>29/09/2022 16:23</Typography>
                </Box>
                <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20" />
              </Box>
              <Box className="kt-timeline-v2__item">
                <Box className="kt-timeline-v2__item-cricle">
                  <Avatar
                    variant="circular"
                    sx={{ width: 24, height: 24 }}
                    src={toAbsoluteUrl("/media/users/100_4.jpg")}
                    alt="user firstname"
                  ></Avatar>
                </Box>
                <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                  <Typography variant={"body1"}>
                    <b style={{ color: "#556EE6" }}>John Smith</b> changes case
                    settings
                  </Typography>
                  <Typography>29/09/2022 16:23</Typography>
                </Box>
                <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20" />
              </Box>
              <Box className="kt-timeline-v2__item">
                <Box className="kt-timeline-v2__item-cricle">
                  <Avatar
                    variant="circular"
                    sx={{ width: 24, height: 24 }}
                    src={toAbsoluteUrl("/media/users/100_4.jpg")}
                    alt="user firstname"
                  ></Avatar>
                </Box>
                <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                  <Typography variant={"body1"}>
                    <b style={{ color: "#556EE6" }}>John Smith</b> changes case
                    settings
                  </Typography>
                  <Typography>29/09/2022 16:23</Typography>
                </Box>
                <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20" />
              </Box>
            </Box>
          </Box>
        </PerfectScrollbar>
      }
    />
  );
};

export default Activities;
