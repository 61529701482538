// @ts-nocheck
import React, { useEffect, useReducer } from "react";
import { calculateDiscount } from "./items";

const Totals = ({ items, fines, symbol, total }) => {
  const [state, setState] = useReducer(
    (prevState, newState) => {
      return { ...prevState, ...newState };
    },
    {
      totalExTax: 0,
      totalTax: 0,
      totalToPay: 0,
      totalDiscount: 0,
    }
  );

  useEffect(() => {
    total(state.totalToPay);
  }, [state]);

  useEffect(() => {
    calculateTotals(items, fines);
  }, [JSON.stringify(items), JSON.stringify(fines)]);

  const calculateTotals = (items: any, fines: any) => {
    let totalExTax = 0;
    let totalTax = 0;
    let totalDiscount = 0;
    let totalToPay = 0;

    if (items.length) {
      items.map((row: any) => {
        totalExTax += parseFloat(String(row.price_per_unit * row.quantity));
        totalTax += parseFloat(row.tax);
        totalToPay += parseFloat(row.total_amount);
        totalDiscount += calculateDiscount(row);
      });
    }

    if (fines.length) {
      fines.map((fine: any) => {
        let currDiss = calculateDiscount(fine);
        totalDiscount += currDiss;
        totalToPay += parseFloat(fine.price_per_unit) - currDiss;
      });
    }

    setState({
      totalExTax: parseFloat(String(totalExTax)).toFixed(2),
      totalTax: parseFloat(String(totalTax)).toFixed(2),
      totalToPay: parseFloat(String(totalToPay)).toFixed(2),
      totalDiscount: parseFloat(String(totalDiscount)).toFixed(2),
    });
  };

  const currencySymbol = symbol;
  return (
    <div className="totals-table-container">
      <div className="row">
        <div className="offset-md-8" />
        <div className="col-md-4 p-0">
          <table className="table">
            <tbody>
              <tr>
                <td>TotalExclTax</td>
                <td>
                  {state.totalExTax} {currencySymbol}
                </td>
              </tr>

              {state.totalDiscount > 0 && (
                <tr>
                  <td className="font-weight-bold">TotalDiscount</td>
                  <td className="font-weight-bold">
                    -{state.totalDiscount} {currencySymbol}
                  </td>
                </tr>
              )}

              <tr>
                <td>TotalTax</td>
                <td>
                  {state.totalTax} {currencySymbol}
                </td>
              </tr>

              {fines.length > 0 &&
                fines.map((item: any, key: any) => (
                  <tr key={key}>
                    <td>{item.article_description || "---"}</td>
                    <td>
                      {item.price_per_unit} {currencySymbol}
                    </td>
                  </tr>
                ))}

              <tr>
                <td className="font-weight-bold">TotalToPay</td>
                <td className="font-weight-bold">
                  {state.totalToPay} {currencySymbol}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Totals;
