import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "../../../widgets/uncontrolled";
import { IFormUsersValues } from "./parts/users";
import Snackbar from "../../../widgets/Snackbar";
import { caseService } from "../../../services";
import { IUser } from "../../../interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";

interface IFormProps {
  _id: string;
  todoId: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  description: string;
  users: Array<IUser>;
  documents?: Array<File>;
} & IFormUsersValues;

const initValues = {
  title: "",
  description: "",
  users: [],
  documents: [],
} as FormStateValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const CaseTodoNoteStore: FC<IFormProps> = (props) => {
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const intl = useIntl();
  const classes = useStyles();
  const [documents, setDocuments] = useState<Array<File>>([]);

  useEffect(() => {
    form.values.documents = documents;
  }, [documents]);

  const handleClose = () => {
    props.setDialogue(false);
    setDocuments([]);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .postTodoNote(props._id, props.todoId, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.NOTE.ADD"}
                  defaultMessage={"CASE.NOTE.ADD"}
                />
              </Typography>
              <Button
                form={"note_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"note_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.title = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Input
                      rows={5}
                      name={"description"}
                      label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.description = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        rows={5}
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {intl.formatMessage({
                          id: "STANDARD.FILES",
                          defaultMessage: "Files",
                        })}
                      </Typography>
                      <Controls.MyDropzone
                        onDrop={(files) => {
                          setDocuments((documents) => {
                            return documents.concat(files);
                          });
                        }}
                      />
                      <List dense={false}>
                        {documents?.map((doc) => {
                          return (
                            <ListItem sx={{ p: "0px!important" }}>
                              <ListItemButton
                                component={"a"}
                                sx={{ p: "0px!important" }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.388 6.5281V1.98644C12.0304 2.22903 12.6139 2.6054 13.0998 3.0906L15.5193 5.51144C16.005 5.99677 16.3817 6.58011 16.6241 7.22255H12.0825C11.8983 7.22255 11.7217 7.14938 11.5914 7.01915C11.4612 6.88892 11.388 6.71228 11.388 6.5281ZM16.9436 8.94824V14.8614C16.9425 15.782 16.5763 16.6645 15.9254 17.3155C15.2744 17.9664 14.3919 18.3326 13.4714 18.3337H6.52691C5.60636 18.3326 4.72382 17.9664 4.0729 17.3155C3.42197 16.6645 3.05579 15.782 3.05469 14.8614V5.13921C3.05579 4.21866 3.42197 3.33613 4.0729 2.6852C4.72382 2.03427 5.60636 1.66809 6.52691 1.66699L9.66233 1.66699C9.77552 1.66699 9.88733 1.67602 9.99913 1.68366V6.5281C9.99913 7.08064 10.2186 7.61054 10.6093 8.00124C11 8.39194 11.5299 8.61144 12.0825 8.61144H16.9269C16.9345 8.72324 16.9436 8.83505 16.9436 8.94824ZM11.388 14.8614C11.388 14.6773 11.3149 14.5006 11.1846 14.3704C11.0544 14.2402 10.8778 14.167 10.6936 14.167H7.22135C7.03718 14.167 6.86054 14.2402 6.73031 14.3704C6.60007 14.5006 6.52691 14.6773 6.52691 14.8614C6.52691 15.0456 6.60007 15.2223 6.73031 15.3525C6.86054 15.4827 7.03718 15.5559 7.22135 15.5559H10.6936C10.8778 15.5559 11.0544 15.4827 11.1846 15.3525C11.3149 15.2223 11.388 15.0456 11.388 14.8614ZM13.4714 12.0837C13.4714 11.8995 13.3982 11.7228 13.268 11.5926C13.1377 11.4624 12.9611 11.3892 12.7769 11.3892H7.22135C7.03718 11.3892 6.86054 11.4624 6.73031 11.5926C6.60007 11.7228 6.52691 11.8995 6.52691 12.0837C6.52691 12.2678 6.60007 12.4445 6.73031 12.5747C6.86054 12.7049 7.03718 12.7781 7.22135 12.7781H12.7769C12.9611 12.7781 13.1377 12.7049 13.268 12.5747C13.3982 12.4445 13.4714 12.2678 13.4714 12.0837Z"
                                    fill="#767C91"
                                  />
                                </svg>
                                <ListItemText
                                  primary={doc.name}
                                  secondary={false}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        rows={5}
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default CaseTodoNoteStore;
