import { Grid, TextField } from "@mui/material";
import React, { FC } from "react";
import { useIntl } from "react-intl";

type SearchType = {
  value?: string;
  onChange: (text: string) => void;
};

const SearchInput: FC<SearchType> = (props) => {
  const { onChange, value } = props;
  const intl = useIntl();

  return (
    <TextField
      fullWidth={true}
      value={value}
      variant="standard"
      onChange={(e) => onChange(e.target.value)}
      placeholder={intl.formatMessage({ id: "STANDARD.SEARCH" })}
      InputProps={{
        startAdornment: (
          <Grid
            sx={{
              ml: 2,
              mr: 1.5,
              marginTop: "3px",
            }}
          >
            <i
              className="fi fi-bs-search"
              style={{
                color: "#767c91",
              }}
            ></i>
          </Grid>
        ),
      }}
      sx={{
        width: "100%",
        backgroundColor: "#E1E6FB",
        opacity: "0.5",
        borderRadius: 36,
        height: 36,
        "& .MuiInput-underline:before": {
          content: "unset",
        },
        "& .MuiInput-underline:after": {
          content: "unset",
        },
        "& .MuiInput-root": {
          height: 36,
          border: "1px solid #9FA5BB",
          borderRadius: 36,
        },
      }}
    />
  );
};

export default SearchInput;
