import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import Controls from "app/widgets/uncontrolled";
import React, { FC, memo } from "react";
import { IRole } from "app/interfaces";
import { ICommune } from "../../../../services/comunne.service";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { DatePicker, Space } from "antd";
import { ICase } from "../../../../services/case.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

export interface IFormCommuneValues {
  commune?: string;
  expire_date_commune?: string;
  notice_commune?: string;
}

interface IFormUsersProps {
  roles: Array<IRole>;
  case?: ICase;
  communes: Array<ICommune>;
  communeData?: string | null;
  communeExpireData?: number | null;
  touched: boolean;
  handleChange: any;
  values: IFormCommuneValues;
}

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const Users: FC<IFormUsersProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  const { notice_commune } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleCommuneSelection = (e: SelectChangeEvent<any>): void => {
    let commune = props.communes.find((c) => c._id === e.target.value);

    if (commune) props.values.commune = commune._id;
  };

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.INFORMATION.COMMUNE.COMMUNE"}
              defaultMessage={"CASE.INFORMATION.COMMUNE.COMMUNE"}
            />
          </Typography>
          <Controls.Select
            onChange={(e) => handleCommuneSelection(e)}
            label={""}
            formId={"commune_pick"}
            name={"commune"}
            defaultValue={props.case?.commune?._id ?? ""}
            options={props.communes.map((c) => {
              return {
                id: c._id,
                title: c.name,
              };
            })}
          />
        </Box>
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <Box
          className={classes.input}
          sx={{
            mt: 2,
            "& .MuiFormControl-root": {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  "& legend": {
                    display: "none!important",
                  },
                },
              },
            },
          }}
        >
          <Typography variant={"body1"} sx={{ mb: 1 }}>
            <FormattedMessage
              id={"CASE.FORM.EXPIREDATECOMMUNE"}
              defaultMessage={"CASE.FORM.EXPIREDATECOMMUNE"}
            />
          </Typography>
          <Space className={classes.antSpace} direction="vertical" size={12}>
            <DatePicker
              className={classes.datePicker}
              dropdownClassName={classes.antDropdown}
              onChange={(value, dateString) => {
                props.values.expire_date_commune = dateString;
              }}
              defaultValue={
                props.case?.expire_date_commune
                  ? moment(props.case?.expire_date_commune, "X").local()
                  : undefined
              }
            />
          </Space>
        </Box>
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <Box className={classes.input}>
          <Typography variant={"body1"} sx={{ mt: 2 }}>
            <FormattedMessage
              id={"CASE.INFORMATION.COMMUNE.NOTICE_CONTRACT"}
              defaultMessage={"CASE.INFORMATION.COMMUNE.NOTICE_CONTRACT"}
            />
          </Typography>
          <Controls.Select
            onChange={(e) => {
              props.values.notice_commune = e.target.value;
            }}
            label={""}
            formId={"notice_pick"}
            name={"notice_contract"}
            defaultValue={props.case?.notice_commune?._id ?? ""}
            options={notice_commune?.map((c) => {
              return {
                id: c._id,
                title: c.title[intl.locale],
              };
            })}
          />
        </Box>
      </Grid>
    </>
  );
};

export default memo(Users);
