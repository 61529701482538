import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Snackbar from "app/widgets/Snackbar";
import { caseService, todoService } from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { ITodo } from "app/services/todo.service";
import Controls from "app/widgets/uncontrolled";
import { PermissionsGate } from "app/permission/PermissionsGate";
import { useForm } from "app/hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { ICase } from "app/services/case.service";
import {
  Button,
  InputAdornment,
  Skeleton,
  Box,
  Typography,
  Toolbar,
} from "@mui/material";
import { Grid } from "@mui/material";
import Users from "app/pages/Cases/store/parts/users";
import moment from "moment";
import { useParams } from "react-router";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";
import UsersGroupedSelect from "../../../partials/layout/UsersGroupedSelect";

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
  _todo?: ITodo | null;
  save?: Function;
}

export type FormStateValues = {
  type: string;
  title: string | null;
  category: string;
  description: string;
  case: string;
  lead: string;
  users: Array<string>;
  date: string;
  start_time: string;
  deadline_date: string;
  end_time: string;
  status: string;
};

const useStyles = makeStyles(() => ({
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const ShowTodoPage: FC<FormProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const classes = useStyles();
  const [todo, setTodo] = useState<ITodo | null>(null);
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { todo_types } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [cases, setCases] = useState<Array<ICase>>();

  useEffect(() => {
    caseService
      .getAll()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  useEffect(() => {
    todoService
      .getOne(id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: todo } = data;
        setTodo(todo);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [id]);

  const handleSave = () => {
    let validated = form.validate();
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    todoService
      .update(id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        setTimeout(() => {}, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (todo) {
      form.values.type = todo?.type?._id;
      form.values.title = todo?.title;
      form.values.category = todo?.category?._id;
      form.values.description = todo?.description;
      form.values.case = todo?.case?._id;
      form.values.lead = todo?.lead?._id;
      form.values.users = todo?.users?.map((user) => user._id);
      form.values.date = moment
        .unix(todo?.date)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      form.values.deadline_date = moment
        .unix(todo?.deadline_date)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      form.values.status = todo?.status?._id;
    }
  }, [todo]);

  return (
    <PermissionsGate section={"todos"} scope={"read"}>
      <div
        style={{ padding: "20px" }}
        className="kt-portlet kt-portlet--bordered"
      >
        <form
          id={"todo_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"DIALOGUE.EDIT"}
                  defaultMessage={"DIALOGUE.EDIT"}
                />
              </Typography>
              <Button
                color="inherit"
                data-cy-class={"todo_update"}
                data-cy-todo-id={props._todo?._id}
                data-cy-case-id={props._id}
                type={"submit"}
                form={"todo_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <Grid container spacing={2} sx={{ mt: "20px" }}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {loaded && todo ? (
                <Controls.Input
                  name={"title"}
                  label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                  defaultValue={todo?.title}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.title = event.target.value;

                    event.preventDefault();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">EN</InputAdornment>
                    ),
                    // @ts-ignore
                    "data-cy": "todo_title_en",
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"title"}
                    label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: "10px" }}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              {loaded && todo ? (
                <Controls.Select
                  name={"category"}
                  formId={"category_pick"}
                  options={todo_categories?.map((t) => {
                    return {
                      id: t._id.toString(),
                      title: t.title[intl.locale],
                    };
                  })}
                  defaultValue={todo?.category?._id}
                  label={intl.formatMessage({ id: "STANDARD.CATEGORY" })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.category = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Select
                    name={"category"}
                    options={[]}
                    label={intl.formatMessage({ id: "STANDARD.CATEGORY" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              {loaded && todo ? (
                <Controls.Select
                  name={"case"}
                  formId={"case_pick"}
                  options={
                    cases &&
                    cases.map((_case) => {
                      return {
                        id: _case._id.toString(),
                        title: `${intl.formatMessage({
                          id: "CASE.INFORMATION.CASE",
                        })}: ${_case._id}; Users: ${_case.users.map(
                          (u) => u.firstname
                        )}`,
                      };
                    })
                  }
                  defaultValue={todo?.case?._id}
                  label={intl.formatMessage({ id: "CASE.INFORMATION.CASE" })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.case = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Select
                    name={"case"}
                    options={[]}
                    label={intl.formatMessage({ id: "CASE.INFORMATION.CASE" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
          <Grid sx={{ mt: "20px" }} xl={6} lg={6} md={6} sm={6} xs={6}>
            {loaded && todo ? (
              <UsersGroupedSelect
                labelSx={{ top: "-5px" }}
                sx={{
                  height: 40,
                  backgroundColor: "#fff",
                  border: "1px solid #E6E6E6",
                  borderRadius: 2,
                  m: () => ({}),
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "unset",
                  },
                }}
                label={intl.formatMessage({ id: "CASE.TODO.LEAD" })}
                name={"users"}
                onChange={(value: string) => {
                  form.values.lead = value;
                }}
                query={[
                  {
                    name: "pagination",
                    value: "0",
                  },
                ]}
              />
            ) : (
              <Skeleton width={"100%"}>
                <Controls.Select
                  name={"lead"}
                  options={[]}
                  label={intl.formatMessage({ id: "CASE.TODO.LEAD" })}
                  onChange={() => {}}
                />
              </Skeleton>
            )}
          </Grid>
          {loaded && todo ? (
            <Grid mt={2}>
              <Users
                rows={todo?.users?.map((u, i) => ({
                  rowIndex: i,
                  role: u.role,
                  user: u,
                }))}
                handleChange={form.handleChange}
                values={form.values}
              />
            </Grid>
          ) : (
            <>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"role"}
                      label={intl.formatMessage({ id: "STANDARD.ROLE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"user"}
                      label={intl.formatMessage({ id: "STANDARD.USER" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                </Grid>
              </Grid>
            </>
          )}
          <Grid sx={{ mt: "20px" }}>
            {loaded && todo ? (
              <Controls.Input
                name={"description"}
                label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                defaultValue={todo?.description}
                onChange={(event) => {
                  if (!event.target.value) return;

                  form.values.description = event.target.value;
                }}
                InputProps={{
                  // @ts-ignore
                  "data-cy": "todo_description",
                }}
              />
            ) : (
              <Skeleton width={"100%"}>
                <Controls.Input
                  name={"description"}
                  label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                  onChange={() => {}}
                />
              </Skeleton>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ mt: "10px" }}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {loaded && todo ? (
                <Space
                  className={classes.antSpace}
                  direction="vertical"
                  size={12}
                >
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    className={classes.datePicker}
                    dropdownClassName={classes.antDropdown}
                    onChange={(value, dateString) => {
                      form.values.date = dateString;
                    }}
                    defaultValue={moment(todo?.date, "X").local()}
                  />
                </Space>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"description"}
                    label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {loaded && todo ? (
                <Space
                  className={classes.antSpace}
                  direction="vertical"
                  size={12}
                >
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    className={classes.datePicker}
                    dropdownClassName={classes.antDropdown}
                    onChange={(value, dateString) => {
                      form.values.date = dateString;
                    }}
                    defaultValue={moment(todo?.date, "X").local()}
                  />
                </Space>
              ) : (
                <Skeleton width={"100%"}>
                  <Space
                    className={classes.antSpace}
                    direction="vertical"
                    size={12}
                  >
                    <DatePicker />
                  </Space>
                </Skeleton>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: "10px" }}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {loaded && todo ? (
                <Controls.Select
                  name={"type"}
                  formId={"type_pick"}
                  options={todo_types?.map((t) => {
                    return {
                      id: t._id.toString(),
                      title: t.title[intl.locale],
                    };
                  })}
                  defaultValue={todo?.type?._id}
                  label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.type = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"type"}
                    label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {loaded && todo ? (
                <Controls.Select
                  options={todo_statuses?.map((t) => {
                    return {
                      id: t._id.toString(),
                      title: t.title[intl.locale],
                    };
                  })}
                  formId={"status_pick"}
                  defaultValue={todo?.status?._id}
                  name={"status"}
                  label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.status = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"status"}
                    label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </PermissionsGate>
  );
};
export default ShowTodoPage;
