import { userService } from "app/services";
import { INotification } from "app/services/notification.service";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, select, takeLatest } from "redux-saga/effects";
import { IAction } from "../../interfaces";

export interface INotificationState {
  notifications: Array<INotification>,
  loading: boolean,
  errors: Array<any>
}

export const actionTypes = {
  ReadNotification: "Read notification",

  AddNotification: "Add notification",
  AddNotification_SUCCESS: "Add notification success",
  AddNotification_PENDING: "Add notification pending",
  AddNotification_ERROR: "Add notification error",

  GetNotification: "Get notification",
  GetNotification_SUCCESS: "Add notification success",
  GetNotification_PENDING: "Add notification pending",
  GetNotification_ERROR: "Add notification error",
};

const initialState: INotificationState = {
  notifications: [],
  loading: false,
  errors: []
};

export const reducer = persistReducer(
  { storage, key: "setting", whitelist: [] },
  (state: INotificationState = initialState, action: IAction) => {
    switch (action.type) {

      case actionTypes.AddNotification: {
        const {notification} = action.payload;
        const notifications = [...state.notifications, notification];
        return { ...state, notifications };
      }

      case actionTypes.GetNotification_SUCCESS: {
        const {notifications} = action.payload;
        return { ...state, notifications};
      }

      default:
        return state;
    }
  }
);

export const actions = {
  addNotification: (notification: INotification) => ({ type: actionTypes.AddNotification, payload: {notification} }),
  getNotifications: () => ({ type: actionTypes.GetNotification})
};

export function* saga() {
  yield takeLatest(actionTypes.GetNotification, function* getUsersNotificationSaga() {

    yield put({ type: actionTypes.GetNotification_PENDING })

    try {
      const {user} = yield select((state) => state.auth);
      const { data: notifications } = yield userService.getNotifications(user.id);
      yield put({ type: actionTypes.GetNotification_SUCCESS, payload: {notifications} })
    }
    catch(error) {
      yield put({ type: actionTypes.GetNotification_ERROR, error })
    }
  });
}
