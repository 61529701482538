import React, { FC } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { IUser } from "../../../../interfaces";

const Members: FC<{
  items: Array<IUser>;
  contextMenuItems?: Array<{
    icon: string;
    title: string;
    onClick: (userId: string) => void;
  }>;
  onClick?: (id: string) => void;
}> = (props) => {
  const { items, onClick, contextMenuItems } = props;
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
    id: string;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent, id: string) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            id: id,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <>
      <List sx={{ width: "100%", p: 0 }} dense={true}>
        {items.map((item) => {
          return (
            <ListItem
              key={item._id.toString()}
              sx={{ p: 0, marginTop: 2, gap: 1, cursor: "pointer" }}
              disableGutters
              onClick={() => onClick && onClick(item._id.toString())}
              onContextMenu={(event) => {
                handleContextMenu(event, item._id.toString());
              }}
            >
              <ListItemAvatar
                sx={{
                  minWidth: "44px",
                }}
              >
                <Avatar variant={"circular"} src={item.file?.base64} />
              </ListItemAvatar>
              <ListItemText
                primary={item.fullname}
                secondary={"online"}
                sx={{
                  m: 0,
                }}
              />
            </ListItem>
          );
        })}
      </List>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        PaperProps={{
          style: {
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.25);",
            borderRadius: 8,
          },
        }}
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenuItems?.map((item) => {
          return (
            <MenuItem
              sx={{
                px: 1,
              }}
              onClick={() => {
                contextMenu && item.onClick(contextMenu.id);
                setContextMenu(null);
              }}
            >
              <ListItemIcon>
                <i
                  className={item.icon}
                  style={{
                    fontSize: "20px",
                    lineHeight: "0.5em",
                    verticalAlign: "-0.15em",
                    display: "inline-flex",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  fontSize: 12,
                }}
              >
                {/*sada*/}
                {item.title}
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default Members;
