import { IUrlQuery, IRestApiCollection, IRestApiError } from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { queryToString } from "../utils/http";

export interface INotification {
  text: string;
  data: any | null;
  read: boolean;
  _created_at: string;
}

export interface IActivities {
  data: {
    count: number;
  };
}

const INDEX = API_ROUTES.activities;
const UPDATE = API_ROUTES.activity;
const COUNT = API_ROUTES.activity_count;
const RESET_COUNT = API_ROUTES.reset_activity_count;

interface INotificationService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<INotification> | IRestApiError>;
  count(
    queries?: Array<IUrlQuery>
  ): Promise<{ data: { count: number } } | IRestApiError>;
  readAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<INotification> | IRestApiError>;
}

const activitiesService: INotificationService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },
  async count(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(COUNT)}${queriesString}`, "get");
  },

  async readAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(RESET_COUNT)}${queriesString}`, "get");
  },
};

export { activitiesService };
