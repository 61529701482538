import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { Socket } from "socket.io-client";
import { IAction } from "../../interfaces";

export interface ISocketState {
  socket: Socket | {};
  connection: boolean;
}

export const actionTypes = {
  Connect: "Socket connect",
  Disconnect: "Socket disconnect",
};

const initialState: ISocketState = {
  socket: {},
  connection: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: "socket",
    whitelist: ["socket", "connection"],
  },
  (state: ISocketState = initialState, action: IAction) => {
    switch (action.type) {
      case actionTypes.Connect: {
        const { socket } = action.payload;
        return {
          ...state,
          socket,
          connection: true,
        };
      }

      case actionTypes.Disconnect: {
        return {
          socket: {},
          connection: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  connect: (socket: Socket) => ({
    type: actionTypes.Connect,
    payload: { socket },
  }),
  disconnect: () => ({ type: actionTypes.Disconnect }),
};
