import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { userService } from "app/services";
import { useParams } from "react-router";
import moment from "moment";
import TimeTrackStore from "../store/timeTrackStore";
import { useTable } from "app/hooks/subresource/useTable";
import { GridTable } from "app/partials/layout/GridTable";
import ShowTimeTrack from "./showTimeTrack";
import { useIntl } from "react-intl";
import { PermissionsGate } from "app/permission/PermissionsGate";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { makeStyles } from "@material-ui/core";
import TimeTrackFilter from "../filters/timeTrackFilter";
import EditIcon from "../../../partials/icons/EditIcon";
import UserAvatar from "../../../partials/layout/UserAvatar";
import TimeTrack from "../../../partials/content/TimeTrack";
import Snackbar from "../../../widgets/Snackbar";
import { ITimeTrack } from "../../../services/timeTrack.service";
import WeeklyStatisticsChart from "../../../widgets/WeeklyStatisticsChart";
import MonthlyStatisticsChart from "../../../widgets/MonthlyStatisticsChart";
import TimeColumnChart from "../../../widgets/TimeColumnChart";
import { IUser } from "../../../interfaces";

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiBox-root": {
      "& .MuiGrid-root": {
        // marginBottom: "4px!important",
        // marginTop: "4px!important",
      },
    },
  },
  f16: {
    fontSize: 16,
  },
  gray: {
    color: "gray",
  },
}));
const UserTimeTracks: FC<{ user: IUser | undefined }> = (props) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const classes = useStyles();

  const { data, xhrLoading, refresh, remove, updateQuery } = useTable<any>({
    fetch: userService.getTimeTracks,
    delete: userService.deleteTimeTrack,
    param: id,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });

  const [confirmId, setConfirmId] = useState<string | null>(null);
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  // @ts-ignore
  const columns: IColumns[] = [
    {
      field: "_id",
      headerName: "ID",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      minWidth: 50,
    },
    {
      field: "created_by",
      headerName: intl.formatMessage({ id: "STANDARD.CREATED_BY" }),
      renderCell: (params) => {
        return <UserAvatar user={params.row?.created_by} />;
      },
      minWidth: 100,
    },
    {
      headerName: intl.formatMessage({ id: "USERS.PLACE" }),
      field: "place",
      renderCell: (params) => {
        return (
          <div className="kt-timeline-v2 todo-timeline ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-warning" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  <Tooltip title={params.row?.start_location}>
                    <Typography variant="inherit">
                      {params.row?.start_location?.substring(0, 16) + ".."}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-primary todo-timeline-circle-icon" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  <Tooltip title={params.row?.end_location}>
                    <Typography variant="inherit">
                      {params.row?.end_location
                        ? params.row?.end_location?.substring(0, 16) + ".."
                        : intl.formatMessage({
                            id: "NO_DATA",
                            defaultMessage: "No data",
                          })}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      renderCell: (params) => {
        return (
          <div className="kt-timeline-v2 todo-timeline ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-success" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {moment
                    .unix(params.row?.start_date_time)
                    .local()
                    .format("DD/MM/YYYY HH:mm")}
                </div>
              </div>
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-danger todo-timeline-circle-icon" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {params.row?.end_date_time
                    ? moment
                        .unix(params.row?.end_date_time)
                        .local()
                        .format("DD/MM/YYYY HH:mm")
                    : intl.formatMessage({
                        id: "NO_DATA",
                        defaultMessage: "No data",
                      })}
                </div>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 150,
    },
    {
      field: "elapse",
      headerName: intl.formatMessage({ id: "CASE.TIMETRACK.ELLAPSETIME" }),
      renderCell: (params) => {
        let elapse = params.row?.end_date_time - params.row?.start_date_time;
        let second = elapse % 60;
        let minute = Math.floor((elapse % 3600) / 60);
        let hour = Math.floor((elapse % 86400) / 3600);
        let result = "";

        result += hour >= 1 ? hour + "h " : "";
        result += minute > 0 ? minute + "m " : "";
        result += second > 0 ? second + "s " : "";

        return result;
      },
      minWidth: 120,
    },
    {
      field: "type",
      headerName: intl.formatMessage({ id: "STANDARD.TYPE" }),
      renderCell: (params) => {
        return (
          params.row?.type?.title && (
            <Tooltip title={params.row?.type?.title[intl.locale]}>
              <Typography variant="inherit">
                {params.row?.type?.title[intl.locale].length > 10
                  ? params.row?.type?.title[intl.locale]?.substring(0, 10) +
                    ".."
                  : params.row?.type?.title[intl.locale]}
              </Typography>
            </Tooltip>
          )
        );
      },
      minWidth: 110,
    },
    {
      field: "platform",
      headerName: intl.formatMessage({ id: "STANDARD.PLATFORM" }),
      align: "center",
      renderCell: (params) => {
        let platform;

        if (params.row?.platform === "app") {
          platform = `smartphone`;
        } else if (params.row?.platform === "web") {
          platform = `computer ${classes.gray}`;
        }

        return (
          <Tooltip
            title={
              params.row?.platform?.charAt(0).toUpperCase() +
              params.row?.platform?.slice(1)
            }
          >
            <i className={`fi fi-sr-${platform} ${classes.f16}`} />
          </Tooltip>
        );
      },
      minWidth: 50,
    },
    {
      field: "_updated_at",
      headerName: intl.formatMessage({ id: "STANDARD.UPDATED_AT" }),
      renderCell: (params) => {
        return params.row?._updated_at ? (
          moment
            .unix(params.row?._updated_at)
            .local()
            .format("DD.MM.YYYY HH:mm")
        ) : (
          <Tooltip title={intl.formatMessage({ id: "STANDARD.NOT_UPDATED" })}>
            <Typography variant="inherit">
              {intl.formatMessage({ id: "STANDARD.NOT_UPDATED" })}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 110,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"case_timerecords"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"case_time_track_show"}
                data-cy-case-id={id}
                data-cy-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"case_timerecords"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"case_time_track_delete"}
                data-cy-case-id={id}
                data-cy-id={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 110,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [timeTrackId, setTimeTrackId] = useState<string | null>(null);

  const show = (_id: string) => {
    setTimeTrackId(_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const [activeTimeTrack, setTimeTrack] = useState<ITimeTrack | null>();

  useEffect(() => {
    userService
      .getTimeTracks(id, [{ name: "unfinished", value: 1 }])
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        let { data: timeTrack } = data;
        let unFinishedTrack = timeTrack.find((t) => !t.end_location);

        if (unFinishedTrack) setTimeTrack(unFinishedTrack);
        else setTimeTrack(null);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const [weekSpent, setWeekSpent] = useState<number>(0);
  const [monthSpent, setMonthSpent] = useState<{
    titles: string[];
    series: number[];
    colors: string[];
  }>({ titles: [], series: [], colors: [] });
  const [yearSpent, setYearSpent] = useState<
    { spent: number; total: number }[]
  >([]);

  useEffect(() => {
    userService.getTimeTracksSumWeek(id).then((data) => {
      if ("error" in data) {
        throw Error(data.error.message);
      }
      const { spent } = data.data;

      setWeekSpent(spent);
    });
  }, []);

  useEffect(() => {
    userService.getTimeTracksSumYear(id).then((data) => {
      if ("error" in data) {
        throw Error(data.error.message);
      }
      const spent = data.data;

      let spentArr: { spent: number; total: number }[] = [];

      spent?.map((mt, index) => {
        spentArr.push({
          spent: mt.spent,
          total: mt.total,
        });
      });

      setYearSpent(spentArr);
    });
  }, []);

  useEffect(() => {
    userService.getTimeTracksSumMonth(id).then((data) => {
      if ("error" in data) {
        throw Error(data.error.message);
      }
      const spent = data.data;
      let titles: Array<string> = [];
      let series: Array<number> = [];
      let colors: Array<string> = [];

      if (spent) {
        Object.keys(spent).forEach(function (key) {
          titles.push(key);
          // @ts-ignore
          series.push(+(spent[key].hour / 3600).toFixed(0));
          // @ts-ignore
          colors.push(spent[key].color);
        });
      }

      const result = { titles, series, colors };
      setMonthSpent(result);
    });
  }, []);

  return (
    <Box className={classes.container}>
      <>
        {id ? (
          <>
            <Box sx={{ width: "30%", marginBottom: "15px" }}>
              <TimeTrack
                data={activeTimeTrack}
                related={{ _id: id }}
                type={"user"}
              />
            </Box>
            <Grid container spacing={1} mb={2}>
              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                <WeeklyStatisticsChart
                  spent={weekSpent}
                  total={
                    props.user
                      ? props.user.work_hours
                        ? props.user.work_hours_type === "week"
                          ? +props.user.work_hours * 4 * 3600
                          : props.user.work_hours * 3600
                        : 0
                      : 0
                  }
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                <MonthlyStatisticsChart
                  titles={monthSpent.titles}
                  series={monthSpent.series}
                  colors={monthSpent.colors}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TimeColumnChart data={yearSpent} />
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </>
      <PermissionsGate section={"time_track_user"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete time registration?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(id, confirmId);
            }}
          >
            Are you sure you want to delete this user time registration?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"time_track_user"} scope={"read"}>
        {timeTrackId ? (
          <ShowTimeTrack
            _case={id}
            _id={timeTrackId}
            open={Boolean(timeTrackId)}
            setDialogue={() => setTimeTrackId(null)}
            onDone={refresh}
            session_type={"user"}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"time_track_user"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel: (props: { open: boolean; close: () => void }) => {
              return (
                <TimeTrackStore
                  _id={id}
                  open={props.open}
                  setDialogue={props.close}
                  onDone={refresh}
                  session_type={"user"}
                />
              );
            },
            filters: (props: { show: boolean }) => {
              return <TimeTrackFilter show={props.show} />;
            },
          }}
          search={(queries) => updateQuery(queries)}
          quickSearchToolbar={true}
        />
      </PermissionsGate>
    </Box>
  );
};

export default UserTimeTracks;
