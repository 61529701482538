// @ts-nocheck
import React from "react";
import Controls from "../../../../../widgets/controls";

const AddChecklist = React.memo(
  ({ state, setState, contentRef, onAddChecklist }) => {
    return (
      <div ref={contentRef} className="mb-3">
        <button
          style={{
            width: 35,
            height: 35,
            borderRadius: "50%",
            backgroundColor: "#0D99FF",
            borderColor: "#0D99FF",
          }}
          type="button"
          className="btn btn-primary d-flex align-items-center justify-content-center p-0"
          onClick={() => setState({ add: !state.add, title: "Checklist" })}
        >
          <svg
            style={{
              transform: state.add ? "rotate(45deg)" : "rotate(0deg)",
              transition: "all .3s ",
            }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75V9.25H4.75C4.33579 9.25 4 9.58579 4 10C4 10.4142 4.33579 10.75 4.75 10.75H9.25V15.25C9.25 15.6642 9.58579 16 10 16C10.4142 16 10.75 15.6642 10.75 15.25V10.75H15.25C15.6642 10.75 16 10.4142 16 10C16 9.58579 15.6642 9.25 15.25 9.25H10.75V4.75Z"
              fill="white"
            />
          </svg>
        </button>
        {state.add && (
          <form id={"check"}>
            <div className="form-group">
              <div className="form-group mt-3 mb-2">
                <Controls.Input
                  maxLength="300"
                  value={state.title}
                  onChange={(e) => setState({ title: e.target.value })}
                  placeholder={"Title"}
                  className="form-control"
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      onAddChecklist(e);
                    }
                  }}
                  onFocus={(e) => {
                    let length = e.target.value.length;
                    e.target.setSelectionRange(0, length);
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-primary px-5 py-2"
                  style={{
                    backgroundColor: "#0D99FF",
                    borderColor: "#0D99FF",
                  }}
                  onClick={(event) => onAddChecklist(event)}
                >
                  Save
                </button>
                <button
                  className="btn btn-secondary px-5 py-2"
                  type="button"
                  onClick={() => setState({ add: false })}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
);

export default AddChecklist;
