import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from "clsx";
import * as auth from "app/store/ducks/auth.duck";
import Controls from "app/widgets/uncontrolled";
import { login, getBusinessesByUser, getUserByPhone } from "app/crud/auth.crud";
import {
  ListItemButton,
  ListItemText,
  Typography,
  Box,
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  Avatar,
  List,
  Chip,
} from "@mui/material";
import { useCookies } from "react-cookie";
import Snackbar from "app/widgets/Snackbar";
import { stringAvatar } from "../../widgets/AvatarBackground";
import { getFileExtIcon } from "../../utils/standart";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const [step, setStep] = useState(0);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const [businesses, setBusinesses] = React.useState();
  const [user, setUser] = React.useState();
  const [users, setUsers] = React.useState();
  const [disable, setDisable] = React.useState(false);

  const [selectedIndex, setSelectedIndex] = React.useState();
  const [, setCookie] = useCookies();

  // const handleListItemClick = (event, user) => {
  //   setSelectedIndex(user._id);
  //   login(user.phone, loginInfo.password, business._id)
  //     .then(({ accessToken, business }) => {
  //       disableLoading();
  //       props.login(accessToken, business);
  //       let date = new Date();
  //       date.setDate(date.getDate() + 30);
  //
  //       setCookie("arc_token", accessToken, {
  //         path: "/",
  //         expires: date,
  //         httpOnly: true,
  //         secure: true,
  //       });
  //     })
  //     .catch((error) => {
  //       disableLoading();
  //     });
  // };

  const handleBackButton = () => {
    // step 0 = phone
    // step 1 = password
    // step 2 = user list
    switch (step) {
      case 1:
        if (users.length > 0) {
          setStep(2);
        } else if (user) {
          setStep(0);
        }
        break;
      case 2:
        setStep(0);
        break;
      default:
        setStep(2);
    }
  };

  const renderContent = (
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
    disable
  ) => {
    let content = null;
    switch (step) {
      case 0:
        content = (
          <Box
            sx={{
              mt: 8,
              "& .MuiFormControl-root": {
                "& .MuiOutlinedInput-root": {
                  border: "1px solid #C9D2FF",
                  backgroundColor: "#fdfdfd",
                  padding: 1,
                  borderRadius: 2,
                  "& input": {
                    "&:-internal-autofill-selected": {
                      backgroundColor: "#ffffff!important",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "11px!important",
                    backgroundColor: "#fdfdfd",
                  },
                },
                height: 40,
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
                m: () => ({}),
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "unset",
                },
              },
            }}
          >
            <Controls.Input
              type="phone"
              margin="normal"
              id="phone"
              className="kt-width-full"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phone}
              disabled={disable}
              helperText={touched.phone && errors.phone}
              error={Boolean(touched.phone && errors.phone)}
              InputProps={{
                startAdornment: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3569 12C15.0657 12 17.2617 9.76143 17.2617 7C17.2617 4.23858 15.0657 2 12.3569 2C9.64808 2 7.45215 4.23858 7.45215 7C7.45215 9.76143 9.64808 12 12.3569 12Z"
                      fill="#0D99FF"
                    />
                    <path
                      d="M12.3571 13.667C8.29577 13.6716 5.00452 17.0268 5 21.167C5 21.6272 5.36598 22.0003 5.81745 22.0003H18.8968C19.3483 22.0003 19.7142 21.6272 19.7142 21.167C19.7098 17.0268 16.4185 13.6716 12.3571 13.667Z"
                      fill="#0D99FF"
                    />
                  </svg>
                ),
              }}
            />
          </Box>
        );
        break;
      case 1:
        content = (
          <>
            <Grid container>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                <Button onClick={handleBackButton}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 6.75119C14.9998 6.95026 14.9196 7.14112 14.7771 7.2818L10.8898 11.1222C10.7722 11.2384 10.6788 11.3763 10.6152 11.5282C10.5515 11.68 10.5187 11.8428 10.5187 12.0072C10.5187 12.1715 10.5515 12.3343 10.6152 12.4861C10.6788 12.638 10.7722 12.776 10.8898 12.8922L14.772 16.7275C14.9104 16.8691 14.987 17.0588 14.9853 17.2557C14.9836 17.4525 14.9037 17.6409 14.7627 17.7801C14.6218 17.9193 14.4312 17.9983 14.2319 18C14.0327 18.0017 13.8407 17.926 13.6973 17.7892L9.81517 13.9569C9.29317 13.4402 9 12.7399 9 12.0099C9 11.2799 9.29317 10.5797 9.81517 10.063L13.7024 6.22009C13.8087 6.11501 13.9441 6.04345 14.0916 6.01445C14.2391 5.98545 14.392 6.00032 14.5309 6.05717C14.6698 6.11403 14.7885 6.21032 14.872 6.33386C14.9555 6.4574 15.0001 6.60264 15 6.75119Z"
                      fill="#767C91"
                    />
                  </svg>
                  <Typography sx={{ color: "#6D6D6D" }} variant={"body2"}>
                    <FormattedMessage
                      id="AUTH.GENERAL.BACK_BUTTON"
                      defaultMessage={"Back"}
                    />
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    mt: 2,
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: user?.role?.color ?? "#0D99FF",
                    }}
                    src={user?.avatar && user?.file?.base64}
                    alt={user.firstname}
                    sx={{
                      width: 140,
                      height: 140,
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    variant="square"
                  >
                    {user.firstname.charAt(0)}
                    {user.lastname.charAt(0)}
                  </Avatar>
                  <Typography sx={{ mt: 2 }} variant={"h5"}>
                    {user.fullname}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 7,
                "& .MuiFormControl-root": {
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #C9D2FF",
                    padding: 1,
                    borderRadius: 2,
                    "& .MuiOutlinedInput-input": {
                      padding: "11px!important",
                    },
                  },
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  m: () => ({}),
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "unset",
                  },
                },
              }}
            >
              <Controls.Input
                type="password"
                margin="normal"
                id="password"
                // // label="Password"
                className="kt-width-full"
                name="password"
                onBlur={handleBlur}
                disable={disable}
                onChange={handleChange}
                value={values.password}
                helperText={touched.password && errors.password}
                error={Boolean(touched.password && errors.password)}
                InputProps={{
                  startAdornment: (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.8331 9.02V7.83332C17.8331 4.61168 15.2215 2 11.9998 2C8.77814 2 6.1665 4.61168 6.1665 7.83332V9.02C4.64986 9.68191 3.66869 11.1786 3.6665 12.8333V17.8333C3.66924 20.1334 5.53311 21.9973 7.83314 22H16.1665C18.4665 21.9973 20.3304 20.1334 20.3331 17.8333V12.8333C20.331 11.1786 19.3498 9.68191 17.8331 9.02ZM12.8331 16.1667C12.8331 16.6269 12.4601 17 11.9998 17C11.5396 17 11.1665 16.6269 11.1665 16.1667V14.5C11.1665 14.0398 11.5396 13.6667 11.9998 13.6667C12.4601 13.6667 12.8331 14.0398 12.8331 14.5V16.1667ZM16.1665 8.66668H7.83314V7.83336C7.83314 5.53219 9.69861 3.66668 11.9998 3.66668C14.301 3.66668 16.1665 5.53215 16.1665 7.83336V8.66668Z"
                        fill="#0D99FF"
                      />
                    </svg>
                  ),
                }}
              />
            </Box>
          </>
        );
        break;
      case 2:
        content = (
          <>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
              <Button onClick={handleBackButton}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 6.75119C14.9998 6.95026 14.9196 7.14112 14.7771 7.2818L10.8898 11.1222C10.7722 11.2384 10.6788 11.3763 10.6152 11.5282C10.5515 11.68 10.5187 11.8428 10.5187 12.0072C10.5187 12.1715 10.5515 12.3343 10.6152 12.4861C10.6788 12.638 10.7722 12.776 10.8898 12.8922L14.772 16.7275C14.9104 16.8691 14.987 17.0588 14.9853 17.2557C14.9836 17.4525 14.9037 17.6409 14.7627 17.7801C14.6218 17.9193 14.4312 17.9983 14.2319 18C14.0327 18.0017 13.8407 17.926 13.6973 17.7892L9.81517 13.9569C9.29317 13.4402 9 12.7399 9 12.0099C9 11.2799 9.29317 10.5797 9.81517 10.063L13.7024 6.22009C13.8087 6.11501 13.9441 6.04345 14.0916 6.01445C14.2391 5.98545 14.392 6.00032 14.5309 6.05717C14.6698 6.11403 14.7885 6.21032 14.872 6.33386C14.9555 6.4574 15.0001 6.60264 15 6.75119Z"
                    fill="#767C91"
                  />
                </svg>
                <Typography sx={{ color: "#6D6D6D" }} variant={"body2"}>
                  <FormattedMessage
                    id="AUTH.GENERAL.BACK_BUTTON"
                    defaultMessage={"Back"}
                  />
                </Typography>
              </Button>
            </Grid>
            <Box sx={{ mt: 2 }}>
              {users?.map((user) => {
                return (
                  <>
                    <List
                      sx={{
                        width: "100%",
                        backgroundColor: "background.paper",
                        pt: "0px!important",
                        pb: "0px!important",
                      }}
                    >
                      <ListItem
                        secondaryAction={
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 17.2488C9.00018 17.0497 9.08037 16.8589 9.22294 16.7182L13.1102 12.8778C13.2278 12.7616 13.3212 12.6237 13.3848 12.4718C13.4485 12.32 13.4813 12.1572 13.4813 11.9928C13.4813 11.8285 13.4485 11.6657 13.3848 11.5139C13.3212 11.362 13.2278 11.224 13.1102 11.1078L9.228 7.27248C9.08956 7.13087 9.01296 6.9412 9.01469 6.74433C9.01642 6.54746 9.09635 6.35913 9.23726 6.21992C9.37818 6.08071 9.5688 6.00174 9.76807 6.00003C9.96735 5.99832 10.1593 6.074 10.3027 6.21077L14.1848 10.0431C14.7068 10.5598 15 11.2601 15 11.9901C15 12.7201 14.7068 13.4203 14.1848 13.937L10.2976 17.7799C10.1913 17.885 10.0559 17.9566 9.90838 17.9856C9.76091 18.0146 9.60804 17.9997 9.46912 17.9428C9.33021 17.886 9.21148 17.7897 9.12798 17.6661C9.04447 17.5426 8.99993 17.3974 9 17.2488Z"
                              fill="#767C91"
                            />
                          </svg>
                        }
                        disablePadding
                      >
                        <ListItemButton
                          selected={selectedIndex === user._id}
                          data-cy-name={user.firstname}
                          onClick={(event) => {
                            // handleListItemClick(event, user);
                            setUser(user);
                            setStep(1);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              style={{
                                backgroundColor: user?.role?.color ?? "#0D99FF",
                              }}
                              src={user.avatar && user.avatar.base64}
                              alt={user.firstname}
                              sx={{
                                width: 50,
                                height: 50,
                                borderRadius: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              variant="square"
                            >
                              {user.firstname.charAt(0)}
                              {user.lastname.charAt(0)}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ ml: 2 }}
                            primary={
                              <Typography
                                variant={"body1"}
                                sx={{ color: "#0D99FF" }}
                              >
                                {user.fullname}
                              </Typography>
                            }
                            secondary={
                              <React.Fragment>
                                <Chip
                                  label={user.businesses[0]?.name}
                                  sx={{
                                    bgcolor: "#556EE6",
                                    color: "#fff",
                                    fontWeight: 600,
                                  }}
                                />
                                <Chip
                                  label={user.role?.title?.en}
                                  sx={{
                                    bgcolor: user.role?.color,
                                    color: "#fff",
                                    fontWeight: 600,
                                  }}
                                />
                              </React.Fragment>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </>
                );
              })}
            </Box>
          </>
        );
        break;
      default:
        content = (
          <Box
            sx={{
              mt: 8,
              "& .MuiFormControl-root": {
                "& .MuiOutlinedInput-root": {
                  border: "1px solid #C9D2FF",
                  padding: 1,
                  borderRadius: 2,
                  "& input": {
                    "&:-internal-autofill-selected": {
                      backgroundColor: "#ffffff!important",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "11px!important",
                  },
                },
                height: 40,
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
                m: () => ({}),
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "unset",
                },
              },
            }}
          >
            <Controls.Input
              type="phone"
              margin="normal"
              id="phone"
              className="kt-width-full"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phone}
              disabled={disable}
              helperText={touched.phone && errors.phone}
              error={Boolean(touched.phone && errors.phone)}
              InputProps={{
                startAdornment: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3569 12C15.0657 12 17.2617 9.76143 17.2617 7C17.2617 4.23858 15.0657 2 12.3569 2C9.64808 2 7.45215 4.23858 7.45215 7C7.45215 9.76143 9.64808 12 12.3569 12Z"
                      fill="#0D99FF"
                    />
                    <path
                      d="M12.3571 13.667C8.29577 13.6716 5.00452 17.0268 5 21.167C5 21.6272 5.36598 22.0003 5.81745 22.0003H18.8968C19.3483 22.0003 19.7142 21.6272 19.7142 21.167C19.7098 17.0268 16.4185 13.6716 12.3571 13.667Z"
                      fill="#0D99FF"
                    />
                  </svg>
                ),
              }}
            />
          </Box>
        );
    }
    return content;
  };

  const onSubmit = async (setStatus, values) => {
    switch (step) {
      case 1:
        return login(
          user._id,
          user.phone,
          values.password,
          user.businesses[0]._id
        )
          .then(({ accessToken, business, error, message }) => {
            disableLoading();
            if (error) {
              setStatus(error.message);
            } else {
              props.login(accessToken, business);
              let date = new Date();
              date.setDate(date.getDate() + 30);

              setCookie("arc_token", accessToken, {
                path: "/",
                expires: date,
                httpOnly: true,
                secure: true,
              });
            }
          })
          .catch((error) => {
            disableLoading();
          });
      default:
        return getUserByPhone(values.phone).then(
          ({ status, description, data }) => {
            disableLoading();
            if (status === "error") {
              setStatus(description);
              setStep(0);
            }
            if (data?._id) {
              setUser(data);
              setUsers([]);
              setStep(1);
            } else if (data.length > 0) {
              setUsers(data);
              setUser(null);
              setStep(2);
            }
          }
        );
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/bg/bg_blue_pattern.jpg"
          )})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100vw",
          height: "100vh",
          position: "absolute",
          zIndex: 0,
        }}
      ></div>
      <div
        style={{
          width: "100vw",
          height: "400px",
          position: "absolute",
          background: "white",
          zIndex: 0,
        }}
      ></div>
      <div className="kt-login__body" style={{}}>
        <div
          className="kt-login__form"
          style={{
            height: "unset",
            minHeight: 380,
            zIndex: 1,
            backgroundColor: "#fdfdfd",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "50px",
            }}
          >
            <img width={250} src="/media/logos/logo-2.png" alt="" />
          </div>
          <Formik
            initialValues={{
              phone: null,
              password: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.phone) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.phone = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              // if (!values.password) {
              //   errors.password = intl.formatMessage({
              //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
              //   });
              // }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setStatus(null);
              setTimeout(() => {
                onSubmit(setStatus, values);
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <div></div>
                )}
                <>
                  <div className="" style={{ textAlign: "center" }}>
                    <Typography sx={{ color: "#000000" }} variant={"h4"}>
                      {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                      Welcome back!
                    </Typography>
                  </div>

                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      color: "#9FA5BB",
                    }}
                  >
                    <Typography variant={"subtitle1"}>
                      Enter your informations to access your account.
                    </Typography>
                  </div>

                  {renderContent(
                    handleBlur,
                    handleChange,
                    values,
                    touched,
                    errors,
                    disable
                  )}

                  {step !== 2 && (
                    <Box
                      className="kt-login__actions"
                      sx={{
                        "& .MuiButton-root": {
                          "&:hover": {
                            backgroundColor: "#0D99FF",
                          },
                        },
                      }}
                    >
                      <Button
                        sx={{
                          mt: 4.5,
                          width: "100%",
                          boxShadow: "none",
                          backgroundColor: "#0D99FF",
                          color: "#fff",
                          borderRadius: 2,
                        }}
                        disableRipple
                        disableFocusRipple
                        disableElevation
                        id="kt_login_signin_submit"
                        type="submit"
                        disable={disable}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                          {
                            "kt-spinner kt-spinner--right kt-scl spinner--md kt-spinner--light":
                              loading,
                          }
                        )}`}
                        style={loadingButtonStyle}
                      >
                        {step === 1 ? "Sign In" : "Next"}
                      </Button>
                    </Box>
                  )}
                </>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
