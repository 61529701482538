import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { IBusiness } from "./business.service";
import { IDocument } from "./document.service";
import { ISetting } from "./setting.service";
import { ICase } from "./case.service";

export interface IAccounting {
  _id: string;
  number: number;
  title: string;
  case: ICase;
  template: ITemplate;
  sent: number;
  total: number;
  description: string;
  send_to: string;
  overdue_date: string;
  content: string;
  through: string;
  currency: string;
  paid_at: string;
  paid_with: string;
  credit_note: string;
  items: any[];
  fines: any[];
  status: { value: string; label: string };
  to_business: IBusiness | null;
  user: IUser | null;
  articles: any[];
  _created_at: string;
  _updated_at: string;
}
export interface IExpense {
  _id: string;
  title: string;
  total: number;
  total_vat_excluded: number;
  total_vat_amount: number;
  supplier: ISupplier;
  description: string;
  created_by: { id: string; fullname: string };
  invoice_date: string;
  currency: string;
  payment_date: string;
  expense_category: ISetting;
  items: any[];
  files: string[];
  documents: IDocument[];
  _created_at: string;
  _updated_at: string;
}
export interface ISupplier {
  _id: string;
  title: string;
  _created_at: string;
  _updated_at: string;
}

export interface ITemplate {
  _id: string;
  title: string;
  content: string;
  _created_at: string;
  _updated_at: string;
}

export interface ITaxRate {
  _id: string;
  title: string;
  rate: number;
  _created_at: string;
  _updated_at: string;
}

export interface IAccountingStore {
  title: string;
  overdue_date: string;
  paid_at: string;
  description: string;
  currency: string;
  paid_with: string;
  status: string;
  articles: any[];
  send_to: string;
  user: string;
  business: string;
}

export interface IExpenseStore {
  title: string;
  expense_category: string;
  overdue_date: string;
  paid_at: string;
  description: string;
  currency: string;
  paid_with: string;
  status: string;
  articles: any[];
  send_to: string;
  user: string;
  business: string;
}

export interface ITemplateStore {
  title: string;
  content: string;
}

export interface ISupplierStore {
  title: string;
}

export interface ITaxRateStore {
  title: string;
  rate: number;
}

export interface IAccountingQuickCardsCount {
  data: {
    completed: { count: number; amount: number; percent: number };
    in_progress: { count: number; amount: number; percent: number };
    total: { count: number; amount: number; percent: number };
    overdue: { count: number; amount: number; percent: number };
  };
}

const INDEX = API_ROUTES.invoices;
const SHOW = API_ROUTES.invoice;
const UPDATE = API_ROUTES.invoice;
const DELETE = API_ROUTES.invoice;
const SEND = API_ROUTES.invoice_send;
const INVOICE_PDF = API_ROUTES.invoice_pdf;
const QUICK_CARDS_COUNT = API_ROUTES.invoices_count;

// expenses
const INDEX_EXPENSE = API_ROUTES.expenses;
const SHOW_EXPENSE = API_ROUTES.expense;
const UPDATE_EXPENSE = API_ROUTES.expense;
const DELETE_EXPENSE = API_ROUTES.expense;
const EXPENSE_QUICK_CARDS_COUNT = API_ROUTES.expenses_count;

// suppliers
const INDEX_SUPPLIER = API_ROUTES.suppliers;
const SHOW_SUPPLIER = API_ROUTES.supplier;
const UPDATE_SUPPLIER = API_ROUTES.supplier;
const DELETE_SUPPLIER = API_ROUTES.supplier;

// tax rates
const TAX_INDEX = API_ROUTES.tax_rates;
const TAX_SHOW = API_ROUTES.tax_rate;

// templates
const INDEX_TEMPLATE = API_ROUTES.templates;
const SHOW_TEMPLATE = API_ROUTES.template;
const UPDATE_TEMPLATE = API_ROUTES.template;
const DELETE_TEMPLATE = API_ROUTES.template;

// credit note
const CREDIT_NOTE = API_ROUTES.credit_note;

export interface IAccountingService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IAccounting> | IRestApiError>;
  add(
    data: IAccountingStore
  ): Promise<IRestApiResource<IAccounting> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IAccounting> | IRestApiError>;
  getQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<IAccountingQuickCardsCount | IRestApiError>;
  update(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IAccounting> | IRestApiError>;
  delete(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IAccounting> | IRestApiError>;
  sendInvoice(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IAccounting> | IRestApiError>;
  getTaxRates(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITaxRate> | IRestApiError>;
  addTaxRate(
    data: ITaxRateStore
  ): Promise<IRestApiResource<ITaxRate> | IRestApiError>;
  getOneTaxRate(
    _id: string
  ): Promise<IRestApiResource<ITaxRate> | IRestApiError>;
  updateTaxRate(
    _id: string,
    data: any
  ): Promise<IRestApiResource<ITaxRate> | IRestApiError>;
  deleteTaxRate(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITaxRate> | IRestApiError>;

  // expenses
  getAllExpenses(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IExpense> | IRestApiError>;
  addExpense(
    data: IExpenseStore
  ): Promise<IRestApiResource<IExpense> | IRestApiError>;
  getOneExpense(
    _id: string
  ): Promise<IRestApiResource<IExpense> | IRestApiError>;
  // getQuickCardsCount(
  //     query?: Array<IUrlQuery>
  // ): Promise<IAccountingQuickCardsCount | IRestApiError>;
  updateExpense(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IExpense> | IRestApiError>;
  deleteExpense(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IExpense> | IRestApiError>;
  getExpenseQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<IAccountingQuickCardsCount | IRestApiError>;

  // suppliers
  getAllSuppliers(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ISupplier> | IRestApiError>;
  addSupplier(
    data: ISupplierStore
  ): Promise<IRestApiResource<ISupplier> | IRestApiError>;
  getOneSupplier(
    _id: string
  ): Promise<IRestApiResource<ISupplier> | IRestApiError>;
  // getQuickCardsCount(
  //     query?: Array<IUrlQuery>
  // ): Promise<IAccountingQuickCardsCount | IRestApiError>;
  updateSupplier(
    _id: string,
    data: any
  ): Promise<IRestApiResource<ISupplier> | IRestApiError>;
  deleteSupplier(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ISupplier> | IRestApiError>;

  // templates
  getAllTemplates(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITemplate> | IRestApiError>;
  addTemplate(
    data: ITemplateStore
  ): Promise<IRestApiResource<ITemplate> | IRestApiError>;
  getOneTemplate(
    _id: string
  ): Promise<IRestApiResource<ITemplate> | IRestApiError>;
  // getQuickCardsCount(
  //     query?: Array<IUrlQuery>
  // ): Promise<IAccountingQuickCardsCount | IRestApiError>;
  updateTemplate(
    _id: string,
    data: any
  ): Promise<IRestApiResource<ITemplate> | IRestApiError>;
  deleteTemplate(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITemplate> | IRestApiError>;
  getPDF(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IAccounting> | IRestApiError>;

  addCreditNote(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IAccounting> | IRestApiError>;
}

const accountingService: IAccountingService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async add(data: IAccountingStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(INDEX), "post", formData);
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async sendInvoice(_id: string, data: any) {
    let formData = generateFormData(new FormData(), data);

    return http(
      `${getRoute(SEND.replace(":_id", _id.toString()))}`,
      "post",
      formData
    );
  },

  async getQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(QUICK_CARDS_COUNT)}${queryString}`, "get");
  },

  async getTaxRates(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(TAX_INDEX)}${queriesString}`, "get");
  },

  async addTaxRate(data: ITaxRateStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(TAX_INDEX), "post", formData);
  },

  async getOneTaxRate(_id: string) {
    return http(`${getRoute(TAX_SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async updateTaxRate(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(TAX_SHOW).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async deleteTaxRate(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(TAX_SHOW.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  // expenses
  async getAllExpenses(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX_EXPENSE)}${queriesString}`, "get");
  },

  async addExpense(data: IExpenseStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(INDEX_EXPENSE), "post", formData);
  },

  async getOneExpense(_id: string) {
    return http(
      `${getRoute(SHOW_EXPENSE).replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async updateExpense(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE_EXPENSE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async deleteExpense(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(
        DELETE_EXPENSE.replace(":_id", _id.toString())
      )}${queriesString}`,
      "delete"
    );
  },

  async getExpenseQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(EXPENSE_QUICK_CARDS_COUNT)}${queryString}`, "get");
  },

  // supplier
  async getAllSuppliers(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX_SUPPLIER)}${queriesString}`, "get");
  },

  async addSupplier(data: ISupplierStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(INDEX_SUPPLIER), "post", formData);
  },

  async getOneSupplier(_id: string) {
    return http(
      `${getRoute(SHOW_SUPPLIER).replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async updateSupplier(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE_SUPPLIER).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async deleteSupplier(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(
        DELETE_SUPPLIER.replace(":_id", _id.toString())
      )}${queriesString}`,
      "delete"
    );
  },

  // templates
  async getAllTemplates(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX_TEMPLATE)}${queriesString}`, "get");
  },

  async addTemplate(data: ITemplateStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(INDEX_TEMPLATE), "post", formData);
  },

  async getOneTemplate(_id: string) {
    return http(
      `${getRoute(SHOW_TEMPLATE).replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async updateTemplate(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE_TEMPLATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async deleteTemplate(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(
        DELETE_TEMPLATE.replace(":_id", _id.toString())
      )}${queriesString}`,
      "delete"
    );
  },

  // pdf
  async getPDF(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INVOICE_PDF)}${queriesString}`, "get");
  },

  // credit note

  async addCreditNote(_id: string, data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(CREDIT_NOTE).replace(":_id", _id.toString())}`,
      "post",
      formData
    );
  },
};

export { accountingService };
