import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import { caseService, ICase } from "app/services/case.service";
import { Box } from "@mui/material";
import { ITodo } from "app/services/todo.service";
import moment, { Moment } from "moment";
import WeeklyStatisticsChart from "app/widgets/WeeklyStatisticsChart";
import MonthlyStatisticsChart from "app/widgets/MonthlyStatisticsChart";
import BudgetStatisticsChart from "app/widgets/BudgetStatisticsChart";
import Activities from "app/widgets/Activities";
import TimeColumnChart from "app/widgets/TimeColumnChart";
import StaticDatePicker from "app/widgets/StaticDatePicker";
import RatioCard from "../../../widgets/RatioCard";
import Snackbar from "../../../widgets/Snackbar";
import { useHistory } from "react-router-dom";
import { RootState } from "app/store/store";
import { useSelector } from "react-redux";

type IProps = {
  case: ICase;
  tabChange: Function;
};

const Overview = (props: IProps) => {
  const [todos, setTodos] = useState<Array<ITodo>>([]);
  const [allTodos, setAllTodos] = useState<Array<ITodo>>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [monthSpent, setMonthSpent] = useState<{
    titles: string[];
    series: number[];
    colors: string[];
  }>({ titles: [], series: [], colors: [] });
  const [weekSpent, setWeekSpent] = useState<number>(0);
  const [yearSpent, setYearSpent] = useState<
    { spent: number; total: number }[]
  >([]);
  const history = useHistory();
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  const onDateChange = (e: Moment) => {
    let _todos = allTodos.filter(
      (todo) =>
        moment.unix(todo.date).local().format("YYYY-MM-DD") ===
          moment(e).format("YYYY-MM-DD") ||
        moment.unix(todo.deadline_date).local().format("YYYY-MM-DD") ===
          moment(e).format("YYYY-MM-DD")
    );

    setTodos(_todos);
  };

  const tabChange = (tab: string) => {
    history.push(`/cases/${props.case._id}?section=${tab}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const onRangeChange = (startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (!startDate || !endDate) return;

    if (props.case._id) {
      caseService
        .getTodos(props.case._id, [
          {
            name: "calendar_start",
            value: startDate,
          },
          {
            name: "calendar_end",
            value: endDate,
          },
          {
            name: "calendar_start_date_deadline",
            value: startDate,
          },
          {
            name: "calendar_end_date_deadline",
            value: endDate,
          },
          {
            name: "pagination",
            value: "0",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: todos } = data;
          setAllTodos(todos);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [startDate]);

  useEffect(() => {
    caseService.getTimeTracksSumMonth(props.case._id).then((data) => {
      if ("error" in data) {
        throw Error(data.error.message);
      }
      const spent = data.data;
      let titles: Array<string> = [];
      let series: Array<number> = [];
      let colors: Array<string> = [];

      if (spent) {
        Object.keys(spent).forEach(function (key) {
          titles.push(key);
          // @ts-ignore
          series.push(+(spent[key].hour / 3600).toFixed(0));
          // @ts-ignore
          colors.push(spent[key].color);
        });
      }

      const result = { titles, series, colors };
      setMonthSpent(result);
    });
  }, []);

  useEffect(() => {
    caseService.getTimeTracksSumWeek(props.case._id).then((data) => {
      if ("error" in data) {
        throw Error(data.error.message);
      }
      const { spent } = data.data;

      setWeekSpent(spent);
    });
  }, []);
  const currYear: number = new Date().getFullYear();

  useEffect(() => {
    caseService.getTimeTracksSumYear(props.case._id).then((data) => {
      if ("error" in data) {
        throw Error(data.error.message);
      }
      const spent = data.data;

      let spentArr: { spent: number; total: number }[] = [];

      props.case.meeting_durations[currYear]?.map((mt, index) => {
        let meetingDurations = mt.administration_work + mt.face_to_face;

        if (props.case.meeting_duration_type?.slug === "week")
          meetingDurations = meetingDurations * 4;

        spentArr.push({
          spent: spent[index]?.spent,
          total: meetingDurations,
        });
      });

      setYearSpent(spentArr);
    });
  }, []);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2 }}>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <RatioCard ratio={"100%"}>
            <Box
              sx={{
                padding: "12px 12px 16px 12px",
                height: "100%",
              }}
            >
              <StaticDatePicker
                todos={allTodos}
                onDateChange={onDateChange}
                onRangeChange={onRangeChange}
              />
            </Box>
          </RatioCard>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12} sx={{ height: 290 }}>
          {todos?.length ? (
            <Box
              sx={{
                background: "#ffffff",
                padding: 2,
                borderRadius: 2,
                height: "100%",
                overflow: "scroll",
              }}
            >
              {todos?.map((todo) => {
                {
                  return (
                    <>
                      <Box
                        sx={{
                          borderRadius: 2,
                          padding: "12px",
                          background: "#E1E6FB",
                          mb: 1,
                        }}
                      >
                        <Box>
                          <i
                            style={{
                              color: `${
                                todo.category ? todo.category.color : "#000"
                              }`,
                              fontSize: 10,
                            }}
                            className="fa fa-circle "
                          />
                        </Box>
                        <Box sx={{ ml: 3, mt: "-17px" }}>
                          <Typography
                            variant={"body2"}
                            sx={{
                              color: "#000000",
                            }}
                          >
                            {todo.description}
                          </Typography>
                          <Typography
                            variant={"body2"}
                            sx={{
                              color: "#767C91",
                              mt: "5px",
                            }}
                          >
                            {moment
                              .unix(todo.date)
                              .local()
                              .format("YYYY/MM/DD HH:mm")}{" "}
                            -{" "}
                            {moment
                              .unix(todo.deadline_date)
                              .local()
                              .format("YYYY/MM/DD HH:mm")}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                }
              })}
            </Box>
          ) : (
            <Box
              sx={{
                background: "#ffffff",
                padding: 4,
                borderRadius: 2,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <svg
                  width="264"
                  height="176"
                  viewBox="0 0 264 176"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M77.399 43.0361L37.9043 69.4556L76.8064 127.611L116.301 101.191L77.399 43.0361Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M95.8922 98.4404L95.4451 101.214L75.8105 98.3841L76.2576 95.6104L95.8922 98.4404Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M86.0047 88.5454L88.8207 88.9502L85.6984 108.282L82.8789 107.874L86.0047 88.5454Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M67.9359 63.9761C67.9641 64.0148 64.0076 66.7147 59.0972 69.9953C54.1868 73.2759 50.1846 75.9089 50.1564 75.8666C50.1282 75.8244 54.0847 73.1281 58.9951 69.8439C63.9055 66.5598 67.8902 63.9338 67.9359 63.9761Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M82.4451 60.3294C82.4733 60.3716 75.8909 64.8209 67.7456 70.2699C59.6003 75.7188 52.9616 80.1012 52.9405 80.0625C52.9194 80.0238 59.4877 75.5675 67.6435 70.1185C75.7994 64.6696 82.417 60.2872 82.4451 60.3294Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M85.3563 64.68C85.3844 64.7222 78.802 69.175 70.6567 74.624C62.5115 80.073 55.8727 84.4553 55.8516 84.4131C55.8305 84.3709 62.4059 79.9216 70.5547 74.4691C78.7035 69.0166 85.3281 64.6378 85.3563 64.68Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M88.2677 69.0345C88.2959 69.0767 81.7135 73.5295 73.5682 78.975C65.4229 84.4204 58.7842 88.8098 58.7842 88.7676C58.7842 88.7254 65.3349 84.2726 73.4837 78.8236C81.6325 73.3746 88.2395 68.9922 88.2677 69.0345Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M91.1681 73.3885C91.1963 73.4307 84.6139 77.88 76.4686 83.329C68.3233 88.7779 61.6846 93.1603 61.667 93.1181C61.6494 93.0758 68.2283 88.6266 76.3841 83.1776C84.54 77.7286 91.1681 73.3462 91.1681 73.3885Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M209.464 82.0038L176.295 104.192L208.965 153.031L242.135 130.843L209.464 82.0038Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M224.999 128.543L224.622 130.874L208.131 128.498L208.511 126.167L224.999 128.543Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M216.694 120.232L219.06 120.574L216.437 136.808L214.068 136.467L216.694 120.232Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M201.506 99.6159C201.534 99.6582 198.215 101.929 194.089 104.685C189.964 107.441 186.599 109.644 186.57 109.613C186.542 109.581 189.865 107.304 193.987 104.544C198.109 101.784 201.478 99.5561 201.506 99.6159Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M213.703 96.536C213.731 96.5782 208.208 100.324 201.383 104.896C194.558 109.468 188.972 113.15 188.943 113.108C188.915 113.066 194.438 109.321 201.263 104.745C208.089 100.169 213.664 96.4938 213.703 96.536Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M216.148 100.19C216.177 100.232 210.654 103.977 203.828 108.553C197.003 113.129 191.417 116.804 191.389 116.762C191.361 116.72 196.866 112.992 203.709 108.416C210.552 103.84 216.127 100.151 216.148 100.19Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M218.592 103.84C218.62 103.882 213.094 107.628 206.272 112.204C199.45 116.78 193.86 120.454 193.832 120.412C193.804 120.37 199.327 116.628 206.152 112.049C212.977 107.469 218.567 103.805 218.592 103.84Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M221.057 107.504C221.085 107.547 215.562 111.288 208.737 115.864C201.911 120.44 196.325 124.115 196.297 124.076C196.269 124.038 201.792 120.289 208.617 115.713C215.442 111.137 221.011 107.462 221.057 107.504Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M156.989 45.1755L120.797 25.8184L92.2941 79.1097L128.486 98.4668L156.989 45.1755Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M122.292 81.7942L119.997 82.5792L114.168 66.4682L116.467 65.6797L122.292 81.7942Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M125.811 70.2344L126.649 72.547L110.647 78.0277L109.81 75.715L125.811 70.2344Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M137.182 46.464C137.154 46.5168 133.486 44.609 128.984 42.2048C124.482 39.8007 120.86 37.8048 120.888 37.752C120.916 37.6992 124.587 39.6071 129.089 42.0112C133.592 44.4154 137.21 46.4077 137.182 46.464Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M145.869 56.0492C145.84 56.102 139.765 52.9058 132.299 48.9141C124.833 44.9225 118.807 41.6418 118.835 41.589C118.863 41.5362 124.935 44.7289 132.401 48.7241C139.867 52.7193 145.897 55.9964 145.869 56.0492Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M143.736 60.037C143.708 60.0898 137.632 56.8971 130.166 52.9019C122.7 48.9067 116.674 45.6296 116.702 45.5768C116.73 45.524 122.802 48.7202 130.268 52.7119C137.734 56.7035 143.764 59.9842 143.736 60.037Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M141.603 64.0254C141.575 64.0782 135.499 60.8856 128.033 56.8939C120.568 52.9022 114.541 49.6322 114.569 49.5688C114.598 49.5054 120.67 52.7086 128.136 56.7003C135.601 60.692 141.631 63.9726 141.603 64.0254Z"
                    fill="#E0E0E0"
                  />
                  <path
                    d="M139.469 68.0134C139.441 68.0662 133.366 64.8736 125.9 60.8819C118.434 56.8902 112.404 53.6096 112.436 53.5568C112.467 53.504 118.536 56.6966 126.002 60.6883C133.468 64.68 139.497 67.9606 139.469 68.0134Z"
                    fill="#E0E0E0"
                  />
                  <path
                    opacity="0.2"
                    d="M139.656 0H138.748V15.481H139.656V0Z"
                    fill="black"
                  />
                  <path
                    d="M118.001 34.2107H160.403C160.403 34.2107 156.489 15.4878 138.882 15.4878C123.221 15.4878 118.001 34.2107 118.001 34.2107Z"
                    fill="#556EE6"
                  />
                  <path
                    d="M139.656 0H138.748V15.481H139.656V0Z"
                    fill="#556EE6"
                  />
                  <path
                    d="M233.611 154.82C233.611 154.869 188.115 154.912 132.003 154.912C75.8907 154.912 30.3877 154.88 30.3877 154.82C30.3877 154.76 75.8767 154.729 132.003 154.729C188.129 154.729 233.611 154.767 233.611 154.82Z"
                    fill="#263238"
                  />
                  <path
                    d="M105.248 82.5581L92.7872 146.552L91.168 154.929L174.24 154.528C177.971 154.51 181.28 151.198 182.473 146.277L197.208 85.8634C198.12 82.1287 196.12 78.2426 193.29 78.2461L109.19 78.3341C107.36 78.3306 105.72 80.0624 105.248 82.5581Z"
                    fill="#556EE6"
                  />
                  <g opacity="0.5">
                    <path
                      d="M105.248 82.5581L92.7872 146.552L91.168 154.929L174.24 154.528C177.971 154.51 181.28 151.198 182.473 146.277L197.208 85.8634C198.12 82.1287 196.12 78.2426 193.29 78.2461L109.19 78.3341C107.36 78.3306 105.72 80.0624 105.248 82.5581Z"
                      fill="black"
                    />
                  </g>
                  <path
                    d="M166.281 142.208L157.481 71.3118C157.129 68.4078 154.809 66.2395 152.074 66.25L132.785 66.3099C132.068 66.318 131.36 66.4719 130.705 66.7624C130.049 67.0529 129.46 67.4738 128.972 67.9995L119.82 77.7393L66.1757 78.028C62.8986 78.0456 60.3782 81.1502 60.8112 84.635L68.9811 150.16C69.3331 153.06 71.6528 155.225 74.3808 155.222L167.89 155.035C178.302 155.25 179.298 152.177 179.298 152.177C167.344 154.82 166.281 142.226 166.281 142.208Z"
                    fill="#556EE6"
                  />
                  <g opacity="0.5">
                    <path
                      d="M125.959 128.03L122.806 131.416L98.5176 109.564L101.671 106.178L125.959 128.03Z"
                      fill="white"
                    />
                    <path
                      d="M121.486 105.428L124.971 108.564L102.995 132.166L99.5068 129.029L121.486 105.428Z"
                      fill="white"
                    />
                  </g>
                </svg>
                <Typography
                  variant="body1"
                  style={{ color: "#6D6D6D", textAlign: "center" }}
                >
                  No data
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
        {me?.role.slug !== "citizen" && me?.role.slug !== "social-worker" && (
          <>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <WeeklyStatisticsChart
                spent={weekSpent}
                total={
                  (props.case.administration_work + props.case.face_to_face) /
                  1000
                }
                onClick={() => tabChange("time_track")}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <MonthlyStatisticsChart
                titles={monthSpent.titles}
                series={monthSpent.series}
                colors={monthSpent.colors}
                onClick={() => tabChange("time_track")}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <BudgetStatisticsChart
                value1={props.case.budget.usage}
                value2={props.case.init_budget}
                currency={props.case.budget.currency}
                onClick={() => tabChange("finance")}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <Activities />
            </Grid>
            <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
              <TimeColumnChart data={yearSpent} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Overview;
