// @ts-nocheck
import React, { useEffect, useState } from "react";
import { accountingService } from "app/services";
import Snackbar from "app/widgets/Snackbar";
import { ITaxRate } from "app/services/accounting.service";
import { Box, IconButton } from "@mui/material";
import { useIntl } from "react-intl";
import DeleteIcon from "app/partials/icons/DeleteIcon";

const Row = ({
  data,
  index,
  provided,
  snapshot,
  row,
  onDelete,
  onTitleChange,
  onColumnChangeEvent,
}) => {
  const st = { td: { width: "15%" } };
  const intl = useIntl();
  const [taxRates, setTaxRates] = useState<ITaxRate[]>([]);

  useEffect(() => {
    accountingService
      .getTaxRates()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: taxRates } = data;

        setTaxRates(taxRates);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [provided]);

  return (
    <tr
      className="mb-2"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="pl-0 d-flex align-items-center justify-content-center">
        {data.length > 1 && (
          <div className="pl-0 d-flex align-items-center justify-content-center">
            <Box
              className={{
                dragging: snapshot.isDragging,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Outline"
                viewBox="0 0 24 24"
                width="13"
                height="13"
              >
                <path d="M4.5,17.5H2a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,4.5,17.5Zm0,4.5H2V19.5H4.5Z" />
                <path d="M22,17.5H19.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V19.5A2,2,0,0,0,22,17.5ZM22,22H19.5V19.5H22Z" />
                <path d="M4.5,8.75H2a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,4.5,8.75Zm0,4.5H2v-2.5H4.5Z" />
                <path d="M22,8.75H19.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,22,8.75Zm0,4.5H19.5v-2.5H22Z" />
                <path d="M4.5,0H2A2,2,0,0,0,0,2V4.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V2A2,2,0,0,0,4.5,0Zm0,4.5H2V2H4.5Z" />
                <path d="M13.25,17.5h-2.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,13.25,17.5Zm0,4.5h-2.5V19.5h2.5Z" />
                <path d="M13.25,8.75h-2.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,13.25,8.75Zm0,4.5h-2.5v-2.5h2.5Z" />
                <path d="M13.25,0h-2.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V2A2,2,0,0,0,13.25,0Zm0,4.5h-2.5V2h2.5Z" />
                <path d="M22,0H19.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,4.5H19.5V2H22Z" />
              </svg>
            </Box>
          </div>
        )}
        <td
          style={{ width: "35%", paddingLeft: data.length > 1 ? "7px" : "0px" }}
          colSpan={data.length > 1 ? "1" : "2"}
        >
          <input
            className="form-control"
            placeholder={"Title"}
            type="text"
            value={row.title || ""}
            onChange={(e) => onTitleChange(e.target.value, index)}
          />
        </td>
        <td style={st.td}>
          <select
            style={{ width: "105px" }}
            className="custom-select"
            value={row.tax_id}
            onChange={(event) => {
              let currentSetting = taxRates.find(
                (setting) => setting._id === event.target.value
              );

              // if (!currentSetting) {
              //   if (row.tax_id === event.target.value) {
              //     currentSetting = selectedTaxRate;
              //   } else {
              //     currentSetting = false;
              //   }
              // }

              if (currentSetting) {
                onColumnChangeEvent(currentSetting._id, index, "tax_id");
                onColumnChangeEvent(currentSetting.rate, index, "vat_rate");
              }
            }}
          >
            <option value="">VatRate</option>
            {taxRates.map((item, key) => {
              return (
                <option value={item._id} key={key}>
                  {item.rate}% {item.title}
                </option>
              );
            })}
          </select>
        </td>
        <td>
          <input
            style={{ width: "70px" }}
            className="form-control"
            placeholder={"Quantity"}
            type="number"
            min={0}
            step={0.1}
            value={row.quantity || ""}
            onFocus={(e) => e.target.select()}
            onChange={(e) =>
              onColumnChangeEvent(e.target.value, index, "quantity")
            }
          />
        </td>
        <td style={st.td}>
          <input
            className="form-control"
            placeholder={"ExclTax"}
            type="number"
            step="any"
            min={0}
            value={row.price_per_unit || ""}
            onFocus={(e) => e.target.select()}
            onChange={(e) =>
              onColumnChangeEvent(e.target.value, index, "price_per_unit")
            }
          />
        </td>

        <td style={st.td}>
          <input
            className="form-control"
            placeholder={"Tax"}
            type="number"
            readOnly={true}
            min={0}
            step="any"
            value={row.tax || 0}
          />
        </td>

        <td style={st.td}>
          <input
            className="form-control"
            placeholder={"InclTax"}
            type="number"
            min={0}
            step="any"
            value={row.price_inc_tax || ""}
            onFocus={(e) => e.target.select()}
            onChange={(e) =>
              onColumnChangeEvent(e.target.value, index, "price_inc_tax")
            }
          />
        </td>
        <div className="pr-2">
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </tr>
  );
};

export default Row;
