import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "../../widgets/uncontrolled";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useForm } from "../../hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "@material-ui/core";

type Props = {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  submit: Function;
};

export type FormStateValues = {
  reason: string;
};

const initValues = {
  reason: "",
};

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    backgroundColor: "#E0F2FF",
    boxShadow: "none",
    pr: 3,
  },
}));

const ReasonModal: FC<Props> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const classes = useStyles();

  const handleClose = () => {
    props.setDialogue(false);
  };

  const submit = () => {
    props.submit(form.validate(), () => {
      form.clear();
    });
  };

  return (
    <form id={"reason_form"} onSubmit={(e) => form.handleSubmit(e, submit)}>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        TransitionComponent={Transition}
      >
        <Box className={classes.container}>
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"DELETE_REASON"}
                defaultMessage={"DELETE_REASON"}
              />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              type={"submit"}
              form={"reason_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              <Controls.Input
                name={"title"}
                label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                onChange={(event) => {
                  if (!event.target.value) return;

                  form.values.reason = event.target.value;
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </form>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ReasonModal;
