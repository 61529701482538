import React from "react";
import { DataTable } from "../../partials/layout/DataTable";
import { connect } from "react-redux";


const Partners = ({ props }: any) => {


  const tableRef = React.createRef();
  return (
    <div>
      {/*<DataTable*/}
      {/*  filter*/}
      {/*  selection*/}
      {/*  tableRef={tableRef}*/}
      {/*  title=""*/}
      {/*  data={[]}*/}
      {/*  columns={[*/}
      {/*    {*/}
      {/*      title: "Case number",*/}
      {/*      field: "id"*/}
      {/*    },*/}
      {/*    {*/}
      {/*      title: "Citizen",*/}
      {/*      field: "citizen",*/}
      {/*      lookup: {}*/}
      {/*    },*/}
      {/*    {*/}
      {/*      title: "Employee",*/}
      {/*      field: "employee",*/}
      {/*      lookup: {}*/}
      {/*    },*/}
      {/*    {*/}
      {/*      title: "Focus area",*/}
      {/*      field: "focus_area",*/}
      {/*      lookup: {}*/}
      {/*    },*/}
      {/*    {*/}
      {/*      title: "Focus type",*/}
      {/*      field: "focus_type",*/}
      {/*      lookup: {}*/}
      {/*    },*/}
      {/*    {*/}
      {/*      title: "Partner",*/}
      {/*      field: "partner",*/}
      {/*      lookup: {}*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*  actions={[*/}
      {/*    {*/}
      {/*      icon: "add",*/}
      {/*      tooltip: "Add Case",*/}
      {/*      isFreeAction: true,*/}
      {/*      onClick: () => {*/}
      {/*        console.log("onclick");*/}
      {/*      }*/}
      {/*    }*/}
      {/*  ]}*/}
      {/*/>*/}
    </div>
  );
};
const mapStateToProps = ({ i18n }: any) => {
  return {
    lang: i18n.lang,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Partners);
