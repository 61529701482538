import React, { ChangeEvent, ReactNode } from "react";
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from "@material-ui/core";

type ComponentProps = {
  label: string,
  value: Date,
  variant?: string,
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void,
}

const DatePicker = (props : ComponentProps) => {

  const { label, value, onChange, variant } = props


  const convertToDefEventPara = (name: string, value: any) => ({
    target: {
      name, value
    }
  })


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker label={label}
                     inputFormat="dd/MM/yyyy"
                     renderInput={(params: any) => <TextField {...params} variant={variant ?? 'outlined'} fullWidth />}
                     value={value}
                     onChange={onChange}

      />
    </LocalizationProvider>
  )
}

export default DatePicker
