import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import * as _setting from "app/store/ducks/setting.duck";
import { Skeleton } from "@mui/lab";
import { offerService } from "app/services";
import {
  Grid,
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Chip,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { IOffer } from "../../services/offer.service";
import { FormattedMessage, useIntl } from "react-intl";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "app/store/ducks/setting.duck";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

const ShowOffer = (props: IFormProps) => {
  const intl = useIntl();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [offer, setOffer] = useState<IOffer>();

  useEffect(() => {
    offerService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: offer } = data;
        setOffer(offer);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xl"}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            <FormattedMessage id={"OFFER.SHOW"} defaultMessage={"OFFER.SHOW"} />
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form id={"offer_form"} onSubmit={() => {}}>
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body1"
                  component="div"
                >
                  <Chip
                    label={intl.formatMessage({ id: "CASE.FOCUS_AREA" })}
                    sx={{
                      bgcolor: "primary.main",
                      color: "white.main",
                      mr: "10px",
                    }}
                  />
                  {offer?.focus_area?.title &&
                    offer?.focus_area?.title[intl.locale]}
                </Typography>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Select
                    name={"focus_area"}
                    options={[]}
                    label={intl.formatMessage({ id: "CASE.FOCUS_AREA" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body1"
                  component="div"
                >
                  <Chip
                    label={intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" })}
                    sx={{
                      bgcolor: "primary.main",
                      color: "white.main",
                      mr: "10px",
                    }}
                  />
                  {offer?.email}
                </Typography>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body1"
                  component="div"
                >
                  <Chip
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    sx={{
                      bgcolor: "primary.main",
                      color: "white.main",
                      mr: "10px",
                    }}
                  />
                  {offer?.phone}
                </Typography>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"phone"}
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: offer?.content ?? "" }}
                ></Typography>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"content"}
                    label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowOffer);
