import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import CaseChart from "../../widgets/CaseChart";
import { Grid } from "@mui/material";
import ContractChart from "../../widgets/ContractChart";
import ActiveUsers from "../../widgets/ActiveUsers";
import Todo from "../../widgets/Todo";
import OfferChart from "../../widgets/OfferChart";
import TimeRecordActivities from "../../widgets/TimeRecordActivities";

type HomeStatisticsType = "Cases" | "Todos" | "Time_tracks" | "Notes";

export interface IHomeStatistics {
  checkedModules: Array<HomeStatisticsType>;
}

export interface HomeStatisticCardProps {
  title: string;
  value: number;
  description: string;
  data: Array<number>;
  color: string;
  border: number;
}

const Home: FC<HomeStatisticCardProps> = (props) => {
  const modules = useSelector(
    (state: RootState) => state.auth.business?.modules
  );
  const { user } = useSelector(({ auth }: RootState) => auth);

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
          <CaseChart source={"home"} />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
          <ContractChart />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
          <ActiveUsers />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={8} xl={8}>
          <Todo />
        </Grid>
        {user?.role?.slug === "moderator" ||
          user?.role?.slug === "super-admin" ? (
            <>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                <OfferChart />
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4} />
              <Grid item sm={12} xs={12} md={12} lg={8} xl={8}>
                <TimeRecordActivities />
              </Grid></>
          ) : <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
          <TimeRecordActivities />
        </Grid>}

      </Grid>
    </>
  );
};
export default Home;
