// @ts-nocheck
import React, { useEffect, useReducer } from "react";

const Totals = ({ items, selectedCurrency, total }) => {
  const [state, setState] = useReducer(
    (prevState, newState) => {
      return { ...prevState, ...newState };
    },
    {
      totalExTax: 0,
      totalIncTax: 0,
      totalTax: 0,
    }
  );

  useEffect(() => {
    total(state.totalIncTax);
  }, [state]);

  const calculateTotals = (array) => {
    let totalExTax = 0;
    let totalIncTax = 0;
    let totalTax = 0;

    if (array.length) {
      array.map((row) => {
        totalExTax += parseFloat(row.price_per_unit * row.quantity);
        totalIncTax += parseFloat(row.price_inc_tax);
        totalTax += parseFloat(row.tax);
      });
    }

    setState({
      totalExTax: parseFloat(totalExTax).toFixed(2),
      totalIncTax: parseFloat(totalIncTax).toFixed(2),
      totalTax: parseFloat(totalTax).toFixed(2),
    });
  };

  useEffect(() => {
    calculateTotals(items);
  }, [JSON.stringify(items)]);

  const currencySymbol = selectedCurrency;
  return (
    <div className="totals-table-container">
      <div className="row">
        <div className="offset-md-8" />
        <div className="col-md-4 p-0">
          <table className="table">
            <tbody>
              <tr>
                <td>TotalExclTax</td>
                <td>
                  {state.totalExTax} {currencySymbol}
                </td>
              </tr>
              <tr>
                <td>TotalTax</td>
                <td>
                  {state.totalTax} {currencySymbol}
                </td>
              </tr>
              <tr>
                <td className="font-weight-bold">TotalInclTax</td>
                <td className="font-weight-bold">
                  {state.totalIncTax} {currencySymbol}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Totals;
