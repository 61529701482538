import { useIntl } from "react-intl";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { statisticService } from "../services";
import Snackbar from "./Snackbar";
import ReactApexChart from "react-apexcharts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IContractTotal } from "../services/statistic.service";
import NoData from "../partials/NoData";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@material-ui/core";

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  leftProgress: {
    "&.MuiLinearProgress-root": {
      backgroundColor: "transparent!important",
      transform: "rotate(180deg)",
    },
    "&.MuiLinearProgress-root .MuiLinearProgress-bar": {
      borderRadius: "20px",
    },
  },
}));

const CasePerformanceChart = () => {
  const options = ["all", "month", "week"];
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<string>("all");
  const [colors, setColors] = useState<Array<string>>([]);
  const [series, setSeries] = useState<Array<number>>([]);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key: string) => {
    setFilter(key);
    setAnchorEl(null);
  };

  const [contracts, setContracts] = useState<IContractTotal>();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    statisticService
      .getContractsTotal([
        {
          name: "filter",
          value: filter,
        },
      ])
      .then((data) => {
        if ("error" in data) {
          // @ts-ignore
          if ("code" in data.error) {
            throw new Error(data.error.message);
          } else {
            throw new Error(data.error);
          }
        }

        setContracts(data);

        let _colors: Array<string> = [];
        let _series: Array<number> = [];

        _colors.push(
          data.accepted?.color,
          data.pending?.color,
          data.expired?.color
        );

        setColors(_colors);

        _series.push(
          data.accepted?.count,
          data.pending?.count,
          data.expired?.count
        );

        setSeries(_series);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [filter]);

  useEffect(() => {
    if (contracts) {
      setTotal(
        contracts.expired?.count +
          contracts.pending?.count +
          contracts.accepted?.count
      );
    }
  }, [contracts]);

  return (
    <Card sx={{ padding: "0 20px", height: "316px" }}>
      <CardHeader
        titleTypographyProps={{
          fontSize: 16,
        }}
        sx={{ padding: "12px 0 12px 0" }}
        title={intl.formatMessage({ id: "STANDARD.PERFORMANCE" })}
        action={
          <>
            <IconButton
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              edge="start"
              color="inherit"
              disableFocusRipple={true}
              sx={{
                borderRadius: 0,
                padding: "8px 0 0 0",
                "&.MuiButtonBase-root:hover": {
                  backgroundColor: "transparent",
                },
              }}
              aria-label="close"
            >
              <Typography
                variant={"inherit"}
                sx={{ fontSize: "13px", textTransform: "capitalize" }}
              >
                {filter}
              </Typography>
              <ExpandMoreIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose(filter)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === filter}
                  sx={{
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    handleClose(option);
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </>
        }
      />
      <>
        <CardContent sx={{ padding: 0, paddingTop: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Box sx={{ width: "100%", marginBottom: "47px" }}>
                <LinearProgress
                  className={classes.leftProgress}
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "green",
                    },
                  }}
                  variant="determinate"
                />
              </Box>
              <Box sx={{ width: "100%", marginBottom: "47px" }}>
                <LinearProgress
                  className={classes.leftProgress}
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "green",
                    },
                  }}
                  value={80}
                  variant="determinate"
                />
              </Box>
              <Box sx={{ width: "100%", marginBottom: "47px" }}>
                <LinearProgress
                  className={classes.leftProgress}
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "green",
                    },
                  }}
                  value={60}
                  variant="determinate"
                />
              </Box>
              <Box sx={{ width: "100%", marginBottom: "47px" }}>
                <LinearProgress
                  className={classes.leftProgress}
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "green",
                    },
                  }}
                  variant="determinate"
                  value={40}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  className={classes.leftProgress}
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "green",
                    },
                  }}
                  value={20}
                  variant="determinate"
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Box sx={{ width: "100%", marginBottom: "47px" }}>
                <LinearProgress
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    backgroundColor: "transparent",
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "red",
                    },
                    "& .MuiLinearProgress-bar": {
                      borderRadius: "20px",
                    },
                  }}
                  variant="determinate"
                />
              </Box>
              <Box sx={{ width: "100%", marginBottom: "47px" }}>
                <LinearProgress
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    backgroundColor: "transparent",
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "red",
                    },
                    "& .MuiLinearProgress-bar": {
                      borderRadius: "20px",
                    },
                  }}
                  value={80}
                  variant="determinate"
                />
              </Box>
              <Box sx={{ width: "100%", marginBottom: "47px" }}>
                <LinearProgress
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    backgroundColor: "transparent",
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "red",
                    },
                    "& .MuiLinearProgress-bar": {
                      borderRadius: "20px",
                    },
                  }}
                  value={60}
                  variant="determinate"
                />
              </Box>
              <Box sx={{ width: "100%", marginBottom: "47px" }}>
                <LinearProgress
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    backgroundColor: "transparent",
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "red",
                    },
                    "& .MuiLinearProgress-bar": {
                      borderRadius: "20px",
                    },
                  }}
                  variant="determinate"
                  value={40}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  sx={{
                    height: "8px",
                    borderRadius: 12,
                    backgroundColor: "transparent",
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: "red",
                    },
                    "& .MuiLinearProgress-bar": {
                      borderRadius: "20px",
                    },
                  }}
                  value={20}
                  variant="determinate"
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </>
    </Card>
  );
};

export default CasePerformanceChart;
