// @ts-nocheck
import React from "react";
import { Lang } from "fogito-core-ui";
import Controls from "../../widgets/controls";
import { calculateDiscount } from "./items";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiInputLabel-root": {
        top: "-5px",
      },
      height: 40,
      backgroundColor: "#fff",
      border: "1px solid #E6E6E6",
      borderRadius: 8,
      m: () => ({}),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
    },
  },
}));

const Discount = ({ row, index, symbol, onDiscount }) => {
  const classes = useStyles();
  let discount_list = [{ title: "%", id: "percent" }];
  if (symbol) {
    discount_list = discount_list.concat([{ title: symbol, id: "currency" }]);
  }

  const type = row.discount?.type;

  return (
    <div className="d-flex justify-content-end align-items-center mt-2">
      <div>
        <input
          style={{ width: "121px" }}
          className="form-control"
          placeholder={Lang.get("DiscountTitle")}
          type="text"
          value={row.discount?.description || ""}
          onChange={(e) => onDiscount(index, "description", e.target.value)}
        />
      </div>
      <div className="px-2">
        <input
          style={{ width: "121px" }}
          className="form-control"
          placeholder={Lang.get("Discount")}
          type="number"
          min={0}
          step="any"
          value={row.discount?.value || ""}
          onChange={(e) => onDiscount(index, "value", parseInt(e.target.value))}
        />
      </div>
      <Box
        className={classes.input}
        sx={{
          width: "121px",
          cursor: "default",
          height: 38,
          "& .MuiOutlinedInput-root, .MuiInputBase-root, .MuiInputBase-formControl":
            { height: 38 },
        }}
      >
        <Controls.Select
          labelSx={{ top: "-5px" }}
          sx={{
            width: 105,
            height: 40,
            backgroundColor: "#fff",
            border: "1px solid #E6E6E6",
            borderRadius: 2,
            m: () => ({}),
            "& .MuiOutlinedInput-notchedOutline": {
              border: "unset",
            },
          }}
          value={discount_list.find((dis_item) => dis_item.id === type)?.id}
          onChange={(data) => {
            onDiscount(index, "type", data?.target.value);
          }}
          options={discount_list}
          label={""}
          name={"item"}
        />
      </Box>
      <div
        className="text-nowrap"
        style={{ width: "5rem", padding: "5px", textAlign: "center" }}
      >
        {calculateDiscount(row)} {symbol}
      </div>
    </div>
  );
};

export default Discount;
