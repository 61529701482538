import React, { FC } from "react";
import { Avatar, Box, Typography } from "@mui/material";

const Info: FC<{ image: string; main: string; secondary: string }> = (
  props
) => {
  const { image, main, secondary } = props;

  return (
    <Box
      sx={{
        height: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 4.6,
        marginBottom: 2.3,
      }}
    >
      <Box
        sx={{
          marginBottom: 2,
          height: "inherit",
        }}
      >
        <Avatar sx={{ height: 100, width: 100 }} src={image} />
      </Box>
      <Box
        sx={{
          marginBottom: 0.8,
        }}
      >
        <Typography fontSize={16} lineHeight={1.1} color={"#3B3B3B"}>
          {main}
        </Typography>
      </Box>
      <Box>
        <Typography fontSize={12} lineHeight={1.1} fontWeight={400}>
          {secondary}
        </Typography>
      </Box>
    </Box>
  );
};

export default Info;
