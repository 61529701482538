import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "../../widgets/uncontrolled";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import Snackbar from "../../widgets/Snackbar";
import { ISetting } from "../../services/setting.service";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  callBack?: Function;
  goalQuestions?: Array<ISetting>;
  pickedQuestions?: Array<{ _id: string; interval: string }> | null;
  checked?: Array<string>;
}

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const useStyles = makeStyles(() => ({
  tickSize: {
    transform: "scale(1.2)",
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& .MuiSelect-select": {
          padding: "15px!important",
        },
        "& fieldset": {
          //top: 0,
          border: "1px solid #0D99FF!important",
          color: "#0D99FF",
          borderRadius: 8,
        },
      },
    },
  },
}));

const PlanQuestions: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { plan_intervals } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [questions, setQuestions] = useState<
    Array<{ _id: string; interval: string }>
  >(props.pickedQuestions ?? []);
  const [checked, setChecked] = useState([""]);
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  useEffect(() => {
    setQuestions(props.pickedQuestions ?? []);

    let resetCheck: Array<string> = [];
    props.pickedQuestions?.map((q) => {
      resetCheck.push(q._id);
    });
    setChecked(resetCheck);
  }, [props.open]);

  const handleToggle = (value: string) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);

      let resetQuestions = questions?.filter((q) => q._id !== value);
      setQuestions(resetQuestions);
    }

    setChecked(newChecked);
  };

  const saveQuestions = () => {
    if (props.callBack) {
      props.callBack(questions, () => {});
    }
  };

  const handleClose = () => {
    props.setDialogue(false);
    setChecked([]);
    setQuestions([]);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      sx={{
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#E0F2FF",
          boxShadow: "none",
          p: "0px 15px",
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"CASE.PLAN.QUESTIONS"}
              defaultMessage={"CASE.PLAN.QUESTIONS"}
            />
          </Typography>
          {me?.role.slug !== "citizen" && (
            <Button
              autoFocus
              color="inherit"
              type={"submit"}
              form={"plan_questions_form"}
              onClick={(e) => {
                e.preventDefault();
                if (questions && questions?.length > 0) {
                  saveQuestions();
                  props.setDialogue(false);
                } else {
                  Snackbar.error("Choose questions");
                }
              }}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          )}
        </Toolbar>
      </Box>
      <DialogContent sx={{ padding: "0px!important", paddingTop: "10px" }}>
        <form id={"plan_questions_form"} onClick={() => {}}>
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            style={{ maxHeight: "600px", position: "relative" }}
          >
            {props.goalQuestions &&
              props.goalQuestions.map((gq, index) => (
                <Grid container sx={{ p: "0 20px 5px 13px" }}>
                  <Grid
                    item
                    xl={7}
                    lg={7}
                    md={7}
                    sm={7}
                    xs={7}
                    sx={{ display: "flex" }}
                  >
                    <ListItem key={gq._id} data-cy-id={"question_" + index}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <Checkbox
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                          checked={checked.indexOf(gq._id) !== -1}
                          value={gq._id}
                          onClick={(e) => {
                            e.preventDefault();
                            handleToggle(gq._id);
                          }}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": gq._id }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant={"body1"}>
                          {gq?.title[intl.locale] ?? "Need translate"}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </Grid>
                  <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                    <ListItemButton className={classes.input}>
                      <Controls.Select
                        name={"interval"}
                        disabled={
                          checked.indexOf(gq._id) == -1 ||
                          me?.role.slug === "citizen"
                        }
                        options={plan_intervals?.map((p_i) => {
                          return {
                            id: p_i._id.toString(),
                            title: p_i.title ? p_i.title[intl.locale] : "",
                          };
                        })}
                        defaultValue={
                          questions?.find((q) => q._id === gq._id)?.interval
                        }
                        onChange={(event) => {
                          if (!event.target.value) return;

                          let interval = event.target.value;

                          let _qs = questions?.find((q) => {
                            if (q._id === gq._id) {
                              q.interval = interval;
                              return q;
                            }
                          });

                          if (_qs) {
                            setQuestions([...questions, _qs]);
                          } else {
                            setQuestions([
                              ...questions,
                              {
                                _id: gq._id,
                                interval: event.target.value,
                              },
                            ]);
                          }
                        }}
                        label={intl.formatMessage({ id: "CASE.PLAN.INTERVAL" })}
                      />
                    </ListItemButton>
                  </Grid>
                </Grid>
              ))}
          </PerfectScrollbar>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default PlanQuestions;
