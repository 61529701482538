import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { IUrlQuery, IUser } from "../../interfaces";
import { communeService, ICommune } from "../../services/comunne.service";
import { userService } from "../../services";
import Snackbar from "../../widgets/Snackbar";
import { Box, Grid } from "@mui/material";
import Controls from "../../widgets/uncontrolled";
import { Select } from "antd";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  antSelect: {
    "& .ant-select-selector": {
      height: 40,
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
  },
}));

const CaseFilter = (props: {
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
}) => {
  const classes = useStyles();
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { focus_areas } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { focus_types } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const [communes, setCommunes] = useState<Array<ICommune>>();
  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };
  const timeOut = useRef<any>();
  const [users, setUsers] = useState<IUser[]>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: userSearch,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    userService
      .getMinlist(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    userService
      .getMinlist(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  useEffect(() => {
    communeService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
        {
          name: "alphabetical",
          value: "true",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: communes } = data;
        setCommunes(communes);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const handleUserChange = (value: string | string[]) => {
    handleQuery("user", value.toString());
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Select
            mode="multiple"
            placeholder="Users"
            defaultValue={[]}
            onChange={handleUserChange}
            onSearch={(value) => {
              sendQuery(value);
            }}
            filterOption={false}
            style={{ width: "100%" }}
            allowClear={true}
            options={
              users &&
              users.map((user) => {
                return {
                  value: user._id.toString(),
                  label: user.firstname + " " + user.lastname,
                };
              })
            }
            onClear={() => {
              sendQuery("");
            }}
            className={classes.antSelect}
            maxTagCount={"responsive"}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"focus_area"}
            options={focus_areas
              ?.sort(function (a, b) {
                if (
                  a.title[intl.locale]?.toLowerCase() <
                  b.title[intl.locale]?.toLowerCase()
                )
                  return -1;
                if (
                  a.title[intl.locale]?.toLowerCase() >
                  b.title[intl.locale]?.toLowerCase()
                )
                  return 1;
                return 0;
              })
              .map((focus_area) => {
                return {
                  id: focus_area._id.toString(),
                  title: focus_area.title[intl.locale],
                };
              })}
            label={intl.formatMessage({ id: "CASE.FOCUS_AREA" })}
            onChange={(event) => {
              handleQuery("focus_area", event.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"focus_type"}
            options={focus_types
              ?.sort(function (a, b) {
                if (
                  a.title[intl.locale]?.toLowerCase() <
                  b.title[intl.locale]?.toLowerCase()
                )
                  return -1;
                if (
                  a.title[intl.locale]?.toLowerCase() >
                  b.title[intl.locale]?.toLowerCase()
                )
                  return 1;
                return 0;
              })
              .map((focus_type) => {
                return {
                  id: focus_type._id.toString(),
                  title: focus_type.title[intl.locale],
                };
              })}
            label={intl.formatMessage({ id: "CASE.FOCUS_TYPE" })}
            onChange={(event) => {
              if (!event.target.value) return;

              handleQuery("focus_type", event.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"commune"}
            options={
              communes &&
              communes.map((commune) => {
                return {
                  id: commune._id,
                  title: `${commune.name}`,
                };
              })
            }
            label={intl.formatMessage({
              id: "CASE.INFORMATION.COMMUNE.COMMUNE",
            })}
            onChange={(event) => {
              if (!event.target.value) return;

              handleQuery("commune", event.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseFilter;
