import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import * as _setting from "app/store/ducks/setting.duck";
import { Skeleton } from "@mui/lab";
import {
  Grid,
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { communeService, ICommune } from "app/services/comunne.service";
import { FormattedMessage, useIntl } from "react-intl";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "app/store/ducks/setting.duck";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  name: string;
  email: string;
  phone: string;
  ean: string;
  cvr: string;
  address: string;
  active: number;
  is_blocked: number;
};

const initValues = {
  name: "",
  ean: "",
  email: "",
  cvr: "",
  address: "",
  phone: "",
  active: 1,
  is_blocked: 0,
} as FormStateValues;

const ShowCommune = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [mode, setMode] = useState<string>("show");
  const [commune, setCommune] = useState<ICommune>();

  useEffect(() => {
    communeService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: commune } = data;
        setCommune(commune);
        setMode("show");
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
    setMode("show");
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    communeService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (commune) {
      form.values.name = commune.name;
      form.values.email = commune.email;
      form.values.phone = commune.phone;
      form.values.ean = commune.ean;
      form.values.cvr = commune.cvr;
      form.values.address = commune.address;
      form.values.active = commune.active;
      form.values.is_blocked = commune.is_blocked;
    }
  }, [commune]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          pr: 2,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"COMMUNE.SHOW"}
              defaultMessage={"Show commune"}
            />
          </Typography>
          <Button
            form={"commune_form"}
            autoFocus
            color="inherit"
            type={"submit"}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage
              id={"DIALOGUE.SAVE"}
              defaultMessage={"DIALOGUE.SAVE"}
            />
          </Button>
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id={"commune_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && commune ? (
                <Controls.Input
                  name={"name"}
                  label={intl.formatMessage({
                    id: "CASE.INFORMATION.COMMUNE.NAME",
                  })}
                  defaultValue={commune?.name}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.name = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"name"}
                    label={intl.formatMessage({
                      id: "CASE.INFORMATION.COMMUNE.NAME",
                    })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && commune ? (
                <Controls.Input
                  multiline={true}
                  name={"phone"}
                  label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                  defaultValue={commune?.phone}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.phone = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"phone"}
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && commune ? (
                <Controls.Input
                  multiline={true}
                  name={"email"}
                  label={intl.formatMessage({
                    id: "USERS.EMAIL",
                    defaultMessage: "Email",
                  })}
                  defaultValue={commune?.email}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.email = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"phone"}
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && commune ? (
                <Controls.Input
                  multiline={true}
                  name={"ean"}
                  label={intl.formatMessage({
                    id: "COMMUNE.EAN",
                    defaultMessage: "EAN",
                  })}
                  defaultValue={commune?.ean}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.ean = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"phone"}
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && commune ? (
                <Controls.Input
                  multiline={true}
                  name={"cvr"}
                  label={intl.formatMessage({
                    id: "COMMUNE.CVR",
                    defaultMessage: "CVR",
                  })}
                  defaultValue={commune?.cvr}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.cvr = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"phone"}
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && commune ? (
                <Controls.Input
                  multiline={true}
                  name={"address"}
                  label={intl.formatMessage({
                    id: "COMMUNE.ADDRESS",
                    defaultMessage: "Address",
                  })}
                  defaultValue={commune?.address}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.address = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"phone"}
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowCommune);
