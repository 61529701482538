import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { accountingService } from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "../../hooks/useForm";
import { makeStyles } from "@material-ui/core";
import { ISetting } from "../../services/setting.service";
import InvoiceItems from "./items";
import { IAccounting } from "../../services/accounting.service";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

interface IFormProps {
  _id: string;
  open: boolean;
  onDone: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

type FormStateValues = {
  status: string;
  case: string;
  template: string;
  send_to: string;
  to_business: string | null;
  user: string;
  total: number;
  currency: string;
  paid_with: string;
  paid_at: string | null;
  overdue_date: string;
  description: string;
  articles: any[];
};

const CreditNote: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [invoice, setInvoice] = useState<IAccounting>();
  const form = useForm<FormStateValues>();
  const [currencyLabel, setCurrencyLabel] = useState<string>("$");
  const [items, setItems] = useState<any>(invoice ? invoice.items : []);
  const [data, setData] = useState<any>([]);

  const { currencies } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleClose = () => {
    props.setDialogue(false);
  };

  useEffect(() => {
    accountingService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: invoice } = data;
        setInvoice(invoice);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    accountingService
      .addCreditNote(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (invoice) {
      form.values.articles = invoice.articles;
      setItems(invoice.items);

      setData([...invoice.items, ...invoice.fines]);

      const invoiceCurrency = currencies?.find(
        (r: ISetting) => r.slug === invoice.currency
      )?.symbol;
      if (invoiceCurrency) {
        setCurrencyLabel(invoiceCurrency);
      }
    }
  }, [invoice]);

  const onChangeData = (items: any, type: string) => {
    if (type === "item") {
      setItems(items);
    }
  };

  const getTotal = (total: number) => {
    form.values.total = total;
  };

  useEffect(() => {
    form.values.articles = items;
  }, [items]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"PROJECT.SHOW"}
                  defaultMessage={"Show invoice"}
                />
              </Typography>
              <Button
                form={"invoice_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"invoice_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && invoice && data ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.ITEMS",
                            defaultMessage: "Items",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <InvoiceItems
                        {...{
                          data: items,
                          type: "item",
                          discountable: false,
                          onChangeData: onChangeData,
                          symbol: currencyLabel,
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default CreditNote;
