import { useIntl } from "react-intl";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";

const Header: FC<{
  onEdit: () => void;
  clickOnCloe: () => void;
}> = (props) => {
  const { onEdit, clickOnCloe } = props;
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Box
      id={"chatProfileHeader"}
      sx={{
        position: "sticky",
        top: 0,
        zIndex: "101",
        outline: `${theme.spacing(1.9)} solid white`, // for sticky position overflow
        display: "flex",
        backgroundColor: "white",
        justifyContent: "space-between",
        alignItems: "center",
        height: 20,
        minHeight: 20,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          gap: 1.2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={clickOnCloe}
        >
          <i
            className={"fi fi-bs-cross"}
            style={{
              fontSize: 10,
              color: "#767C91",
              cursor: "pointer",
              display: "inline-flex",
              marginBottom: "1px",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={14}
            lineHeight={0.8}
            color={"#3B3B3B"}
            fontWeight={"500"}
          >
            {intl.formatMessage({
              id: "CHAT.PROFILE",
            })}
          </Typography>
        </Box>
      </Box>
      {/*<Box*/}
      {/*  onClick={onEdit}*/}
      {/*  sx={{*/}
      {/*    cursor: "pointer",*/}
      {/*    display: "flex",*/}
      {/*    justifyContent: "center",*/}
      {/*    alignItems: "center",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <i*/}
      {/*    className="fi fi-sr-edit"*/}
      {/*    style={{*/}
      {/*      lineHeight: "0.5em",*/}
      {/*      verticalAlign: "-0.15em",*/}
      {/*      display: "inline-flex",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Box>*/}
    </Box>
  );
};

export default Header;
