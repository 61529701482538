import {API_ROUTES, getRoute} from "../config/apiRoutes";
import {IRestApiCollection, IRestApiError, IRestApiResource, IUrlQuery} from "../interfaces";
import {generateFormData, queryToString} from "../utils/http";
import {http} from "../lib/http";

export interface IModule {
    _id: string,
    title: string
}

const INDEX = API_ROUTES.modules;
const SHOW = API_ROUTES.modules;
const UPDATE = API_ROUTES.modules;
const DELETE = API_ROUTES.modules;

export interface IModuleService {
    getAll(queries?: Array<IUrlQuery>): Promise<IRestApiCollection<IModule> | IRestApiError>,
    getOne(_id: string): Promise<IRestApiResource<IModule> | IRestApiError>,
    update(_id: string, data: IModule): Promise<IRestApiResource<IModule> | IRestApiError>,
    add(data: any): Promise<IRestApiResource<any> | IRestApiError>,
    delete(_id: string): Promise<IRestApiCollection<IModule> | IRestApiError>,
}

const moduleService: IModuleService = {
    async getAll(queries?: Array<IUrlQuery>) {
        let queriesString = queries ? `?${queryToString(queries)}` : '';
        return  http(`${getRoute(INDEX)}${queriesString}`, "get");
    },
    async getOne(_id: string) {
        return  http(`${getRoute(SHOW).replace(':_id', _id.toString())}`, "get");
    },
    async update(_id: string, data: IModule) {
        let jsonData = JSON.stringify(data);
        return  http(`${getRoute(UPDATE).replace(':_id', _id.toString())}`, "put", jsonData);
    },
    async add(data: any) {
        let formData = generateFormData(new FormData(), data);
        return http(getRoute(INDEX), "post", formData);
    },
    async delete(_id: string,queries?: Array<IUrlQuery>) {
        let queriesString = queries ? `?${queryToString(queries)}` : '';
        return http(`${getRoute(DELETE.replace(':_id', _id.toString()))}${queriesString}`, "delete");
    },
}

export {moduleService}
