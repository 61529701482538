import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Topbar from "./Topbar";
import HMenu from "./HMenu/HMenu";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";
import { useTheme } from "@mui/material/styles";
import store, { RootState } from "../../../app/store/store";

const Header = (props: {
  menuHeaderDisplay: boolean;
  headerAttributes: Array<any>;
  headerClasses: string;
}) => {
  const headerCommonRef = useRef<HTMLDivElement>(null);
  const { menuHeaderDisplay, headerAttributes, headerClasses } = props;
  const theme = useTheme();

  useEffect(() => {
    let options: { [key in string]: string | number } = {};

    if (
      headerCommonRef.current?.getAttribute("data-ktheader-minimize") === "1"
    ) {
      options["minimize.desktop.on"] = "kt-header--minimize";
      options["offset.desktop"] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(headerCommonRef.current, options);
  });

  return (
    <div
      className={`kt-header kt-grid__item ${headerClasses}`}
      style={{
        backgroundColor: theme.palette.background.default,
      }}
      id="kt_header"
      ref={headerCommonRef}
      {...headerAttributes}
    >
      <AnimateLoading />
      {menuHeaderDisplay && <HMenu />}
      {!menuHeaderDisplay && <div />}
      <Topbar />
      {/* <!-- end:: Header Topbar --> */}
    </div>
  );
};

const mapStateToProps = (store: RootState) => ({
  headerClasses: builder.selectors.getClasses(store, {
    path: "header",
    toString: true,
  }),
  headerAttributes: builder.selectors.getAttributes(store, { path: "header" }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    "header.menu.self.display"
  ),
  fluid:
    objectPath.get(store.builder.layoutConfig, "header.self.width") === "fluid",
});

export default connect(mapStateToProps)(Header);
