import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../../partials/layout/DataTable";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { incomingOfferService } from "app/services";
import moment from "moment";
import { useTable } from "../../../hooks/useTable";
import { IIncomingOffer } from "../../../services/incomingOffer.service";
import { GridTable } from "../../../partials/layout/GridTable";
import ShowIncomingOffer from "./show";
import { useIntl } from "react-intl";
import { PermissionsGate } from "../../../permission/PermissionsGate";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import { Ii18nState } from "../../../store/ducks/i18n.duck";
import { ISettingsState } from "../../../store/ducks/setting.duck";
import IncomingOfferNoteFilter from "./filter";
import { usePermissions } from "../../../hooks/useRole";
import EditIcon from "../../../partials/icons/EditIcon";

type Props = {
  lang: string;
  settings_loading: boolean;
};

const IncomingOffers = (props: Props) => {
  const intl = useIntl();
  const permissions = usePermissions();
  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<IIncomingOffer>({
      fetch: incomingOfferService.getAll,
      delete: incomingOfferService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 160,
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "CASE.INFORMATION.COMMUNE.NAME" }),
      field: "name",
      valueGetter: (params) => {
        return params.row?.name;
      },
      minWidth: 300,
    },
    {
      headerName: intl.formatMessage({ id: "AUTH.INPUT.EMAIL" }),
      field: "email",
      valueGetter: (params) => {
        return params.row?.email;
      },
      minWidth: 250,
    },
    {
      headerName: intl.formatMessage({ id: "USERS.PHONENUMBER" }),
      field: "phone",
      valueGetter: (params) => {
        return params.row?.phone;
      },
      minWidth: 250,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "_created_at",
      valueGetter: (params) => {
        return moment.unix(params.row?._created_at).utc().format("DD/MM/YYYY");
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"incoming_offers"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"incoming_offer_show"}
                data-cy-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"incoming_offers"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"incoming_offer_delete"}
                data-cy-id={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [incomingOfferId, setIncomingOfferId] = useState<string | null>(null);

  const show = (_id: string) => {
    setIncomingOfferId(_id);
  };
  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"incoming_offers"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete incoming offer?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this incoming offer?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"incoming_offers"} scope={"read"}>
        {incomingOfferId ? (
          <ShowIncomingOffer
            _id={incomingOfferId}
            open={Boolean(incomingOfferId)}
            setDialogue={() => setIncomingOfferId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"incoming_offers"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            // actionPanel:
            //   permissions &&
            //   hasPermission(permissions, "incoming_offers", "create")
            //     ? (props: { open: boolean; close: () => void }) => {
            //         return (
            //           <IncomingOfferStore
            //             open={props.open}
            //             setDialogue={props.close}
            //             onDone={refresh}
            //           />
            //         );
            //       }
            //     : undefined,
            filters: (props: { show: boolean }) => {
              return (
                <IncomingOfferNoteFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingOffers);
