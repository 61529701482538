import React, { useEffect, useRef, useState } from "react";
import { Avatar, Grid, IconButton } from "@mui/material";
import { IColumns } from "../../../partials/layout/DataTable";
import { IUser } from "../../../interfaces";
import { useParams } from "react-router";
import { useTable } from "../../../hooks/subresource/useTable";
import { GridTable } from "../../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import { userService } from "../../../services";
import {
  hasPermission,
  PermissionsGate,
} from "../../../permission/PermissionsGate";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import Badge from "@mui/material/Badge";
import { ICase } from "../../../services/case.service";
import EditIcon from "../../../partials/icons/EditIcon";
import DeleteIcon from "../../../partials/icons/DeleteIcon";
import NewCase from "../../Cases/store";
import { usePermissions } from "../../../hooks/useRole";
import UserCaseFilter from "../filters/caseFilter";
import { stringAvatar } from "../../../widgets/AvatarBackground";
import { useHistory } from "react-router-dom";
import UserAvatar from "../../../partials/layout/UserAvatar";

const Cases = () => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const { data, xhrLoading, remove, updateQuery, refresh } = useTable<ICase>({
    fetch: userService.getCases,
    delete: userService.deleteCase,
    param: id,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const permissions = usePermissions();
  const history = useHistory();

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.USERS" }),
      field: "users",
      renderCell: (params) => {
        return (
          <Grid container>
            {params.row?.users?.map((user: IUser) => {
              return <UserAvatar user={user} />;
            })}
          </Grid>
        );
      },
      minWidth: 300,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.FOCUS_TYPE" }),
      field: "focus_area_type",
      valueGetter: (params) => {
        return (
          params.row?.focus_type?.title &&
          params.row?.focus_type?.title[intl.locale]
        );
      },
      minWidth: 250,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.FOCUS_AREA" }),
      field: "focus_area_id",
      valueGetter: (params) => {
        return (
          params.row?.focus_area?.title &&
          params.row?.focus_area?.title[intl.locale]
        );
      },
      minWidth: 250,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"cases"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"cases"} scope={"delete"}>
              <IconButton onClick={() => handleClick(params.row?._id)}>
                <DeleteIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (_id: string) => {
    const url = `/cases/${_id}`;
    history.push(url);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"cases"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete case?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(id, confirmId);
            }}
          >
            Are you sure you want to delete this case?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"cases"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "cases", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <NewCase
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            filters: (props: { show: boolean }) => {
              return (
                <UserCaseFilter show={props.show} updateQuery={updateQuery} />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default Cases;
