import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Dialog,
  Toolbar,
  DialogContent,
  Slide,
  Grid,
  Skeleton,
  TextField,
  Divider,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useForm } from "../../hooks/useForm";
import { makeStyles } from "@material-ui/core";
import Snackbar from "../../widgets/Snackbar";
import { businessService, moduleService, userService } from "app/services";
import { IModule } from "app/services/module.service";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "../../widgets/uncontrolled";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import * as auth from "app/store/ducks/auth.duck";
import Uploader from "./uploader";
import { ISetting } from "../../services/setting.service";

export type FormStateValues = {
  name: string;
  description: string;
  address: string;
  phone: string;
  email: string;
  vat_id: string;
  rn: string;
  an: string;
  currency: string;
  modules: Array<string>;
  active: number;
  file: string;
};

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

const initValues = {
  name: "",
  description: "",
  address: "",
  phone: "",
  vat_id: "",
  rn: "",
  an: "",
  currency: "",
  email: "",
  modules: [],
  active: 1,
  file: "",
} as FormStateValues;

const useStyle = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  container: {
    width: "100%",
    padding: "0px",
    display: "flex",
    flexFlow: "column wrap",
    height: 250, // set the height limit to your liking
  },
  item: {
    width: "auto",
  },
}));

const BusinessStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { user } = useSelector(({ auth }: RootState) => auth);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([""]);
  const [modules, setModules] = useState<Array<IModule>>([]);
  const classes = useStyle();

  const { currencies } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    businessService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        props.setDialogue(false);
        props.onDone();
        form.values = {
          active: 0,
          description: "",
          phone: "",
          address: "",
          vat_id: "",
          rn: "",
          an: "",
          currency: "",
          email: "",
          file: "",
          modules: [],
          name: "",
        };
        setChecked([]);

        if (user) {
          userService.getOne(user?._id).then((data) => {
            if ("error" in data) {
              throw Error(data.error.message);
            }
            dispatch(auth.actions.fulfillUser(data.data));
          });
        }
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    moduleService
      .getAll()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: modules } = data;
        setModules(modules);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);
  const handleToggle = (value: string) => {
    console.log("value", value);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    if (form.values.modules?.includes(value)) {
      form.values.modules = form.values.modules?.filter((m) => m !== value);
    } else {
      form.values.modules?.push(value);
    }
  };

  const handleClose = () => {
    props.setDialogue(false);
    form.clear();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
      TransitionComponent={Transition}
      sx={{
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "rgb(245, 245, 245)",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"BUSINESS.ADD"}
              defaultMessage={"BUSINESS.ADD"}
            />
          </Typography>
          <Button
            autoFocus
            color="inherit"
            type={"submit"}
            form={"business_form"}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage
              id={"DIALOGUE.SAVE"}
              defaultMessage={"DIALOGUE.SAVE"}
            />
          </Button>
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id="business_form"
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage id={"AVATAR"} defaultMessage={"AVATAR"} />
                  </Typography>
                  <Uploader
                    afterUpload={(file_id: string) =>
                      (form.values.file = file_id)
                    }
                    accept={".png,.jpg,.jpeg,"}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"business"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"ECOMMERCE.COMMON.BUSINESS"}
                      defaultMessage={"ECOMMERCE.COMMON.BUSINESS"}
                    />
                  </Typography>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.name = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={form.values.name}
                    name={"business"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"business"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"CASE.INFORMATION.EMAIL"}
                      defaultMessage={"CASE.INFORMATION.EMAIL"}
                    />
                  </Typography>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.email = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={form.values.email}
                    name={"email"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"STANDARD.VATID"}
                      defaultMessage={"VAT ID"}
                    />
                  </Typography>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.vat_id = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={form.values.vat_id}
                    name={"vat_id"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"USERS.PRN"}
                      defaultMessage={"USERS.PRN"}
                    />
                  </Typography>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.rn = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={form.values.rn}
                    name={"rn"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"USERS.PAN"}
                      defaultMessage={"USERS.PAN"}
                    />
                  </Typography>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.an = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={form.values.an}
                    name={"an"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
              <Box className={classes.input}>
                <Divider sx={{ mb: 1 }}>
                  <Chip
                    label={intl.formatMessage({
                      id: "STANDARD.DEFAULT_CURRENCY",
                      defaultMessage: "Default currency",
                    })}
                    sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                  />
                </Divider>
                <Controls.Select
                  options={
                    currencies &&
                    currencies.map((currency) => {
                      return {
                        id: currency.slug,
                        title: currency.currency_title ?? "",
                      };
                    })
                  }
                  formId={"status_pick"}
                  name={"status"}
                  label={""}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.currency = event.target.value;
                  }}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded ? (
                <Box className={classes.input}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "STANDARD.ADDRESS",
                        defaultMessage: "Address",
                      })}
                      sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                    />
                  </Divider>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.address = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={form.values.address}
                    name={"address"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
              {loaded ? (
                <Box className={classes.input}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "STANDARD.PHONE",
                        defaultMessage: "Phone",
                      })}
                      sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                    />
                  </Divider>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.phone = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={form.values.phone}
                    name={"phone"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"STANDARD.DESCRIPTION"}
                      defaultMessage={"STANDARD.DESCRIPTION"}
                    />
                  </Typography>
                  <TextField
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.description = e.target.value;
                      e.preventDefault();
                    }}
                    multiline={true}
                    rows={4}
                    defaultValue={form.values.description}
                    name={"description"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"description"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant={"body1"} sx={{ mt: 1 }}>
              <FormattedMessage
                id={"BUSINESS.MODULES"}
                defaultMessage={"BUSINESS.MODULES"}
              />
            </Typography>
            <List className={classes.container}>
              {modules.map((module) => {
                const labelId = `checkbox-list-label-${module}`;
                return (
                  <ListItem
                    key={module._id}
                    disablePadding
                    className={classes.item}
                  >
                    <ListItemButton
                      sx={{ padding: "0px 2px" }}
                      role={undefined}
                      onClick={() => {
                        handleToggle(module._id);
                      }}
                      dense
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 34,
                          "& .Mui-checked": {
                            color: "#0D99FF!important",
                          },
                        }}
                      >
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(module._id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          value={module._id}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={
                          module.title.charAt(0).toUpperCase() +
                          module.title.slice(1)
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default BusinessStore;
