import React, { ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  Tooltip,
  OutlinedInput,
  Theme,
} from "@mui/material";
import { SxProps } from "@mui/system";

type ComponentProps = {
  name: string;
  label: string;
  sx?: SxProps<Theme> | undefined;
  labelSx?: SxProps<Theme> | undefined;
  _maxWidth?: string;
  formId?: string;
  disableText?: string;
  value?: number | string;
  disabled?: boolean;
  defaultValue?: Array<string>;
  error?: string;
  onChange: (event: SelectChangeEvent<any>, child: ReactNode) => void;
  options?: Array<{ id: number | string; title: string } | undefined>;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelect = (props: ComponentProps) => {
  // const ref = useRef<HTMLElement>();
  //
  // // const trigger = useScrollTrigger({
  // //   threshold: 0,
  // //   target: window,
  // //   disableHysteresis: false
  // //   // target: ref.current ? ref.current : undefined,
  // // });
  // //
  // // useEffect(() => {
  // //   console.log('trigger', trigger)
  // // }, [trigger])

  const {
    name,
    formId,
    label,
    _maxWidth = null,
    value,
    defaultValue,
    error = null,
    labelSx = null,
    sx = null,
    onChange,
    options,
    disabled,
    disableText,
  } = props;

  return (
    <FormControl
      variant="outlined"
      fullWidth
      disabled={disabled ?? false}
      {...(error && { error: true })}
    >
      <InputLabel id="demo-multiple-chip-label" sx={labelSx}>
        {label}
      </InputLabel>
      <Tooltip title={disabled ? disableText ?? "" : ""}>
        <MuiSelect
          labelId="demo-multiple-chip-label"
          sx={sx}
          id={formId}
          fullWidth
          label={label}
          name={name}
          // value={ [{}] as Array<{'id': string, 'title': string}>}
          defaultChecked={true}
          defaultValue={defaultValue ?? []}
          onChange={onChange}
          multiple
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          MenuProps={MenuProps}
        >
          {options &&
            options.map(
              (item) =>
                item && (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                )
            )}
        </MuiSelect>
      </Tooltip>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default MultipleSelect;
