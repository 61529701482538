import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { connect, useSelector } from "react-redux";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { folderService, noteService } from "app/services";
import moment from "moment";
import { useTable } from "app/hooks/useTable";
import { GridTable } from "app/partials/layout/GridTable";
import { IFolder } from "app/services/folder.service";
import FolderStore from "./Modal/store";
import ShowFolder from "./Modal/show";
import { useIntl } from "react-intl";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { hasPermission, PermissionsGate } from "app/permission/PermissionsGate";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "../../store/ducks/setting.duck";
import { usePermissions } from "app/hooks/useRole";
import EditIcon from "../../partials/icons/EditIcon";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store/store";
import { stringAvatar } from "../../widgets/AvatarBackground";

const Folders = () => {
  const intl = useIntl();
  const history = useHistory();
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const permissions = usePermissions();
  const [quickCardsCount, setQuickCardsCount] = useState<{
    moderator: number;
    employee: number;
  }>();
  const { user } = useSelector(({ auth }: RootState) => auth);

  const resetCardQuery = (
    isActive: boolean,
    name: "moderator" | "employee"
  ) => {
    let _queries = queries?.filter(
      (q) => q.name !== "moderator" && q.name !== "employee"
    );

    if (!_queries) return;

    if (isActive && queries && _queries) {
      let __queries = _queries.filter((q) => q.name !== name);
      setQuery(__queries);
    } else {
      setQuery([
        ..._queries,
        {
          name: name,
          value: "1",
        },
      ]);
    }
  };

  const { data, xhrLoading, remove, queries, setQuery, refresh, updateQuery } =
    useTable<IFolder>({
      fetch: folderService.getAll,
      delete: folderService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return params.row?.title && params.row?.title[intl.locale];
      },
      minWidth: 300,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 160,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.CREATED_BY" }),
      field: "created_by",
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                backgroundColor:
                  params?.row?.created_by?.role?.color ?? "#0D99FF",
                border:
                  "2px solid " + params?.row?.created_by?.role?.color ??
                  "#0D99FF",
              }}
              alt="Remy Sharp"
              sx={{
                backgroundColor: "#0D99FF",
                lineHeight: "0!important",
                width: 32,
                height: 32,
              }}
              src={params.row?.created_by?.file?.base64}
            >
              {params.row?.created_by?.firstname.charAt(0) +
                params.row?.created_by?.lastname.charAt(0)}
            </Avatar>
            <Typography sx={{ mt: 2 }} variant={"body1"}>
              {params.row?.created_by?.firstname +
                " " +
                params.row?.created_by?.lastname}
            </Typography>
          </Stack>
        );
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => showNotes(params.row?._id)}>
              <FolderIcon sx={{ color: "warning.main" }} />
            </IconButton>
            <PermissionsGate section={"notes"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"folder_show"}
                data-cy={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"notes"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"folder_remove"}
                data-cy={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];
  useEffect(() => {
    noteService.getFoldersQuickCardsCount().then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const { data: counts } = data;

      setQuickCardsCount(counts);
    });
  }, [queries]);

  const [folderId, setFolderId] = useState<string | null>(null);

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (_id: string) => {
    setFolderId(_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const showNotes = (_id: string) => {
    const url = `/folders/${_id}/notes`;
    // window.open(url);
    history.push(url);
  };

  const quickCards = [
    {
      title: intl.formatMessage({ id: "EMPLOYEE_NOTES" }),
      onClick: (isActive: boolean) => resetCardQuery(isActive, "employee"),
      icon: "fi-sr-time-delete",
      value: quickCardsCount ? quickCardsCount.employee : 0,
    },
  ];

  if (user?.role?.slug === "moderator" || user?.role?.slug === "super-admin") {
    quickCards.push({
      title: intl.formatMessage({ id: "MODERATOR_NOTES" }),
      onClick: (isActive: boolean) => resetCardQuery(isActive, "moderator"),
      icon: "fi-sr-inbox",
      value: quickCardsCount ? quickCardsCount.moderator : 0,
    });
  }

  return (
    <>
      <PermissionsGate section={"notes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete folder?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this folder?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"notes"} scope={"read"}>
        {folderId ? (
          <ShowFolder
            _id={folderId}
            open={Boolean(folderId)}
            setDialogue={() => setFolderId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"notes"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "notes", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <FolderStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            quickCards,
          }}
        />
      </PermissionsGate>
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Folders);
