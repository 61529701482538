import React, { Dispatch, FC, SetStateAction, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "../../hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Controls from "../../widgets/uncontrolled";
import Snackbar from "../../widgets/Snackbar";
import { vacancyService } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  full_name: string;
  birthdate: string;
  gender: string;
  email: string;
  phone: string;
  address: string;
  post_number: string;
  city: Array<{ id: string; title: string }>;
  languages: Array<{ id: number; title: string }>;
  education_1: string;
  education_2: string;
  education_3: string;
  work_position: string;
  work_experience: string;
  adhd: boolean;
  autism: boolean;
  borderline: boolean;
  ptsd: boolean;
  ocd: boolean;
  criminal: boolean;
  drug_and_alcohol_abuse: boolean;
  physically_vulnerable: boolean;
  eating_disorder: boolean;
  handicap: boolean;
  diabetes: boolean;
  application_year: string;
  last_updated: string;
  age: string;
  status: number;
};

const initValues = {
  full_name: "",
  birthdate: "",
  gender: "",
  email: "",
  phone: "",
  address: "",
  post_number: "",
  city: [],
  languages: [],
  education_1: "",
  education_2: "",
  education_3: "",
  work_position: "",
  work_experience: "",
  adhd: false,
  autism: false,
  borderline: false,
  ptsd: false,
  ocd: false,
  eating_disorder: false,
  criminal: false,
  drug_and_alcohol_abuse: false,
  physically_vulnerable: false,
  handicap: false,
  diabetes: false,
  application_year: "",
  last_updated: "",
  age: "",
  status: 1,
} as FormStateValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.23)",
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const VacancyStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const classes = useStyles();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const { vacancy_cities } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { vacancy_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { vacancy_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [birthdate, setBirthdate] = useState<Date | null>(null);

  const handleBirthdateChange = (date: Date | null) => {
    setBirthdate(date);
  };

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    vacancyService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"VACANCY.ADD"}
                  defaultMessage={"VACANCY.ADD"}
                />
              </Typography>
              <Button
                form={"vacancy_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"vacancy_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"full_name"}
                      label={intl.formatMessage({ id: "VACANCY.FULLNAME" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.full_name = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"full_name"}
                        label={intl.formatMessage({ id: "VACANCY.FULLNAME" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.DatePicker
                      name={"birthdate"}
                      label={intl.formatMessage({ id: "USERS.BIRTHDAY" })}
                      onChange={(date) => {
                        if (!date) return;

                        handleBirthdateChange(date);

                        form.values.birthdate =
                          moment(date).format("YYYY-MM-DD");
                      }}
                      value={birthdate}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.DatePicker
                        name={"birthdate"}
                        label={intl.formatMessage({ id: "USERS.BIRTHDAY" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Select
                      name={"gender"}
                      formId={"gender"}
                      options={[
                        {
                          id: "male",
                          title: intl.formatMessage({ id: "USERS.MALE" }),
                        },
                        {
                          id: "female",
                          title: intl.formatMessage({ id: "USERS.FEMALE" }),
                        },
                      ]}
                      label={intl.formatMessage({ id: "USERS.GENDER" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.gender = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"gender"}
                        options={[]}
                        label={intl.formatMessage({ id: "USERS.GENDER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"age"}
                      label={intl.formatMessage({ id: "VACANCY.AGE" })}
                      type={"number"}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.age = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"age"}
                        label={intl.formatMessage({ id: "VACANCY.AGE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"email"}
                      label={intl.formatMessage({
                        id: "CASE.INFORMATION.EMAIL",
                      })}
                      type={"email"}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.email = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"email"}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.EMAIL",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"phone"}
                      label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.phone = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"phone"}
                        label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"address"}
                      label={intl.formatMessage({ id: "STANDARD.ADDRESS" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.address = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"address"}
                        label={intl.formatMessage({ id: "STANDARD.ADDRESS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"post_number"}
                      label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.post_number = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"post_number"}
                        label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Select
                      name={"city"}
                      formId={"city"}
                      options={vacancy_cities?.map((city) => {
                        return {
                          id: city._id.toString(),
                          title: city.title[intl.locale],
                        };
                      })}
                      label={intl.formatMessage({ id: "USERS.CITY" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.city = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"city"}
                        options={[]}
                        label={intl.formatMessage({ id: "USERS.CITY" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.MultipleSelect
                      name={"languages"}
                      formId={"languages"}
                      options={vacancy_languages?.map((vacancy_language) => {
                        return {
                          id: vacancy_language._id,
                          title:
                            vacancy_language.title[intl.defaultLocale] ??
                            "Undefined",
                        };
                      })}
                      label={intl.formatMessage({ id: "USERS.LANGUAGES" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.languages = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"languages"}
                        options={[]}
                        label={intl.formatMessage({ id: "USERS.LANGUAGES" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"education_1"}
                      label={intl.formatMessage({ id: "VACANCY.EDUCATION1" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.education_1 = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"education_1"}
                        label={intl.formatMessage({ id: "VACANCY.EDUCATION1" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"education_2"}
                      label={intl.formatMessage({ id: "VACANCY.EDUCATION2" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.education_2 = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"education_2"}
                        label={intl.formatMessage({ id: "VACANCY.EDUCATION2" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"education_3"}
                      label={intl.formatMessage({ id: "VACANCY.EDUCATION3" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.education_3 = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"education_3"}
                        label={intl.formatMessage({ id: "VACANCY.EDUCATION3" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"work_position"}
                      label={intl.formatMessage({ id: "VACANCY.WORKPOSITION" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.work_position = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"work_position"}
                        label={intl.formatMessage({
                          id: "VACANCY.WORKPOSITION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"work_experience"}
                      label={intl.formatMessage({
                        id: "VACANCY.WORKEXPERIENCE",
                      })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.work_experience = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"work_experience"}
                        label={intl.formatMessage({
                          id: "VACANCY.WORKEXPERIENCE",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"application_year"}
                      label={intl.formatMessage({
                        id: "VACANCY.APPLICATIONYEAR",
                      })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.application_year = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"application_year"}
                        label={intl.formatMessage({
                          id: "VACANCY.APPLICATIONYEAR",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Input
                      name={"last_updated"}
                      label={intl.formatMessage({ id: "VACANCY.LASTUPDATED" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.last_updated = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"last_updated"}
                        label={intl.formatMessage({
                          id: "VACANCY.LASTUPDATED",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Controls.Select
                      name={"status"}
                      formId={"status"}
                      options={vacancy_statuses?.map((vacancy_status) => {
                        return {
                          id: vacancy_status._id.toString(),
                          title: vacancy_status.title[intl.locale],
                        };
                      })}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.status = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"status"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.adhd = event.target.checked;
                          }}
                          id={"adhd"}
                        />
                      }
                      label="ADHD"
                      name={"adhd"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="ADHD"
                        name={"adhd"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.autism = event.target.checked;
                          }}
                          id={"autism"}
                        />
                      }
                      label={intl.formatMessage({ id: "VACANCY.AUTISM" })}
                      name={"autism"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: "VACANCY.AUTISM" })}
                        name={"autism"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.borderline = event.target.checked;
                          }}
                          id={"borderline"}
                        />
                      }
                      label={intl.formatMessage({ id: "VACANCY.BORDERLINE" })}
                      name={"borderline"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: "VACANCY.BORDERLINE" })}
                        name={"borderline"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.ptsd = event.target.checked;
                          }}
                          id={"ptsd"}
                        />
                      }
                      label="PTSD"
                      name={"ptsd"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="PTSD"
                        name={"ptsd"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.ocd = event.target.checked;
                          }}
                          id={"ocd"}
                        />
                      }
                      label="OCD"
                      name={"ocd"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="OCD"
                        name={"ocd"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.criminal = event.target.checked;
                          }}
                          id={"criminal"}
                        />
                      }
                      label={intl.formatMessage({ id: "VACANCY.CRIMINAL" })}
                      name={"criminal"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: "VACANCY.CRIMINAL" })}
                        name={"criminal"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.drug_and_alcohol_abuse =
                              event.target.checked;
                          }}
                          id={"drug_and_alcohol_abuse"}
                        />
                      }
                      label={intl.formatMessage({ id: "VACANCY.DAAB" })}
                      name={"drug_and_alcohol_abuse"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: "VACANCY.DAAB" })}
                        name={"drug_and_alcohol_abuse"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.physically_vulnerable =
                              event.target.checked;
                          }}
                          id={"physically_vulnerable"}
                        />
                      }
                      label={intl.formatMessage({
                        id: "VACANCY.PHYSICALLYVULNERABLE",
                      })}
                      name={"physically_vulnerable"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({
                          id: "VACANCY.PHYSICALLYVULNERABLE",
                        })}
                        name={"physically_vulnerable"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.diabetes = event.target.checked;
                          }}
                          id={"diabetes"}
                        />
                      }
                      label={intl.formatMessage({
                        id: "VACANCY.RELATEDDISORDERS",
                      })}
                      name={"diabetes"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({
                          id: "VACANCY.RELATEDDISORDERS",
                        })}
                        name={"diabetes"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.handicap = event.target.checked;
                          }}
                          id={"handicap"}
                        />
                      }
                      label={"Handicap"}
                      name={"handicap"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={"Handicap"}
                        name={"handicap"}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={5} xl={5}>
                  {loaded ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            form.values.eating_disorder = event.target.checked;
                          }}
                          id={"eating_disorder"}
                        />
                      }
                      label={intl.formatMessage({
                        id: "VACANCY.EATINGDISORDERS",
                      })}
                      name={"eating_disorder"}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={intl.formatMessage({
                          id: "VACANCY.EATINGDISORDERS",
                        })}
                        name={"eating_disorder"}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default VacancyStore;
