import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { IUser } from "../../../../../interfaces";

const Actions: FC<{
  onDeleteClick: () => void;
}> = (props) => {
  const { onDeleteClick } = props;
  const intl = useIntl();

  const actionsSX = {
    display: "flex",
    justifyContent: "flex-start",
    gap: 2,
    cursor: "pointer",
    borderRadius: 1,
    mx: -1,
    px: 1,
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  };

  const iconContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box
      sx={{
        height: 100,
        marginBottom: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box>
        <Typography lineHeight={"24px"} fontSize={16} color={"#6D6D6D"}>
          {intl.formatMessage({
            id: "CHAT.ACTIONS",
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Box sx={actionsSX} onClick={onDeleteClick}>
          <Box sx={iconContainer}>
            <i
              className={"fi fi-sr-trash"}
              style={{
                fontSize: 15,
                color: "#EC3033",
                display: "inline-flex",
                marginBottom: "1px",
              }}
            />
          </Box>
          <Box>
            <Typography color={"#3B3B3B"} lineHeight={"24px"}>
              {intl.formatMessage({
                id: "CHAT.DELETE_GROUP",
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Actions;
