import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { FormattedMessage, useIntl } from "react-intl";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "../../../widgets/uncontrolled";
import { IUser } from "../../../interfaces";
import { useForm } from "../../../hooks/useForm";
import Snackbar from "../../../widgets/Snackbar";
import { userService } from "../../../services";
import { FormStateValues } from "../store";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { makeStyles } from "@material-ui/core";
import { AutocompletePrediction } from "../../../partials/content/TimeTrack";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import * as auth from "../../../store/ducks/auth.duck";
import { useDispatch } from "react-redux";

interface Props {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  user: IUser | undefined;
  userRefresh: Function;
}

const useStyles = makeStyles(() => ({
  antDropdown: {
    zIndex: 9999,
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.23)",
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  signature: {
    padding: "16px 0 0 16px",
    "& .kt-portlet": {
      "& .kt-portlet__head": {
        display: "none!important",
      },
      "& .kt-portlet__body": {
        border: "1px solid #E6E6E6!important",
        borderRadius: 6,
        p: 0,
      },
    },
  },
}));

const EditSibling: FC<Props> = (props) => {
  const form = useForm<FormStateValues>();
  const intl = useIntl();
  const classes = useStyles();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [addressOptions, setAddressesOptions] = useState<
    Array<AutocompletePrediction>
  >([]);
  const [search, setSearch] = useState<string>("");
  const [address, setAddress] = useState<AutocompletePrediction | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (search.length > 0) searchAddress();
  }, [search]);

  const searchAddress = () => {
    let service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: search,
      },
      (res) => {
        if (res) {
          setAddressesOptions(res);
        }
      }
    );
  };

  useEffect(() => {
    if (address) {
      form.values.city = address.terms ? address.terms[2]?.value : "";
      form.values.address = address.description;
      form.values.address_json = address;
    }
  }, [address]);

  useEffect(() => {
    if (props.user && props.open) {
      form.values.firstname = props.user.firstname;
      form.values.lastname = props.user.lastname;
      form.values.gender = props.user.gender;
      form.values.email = props.user.email;
      form.values.address = props.user.address ?? "";
      form.values.ssn = props.user.ssn ?? "";
      form.values.phone = props.user.phone ?? "";
      form.values.active = 1;
      form.values.zipcode = props.user.zipcode ?? "";
      form.values.password = "";
    }
  }, [props.open]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    userService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.userRefresh();
        form.clear();
        // dispatch(auth.actions.fulfillUser(data.data));
        dispatch(auth.actions.updateSwitchUsers(data.data));
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };
  return (
    <>
      <Dialog
        fullWidth={true}
        // maxWidth={'lg'}
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseOutlinedIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <FormattedMessage
                id={"USERS.PROFILE.EDIT"}
                defaultMessage={"USERS.PROFILE.EDIT"}
              />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              data-cy-class={"user_update"}
              data-cy-id={props.user?._id}
              type={"submit"}
              form={"user_update_form"}
            >
              <FormattedMessage
                id={"DIALOGUE.UPDATE"}
                defaultMessage={"DIALOGUE.UPDATE"}
              />
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form
            id={"user_update_form"}
            onSubmit={(e) => {
              form.handleSubmit(e, handleSave);
              e.preventDefault();
            }}
          >
            <Grid container spacing={2} mb={2}>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"firstname"}
                    label={intl.formatMessage({ id: "USERS.FIRSTNAME" })}
                    defaultValue={props.user?.firstname}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.firstname = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"firstname"}
                      label={intl.formatMessage({ id: "USERS.FIRSTNAME" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"lastname"}
                    label={intl.formatMessage({ id: "USERS.LASTNAME" })}
                    defaultValue={props.user?.lastname}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.lastname = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"lastname"}
                      label={intl.formatMessage({ id: "USERS.LASTNAME" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Select
                    name={"gender"}
                    formId={"gender"}
                    options={[
                      {
                        id: "male",
                        title: intl.formatMessage({ id: "USERS.MALE" }),
                      },
                      {
                        id: "female",
                        title: intl.formatMessage({ id: "USERS.FEMALE" }),
                      },
                    ]}
                    defaultValue={props.user?.gender}
                    label={intl.formatMessage({ id: "USERS.GENDER" })}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.gender = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Select
                      name={"gender"}
                      options={[]}
                      label={intl.formatMessage({ id: "USERS.GENDER" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"email"}
                    label={intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" })}
                    type={"email"}
                    defaultValue={props.user?.email}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.email = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"email"}
                      label={intl.formatMessage({
                        id: "CASE.INFORMATION.EMAIL",
                      })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"ssn"}
                    label={intl.formatMessage({ id: "USERS.SSN" })}
                    defaultValue={props.user?.ssn}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.ssn = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"ssn"}
                      label={intl.formatMessage({ id: "USERS.SSN" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"phone"}
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    defaultValue={props.user?.phone}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.phone = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"phone"}
                      label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"}>
                      <FormattedMessage
                        id={"USERS.POSTNUMBER"}
                        defaultMessage={"USERS.POSTNUMBER"}
                      />
                    </Typography>
                    <Controls.Input
                      name={"zipcode"}
                      label={""}
                      defaultValue={props.user?.zipcode}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.zipcode = event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"zipcode"}
                      label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"}>
                      <FormattedMessage
                        id={"STANDARD.ADDRESS"}
                        defaultMessage={"STANDARD.ADDRESS"}
                      />
                    </Typography>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      getOptionLabel={(
                        option: AutocompletePrediction | string
                      ) =>
                        typeof option === "string" ? option : option.description
                      }
                      filterOptions={(x) => x}
                      id="google-map"
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      options={addressOptions}
                      defaultValue={props.user?.address_json}
                      onChange={(
                        event: any,
                        newValue: AutocompletePrediction | null
                      ) => {
                        setAddress(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          onChange={(e) => setSearch(e.target.value)}
                          {...params}
                          value={search}
                          fullWidth
                        />
                      )}
                      renderOption={(props, option: AutocompletePrediction) => {
                        const text = option.description;

                        return (
                          <li {...props}>
                            <Grid container alignItems="center">
                              <Grid item>
                                <Box
                                  component={LocationOnOutlinedIcon}
                                  sx={{ color: "text.secondary", mr: 2 }}
                                />
                              </Grid>
                              <Grid item xs>
                                <Typography variant="body2" color="text.main">
                                  {text}
                                </Typography>
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"place"}
                      label={intl.formatMessage({ id: "USERS.PLACE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"}>
                      <FormattedMessage
                        id={"USERS.NEW_PASSWORD"}
                        defaultMessage={"New password"}
                      />
                    </Typography>
                    <Controls.Input
                      name={"password"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.password = event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"zipcode"}
                      label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default EditSibling;
