import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

export interface IProject {
  _id: string;
  title: string;
  start: string;
  end: string;
  priority: number;
  status: { value: string; label: string };
  users: IUser[];
  _created_at: string;
  _updated_at: string;
}

export interface IProjectStore {
  title: string;
  start: string;
  end: string;
  priority: number;
  status: string;
  users: string[];
}

const INDEX = API_ROUTES.projects;
const MIN_LIST = API_ROUTES.projects_minlist;
const SHOW = API_ROUTES.project;
const UPDATE = API_ROUTES.project;
const DELETE = API_ROUTES.project;

export interface IProjectService {
  getMinList(
    queries?: Array<IUrlQuery>
  ): Promise<{ data: any } | IRestApiError>;
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IProject> | IRestApiError>;
  add(data: IProjectStore): Promise<IRestApiResource<IProject> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IProject> | IRestApiError>;
  update(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IProject> | IRestApiError>;
  delete(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IProject> | IRestApiError>;
}

const projectService: IProjectService = {
  async getMinList(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(MIN_LIST)}${queriesString}`, "get");
  },
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async add(data: IProjectStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(INDEX), "post", formData);
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },
};

export { projectService };
