import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { IAction, IRestApiCollection, IRestApiError } from "../../interfaces";
import { put, takeLatest } from "redux-saga/effects";
import { translationService } from "../../services";
import { AvailableLangs, ITranslate } from "../../services/translation.service";

export interface Ii18nState {
  lang: AvailableLangs;
  messages: Array<ITranslate> | null;
  loading: boolean;
  cached: boolean;
  errors: Array<any>;
}

export const actionTypes = {
  SetLanguage: "i18n/SET_LANGUAGE",
  GetMessages: "Get messages from backend",
  GetMessages_SUCCESS: "Get messages from backend success",
  GetMessages_PENDING: "Get messages from backend pending",
  GetMessages_ERROR: "Get messages from backend error",
  Update: "Update translations",
  UpdateOne: "Update one translation",
};

const initialState: Ii18nState = {
  lang: "en",
  messages: null,
  loading: false,
  cached: false,
  errors: [],
};

export const reducer = persistReducer(
  { storage, key: "i18n" },
  (state: Ii18nState = initialState, action: IAction) => {
    switch (action.type) {
      case actionTypes.SetLanguage: {
        return { ...state, lang: action.payload.lang };
      }

      case actionTypes.GetMessages_PENDING: {
        return { ...state, loading: true };
      }

      case actionTypes.GetMessages_SUCCESS: {
        let { messages }: { messages: Array<ITranslate> } = action.payload;
        let newMessages: Array<ITranslate> = [];

        newMessages.push(...messages);

        return { ...state, loading: false, messages: newMessages };
      }

      case actionTypes.UpdateOne: {
        let translation: ITranslate = action.payload;
        let messages = state.messages ?? [];
        let index = messages?.findIndex((m) => m._id === translation._id);

        if (index && messages) messages[index] = translation;

        return { ...state, messages: [...messages], loading: false };
      }

      case actionTypes.GetMessages_ERROR: {
        let { errors } = action.payload;
        return { ...state, loading: false, errors };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setLanguage: (lang: string) => ({
    type: actionTypes.SetLanguage,
    payload: { lang },
  }),
  getMessages: () => ({ type: actionTypes.GetMessages }),
  update: (translate: ITranslate) => ({
    type: actionTypes.Update,
    payload: translate,
  }),
  updateOne: (translate: ITranslate) => ({
    type: actionTypes.UpdateOne,
    payload: translate,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetMessages, function* getLangsSaga() {
    yield put({ type: actionTypes.GetMessages_PENDING });

    try {
      const data: IRestApiCollection<ITranslate> | IRestApiError =
        yield translationService.getAll();

      if ("error" in data) {
        throw new Error(data.error.message);
      }

      yield put({
        type: actionTypes.GetMessages_SUCCESS,
        payload: { messages: data.data },
      });
    } catch (error) {
      yield put({ type: actionTypes.GetMessages_ERROR, payload: { error } });
    }
  });
}
