// @ts-nocheck
import React, { FC, useEffect, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Row from "./row";

const useStyles = makeStyles(() => ({
  iconButtonAdd: {
    "&:hover": {
      backgroundColor: "#556EE6!important",
    },
  },
}));

interface IProps {
  data: any;
  type: string;
  onChangeData: Function;
  symbol: string;
}

const ExpenseItems: FC<IProps> = (props) => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>(props.data ?? []);

  useEffect(() => {
    if (props.data) setData(props.data);
  }, [props.data]);

  useEffect(() => {
    props.onChangeData(data, props.type);
  }, [data]);

  const onAdd = () => {
    let array = [...data];

    array.push({
      expense_id: "",
      title: "",
      quantity: 1,
      price_per_unit: 0,
      price_inc_tax: 0,
      vat_rate: 0,
      tax_id: "",
      tax: 0,
    });

    setData(array);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    setData((prevData) => {
      const newItems = [...prevData];
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      return newItems;
    });
  };

  const onTitleChange = (value: string, index: number) => {
    const newArray = [...data];

    if (newArray) {
      if (index >= 0 && index < newArray.length) {
        newArray[index]["title"] = value;

        setData(newArray);
      } else {
        console.error("Index is out of bounds.");
      }
    } else {
      console.error("Data is not initialized or is invalid.");
    }
  };

  const onColumnChangeEvent = (value: any, index: any, colName: any) => {
    setData((prevData) => {
      const array = [...prevData];
      value = value || 0;

      array[index][colName] = value;
      let quantity = parseFloat(array[index]["quantity"]);
      let vat = array[index]["vat_rate"]
        ? parseInt(array[index]["vat_rate"])
        : 0;
      let tax_id = array[index]["tax_id"];
      let price_per_unit = parseFloat(array[index]["price_per_unit"]);
      let price_inc_tax = parseFloat(array[index]["price_inc_tax"]);

      if (["price_per_unit", "vat_rate", "quantity"].includes(colName)) {
        array[index]["price_inc_tax"] = parseFloat(
          (price_per_unit + (price_per_unit * vat) / 100) * quantity
        ).toFixed(2);
      } else {
        price_inc_tax = price_inc_tax / quantity;
        array[index]["price_per_unit"] = parseFloat(
          (100 * price_inc_tax) / (100 + vat)
        ).toFixed(2);
      }

      let tax =
        array[index]["price_inc_tax"] -
        array[index]["price_per_unit"] * array[index]["quantity"];

      array[index]["tax"] = parseFloat(tax).toFixed(2);
      array[index]["tax_id"] = tax_id;
      return array;
    });
  };

  const onDelete = (id: any) => {
    setData((prevData) => {
      let array = [...prevData];
      array.splice(id, 1);

      return array;
    });
  };

  return (
    <>
      {data && data.length > 0 ? (
        <div className="draggable-table">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="table" direction="vertical">
              {(provided) => (
                <div
                  className="table w-100 m-0"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {/* Rendering table header */}
                  <div className="table-head">
                    <span style={{ marginLeft: "0rem" }}>Title</span>
                    <span style={{ marginLeft: "19rem" }}>VatRate</span>
                    <span style={{ marginLeft: "5.8rem" }}>Quantity</span>
                    <span style={{ marginLeft: "2.7rem" }}>ExclTax</span>
                    <span style={{ marginLeft: "5rem" }}>Tax</span>
                    <span style={{ marginLeft: "7.1rem" }}>InclTax</span>
                  </div>
                  {/* Rendering table rows */}
                  <tbody className="mt-3">
                    {data.map((row: any, index: number) => {
                      const uniq_id = "_id_" + index;
                      return (
                        <Draggable
                          key={uniq_id}
                          draggableId={uniq_id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            // if (snapshot.isDragging) {
                            //   provided.draggableProps.style.left =
                            //     provided.draggableProps.style.offsetLeft;
                            //   provided.draggableProps.style.top =
                            //     provided.draggableProps.style.offsetTop;
                            //   // provided.draggableProps.style.display = "flex";
                            // }
                            return (
                              <Row
                                data={data}
                                row={row}
                                index={index}
                                provided={provided}
                                snapshot={snapshot}
                                onTitleChange={onTitleChange}
                                onColumnChangeEvent={onColumnChangeEvent}
                                onDelete={onDelete}
                              />
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <></>
      )}
      {/* Add button */}
      <IconButton
        className={`${classes.iconButtonAdd}`}
        sx={{
          width: "124px",
          height: "40px",
          display: "flex",
          backgroundColor: "#0D99FF",
          borderRadius: 1,
          gap: "6px",
        }}
        onClick={onAdd}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75V9.25H4.75C4.33579 9.25 4 9.58579 4 10C4 10.4142 4.33579 10.75 4.75 10.75H9.25V15.25C9.25 15.6642 9.58579 16 10 16C10.4142 16 10.75 15.6642 10.75 15.25V10.75H15.25C15.6642 10.75 16 10.4142 16 10C16 9.58579 15.6642 9.25 15.25 9.25H10.75V4.75Z"
            fill="white"
          />
        </svg>
        <Typography
          sx={{
            fontSize: 16,
            color: "#fff",
          }}
          variant="inherit"
        >
          Add
        </Typography>
      </IconButton>
    </>
  );
};

export default ExpenseItems;
