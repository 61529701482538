import React from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const Editor = (props) => {

  return (
      <CKEditor
        editor={ DecoupledEditor }
        onReady={(editor) => {
          editor.ui.getEditableElement().parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
          );
        }}
        data={props.data ?? ""}
        onChange={(event, editor) => {
          props.onChange(editor.getData());
        } }
      />
  )
}

export default Editor;
