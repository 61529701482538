import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "../../../partials/layout/DataTable";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { caseService } from "app/services";
import moment from "moment";
import { useParams } from "react-router";
import TodoStore from "../store/todoStore";
import { GridTable } from "../../../partials/layout/GridTable";
import { useTable } from "../../../hooks/subresource/useTable";
import { ITodo } from "../../../services/todo.service";
import { useIntl } from "react-intl";
import ShowCaseTodo from "./showTodo";
import FolderIcon from "@mui/icons-material/Folder";
import { PermissionsGate } from "../../../permission/PermissionsGate";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import ShowCaseTodoNotes from "./todoNote";
import EditIcon from "../../../partials/icons/EditIcon";
import CaseTodoFilter from "./filters/todoFilter";

type Props = {
  _id: string;
};

const Todo: FC<Props> = (props) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();

  const { data, xhrLoading, remove, queries, setQuery, refresh, updateQuery } =
    useTable<ITodo>({
      fetch: caseService.getTodos,
      delete: caseService.deleteTodo,
      param: id,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  const [confirmId, setConfirmId] = useState<string | null>(null);
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  // @ts-ignore
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
    },
    {
      field: "title",
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row align-items-center">
            <i
              className="fa fa-genderless mr-1 fs-40"
              style={{ color: params.row?.category?.color }}
            />
            <span>{params.row?.title}</span>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      valueGetter: (params) => {
        return moment.unix(params.row?.date).local().format("DD/MM/YYYY");
      },
      minWidth: 200,
    },
    {
      field: "category",
      headerName: intl.formatMessage({ id: "STANDARD.CATEGORY" }),
      valueGetter: (params) => {
        return (
          params.row?.category?.title &&
          params.row?.category?.title[intl.locale]
        );
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
      field: "status",
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row align-items-center">
            <i
              className="fa fa-genderless mr-1 fs-40"
              style={{ color: params.row?.status?.color }}
            />
            <span>{params.row?.status?.title[intl.locale]}</span>
          </div>
        );
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"notes"} scope={"read"}>
              <>
                <IconButton
                  onClick={() => showNotes(params.row?._id)}
                  // onClick={() => setEditNote(true)}
                  data-cy-class={"case_todo_note_show"}
                  data-cy-case-id={id}
                  data-cy-todo-id={params.row?._id}
                >
                  <FolderIcon sx={{ color: "primary.main" }} />
                </IconButton>
              </>
            </PermissionsGate>
            <PermissionsGate section={"todos"} scope={"read"}>
              <>
                <IconButton
                  onClick={() => show(params.row?._id)}
                  data-cy-class={"case_todo_show"}
                  data-cy-case-id={id}
                  data-cy-todo-id={params.row?._id}
                >
                  <EditIcon />
                </IconButton>
              </>
            </PermissionsGate>
            <PermissionsGate section={"todos"} scope={"read"}>
              <>
                <IconButton
                  onClick={() => handleClick(params.row?._id)}
                  data-cy-class={"case_todo_delete"}
                  data-cy-case-id={id}
                  data-cy-todo-id={params.row?._id}
                >
                  <DeleteIcon sx={{ color: "error.main" }} />
                </IconButton>
              </>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [notesTodoId, setNotesTodoId] = useState<string | null>(null);
  const [todoId, setTodoId] = useState<string | null>(null);

  const showNotes = (id: string) => {
    setNotesTodoId(id);
  };

  const show = (id: string) => {
    setTodoId(id);
  };

  const handleClick = (id: string) => {
    setConfirmId(id);
  };

  return (
    <>
      <PermissionsGate section={"todos"} scope={"delete"}>
        {confirmId && confirmId.length > 0 ? (
          <ConfirmDialog
            title="Delete todo?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(id, confirmId);
            }}
          >
            Are you sure you want to delete this case todo?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      {notesTodoId && (
        <ShowCaseTodoNotes
          caseId={props._id}
          todoId={notesTodoId}
          open={Boolean(notesTodoId)}
          setDialogue={() => setNotesTodoId(null)}
          onDone={refresh}
        />
      )}
      {todoId ? (
        <ShowCaseTodo
          _id={todoId}
          _case={id}
          open={Boolean(todoId)}
          setDialogue={() => setTodoId(null)}
          onDone={refresh}
        />
      ) : (
        <></>
      )}
      <PermissionsGate section={"todos"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel: (props: { open: boolean; close: () => void }) => {
              return (
                <TodoStore
                  _id={id}
                  open={props.open}
                  setDialogue={props.close}
                  onDone={() => {}}
                />
              );
            },
            filters: (props: { show: boolean }) => {
              return (
                <CaseTodoFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
          search={(queries) => updateQuery(queries)}
          quickSearchToolbar={true}
        />
      </PermissionsGate>
    </>
  );
};

export default Todo;
