import React, { FC, useState } from "react";

let FixedRef: {
  clear: () => void;
  put: (jsx: JSX.Element) => void;
  show: () => void;
  hide: () => void;
};

export const FixedSideButton: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [content, setContent] = useState<JSX.Element | null>(null);

  FixedRef = {
    clear: () => setContent(null),
    put: (jsx) => setContent(jsx),
    show: () => setOpen(true),
    hide: () => setOpen(false),
  };

  return open ? content : null;
};

export default {
  clear() {
    FixedRef.clear();
  },
  put(jsx: JSX.Element) {
    FixedRef.put(jsx);
  },
  show() {
    FixedRef.show();
  },
  hide() {
    FixedRef.hide();
  },
};
