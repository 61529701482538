import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "../../../../widgets/uncontrolled";
import React, { FC, memo, useEffect, useState } from "react";
import { ISetting } from "../../../../services/setting.service";
import { FormattedMessage, useIntl } from "react-intl";
import { ICase } from "../../../../services/case.service";
import { makeStyles } from "@material-ui/core";
import { TransitionProps } from "@mui/material/transitions";

export interface IFormConditionValues {
  administration_work?: number;
  face_to_face?: number;
  meeting_dur?: string;
  meeting_durations?: {
    month: string;
    administration_work: number;
    face_to_face: number;
  }[];
  meeting_duration_type?: string;
}

interface IFormConditionProps {
  case?: ICase;
  touched: boolean;
  handleChange: any;
  meeting_duration_types: Array<ISetting>;
  values: IFormConditionValues;
}

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const Condition: FC<IFormConditionProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const initValue = [
    { month: "1", administration_work: 0, face_to_face: 0 },
    { month: "2", administration_work: 0, face_to_face: 0 },
    { month: "3", administration_work: 0, face_to_face: 0 },
    { month: "4", administration_work: 0, face_to_face: 0 },
    { month: "5", administration_work: 0, face_to_face: 0 },
    { month: "6", administration_work: 0, face_to_face: 0 },
    { month: "7", administration_work: 0, face_to_face: 0 },
    { month: "8", administration_work: 0, face_to_face: 0 },
    { month: "9", administration_work: 0, face_to_face: 0 },
    { month: "10", administration_work: 0, face_to_face: 0 },
    { month: "11", administration_work: 0, face_to_face: 0 },
    { month: "12", administration_work: 0, face_to_face: 0 },
  ];

  const currYear: number = new Date().getFullYear();

  const [meetingDurationDialogue, setMeetingDurationDialogue] =
    useState<boolean>(false);
  const [currentMonthDuration, setCurrentMonthDuration] = useState<number>(0);
  const [meetingDuration, setMeetingDuration] = useState<
    {
      month: string;
      administration_work: number;
      face_to_face: number;
    }[]
  >(props.case?.meeting_durations[currYear] ?? initValue);

  console.log(meetingDuration);
  let durationArr = meetingDuration;

  const handleClose = () => {
    setMeetingDurationDialogue(false);
  };

  const currMonth: number = new Date().getMonth();

  useEffect(() => {
    const total =
      meetingDuration[currMonth].face_to_face +
      meetingDuration[currMonth].administration_work;

    props.values.administration_work =
      meetingDuration[currMonth].administration_work;
    props.values.face_to_face = meetingDuration[currMonth].face_to_face;

    if (!meetingDurationDialogue) setCurrentMonthDuration(total);
  }, [meetingDuration, meetingDurationDialogue]);

  const setDuration = () => {
    setMeetingDuration(durationArr);
    props.values.meeting_durations = durationArr;
  };

  return (
    <>
      <Grid container sx={{ mt: "10px" }} spacing={2}>
        <Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
          <Box className={classes.input}>
            <Typography variant={"body1"} sx={{ mb: 1 }}>
              <FormattedMessage
                id={"CASE.FORM.TOTAL"}
                defaultMessage={"Total"}
              />
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent: "spaceBetween",
              }}
            >
              <Typography variant={"body1"}>
                {currentMonthDuration}{" "}
                <FormattedMessage
                  id={"STANDARD.HOURS"}
                  defaultMessage={"hours"}
                />
              </Typography>
              <IconButton
                sx={{
                  backgroundColor: "#0D99FF",
                  width: 40,
                  height: 40,
                  borderRadius: 8,
                  "&:hover": { backgroundColor: "#0D99FF" },
                }}
                onClick={() => setMeetingDurationDialogue(true)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6223 2.31247L6.1556 10.7791C5.83222 11.1008 5.57586 11.4833 5.40135 11.9047C5.22684 12.3261 5.13764 12.7779 5.13893 13.234V14.1666C5.13893 14.3508 5.21209 14.5275 5.34233 14.6577C5.47256 14.7879 5.6492 14.8611 5.83337 14.8611H6.76601C7.2221 14.8624 7.67391 14.7732 8.09529 14.5987C8.51667 14.4242 8.89925 14.1678 9.22087 13.8444L17.6875 5.37775C18.0934 4.97092 18.3213 4.41975 18.3213 3.84511C18.3213 3.27048 18.0934 2.7193 17.6875 2.31247C17.2748 1.91795 16.7259 1.69778 16.1549 1.69778C15.584 1.69778 15.035 1.91795 14.6223 2.31247ZM16.7056 4.39581L8.23893 12.8625C7.84735 13.2517 7.3181 13.4707 6.76601 13.4722H6.52782V13.234C6.52927 12.6819 6.74835 12.1527 7.13754 11.7611L15.6042 3.29442C15.7525 3.15272 15.9498 3.07365 16.1549 3.07365C16.36 3.07365 16.5573 3.15272 16.7056 3.29442C16.8514 3.44061 16.9333 3.63865 16.9333 3.84511C16.9333 4.05158 16.8514 4.24961 16.7056 4.39581Z"
                    fill="white"
                  />
                  <path
                    d="M17.6389 7.90207C17.4547 7.90207 17.278 7.97524 17.1478 8.10547C17.0176 8.23571 16.9444 8.41234 16.9444 8.59652V12.0833H14.1666C13.6141 12.0833 13.0842 12.3028 12.6935 12.6935C12.3028 13.0842 12.0833 13.6141 12.0833 14.1667V16.9444H5.13885C4.58631 16.9444 4.05641 16.7249 3.66571 16.3342C3.27501 15.9435 3.05552 15.4136 3.05552 14.8611V5.13888C3.05552 4.58634 3.27501 4.05644 3.66571 3.66574C4.05641 3.27504 4.58631 3.05555 5.13885 3.05555H11.418C11.6022 3.05555 11.7788 2.98238 11.9091 2.85215C12.0393 2.72191 12.1125 2.54528 12.1125 2.3611C12.1125 2.17692 12.0393 2.00029 11.9091 1.87005C11.7788 1.73982 11.6022 1.66666 11.418 1.66666H5.13885C4.2183 1.66776 3.33576 2.03394 2.68483 2.68486C2.03391 3.33579 1.66773 4.21833 1.66663 5.13888L1.66663 14.8611C1.66773 15.7817 2.03391 16.6642 2.68483 17.3151C3.33576 17.966 4.2183 18.3322 5.13885 18.3333H13.0159C13.4721 18.3346 13.924 18.2454 14.3455 18.0709C14.767 17.8964 15.1497 17.6401 15.4715 17.3167L17.3159 15.4708C17.6394 15.1492 17.8958 14.7666 18.0704 14.3453C18.2451 13.9239 18.3344 13.4721 18.3333 13.016V8.59652C18.3333 8.41234 18.2601 8.23571 18.1299 8.10547C17.9997 7.97524 17.823 7.90207 17.6389 7.90207ZM14.4895 16.3347C14.2104 16.6132 13.8574 16.806 13.4722 16.8903V14.1667C13.4722 13.9825 13.5453 13.8058 13.6756 13.6756C13.8058 13.5454 13.9824 13.4722 14.1666 13.4722H16.8923C16.8064 13.8566 16.6139 14.209 16.3368 14.4889L14.4895 16.3347Z"
                    fill="white"
                  />
                </svg>
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12} md={9} lg={9} xl={9}>
          <Box className={classes.input}>
            <Typography variant={"body1"} sx={{ mb: 0.7 }}>
              <FormattedMessage
                id={"CASE.INFORMATION.MEETINGDURATIONTYPE"}
                defaultMessage={"CASE.INFORMATION.MEETINGDURATIONTYPE"}
              />
            </Typography>
            <Controls.Select
              onChange={(e) => {
                if (typeof e.target.value !== "string") return;

                props.values.meeting_duration_type = e.target.value;
              }}
              label={""}
              formId={"meeting_duration_type_pick"}
              name={"meeting_duration_type"}
              defaultValue={props.case?.meeting_duration_type?._id}
              options={props.meeting_duration_types.map((m) => ({
                id: m._id,
                title: m.title && m.title[intl.locale],
              }))}
            />
          </Box>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={meetingDurationDialogue}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
            pr: 2,
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage id={"CASE.EDIT"} defaultMessage={"CASE.EDIT"} />
            </Typography>
            <Button
              form={"case_update_form"}
              autoFocus
              color="inherit"
              type={"button"}
              onClick={() => {
                // props.values.meeting_durations = meetingDuration;
                setDuration();
                setMeetingDurationDialogue(false);
              }}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          {/*January*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // setMeetingDuration((state) => {
                    //   state[0].administration_work = +e.target.value;
                    //   return state;
                    // });

                    durationArr[0].administration_work = +e.target.value;
                  }}
                  defaultValue={
                    props.values.meeting_durations
                      ? props.values.meeting_durations[0].administration_work &&
                        props.values.meeting_durations[0].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    // durationArr[0].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                    durationArr[0].face_to_face = +e.target.value;
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[0].face_to_face &&
                        meetingDuration[0].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"January"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*February*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    // durationArr[1].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                    durationArr[1].administration_work = +e.target.value;
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[1].administration_work &&
                        meetingDuration[1].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    // durationArr[1].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                    durationArr[1].face_to_face = +e.target.value;
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[1].face_to_face &&
                        meetingDuration[1].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"February"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*March*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    // durationArr[2].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                    durationArr[2].administration_work = +e.target.value;
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[2].administration_work &&
                        meetingDuration[2].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    // durationArr[2].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                    durationArr[2].face_to_face = +e.target.value;
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[2].face_to_face &&
                        meetingDuration[2].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"March"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*April*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    // durationArr[3].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                    durationArr[3].administration_work = +e.target.value;
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[3].administration_work &&
                        meetingDuration[3].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    // durationArr[3].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                    durationArr[3].face_to_face = +e.target.value;
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[3].face_to_face &&
                        meetingDuration[3].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"April"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*May*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    // durationArr[4].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                    durationArr[4].administration_work = +e.target.value;
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[4].administration_work &&
                        meetingDuration[4].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[4].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[4].face_to_face &&
                        meetingDuration[4].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"May"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*June*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[5].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[5].administration_work &&
                        meetingDuration[5].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[5].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[5].face_to_face &&
                        meetingDuration[5].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"June"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*July*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[6].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[6].administration_work &&
                        meetingDuration[6].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[6].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[6].face_to_face &&
                        meetingDuration[6].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"July"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*August*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[7].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[7].administration_work &&
                        meetingDuration[7].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[7].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[7].face_to_face &&
                        meetingDuration[7].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"August"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*September*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[8].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[8].administration_work &&
                        meetingDuration[8].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[8].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[8].face_to_face &&
                        meetingDuration[8].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"September"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*October*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[9].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[9].administration_work &&
                        meetingDuration[9].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[9].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[9].face_to_face &&
                        meetingDuration[9].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"October"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*November*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[10].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[10].administration_work &&
                        meetingDuration[10].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[10].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[10].face_to_face &&
                        meetingDuration[10].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"November"}
                />
              </Box>
            </Grid>
          </Grid>
          {/*December*/}
          <Grid container sx={{ mt: "10px" }} spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                  <FormattedMessage
                    id={"CASE.FORM.ADMINISTRATIONWORK"}
                    defaultMessage={"CASE.FORM.ADMINISTRATIONWORK"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[11].administration_work = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[11].administration_work &&
                        meetingDuration[11].administration_work
                      : 0
                  }
                  label={""}
                  name={"admin_work"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.FACETOFACE"}
                    defaultMessage={"CASE.FORM.FACETOFACE"}
                  />
                </Typography>
                <Controls.Input
                  onChange={(e) => {
                    // let durationArray = meetingDuration;
                    durationArr[11].face_to_face = +e.target.value;
                    // setMeetingDuration(durationArray);
                  }}
                  defaultValue={
                    meetingDuration
                      ? meetingDuration[11].face_to_face &&
                        meetingDuration[11].face_to_face
                      : 0
                  }
                  label={""}
                  name={"fcf"}
                  type={"number"}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 0.7 }}>
                  <FormattedMessage
                    id={"CALENDAR.MONTH"}
                    defaultMessage={"CALENDAR.MONTH"}
                  />
                </Typography>
                <Controls.Input
                  disabled={true}
                  label={""}
                  name={"meeting_duration_type"}
                  defaultValue={"December"}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default memo(Condition);
