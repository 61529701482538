// @ts-nocheck
export const uuid = (): string => {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };

  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

export const checkKey = <T>(key: PropertyKey, obj: T): key is keyof T => {
  return key in obj;
};

export const getFileExtIcon = (
  name: string
): "xlsx.svg" | "pdf.svg" | "file.svg" | null => {
  let _name = name.split(".")?.pop() ?? "";
  let ret: "xlsx.svg" | "pdf.svg" | "file.svg" | null;

  switch (_name) {
    case "xls":
    case "xlsx":
      ret = "xlsx.svg";
      break;
    case "pdf":
      ret = "pdf.svg";
      break;
    default:
      ret = "file.svg";
      break;
  }

  return ret;
};
