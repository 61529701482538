import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import Snackbar from "../../../widgets/Snackbar";
import {caseService} from "../../../services";
import {useIntl} from "react-intl";
import TodoShowDialogue, {FormStateValues} from "../../../partials/dialogues/show/todo";
import {ITodo} from "../../../services/todo.service";

interface FormProps {
  _case: string
  _id: string
  open: boolean
  setDialogue: Dispatch<SetStateAction<boolean>>
  onDone: Function
}

const ShowCaseTodo = (props: FormProps) => {
  const intl = useIntl();
  const [todo, setTodo] = useState<ITodo | null>(null);

  useEffect(() => {
    caseService.getOneTodo(props._case, props._id).then((data) => {

      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const { data: todo } = data;
      setTodo(todo);
    }).catch((e) => {
      Snackbar.error(e.message);
    });
  }, [props._id]);

  const handleSave = (validated: FormStateValues, callback: () => void) => {

    if (!validated){
      Snackbar.error(intl.formatMessage({id: 'SNACKBAR.STOREERROR'}))
      return;
    }

    caseService.updateTodo(props._case, props._id, validated).then((data) => {
      if ("error" in data){
        throw Error(intl.formatMessage({id: 'SNACKBAR.SOMETHINGWENTWRONG'}))
      }

      Snackbar.info(intl.formatMessage({id: 'SNACKBAR.UPDATED'}))

      setTimeout(() => {
        props.setDialogue(false);
        props.onDone();
        callback();
      }, 1000)

    }).catch((e) => {
      Snackbar.error(e.message)
    });
  }

  return (
    <TodoShowDialogue _id={props._case}
                      _todo={todo}
                      open={props.open}
                      setDialogue={props.setDialogue}
                      save={handleSave}
                      case_needed={false}/>
  )
}

export default ShowCaseTodo;
