import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { IconButton } from "@mui/material";
import { settingService } from "../../../services";
import { useTable } from "app/hooks/useTable";
import { ISetting } from "app/services/setting.service";
import { GridTable } from "app/partials/layout/GridTable";
import { useIntl } from "react-intl";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { hasPermission } from "app/permission/PermissionsGate";
import { usePermissions } from "app/hooks/useRole";
import EditIcon from "../../../partials/icons/EditIcon";
import DeleteIcon from "../../../partials/icons/DeleteIcon";
import ShowPosition from "./show";
import PositionStore from "./store";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const Position = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  const { data, xhrLoading, refresh, remove, updateQuery } = useTable<ISetting>(
    {
      fetch: settingService.get,
      delete: settingService.delete,
      query: [
        {
          name: "type",
          value: "positions",
        },
        {
          name: "per_page",
          value: 5,
        },
      ],
    }
  );

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
    },
    {
      field: "title",
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      valueGetter: (params) => {
        return params.row?.title && params.row?.title[intl.locale];
      },
      minWidth: 250,
    },
    {
      field: "amount",
      headerName: intl.formatMessage({ id: "CASE.FINANCE.AMOUNT" }),
      renderCell: (params) => {
        return params.row?.amount;
      },
      minWidth: 150,
      hide: me?.role.slug !== "super-admin",
    },
    {
      field: "amount_type",
      headerName: intl.formatMessage({ id: "STANDARD.AMOUNT_TYPE" }),
      renderCell: (params) => {
        return params.row?.amount
          ? params.row?.amount_type === "1_hour"
            ? intl.formatMessage({ id: "STANDARD.ONE_HOUR" })
            : intl.formatMessage({ id: "STANDARD.ONE_MONTH" })
          : "";
      },
      minWidth: 150,
      hide: me?.role.slug !== "super-admin",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => show(params.row?._id)}
              data-cy-class={"focus_type_show"}
              data-cy-id={params.row?._id}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleClick(params.row?._id)}
              data-cy-class={"focus_type_delete"}
              data-cy-id={params.row?._id}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [positionId, setPositionId] = useState<string | null>(null);

  const show = (_id: string) => {
    setPositionId(_id);
  };
  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete position?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this position?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      {positionId ? (
        <ShowPosition
          _id={positionId}
          open={Boolean(positionId)}
          setDialogue={() => setPositionId(null)}
          onDone={refresh}
        />
      ) : (
        <></>
      )}
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
        search={(queries) => updateQuery(queries)}
        filterToolbar={{
          actionPanel:
            permissions && hasPermission(permissions, "settings", "create")
              ? (props: { open: boolean; close: () => void }) => {
                  return (
                    <PositionStore
                      open={props.open}
                      setDialogue={props.close}
                      onDone={refresh}
                    />
                  );
                }
              : undefined,
        }}
      />
    </>
  );
};

export default Position;
