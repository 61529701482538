import React, { ChangeEventHandler } from "react";
import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import { Tooltip } from "@mui/material";


const Input = (props : TextFieldProps & {disableText?: string}) => {

  const { name, label, value, error=null, onChange, inputRef, disableText, disabled} = props;
  return (
    <Tooltip title={disabled ? (disableText ?? '') : ''} >
      <TextField
        inputRef={inputRef}
        variant="outlined"
        fullWidth
        label={label}
        name={name}
        disabled={disabled ?? false}
        value={value ?? ""}
        onChange={onChange}
        {...(error && {error:true,helperText:error})}
      />
    </Tooltip>
  )
}

export default Input;
