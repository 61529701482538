import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import { IFormUsersValues } from "app/pages/Cases/store/parts/users";
import Snackbar from "app/widgets/Snackbar";
import { caseService } from "app/services";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { FormattedMessage, useIntl } from "react-intl";
import Attachments from "app/pages/Cases/store/parts/attachments";
import { makeStyles } from "@material-ui/core";

interface IFormProps {
  case: string;
  plan: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  title: string;
  description: string;
  files: string[];
} & IFormUsersValues;

const initValues = {
  title: "",
  description: "",
} as FormStateValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const PlanNoteStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const classes = useStyles();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { roles } = useSelector((state: RootState) => state.permission);
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    form.values.files = files?.file_ids?.map((file: any) => file._id);
  }, [files]);

  const handleClose = () => {
    props.setDialogue(false);
    form.values = initValues;
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .addPlanNotes(props.case, props.plan, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.NOTE.ADD"}
                  defaultMessage={"CASE.NOTE.ADD"}
                />
              </Typography>
              <Button
                form={"folder_note_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"folder_note_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.title = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Input
                      multiline={true}
                      rows={5}
                      name={"description"}
                      label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.description = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        rows={5}
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {intl.formatMessage({
                          id: "STANDARD.FILES",
                          defaultMessage: "Files",
                        })}
                      </Typography>
                      <Attachments {...{ setFiles, files }} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"files"}
                        options={[]}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default PlanNoteStore;
