import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "app/config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { ISetting } from "./setting.service";
import { INote, INoteShow, INoteStore } from "./note.service";
import { IDocument } from "./document.service";

export interface IVacancy {
  _id: string;
  full_name: string;
  birthdate: number;
  gender: string;
  email: string;
  phone: string;
  address: string;
  post_number: string;
  city: ISetting;
  languages: Array<ISetting>;
  education_1: string;
  education_2: string;
  education_3: string;
  work_position: string;
  work_experience: string;
  adhd: boolean;
  autism: boolean;
  borderline: boolean;
  ptsd: boolean;
  ocd: boolean;
  criminal: boolean;
  drug_and_alcohol_abuse: boolean;
  physically_vulnerable: boolean;
  handicap: boolean;
  eating_disorder: boolean;
  diabetes: boolean;
  application_year: string;
  last_updated: string;
  age: string;
  status: ISetting;
  documents?: Array<IDocument>;
}

const INDEX = API_ROUTES.vacancies;
const SHOW = API_ROUTES.vacancy;
const UPDATE = API_ROUTES.vacancy;
const STORE = API_ROUTES.vacancies;
const DELETE = API_ROUTES.vacancy_delete;

const NOTE_INDEX = API_ROUTES.vacancy_notes;
const NOTE_STORE = API_ROUTES.vacancy_notes;
const NOTE_SHOW = API_ROUTES.vacancy_note;
const NOTE_UPDATE = API_ROUTES.vacancy_note;
const NOTE_DELETE = API_ROUTES.vacancy_note;

export interface IVacancyService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IVacancy> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IVacancy> | IRestApiError>;
  update(
    _id: string,
    data: IVacancy
  ): Promise<IRestApiResource<IVacancy> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<IVacancy> | IRestApiError>;
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>;
  delete(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IVacancy> | IRestApiError>;
  getNotes(
    vacancyId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;
  addNote(
    vacancyId: string,
    data: any,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
  getNote(
    vacancyId: string,
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<INoteShow> | IRestApiError>;
  updateNote(
    vacancyId: string,
    _id: string,
    data: INoteStore,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INote> | IRestApiError>;
  deleteNote(
    vacancyId: string,
    _id: string
  ): Promise<IRestApiCollection<INote> | IRestApiError>;
}

const vacancyService: IVacancyService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: IVacancy) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async add(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

  async getNotes(vacancyId: string, queries: Array<IUrlQuery>) {
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_INDEX).replace(
        ":vacancyId",
        vacancyId.toString()
      )}${queryString}`,
      "get"
    );
  },

  async addNote(vacancyId: string, data: any, queries?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_STORE).replace(
        ":vacancyId",
        vacancyId.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  getNote(vacancyId: string, _id: string, queries?: Array<IUrlQuery>) {
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_SHOW)
        .replace(":vacancyId", vacancyId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updateNote(
    vacancyId: string,
    _id: string,
    data: any,
    queries?: Array<IUrlQuery>
  ) {
    let jsonData = JSON.stringify(data);
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_UPDATE)
        .replace(":vacancyId", vacancyId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async deleteNote(vacancyId: string, _id: string, queries?: Array<IUrlQuery>) {
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_DELETE)
        .replace(":vacancyId", vacancyId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "delete"
    );
  },
};

export { vacancyService };
