import Autocomplete from "@mui/lab/Autocomplete";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FormattedMessage, useIntl } from "react-intl";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "../../../widgets/uncontrolled";
import { IRole, IUser } from "../../../interfaces";
import moment from "moment";
import Module from "../../../partials/content/Module";
import SignatureCanvas from "react-signature-canvas";
import { useForm } from "../../../hooks/useForm";
import Snackbar from "../../../widgets/Snackbar";
import { communeService, userService } from "../../../services";
import { FormStateValues } from "../store";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import ReactSignatureCanvas from "react-signature-canvas";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";
import { AutocompletePrediction } from "../../../partials/content/TimeTrack";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import * as auth from "../../../store/ducks/auth.duck";
import { useDispatch } from "react-redux";
import { ICommune } from "../../../services/comunne.service";

interface Props {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  user: IUser | undefined;
  userRefresh: Function;
}

const useStyles = makeStyles(() => ({
  antDropdown: {
    zIndex: 9999,
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.23)",
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  signature: {
    padding: "16px 0 0 16px",
    "& .kt-portlet": {
      "& .kt-portlet__head": {
        display: "none!important",
      },
      "& .kt-portlet__body": {
        border: "1px solid #E6E6E6!important",
        borderRadius: 6,
        p: 0,
      },
    },
  },
}));

const EditUser: FC<Props> = (props) => {
  const form = useForm<FormStateValues>();
  const intl = useIntl();
  const classes = useStyles();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const { roles } = useSelector((state: RootState) => state.permission);
  const { languages } = useSelector((state: RootState) => state.settingReducer);
  const [birthdate, setBirthdate] = useState<Date | null>();
  const [addressOptions, setAddressesOptions] = useState<
    Array<AutocompletePrediction>
  >([]);
  const [search, setSearch] = useState<string>("");
  const [address, setAddress] = useState<AutocompletePrediction | null>(null);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [communes, setCommunes] = useState<ICommune[]>([]);
  const dispatch = useDispatch();
  const boss_commune = roles.find((r: IRole) => r.slug === "boss-commune")?._id;

  useEffect(() => {
    if (search.length > 0) searchAddress();
  }, [search]);

  const searchAddress = () => {
    let service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: search,
      },
      (res) => {
        if (res) {
          setAddressesOptions(res);
        }
      }
    );
  };

  useEffect(() => {
    if (selectedRole === boss_commune) {
      communeService
        .getAll([
          {
            name: "pagination",
            value: "0",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: communes } = data;
          setCommunes(communes);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [selectedRole]);

  useEffect(() => {
    if (address) {
      form.values.city = address.terms ? address.terms[2].value : "";
      form.values.address = address.description;
      form.values.address_json = address;
    }
  }, [address]);
  const sigPad = useRef<ReactSignatureCanvas | null>(null);

  useEffect(() => {
    setTimeout(() => {
      dataFromUrl();
    }, 0);
  }, [props.open]);

  useEffect(() => {
    if (props.user && props.open) {
      form.values.role = props.user.role?._id;
      form.values.firstname = props.user.firstname;
      form.values.lastname = props.user.lastname;
      form.values.gender = props.user.gender;
      form.values.email = props.user.email;
      form.values.private_email = props.user.private_email ?? "";
      form.values.address = props.user.address ?? "";
      form.values.birthdate = props.user.birthdate
        ? moment.unix(+props.user.birthdate).local().format("YYYY-MM-DD")
        : "";
      form.values.ssn = props.user.ssn ?? "";
      form.values.languages = props.user.languages
        ? props.user.languages.map((u_l) => u_l._id)
        : [];
      form.values.phone = props.user.phone ?? "";
      form.values.active = 1;
      form.values.zipcode = props.user.zipcode ?? "";
      form.values.only_self = "";
      form.values.sessions = props.user.sessions;
      form.values.work_hours = props.user.work_hours ?? 0;
      form.values.work_hours_type = props.user.work_hours_type ?? "week";
      form.values.payment_account_number =
        props.user.payment_account_number ?? "";
      form.values.payment_registration_number =
        props.user.payment_registration_number ?? "";
      form.values.signature = props.user.signature ?? "";

      if (props.user.role?.slug === "boss-commune")
        setSelectedRole(props.user.role?._id);
    }
  }, [props.open]);
  const handleBirthdateChange = (date: Date | null) => {
    setBirthdate(date);
  };

  const clear = () => {
    if (sigPad) sigPad.current?.clear();
  };

  const getData = () => {
    if (sigPad)
      return sigPad.current?.getTrimmedCanvas().toDataURL("text/html");
  };

  const dataFromUrl = () => {
    if (sigPad) return sigPad.current?.fromDataURL(props.user?.signature ?? "");
  };

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    userService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.userRefresh();
        form.clear();
        // dispatch(auth.actions.fulfillUser(data.data));
        dispatch(auth.actions.updateSwitchUsers(data.data));
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const { currencies } = useSelector(
    (state: RootState) => state.settingReducer
  );

  return (
    <>
      <Dialog
        fullWidth={true}
        // maxWidth={'lg'}
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
            p: "0px 20px",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"USERS.PROFILE.EDIT"}
                defaultMessage={"Edit"}
              />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              data-cy-class={"time_record_update"}
              type={"submit"}
              form={"user_update_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id={"user_update_form"}
            onSubmit={(e) => {
              form.handleSubmit(e, handleSave);
              e.preventDefault();
            }}
          >
            <Grid container spacing={2} mb={2}>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Select
                    name={"role"}
                    formId={"role"}
                    options={roles?.map((role: IRole) => {
                      return {
                        id: role._id,
                        title: role.title[intl.locale],
                      };
                    })}
                    defaultValue={props.user?.role?._id}
                    label={intl.formatMessage({ id: "STANDARD.ROLE" })}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.role = event.target.value;
                      setSelectedRole(event.target.value);
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Select
                      name={"role"}
                      options={[]}
                      label={intl.formatMessage({ id: "STANDARD.ROLE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              {boss_commune === selectedRole && (
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                  {loaded ? (
                    <Box>
                      <Controls.MultipleSelect
                        label={intl.formatMessage({
                          id: "PERMISSION.TITLE.COMMUNES",
                          defaultMessage: "Communes",
                        })}
                        name={"communes"}
                        formId={"communes"}
                        options={communes.map((commune: ICommune) => {
                          return {
                            id: commune._id.toString(),
                            title: commune.name,
                          };
                        })}
                        defaultValue={props?.user?.assigned_communes?.map(
                          (commune) => commune
                        )}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.assigned_communes = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              )}
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"firstname"}
                    label={intl.formatMessage({ id: "USERS.FIRSTNAME" })}
                    defaultValue={props.user?.firstname}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.firstname = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"firstname"}
                      label={intl.formatMessage({ id: "USERS.FIRSTNAME" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"lastname"}
                    label={intl.formatMessage({ id: "USERS.LASTNAME" })}
                    defaultValue={props.user?.lastname}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.lastname = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"lastname"}
                      label={intl.formatMessage({ id: "USERS.LASTNAME" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Space
                    className={classes.antSpace}
                    direction="vertical"
                    size={12}
                  >
                    <DatePicker
                      className={classes.datePicker}
                      dropdownClassName={classes.antDropdown}
                      onChange={(value, dateString) => {
                        form.values.birthdate = dateString;
                      }}
                      defaultValue={
                        props.user?.birthdate
                          ? moment(props.user?.birthdate, "X").local()
                          : undefined
                      }
                    />
                  </Space>
                ) : (
                  <Skeleton width={"100%"}>
                    <Space
                      className={classes.antSpace}
                      direction="vertical"
                      size={12}
                    >
                      <DatePicker />
                    </Space>
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Select
                    name={"gender"}
                    formId={"gender"}
                    options={[
                      {
                        id: "male",
                        title: intl.formatMessage({ id: "USERS.MALE" }),
                      },
                      {
                        id: "female",
                        title: intl.formatMessage({ id: "USERS.FEMALE" }),
                      },
                    ]}
                    defaultValue={props.user?.gender}
                    label={intl.formatMessage({ id: "USERS.GENDER" })}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.gender = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Select
                      name={"gender"}
                      options={[]}
                      label={intl.formatMessage({ id: "USERS.GENDER" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"email"}
                    label={intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" })}
                    type={"email"}
                    defaultValue={props.user?.email}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.email = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"email"}
                      label={intl.formatMessage({
                        id: "CASE.INFORMATION.EMAIL",
                      })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"private_email"}
                    label={intl.formatMessage({ id: "USERS.PRIVATEEMAIL" })}
                    defaultValue={props.user?.private_email}
                    type={"email"}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.private_email = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"private_email"}
                      label={intl.formatMessage({ id: "USERS.PRIVATEEMAIL" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"ssn"}
                    label={intl.formatMessage({ id: "USERS.SSN" })}
                    defaultValue={props.user?.ssn}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.ssn = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"ssn"}
                      label={intl.formatMessage({ id: "USERS.SSN" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Controls.Input
                    name={"phone"}
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    defaultValue={props.user?.phone}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.phone = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"phone"}
                      label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Controls.Input
                    name={"zipcode"}
                    label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                    defaultValue={props.user?.zipcode}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.zipcode = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"zipcode"}
                      label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Controls.MultipleSelect
                    name={"languages"}
                    formId={"languages"}
                    options={languages
                      ?.sort(function (a, b) {
                        if (
                          a.title[intl.locale]?.toLowerCase() <
                          b.title[intl.locale]?.toLowerCase()
                        )
                          return -1;
                        if (
                          a.title[intl.locale]?.toLowerCase() >
                          b.title[intl.locale]?.toLowerCase()
                        )
                          return 1;
                        return 0;
                      })
                      .map((language) => {
                        return {
                          id: language._id.toString(),
                          title: language.title[intl.locale],
                        };
                      })}
                    defaultValue={props.user?.languages?.map(
                      (user_language) => user_language._id
                    )}
                    label={intl.formatMessage({ id: "USERS.LANGUAGES" })}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.languages = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Select
                      name={"languages"}
                      options={[]}
                      label={intl.formatMessage({ id: "USERS.LANGUAGES" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Controls.Input
                    name={"payment_registration_number"}
                    label={intl.formatMessage({ id: "USERS.PRN" })}
                    defaultValue={props.user?.payment_registration_number}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.payment_registration_number =
                        event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"payment_registration_number"}
                      label={intl.formatMessage({ id: "USERS.PRN" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Controls.Input
                    name={"payment_account_number"}
                    label={intl.formatMessage({ id: "USERS.PAN" })}
                    defaultValue={props.user?.payment_account_number}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.payment_account_number = event.target.value;
                    }}
                  />
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"payment_account_number"}
                      label={intl.formatMessage({ id: "USERS.PAN" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>

              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"}>
                      <FormattedMessage
                        id={"USERS.WORK_HOURS"}
                        defaultMessage={"Work hours"}
                      />
                    </Typography>
                    <Controls.Input
                      name={"work_hours"}
                      type={"number"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.work_hours = +event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"work_hours"}
                      label={intl.formatMessage({ id: "USERS.WORK_HOURS" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"}>
                      <FormattedMessage
                        id={"USERS.WORK_HOURS_TYPE"}
                        defaultMessage={"Work hours type"}
                      />
                    </Typography>
                    <Controls.Select
                      name={"work_hours_type"}
                      formId={"work_hours_type"}
                      options={[
                        {
                          id: "week",
                          title: intl.formatMessage({
                            id: "WEEK",
                            defaultMessage: "Week",
                          }),
                        },
                        {
                          id: "month",
                          title: intl.formatMessage({
                            id: "MONTH",
                            defaultMessage: "Month",
                          }),
                        },
                      ]}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.work_hours_type = event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"work_hours_type"}
                      label={intl.formatMessage({
                        id: "USERS.WORK_HOURS_TYPE",
                      })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                <Box className={classes.input}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "STANDARD.CURRENCY",
                        defaultMessage: "Currency",
                      })}
                      sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                    />
                  </Divider>
                  <Controls.Select
                    options={
                      currencies &&
                      currencies.map((currency) => {
                        return {
                          id: currency.slug,
                          title: currency.currency_title ?? "",
                        };
                      })
                    }
                    formId={"status_pick"}
                    defaultValue={props.user?.currency?.slug}
                    name={"status"}
                    label={""}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.currency = event.target.value;
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.BUDGETLIMIT",
                          defaultMessage: "Budget limit",
                        })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Input
                      name={"budget"}
                      type={"number"}
                      defaultValue={props.user?.balance}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.budget = +event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"budget"}
                      label={intl.formatMessage({
                        id: "CASE.INFORMATION.BUDGETLIMIT",
                      })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"}>
                      <FormattedMessage
                        id={"STANDARD.ADDRESS"}
                        defaultMessage={"STANDARD.ADDRESS"}
                      />
                    </Typography>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      getOptionLabel={(
                        option: AutocompletePrediction | string
                      ) =>
                        typeof option === "string" ? option : option.description
                      }
                      filterOptions={(x) => x}
                      id="google-map"
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      options={addressOptions}
                      defaultValue={props.user?.address_json}
                      onChange={(
                        event: any,
                        newValue: AutocompletePrediction | null
                      ) => {
                        setAddress(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          onChange={(e) => setSearch(e.target.value)}
                          {...params}
                          value={search}
                          label={""}
                          fullWidth
                        />
                      )}
                      renderOption={(props, option: AutocompletePrediction) => {
                        const text = option.description;

                        return (
                          <li {...props}>
                            <Grid container alignItems="center">
                              <Grid item>
                                <Box
                                  component={LocationOnOutlinedIcon}
                                  sx={{ color: "text.secondary", mr: 2 }}
                                />
                              </Grid>
                              <Grid item xs>
                                <Typography variant="body2" color="text.main">
                                  {text}
                                </Typography>
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"place"}
                      label={intl.formatMessage({ id: "USERS.PLACE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              {/*<Grid item sm={6} xs={6} md={6} lg={6} xl={6}>*/}
              {loaded ? (
                <Box className={classes.signature}>
                  <Box sx={{ display: "flex" }}>
                    <Typography variant={"body1"} sx={{ flex: 1 }}>
                      <FormattedMessage
                        id={"USERS.SIGNATURE"}
                        defaultMessage={"USERS.SIGNATURE"}
                      />
                    </Typography>
                    <Button color={"error"} onClick={() => clear()}>
                      <FormattedMessage
                        id={"STANDARD.CLEAR"}
                        defaultMessage={"STANDARD.CLEAR"}
                      />
                    </Button>
                  </Box>
                  <Module title={""}>
                    <SignatureCanvas
                      penColor="black"
                      ref={(node) => {
                        sigPad.current = node;
                      }}
                      canvasProps={{
                        width: 500,
                        height: 160,
                        className: "sigCanvas",
                      }}
                      onEnd={() => {
                        form.values.signature = getData() ?? "";
                      }}
                    />
                  </Module>
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"signature"}
                    label={intl.formatMessage({ id: "USERS.SIGNATURE" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
              {/*</Grid>*/}
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default EditUser;
