import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { connect, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { IUser } from "app/interfaces";
import { GridTable } from "app/partials/layout/GridTable";
import { useTable } from "app/hooks/useTable";
import { caseService, ICase } from "app/services/case.service";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import snackbar from "../../widgets/Snackbar";
import { useIntl } from "react-intl";
import { RootState } from "../../store/store";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "../../store/ducks/setting.duck";
import { ISetting } from "../../services/setting.service";

type Props = {
  lang: string;
  focus_areas?: Array<ISetting>;
  focus_types?: Array<ISetting>;
  settings_loading: boolean;
};

const ActionPanel = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("cases/add");
  };

  return <></>;
};

const ArchiveCases = (props: Props) => {
  // const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
  //   items: [
  //     {
  //       id: 1,
  //       columnField: 'id',
  //       value: [5000, 15000],
  //       operatorValue: 'between',
  //     },
  //   ],
  // });
  const { data, xhrLoading, remove, updateQuery } = useTable<ICase>({
    fetch: caseService.getAllArchive,
    delete: caseService.delete,
    query: [
      {
        name: "per_page",
        value: 5,
      },
      {
        name: "archive",
        value: 1,
      },
    ],
  });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const intl = useIntl();
  const locale = useSelector(({ i18n }: RootState) => i18n.lang);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: intl.formatMessage({ id: "CASE.CASENUMBER" }),
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "CASE.USERS" }),
      field: "users",
      renderCell: (params) => {
        return params.row?.users.map((user: IUser) => {
          return (
            <div>
              <Chip
                key={user._id}
                label={user.firstname}
                sx={{ bgcolor: "primary.main", color: "white.main" }}
                clickable
                onClick={(e) => {
                  window.open(`/users/${user._id}`);
                }}
              />
            </div>
          );
        });
      },
      minWidth: 400,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.FOCUS_TYPE" }),
      field: "focus_area_type",
      valueGetter: (params) => {
        return params.row?.focus_type?.title["en"] ?? "";
      },
      minWidth: 160,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.FOCUS_AREA" }),
      field: "focus_area_id",
      valueGetter: (params) => {
        return params.row?.focus_area?.title["en"] ?? "";
      },
      minWidth: 160,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => snackbar.info("Coming soon")}>
              <RestartAltOutlinedIcon sx={{ color: "error.main" }} />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (id: string) => {
    const url = `/cases/${id}`;
    window.open(url);
  };

  return (
    <GridTable
      loading={xhrLoading}
      skeleton={!init}
      pageSize={data?.per_page}
      count={data?.total}
      columns={columns}
      rows={data?.data ?? []}
      tableRef={tableRef}
      filterModel={undefined}
      query={(queries) => updateQuery(queries)}
      search={(queries) => updateQuery(queries)}
      actionPanel={<ActionPanel />}
      // extraToolbar={<Button variant="text" size={'small'} startIcon={<ArchiveOutlinedIcon />}>
      //   Archived
      // </Button>}
      quickSearchToolbar={true}
    />
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    focus_areas: settingReducer.focus_areas,
    focus_types: settingReducer.focus_types,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveCases);
