// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Controls from "../../widgets/uncontrolled";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { columnService, projectService, taskService } from "../../services";
import Snackbar from "../../widgets/Snackbar";
import { IProject } from "../../services/project.service";
import { TransitionProps } from "@mui/material/transitions";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { makeStyles } from "@material-ui/core";
import { DatePicker, Progress } from "antd";
import UserAvatar from "../../partials/layout/UserAvatar";
import moment from "moment/moment";
import { useForm } from "../../hooks/useForm";
import { FormStateValues } from "./store";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IColumn } from "../../services/column.service";
import TaskShow from "./show";

interface Task {
  _id: string;
  title: string;
}

interface Column {
  _id: string;
  title: string;
  taskIds: string[];
}

interface Data {
  tasks: { [key: string]: Task }[];
  columns: { [key: string]: Column }[];
  columnOrder: string[];
}

const initialData: Data = {
  tasks: {
    "task-1": { _id: "task-1", content: "Task 1" },
    "task-2": { _id: "task-2", content: "Task 2" },
    "task-3": { _id: "task-3", content: "Task 3" },
    "task-4": { _id: "task-4", content: "Task 4" },
    "task-5": { _id: "task-5", content: "Task 5" },
    "task-6": { _id: "task-6", content: "Task 6" },
    "task-7": { _id: "task-7", content: "Task 7" },
    "task-8": { _id: "task-8", content: "Task 8" },
  },
  columns: {
    "column-1": {
      _id: "column-1",
      title: "To Do",
      taskIds: ["task-1", "task-2", "task-3", "task-4"],
    },
    "column-2": {
      _id: "column-2",
      title: "In Progress",
      taskIds: ["task-5"],
    },
    "column-3": {
      _id: "column-3",
      title: "Done",
      taskIds: ["task-6", "task-7"],
    },
    "column-4": {
      _id: "column-4",
      title: "Waiting",
      taskIds: ["task-8"],
    },
  },
  columnOrder: ["column-1", "column-2", "column-3", "column-4"],
};

const useStyle = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  container: {
    width: "100%",
    padding: "0px",
    display: "flex",
    flexFlow: "column wrap",
    height: 250, // set the height limit to your liking
  },
  item: {
    width: "auto",
  },
  ant: {
    "& .ant-progress-inner": {
      width: "40px!important",
      height: "40px!important",
      fontSize: "12px!important",
    },
  },
  calendar: {
    zIndex: 9999,
  },
  dateRangeInput: {
    width: "100%",
    borderRadius: 8,
    border: "none!important",
    "& .ant-picker-focused": {
      boxShadow: "none!important",
    },
    "& .ant-picker": {
      border: "1px solid #E6E6E6!important",
      height: 40,
      backgroundColor: "#fff",
      borderRadius: 8,
      width: "100%",
      color: "#000000!important",
      "& .ant-picker-input input::placeholder": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  },
  iconButtonAdd: {
    "&:hover": {
      backgroundColor: "#556EE6!important",
    },
  },
}));

export type FormStateValues = {
  title: string;
};

const initValues = {
  title: "",
} as FormStateValues;

const KanbanBoard: React.FC = () => {
  const form = useForm<FormStateValues>(initValues);
  const intl = useIntl();
  const history = useHistory();
  const inputRef = React.useRef();
  const [data, setData] = useState<Data>(undefined);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [_columns, setColumns] = useState<IColumn[]>([]);
  const [_project, setProject] = useState<IProject>();
  const [addModal, setAddModal] = useState<boolean>(false);
  const [projectModal, setProjectModal] = useState<boolean>(false);
  const [moveModal, setMoveModal] = useState<boolean>(false);
  const [movePosition, setMovePosition] = useState<boolean>(false);
  const [moveProject, setMoveProject] = useState<string | null>(null);
  const [moveColumn, setMoveColumn] = useState<string | null>(null);
  const [editable, setEditable] = useState<string | null>(null);
  const [showTask, setShowTask] = useState<string | null>(null);
  const [column, setColumn] = useState<string | null>(null);
  const [newTitle, setNewTitle] = useState<string>("");
  const [newTask, setNewTask] = useState<string | null>(null);
  const [start, setStart] = useState<string | null>(null);
  const [end, setEnd] = useState<string | null>(null);
  const [newTaskTitle, setNewTaskTitle] = useState<string | null>(null);
  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);
  const project = query.get("project");
  const classes = useStyle();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!project) {
      setProjectModal(true);
      return;
    }

    setMoveProject(project);
    projectService
      .getOne(project)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: project } = data;

        setProject(project);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [project]);

  const handleClose = () => {
    history.push(`/tasks`);
  };

  const handleAddClose = () => {
    setAddModal(false);
  };

  const getProjects = (search: string) => {
    const query = [];

    if (search && search.length > 0) {
      query.push({
        name: "keyword",
        value: search,
      });
    }

    projectService
      .getAll(query)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: projects } = data;

        setProjects(projects);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    columnService
      .getAll([{ name: "project", value: moveProject }])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: columns } = data;

        setColumns(columns);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [moveProject, moveModal]);

  useEffect(() => {
    if (project) {
      projectService
        .getAll()
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }
          const { data: projects } = data;

          setProjects(projects);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [project]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "column") {
      const newColumnOrder = Array.from(data.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...data,
        columnOrder: newColumnOrder,
      };

      columnService.moveColumn({
        id: draggableId,
        project: project,
        position: destination.index + 1,
      });

      setData(newState);
      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      columnService.moveCard({
        id: draggableId,
        project: project,
        column: newColumn.id,
        position: destination.index + 1,
      });

      setData(newState);
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    columnService.moveCard({
      id: draggableId,
      project: project,
      column: newFinish.id,
      position: destination.index + 1,
    });

    setData(newState);
  };

  useEffect(() => {
    if (editable || newTask) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [editable, newTask]);

  const timeOut = useRef<any>();

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      getProjects(value);
    }, 300);
  };

  const columnList = () => {
    columnService
      .list([{ name: "id", value: project }])
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }
        const { data: res } = data;

        setData({
          tasks: res.tasks,
          columns: res.columns,
          columnOrder: res.columnOrder,
        });
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (project) {
      columnList();
    }
  }, [project]);

  useEffect(() => {
    if (start && end) {
      columnService
        .list([
          { name: "id", value: project },
          { name: "start", value: start },
          { name: "end", value: end },
        ])
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }
          const { data: res } = data;

          setData({
            tasks: res.tasks,
            columns: res.columns,
            columnOrder: res.columnOrder,
          });
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [start, end]);

  const { RangePicker } = DatePicker;

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    validated["project"] = project;

    columnService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          setAddModal(false);
          columnList();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleSubmitNewTask = (columnId: string) => {
    taskService
      .add({
        column_id: columnId,
        project_id: project,
        title: newTaskTitle,
      })
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          columnList();
          setNewTask(null);
          form.clear();
        }, 700);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleDeleteColumn = (columnId: string) => {
    columnService
      .delete(columnId)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.DELETED" }));

        setTimeout(() => {
          columnList();
          setNewTask(null);
          form.clear();
        }, 700);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleNewTitle = (title: string, columnId: string) => {
    if (title.length < 1) {
      setEditable(null);
      return;
    }
    columnService
      .update(columnId, { title: title })
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        setTimeout(() => {
          columnList();
          setEditable(null);
        }, 700);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleMove = () => {
    if (moveProject && movePosition) {
      columnService
        .moveColumn({
          id: moveColumn,
          project: moveProject,
          position: movePosition,
        })
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }

          Snackbar.success(
            intl.formatMessage({
              id: "SNACKBAR.MOVED",
              defaultMessage: "Moved successfully",
            })
          );
          setTimeout(() => {
            columnList();
            setMoveModal(false);
          }, 700);
        });
    } else {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.INCOMPLETE_REQUEST",
          defaultMessage: "Incomplete request",
        })
      );
    }
  };

  const handleTask = (task: string) => {
    setShowTask(task);
  };

  return (
    <>
      {showTask && (
        <TaskShow
          _id={showTask}
          open={Boolean(showTask)}
          onDone={columnList}
          setDialogue={() => {
            setShowTask(null);
          }}
        />
      )}
      {projectModal && (
        <Dialog
          open={projectModal}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"MENU.PROJECTS"}
                  defaultMessage={"Projects"}
                />
              </Typography>
            </Toolbar>
          </Box>
          <DialogContent>
            <TextField
              placeholder={"Search..."}
              sx={{ marginBottom: "10px" }}
              fullWidth={true}
              onChange={(event) => {
                sendQuery(event.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    sx={{ zIndex: "999!important" }}
                    position="start"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.4219 13.2473L11.8413 10.6655C13.7722 8.08511 13.2457 4.42788 10.6653 2.49693C8.08486 0.565972 4.42764 1.09248 2.49668 3.67291C0.565728 6.25334 1.09224 9.91056 3.67267 11.8415C5.74548 13.3926 8.59245 13.3926 10.6653 11.8415L13.247 14.4232C13.5715 14.7477 14.0975 14.7477 14.4219 14.4232C14.7463 14.0988 14.7463 13.5728 14.4219 13.2484L14.4219 13.2473ZM7.19049 11.3432C4.89713 11.3432 3.03801 9.4841 3.03801 7.19074C3.03801 4.89737 4.89713 3.03826 7.19049 3.03826C9.48386 3.03826 11.343 4.89737 11.343 7.19074C11.3405 9.48306 9.48285 11.3408 7.19049 11.3432Z"
                        fill="#767C91"
                      />
                    </svg>
                  </InputAdornment>
                ),
                // @ts-ignore
                "data-cy": "search",
              }}
            />
            {projects.map((project, index) => {
              const labelId = `checkbox-list-label-${index}`;
              let color = "";

              if (project.progress?.percent) {
                if (
                  project.progress.percent > 0 &&
                  project.progress?.percent < 25
                ) {
                  color = "#ffccc7";
                } else if (
                  project.progress.percent > 25 &&
                  project.progress?.percent < 50
                ) {
                  color = "#ffe58f";
                } else {
                  color = "#87d068";
                }
              }

              return (
                <Grid item sm={12} xs={12} md={12} lg={4} xl={4} key={labelId}>
                  <ListItem disablePadding className={classes.item}>
                    <ListItemButton
                      sx={{
                        padding: "0px 2px",
                        display: "flex",
                        height: 50,
                        justifyContent: "space-between",
                        padding: "0 20px",
                      }}
                      role={undefined}
                      onClick={() => {
                        history.push(`/kanban?project=${project._id}`);
                        setProjectModal(false);
                      }}
                      dense
                    >
                      <Typography variant="body1">{project.title}</Typography>

                      <Progress
                        size={"default"}
                        type="circle"
                        percent={project.progress?.percent}
                        className={classes.ant}
                        strokeColor={color}
                      />
                    </ListItemButton>
                  </ListItem>
                </Grid>
              );
            })}
          </DialogContent>
        </Dialog>
      )}
      {addModal && (
        <Dialog
          open={addModal}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleAddClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage id={"ADD"} defaultMessage={"Add"} />
              </Typography>
              <Button
                form={"list_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"list_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;

                          event.preventDefault();
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
      {moveModal && (
        <Dialog
          open={moveModal}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={() => setMoveModal(false)}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage id={"MOVE"} defaultMessage={"Move"} />
              </Typography>
              <Button
                form={"move_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SUBMIT"}
                  defaultMessage={"Submit"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"move_form"}
              onSubmit={(e) => form.handleSubmit(e, handleMove)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Select
                      labelSx={{ top: "-5px" }}
                      sx={{
                        height: 40,
                        backgroundColor: "#fff",
                        border: "1px solid #E6E6E6",
                        borderRadius: 2,
                        m: () => ({}),
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "unset",
                        },
                        "& .MuiFormLabel-root": {
                          top: "-5px",
                        },
                        "& .MuiInputBase-input": {
                          padding: "9.5px 14px",
                        },
                      }}
                      name={"project"}
                      defaultValue={project}
                      options={
                        projects &&
                        projects.map((project) => {
                          return {
                            id: project._id.toString(),
                            title: project.title,
                          };
                        })
                      }
                      label={intl.formatMessage({
                        id: "STANDARD.PROJECTS",
                        defaultMessage: "Projects",
                      })}
                      onChange={(event) => {
                        setMoveProject(event.target.value);
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Select
                      labelSx={{ top: "-5px" }}
                      sx={{
                        height: 40,
                        backgroundColor: "#fff",
                        border: "1px solid #E6E6E6",
                        borderRadius: 2,
                        m: () => ({}),
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "unset",
                        },
                        "& .MuiFormLabel-root": {
                          top: "-5px",
                        },
                        "& .MuiInputBase-input": {
                          padding: "9.5px 14px",
                        },
                      }}
                      name={"project"}
                      defaultValue={project}
                      options={
                        _columns &&
                        _columns.map((_column, index) => {
                          return {
                            id: index + 1,
                            title: index + 1,
                          };
                        })
                      }
                      label={intl.formatMessage({
                        id: "STANDARD.POSITION",
                        defaultMessage: "Position",
                      })}
                      onChange={(event) => {
                        setMovePosition(event.target.value);
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
      <Box>
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
            {!projectModal && data ? (
              <Controls.Select
                labelSx={{ top: "-5px" }}
                sx={{
                  height: 40,
                  backgroundColor: "#fff",
                  border: "1px solid #E6E6E6",
                  borderRadius: 2,
                  m: () => ({}),
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "unset",
                  },
                  "& .MuiFormLabel-root": {
                    top: "-5px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "9.5px 14px",
                  },
                }}
                name={"project"}
                defaultValue={project}
                options={
                  projects &&
                  projects.map((project) => {
                    return {
                      id: project._id.toString(),
                      title: project.title,
                    };
                  })
                }
                label={intl.formatMessage({
                  id: "STANDARD.PROJECTS",
                  defaultMessage: "Projects",
                })}
                onChange={(event) => {
                  history.push(`/kanban?project=${event.target.value}`);
                }}
              />
            ) : (
              <Skeleton width={"100%"}>
                <Controls.Select
                  name={"active"}
                  options={[]}
                  label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                  onChange={() => {}}
                />
              </Skeleton>
            )}
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
            {!projectModal && data ? (
              <Box className={classes.dateRangeInput}>
                <RangePicker
                  // showTime={{ format: "HH:mm" }}
                  onCalendarChange={(value, dateString) => {
                    if (value && dateString[0] && dateString[1]) {
                      setStart(
                        (
                          moment(dateString[0], "YYYY-MM-DD HH:mm").valueOf() /
                          1000
                        ).toString()
                      );
                      setEnd(
                        (
                          moment(dateString[1], "YYYY-MM-DD HH:mm").valueOf() /
                          1000
                        ).toString()
                      );
                    } else {
                      columnList();
                    }
                  }}
                  popupClassName={classes.calendar}
                />
              </Box>
            ) : (
              <Skeleton width={"100%"}>
                <Controls.Select
                  name={"active"}
                  options={[]}
                  label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                  onChange={() => {}}
                />
              </Skeleton>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {!projectModal && data ? (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {_project?.users.map((user) => {
                  return <UserAvatar user={user} />;
                })}
              </Box>
            ) : (
              <Skeleton width={"100%"}>
                <Controls.Select
                  name={"active"}
                  options={[]}
                  label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                  onChange={() => {}}
                />
              </Skeleton>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {!projectModal && data ? (
              <IconButton
                className={`${classes.iconButtonAdd}`}
                sx={{
                  width: "92px",
                  height: "40px",
                  display: "flex",
                  backgroundColor: "#556EE6",
                  borderRadius: 1,
                  gap: "6px",
                }}
                onClick={() => setAddModal(true)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75V9.25H4.75C4.33579 9.25 4 9.58579 4 10C4 10.4142 4.33579 10.75 4.75 10.75H9.25V15.25C9.25 15.6642 9.58579 16 10 16C10.4142 16 10.75 15.6642 10.75 15.25V10.75H15.25C15.6642 10.75 16 10.4142 16 10C16 9.58579 15.6642 9.25 15.25 9.25H10.75V4.75Z"
                    fill="white"
                  />
                </svg>
              </IconButton>
            ) : (
              <Skeleton width={"100%"}>
                <Controls.Select
                  name={"active"}
                  options={[]}
                  label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                  onChange={() => {}}
                />
              </Skeleton>
            )}
          </Grid>
        </Grid>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
        >
          {(provided) => {
            return (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  display: "flex",
                  overflow: "auto",
                  position: "relative",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "scrollbar-width": "none",
                  "-ms-overflow-style": "none",
                }}
              >
                {!projectModal && data ? (
                  data.columnOrder.map((columnId, index) => {
                    const column = data.columns[columnId];
                    const tasks = column.taskIds?.map(
                      (taskId) => data.tasks[taskId]
                    );

                    return (
                      <Draggable
                        draggableId={column.id}
                        index={index}
                        key={column.id}
                      >
                        {(provided, snapshot) => {
                          if (snapshot.isDragging) {
                            const x = 265;
                            const y = 60;
                            provided.draggableProps.style.left =
                              provided.draggableProps.style.left - x;
                            provided.draggableProps.style.top = y;
                          }
                          return (
                            <Box
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              sx={{ margin: 1 }}
                            >
                              <Paper
                                {...provided.dragHandleProps}
                                sx={{ padding: 2, minWidth: 300 }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {editable == columnId ? (
                                    <Controls.Input
                                      inputRef={inputRef}
                                      sx={{
                                        marginBottom: "10px",
                                        "& .MuiOutlinedInput-root": {
                                          height: "40px!important",
                                        },
                                      }}
                                      name={"title"}
                                      defaultValue={column.title}
                                      label={""}
                                      onBlur={() => {
                                        if (
                                          newTitle &&
                                          newTitle !== column.title
                                        ) {
                                          handleNewTitle(newTitle, columnId);
                                        } else {
                                          setEditable(null);
                                        }
                                      }}
                                      onChange={(event) => {
                                        if (!event.target.value) return;

                                        setNewTitle(event.target.value);

                                        event.preventDefault();
                                      }}
                                      onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                          event.preventDefault(); // Prevents default Enter key behavior
                                          // Trigger your submit logic here
                                          if (
                                            newTitle &&
                                            newTitle !== column.title
                                          ) {
                                            handleNewTitle(newTitle, columnId);
                                          } else {
                                            setEditable(null);
                                          }
                                        }
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <Typography
                                        variant="h6"
                                        onClick={() => {
                                          setEditable(columnId);
                                          setNewTitle(column.title);
                                        }}
                                      >
                                        {column.title}
                                      </Typography>
                                    </>
                                  )}

                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) => {
                                      handleClick(event);
                                      setColumn(columnId);
                                    }}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                </Box>
                                <Droppable droppableId={column.id} type="task">
                                  {(provided) => {
                                    return (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{
                                          backgroundColor: "#0d99ff33",
                                          borderRadius: "8px",
                                          padding: 2,
                                          minHeight: 100,
                                        }}
                                      >
                                        {tasks.map((task, index) => (
                                          <Draggable
                                            key={task._id}
                                            draggableId={task._id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              if (snapshot.isDragging) {
                                                provided.draggableProps.style.left =
                                                  "auto";
                                                provided.draggableProps.style.top =
                                                  "auto";
                                                provided.draggableProps.style.position =
                                                  "absolute";
                                              }
                                              return (
                                                <Card
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  sx={{ marginBottom: 1 }}
                                                  onClick={() =>
                                                    handleTask(task._id)
                                                  }
                                                >
                                                  <CardContent>
                                                    <Typography>
                                                      {task.title}
                                                    </Typography>
                                                  </CardContent>
                                                </Card>
                                              );
                                            }}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </Box>
                                    );
                                  }}
                                </Droppable>
                                <Box sx={{ marginTop: "10px" }}>
                                  {newTask === columnId ? (
                                    <Controls.Input
                                      inputRef={inputRef}
                                      sx={{
                                        marginBottom: "10px",
                                        "& .MuiOutlinedInput-root": {
                                          height: "40px!important",
                                        },
                                      }}
                                      name={"title"}
                                      label={""}
                                      value={newTaskTitle}
                                      onBlur={() => {
                                        setNewTask(null);
                                      }}
                                      onChange={(event) => {
                                        // Your existing onChange logic here
                                        if (!event.target.value) return;
                                        setNewTaskTitle(event.target.value);
                                        event.preventDefault();
                                      }}
                                      onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                          event.preventDefault(); // Prevents default Enter key behavior
                                          // Trigger your submit logic here
                                          if (newTaskTitle) {
                                            handleSubmitNewTask(columnId);
                                          } else {
                                            Snackbar.error(
                                              intl.formatMessage({
                                                id: "TITLE_REQUIRED",
                                                defaultMessage:
                                                  "Title is required",
                                              })
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  ) : (
                                    <button
                                      className="d-flex align-items-center justify-content-center font-weight-bold border-0 w-100 h-100 p-3"
                                      onClick={() => setNewTask(columnId)}
                                      style={{ borderRadius: "8px" }}
                                    >
                                      <i className="feather feather-plus font-weight-bold mr-2" />
                                      New Task
                                    </button>
                                  )}
                                </Box>
                              </Paper>
                            </Box>
                          );
                        }}
                      </Draggable>
                    );
                  })
                ) : (
                  <Skeleton width={"100%"}></Skeleton>
                )}
                {provided.placeholder}
              </Box>
            );
          }}
        </Droppable>
      </DragDropContext>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem
          key={"add"}
          onClick={() => {
            setNewTask(column);
            handleMenuClose();
          }}
        >
          Add
        </MenuItem>
        <MenuItem
          key={"move"}
          onClick={() => {
            setMoveColumn(column);
            setMoveModal(true);
            getProjects();
            handleMenuClose();
          }}
        >
          Move
        </MenuItem>
        <MenuItem
          key={"delete"}
          onClick={() => {
            handleDeleteColumn(column);
            handleMenuClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default KanbanBoard;
