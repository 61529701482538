import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { contractTemplateService } from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

interface IFormProps {
  templateId: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

const ContractShow: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [contract, setContract] = useState<any | undefined>();

  const handleClose = () => {
    props.setDialogue(false);
  };

  useEffect(() => {
    contractTemplateService
      .getSingleContract(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: contract } = data;
        setContract(contract);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props.templateId, props._id]);

  const exportContract = () => {
    console.log("props._id", props._id);

    contractTemplateService
      .export(contract?._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const url = "data:application/pdf;base64," + data.data;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `contract_${contract?._id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.DOWNLOADED" }));
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CONTRACT.SHOW"}
                  defaultMessage={"CONTRACT.SHOW"}
                />
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type={"submit"}
                onClick={exportContract}
              >
                <PictureAsPdfIcon
                  sx={{ color: "pdfColor.main" }}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <FormattedMessage
                  id={"EXPORT.PDF"}
                  defaultMessage={"EXPORT.PDF"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form id={"contract_form"} onSubmit={() => {}}>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="body1"
                      component="div"
                    >
                      <Chip
                        label={intl.formatMessage({ id: "STANDARD.SUBJECT" })}
                        sx={{
                          bgcolor: "primary.main",
                          color: "white.main",
                          mr: "10px",
                        }}
                      />
                      {contract?.subject}
                    </Typography>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"subject"}
                        label={"Subject"}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="body1"
                      component="div"
                      id={"content"}
                      dangerouslySetInnerHTML={{
                        __html: contract?.content ?? "",
                      }}
                    ></Typography>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Editor
                        name={"content"}
                        label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ContractShow;
