import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { connect } from "react-redux";
import { Button, Chip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridRowId } from "@mui/x-data-grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { mailboxService } from "app/services";
import moment from "moment";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import MailboxStore from "./store";
import { IMailbox } from "../../services/mailbox.service";
import ShowMailbox from "./show";
import { FormattedMessage, useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "../../store/ducks/setting.duck";
import EditIcon from "../../partials/icons/EditIcon";
import NewCase from "../Cases/store";
import { usePermissions } from "../../hooks/useRole";

type Props = {
  lang: string;
  settings_loading: boolean;
};

const Mailboxes = (props: Props) => {
  const intl = useIntl();
  const permissions = usePermissions();
  const [addForm, setAddForm] = useState<boolean>(false);

  const { data, xhrLoading, remove, refresh, updateQuery } = useTable<IMailbox>(
    {
      fetch: mailboxService.getAll,
      delete: mailboxService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    }
  );

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 160,
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.SUBJECT" }),
      field: "subject",
      valueGetter: (params) => {
        return params.row?.subject;
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" }),
      field: "email",
      valueGetter: (params) => {
        return params.row?.email;
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ISSECURE" }),
      field: "is_secure",
      renderCell: (params) => {
        let res;
        if (params.row?.is_secure) {
          res = (
            <Chip
              label={intl.formatMessage({ id: "STANDARD.SECURE" })}
              sx={{ bgcolor: "success.main", color: "#fff" }}
            />
          );
        } else {
          res = (
            <Chip
              label={intl.formatMessage({ id: "STANDARD.NOTSECURE" })}
              sx={{ bgcolor: "error.main", color: "#fff" }}
            />
          );
        }
        return res;
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      renderCell: (params) => {
        let date: string;
        if (params.row?._updated_at) {
          date = moment
            .unix(params.row?._updated_at)
            .local()
            .format("DD/MM/YYYY HH:mm");
        } else {
          date = moment
            .unix(params.row?._created_at)
            .local()
            .format("DD/MM/YYYY HH:mm");
        }
        return date;
      },
      minWidth: 120,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"mailboxes"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"mailboxes"} scope={"delete"}>
              <IconButton onClick={() => handleClick(params.row?._id)}>
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [mailboxId, setMailboxId] = useState<string | null>(null);

  const show = (_id: string) => {
    setMailboxId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"mailboxes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete mailbox?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this mailbox?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"mailboxes"} scope={"read"}>
        {mailboxId ? (
          <ShowMailbox
            _id={mailboxId}
            open={Boolean(mailboxId)}
            setDialogue={() => setMailboxId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"mailboxes"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "mailboxes", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <MailboxStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
          }}
        />
      </PermissionsGate>
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Mailboxes);
