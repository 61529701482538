import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IUser } from "app/interfaces";
import { GridTable } from "app/partials/layout/GridTable";
import { useTable } from "app/hooks/useTable";
import { caseService, ICase } from "app/services/case.service";
import NewCase from "./store";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import { usePermissions } from "../../hooks/useRole";
import CaseFilter from "./filter";
import { useHistory } from "react-router-dom";
import EditIcon from "../../partials/icons/EditIcon";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import UserAvatar from "../../partials/layout/UserAvatar";

const Cases = () => {
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const history = useHistory();
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<ICase>({
      fetch: caseService.getAll,
      delete: caseService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  const [quickCardsCount, setQuickCardsCount] = useState<{
    archived: number;
    expired: number;
    not_started: number;
  }>();

  useEffect(() => {
    caseService.getQuickCardsCount().then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const { data: counts } = data;

      setQuickCardsCount(counts);
    });
  }, [xhrLoading]);

  const permissions = usePermissions();

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const intl = useIntl();

  const columns: IColumns[] = [
    {
      field: "_id",
      headerName: intl.formatMessage({ id: "CASE.CASENUMBER" }),
      align: "center",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
    },
    {
      field: "key",
      headerName: intl.formatMessage({ id: "CASE.CASENUMBER" }),
      align: "center",
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.USERS" }),
      field: "users",
      renderCell: (params) => {
        return params.row.users.map((user: IUser) => {
          return <UserAvatar user={user} />;
        });
      },
      minWidth: 200,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.FOCUS_TYPE" }),
      field: "focus_area_type",
      valueGetter: (params) => {
        return (
          params.row?.focus_type?.title &&
          params.row?.focus_type?.title[intl.locale]
        );
      },
      minWidth: 250,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.FOCUS_AREA" }),
      field: "focus_area_id",
      valueGetter: (params) => {
        return (
          params.row?.focus_area?.title &&
          params.row?.focus_area?.title[intl.locale]
        );
      },
      minWidth: 250,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"cases"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"case_show"}
                data-cy={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"cases"} scope={"delete"}>
              <>
                <IconButton
                  onClick={() => {
                    handleClick(params.row?._id);
                  }}
                  data-cy-class={"case_remove"}
                  data-cy={params.row?._id}
                >
                  <DeleteIcon sx={{ color: "error.main" }} />
                </IconButton>
              </>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      sortable: false,
      minWidth: 100,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (_id: string) => {
    const url = `/cases/${_id}?section=overview`;
    // window.open(url);
    history.push(url);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const resetCardQuery = (
    isActive: boolean,
    name: "not_started" | "expired" | "archive"
  ) => {
    let _queries = queries?.filter(
      (q) =>
        q.name !== "archive" && q.name !== "expired" && q.name !== "not_started"
    );

    if (!_queries) return;

    if (isActive && queries && _queries) {
      let __queries = _queries.filter((q) => q.name !== name);
      setQuery(__queries);
    } else {
      setQuery([
        ..._queries,
        {
          name: name,
          value: "1",
        },
      ]);
    }
  };

  return (
    <>
      <PermissionsGate section={"cases"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete Case?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this case?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"cases"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "cases", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <NewCase
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            quickCards:
              me?.role.slug !== "citizen"
                ? [
                    {
                      title: intl.formatMessage({ id: "QUICK_CARD_ARCHIVED" }),
                      onClick: (isActive: boolean) =>
                        resetCardQuery(isActive, "archive"),
                      icon: "fi-sr-inbox",
                      value: quickCardsCount ? quickCardsCount.archived : 0,
                    },
                    {
                      title: intl.formatMessage({ id: "QUICK_CARD_EXPIRED" }),
                      onClick: (isActive: boolean) =>
                        resetCardQuery(isActive, "expired"),
                      icon: "fi-sr-time-delete",
                      value: quickCardsCount ? quickCardsCount.expired : 0,
                    },
                    {
                      title: intl.formatMessage({
                        id: "QUICK_CARD_NOT_STARTED",
                      }),
                      onClick: (isActive: boolean) =>
                        resetCardQuery(isActive, "not_started"),
                      icon: "fi-sr-calendar-clock",
                      value: quickCardsCount ? quickCardsCount.not_started : 0,
                    },
                  ]
                : undefined,
            filters:
              me?.role.slug !== "citizen"
                ? (props: { show: boolean }) => {
                    return (
                      <CaseFilter show={props.show} updateQuery={updateQuery} />
                    );
                  }
                : undefined,
          }}
          search={(queries) => updateQuery(queries)}
          quickSearchToolbar={true}
        />
      </PermissionsGate>
    </>
  );
};

export default Cases;
