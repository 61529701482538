import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
  Avatar,
  Button,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import Controls from "../../widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import { INote } from "../../services/note.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import AttachmentsInfo from "../../pages/Cases/show/parts/attachmentsInfo";
import { stringAvatar } from "../../widgets/AvatarBackground";

interface IFormProps {
  note?: INote;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  save?: Function;
}

export type FormStateValues = {
  title: string;
  description: string;
  files?: Array<string>;
  users: Array<string>;
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  activityLine: {
    "&, &:before": {
      content: "",
      position: "absolute",
      left: "11px",
      width: "0.214rem",
      top: "42px",
      bottom: "5px",
      height: "calc(22% - 5px)",
      backgroundColor: "#E7E9F5",
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
}));

const EditNotes = (props: IFormProps) => {
  const form = useForm<FormStateValues>();
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    form.values.files = files?.attachments?.map((file: any) => file._id);
  }, [files]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const save = () => {
    props.save &&
      props.save(form.validate(), () => {
        form.clear();
      });
  };

  useEffect(() => {
    if (props.note) {
      form.values.title = props.note.title;
      form.values.description = props.note.description;
      form.values.files = files?.file_ids?.map((file: any) => file._id);

      setFiles(props.note.documents);
    }
  }, [props.note]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage id={"CASE.EDIT"} defaultMessage={"CASE.EDIT"} />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              type={"submit"}
              form={"note_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id={"note_form"}
            onSubmit={(e) => {
              save();
              e.preventDefault();
            }}
          >
            <Grid container sx={{ mb: 3 }}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.TITLE"}
                        defaultMessage={"STANDARD.TITLE"}
                      />
                    </Typography>
                    <Box className={classes.input}>
                      <Controls.Input
                        name={"title"}
                        defaultValue={props.note?.title}
                        label={""}
                        onChange={(e) => {
                          form.values.title = e.target.value;
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Select
                      name={"title"}
                      options={[]}
                      onChange={() => {}}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
            <Grid container sx={{ mb: 3 }}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      Note
                    </Typography>
                    <Box className={classes.input}>
                      <Controls.Input
                        name={"note"}
                        rows={5}
                        multiline={true}
                        defaultValue={props.note?.description}
                        label={""}
                        onChange={(e) => {
                          form.values.description = e.target.value;
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"note"}
                      onChange={() => {}}
                      label={""}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Box className={classes.dropzone}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"STANDARD.FILES"}
                      defaultMessage={"STANDARD.FILES"}
                    />
                  </Typography>
                  <AttachmentsInfo setParams={setFiles} params={props.note} />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"files"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <>
              <Typography sx={{ fontWeight: 500 }} variant={"body1"}>
                Recent Activities
              </Typography>
              {props.note?.activities && (
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Box className=" kt-timeline-v2-forcase">
                    <Box className="kt-timeline-v2__items-forcase">
                      {props.note?.activities.map((activity) => {
                        const description = intl.formatMessage({
                          id: activity.title,
                        });
                        const descriptionArray = description.split("-");
                        const title = descriptionArray[0].replace(
                          "{who}",
                          activity.replacement.title
                        );
                        return (
                          <Box className="kt-timeline-v2__item">
                            <Box className="kt-timeline-v2__item-cricle">
                              <Avatar
                                variant="circular"
                                sx={{ width: 24, height: 24 }}
                                src={activity.replacement.avatar}
                                alt={title}
                              />
                            </Box>
                            <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                              <Typography variant={"body1"}>
                                <b style={{ color: "#556EE6" }}>
                                  <a
                                    onClick={() => {
                                      history.push(
                                        `/users/${activity.replacement.id}`
                                      );
                                    }}
                                  >
                                    {title}
                                  </a>
                                </b>
                                {" - "} {descriptionArray[1]}
                              </Typography>
                              <Typography>
                                {moment
                                  .unix(activity.created_at)
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              )}
            </>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default EditNotes;
