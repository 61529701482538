import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { INoteShow, INoteStore } from "./note.service";

export interface IValue {
  _id: string;
  title: string;
  description: string;
  _created_at: string;
  _updated_at: string;
}

const INDEX = API_ROUTES.values;
const SHOW = API_ROUTES.value;
const UPDATE = API_ROUTES.value;
const STORE = API_ROUTES.values;
const DELETE = API_ROUTES.value;

export interface IValueService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IValue> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IValue> | IRestApiError>;
  update(
    _id: string,
    data: any,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IValue> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<IValue> | IRestApiError>;
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>;
}

const valueService: IValueService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}?${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any, queries?: Array<IUrlQuery>) {
    let jsonData = JSON.stringify(data);
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}${queriesString}`,
      "put",
      jsonData
    );
  },

  async add(data: any) {
    let formData = generateFormData(new FormData(), data);

    return http(getRoute(STORE), "post", formData);
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DELETE).replace(":_id", _id.toString())}${queriesString}`,
      "delete"
    );
  },
};

export { valueService };
