import { useCallback, useEffect, useState } from "react";
import Snackbar from "../widgets/Snackbar";
import { IRestApiCollection, IRestApiError, IUrlQuery } from "../interfaces";
import { useIntl } from "react-intl";

interface Props<T> {
  query?: Array<IUrlQuery>;
  fetch: (
    query?: Array<IUrlQuery>
  ) => Promise<IRestApiCollection<T> | IRestApiError>;
  delete?: (
    id: string,
    queries?: Array<IUrlQuery>
  ) => Promise<IRestApiCollection<T> | IRestApiError>;
  postponedLoad?: true;
}

export const useTable = <T>(props: Props<T>) => {
  const intl = useIntl();
  const [init, setInit] = useState<boolean>();
  const [data, setData] = useState<IRestApiCollection<T>>();
  const [queries, setQuery] = useState<Array<IUrlQuery> | undefined>(
    props?.query?.concat([{name: 'lang', value: intl.locale}])
  );
  const [xhrLoading, setLoading] = useState<boolean>(true);
  const [xhrError, setError] = useState<any>();
  useEffect(() => {
    if (!props.postponedLoad) load();

    setInit(true);
  }, []);

  useEffect(() => {
    if (init) load();
  }, [queries]);

  const xhr = () => {
    setLoading(true);


    props
      .fetch(queries)
      .then((data: IRestApiCollection<any> | IRestApiError) => {
        setLoading(false);

        if ("error" in data) {
          throw new Error(data.error.message);
        }

        setData(data);
      })
      .catch((e: any) => {
        setLoading(false);
        Snackbar.error(e.message);
      });
  };

  const _remove = (id: string) => {
    if (!props.delete) return;

    props
      .delete(id, queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        } else setData(data);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const load = useCallback(() => xhr(), [queries]);
  const refresh = useCallback(() => xhr(), [queries]);
  const remove = useCallback((id: string) => _remove(id), [queries]);
  const updateQuery = useCallback(
    (_queries: Array<IUrlQuery>) => {
      if (queries) {
        let queryToUpdate = [...queries];

        for (let _query of _queries) {
          let sameQuery = queryToUpdate.find((q) => q.name === _query.name);

          if (sameQuery) sameQuery.value = _query.value;
          else queryToUpdate.push(_query);
        }

        setQuery(queryToUpdate);
      } else setQuery(_queries);
    },
    [queries]
  );

  return {
    data,
    setData,
    refresh,
    remove,
    setQuery,
    updateQuery,
    xhrLoading,
    xhrError,
    load,
    queries,
  };
};
