import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { INote, INoteShow, INoteStore } from "./note.service";
import { ISetting } from "./setting.service";

export interface IOffer {
  _id: string;
  content: string;
  email: string;
  phone: string;
  is_secure: boolean;
  is_read: boolean;
  focus_area: ISetting;
  mail_response: string;
  sms_response: string;
  mail_content: string;

  _created_at: string;
  _updated_at: string;
}

export interface IOfferQuickCardsCount {
  data: {
    accepted: number;
    waiting: number;
    cancelled: number;
  };
}

const INDEX = API_ROUTES.offers;
const SHOW = API_ROUTES.offer;
const UPDATE = API_ROUTES.offer;
const UPDATE_STATUS = API_ROUTES.offers_status_update;
const STORE = API_ROUTES.offers;
const DELETE = API_ROUTES.offer_delete;

const QUICK_CARDS_COUNT = API_ROUTES.offers_count;

const NOTE_INDEX = API_ROUTES.offer_notes;
const NOTE_STORE = API_ROUTES.offer_notes;
const NOTE_SHOW = API_ROUTES.offer_note;
const NOTE_UPDATE = API_ROUTES.offer_note;
const NOTE_DELETE = API_ROUTES.offer_note;

export interface IOfferService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IOffer> | IRestApiError>;
  getQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<IOfferQuickCardsCount | IRestApiError>;
  getOne(offerId: string): Promise<IRestApiResource<IOffer> | IRestApiError>;
  update(
    _id: string,
    data: IOffer
  ): Promise<IRestApiResource<IOffer> | IRestApiError>;
  updateStatuses(
    data: { ids: string[]; status: string },
    query?: Array<IUrlQuery>
  ): Promise<{ status: string } | IRestApiError>;
  delete(offerId: string): Promise<IRestApiCollection<IOffer> | IRestApiError>;
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>;
  getNotes(
    offerId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;
  addNote(
    offerId: string,
    data: any,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
  getNote(
    offerId: string,
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<INoteShow> | IRestApiError>;
  updateNote(
    offerId: string,
    _id: string,
    data: INoteStore,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INote> | IRestApiError>;
  deleteNote(
    offerId: string,
    _id: string
  ): Promise<IRestApiCollection<INote> | IRestApiError>;
}

const offerService: IOfferService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(QUICK_CARDS_COUNT)}${queryString}`, "get");
  },

  async getOne(getNotes: string) {
    return http(
      `${getRoute(SHOW).replace(":offerId", getNotes.toString())}`,
      "get"
    );
  },

  async update(_id: string, data: IOffer) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async updateStatuses(
    data: { ids: string[]; status: string },
    query?: Array<IUrlQuery>
  ) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(UPDATE_STATUS)}${queryString}`, "post", formData);
  },

  async delete(getNotes: string) {
    return http(
      getRoute(DELETE.replace(":offerId", getNotes.toString())),
      "delete"
    );
  },

  async add(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

  async getNotes(offerId: string, queries: Array<IUrlQuery>) {
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_INDEX).replace(
        ":offerId",
        offerId.toString()
      )}${queryString}`,
      "get"
    );
  },

  async addNote(offerId: string, data: any, queries?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_STORE).replace(
        ":offerId",
        offerId.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  getNote(offerId: string, _id: string, queries?: Array<IUrlQuery>) {
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_SHOW)
        .replace(":offerId", offerId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updateNote(
    offerId: string,
    _id: string,
    data: any,
    queries?: Array<IUrlQuery>
  ) {
    let jsonData = JSON.stringify(data);
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_UPDATE)
        .replace(":offerId", offerId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async deleteNote(offerId: string, _id: string, queries?: Array<IUrlQuery>) {
    let queryString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(NOTE_DELETE)
        .replace(":offerId", offerId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "delete"
    );
  },
};

export { offerService };
