import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import Snackbar from "../widgets/Snackbar";
import {
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { useIntl } from "react-intl";

interface Props<T> {
  update: (
    _id: string,
    data: T
  ) => Promise<IRestApiResource<T> | IRestApiError>;
  handeChange: (params: GridRenderEditCellParams, value: string) => void;
  rows: IRestApiCollection<T> | undefined;
  updateRow: SetStateAction<any>;
  onDone?: (data: T) => void;
  onError?: (data: T) => void;
}

export const useCellEdit = <T extends { _id: string }>(props: Props<T>) => {
  const intl = useIntl();
  const [oldRow, setOldData] = useState<T>();
  const [newRow, setNewData] = useState<T>();
  const [cell, setCell] = useState<{ _id: string; field: string } | null>();
  const [xhrLoadings, setLoadings] = useState<
    Array<{ _id: string; field: string }>
  >([]);

  const xhr = (_id: string, payload: T) => {
    if (cell) setLoadings([...xhrLoadings, cell]);

    props
      .update(_id, payload)
      .then((response) => {
        if ("error" in response) {
          throw Error(response.error.message);
        }

        const { data: data } = response;
        onDone(data);

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
      })
      .catch((e) => {
        if (props.onError && oldRow) props.onError(oldRow);

        onError();
        Snackbar.error(e.message);
      })
      .finally(() => {
        if (cell) setLoadings([...xhrLoadings.filter((l) => l._id !== _id)]);
      });
  };

  const startEdit = useCallback(
    (data: T, cell: { _id: string; field: string }) => {
      setOldData(JSON.parse(JSON.stringify(data)));
      setNewData(data);
      setCell(cell);
    },
    [oldRow]
  );

  const stopEdit = useCallback(
    (d: T) => {
      if (JSON.stringify(newRow) === JSON.stringify(oldRow) || !newRow) return;

      if (d._id) {
        xhr(d._id, d);
        setCell(null);
      }
    },
    [newRow]
  );

  const change = useCallback(
    (params: GridRenderEditCellParams, value: string) => {
      props.handeChange(params, value);
    },
    [newRow]
  );

  const onDone = (data: T) => {
    if (props.onDone) props.onDone(data);

    if (!props.rows) return;

    let newData = props.rows.data.map((_d) => {
      if (data._id === _d._id) return data;
      else return _d;
    });

    if (newData) {
      props.rows.data = newData;
      props.updateRow(props.rows);
    }
  };

  const onError = () => {
    if (props.onError && oldRow) {
      props.onError(oldRow);
      return;
    }

    reset();
  };

  const reset = () => {
    if (!props.rows || !oldRow) return;

    let _oldData = props.rows.data.map((_d) => {
      if (oldRow._id === _d._id) return oldRow;
      else return _d;
    });

    if (_oldData) {
      props.rows.data = _oldData;
      props.updateRow(props.rows);
    }
  };

  return {
    newRow,
    setNewData,
    startEdit,
    stopEdit,
    xhrLoadings,
    change,
    reset,
  };
};
