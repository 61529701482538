import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { userService } from "app/services";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { IFormUsersValues } from "../../Cases/store/parts/users";
import { INote } from "../../../services/note.service";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { stringAvatar } from "../../../widgets/AvatarBackground";

interface IFormProps {
  user: string;
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  description: string;
  users: Array<string>;
} & IFormUsersValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        marginTop: "5px",
        "& fieldset": {
          border: "1px solid #E6E6E6!important",
          borderRadius: "6px",
          "& legend": {
            display: "none!important",
          },
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        top: 0,
      },
    },
  },
}));

const ShowUserNote = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const classes = useStyles();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [note, setNote] = useState<INote>();
  const history = useHistory();

  useEffect(() => {
    userService
      .getNote(props.user, props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: note } = data;
        setNote(note);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props.user, props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    userService
      .updateNote(props.user, props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (note) {
      form.values.title = note.title;
      form.values.description = note.description;
    }
  }, [note]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          pr: 2,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"CASE.NOTE.SHOW"}
              defaultMessage={"CASE.NOTE.SHOW"}
            />
          </Typography>
          <Button
            form={"note_form"}
            autoFocus
            color="inherit"
            type={"submit"}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage
              id={"DIALOGUE.SAVE"}
              defaultMessage={"DIALOGUE.SAVE"}
            />
          </Button>
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id={"note_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && note ? (
                <Box className={classes.input}>
                  <Typography
                    variant={"h4"}
                    fontWeight={500}
                    fontSize={13}
                    sx={{ mb: 1 }}
                  >
                    <FormattedMessage
                      id={"STANDARD.TITLE"}
                      defaultMessage={"STANDARD.TITLE"}
                    />
                  </Typography>
                  <Controls.Input
                    name={"title"}
                    defaultValue={note?.title}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.title = event.target.value;
                    }}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"title"}
                    label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && note ? (
                <Box className={classes.input}>
                  <Typography
                    variant={"h4"}
                    fontWeight={500}
                    fontSize={13}
                    sx={{ mb: 1 }}
                  >
                    <FormattedMessage
                      id={"STANDARD.DESCRIPTION"}
                      defaultMessage={"STANDARD.DESCRIPTION"}
                    />
                  </Typography>
                  <Controls.Input
                    multiline={true}
                    rows={5}
                    name={"description"}
                    defaultValue={note?.description}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.description = event.target.value;
                    }}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    rows={5}
                    name={"description"}
                    label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            {note?.activities && (
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                <Box className=" kt-timeline-v2-forcase">
                  <Box className="kt-timeline-v2__items-forcase">
                    {note?.activities.map((activity) => {
                      const description = intl.formatMessage({
                        id: activity.title,
                      });
                      const descriptionArray = description.split("-");
                      const title = descriptionArray[0].replace(
                        "{who}",
                        activity.replacement.title
                      );
                      return (
                        <Box className="kt-timeline-v2__item">
                          <Box className="kt-timeline-v2__item-cricle">
                            <Avatar
                              variant="circular"
                              sx={{ width: 24, height: 24 }}
                              src={activity.replacement.avatar}
                              alt={title}
                            />
                          </Box>
                          <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                            <Typography variant={"body1"}>
                              <b style={{ color: "#556EE6" }}>
                                <a
                                  onClick={() => {
                                    history.push(
                                      `/users/${activity.replacement.id}`
                                    );
                                  }}
                                >
                                  {title}
                                </a>
                              </b>
                              {" - "} {descriptionArray[1]}
                            </Typography>
                            <Typography>
                              {moment
                                .unix(activity.created_at)
                                .local()
                                .format("DD-MM-YYYY HH:mm")}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowUserNote;
