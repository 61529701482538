// @ts-nocheck
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Media, Files, Links, Voices, Members } from "./index";
import { IUser } from "../../../../interfaces";
import { IFile } from "../../../../services/file.service";

export type MediaProps = {
  items: Array<IFile>;
  onClick?: (id: string) => void;
};

export type LinksProps = {
  items: Array<{
    id: string;
    title: string;
    secondary: string;
    url: string;
    extracted_image: string;
  }>;
  onClick: (id: string) => void;
};

export type FilesProps = {
  items: Array<IFile>;
  onClick?: (id: string) => void;
};

export type VoicesProps = {
  items: Array<IFile>;
  onClick?: (id: string) => void;
};

export type MembersProps = {
  items: Array<IUser>;
  onClick?: (id: string) => void;
  onContextClick?: (id: string) => void;
  contextMenuItems?: Array<{
    icon: string;
    title: string;
    onClick: (userId: string) => void;
  }>;
};

const Tabs: FC<{
  open: boolean;
  onClick: (top: number) => void;
  media: MediaProps;
  files: FilesProps;
  links: LinksProps;
  voices: VoicesProps;
  members?: MembersProps;
}> = (props) => {
  const { media, files, links, voices, members, onClick, open } = props;
  const intl = useIntl();
  const [value, setValue] = useState<string>("0");

  const [minPanelHeight, setPanelMinHeight] = useState<number | null>(null);
  const [scrollToPos, setScrollTop] = useState<number | null>(null);

  const tabRef = useRef<HTMLDivElement | null>(null);
  const _tabs = useMemo<
    Array<{ id: string; component: () => JSX.Element }>
  >(() => {
    let ret = [
      {
        id: "CHAT.MEDIA",
        component: () => {
          return <Media {...media} />;
        },
      },
      {
        id: "CHAT.FILES",
        component: () => {
          return <Files {...files} />;
        },
      },
      // {
      //   id: "CHAT.LINKS",
      //   component: () => {
      //     return <Links {...links} />;
      //   },
      // },
      {
        id: "CHAT.VOICE",
        component: () => {
          return <Voices {...voices} />;
        },
      },
    ];
    if (members) {
      ret.unshift({
        id: "CHAT.MEMBERS",
        component: () => {
          return <Members {...members} />;
        },
      });
    }

    return ret;
  }, [members]);

  useEffect(() => {
    const chatProfileContainer: HTMLElement | null = document.querySelector(
      "#chatProfileContainer"
    );
    const chatProfileHeader: HTMLElement | null =
      document.querySelector("#chatProfileHeader");

    if (!chatProfileContainer || !chatProfileHeader) return;

    let chatProfileContainerRect = chatProfileContainer.getBoundingClientRect();
    let chatProfileHeaderRect = chatProfileHeader.getBoundingClientRect();
    if (!chatProfileContainerRect || !chatProfileHeaderRect) return;

    setPanelMinHeight(
      chatProfileContainerRect.height - chatProfileHeaderRect.height - 30 - 38 // 30 is outline of header, 38 is tab navigation height
    );
  }, [open]);

  useEffect(() => {
    const chatProfileHeader: HTMLElement | null =
      document.querySelector("#chatProfileHeader");

    if (!tabRef.current) return;

    let tabRect = tabRef.current.getBoundingClientRect(),
      chatProfileHeaderRect = chatProfileHeader?.getBoundingClientRect();

    if (!tabRect || !chatProfileHeaderRect) return;

    setScrollTop(
      tabRect.top - chatProfileHeaderRect.top - chatProfileHeaderRect.height
    );
  }, [open]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue.toString());
  };

  const tabSX = {
    px: 0,
    py: 0.5,
    minWidth: "auto",
    maxWidth: "fit-content",
    height: "38px",
    minHeight: "38px",
  };

  return (
    <Box ref={tabRef}>
      <TabContext value={value}>
        <Box>
          <TabList
            variant="scrollable"
            scrollButtons={false}
            value={value}
            onChange={handleChange}
            sx={{
              height: "38px",
              minHeight: "38px",
              "& .MuiTabs-indicator": {
                borderRadius: "3px",
                backgroundColor: "#0D99FF",
              },
              // "& .MuiTabs-flexContainer": {
              //   justifyContent: "space-between",
              // },
            }}
          >
            {_tabs.map((tab, index) => {
              return (
                <Tab
                  onClick={() => {
                    if (scrollToPos) onClick(scrollToPos);
                  }}
                  disableRipple
                  sx={tabSX}
                  label={intl.formatMessage({
                    id: tab.id,
                  })}
                  value={index.toString()}
                />
              );
            })}
          </TabList>
        </Box>
        <Box
          sx={{
            height: minPanelHeight,
          }}
        >
          {_tabs.map((tab, index) => {
            return (
              <TabPanel
                value={index.toString()}
                tabIndex={index}
                sx={{ p: 0, width: "100%", overflowY: "scroll" }}
              >
                {tab.component()}
              </TabPanel>
            );
          })}
        </Box>
      </TabContext>
    </Box>
  );
};

export default Tabs;
