import React, { createContext } from "react";
import { IUrlQuery, IRestApiCollection, IRestApiError, IRestApiResource } from "../interfaces";
import {http} from "../lib/http";
import {API_ROUTES, getRoute} from "../config/apiRoutes";
import {queryToString} from "../utils/http";

export interface ILog {

}

const INDEX = API_ROUTES.logs;

export interface ILogService {
  getAll(queries?: Array<IUrlQuery>): Promise<IRestApiCollection<ILog> | IRestApiError>,
}


const logService : ILogService = {

  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : '';
    return  http(`${getRoute(INDEX)}?${queriesString}`, "get");
  },

}

export {logService};
