import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { accountingService } from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "app/hooks/useForm";
import moment, { Moment } from "moment";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";
import { ISetting } from "app/services/setting.service";
import ExpenseItems from "./items";
import Totals from "./totals";
import { IExpense, ISupplier } from "app/services/accounting.service";
import AttachmentsInfo from "../../Cases/show/parts/attachmentsInfo";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
}));

interface IFormProps {
  _id: string;
  open: boolean;
  onDone: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

type FormStateValues = {
  total: number;
  currency: string;
  supplier: string;
  invoice_date: string;
  payment_date: string;
  title: string;
  expense_category: string;
  description: string;
  items: any[];
  files: string[];
};

const ExpenseShow: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [expense, setExpense] = useState<IExpense>();
  const [suppliers, setSuppliers] = useState<ISupplier[]>();
  const form = useForm<FormStateValues>();
  const [currencyLabel, setCurrencyLabel] = useState<string>("$");
  const [items, setItems] = useState<any>(expense ? expense.items : []);
  const [data, setData] = useState<any>([]);
  const [invoiceDate, setInvoiceDate] = useState<Moment | undefined>(undefined);
  const [payment_date, setPaymentDate] = useState<Moment | undefined>(
    undefined
  );
  const [files, setFiles] = useState<any>([]);

  const { currencies, expense_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );

  useEffect(() => {
    form.values.files = files?.attachments?.map((file: any) => file._id);
  }, [files]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  useEffect(() => {
    accountingService
      .getOneExpense(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: expense } = data;
        setExpense(expense);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });

    accountingService
      .getAllSuppliers()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: suppliers } = data;
        setSuppliers(suppliers);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    accountingService
      .updateExpense(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (expense) {
      form.values.currency = expense.currency;
      form.values.title = expense.title;
      form.values.total = expense.total;
      form.values.items = expense.items;
      form.values.description = expense.description;
      form.values.supplier = expense.supplier?._id;
      form.values.invoice_date = (
        moment.unix(+expense.invoice_date).utc().valueOf() / 1000
      ).toString();
      form.values.payment_date = (
        moment.unix(+expense.payment_date).utc().valueOf() / 1000
      ).toString();
      setItems(expense.items);

      setData(expense.items);

      const expenseCurrency = currencies?.find(
        (r: ISetting) => r.slug === expense.currency
      )?.symbol;
      if (expenseCurrency) {
        setCurrencyLabel(expenseCurrency);
      }

      form.values.files = files?.file_ids?.map((file: any) => file._id);

      setFiles(expense.documents);
    }
  }, [expense]);

  const onChangeData = (items: any) => {
    setItems(items);
  };

  useEffect(() => {
    if (invoiceDate)
      form.values.invoice_date = (invoiceDate.valueOf() / 1000).toString();
  }, [invoiceDate]);

  useEffect(() => {
    if (payment_date)
      form.values.payment_date = (payment_date.valueOf() / 1000).toString();
  }, [payment_date]);

  const getTotal = (total: number) => {
    form.values.total = total;
  };

  useEffect(() => {
    form.values.items = items;
  }, [items]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"EXPENSE.SHOW"}
                  defaultMessage={"Show expense"}
                />
              </Typography>
              <Button
                form={"expense_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"expense_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && expense ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.TITLE",
                            defaultMessage: "Title",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        multiline={true}
                        defaultValue={expense.title}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && expense ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.SUPPLIER",
                            defaultMessage: "Supplier",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Select
                        options={
                          suppliers &&
                          suppliers.map((supplier) => {
                            return {
                              id: supplier._id,
                              title: supplier.title ?? "",
                            };
                          })
                        }
                        formId={"status_pick"}
                        defaultValue={expense?.supplier?._id}
                        name={"expense_category"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.supplier = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && expense ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.EXPENSE_CATEGORY",
                            defaultMessage: "Category",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Select
                        options={
                          expense_categories &&
                          expense_categories.map((expense_category) => {
                            return {
                              id: expense_category._id,
                              title: expense_category.title[intl.locale] ?? "",
                            };
                          })
                        }
                        formId={"status_pick"}
                        defaultValue={expense?.expense_category?._id}
                        name={"expense_category"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.expense_category = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && expense ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.CURRENCY",
                            defaultMessage: "Currency",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Select
                        options={
                          currencies &&
                          currencies.map((currency) => {
                            return {
                              id: currency.slug,
                              title: currency.currency_title ?? "",
                            };
                          })
                        }
                        formId={"status_pick"}
                        defaultValue={expense?.currency}
                        name={"status"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          const selected = currencies?.find(
                            (r: ISetting) => r.slug === event.target.value
                          )?.symbol;
                          if (selected) {
                            setCurrencyLabel(selected);
                          }

                          form.values.currency = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && expense ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.INVOICE_DATE",
                            defaultMessage: "Invoice date",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          value={invoiceDate}
                          defaultValue={moment(
                            expense.invoice_date,
                            "X"
                          ).local()}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.invoice_date = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                            setInvoiceDate(moment(dateString));
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            onClick={() => {
                              form.values.invoice_date = (
                                moment().local().valueOf() / 1000
                              ).toString();

                              setInvoiceDate(moment().local());
                            }}
                          >
                            Today
                          </Button>
                          <Button
                            onClick={() => {
                              setInvoiceDate(moment().local().add(8, "days"));
                            }}
                          >
                            + 8 day
                          </Button>
                          <Button
                            onClick={() => {
                              setInvoiceDate(moment().local().add(10, "days"));
                            }}
                          >
                            + 10 day
                          </Button>
                          <Button
                            onClick={() => {
                              setInvoiceDate(moment().local().add(30, "days"));
                            }}
                          >
                            + 30 day
                          </Button>
                        </Box>
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && expense ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.PAYMENT_DATE",
                            defaultMessage: "Payment date",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          value={payment_date}
                          defaultValue={moment(
                            expense.payment_date,
                            "X"
                          ).local()}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.payment_date = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                            setPaymentDate(moment(dateString));
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            onClick={() => {
                              form.values.payment_date = (
                                moment().local().valueOf() / 1000
                              ).toString();

                              setPaymentDate(moment().local());
                            }}
                          >
                            Today
                          </Button>
                          <Button
                            onClick={() => {
                              setPaymentDate(moment().local().add(8, "days"));
                            }}
                          >
                            + 8 day
                          </Button>
                          <Button
                            onClick={() => {
                              setPaymentDate(moment().local().add(10, "days"));
                            }}
                          >
                            + 10 day
                          </Button>
                          <Button
                            onClick={() => {
                              setPaymentDate(moment().local().add(30, "days"));
                            }}
                          >
                            + 30 day
                          </Button>
                        </Box>
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && expense ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                            defaultMessage: "Description",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"description"}
                        label={""}
                        multiline={true}
                        defaultValue={expense.description}
                        rows={6}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.description = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && expense && data ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.ITEMS",
                            defaultMessage: "Items",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <ExpenseItems
                        {...{
                          data: items,
                          type: "item",
                          onChangeData: onChangeData,
                          symbol: currencyLabel,
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Totals
                    items={items}
                    selectedCurrency={currencyLabel}
                    total={getTotal}
                  />
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && expense ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        Files
                      </Typography>
                      <AttachmentsInfo setParams={setFiles} params={expense} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"files"}
                        options={[]}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ExpenseShow;
