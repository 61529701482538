import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import * as _setting from "app/store/ducks/setting.duck";
import { Skeleton } from "@mui/lab";
import { mailboxService } from "app/services";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { IMailbox } from "../../services/mailbox.service";
import { FormattedMessage, useIntl } from "react-intl";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import { ISettingsState } from "app/store/ducks/setting.duck";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  subject: string;
  email: string;
  phone: string;
  is_secure: boolean;
  is_read: boolean;
  mail_response: boolean;
  sms_response: boolean;
  content: string;
  mail_content: string;
  type: string;
};

const initValues = {
  subject: "",
  email: "",
  phone: "",
  is_secure: false,
  is_read: false,
  mail_response: false,
  sms_response: false,
  content: "",
  mail_content: "",
  type: "mail",
} as FormStateValues;

const ShowMailbox = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);

  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [mailbox, setMailbox] = useState<IMailbox>();

  useEffect(() => {
    mailboxService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: mailbox } = data;
        setMailbox(mailbox);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  useEffect(() => {
    if (mailbox) {
      form.values.subject = mailbox.subject;
      form.values.email = mailbox.email;
      form.values.phone = mailbox.phone;
      form.values.is_secure = mailbox.is_secure;
      form.values.is_read = mailbox.is_read;
      form.values.mail_response = mailbox.mail_response;
      form.values.sms_response = mailbox.sms_response;
      form.values.content = mailbox.content;
      form.values.mail_content = mailbox.mail_content;
      form.values.type = mailbox.type;
    }
  }, [mailbox]);

  const handleSave = () => {
    let validated = form.validate();
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    mailboxService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          pr: 2,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"MAILBOX.SHOW"}
              defaultMessage={"MAILBOX.SHOW"}
            />
          </Typography>
          <Button
            form={"mailbox_form"}
            autoFocus
            color="inherit"
            type={"submit"}
            sx={{
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="m.172,3.708C-.216,2.646.076,1.47.917.713,1.756-.041,2.951-.211,3.965.282l18.09,8.444c.97.454,1.664,1.283,1.945,2.273H4.048L.229,3.835c-.021-.041-.04-.084-.057-.127Zm3.89,9.292L.309,20.175c-.021.04-.039.08-.054.122-.387,1.063-.092,2.237.749,2.993.521.467,1.179.708,1.841.708.409,0,.819-.092,1.201-.279l18.011-8.438c.973-.456,1.666-1.288,1.945-2.28H4.062Z"
                fill={"#0D99FF"}
              />
            </svg>
          </Button>
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id={"mailbox_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded && mailbox ? (
                <Controls.Input
                  name={"subject"}
                  defaultValue={mailbox?.subject}
                  label={intl.formatMessage({ id: "STANDARD.SUBJECT" })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.subject = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"subject"}
                    label={intl.formatMessage({ id: "STANDARD.SUBJECT" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded && mailbox ? (
                <Controls.Input
                  name={"email"}
                  defaultValue={mailbox?.email}
                  label={intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.email = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded && mailbox ? (
                <Controls.Input
                  name={"phone"}
                  defaultValue={mailbox?.phone}
                  label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.phone = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"phone"}
                    label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded && mailbox ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        form.values.is_secure = event.target.checked;
                      }}
                      defaultChecked={
                        mailbox?.is_secure && +mailbox.is_secure === 1
                      }
                    />
                  }
                  label={intl.formatMessage({ id: "STANDARD.ISSECURE" })}
                  name={"is_secure"}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={intl.formatMessage({ id: "STANDARD.ISSECURE" })}
                    name={"is_secure"}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && mailbox ? (
                <Controls.Editor
                  name={"content"}
                  label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                  data={mailbox?.content}
                  onChange={(data: any) => {
                    form.values.content = data;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"content"}
                    label={intl.formatMessage({ id: "STANDARD.CONTENT" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowMailbox);
