import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import {
  accountingService,
  businessService,
  caseService,
  userService,
} from "app/services";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { DatePicker, Space } from "antd";
import moment, { Moment } from "moment";
import { IUser } from "../../interfaces";
import { IBusiness } from "../../services/business.service";
import InvoiceItems from "./items";
import Totals from "./totals";
import { ISetting } from "../../services/setting.service";
import { ITemplate } from "app/services/accounting.service";
import { ICase } from "../../services/case.service";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
  iconButtonAdd: {
    "&:hover": {
      backgroundColor: "#556EE6!important",
    },
  },
}));

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  case: string;
  template: string;
  invoice_url: string;
  status: string;
  send_to: string;
  to_business: string;
  through: string;
  user: string;
  total: number;
  currency: string;
  paid_with: string;
  paid_at: string;
  overdue_date: string;
  description: string;
  articles: any[];
};

const initValues = {
  status: "draft",
  send_to: "",
  invoice_url: "",
  template: "",
  case: "",
  through: "",
  to_business: "",
  user: "",
  total: 0,
  currency: "usd",
  overdue_date: "",
  paid_with: "",
  paid_at: "",
  description: "",
  articles: [],
} as FormStateValues;

const AccountingStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [users, setUsers] = useState<IUser[]>([]);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const [cases, setCases] = useState<Array<ICase>>();
  const [businessSearch, setBusinessSearch] = useState<string>("");
  const [sendTo, setSendTo] = useState<string>("");
  const [currencyLabel, setCurrencyLabel] = useState<string>("$");
  const [overdueDate, setOverdueDate] = useState<Moment | undefined>(undefined);
  const [paid_date, setPaidDate] = useState<Moment | undefined>(undefined);
  const [items, setItems] = useState<any>([]);
  const [fines, setFines] = useState<any>([]);
  const [caseSearch, setCaseSearch] = useState<string>("");

  const { currencies } = useSelector(
    (state: RootState) => state.settingReducer
  );

  useEffect(() => {
    form.values.case = caseSearch;
  }, [caseSearch]);

  const loadCases = () => {
    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    props.open && loadCases();
  }, [props.open]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: userSearch,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    userService
      .getMinlist(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: businessSearch,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    businessService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: businesses } = data;

        setBusinesses(businesses);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [businessSearch]);

  const loadOwnUsers = () => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    userService
      .getMinlist(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const loadBusinesses = () => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "alphabetical",
        value: "true",
      },
    ];

    businessService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: businesses } = data;

        setBusinesses(businesses);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (sendTo == "business") loadBusinesses();
    else loadOwnUsers();
  }, [sendTo]);

  useEffect(() => {
    if (props.open) {
      form.values.status = "draft";

      accountingService
        .getAllTemplates()
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: templates } = data;

          setTemplates(templates);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [props.open]);

  useEffect(() => {
    form.values.send_to = sendTo;
  }, [sendTo]);

  useEffect(() => {
    if (overdueDate)
      form.values.overdue_date = (overdueDate.valueOf() / 1000).toString();
  }, [overdueDate]);

  useEffect(() => {
    if (paid_date)
      form.values.paid_at = (paid_date.valueOf() / 1000).toString();
  }, [paid_date]);

  const handleClose = () => {
    props.setDialogue(false);
    setOverdueDate(undefined);
    setPaidDate(undefined);
    setSendTo("");
  };

  const getTotal = (total: number) => {
    form.values.total = total;
  };

  const handleSave = () => {
    let url = window.location.hostname;

    if (window.location.port) {
      url += ":" + window.location.port;
    }

    form.values.invoice_url = url;

    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    if (validated.through && validated.through == "iban") {
      Snackbar.info("Send invoice through IBAN function not activated yet.");
      return;
    }

    accountingService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const onChangeData = (items: any, type: string) => {
    if (type === "item") {
      setItems(items);
    } else {
      setFines(items);
    }
  };
  const timeOut = useRef<any>();

  useEffect(() => {
    form.values.articles = items.concat(fines);
  }, [items, fines]);

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage id={"ADD"} defaultMessage={"Add"} />
              </Typography>
              <Button
                form={"invoice_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"invoice_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.INVOICE_TEMPLATE",
                            defaultMessage: "Invoice template",
                          })}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Controls.Select
                        options={
                          templates &&
                          templates.map((template) => {
                            return {
                              id: template._id,
                              title: template.title ?? "",
                            };
                          })
                        }
                        formId={"status_pick"}
                        defaultValue={""}
                        name={"status"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.template = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.CASE",
                            defaultMessage: "Case",
                          })}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        onChange={(e, value: any, reason: string) => {
                          if (reason === "clear") {
                            setCaseSearch("");
                            return;
                          } else {
                            setCaseSearch(value?.id);
                          }
                        }}
                        options={
                          cases
                            ? cases.map((_case) => {
                                return {
                                  id: _case._id.toString(),
                                  label: `${intl.formatMessage({
                                    id: "CASE.INFORMATION.CASE",
                                  })}: ${_case.key}; Users: ${_case.users.map(
                                    (u) => u.firstname
                                  )}`,
                                };
                              })
                            : []
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "STANDARD.SENDTO",
                          defaultMessage: "Send to",
                        })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Select
                      options={[
                        {
                          id: "business",
                          title: "Business",
                        },
                        {
                          id: "user",
                          title: "User",
                        },
                        {
                          id: "commune",
                          title: "Commune",
                        },
                      ]}
                      formId={"send_to"}
                      defaultValue={""}
                      name={"send_to"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        setSendTo(event.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                {sendTo == "business" ? (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded ? (
                      <Box className={classes.input}>
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={intl.formatMessage({
                              id: "STANDARD.BUSINESS",
                              defaultMessage: "Business",
                            })}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          onChange={(e, value: any, reason: string) => {
                            if (reason === "clear") {
                              setBusinessSearch("");
                              return;
                            } else {
                              setBusinessSearch(value?.id);
                              form.values.to_business = value?.id;
                            }
                          }}
                          options={
                            businesses
                              ? businesses.map((business) => {
                                  return {
                                    id: business._id.toString(),
                                    label: business.name,
                                  };
                                })
                              : []
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}

                {sendTo == "user" ? (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded ? (
                      <Box className={classes.input}>
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={intl.formatMessage({ id: "STANDARD.USERS" })}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          onChange={(e, value: any, reason: string) => {
                            if (reason === "clear") {
                              setUserSearch("");
                              return;
                            } else {
                              setUserSearch(value?.id);
                              form.values.user = value?.id;
                            }
                          }}
                          onInputChange={(e, value) => {
                            sendQuery(value);
                          }}
                          options={
                            users
                              ? users.map((user) => {
                                  return {
                                    id: user._id.toString(),
                                    label: user.firstname + " " + user.lastname,
                                  };
                                })
                              : []
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}

                {sendTo == "commune" ? (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded ? (
                      <Box className={classes.input}>
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={intl.formatMessage({
                              id: "STANDARD.THROUGH",
                              defaultMessage: "Through",
                            })}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Controls.Select
                          options={[
                            {
                              id: "email",
                              title: "Email",
                            },
                            {
                              id: "iban",
                              title: "IBAN",
                            },
                          ]}
                          formId={"status_pick"}
                          defaultValue={"email"}
                          name={"through"}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.through = event.target.value;
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "STANDARD.PAYMENT_SETTING",
                          defaultMessage: "Payment setting",
                        })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Select
                      options={[]}
                      formId={"status_pick"}
                      defaultValue={""}
                      name={"status"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.status = event.target.value;
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Select
                      options={[
                        {
                          id: "created",
                          title: intl.formatMessage({ id: "STANDARD.CREATED" }),
                        },
                        {
                          id: "draft",
                          title: intl.formatMessage({ id: "STANDARD.DRAFT" }),
                        },
                        {
                          id: "paid",
                          title: intl.formatMessage({ id: "STANDARD.PAID" }),
                        },
                      ]}
                      formId={"status_pick"}
                      defaultValue={"draft"}
                      name={"status"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.status = event.target.value;
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "STANDARD.CURRENCY",
                          defaultMessage: "Currency",
                        })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Select
                      options={
                        currencies &&
                        currencies.map((currency) => {
                          return {
                            id: currency.slug,
                            title: currency.currency_title ?? "",
                          };
                        })
                      }
                      formId={"status_pick"}
                      defaultValue={"usd"}
                      name={"status"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        const selected = currencies?.find(
                          (r: ISetting) => r.slug === event.target.value
                        )?.symbol;
                        if (selected) {
                          setCurrencyLabel(selected);
                        }

                        form.values.currency = event.target.value;
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.OVERDUEDATE",
                            defaultMessage: "Overdue date",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          value={overdueDate}
                          placeholder={intl.formatMessage({
                            id: "STANDARD.SELECTDATE",
                            defaultMessage: "Select date",
                          })}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.overdue_date = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                            setOverdueDate(moment(dateString));
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            onClick={() => {
                              form.values.overdue_date = (
                                moment().local().valueOf() / 1000
                              ).toString();

                              setOverdueDate(moment().local());
                            }}
                          >
                            {intl.formatMessage({
                              id: "STANDARD.TODAY",
                              defaultMessage: "Today",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setOverdueDate(moment().local().add(8, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.EIGHTDAY",
                              defaultMessage: "8 day",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setOverdueDate(moment().local().add(10, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.TENDAY",
                              defaultMessage: "10 day",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setOverdueDate(moment().local().add(30, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.THIRTYDAY",
                              defaultMessage: "30 day",
                            })}
                          </Button>
                        </Box>
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                            defaultMessage: "Description",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"description"}
                        label={""}
                        multiline={true}
                        rows={6}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.description = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.PAIDWITH",
                            defaultMessage: "Paid with",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"paid_with"}
                        label={""}
                        multiline={true}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.paid_with = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.PAIDAT",
                            defaultMessage: "Paid at",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          value={paid_date}
                          placeholder={intl.formatMessage({
                            id: "STANDARD.SELECTDATE",
                            defaultMessage: "Select date",
                          })}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.paid_at = dateString;
                            setPaidDate(moment(dateString));
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setPaidDate(moment().local());
                            }}
                          >
                            {intl.formatMessage({
                              id: "STANDARD.TODAY",
                              defaultMessage: "Today",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setPaidDate(moment().local().add(8, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.EIGHTDAY",
                              defaultMessage: "8 day",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setPaidDate(moment().local().add(10, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.TENDAY",
                              defaultMessage: "10 day",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setPaidDate(moment().local().add(30, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.THIRTYDAY",
                              defaultMessage: "30 day",
                            })}
                          </Button>
                        </Box>
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.ITEMS",
                            defaultMessage: "Items",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <InvoiceItems
                        {...{
                          data: items,
                          type: "item",
                          onChangeData: onChangeData,
                          symbol: currencyLabel,
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.FINES",
                            defaultMessage: "Fines",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <InvoiceItems
                        {...{
                          data: fines,
                          type: "fine",
                          onChangeData: onChangeData,
                          symbol: currencyLabel,
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Totals
                    items={items}
                    fines={fines}
                    symbol={currencyLabel}
                    total={getTotal}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default AccountingStore;
