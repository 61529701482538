import React, { ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  SelectChangeEvent
} from "@mui/material";

type ComponentProps = {
  name: string,
  label: string,
  value: number|string,
  error?: string,
  onChange: (event:  SelectChangeEvent<any>, child: ReactNode) => void,
  options: Array<any>
}

const RadioGroup = (props : ComponentProps) => {

  const { name, label, value,error=null, onChange, options } = props;

  return (
    <FormControl variant="outlined"
                 {...(error && { error: true })}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        defaultValue={''}
        onChange={onChange}>
        <MenuItem value="">None</MenuItem>
        {
          options.map(
            item => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
          )
        }
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default RadioGroup;
