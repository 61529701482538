import React, {Dispatch, FC, SetStateAction, useCallback} from 'react';
import Snackbar from "../../../widgets/Snackbar";
import {settingService} from "../../../services";
import SettingDialogue1, {FormStateValues} from "../../../partials/dialogues/store/Settings/settingDialogue1";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import * as setting from "../../../store/ducks/setting.duck";


interface IFormProps {
  open: boolean,
  setDialogue: Dispatch<SetStateAction<boolean>>
  onDone: Function
}

const VacancyLanguageStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch()
  const addSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Add, payload: s}),
    [dispatch]
  )
  const handleSave = (validated: FormStateValues, callback: () => void) => {

    if (!validated){
      Snackbar.error(intl.formatMessage({id: 'SNACKBAR.STOREERROR'}))
      return;
    }

    settingService.add(validated, [
      {
        name: 'type',
        value: 'vacancy_languages'
      }
      ]).then((data) => {
      if ("error" in data){
        throw new Error(data.error.message);
      }

      Snackbar.success(intl.formatMessage({id: 'SNACKBAR.ADDED'}))

      let {data: setting} = data;
      addSetting(setting);

      setTimeout(() => {
        props.setDialogue(false);
        props.onDone();
        callback();
      }, 1000)

    }).catch((e) => {
      Snackbar.error(e.message)
    });
  }

  return(
    <SettingDialogue1 open={props.open}
                      setDialogue={props.setDialogue}
                      section={intl.formatMessage({id: 'SETTINGS.VACANCYLANGUAGE'})}
                      save={handleSave}/>
  )
}

export default VacancyLanguageStore;
