import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { accountingService } from "app/services";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { ITaxRate } from "../../../services/accounting.service";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  rate: number;
};

const initValues = {
  title: "",
  rate: 0,
} as FormStateValues;

const ShowTaxRate = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [rate, setRate] = useState<ITaxRate>();

  useEffect(() => {
    accountingService
      .getOneTaxRate(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: rate } = data;
        setRate(rate);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    accountingService
      .updateTaxRate(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (rate) {
      form.values.title = rate.title;
      form.values.rate = rate.rate;
    }
  }, [rate]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          pr: 2,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"TAX_RATE.SHOW"}
              defaultMessage={"Show tax rate"}
            />
          </Typography>
          <Button
            form={"rate_form"}
            autoFocus
            color="inherit"
            type={"submit"}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage
              id={"DIALOGUE.SAVE"}
              defaultMessage={"DIALOGUE.SAVE"}
            />
          </Button>
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id={"rate_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && rate ? (
                <Controls.Input
                  name={"title"}
                  label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                  defaultValue={rate?.title}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.title = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"title"}
                    label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && rate ? (
                <Controls.Input
                  name={"rate"}
                  type={"number"}
                  defaultValue={rate.rate}
                  label={intl.formatMessage({
                    id: "STANDARD.RATE",
                    defaultMessage: "Rate",
                  })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.rate = +event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    rows={5}
                    name={"rate"}
                    label={intl.formatMessage({
                      id: "STANDARD.RATE",
                      defaultMessage: "Rate",
                    })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowTaxRate;
