// @ts-nocheck
import React from "react";
import uuid from "react-uuid";
import { Draggable } from "react-beautiful-dnd";
import useOutsideAlerter from "./useOutsideAlerter";
import Controls from "../../../../../widgets/controls";
import { Textarea, useModal } from "fogito-core-ui";
import { Box, Checkbox } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Item = React.memo(
  ({ row, index, params, setParams, addItemsToggle, closeAddItemsToggle }) => {
    const modal = useModal();
    const contentRef = React.useRef();
    const itemAddRef = React.useRef();
    const itemAddInputRef = React.useRef();
    const [state, setState] = React.useReducer(
      (prevState, newState) => ({ ...prevState, ...newState }),
      {
        editItemsTitle: false,
        addItemsTitle: false,
        selectedItem: "",
        title: "",
        itemToggleVisible: false,
        editTitle: "",
      }
    );

    const onChangeCompleted = async (value, item, key) => {
      setParams({
        ...params,
        checklist: params.checklist.map((x, i) => {
          if (i == index) {
            return {
              ...x,
              items: x.items.map((c, d) => {
                if (d == key) {
                  return { ...c, completed: !value ? 0 : 1 };
                }
                return c;
              }),
            };
          }
          return x;
        }),
      });
    };

    const onAddChecklistItem = async (e, row, index) => {
      e.preventDefault();
      let array = state.title?.split("\n");
      let newData = [];
      for (const title of array) {
        if (title.trim() !== "") {
          itemAddInputRef.current?.querySelector("textarea")?.focus();
          newData.push({
            id: uuid(),
            title: title,
            completed: 0,
            due_date: false,
            user: false,
          });
          setParams({
            ...params,
            checklist: params.checklist.map((x, i) => {
              if (i == index) {
                return {
                  ...x,
                  items: x.items.concat([...newData]),
                };
              }
              return { ...x };
            }),
          });
        }
      }
      setState({ title: "" });
    };

    const onDeleteChecklistItem = (key) => {
      let arr = params.checklist;
      arr[index].items.splice(key, 1);
      setParams({ ...params, checklist: arr });
      setState({ itemToggleVisible: false });
    };

    const onEdit = async (e, key, item) => {
      e.preventDefault();
      if (state.editTitle.trim() !== "") {
        setParams({
          ...params,
          checklist: params.checklist.map((x, i) => {
            if (i == index) {
              return {
                ...x,
                items: x.items.map((c, d) => {
                  if (d == key) {
                    return {
                      ...c,
                      title: state.editTitle,
                      completed: item.completed,
                    };
                  }
                  return c;
                }),
              };
            }
            return x;
          }),
        });
        setState({ editItemsTitle: false });
      }
    };

    useOutsideAlerter(contentRef, function () {
      setState({ editItemsTitle: false });
    });

    useOutsideAlerter(itemAddRef, function () {
      closeAddItemsToggle();
    });

    const escButton = (event) => {
      if (event.keyCode === 27) {
        setState({
          editItemsTitle: false,
          addItemsTitle: false,
          title: "",
        });
        modal.hide("convert");
      }
    };

    React.useEffect(() => {
      document.addEventListener("keydown", (e) => escButton(e), false);
      return () => {
        document.addEventListener("keydown", (e) => escButton(e), false);
      };
    }, []);

    return (
      <>
        {row.items.map((item, key) => (
          <Draggable draggableId={item.id} index={key} key={item.id}>
            {(provided, snapshot) => {
              // if (snapshot.isDragging) {
              //   provided.draggableProps.style.left =
              //     provided.draggableProps.style.offsetLeft;
              //   provided.draggableProps.style.top =
              //     provided.draggableProps.style.offsetTop;
              // }
              return (
                <div className="position-relative" key={key}>
                  <div {...provided.draggableProps} ref={provided.innerRef}>
                    <div className="checklist-line">
                      <div className="form-check w-100">
                        <div className="d-flex align-items-center">
                          <Box
                            {...provided.dragHandleProps}
                            style={{ width: 13 }}
                            className="mr-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Outline"
                              viewBox="0 0 24 24"
                              width="13"
                              height="13"
                            >
                              <path d="M4.5,17.5H2a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,4.5,17.5Zm0,4.5H2V19.5H4.5Z" />
                              <path d="M22,17.5H19.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V19.5A2,2,0,0,0,22,17.5ZM22,22H19.5V19.5H22Z" />
                              <path d="M4.5,8.75H2a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,4.5,8.75Zm0,4.5H2v-2.5H4.5Z" />
                              <path d="M22,8.75H19.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,22,8.75Zm0,4.5H19.5v-2.5H22Z" />
                              <path d="M4.5,0H2A2,2,0,0,0,0,2V4.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V2A2,2,0,0,0,4.5,0Zm0,4.5H2V2H4.5Z" />
                              <path d="M13.25,17.5h-2.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,13.25,17.5Zm0,4.5h-2.5V19.5h2.5Z" />
                              <path d="M13.25,8.75h-2.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,13.25,8.75Zm0,4.5h-2.5v-2.5h2.5Z" />
                              <path d="M13.25,0h-2.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V2A2,2,0,0,0,13.25,0Zm0,4.5h-2.5V2h2.5Z" />
                              <path d="M22,0H19.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,4.5H19.5V2H22Z" />
                            </svg>
                          </Box>
                          <Checkbox
                            onChange={(e) => {
                              e.stopPropagation();
                              onChangeCompleted(e.target.checked, item, key);
                            }}
                            checked={item.completed}
                          />
                          {/*<InputCheckbox*/}
                          {/*  onChange={(e) =>*/}
                          {/*    e.stopPropagation() +*/}
                          {/*    onChangeCompleted(e.target.checked, item, key)*/}
                          {/*  }*/}
                          {/*  checked={item.completed}*/}
                          {/*/>*/}
                        </div>
                        {state.editItemsTitle === key ? (
                          <form
                            ref={contentRef}
                            style={{ width: "90%" }}
                            className="ml-2"
                          >
                            <div className="form-group mb-2">
                              <Textarea
                                rows="1"
                                maxLength="300"
                                defaultValue={item.title}
                                onChange={(e) =>
                                  setState({ editTitle: e.target.value })
                                }
                                placeholder={"Title"}
                                className="form-control"
                                autoFocus
                                onFocus={(e) => {
                                  let length = e.target.value.length;
                                  e.target.setSelectionRange(length, length);
                                }}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                    onEdit(e, key, item);
                                  }
                                }}
                              />
                            </div>
                            <div className="d-flex">
                              <button
                                style={{
                                  backgroundColor: "#0D99FF",
                                  borderColor: "#0D99FF",
                                }}
                                onClick={(e) => onEdit(e, key, item)}
                                className="btn btn-primary px-5"
                                type="button"
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-secondary px-5"
                                type="button"
                                onClick={() => {
                                  setState({
                                    editItemsTitle: false,
                                    title: "",
                                  });
                                  setParams({
                                    ...params,
                                    checklist: params.checklist.map((x, i) => {
                                      if (i == index) {
                                        return {
                                          ...x,
                                          items: x.items.map((c, d) => {
                                            if (d == key) {
                                              return {
                                                ...c,
                                                title: item.title,
                                              };
                                            }
                                            return c;
                                          }),
                                        };
                                      }
                                      return x;
                                    }),
                                  });
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        ) : (
                          <button
                            onClick={() => {
                              setState({
                                editItemsTitle: key,
                                editTitle: item.title,
                              });
                              closeAddItemsToggle();
                            }}
                            className="btn btn-block shadow-none bg-transparent p-0 ml-2"
                            style={{
                              whiteSpace: "normal",
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                            type="button"
                          >
                            {item.title}
                          </button>
                        )}
                      </div>
                      {state.editItemsTitle !== key && (
                        <div className="d-flex">
                          <button
                            className="btn shadow-none bg-transparent feather feather-x fs-18 p-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              setState({ itemToggleVisible: key });
                              onDeleteChecklistItem(key);
                            }}
                            type="button"
                          >
                            <CloseOutlinedIcon
                              style={{
                                color: "#EC3033",
                              }}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }}
          </Draggable>
        ))}
        {addItemsToggle === index && (
          <form style={{ marginLeft: "2.6rem" }} ref={itemAddRef}>
            <div className="form-group mb-1" ref={itemAddInputRef}>
              <Controls.Input
                className="form-control mr-2 mt-2"
                autoFocus
                value={state.title}
                placeholder={"Title"}
                onChange={(e) => setState({ title: e.target.value })}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    onAddChecklistItem(e, row, index);
                  }
                }}
              />
            </div>
            <div className="d-flex mt-2">
              <button
                className="btn btn-primary px-5 py-2"
                style={{
                  backgroundColor: "#0D99FF",
                  borderColor: "#0D99FF",
                }}
                onClick={(e) => onAddChecklistItem(e, row, index)}
              >
                {"Save"}
              </button>
              <button
                className="btn btn-secondary px-5 py-2"
                onClick={closeAddItemsToggle}
                type="button"
              >
                {"Cancel"}
              </button>
            </div>
          </form>
        )}
      </>
    );
  }
);

export default Item;
