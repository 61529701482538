import React, { createContext } from "react";
import { IUrlQuery, IRestApiCollection, IRestApiError, IRestApiResource } from "../interfaces";
import {http} from "../lib/http";
import {API_ROUTES, getRoute} from "../config/apiRoutes";
import {queryToString} from "../utils/http";

export interface ISurvey {

}

const INDEX = API_ROUTES.surveys;

export interface ISurveyService {
  getAll(queries?: Array<IUrlQuery>): Promise<IRestApiCollection<ISurvey> | IRestApiError>,
}


const surveyService : ISurveyService = {

  async getAll(queries: Array<IUrlQuery>) {
    let queriesString = queryToString(queries);
    return  http(`${getRoute(INDEX)}?${queriesString}`, "get");
  },

}

export {surveyService};
