import React, { useRef } from "react";
import { Box, Grid } from "@mui/material";
import Controls from "../../../../widgets/uncontrolled";
import { useIntl } from "react-intl";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  calendar: {
    zIndex: 9999,
  },
  dateRangeInput: {
    width: "100%",
    borderRadius: 8,
    border: "none!important",
    "& .ant-picker-focused": {
      boxShadow: "none!important",
    },
    "& .ant-picker": {
      border: "1px solid #E6E6E6!important",
      height: 40,
      backgroundColor: "#fff",
      borderRadius: 8,
      width: "100%",
      color: "#000000!important",
      "& .ant-picker-input input::placeholder": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiInputLabel-root": {
        top: "-5px",
      },
      height: 40,
      backgroundColor: "#fff",
      border: "1px solid #E6E6E6",
      borderRadius: 8,
      m: () => ({}),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
    },
  },
}));

const DocumentFilter = (props: { show: boolean }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { RangePicker } = DatePicker;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
          <Box className={classes.input}>
            <Controls.Input
              name={"title"}
              label={intl.formatMessage({ id: "STANDARD.TITLE" })}
              onChange={(event) => {
                if (!event.target.value) return;
              }}
            />
          </Box>
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
          <Space
            direction="vertical"
            size={12}
            className={classes.dateRangeInput}
          >
            <RangePicker dropdownClassName={classes.calendar} />
          </Space>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentFilter;
