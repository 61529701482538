import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import * as _setting from "app/store/ducks/setting.duck";
import { Skeleton } from "@mui/lab";
import { folderService } from "app/services";
import { Grid, Button, Slide, Typography, Chip } from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { IFolderShow } from "app/services/folder.service";
import { FormattedMessage, useIntl } from "react-intl";
import { PermissionsGate } from "app/permission/PermissionsGate";
import { useParams } from "react-router";
import { Ii18nState } from "../../../store/ducks/i18n.duck";
import { ISettingsState } from "app/store/ducks/setting.duck";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  category: string;
  users: Array<string>;
};

const initValues = {
  title: "",
  category: "",
  users: [],
} as FormStateValues;

const ShowFolder = (props: IFormProps) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [mode, setMode] = useState<string>("show");
  const [folder, setFolder] = useState<IFolderShow>();

  useEffect(() => {
    folderService
      .getOne(id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: folder } = data;
        setFolder(folder);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [id, mode]);

  const handleClose = () => {
    props.setDialogue(false);
    setMode("show");
  };

  const changeMode = () => {
    setMode("update");
  };
  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    folderService
      .update(id, validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        setTimeout(() => {
          setMode("show");
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <PermissionsGate section={"notes"} scope={"read"}>
      <div
        style={{ padding: "20px" }}
        className="kt-portlet kt-portlet--bordered"
      >
        <form
          id={"folder_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
            {loaded ? (
              mode === "show" ? (
                <Typography>
                  <Chip
                    color="primary"
                    sx={{ mr: "10px" }}
                    label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                  />
                  {folder?.title}
                </Typography>
              ) : (
                <Controls.Input
                  name={"title"}
                  label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                  defaultValue={folder?.title}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.title = event.target.value;
                  }}
                />
              )
            ) : (
              <Skeleton width={"100%"}>
                <Controls.Input
                  name={"title"}
                  label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                  onChange={() => {}}
                />
              </Skeleton>
            )}
          </Grid>
          {mode === "show" ? (
            <Button
              sx={{ mt: "20px" }}
              onClick={(e) => {
                changeMode();
                if (folder) {
                  form.values.title = folder?.title;
                }
                e.preventDefault();
              }}
              variant="contained"
              color="primary"
            >
              <FormattedMessage
                id={"DIALOGUE.EDIT"}
                defaultMessage={"DIALOGUE.EDIT"}
              />
            </Button>
          ) : (
            <Button
              sx={{ mt: "20px" }}
              variant="contained"
              color="primary"
              data-cy-class={"todo_update"}
              data-cy-note-id={folder?._id}
              data-cy-case-id={props._id}
              type={"submit"}
              form={"folder_form"}
            >
              <FormattedMessage
                id={"DIALOGUE.UPDATE"}
                defaultMessage={"DIALOGUE.UPDATE"}
              />
            </Button>
          )}
        </form>
      </div>
    </PermissionsGate>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    communes: [],
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowFolder);
