import {
  IUrlQuery,
  IUser,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

export interface IFolder {
  _id: string;
  title: string;
  category: string;
  users: Array<IUser>;
  created_by: IUser;
}

export interface IFolderStore {
  _id: string;
  title: string;
  category: string;
  users: Array<string>;
}

export interface IFolderShow {
  _id: string;
  title: string;
  category: string;
  users: Array<string>;

  _created_at: string;
  _updated_at: string;
}

const INDEX = API_ROUTES.folders;
const SHOW = API_ROUTES.folder;
const UPDATE = API_ROUTES.folder;
const STORE = API_ROUTES.folders;
const DELETE = API_ROUTES.folder_delete;

export interface IFolderService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IFolder> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IFolderShow> | IRestApiError>;
  update(
    _id: string,
    data: IFolderStore
  ): Promise<IRestApiResource<IFolder> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<IFolder> | IRestApiError>;
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>;
}

const folderService: IFolderService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async add(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },
};

export { folderService };
