import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IRole,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

export interface IContractTemplate {
  _id: string;
  title: string;
  content: string;
  for: IRole;
  _created_at: string;
  _updated_at: string;
}

export interface IContract {
  _id: string;
  slug: string;
  subject: string;
  content: string;
  signed: number;
  _created_at: string;
  _updated_at: string;
}

export interface IContractTemplateStore {
  title: string;
  content: string;
  for: string;
}

const INDEX = API_ROUTES.contractTemplates;
const STORE = API_ROUTES.contractTemplates;
const SHOW = API_ROUTES.contractTemplate;
const UPDATE = API_ROUTES.contractTemplate;
const DELETE = API_ROUTES.contractTemplate_delete;

const CONTRACTS_INDEX = API_ROUTES.contracts;
const CONTRACTS_SHOW = API_ROUTES.contract;

const CONTRACTS = API_ROUTES._contracts;
const CONTRACTS_ONE = API_ROUTES._contract;
const CONTRACT_EXPORT = API_ROUTES._contract_export;

export interface IContractTemplateService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IContractTemplate> | IRestApiError>;
  getOne(
    _id: string
  ): Promise<IRestApiResource<IContractTemplate> | IRestApiError>;
  update(
    _id: string,
    data: IContractTemplateStore
  ): Promise<IRestApiResource<IContractTemplate> | IRestApiError>;
  delete(
    _id?: string
  ): Promise<IRestApiCollection<IContractTemplate> | IRestApiError>;
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>;
  getContracts(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;
  getOneContract(
    folderId: string,
    _id: string
  ): Promise<IRestApiResource<IContract> | IRestApiError>;
  getSingleContract(
    _id: string
  ): Promise<IRestApiResource<IContract> | IRestApiError>;
  export(_id: string): Promise<IRestApiResource<any> | IRestApiError>;
}

const contractTemplateService: IContractTemplateService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: IContractTemplateStore) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async add(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

  async getContracts(templateId: string, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(CONTRACTS_INDEX).replace(
        ":templateId",
        templateId.toString()
      )}${queryString}`,
      "get"
    );
  },

  async getOneContract(templateId: string, _id: string) {
    return http(
      `${getRoute(CONTRACTS_SHOW)
        .replace(":templateId", templateId.toString())
        .replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async getSingleContract(_id: string) {
    return http(
      `${getRoute(CONTRACTS_ONE).replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async export(_id: string) {
    return http(
      `${getRoute(CONTRACT_EXPORT).replace(":_id", _id.toString())}`,
      "get"
    );
  },
};

export { contractTemplateService };
