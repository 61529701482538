import { IUrlQuery, IRestApiCollection, IRestApiError, IRestApiResource } from "../interfaces";
import {http} from "../lib/http";
import {API_ROUTES, getRoute} from "../config/apiRoutes";
import {generateFormData, queryToString} from "../utils/http";


export interface IKnowledgebase {
  _id: string
  title: string
}

const INDEX = API_ROUTES.knowledgebase_categories;
const SHOW = API_ROUTES.knowledgebase_category;
const UPDATE = API_ROUTES.knowledgebase_category;
const STORE = API_ROUTES.knowledgebase_categories;
const DELETE = API_ROUTES.knowledgebase_category_delete;

const ITEM_INDEX = API_ROUTES.knowledgebase_category_items;
const ITEM_STORE = API_ROUTES.knowledgebase_category_items;
const ITEM_SHOW = API_ROUTES.knowledgebase_category_item;
const ITEM_UPDATE = API_ROUTES.knowledgebase_category_item;
const ITEM_DELETE = API_ROUTES.knowledgebase_category_item;


export interface IKnowledgebaseService {
  getAll(queries?: Array<IUrlQuery>): Promise<IRestApiCollection<IKnowledgebase> | IRestApiError>,
  getOne(_id: string): Promise<IRestApiResource<IKnowledgebase> | IRestApiError>,
  update(_id: string, data: IKnowledgebase): Promise<IRestApiResource<IKnowledgebase> | IRestApiError>,
  delete(_id: string): Promise<IRestApiCollection<IKnowledgebase> | IRestApiError>,
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>,
  getItems(knowledgebaseCategoryId: string, queries?: Array<IUrlQuery>): Promise<IRestApiCollection<any> | IRestApiError>,
  addItem(knowledgebaseCategoryId: string, data: any, queries?: Array<IUrlQuery>): Promise<IRestApiResource<any> | IRestApiError>,
  getOneItem(knowledgebaseCategoryId: string, _id: string): Promise<IRestApiResource<any> | IRestApiError>,
  updateItem(knowledgebaseCategoryId: string, _id: string, data: any, queries?: Array<IUrlQuery>): Promise<IRestApiResource<any> | IRestApiError>,
  deleteOneItem(knowledgebaseCategoryId: string, _id: string): Promise<IRestApiCollection<any> | IRestApiError>,
}


const knowledgebaseService: IKnowledgebaseService = {

  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : '';
    return  http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return  http(`${getRoute(SHOW).replace(':_id', _id.toString())}`, "get");
  },

  async update(_id: string, data: IKnowledgebase) {
    let jsonData = JSON.stringify(data);
    return  http(`${getRoute(UPDATE).replace(':_id', _id.toString())}`, "put", jsonData);
  },

  async delete(_id: string,queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : '';
    return http(`${getRoute(DELETE.replace(':_id', _id.toString()))}${queriesString}`, "delete");
  },

  async add(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

  async getItems(knowledgebaseCategoryId: string, queries: Array<IUrlQuery>){
    let queryString = queries ? `?${queryToString(queries)}` : '';
    return  http(`${getRoute(ITEM_INDEX)
      .replace(':knowledgebaseCategoryId', knowledgebaseCategoryId.toString())}${queryString}`, "get");
  },

  async addItem(knowledgebaseCategoryId: string, data: any, queries?: Array<IUrlQuery>){
    let formData = generateFormData(new FormData(), data);
    let queryString = queries ? `?${queryToString(queries)}` : '';
    return  http(`${getRoute(ITEM_STORE)
      .replace(':knowledgebaseCategoryId', knowledgebaseCategoryId.toString())}${queryString}`, "post", formData);
  },

  async getOneItem(knowledgebaseCategoryId: string, _id: string) {
    return  http(`${getRoute(ITEM_SHOW)
      .replace(':knowledgebaseCategoryId', knowledgebaseCategoryId.toString())
      .replace(':_id', _id.toString())}`, "get");
  },

  async updateItem(knowledgebaseCategoryId: string, _id: string, data: any, queries?: Array<IUrlQuery>){
    let jsonData = JSON.stringify(data);
    let queryString = queries ? `?${queryToString(queries)}` : '';
    return  http(`${getRoute(ITEM_UPDATE)
      .replace(':knowledgebaseCategoryId', knowledgebaseCategoryId.toString())
      .replace(':_id', _id.toString())}${queryString}`, "put", jsonData);
  },

  async deleteOneItem(knowledgebaseCategoryId: string, _id: string) {
    return  http(`${getRoute(ITEM_DELETE)
        .replace(':knowledgebaseCategoryId', knowledgebaseCategoryId.toString())
        .replace(':_id', _id.toString())}`, "delete");
  },

}

export {knowledgebaseService};
