import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import Controls from "../../../../widgets/uncontrolled";
import React, { FC, memo, useState } from "react";
import { ISetting } from "../../../../services/setting.service";
import { FormattedMessage, useIntl } from "react-intl";
import { ICase } from "../../../../services/case.service";
import { makeStyles } from "@material-ui/core";

export interface IFormFocusValues {
  focus_area?: string;
  focus_type?: string;
  report?: boolean;
  report_type?: string;
  report_interval?: string;
}

interface IFormUsersProps {
  case?: ICase;
  focus_areas: Array<ISetting>;
  focus_types: Array<ISetting>;
  report_types: Array<ISetting>;
  report_intervals: Array<ISetting>;
  touched: boolean;
  handleChange: any;
  values: IFormFocusValues;
}

const Report: FC<IFormUsersProps> = (props) => {
  const intl = useIntl();
  const [report, setReport] = useState<boolean>(true);

  const useStyles = makeStyles(() => ({
    input: {
      "& .MuiFormControl-root": {
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            top: 0,
            border: "1px solid #E6E6E6!important",
            borderRadius: 6,
            "& legend": {
              display: "none!important",
            },
          },
        },
      },
    },
  }));

  const classes = useStyles();

  const handleRTSelection = (e: SelectChangeEvent<any>): void => {
    props.values.report_type = e.target.value;
  };

  const handleRISelection = (e: SelectChangeEvent<any>): void => {
    props.values.report_interval = e.target.value;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Box
                  sx={{
                    "& .MuiSwitch-root": {
                      "& .Mui-checked": {
                        color: "#0D99FF!important",
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: "#0D99FF!important",
                      },
                    },
                  }}
                >
                  <Switch
                    defaultChecked={props.case?.report ?? false}
                    name={"report_check"}
                    sx={{ color: "#0D99FF" }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setReport(Boolean(event.target.checked))
                    }
                  />
                </Box>
              }
              label={intl.formatMessage({ id: "CASE.INFORMATION.REPORT" })}
            />
          </FormGroup>
        </Grid>
        {report ? (
          <>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.FORM.REPORTTYPE"}
                    defaultMessage={"CASE.FORM.REPORTTYPE"}
                  />
                </Typography>
                <Controls.Select
                  onChange={(e) => handleRTSelection(e)}
                  label={""}
                  formId={"report_type_pick"}
                  name={"report_type"}
                  defaultValue={props.case?.report_type?._id}
                  options={props.report_types?.map((r) => ({
                    id: r._id.toString(),
                    title: r.title && r.title[intl.locale],
                  }))}
                  error={""}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              <Box className={classes.input}>
                <Typography variant={"body1"} sx={{ mb: 1 }}>
                  <FormattedMessage
                    id={"CASE.INFORMATION.REPORTINTERVAL"}
                    defaultMessage={"CASE.INFORMATION.REPORTINTERVAL"}
                  />
                </Typography>
                <Controls.Select
                  onChange={(e) => handleRISelection(e)}
                  label={""}
                  formId={"report_interval_pick"}
                  name={"report_interval"}
                  defaultValue={props.case?.report_interval?._id}
                  options={props.report_intervals?.map((r) => ({
                    id: r._id.toString(),
                    title: r.title && r.title[intl.locale],
                  }))}
                  error={""}
                />
              </Box>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default memo(Report);
