import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "../../../widgets/uncontrolled";
import { IFormUsersValues } from "../../Cases/store/parts/users";
import Snackbar from "../../../widgets/Snackbar";
import { caseService, userService } from "../../../services";
import { IUser } from "../../../interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { DatePicker, Space } from "antd";
import Attachments from "../../Cases/store/parts/attachments";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  date: string;
  amount: string;
  direction: string;
  type: string;
  status: number;
  users: Array<IUser>;
  files?: Array<string>;
} & IFormUsersValues;

const initValues = {
  title: "",
  date: moment(new Date()).format("YYYY-MM-DD"),
  amount: "",
  direction: "outcome",
  type: "transfer",
  status: 1,
  users: [],
  documents: [],
} as FormStateValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const FinanceStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const classes = useStyles();
  const { user } = useSelector(({ auth }: RootState) => auth);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { finance_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    form.values.files = files?.file_ids?.map((file: any) => file._id);
  }, [files]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    validated["operation_type"] = "user";

    userService
      .postFinance(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
          form.values.type = initValues.type;
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.FINANCE.ADD"}
                  defaultMessage={"CASE.FINANCE.ADD"}
                />
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type={"submit"}
                form={"finance_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent sx={{ p: "20px 44px" }}>
            <form
              id={"finance_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={3} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"STANDARD.TITLE"}
                          defaultMessage={"STANDARD.TITLE"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;
                          form.values.title = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"STANDARD.DATE"}
                          defaultMessage={"STANDARD.DATE"}
                        />
                      </Typography>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.date = dateString;
                          }}
                        />
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={
                    user?.role?.slug === "moderator" ||
                    user?.role?.slug === "super-admin"
                      ? 4
                      : 6
                  }
                  xl={
                    user?.role?.slug === "moderator" ||
                    user?.role?.slug === "super-admin"
                      ? 4
                      : 6
                  }
                >
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"CASE.FINANCE.AMOUNT"}
                          defaultMessage={"CASE.FINANCE.AMOUNT"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"amount"}
                        label={""}
                        type={"number"}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.amount = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"amount"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {user?.role?.slug === "moderator" ||
                  (user?.role?.slug === "super-admin" && (
                    <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                      {loaded ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"}>
                            <FormattedMessage
                              id={"CASE.FINANCE.DIRECTION"}
                              defaultMessage={"CASE.FINANCE.DIRECTION"}
                            />
                          </Typography>
                          <Controls.Select
                            name={"direction"}
                            formId={"direction"}
                            options={[
                              {
                                id: "income",
                                title: intl.formatMessage({
                                  id: "CASE.FINANCE.PLUS",
                                }),
                              },
                              {
                                id: "outcome",
                                title: intl.formatMessage({
                                  id: "CASE.FINANCE.MINUS",
                                }),
                              },
                            ]}
                            label={""}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              form.values.direction = event.target.value;
                            }}
                          />
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Select
                            name={"direction"}
                            options={[]}
                            label={""}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  ))}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={
                    user?.role?.slug === "moderator" ||
                    user?.role?.slug === "super-admin"
                      ? 4
                      : 6
                  }
                  xl={
                    user?.role?.slug === "moderator" ||
                    user?.role?.slug === "super-admin"
                      ? 4
                      : 6
                  }
                >
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"STANDARD.STATUS"}
                          defaultMessage={"STANDARD.STATUS"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"status"}
                        formId={"status"}
                        options={finance_statuses?.map((f_s) => {
                          return {
                            id: f_s._id,
                            title: f_s.title[intl.locale],
                          };
                        })}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.status = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"status"}
                        options={[]}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        Files
                      </Typography>
                      <Attachments {...{ setFiles, files }} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"files"}
                        options={[]}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default FinanceStore;
