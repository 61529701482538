import React, { FC, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Fade,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchInput from "app/widgets/SearchInput";
import { chatService } from "../../../../services/chat.service";
import Drawer from "@mui/material/Drawer";
import { IMessage } from "../messenger";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export type MessengerHeaderProps = {
  onClick: (searchText: string) => void;
  info: {
    image: string;
    main: string;
    secondary: string;
  };
  room: string;
  onSearch: Function;
  actionPanel: JSX.Element;
};

const Header: FC<MessengerHeaderProps> = (props) => {
  const { actionPanel, info, onClick, onSearch, room } = props;
  const [checked, setSearchChecked] = useState<boolean>(false);
  const [hideSearchIcon, setSearchIconHide] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [drawer, setDrawer] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<IMessage>>([]);
  const timeOut = useRef<any>();

  useEffect(() => {
    if (searchText) {
      if (!timeOut.current) return;

      clearInterval(timeOut.current);
      timeOut.current = setTimeout(getSearchMessages, 500, searchText);
    }
  }, [searchText]);

  const getSearchMessages = (text: string) => {
    if (!text) return;
    setMessages([]);
    chatService
      .getMessages(room, [{ name: "keyword", value: text }])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: messages } = data;

        if (messages.length > 0) {
          setDrawer(true);
          setMessages(messages);
          setSearchText("");
        }
      });
  };

  const clear = () => {
    setSearchText("");
  };

  useEffect(() => {
    timeOut.current = setTimeout(() => {});
    return () => clear();
  }, []);

  return info.main ? (
    <>
      <Grid
        container
        sx={{
          cursor: "pointer",
          height: 80,
          borderBottom: "0.5px solid #E6E6E6",
          p: "20px 24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          container
          xs={5}
          height={"100%"}
          onClick={() => {
            onClick(searchText);
          }}
        >
          <Grid item xs={2} xl={2}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Avatar
                variant="rounded"
                src={info.image}
                sx={{ borderRadius: 2 }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            xl={10}
            sx={{ display: "flex", flexDirection: "column", pl: 0.9 }}
          >
            <Box>
              <Tooltip title={`${info.main}`}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: "#3B3B3B",
                    fontSize: 15,
                  }}
                >
                  {info.main}
                </Typography>
              </Tooltip>
            </Box>
            <Box>
              <Typography
                variant={"body2"}
                sx={{
                  fontWeight: 400,
                  fontSize: 13,
                  color: "#9FA5BB",
                }}
              >
                {info.secondary}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          height={"100%"}
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2.8 }}
        >
          <ClickAwayListener
            onClickAway={() => {
              if (setSearchText.length === 0) {
                setSearchChecked(false);
              }
            }}
          >
            <Box
              height={"100%"}
              sx={{ display: "flex", alignItems: "center" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Fade in={checked}>
                {
                  <Box>
                    <SearchInput
                      value={searchText}
                      onChange={(text: string) => {
                        setSearchText(text);
                      }}
                    />
                  </Box>
                }
              </Fade>
              <Fade
                in={!checked}
                appear={false}
                addEndListener={() => {
                  if (checked) {
                    setSearchIconHide(true);
                  } else {
                    setTimeout(() => {
                      setSearchIconHide(false);
                    }, 100);
                  }
                }}
              >
                <i
                  className={`fi fi-bs-search`}
                  style={{
                    fontSize: 20,
                    lineHeight: "0.5em",
                    verticalAlign: "-0.15em",
                    display: hideSearchIcon ? "none" : "inline-flex",
                  }}
                  onClick={() => setSearchChecked(true)}
                />
              </Fade>
            </Box>
          </ClickAwayListener>
          {actionPanel}
        </Grid>
      </Grid>
      <Grid key={"right"}>
        <Drawer
          sx={{ "& .MuiDrawer-paper": { width: "30%" } }}
          anchor={"right"}
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          <List>
            {messages?.map((message) => (
              <ListItem key={message._id} disablePadding>
                <ListItemButton
                  onClick={() => {
                    onSearch(message);
                    setDrawer(false);
                  }}
                >
                  <ListItemText
                    primary={
                      message.created_by.firstname +
                      " " +
                      message.created_by.lastname
                    }
                    secondary={message.message}
                    primaryTypographyProps={{
                      color: "#0D99FF",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default Header;
