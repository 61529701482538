import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { accountingService } from "app/services";
import { useTable } from "app/hooks/useTable";
import { GridTable } from "app/partials/layout/GridTable";
import { useIntl } from "react-intl";
import { hasPermission, PermissionsGate } from "app/permission/PermissionsGate";
import EditIcon from "app/partials/icons/EditIcon";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import DeleteIcon from "app/partials/icons/DeleteIcon";
import { usePermissions } from "app/hooks/useRole";
import moment from "moment";
import ExpenseStore from "./store";
import { ITemplate } from "app/services/accounting.service";
import ExpenseShow from "./show";
import ShowDraft from "./draft";

const Templates = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  const { data, xhrLoading, updateQuery, refresh, remove } =
    useTable<ITemplate>({
      fetch: accountingService.getAllTemplates,
      delete: accountingService.deleteTemplate,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const [template, setTemplate] = useState<string | null>(null);
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const [showTemplate, setShowTemplate] = useState<string | null>(null);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.TITLE",
        defaultMessage: "Title",
      }),
      field: "title",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.title}>
            <Typography variant="inherit">{params.row?.title}</Typography>
          </Tooltip>
        );
      },
      minWidth: 300,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.CREATED_AT",
        defaultMessage: "Created at",
      }),
      field: "created_at",
      renderCell: (params) => {
        return params.row?._created_at
          ? moment
              .unix(params.row?._created_at)
              .local()
              .format("DD/MM/YYYY HH:mm")
          : "---";
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"accounting"} scope={"read"}>
              <IconButton
                onClick={() => {
                  setShowTemplate(params.row?._id);
                }}
              >
                <i
                  style={{ display: "flex", color: "#FBB73C" }}
                  className="fi fi-sr-eye"
                />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"accounting"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"accounting"} scope={"delete"}>
              <IconButton onClick={() => handleClick(params.row?._id)}>
                <DeleteIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (id: string) => {
    setTemplate(id);
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete template?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this template?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      <PermissionsGate section={"accounting"} scope={"read"}>
        <>
          {template ? (
            <ExpenseShow
              _id={template}
              open={Boolean(template)}
              onDone={() => {
                refresh();
              }}
              setDialogue={() => setTemplate(null)}
            />
          ) : (
            <></>
          )}
        </>
      </PermissionsGate>
      <PermissionsGate section={"accounting"} scope={"read"}>
        <>
          {showTemplate ? (
            <ShowDraft
              _id={showTemplate}
              open={Boolean(showTemplate)}
              setDialogue={() => setShowTemplate(null)}
            />
          ) : (
            <></>
          )}
        </>
      </PermissionsGate>
      <PermissionsGate section={"accounting"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "accounting", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <ExpenseStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={() => {
                          refresh();
                        }}
                      />
                    );
                  }
                : undefined,
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default Templates;
