import React, { Dispatch, SetStateAction, useRef } from "react";
import { useIntl } from "react-intl";
import { IUrlQuery } from "../../interfaces";
import { Box, Grid } from "@mui/material";
import Controls from "../../widgets/uncontrolled";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const UserFilter = (props: {
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
  queries: Array<IUrlQuery> | undefined;
  setQuery: Dispatch<SetStateAction<IUrlQuery[] | undefined>>;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { roles } = useSelector((state: RootState) => state.permission);
  const { cities } = useSelector((state: RootState) => state.settingReducer);

  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"role"}
            formId={"role"}
            options={roles?.map((r) => {
              return {
                id: r._id.toString(),
                title: r.title[intl.locale],
              };
            })}
            label={intl.formatMessage({ id: "STANDARD.ROLE" })}
            onChange={(event) => {
              handleQuery("role", event.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"city"}
            formId={"city"}
            options={cities?.map((c) => {
              return {
                id: c._id.toString(),
                title: c.title[intl.locale],
              };
            })}
            label={intl.formatMessage({ id: "USERS.CITY" })}
            onChange={(event) => {
              handleQuery("city", event.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserFilter;
