import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  FilesProps,
  LinksProps,
  MediaProps,
  MembersProps,
  VoicesProps,
} from "./tabs";

const Actions: FC<{
  members?: MembersProps;
}> = (props) => {
  const intl = useIntl();
  const { members } = props;

  const actionsSX = {
    display: "flex",
    justifyContent: "flex-start",
    gap: 2,
    cursor: "pointer",
    borderRadius: 1,
    mx: -1,
    px: 1,
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  };

  const iconContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box
      sx={{
        height: 100,
        marginBottom: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box>
        <Typography lineHeight={"24px"} fontSize={16} color={"#6D6D6D"}>
          {intl.formatMessage({
            id: "CHAT.ACTIONS",
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        {members?.items.length === 1 && (
          <Box
            sx={actionsSX}
            onClick={() => {
              window.open(`/users/${members?.items[0]._id}`);
            }}
          >
            <Box sx={iconContainer}>
              <i
                className={"fi fi-sr-user"}
                style={{
                  fontSize: 15,
                  color: "#767C91",
                  display: "inline-flex",
                  marginBottom: "1px",
                }}
              />
            </Box>
            <Box>
              <Typography color={"#3B3B3B"} lineHeight={"24px"}>
                {intl.formatMessage({
                  id: "CHAT.GO_TO_PROFILE",
                })}
              </Typography>
            </Box>
          </Box>
        )}
        <Box sx={actionsSX} onClick={() => {}}>
          <Box sx={iconContainer}>
            <i
              className={"custom_icon-muted"}
              style={{
                width: 15,
                height: 15,
                color: "#767C91",
                display: "inline-flex",
                marginBottom: "1px",
              }}
            />
          </Box>
          <Box>
            <Typography color={"#3B3B3B"} lineHeight={"24px"}>
              {intl.formatMessage({
                id: "CHAT.MUTED",
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Actions;
