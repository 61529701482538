import React, { createContext } from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

export interface IGuideline {
  _id: string;
  _created_at: string;
  _updated_at: string;
}

const INDEX = API_ROUTES.guidelines;
const SHOW = API_ROUTES.guideline;
const UPDATE = API_ROUTES.guideline;
const DELETE = API_ROUTES.guideline;
const STORE = API_ROUTES.guidelines;

export interface IGuidelineService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IGuideline> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IGuideline> | IRestApiError>;
  update(
    _id: string,
    data: any
  ): Promise<IRestApiResource<IGuideline> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<IGuideline> | IRestApiError>;
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>;
}

const guidelineService: IGuidelineService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}?${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any) {
    let jsonData = JSON.stringify(data);

    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async add(data: any) {
    let formData = generateFormData(new FormData(), data);

    return http(getRoute(STORE), "post", formData);
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DELETE).replace(":_id", _id.toString())}${queriesString}`,
      "delete"
    );
  },
};

export { guidelineService };
