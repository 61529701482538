import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { ISetting } from "./setting.service";
import { ICase } from "./case.service";

export interface IRobot {
  _id: string;
  title: string;
  type: ISetting;
  case: ICase;
  category: ISetting;
  description: string;
  lead: IUser;
  users: Array<IUser>;
  start: number;
  start_time: string;
  end: number;
  end_time: string;
  status: ISetting;
  repeat: {
    every: number;
    type: string;
    days?: string[];
    end: { type: string; value: string };
  };
  generation: {
    type: string;
    end: { type: string; value: number };
  };
  _created_at: number;
  _updated_at: string;
}

export interface IRobotStore {
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  repeat: {
    every: number;
    type: string;
    days?: string[];
    end: { type: string; value: string };
  };
  generation: {
    type: string;
    end: { type: string; value: number };
  };
}

const INDEX = API_ROUTES.robots;
const SHOW = API_ROUTES.robot;
const UPDATE = API_ROUTES.robot;
const STORE = API_ROUTES.robots;
const DELETE = API_ROUTES.robot;

export interface IRobotService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IRobot> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IRobot> | IRestApiError>;
  update(
    _id: string | undefined,
    data: IRobotStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<IRobot> | IRestApiError>;
  add(data: IRobotStore): Promise<IRestApiResource<IRobot> | IRestApiError>;
}

const robotService: IRobotService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any, query?: Array<IUrlQuery>) {
    let jsonData = JSON.stringify(data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async add(data: IRobotStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },
};

export { robotService };
