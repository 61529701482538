import React, { createContext } from "react";
import {
  IUrlQuery,
  IUser,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

enum EActive {
  inactive = "0",
  active = "1",
}

export interface ICommune {
  _id: string;
  name: string;
  email: string;
  phone: string;
  ean: string;
  cvr: string;
  address: string;
  active: number;
  is_blocked: number;
}

export interface ICommuneQuickCardsCount {
  data: {
    former: number;
  };
}

const INDEX = API_ROUTES.communes;
const USERS_INDEX = API_ROUTES.commune_users;
const SHOW = API_ROUTES.commune;
const UPDATE = API_ROUTES.commune;
const DELETE = API_ROUTES.commune;

const QUICK_CARDS_COUNT = API_ROUTES.communes_count;

export interface ICommuneService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ICommune> | IRestApiError>;
  getQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<ICommuneQuickCardsCount | IRestApiError>;
  getCommuneUsers(
    _id: string
  ): Promise<IRestApiCollection<IUser> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<ICommune> | IRestApiError>;
  update(
    _id: string,
    data: ICommune
  ): Promise<IRestApiResource<ICommune> | IRestApiError>;
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<ICommune> | IRestApiError>;
}

const communeService: ICommuneService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },
  async getQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(QUICK_CARDS_COUNT)}${queryString}`, "get");
  },
  async getCommuneUsers(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(USERS_INDEX).replace(
        ":_id",
        _id.toString()
      )}${queriesString}`,
      "get"
    );
  },
  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },
  async update(_id: string, data: ICommune) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },
  async add(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(INDEX), "post", formData);
  },
  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },
};

export { communeService };
