import TimeTrackStoreDialogue, {
  FormStateValues,
} from "../../partials/dialogues/store/time_track";
import Snackbar from "../../widgets/Snackbar";
import { caseService, timeTrackService } from "../../services";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ICase } from "../../services/case.service";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

const TimeTrackStore: FC<IFormProps> = (props) => {
  const [cases, setCases] = useState<Array<ICase>>();

  useEffect(() => {
    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props.open]);

  const handleSave = (validated: FormStateValues, callback: () => void) => {
    if (!validated) {
      Snackbar.error("Store error");
      return;
    }

    timeTrackService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw Error("Something went wrong");
        }

        Snackbar.info("Added");
        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          callback();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <TimeTrackStoreDialogue
      open={props.open}
      setDialogue={props.setDialogue}
      case_needed={true}
      cases={cases}
      save={handleSave}
    />
  );
};

export default TimeTrackStore;
