import React, { FC, useEffect, useRef, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { IColumns } from "../../../partials/layout/DataTable";
import { IUser } from "../../../interfaces";
import { useParams } from "react-router";
import { useTable } from "../../../hooks/subresource/useTable";
import { GridTable } from "../../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import { userService } from "../../../services";
import {
  hasPermission,
  PermissionsGate,
} from "../../../permission/PermissionsGate";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import EditIcon from "../../../partials/icons/EditIcon";
import DeleteIcon from "../../../partials/icons/DeleteIcon";
import NewCase from "../../Cases/store";
import { usePermissions } from "../../../hooks/useRole";
import UserCaseFilter from "../filters/caseFilter";
import { useHistory } from "react-router-dom";
import UserAvatar from "../../../partials/layout/UserAvatar";
import EditSibling from "./editSibling";
import SiblingStore from "../store/siblingStore";

const Siblings: FC<IUser> = (props) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const { data, xhrLoading, remove, updateQuery, refresh } = useTable<IUser>({
    fetch: userService.getSiblings,
    delete: userService.deleteSibling,
    param: id,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const permissions = usePermissions();
  const history = useHistory();

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "AUTH.INPUT.USERNAME" }),
      field: "username",
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <UserAvatar user={params.row} />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant={"inherit"}>
                {params.row?.firstname + " " + params.row?.lastname}
              </Typography>
            </Grid>
          </Grid>
        );
      },
      minWidth: 250,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" }),
      field: "email",
      valueGetter: (params) => {
        return params.row?.email;
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "USERS.PHONENUMBER" }),
      field: "phone",
      valueGetter: (params) => {
        return params.row?.phone;
      },
      minWidth: 190,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"users"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"user_show"}
                data-cy-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>

            <PermissionsGate section={"users"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"user_delete"}
                data-cy-id={params.row?._id}
              >
                <DeleteIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (_id: string) => {
    userService.getOne(_id).then((data) => {
      if ("error" in data) {
        throw Error(data.error.message);
      }
      const { data: user } = data;

      setUser(user);
    });
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"cases"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete case?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(id, confirmId);
            }}
          >
            Are you sure you want to delete this case?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>

      <PermissionsGate section={"cases"} scope={"read"}>
        <>
          {user && (
            <EditSibling
              open={Boolean(user)}
              setDialogue={() => setUser(null)}
              user={user}
              _id={user._id}
              userRefresh={refresh}
            />
          )}
          <GridTable
            loading={xhrLoading}
            skeleton={!init}
            pageSize={data?.per_page}
            count={data?.total}
            columns={columns}
            rows={data?.data ?? []}
            tableRef={tableRef}
            query={(queries) => updateQuery(queries)}
            search={(queries) => updateQuery(queries)}
            filterToolbar={{
              actionPanel:
                permissions && hasPermission(permissions, "cases", "create")
                  ? (props: { open: boolean; close: () => void }) => {
                      return (
                        <SiblingStore
                          open={props.open}
                          setDialogue={props.close}
                          onDone={refresh}
                        />
                      );
                    }
                  : undefined,
            }}
          />
        </>
      </PermissionsGate>
    </>
  );
};

export default Siblings;
