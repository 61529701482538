import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import {
  accountingService,
  businessService,
  caseService,
  userService,
} from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "../../hooks/useForm";
import moment, { Moment } from "moment";
import { DatePicker, Space } from "antd";
import { makeStyles } from "@material-ui/core";
import SelectUsers, { SelectedUsers } from "../../partials/SelectUsers";
import { ISetting } from "../../services/setting.service";
import InvoiceItems from "./items";
import Totals from "./totals";
import { IAccounting, ITemplate } from "../../services/accounting.service";
import { IUser } from "../../interfaces";
import { IBusiness } from "../../services/business.service";
import { ICase } from "../../services/case.service";
import { useLocation } from "react-router-dom";
import { ReactToPrint } from "react-to-print";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

interface IFormProps {
  _id: string;
  open: boolean;
  onDone: Function;
  setDialogue: Dispatch<SetStateAction<boolean>>;
}

type FormStateValues = {
  status: string;
  case: string;
  template: string;
  send_to: string;
  to_business: string | null;
  user: string;
  total: number;
  currency: string;
  paid_with: string;
  paid_at: string | null;
  overdue_date: string;
  description: string;
  articles: any[];
};

const Invoice: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const [invoice, setInvoice] = useState<IAccounting>();

  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);
  const hash = query.get("hash") ?? "";

  useEffect(() => {
    if (hash.length > 0) {
      accountingService
        .getPDF([
          {
            name: "hash",
            value: hash,
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: invoice } = data;
          setInvoice(invoice);
        });
    }
  }, [hash]);

  const printRef = useRef<any>({});

  return invoice ? (
    <>
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ReactToPrint
          bodyClass="print_content"
          content={() => printRef.current}
          trigger={() => (
            <button
              type={"button"}
              className="btn"
              style={{ backgroundColor: "#0D99FF", color: "#fff" }}
            >
              Print or get pdf
            </button>
          )}
        />
      </Box>
      <Box
        ref={printRef}
        sx={{
          "& table, td, tr, th": { border: "unset!important" },
          "& .table-bordered": { border: "unset!important" },
          "& .table-bordered td": { border: "unset!important" },
          backgroundColor: "#fff",
          // padding: "35px 0",
          display: "flex",
          justifyContent: "center",
          width: "210mm",
          height: "297mm",
          padding: "20mm",
          margin: "0 auto",
        }}
        dangerouslySetInnerHTML={{
          __html: invoice.content.replace(
            "{INVOICE_DATE}",
            invoice._created_at
          ),
        }}
      />{" "}
    </>
  ) : (
    <></>
  );
};

export default Invoice;
