import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "../../../fullcalendar.css";
import "@fullcalendar/daygrid/main.css";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Controls from "../../widgets/uncontrolled";
import { ITodo, todoService } from "../../services/todo.service";
import DefaultPage from "app/partials/content/DefaultPage";
import Snackbar from "../../widgets/Snackbar";
import moment from "moment";
import { IUrlQuery, IUser } from "../../interfaces";
import TodoShowDialogue from "../../partials/dialogues/show/todo";
import { makeStyles } from "@material-ui/core";
import backdrop from "../../widgets/Backdrop";
import { caseService, userService } from "../../services";
import { useIntl } from "react-intl";
import { ICase } from "../../services/case.service";
import { FormStateValues } from "../Todos/Modal/show";
import TodoStore from "../Todos/store";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const Calendar = () => {
  let classes = useStyles();
  const intl = useIntl();
  const timeOut = useRef<any>();
  const [todos, setTodos] = useState<Array<ITodo>>([]);
  const [users, setUsers] = useState<Array<IUser>>();
  const [todoId, setTodoId] = useState<string | null>(null);
  const calendarRef = useRef<FullCalendar>(null);
  const [start, setStart] = useState<string>("");
  const [end, setEnd] = useState<string>("");
  const [searchFor, setSearchFor] = useState<string>("only_mine");
  const [caseSearch, setCaseSearch] = useState<string>("");
  const [userSearch, setUserSearch] = useState<string>("");
  const [_todo, _setTodo] = useState<ITodo | null>(null);
  const [cases, setCases] = useState<Array<ICase>>();
  const [storeModal, setStoreModal] = useState<boolean>(false);
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  useEffect(() => {
    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  useEffect(() => {
    setStart(
      moment(calendarRef.current?.getApi().view.activeStart).format(
        "YYYY-MM-DD"
      )
    );
    setEnd(
      moment(calendarRef.current?.getApi().view.activeEnd).format("YYYY-MM-DD")
    );
  }, []);

  useEffect(() => {
    getTodos();
  }, [start, end, searchFor, caseSearch, userSearch]);

  const handleTodo = (_id: string) => {
    setTodoId(_id);
  };

  const handleSave = (validated: FormStateValues, callback: () => void) => {
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    todoId &&
      todoService
        .update(todoId, validated)
        .then((data) => {
          if ("error" in data) {
            throw Error(
              intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
            );
          }

          Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

          setTimeout(() => {
            setTodoId(null);
            callback();
          }, 1000);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
  };

  useEffect(() => {
    getUsers([
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "bounded_users",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
    ]);
  }, []);

  const getTodos = (query?: Array<IUrlQuery>) => {
    backdrop.on();
    todoService
      .getAll([
        {
          name: "start",
          value: start ?? "",
        },
        {
          name: "end",
          value: end ?? "",
        },
        {
          name: "search_for",
          value: searchFor,
        },
        {
          name: "case",
          value: caseSearch,
        },
        {
          name: "user",
          value: userSearch,
        },
        ...(query ?? []),
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: todos } = data;
        setTodos(todos);
        backdrop.off();
      })
      .catch((e) => {
        Snackbar.error(e.message);
        backdrop.off();
      });
  };

  const getUsers = (queries: { name: string; value: string | number }[]) => {
    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setUsers(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      getUsers([
        {
          name: "pagination",
          value: "1",
        },
        {
          name: "bounded_users",
          value: "1",
        },
        {
          name: "keyword",
          value: value,
        },
      ]);
    }, 500);
  };

  useEffect(() => {
    if (todoId) {
      _setTodo(null);
      todoService
        .getOne(todoId)
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: todo } = data;
          _setTodo(todo);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [todoId]);

  return (
    <>
      {storeModal && (
        <TodoStore
          open={storeModal}
          setDialogue={() => setStoreModal(false)}
          onDone={getTodos}
        />
      )}
      {todoId ? (
        <TodoShowDialogue
          _id={todoId}
          _todo={_todo}
          open={Boolean(todoId)}
          setDialogue={() => setTodoId(null)}
          case_needed={true}
          show_note={true}
          save={handleSave}
        />
      ) : (
        <></>
      )}
      <DefaultPage
        dropDown={
          <Box sx={{ display: "flex" }}>
            {me?.role.slug !== "citizen" && (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                sx={{
                  "&.MuiIconButton-root:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => setStoreModal(true)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.75 10.75H13.25V3.25C13.25 2.55965 12.6904 2 12 2C11.3096 2 10.75 2.55965 10.75 3.25V10.75H3.25C2.55965 10.75 2 11.3096 2 12C2 12.6904 2.55965 13.25 3.25 13.25H10.75V20.75C10.75 21.4404 11.3096 22 12 22C12.6904 22 13.25 21.4404 13.25 20.75V13.25H20.75C21.4404 13.25 22 12.6904 22 12C22 11.3096 21.4404 10.75 20.75 10.75Z"
                    fill="#767C91"
                  />
                </svg>
              </IconButton>
            )}
          </Box>
        }
        beforeCodeTitle={intl.formatMessage({
          id: "MENU.CALENDAR",
          defaultMessage: "Calendar",
        })}
      >
        <form id={""} onSubmit={() => {}}>
          <Grid container spacing={2} mb={2}>
            {(me?.role?.slug === "super-admin" ||
              me?.role?.slug === "moderator") && (
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                <Controls.Select
                  name={"search_for"}
                  defaultValue={"only_mine"}
                  options={[
                    {
                      id: "all",
                      title: intl.formatMessage({
                        id: "STANDARD.ALL",
                        defaultMessage: "All",
                      }),
                    },
                    {
                      id: "only_mine",
                      title: intl.formatMessage({
                        id: "STANDARD.ONLYMINE",
                        defaultMessage: "Only my calendar",
                      }),
                    },
                    {
                      id: "all_employees",
                      title: intl.formatMessage({
                        id: "STANDARD.ALLEMPLOYEES",
                        defaultMessage: "All employees",
                      }),
                    },
                    {
                      id: "all_moderators",
                      title: intl.formatMessage({
                        id: "STANDARD.ALLMODERATORS",
                        defaultMessage: "All moderators",
                      }),
                    },
                  ]}
                  label={intl.formatMessage({ id: "LABEL.SEARCHFOR" })}
                  onChange={(event) => {
                    setSearchFor(event.target.value);
                  }}
                />
              </Grid>
            )}

            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              <Box>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  onChange={(e, value: any, reason: string) => {
                    if (reason === "clear") {
                      setCaseSearch("");
                      return;
                    } else {
                      setCaseSearch(value?.id);
                    }
                  }}
                  options={
                    cases
                      ? cases.map((_case) => {
                          return {
                            id: _case._id,
                            label:
                              _case.key +
                                " " +
                                _case.users.find(
                                  (user) => user.role?.slug === "citizen"
                                )?.fullname ?? null,
                          };
                        })
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: "STANDARD.CASE",
                        defaultMessage: "Case",
                      })}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              <Box>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  onChange={(e, value: any, reason: string) => {
                    if (reason === "clear") {
                      setUserSearch("");
                      return;
                    } else {
                      setUserSearch(value?.id);
                    }
                  }}
                  onInputChange={(e, value) => {
                    sendQuery(value);
                  }}
                  options={
                    users
                      ? users.map((user) => {
                          return {
                            id: user._id.toString(),
                            label: user.firstname + " " + user.lastname,
                          };
                        })
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: "STANDARD.USERS",
                        defaultMessage: "Users",
                      })}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
        <FullCalendar
          initialView="dayGridMonth"
          ref={calendarRef}
          firstDay={1}
          weekNumbers={true}
          weekText={""}
          dayMaxEventRows={2}
          customButtons={{
            prev: {
              click: () => {
                // setDiff(diff - 1)
                calendarRef.current?.getApi().prev();
                setStart(
                  moment(calendarRef.current?.getApi().view.activeStart).format(
                    "YYYY-MM-DD"
                  )
                );
                setEnd(
                  moment(calendarRef.current?.getApi().view.activeEnd).format(
                    "YYYY-MM-DD"
                  )
                );
              },
            },
            next: {
              click: () => {
                // setDiff(diff + 1)
                calendarRef.current?.getApi().next();
                setStart(
                  moment(calendarRef.current?.getApi().view.activeStart).format(
                    "YYYY-MM-DD"
                  )
                );
                setEnd(
                  moment(calendarRef.current?.getApi().view.activeEnd).format(
                    "YYYY-MM-DD"
                  )
                );
              },
            },
          }}
          headerToolbar={{
            start: "today prev,next",
            center: "title",
            end: "dayGridMonth,dayGridWeek,dayGridDay",
          }}
          buttonText={{
            today: intl.formatMessage({ id: "CALENDAR.TODAY" }),
            month: intl.formatMessage({ id: "CALENDAR.MONTH" }),
            week: intl.formatMessage({ id: "CALENDAR.WEEK" }),
            day: intl.formatMessage({ id: "CALENDAR.DAY" }),
          }}
          dayHeaderFormat={{ weekday: "long" }}
          locale={intl.locale}
          plugins={[dayGridPlugin]}
          events={
            todos &&
            todos.map((todo) => {
              let className = "";
              if (todo?.type?.slug == "todo") {
                if (
                  todo?.status?.slug === "not_started" ||
                  todo?.status?.slug === "not-started"
                ) {
                  className += " notstarted_todo";
                } else if (
                  todo?.status?.slug === "pending" ||
                  todo?.status?.slug === "in-progress" ||
                  todo?.status?.slug === "in_progress"
                ) {
                  className += " pending_todo";
                } else {
                  className += " finished_event";
                }
              } else if (todo?.type?.slug === "event") {
                if (todo?.deadline_date < moment().unix()) {
                  className += " finished_event";
                } else {
                  className += " notstarted_todo";
                }
              }
              let title = todo?.title && todo?.title.slice(0, 17) + "...";

              if (todo?.type?.slug == "event") {
                title =
                  moment.unix(todo?.date).local().format("HH:mm") + " " + title;
              }
              return {
                id: todo?._id.toString(),
                title: title,
                description: todo?.description,
                start:
                  todo?.type?.slug === "todo"
                    ? moment
                        .unix(todo?.deadline_date)
                        .local()
                        .format("YYYY-MM-DD")
                    : moment.unix(todo?.date).local().format("YYYY-MM-DD"),
                end: moment
                  .unix(todo?.deadline_date)
                  .local()
                  .format("YYYY-MM-DD"),
                color: todo?.type?.slug === "todo" ? "#ffb822" : "#36a3f7",
                className,
              };
            })
          }
          eventClick={({ event }) => {
            handleTodo(event.id);
          }}
          eventContent={(content) => {
            return (
              <Tooltip
                placement={"top"}
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                arrow
                title={
                  <Box sx={{ width: "100%", maxWidth: 360 }}>
                    <Box sx={{ mt: 1, mx: 2 }}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography variant="h6" color={"#000"}>
                            {content.event.title}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider variant="middle" />
                    <Box sx={{ m: 2 }}>
                      <Typography color="text.secondary" variant="body1">
                        {content.event.extendedProps.description}
                      </Typography>
                    </Box>
                  </Box>
                }
              >
                <Typography variant={"body1"}>{content.event.title}</Typography>
              </Tooltip>
            );
          }}
        />
      </DefaultPage>
    </>
  );
};

export default Calendar;
