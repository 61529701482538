import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { IUrlQuery } from "app/interfaces";
import { Box, Chip, Divider, Grid } from "@mui/material";
import Controls from "app/widgets/uncontrolled";
import { accountingService } from "../../../services";
import Snackbar from "../../../widgets/Snackbar";
import { ISupplier } from "../../../services/accounting.service";

const ExpenseFilter = (props: {
  show: boolean;
  updateQuery: (queries: IUrlQuery[]) => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { expense_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [suppliers, setSuppliers] = useState<ISupplier[]>();

  useEffect(() => {
    if (props.show) {
      accountingService
        .getAllSuppliers()
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }
          const { data: suppliers } = data;
          setSuppliers(suppliers);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [props.show]);

  const handleQuery = (name: string, value: string) => {
    props.updateQuery([
      {
        name,
        value,
      },
    ]);
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"focus_area"}
            options={expense_categories
              ?.sort(function (a, b) {
                if (
                  a.title[intl.locale]?.toLowerCase() <
                  b.title[intl.locale]?.toLowerCase()
                )
                  return -1;
                if (
                  a.title[intl.locale]?.toLowerCase() >
                  b.title[intl.locale]?.toLowerCase()
                )
                  return 1;
                return 0;
              })
              .map((expense_category) => {
                return {
                  id: expense_category._id.toString(),
                  title: expense_category.title[intl.locale],
                };
              })}
            label={intl.formatMessage({
              id: "STANDARD.EXPENSE_CATEGORY",
              defaultMessage: "Category",
            })}
            onChange={(event) => {
              handleQuery("expense_category", event.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            options={
              suppliers &&
              suppliers.map((supplier) => {
                return {
                  id: supplier._id,
                  title: supplier.title ?? "",
                };
              })
            }
            formId={"supplier"}
            defaultValue={""}
            name={"supplier"}
            label={intl.formatMessage({
              id: "STANDARD.SUPPLIER",
              defaultMessage: "Supplier",
            })}
            onChange={(event) => {
              if (!event.target.value) return;
              handleQuery("supplier", event.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExpenseFilter;
