import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { accountingService } from "app/services";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { DatePicker, Space } from "antd";
import moment, { Moment } from "moment";
import Totals from "./totals";
import { ISetting } from "app/services/setting.service";
import Attachments from "../../Cases/store/parts/attachments";
import ExpenseItems from "./items";
import { ISupplier } from "../../../services/accounting.service";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
  iconButtonAdd: {
    "&:hover": {
      backgroundColor: "#556EE6!important",
    },
  },
}));

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  total: number;
  currency: string;
  supplier: string;
  invoice_date: string;
  payment_date: string;
  title: string;
  expense_category: string;
  description: string;
  items: any[];
  files: string[];
};

const initValues = {
  total: 0,
  currency: "usd",
  supplier: "",
  expense_category: "",
  invoice_date: "",
  payment_date: "",
  description: "",
  title: "",
  items: [],
  files: [],
} as FormStateValues;

const ExpenseStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [currencyLabel, setCurrencyLabel] = useState<string>("$");
  const [invoiceDate, setInvoiceDate] = useState<Moment | undefined>(undefined);
  const [payment_date, setPaymentDate] = useState<Moment | undefined>(
    undefined
  );
  const [items, setItems] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [suppliers, setSuppliers] = useState<ISupplier[]>();

  const { currencies, expense_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );

  useEffect(() => {
    if (props.open) {
      accountingService
        .getAllSuppliers()
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }
          const { data: suppliers } = data;
          setSuppliers(suppliers);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [props.open]);

  useEffect(() => {
    form.values.files = files?.file_ids?.map((file: any) => file._id);
  }, [files]);

  useEffect(() => {
    if (invoiceDate)
      form.values.invoice_date = (invoiceDate.valueOf() / 1000).toString();
  }, [invoiceDate]);

  useEffect(() => {
    if (payment_date)
      form.values.payment_date = (payment_date.valueOf() / 1000).toString();
  }, [payment_date]);

  const handleClose = () => {
    props.setDialogue(false);
    setInvoiceDate(undefined);
    setPaymentDate(undefined);
  };

  const getTotal = (total: number) => {
    form.values.total = total;
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    accountingService
      .addExpense(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const onChangeData = (items: any) => {
    setItems(items);
  };

  useEffect(() => {
    form.values.items = items;
  }, [items]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage id={"ADD"} defaultMessage={"Add"} />
              </Typography>
              <Button
                form={"expense_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"expense_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.TITLE",
                            defaultMessage: "Title",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        multiline={true}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "STANDARD.SUPPLIER",
                          defaultMessage: "Supplier",
                        })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Select
                      options={
                        suppliers &&
                        suppliers.map((supplier) => {
                          return {
                            id: supplier._id,
                            title: supplier.title ?? "",
                          };
                        })
                      }
                      formId={"status_pick"}
                      defaultValue={""}
                      name={"expense_category"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;
                        form.values.supplier = event.target.value;
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "STANDARD.EXPENSE_CATEGORY",
                          defaultMessage: "Category",
                        })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Select
                      options={
                        expense_categories &&
                        expense_categories.map((expense_category) => {
                          return {
                            id: expense_category._id,
                            title: expense_category.title[intl.locale] ?? "",
                          };
                        })
                      }
                      formId={"status_pick"}
                      defaultValue={""}
                      name={"expense_category"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;
                        form.values.expense_category = event.target.value;
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "STANDARD.CURRENCY",
                          defaultMessage: "Currency",
                        })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Select
                      options={
                        currencies &&
                        currencies.map((currency) => {
                          return {
                            id: currency.slug,
                            title: currency.currency_title ?? "",
                          };
                        })
                      }
                      formId={"status_pick"}
                      defaultValue={"usd"}
                      name={"status"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        const selected = currencies?.find(
                          (r: ISetting) => r.slug === event.target.value
                        )?.symbol;
                        if (selected) {
                          setCurrencyLabel(selected);
                        }

                        form.values.currency = event.target.value;
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.INVOICE_DATE",
                            defaultMessage: "Invoice date",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          value={invoiceDate}
                          dropdownClassName={classes.antDropdown}
                          placeholder={intl.formatMessage({
                            id: "STANDARD.SELECTDATE",
                            defaultMessage: "Select date",
                          })}
                          onChange={(value, dateString) => {
                            form.values.invoice_date = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                            setInvoiceDate(moment(dateString));
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            onClick={() => {
                              form.values.invoice_date = (
                                moment().local().valueOf() / 1000
                              ).toString();

                              setInvoiceDate(moment().local());
                            }}
                          >
                            {intl.formatMessage({
                              id: "STANDARD.TODAY",
                              defaultMessage: "Today",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setInvoiceDate(moment().local().add(8, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.EIGHTDAY",
                              defaultMessage: "8 day",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setInvoiceDate(moment().local().add(10, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.TENDAY",
                              defaultMessage: "10 day",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setInvoiceDate(moment().local().add(30, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.THIRTYDAY",
                              defaultMessage: "30 day",
                            })}
                          </Button>
                        </Box>
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.PAYMENT_DATE",
                            defaultMessage: "Payment date",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          value={payment_date}
                          placeholder={intl.formatMessage({
                            id: "STANDARD.SELECTDATE",
                            defaultMessage: "Select date",
                          })}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.payment_date = (
                              moment(dateString, "DD-MM-YYYY HH:mm").valueOf() /
                              1000
                            ).toString();
                            setPaymentDate(moment(dateString));
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            onClick={() => {
                              form.values.payment_date = (
                                moment().local().valueOf() / 1000
                              ).toString();

                              setPaymentDate(moment().local());
                            }}
                          >
                            {intl.formatMessage({
                              id: "STANDARD.TODAY",
                              defaultMessage: "Today",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setPaymentDate(moment().local().add(8, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.EIGHTDAY",
                              defaultMessage: "8 day",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setPaymentDate(moment().local().add(10, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.TENDAY",
                              defaultMessage: "10 day",
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              setPaymentDate(moment().local().add(30, "days"));
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "STANDARD.THIRTYDAY",
                              defaultMessage: "30 day",
                            })}
                          </Button>
                        </Box>
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                            defaultMessage: "Description",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"description"}
                        label={""}
                        multiline={true}
                        rows={6}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.description = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({
                            id: "STANDARD.ITEMS",
                            defaultMessage: "Items",
                          })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <ExpenseItems
                        {...{
                          data: items,
                          type: "item",
                          onChangeData: onChangeData,
                          symbol: currencyLabel,
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Totals
                    items={items}
                    selectedCurrency={currencyLabel}
                    total={getTotal}
                  />
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        Files
                      </Typography>
                      <Attachments {...{ setFiles, files }} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"files"}
                        options={[]}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ExpenseStore;
