// @ts-nocheck
import React, { useEffect, useState } from "react";
import { accountingService } from "../../services";
import Snackbar from "../../widgets/Snackbar";
import { ITaxRate } from "../../services/accounting.service";
import { Box, IconButton } from "@mui/material";
import Controls from "../../widgets/uncontrolled";
import { useIntl } from "react-intl";
import Discount from "./discount";
import DeleteIcon from "../../partials/icons/DeleteIcon";

const Row = ({
  data,
  index,
  provided,
  symbol,
  snapshot,
  row,
  discountable,
  onDelete,
  onTitleChange,
  onColumnChangeEvent,
  onDiscount,
}) => {
  // let selectedTaxRate = row?.taxrate;
  const intl = useIntl();
  const [taxRates, setTaxRates] = useState<ITaxRate[]>([]);

  useEffect(() => {
    accountingService
      .getTaxRates()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: taxRates } = data;

        setTaxRates(taxRates);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [provided]);

  return (
    <div
      className="mb-2"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="d-flex">
        {data.length > 1 && (
          <div className="pl-0 d-flex align-items-center justify-content-center">
            <Box
              className={{
                dragging: snapshot.isDragging,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Outline"
                viewBox="0 0 24 24"
                width="13"
                height="13"
              >
                <path d="M4.5,17.5H2a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,4.5,17.5Zm0,4.5H2V19.5H4.5Z" />
                <path d="M22,17.5H19.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V19.5A2,2,0,0,0,22,17.5ZM22,22H19.5V19.5H22Z" />
                <path d="M4.5,8.75H2a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,4.5,8.75Zm0,4.5H2v-2.5H4.5Z" />
                <path d="M22,8.75H19.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,22,8.75Zm0,4.5H19.5v-2.5H22Z" />
                <path d="M4.5,0H2A2,2,0,0,0,0,2V4.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V2A2,2,0,0,0,4.5,0Zm0,4.5H2V2H4.5Z" />
                <path d="M13.25,17.5h-2.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,13.25,17.5Zm0,4.5h-2.5V19.5h2.5Z" />
                <path d="M13.25,8.75h-2.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,13.25,8.75Zm0,4.5h-2.5v-2.5h2.5Z" />
                <path d="M13.25,0h-2.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V2A2,2,0,0,0,13.25,0Zm0,4.5h-2.5V2h2.5Z" />
                <path d="M22,0H19.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,4.5H19.5V2H22Z" />
              </svg>
            </Box>
          </div>
        )}
        <div
          className={`${data.length > 1 ? "pl-3" : "pl-0"}`}
          style={{
            minWidth: data.length > 1 ? "21rem" : "22rem",
          }}
        >
          <input
            className="form-control"
            placeholder={"Title"}
            type="text"
            value={row.article_description || ""}
            onChange={(e) => onTitleChange(e.target.value, index)}
          />
        </div>
        <Box sx={{ pr: 2, pl: 2 }}>
          <select
            style={{ width: "105px" }}
            className="custom-select"
            value={row.tax_id}
            onChange={(event) => {
              let currentSetting = taxRates.find(
                (setting) => setting._id === event.target.value
              );

              // if (!currentSetting) {
              //   if (row.tax_id === event.target.value) {
              //     currentSetting = selectedTaxRate;
              //   } else {
              //     currentSetting = false;
              //   }
              // }

              if (currentSetting) {
                onColumnChangeEvent(currentSetting.rate, index, "vat");
                onColumnChangeEvent(currentSetting._id, index, "tax_id");
              }
            }}
          >
            <option value="">VatRate</option>
            {taxRates.map((item, key) => {
              return (
                <option value={item._id} key={key}>
                  {item.rate}% {item.title}
                </option>
              );
            })}
          </select>
        </Box>
        <div className="pr-2">
          <input
            style={{ width: "70px" }}
            className="form-control"
            placeholder={"Quantity"}
            type="number"
            step={0.1}
            min={0}
            value={row.quantity || ""}
            onChange={(e) =>
              onColumnChangeEvent(e.target.value, index, "quantity")
            }
          />
        </div>
        <div className="pr-2">
          <input
            className="form-control"
            placeholder={"ExclTax"}
            type="number"
            step="any"
            value={row.price_per_unit || ""}
            onFocus={(e) => e.target.select()}
            onChange={(e) =>
              onColumnChangeEvent(e.target.value, index, "price_per_unit")
            }
          />
        </div>
        <div className="pr-2">
          <input
            className="form-control"
            placeholder={"InclTax"}
            type="number"
            step="any"
            value={row.total_exc_discount || ""}
            onFocus={(e) => e.target.select()}
            onChange={(e) =>
              onColumnChangeEvent(e.target.value, index, "total_exc_discount")
            }
          />
        </div>
        <div className="pr-2">
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </div>
        {discountable && (
          <div className="d-flex align-items-center">
            <IconButton
              onClick={() => onDiscount(index, "object", row.discount)}
            >
              {!row.discount ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  fill={"#0D99FF"}
                >
                  <path d="M1.5,24c-.384,0-.768-.146-1.061-.439-.586-.586-.586-1.535,0-2.121L21.439,.439c.586-.586,1.535-.586,2.121,0,.586,.585,.586,1.536,0,2.121L2.561,23.561c-.293,.293-.677,.439-1.061,.439ZM9,4.5C9,2.019,6.981,0,4.5,0S0,2.019,0,4.5s2.019,4.5,4.5,4.5,4.5-2.019,4.5-4.5Zm-3,0c0,.827-.673,1.5-1.5,1.5s-1.5-.673-1.5-1.5,.673-1.5,1.5-1.5,1.5,.673,1.5,1.5Zm18,15c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5,2.019,4.5,4.5,4.5,4.5-2.019,4.5-4.5Zm-3,0c0,.827-.673,1.5-1.5,1.5s-1.5-.673-1.5-1.5,.673-1.5,1.5-1.5,1.5,.673,1.5,1.5Z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512.021 512.021"
                  width="14"
                  height="14"
                  fill={"#EC3033"}
                >
                  <g>
                    <path d="M301.258,256.01L502.645,54.645c12.501-12.501,12.501-32.769,0-45.269c-12.501-12.501-32.769-12.501-45.269,0l0,0   L256.01,210.762L54.645,9.376c-12.501-12.501-32.769-12.501-45.269,0s-12.501,32.769,0,45.269L210.762,256.01L9.376,457.376   c-12.501,12.501-12.501,32.769,0,45.269s32.769,12.501,45.269,0L256.01,301.258l201.365,201.387   c12.501,12.501,32.769,12.501,45.269,0c12.501-12.501,12.501-32.769,0-45.269L301.258,256.01z" />
                  </g>
                </svg>
              )}
            </IconButton>
          </div>
        )}
      </div>
      {row.discount && discountable && (
        <Discount
          row={row}
          index={index}
          symbol={symbol}
          onDiscount={onDiscount}
        />
      )}
    </div>
  );
};

export default Row;
