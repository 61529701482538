import React, {Dispatch, FC, SetStateAction} from 'react';
import Snackbar from "../../widgets/Snackbar";
import {todoService} from "../../services";
import TodoStoreDialogue, {FormStateValues} from "../../partials/dialogues/store/todo";
import {useIntl} from "react-intl";

interface IFormProps {
    open: boolean,
    setDialogue: Dispatch<SetStateAction<boolean>>
    onDone: Function
}

const TodoStore: FC<IFormProps> = (props) => {
    const intl = useIntl();
    const handleSave = (validated: FormStateValues, callback: () => void) => {

        if (!validated){
            Snackbar.error(intl.formatMessage({id: 'SNACKBAR.STOREERROR'}))
            return;
        }

        todoService.add(validated).then((data) => {
            if ("error" in data){
                throw new Error(data.error.message);
            }

            Snackbar.success(intl.formatMessage({id: 'SNACKBAR.ADDED'}))

            setTimeout(() => {
                props.setDialogue(false);
                props.onDone();
                callback();
            }, 1000)

        }).catch((e) => {
            Snackbar.error(e.message)
        });
    }

    return(
        <TodoStoreDialogue open={props.open}
                           setDialogue={props.setDialogue}
                           case_needed={true}
                           save={handleSave}/>
    )
}

export default TodoStore;
