import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Controls from "app/widgets/uncontrolled";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useForm } from "app/hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { TransitionProps } from "@mui/material/transitions";
import Snackbar from "app/widgets/Snackbar";
import { DatePicker } from "antd";
import moment from "moment";
import { timeTrackService } from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ITimeTrack } from "app/services/timeTrack.service";
import Autocomplete from "@mui/material/Autocomplete";
import { ICase } from "../../../services/case.service";

interface IFormProps {
  _id: string;
  session_type?: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  save: Function;
  case_needed: boolean;
  cases?: ICase[];
}
export type FormStateValues = {
  start_date_time: string;
  end_date_time: string;
  start_location: string;
  end_location: string;
  type: string;
  related: string;
  platform: string;
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6 !important" as any,
          borderRadius: 6,
          "& legend": {
            display: "none!important" as any,
          },
        },
      },
    },
  },
  calendar: {
    zIndex: "99999 !important" as any,
  },
  dateRangeInput: {
    width: "100%",
    padding: "10px",
    border: "1px solid #E6E6E6 !important" as any,
    borderRadius: 6,
    "& .ant-picker-focused": {
      boxShadow: "none !important" as any,
    },
    "& .ant-picker": {
      border: "none!important",
      width: "100%",
      color: "#000000!important",
      "& .ant-picker-input input::placeholder": {
        color: "#000000",
      },
    },
  },
}));

const TimeTrackShowDialogue: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { time_track_types } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { user_session_types } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [timeTrack, setTimeTrack] = useState<ITimeTrack | null>(null);

  const { RangePicker } = DatePicker;

  const handleClose = () => {
    props.setDialogue(false);
  };

  const save = () => {
    props.save(form.validate(), () => {
      form.clear();
    });
  };

  useEffect(() => {
    timeTrackService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: timeTrack } = data;
        setTimeTrack(timeTrack);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  useEffect(() => {
    if (timeTrack) {
      form.values.type = timeTrack.type?._id;
      form.values.related = timeTrack.case?._id;
      form.values.start_date_time = (
        moment.unix(+timeTrack.start_date_time).utc().valueOf() / 1000
      ).toString();
      form.values.end_date_time = (
        moment.unix(+timeTrack.end_date_time).utc().valueOf() / 1000
      ).toString();
      form.values.start_location = timeTrack.start_location;
      form.values.end_location = timeTrack.end_location;
      form.values.platform = timeTrack.platform;

      console.log("form.values", form.values);
    }
  }, [timeTrack]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.TIMETRACK.SHOW"}
                  defaultMessage={"CASE.TIMETRACK.SHOW"}
                />
              </Typography>
              <Button
                autoFocus
                color="inherit"
                data-cy-class={"time_record_update"}
                data-cy-id={timeTrack?._id}
                type={"submit"}
                form={"time_track_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  save();
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>

          <DialogContent sx={{ p: "20px 44px" }}>
            <form id={"time_track_form"} onSubmit={() => {}}>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && timeTrack ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"STANDARD.TYPE"}
                          defaultMessage={"STANDARD.TYPE"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"type"}
                        defaultValue={timeTrack?.type?._id}
                        formId={"type"}
                        options={
                          props.session_type && props.session_type == "user"
                            ? user_session_types?.map((t) => {
                                return {
                                  id: t._id.toString(),
                                  title: t.title[intl.locale],
                                };
                              })
                            : time_track_types?.map((t) => {
                                return {
                                  id: t._id.toString(),
                                  title: t.title[intl.locale],
                                };
                              })
                        }
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.type = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"type"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && timeTrack ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"STANDARD.DATE"}
                          defaultMessage={"STANDARD.DATE"}
                        />
                      </Typography>
                      <Box className={classes.dateRangeInput}>
                        <RangePicker
                          defaultValue={[
                            timeTrack?.start_date_time
                              ? moment(timeTrack?.start_date_time, "X").local()
                              : null,
                            timeTrack?.end_date_time
                              ? moment(timeTrack?.end_date_time, "X").local()
                              : null,
                          ]}
                          showTime={{ format: "HH:mm" }}
                          onCalendarChange={(value, dateString) => {
                            if (value && dateString[0] && dateString[1]) {
                              form.values.start_date_time = (
                                moment(
                                  dateString[0],
                                  "YYYY-MM-DD HH:mm"
                                ).valueOf() / 1000
                              ).toString();
                              form.values.end_date_time = (
                                moment(
                                  dateString[1],
                                  "YYYY-MM-DD HH:mm"
                                ).valueOf() / 1000
                              ).toString();
                            }
                          }}
                          popupClassName={classes.calendar}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Box className={classes.dateRangeInput}>
                        <RangePicker
                          showTime={{ format: "HH:mm" }}
                          dropdownClassName={classes.calendar}
                        />
                      </Box>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && timeTrack ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"CASE.TIMETRACK.STARTLOCATION"}
                          defaultMessage={"CASE.TIMETRACK.STARTLOCATION"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"start_location"}
                        label={""}
                        defaultValue={timeTrack?.start_location}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.start_location = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"start_location"}
                        label={intl.formatMessage({
                          id: "CASE.TIMETRACK.STARTLOCATION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && timeTrack ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"CASE.TIMETRACK.ENDLOCATION"}
                          defaultMessage={"CASE.TIMETRACK.ENDLOCATION"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"end_location"}
                        label={""}
                        defaultValue={timeTrack?.end_location}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.end_location = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"end_location"}
                        label={intl.formatMessage({
                          id: "CASE.TIMETRACK.ENDLOCATION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {props.case_needed && (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded && timeTrack ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"CASE.INFORMATION.CASE"}
                            defaultMessage={"CASE.INFORMATION.CASE"}
                          />
                        </Typography>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          onChange={(e, value: any) => {
                            form.values.related = value?.id;
                          }}
                          defaultValue={
                            timeTrack?.case && {
                              id: timeTrack?.case?._id,
                              label:
                                timeTrack?.case?.key +
                                ", " +
                                intl.formatMessage({
                                  id: "CASE.INFORMATION.CASE",
                                }) +
                                ", users, " +
                                timeTrack?.case?.users?.map(
                                  (u) => u.firstname + " "
                                ),
                            }
                          }
                          options={
                            props.cases
                              ? props.cases.map((_case) => {
                                  return {
                                    id: _case._id,
                                    label:
                                      _case.key +
                                      ", " +
                                      intl.formatMessage({
                                        id: "CASE.INFORMATION.CASE",
                                      }) +
                                      ", users, " +
                                      _case.users.map(
                                        (u) =>
                                          u.firstname + " " + u.lastname + " "
                                      ),
                                  };
                                })
                              : []
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          name={"case"}
                          options={[]}
                          label={intl.formatMessage({
                            id: "CASE.INFORMATION.CASE",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default TimeTrackShowDialogue;
