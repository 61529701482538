import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IPermission,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "app/config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

const INDEX = API_ROUTES.permissions;
const SHOW = API_ROUTES.permission;
const UPDATE = API_ROUTES.permission;
const STORE = API_ROUTES.permissions;
const GENERATE = API_ROUTES.permission_generate;
const DELETE = API_ROUTES.permission;

export interface IPermissionService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IPermission> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<IPermission> | IRestApiError>;
  update(
    _id: string,
    data: IPermission
  ): Promise<IRestApiResource<IPermission> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<IPermission> | IRestApiError>;
  add(
    data: IPermission
  ): Promise<IRestApiResource<IPermission> | IRestApiError>;
  generate(data: {
    title: string;
  }): Promise<IRestApiResource<IPermission> | IRestApiError>;
}

const permissionService: IPermissionService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: IPermission) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string) {
    return http(getRoute(DELETE.replace(":_id", _id.toString())), "delete");
  },

  async add(data: IPermission) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

  async generate(data: { title: string }) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(GENERATE), "post", formData);
  },
};

export { permissionService };
