import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { RootState } from "../store/store";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";

const I18nProvider: FC = (props) => {
  const locale = useSelector(({ i18n }: RootState) => i18n.lang);
  const allMessages = useSelector(({ i18n }: RootState) => i18n.messages);
  const [allMessagesKeyValue, setMKVFn] = useState<{
    [key: string]: string;
  } | null>(null);

  useEffect(() => {
    let d: { [key: string]: string } = {};

    if (allMessages) {
      allMessages.map((m) => {
        d[m.key] = m.translations[locale];
      });
    }

    setMKVFn(d);
  }, [allMessages, locale]);

  return (
    <IntlProvider locale={locale} messages={allMessagesKeyValue ?? {}}>
      {props.children}
    </IntlProvider>
  );
};

export default I18nProvider;
