// @ts-nocheck
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Skeleton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Controls from "../../../widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Snackbar from "../../../widgets/Snackbar";
import { permissionService } from "../../../services";
import * as setting from "../../../store/ducks/setting.duck";
import {
  Abilities,
  PermissionsType,
} from "../../../permission/PermissionsGate";
import { PermissionStructureType, TEMPLATE } from "app/permission/permissions";
import { checkKey } from "../../../utils/standart";
import { IPermission, IRole } from "../../../interfaces";
import { makeStyles } from "@material-ui/core";

interface IProps {
  _id: string;
  role: IRole | null;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  structure: PermissionStructureType | null;
};

interface IPermissionRow {
  rowTitle: PermissionsType;
  title: string;
  abilities: Array<Abilities>;
}

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": {
      padding: 0,
    },
  },
  borderUnset: {
    "& .MuiTableCell-root": {
      border: 0,
    },
  },
}));

const ShowPermission: FC<IProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const dispatch = useDispatch();
  const classes = useStyles();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [permission, setPermission] = useState<IPermission>();
  const updateSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Update, payload: s }),
    [dispatch]
  );
  const [template, setTemplate] = useState<Array<IPermissionRow> | null>(null);

  useEffect(() => {
    const rows: Array<IPermissionRow> = [];

    for (let key in TEMPLATE) {
      if (!checkKey<PermissionStructureType>(key, TEMPLATE)) {
        continue;
      }
      const _v = TEMPLATE[key];
      rows.push({
        rowTitle: key,
        title: _v.title,
        abilities: _v.abilities,
      });
    }
    setTemplate(rows);
  }, []);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    if (permission) {
      permissionService
        .update(permission._id, validated)
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

          let { data: setting } = data;
          updateSetting(setting);

          setTimeout(() => {
            props.setDialogue(false);
            props.onDone();
            form.clear();
          }, 1000);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  };

  useEffect(() => {
    permissionService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        let { data: permission } = data;
        setPermission(permission);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  useEffect(() => {
    if (permission) {
      form.values.title = permission.title ?? "";
      form.values.structure = permission.structure;
    }
  }, [permission]);

  const [selectAllStates, setSelectAllStates] = React.useState<{
    [key: string]: boolean;
  }>({});

  const handleSelectAllChange = (event: any, rowTitle: string) => {
    const checked = event.target.checked;

    setSelectAllStates((prev) => ({
      ...prev,
      [rowTitle]: checked,
    }));

    // Update the structure object accordingly
    let { structure } = form.values;

    if (structure && structure[rowTitle]) {
      structure[rowTitle].abilities = checked
        ? ["create", "read", "update", "delete"]
        : [];
    }

    // Update the form state
    form.values.structure = { ...structure };
  };
  const [selectAll, setSelectAll] = useState(false);
  const handleAbilityChange = (
    event: any,
    rowTitle: string,
    ability: Abilities
  ) => {
    let { structure } = form.values;

    if (structure && structure[rowTitle]) {
      if (event.target.checked) {
        structure[rowTitle].abilities.push(ability);
      } else {
        structure[rowTitle].abilities = structure[rowTitle].abilities.filter(
          (a) => a !== ability
        );
      }

      // Check if all abilities are checked to update the "Select All" state for this row
      const allChecked = ["create", "read", "update", "delete"].every((a) =>
        structure[rowTitle].abilities.includes(a as Abilities)
      );

      setSelectAllStates((prev) => ({
        ...prev,
        [rowTitle]: allChecked,
      }));
    }

    // Update the form state
    form.values.structure = { ...structure };

    // Check if all rows have all abilities checked to update the global "Select All" state
    const allAbilitiesChecked = template?.every((row) =>
      ["create", "read", "update", "delete"].every(
        (ability) =>
          structure && structure[row.rowTitle]?.abilities.includes(ability)
      )
    );

    setSelectAll(allAbilitiesChecked);
  };

  useEffect(() => {
    // Function to check if all required abilities are selected for a given rowTitle
    const areAllAbilitiesSelected = (rowTitle: PermissionsType) => {
      const abilities = permission?.structure[rowTitle]?.abilities;
      return (
        abilities?.includes("create") &&
        abilities?.includes("read") &&
        abilities?.includes("update") &&
        abilities?.includes("delete")
      );
    };

    // Check if all rows have all required abilities selected to update global selectAll state
    const allAbilitiesSelected = template?.every((row) =>
      areAllAbilitiesSelected(row.rowTitle)
    );
    setSelectAll(allAbilitiesSelected);

    // Initialize selectAllStates based on current state
    const initialSelectAllStates: Record<PermissionsType, boolean> = {};
    template?.forEach((row) => {
      initialSelectAllStates[row.rowTitle] = areAllAbilitiesSelected(
        row.rowTitle
      );
    });
    setSelectAllStates(initialSelectAllStates);
  }, [permission, template]);

  const handleSelectAll = () => {
    const newStructure = { ...form.values.structure };

    template?.forEach((row) => {
      if (newStructure[row.rowTitle]) {
        newStructure[row.rowTitle].abilities = [
          "create",
          "read",
          "update",
          "delete",
        ];
      }
    });

    form.values.structure = newStructure;

    const newSelectAllStates = template?.reduce((acc, row) => {
      acc[row.rowTitle] = true;
      return acc;
    }, {});

    setSelectAllStates(newSelectAllStates);
    setSelectAll(true);
  };

  const handleUnselectAll = () => {
    const newStructure = { ...form.values.structure };

    template?.forEach((row) => {
      if (newStructure[row.rowTitle]) {
        newStructure[row.rowTitle].abilities = [];
      }
    });

    form.values.structure = newStructure;

    const newSelectAllStates = template?.reduce((acc, row) => {
      acc[row.rowTitle] = false;
      return acc;
    }, {});

    setSelectAllStates(newSelectAllStates);
    setSelectAll(false);
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "rgb(245, 245, 245)",
            boxShadow: "none",
            // p: "0px 20px",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"MENU.PERMISSIONS"}
                defaultMessage={"MENU.PERMISSIONS"}
              />{" "}
              - {props.role?.title[intl.locale]}
            </Typography>
            <Button sx={{ marginLeft: 2 }} onClick={handleSelectAll}>
              <FormattedMessage
                id={"STANDARD.SELECTALL"}
                defaultMessage={"Select All"}
              />
            </Button>
            <Button sx={{ marginLeft: 2 }} onClick={handleUnselectAll}>
              <FormattedMessage
                id={"STANDARD.UNSELECTALL"}
                defaultMessage={"Unselect All"}
              />
            </Button>
            <Button
              form={"permission_form"}
              autoFocus
              type={"submit"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id={"permission_form"}
            onSubmit={(e) => form.handleSubmit(e, handleSave)}
          >
            <Grid container spacing={2} mb={2}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <TableContainer
                    component={Paper}
                    className={classes.table}
                    sx={{
                      boxShadow: "unset!important",
                      borderRadius: 0,
                      maxHeight: 440,
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow sx={{ height: 45 }}>
                          <TableCell align={"left"}>
                            <Typography
                              variant={"h4"}
                              fontSize={14}
                              fontWeight={600}
                            >
                              Title
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            <Typography
                              variant={"h4"}
                              fontSize={14}
                              fontWeight={600}
                            >
                              <FormattedMessage
                                id={"select_all"}
                                defaultMessage={"Select All"}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            <Typography
                              variant={"h4"}
                              fontSize={14}
                              fontWeight={600}
                            >
                              <FormattedMessage
                                id={"create"}
                                defaultMessage={"Create"}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            <Typography
                              variant={"h4"}
                              fontSize={14}
                              fontWeight={600}
                            >
                              <FormattedMessage
                                id={"read"}
                                defaultMessage={"Read"}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            <Typography
                              variant={"h4"}
                              fontSize={14}
                              fontWeight={600}
                            >
                              <FormattedMessage
                                id={"update"}
                                defaultMessage={"Update"}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            <Typography
                              variant={"h4"}
                              fontSize={14}
                              fontWeight={600}
                            >
                              <FormattedMessage
                                id={"delete"}
                                defaultMessage={"Delete"}
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {template?.map((row, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell component="th" scope="row">
                                <Typography variant={"h4"} fontSize={14}>
                                  <FormattedMessage
                                    id={row.title}
                                    defaultMessage={row.title}
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell align={"center"}>
                                <Checkbox
                                  id={`${row.rowTitle}_selectAll`}
                                  onChange={(event) =>
                                    handleSelectAllChange(event, row.rowTitle)
                                  }
                                  checked={
                                    selectAllStates[row.rowTitle] || false
                                  }
                                />
                              </TableCell>
                              {["create", "read", "update", "delete"].map(
                                (value, index) => {
                                  if (!permission?.structure) return null;
                                  return (
                                    <TableCell align={"center"} key={index}>
                                      <Checkbox
                                        id={`${row.rowTitle}_${value}`}
                                        onChange={(event) =>
                                          handleAbilityChange(
                                            event,
                                            row.rowTitle,
                                            value as Abilities
                                          )
                                        }
                                        checked={Boolean(
                                          permission?.structure[
                                            row.rowTitle
                                          ]?.abilities?.includes(
                                            value as Abilities
                                          )
                                        )}
                                      />
                                    </TableCell>
                                  );
                                }
                              )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"titles"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowPermission;
