import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { http } from "../lib/http.ts";

export function login(id, phone, password, business) {
  let fData = new FormData();
  fData.append("_id", id);
  fData.append("phone", phone);
  fData.append("password", password);
  fData.append("business", business);
  return http(getRoute(API_ROUTES.login), "post", fData);
}

export function getBusinessesByUser(phone, password) {
  let fData = new FormData();
  fData.append("phone", phone);
  fData.append("password", password);
  return http(getRoute(API_ROUTES.auth_business), "post", fData);
}

export function getUserByPhone(phone) {
  let fData = new FormData();
  fData.append("phone", phone);
  return http(getRoute(API_ROUTES.auth_user_phone), "post", fData);
}

export function getUserByToken(token, business) {
  try {
    let config = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Business: business,
      },
    };
    return fetch(getRoute("me"), config).then((response) => response.json());
  } catch (e) {
    console.error("error", e);
  }
}

export function register() {
  //soon
}

export function requestPassword() {
  //soon
}
