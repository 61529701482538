import React, { FC } from "react";
import { IRole } from "../interfaces";
import { Avatar, Box, Card, Grid, IconButton, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import { useHistory } from "react-router-dom";
import { IFile } from "../services/file.service";
import { stringAvatar } from "app/widgets/AvatarBackground";
import { useIntl } from "react-intl";

type UserCardProps = {
  firstname: string;
  lastname: string;
  role: IRole;
  file?: IFile | null;
  title?: string;
  _id: string;
};

const UserCard: FC<UserCardProps> = (props) => {
  const { firstname, lastname, role, _id, file } = props;
  const history = useHistory();
  const intl = useIntl();

  return (
    <Card
      sx={{
        border: "1px solid #E6E6E6",
        height: 60,
        borderRadius: 2,
      }}
    >
      <Grid container sx={{ height: "100%", padding: "12px 12px 10px 12px" }}>
        <Grid item container xs={8.5} sx={{ height: "inherit" }}>
          <Grid item xs={2.5} md={2.5} xl={1.5} sx={{ height: "inherit" }}>
            <Box
              sx={{
                height: "100%",
                width: 36,
                position: "relative",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: role?.color ?? "#0D99FF",
                }}
                variant="rounded"
                sx={{ width: "100%", height: "100%", borderRadius: 2 }}
                src={file?.base64}
                alt={firstname}
              />
              <Badge
                color="secondary"
                overlap="circular"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#2FFF93",
                    color: "#2FFF93",
                    width: "10px",
                    height: "10px",
                    position: "absolute",
                    bottom: "15px",
                    left: "24px",
                    zIndex: "99999!important",
                    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                    "&::after": {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      animation: "ripple 1.2s infinite ease-in-out",
                      border: "1px solid #ffffff",
                      content: '""',
                    },
                  },
                  "@keyframes ripple": {
                    "0%": {
                      transform: "scale(.8)",
                      opacity: 1,
                    },
                    "100%": {
                      transform: "scale(2.4)",
                      opacity: 0,
                    },
                  },
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              />
            </Box>
          </Grid>
          <Grid item xs={9.5} sx={{ height: "inherit" }}>
            <Box
              sx={{
                paddingLeft: "12px",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={15} lineHeight={1.1} noWrap>
                {firstname} {lastname}
              </Typography>
              <Typography
                fontSize={12}
                lineHeight={1.1}
                color={"#9FA5BB"}
                noWrap
              >
                {role.title[intl.locale]}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={3.5} sx={{ height: "inherit", paddingLeft: "12px" }}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              sx={{
                // p: 0,
                fontSize: 18,
                color: "#0D99FF",
              }}
              onClick={() => history.push(`/chats`)}
            >
              <i
                className="fi fi-sr-paper-plane"
                style={{
                  // padding: 4,
                  lineHeight: "0.5em",
                  verticalAlign: "-0.15em",
                  display: "inline-flex",
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                // p: 0,
                fontSize: 18,
                color: "#0D99FF",
              }}
              onClick={() => history.push(`/users/${_id}`)}
            >
              <i
                className="fi fi-sr-user"
                style={{
                  // padding: 4,
                  lineHeight: "0.5em",
                  verticalAlign: "-0.15em",
                  display: "inline-flex",
                }}
              />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default UserCard;
