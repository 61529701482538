import React, {
  ChangeEventHandler, createRef,
  FormEvent,
  FormEventHandler, useEffect, useRef,
} from "react";


interface IFormValues {
  [key: string]: any
}

interface IFormValidation<Values>{}
interface IFormError<Values> {}

interface IFormState<Values> {
  values: Values,
  errors: IFormError<Values>
  touched: boolean
}

export interface IForm<Values> extends IFormState<Values>{
  handleSubmit: (e: FormEvent<any>, fn: Function) => void,
  handleChange: any,
  clear: () => void,
  validate: any
}

type ValuesKeys<Values> = keyof IFormState<Values>;

export function useForm<Values extends IFormValues>(props?: Values) : IForm<Values> {

  const initValue : IForm<Values> = {
    errors: {} as IFormError<Values>,
    handleSubmit: (e, fn) => submit(e, fn),
    handleChange: () => {},
    validate: () => validate(),
    clear: () => clear(),
    touched: false,
    values: props ?? {} as Values
  }

  const form = useRef<IForm<Values>>(initValue);


  const submit = (e: FormEvent<any>, fn: Function) => {
    form.current.touched = true;
    fn();
    // form.current.values = {} as Values;
    e.preventDefault()
  }

  const clear = () => {
    form.current.values = {} as Values;
  }

  // const checkValue = <Values, K extends keyof Values>(v: Values, k: K, val: any): Values | boolean => {
  //   return true;
  // }

  const validate = () => {
    // TODO add validation
    return form.current.values;
  }

  return form.current;
}

