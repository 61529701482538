import React, { FC, useRef } from "react";
import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme: Theme) => ({
  icon: {
    lineHeight: "0.5em",
    verticalAlign: "-0.15em",
    display: "inline-flex",
  },
}));

export const Attach: FC<any> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyle();

  const handleHover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget !== anchorEl) setAnchorEl(null);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newFiles = event.target.files ? Array.from(event.target.files) : [];
    props.onFileUpload(newFiles);
  };

  return (
    <>
      <IconButton
        onMouseEnter={handleHover}
        onMouseLeave={handleClose}
        onClick={() => {
          hiddenInputRef.current?.click();
        }}
        aria-label="send"
        size="large"
        aria-expanded={open ? "true" : undefined}
        sx={{
          ml: -1.5,
        }}
      >
        <i className={`fi fi-sr-clip ${classes.icon}`} />
      </IconButton>
      <input
        type="file"
        ref={hiddenInputRef}
        hidden
        multiple
        onChange={handleFileUpload}
      />
      {/*<Menu*/}
      {/*  id="demo-positioned-menu"*/}
      {/*  aria-labelledby="demo-positioned-button"*/}
      {/*  anchorEl={anchorEl}*/}
      {/*  open={open}*/}
      {/*  onClose={handleClose}*/}
      {/*  anchorOrigin={{*/}
      {/*    vertical: "bottom",*/}
      {/*    horizontal: "left",*/}
      {/*  }}*/}
      {/*  transformOrigin={{*/}
      {/*    vertical: "bottom",*/}
      {/*    horizontal: "left",*/}
      {/*  }}*/}
      {/*  MenuListProps={{*/}
      {/*    onMouseLeave: handleClose,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <MenuItem onClick={handleClose}>*/}
      {/*    <ListItemIcon>*/}
      {/*      /!*<InsertPhotoOutlinedIcon fontSize="small" />*!/*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText>Photo Or Video</ListItemText>*/}
      {/*  </MenuItem>*/}
      {/*  <MenuItem*/}
      {/*    onClick={() => {*/}
      {/*      hiddenInputRef.current?.click();*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <ListItemIcon>*/}
      {/*      /!*<InsertDriveFileOutlinedIcon fontSize="small" />*!/*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText>File</ListItemText>*/}
      {/*  </MenuItem>*/}
      {/*</Menu>*/}
    </>
  );
};

export default Attach;
