import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { GridTable } from "app/partials/layout/GridTable";
import { useTable } from "app/hooks/useTable";
import { useIntl } from "react-intl";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import TaxRateStore from "./store";
import ShowTaxRate from "./show";
import { accountingService } from "../../../services";
import { ITaxRate } from "../../../services/accounting.service";
type Props = {
  lang: string;
  settings_loading: boolean;
};

const TaxRates: FC<Props> = () => {
  const intl = useIntl();
  const [confirmId, setConfirmId] = useState<string | null>(null);

  const { data, xhrLoading, refresh, remove, updateQuery } = useTable<ITaxRate>(
    {
      fetch: accountingService.getTaxRates,
      delete: accountingService.deleteTaxRate,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    }
  );

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return params.row?.title;
      },
      minWidth: 320,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.RATE",
        defaultMessage: "Rate",
      }),
      field: "rate",
      valueGetter: (params) => {
        return params.row?.rate;
      },
      minWidth: 160,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => show(params.row?._id)}>
              <OpenInNewIcon sx={{ color: "warning.main" }} />
            </IconButton>
            <IconButton onClick={() => handleClick(params.row?._id)}>
              <DeleteIcon sx={{ color: "error.main" }} />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [rateId, setRateId] = useState<string | null>(null);

  const show = (_id: string) => {
    setRateId(_id);
  };

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete rate?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this rate?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      {rateId ? (
        <ShowTaxRate
          _id={rateId}
          open={Boolean(rateId)}
          setDialogue={() => setRateId(null)}
          onDone={refresh}
        />
      ) : (
        <></>
      )}
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
        search={(queries) => updateQuery(queries)}
        filterToolbar={{
          actionPanel: (props: { open: boolean; close: () => void }) => {
            return (
              <TaxRateStore
                open={props.open}
                setDialogue={props.close}
                onDone={refresh}
              />
            );
          },
        }}
      />
    </>
  );
};

export default TaxRates;
