import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Snackbar from "app/widgets/Snackbar";
import { caseService, robotService, userService } from "app/services";
import { FormattedMessage, useIntl } from "react-intl";
import { IRobot } from "../../../services/robot.service";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, Select, Space } from "antd";
import Controls from "../../../widgets/uncontrolled";
import moment from "moment";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "@material-ui/core";
import { useForm } from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Autocomplete from "@mui/material/Autocomplete";
import SelectUsers, { SelectedUsers } from "../../../partials/SelectUsers";
import { ICase } from "../../../services/case.service";
import { IUser } from "../../../interfaces";
import UserAvatar from "../../../partials/layout/UserAvatar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
  robot?: IRobot | null;
  save?: Function;
}

export type FormStateValues = {
  type: string;
  title: string | null;
  category: string;
  description: string;
  case: string;
  lead: string;
  users: Array<string>;
  status: string;
  start_date: string;
  end_date: string;
  repeat: {
    every: number;
    type: string;
    days?: string[];
    end: { type: string; value: string };
  };
  generation: {
    type: string;
    end: { type: string; value: number };
  };
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#ffffff",
  },
  tooltip: {
    backgroundColor: "#ffffff",
    minWidth: 330,
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.25)",
    height: 136,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    padding: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
  iconButtonMessage: {
    "&:hover": {
      backgroundColor: "#556EE6",
    },
  },
  iconButtonProfile: {
    "&:hover": {
      backgroundColor: "#E1E6FB",
    },
  },
  popper: {
    left: "-5px!important",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const ShowRobot: FC<FormProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [robot, setRobot] = useState<IRobot | null>(null);
  const [repeat, setRepeat] = useState<{
    every: number;
    type: string;
    days?: string[];
    end: { type: string; value: string };
  }>({ every: 0, type: "", days: [], end: { type: "", value: "" } });

  const [generation, setGeneration] = useState<{
    type: string;
    end: { type: string; value: number };
  }>({ type: "auto", end: { type: "after", value: 1 } });
  const [daySelect, setDaySelect] = useState<boolean>(false);
  const [dateSelect, setDateSelect] = useState<boolean>(false);
  const [afterInput, setAfterInput] = useState<boolean>(false);
  const timeOut = useRef<any>();
  const [userSearch, setUserSearch] = useState<string>("");

  const handleType = (type: string) => {
    if (type === "week") {
      setDaySelect(true);
    } else {
      setDaySelect(false);
    }
  };

  useEffect(() => {
    form.values.repeat = repeat;
  }, [repeat]);

  useEffect(() => {
    form.values.generation = generation;
  }, [generation]);

  const handleEnd = (type: string) => {
    if (type === "date") {
      setDateSelect(true);
      setAfterInput(false);
    } else if (type === "after") {
      setAfterInput(true);
      setDateSelect(false);
    } else {
      setDateSelect(false);
      setAfterInput(false);
    }
  };

  const handleClose = () => {
    props.setDialogue(false);
  };

  useEffect(() => {
    robotService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: robot } = data;
        setRobot(robot);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    robotService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  useEffect(() => {
    if (robot) {
      form.values.type = robot?.type?._id;
      form.values.title = robot?.title;
      form.values.category = robot?.category?._id;
      form.values.description = robot?.description;
      form.values.case = robot?.case?._id;
      form.values.lead = robot?.lead?._id;
      form.values.status = robot?.status?._id;
      form.values.start_date = (
        moment.unix(robot.start).utc().valueOf() / 1000
      ).toString();
      form.values.end_date = (
        moment.unix(robot.end).utc().valueOf() / 1000
      ).toString();
      setSelectedUsers(
        robot.users?.map((user) => {
          return {
            _id: user._id,
            firstname: user.firstname,
            lastname: user.lastname,
            role: user.role,
            file: user?.file ?? undefined,
          };
        })
      );

      if (robot?.repeat?.type === "week") setDaySelect(true);
      if (robot?.repeat?.end?.type === "date") setDateSelect(true);

      setStatusField(robot?.type?._id);
      form.values.repeat = robot.repeat;
      form.values.generation = robot.generation;
      setRepeat(robot.repeat);
      setGeneration(robot.generation);
      if (robot.repeat?.type === "week") setDaySelect(true);
      if (robot.repeat?.end?.type === "date") setDateSelect(true);
    }
  }, [robot]);

  ///////

  const [selectedUsers, setSelectedUsers] = useState<Array<SelectedUsers>>([]);
  const [usersDialog, setUsersDialog] = useState<boolean | null>(false);
  const { todo_types } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [status, setStatus] = useState<boolean>(true);

  const setStatusField = (type: string) => {
    const slug =
      type && todo_types?.find((todo_status) => todo_status._id === type)?.slug;

    if (slug === "event") {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };
  const [cases, setCases] = useState<Array<ICase>>();
  const [leads, setLeads] = useState<IUser[]>([]);
  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: userSearch,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setLeads(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [userSearch]);

  useEffect(() => {
    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  useEffect(() => {
    form.values.users = selectedUsers.map((selectedUser) => selectedUser._id);
  }, [selectedUsers]);

  return (
    <>
      <SelectUsers
        open={Boolean(usersDialog)}
        Title={
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage id={"CASE.USERS"} defaultMessage={"CASE.USERS"} />
          </Typography>
        }
        handleClose={() => {
          setUsersDialog(null);
        }}
        handleSave={(selectedUsers: Array<SelectedUsers>) => {}}
        onUserChange={(users) => {
          setSelectedUsers([...users]);
        }}
        users={selectedUsers}
      />
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"ROBOT.ADD"}
                  defaultMessage={"ROBOT.ADD"}
                />
              </Typography>
              <Button
                form={"robot_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"robot_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        defaultValue={robot?.title}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;

                          event.preventDefault();
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Box>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({ id: "CASE.USERS" })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"LABEL.SELECTUSER"}
                        defaultMessage={"LABEL.SELECTUSER"}
                      />
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
                      {selectedUsers &&
                        selectedUsers.map((user) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UserAvatar user={user} />;
                              <IconButton
                                onClick={() => {
                                  setSelectedUsers(
                                    selectedUsers.filter(
                                      (s_user) => s_user._id !== user._id
                                    )
                                  );
                                }}
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  padding: "5px",
                                  backgroundColor: "#EC3033",
                                  "&:hover": { backgroundColor: "#EC3033" },
                                }}
                              >
                                <CloseOutlinedIcon
                                  sx={{
                                    color: "#fff",
                                    width: 10,
                                    height: 10,
                                  }}
                                />
                              </IconButton>
                            </Box>
                          );
                        })}
                      <Avatar sx={{ width: 44, height: 44 }}>
                        <Button
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "100%",
                            p: "14px!important",
                            backgroundColor: "#0D99FF",
                          }}
                          variant="contained"
                          onClick={() => {
                            setUsersDialog(true);
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.75 10.75H13.25V3.25C13.25 2.55965 12.6904 2 12 2C11.3096 2 10.75 2.55965 10.75 3.25V10.75H3.25C2.55965 10.75 2 11.3096 2 12C2 12.6904 2.55965 13.25 3.25 13.25H10.75V20.75C10.75 21.4404 11.3096 22 12 22C12.6904 22 13.25 21.4404 13.25 20.75V13.25H20.75C21.4404 13.25 22 12.6904 22 12C22 11.3096 21.4404 10.75 20.75 10.75Z"
                              fill="white"
                            />
                          </svg>
                        </Button>
                      </Avatar>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={"Case&Category"}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.CATEGORY"}
                          defaultMessage={"STANDARD.CATEGORY"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"category"}
                        formId={"category_pick"}
                        options={todo_categories?.map((t) => {
                          return {
                            id: t._id.toString(),
                            title: t.title[intl.locale],
                          };
                        })}
                        defaultValue={robot?.category?._id}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.category = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"category"}
                        options={[]}
                        label={intl.formatMessage({
                          id: "STANDARD.CATEGORY",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.TYPE"}
                          defaultMessage={"STANDARD.TYPE"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"type"}
                        formId={"type_pick"}
                        options={todo_types?.map((t) => {
                          return {
                            id: t._id.toString(),
                            title: t.title[intl.locale],
                          };
                        })}
                        defaultValue={robot?.type?._id}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.type = event.target.value;
                          setStatusField(event.target.value);
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"type"}
                        options={[]}
                        onChange={() => {}}
                        label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"CASE.INFORMATION.CASE"}
                          defaultMessage={"CASE.INFORMATION.CASE"}
                        />
                      </Typography>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        onChange={(e, value: any) => {
                          form.values.case = value?.id;
                        }}
                        defaultValue={
                          robot?.case && {
                            id: robot?.case?._id,
                            label:
                              robot?.case?.key +
                              ", " +
                              intl.formatMessage({
                                id: "CASE.INFORMATION.CASE",
                              }) +
                              ", users, " +
                              robot?.case?.users?.map((u) => u.firstname + " "),
                          }
                        }
                        options={
                          cases
                            ? cases.map((_case) => {
                                return {
                                  id: _case._id,
                                  label:
                                    _case.key +
                                    ", " +
                                    intl.formatMessage({
                                      id: "CASE.INFORMATION.CASE",
                                    }) +
                                    ", users, " +
                                    _case.users.map(
                                      (u) =>
                                        u.firstname + " " + u.lastname + " "
                                    ),
                                };
                              })
                            : []
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                      {/*<Controls.Select*/}
                      {/*  name={"case"}*/}
                      {/*  formId={"case_pick"}*/}
                      {/*  options={*/}
                      {/*    cases &&*/}
                      {/*    cases.map((_case) => {*/}
                      {/*      return {*/}
                      {/*        id: _case._id.toString(),*/}
                      {/*        title: `${intl.formatMessage({*/}
                      {/*          id: "CASE.INFORMATION.CASE",*/}
                      {/*        })}: ${_case.key}; Users: ${_case.users.map(*/}
                      {/*          (u) => u.firstname*/}
                      {/*        )}`,*/}
                      {/*      };*/}
                      {/*    })*/}
                      {/*  }*/}
                      {/*  defaultValue={robot?.case?._id}*/}
                      {/*  label={""}*/}
                      {/*  onChange={(event) => {*/}
                      {/*    if (!event.target.value) return;*/}

                      {/*    form.values.case = event.target.value;*/}
                      {/*  }}*/}
                      {/*/>*/}
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"case"}
                        options={[]}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.CASE",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                {status && (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded && robot ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"STANDARD.STATUS"}
                            defaultMessage={"STANDARD.STATUS"}
                          />
                        </Typography>
                        <Controls.Select
                          options={todo_statuses?.map((t) => {
                            return {
                              id: t._id.toString(),
                              title: t.title[intl.locale],
                            };
                          })}
                          formId={"status_pick"}
                          defaultValue={robot?.status?._id}
                          name={"status"}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.status = event.target.value;
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          options={[]}
                          name={"status"}
                          label={intl.formatMessage({
                            id: "STANDARD.STATUS",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={status ? 6 : 12}
                  xl={status ? 6 : 12}
                >
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"CASE.TODO.LEAD"}
                          defaultMessage={"CASE.TODO.LEAD"}
                        />
                      </Typography>
                      <Select
                        placeholder={intl.formatMessage({
                          id: "CASE.TODO.LEAD",
                        })}
                        showSearch
                        onChange={(value: string) => {
                          form.values.lead = value;
                        }}
                        onSearch={(value) => {
                          sendQuery(value);
                        }}
                        defaultValue={
                          robot?.lead?.firstname + " " + robot?.lead?.lastname
                        }
                        filterOption={false}
                        style={{ width: "100%" }}
                        allowClear={true}
                        options={
                          leads &&
                          leads.map((user) => {
                            return {
                              value: user._id.toString(),
                              label: user.firstname + " " + user.lastname,
                            };
                          })
                        }
                        className={classes.antSelect}
                        onClear={() => {
                          sendQuery("");
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"lead"}
                        options={[]}
                        label={intl.formatMessage({ id: "CASE.TODO.LEAD" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.DESCRIPTION"}
                          defaultMessage={"STANDARD.DESCRIPTION"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"description"}
                        label={""}
                        multiline={true}
                        rows={6}
                        defaultValue={robot?.description}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.description = event.target.value;
                        }}
                        InputProps={{
                          // @ts-ignore
                          "data-cy": "todo_description",
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <>
                    {loaded && robot ? (
                      <Box className={classes.input} sx={{ width: "100%" }}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"CASE.TIMETRACK.STARTDATE"}
                            defaultMessage={"CASE.TIMETRACK.STARTDATE"}
                          />
                        </Typography>
                        <Space
                          className={classes.antSpace}
                          direction="vertical"
                          size={12}
                        >
                          <DatePicker
                            showTime={{ format: "HH:mm" }}
                            className={classes.datePicker}
                            dropdownClassName={classes.antDropdown}
                            defaultValue={moment(robot?.start, "X").local()}
                            onChange={(value, dateString) => {
                              form.values.start_date = (
                                moment(
                                  dateString,
                                  "DD-MM-YYYY HH:mm"
                                ).valueOf() / 1000
                              ).toString();
                            }}
                            format={"DD-MM-YYYY HH:mm"}
                          />
                        </Space>
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <DatePicker
                          showTime={{ format: "HH:mm" }}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                    {loaded && robot ? (
                      <Box className={classes.input} sx={{ width: "100%" }}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"CASE.TIMETRACK.ENDDATE"}
                            defaultMessage={"CASE.TIMETRACK.ENDDATE"}
                          />
                        </Typography>
                        <Space
                          className={classes.antSpace}
                          direction="vertical"
                          size={12}
                        >
                          <DatePicker
                            showTime={{ format: "HH:mm" }}
                            className={classes.datePicker}
                            dropdownClassName={classes.antDropdown}
                            onChange={(value, dateString) => {
                              form.values.end_date = (
                                moment(
                                  dateString,
                                  "DD-MM-YYYY HH:mm"
                                ).valueOf() / 1000
                              ).toString();
                            }}
                            defaultValue={moment(robot?.end, "X").local()}
                            format={"DD-MM-YYYY HH:mm"}
                          />
                        </Space>
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Space
                          className={classes.antSpace}
                          direction="vertical"
                          size={12}
                        >
                          <DatePicker />
                        </Space>
                      </Skeleton>
                    )}
                  </>
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={"Robot"}
                      sx={{
                        backgroundColor: "#0D99FF",
                        color: "#ffffff",
                      }}
                    />
                  </Divider>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.EVERY"}
                          defaultMessage={"STANDARD.EVERY"}
                        />
                      </Typography>
                      <Controls.Input
                        name={""}
                        type={"number"}
                        defaultValue={robot?.repeat?.every}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          setRepeat({
                            ...repeat,
                            every: +event.target.value,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"type"}
                        options={[]}
                        onChange={() => {}}
                        label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={daySelect ? 3 : 6}
                  xl={daySelect ? 3 : 6}
                >
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.TYPE"}
                          defaultMessage={"STANDARD.TYPE"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"type"}
                        formId={"type"}
                        options={[
                          {
                            id: "day",
                            title: "Day",
                          },
                          {
                            id: "week",
                            title: "Week",
                          },
                          {
                            id: "month",
                            title: "Month",
                          },
                          {
                            id: "year",
                            title: "Year",
                          },
                        ]}
                        defaultValue={robot?.repeat?.type}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          handleType(event.target.value);
                          setRepeat({
                            ...repeat,
                            type: event.target.value,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                {daySelect && (
                  <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
                    {loaded && robot ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"STANDARD.DAYS"}
                            defaultMessage={"STANDARD.DAYS"}
                          />
                        </Typography>
                        <Controls.MultipleSelect
                          label={""}
                          name={"days"}
                          formId={"days"}
                          defaultValue={robot?.repeat?.days}
                          options={[
                            {
                              id: 1,
                              title: "Monday",
                            },
                            {
                              id: 2,
                              title: "Tuesday",
                            },
                            {
                              id: 3,
                              title: "Wednesday",
                            },
                            {
                              id: 4,
                              title: "Thursday",
                            },
                            {
                              id: 5,
                              title: "Friday",
                            },
                            {
                              id: 6,
                              title: "Saturday",
                            },
                            {
                              id: 7,
                              title: "Sunday",
                            },
                          ]}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            setRepeat({
                              ...repeat,
                              days: event.target.value,
                            });
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"description"}
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Tooltip
                        title={
                          "Here you can choose the recurring duration of the task. You have three options: 1) Never- means the task will occur simultaneously without end. 2) Date- means the task will occur until that specific date. 3) After- means you will decide how many tasks you need, and the task will be stopped when the number is reached."
                        }
                      >
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"STANDARD.END"}
                            defaultMessage={"STANDARD.END"}
                          />
                        </Typography>
                      </Tooltip>
                      <Controls.Select
                        options={[
                          {
                            id: "never",
                            title: "Never",
                          },
                          {
                            id: "date",
                            title: "Date",
                          },
                          {
                            id: "after",
                            title: "After",
                          },
                        ]}
                        formId={"status_pick"}
                        defaultValue={robot?.repeat?.end?.type}
                        name={"end"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          handleEnd(event.target.value);

                          setRepeat({
                            ...repeat,
                            end: {
                              type: event.target.value,
                              value: repeat?.end?.value,
                            },
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        options={[]}
                        name={"status"}
                        label={intl.formatMessage({
                          id: "STANDARD.STATUS",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {dateSelect && (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    <>
                      {loaded && robot ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"} sx={{ mb: 1 }}>
                            <FormattedMessage
                              id={"STANDARD.DATE"}
                              defaultMessage={"STANDARD.DATE"}
                            />
                          </Typography>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker
                              className={classes.datePicker}
                              dropdownClassName={classes.antDropdown}
                              onChange={(value, dateString) => {
                                setRepeat({
                                  ...repeat,
                                  end: {
                                    type: repeat?.end?.type,
                                    value: dateString,
                                  },
                                });
                              }}
                              defaultValue={moment(
                                robot?.repeat?.end?.value,
                                "X"
                              ).local()}
                            />
                          </Space>
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker />
                          </Space>
                        </Skeleton>
                      )}
                    </>
                  </Grid>
                )}
                {afterInput && (
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded && robot ? (
                      <Box className={classes.input}>
                        <Typography
                          variant={"body1"}
                          sx={{ mb: 1, display: "inline-block" }}
                        >
                          <FormattedMessage
                            id={"STANDARD.AFTER"}
                            defaultMessage={"STANDARD.AFTER"}
                          />
                        </Typography>
                        <Controls.Input
                          name={"title"}
                          type={"number"}
                          label={""}
                          defaultValue={robot?.repeat?.end?.value}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            setRepeat({
                              ...repeat,
                              end: {
                                type: repeat.end.type,
                                value: event.target.value,
                              },
                            });

                            event.preventDefault();
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={"Generation"}
                      sx={{
                        backgroundColor: "#0D99FF",
                        color: "#ffffff",
                      }}
                    />
                  </Divider>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={daySelect ? 3 : 6}
                  xl={daySelect ? 3 : 6}
                >
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        <FormattedMessage
                          id={"STANDARD.TYPE"}
                          defaultMessage={"STANDARD.TYPE"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"type"}
                        formId={"type"}
                        options={[
                          {
                            id: "auto",
                            title: "Auto",
                          },
                          {
                            id: "manual",
                            title: "Manual",
                          },
                        ]}
                        defaultValue={robot?.generation?.type}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          setGeneration({
                            ...generation,
                            type: event.target.value,
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded && robot ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 1 }}>
                        After occurrence
                      </Typography>
                      <Controls.Input
                        name={""}
                        type={"number"}
                        defaultValue={robot?.generation?.end?.value}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          setGeneration({
                            ...generation,
                            end: {
                              type: generation?.end?.type,
                              value: +event.target.value,
                            },
                          });
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"type"}
                        options={[]}
                        onChange={() => {}}
                        label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowRobot;
