import React, { FC } from "react";
import { toAbsoluteUrl } from "../../_metronic";
import { Theme } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  flag: {
    marginLeft: "10px",
    width: 30,
    height: 20,
    objectFit: "cover",
    borderRadius: "5px",
    "&:first-child": {
      marginLeft: "0",
    },
  },
}));

const languages = [
  {
    lang: "english",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/260-united-kingdom.svg"),
  },
  {
    lang: "danish",
    name: "Danish",
    flag: toAbsoluteUrl("/media/flags/174-denmark.svg"),
  },
  {
    lang: "german",
    name: "German",
    flag: toAbsoluteUrl("/media/flags/162-germany.svg"),
  },
  {
    lang: "french",
    name: "French",
    flag: toAbsoluteUrl("/media/flags/195-france.svg"),
  },
  {
    lang: "spanish",
    name: "Spanish",
    flag: toAbsoluteUrl("/media/flags/128-spain.svg"),
  },
  {
    lang: "italian",
    name: "Italian",
    flag: toAbsoluteUrl("/media/flags/013-italy.svg"),
  },
  {
    lang: "urdu",
    name: "Urdu",
    flag: toAbsoluteUrl("/media/flags/100-pakistan.svg"),
  },
  {
    lang: "kurmanji",
    name: "Kurmanji",
    flag: toAbsoluteUrl("/media/flags/020-iraq.svg"),
  },
  {
    lang: "pashto",
    name: "Pashto",
    flag: toAbsoluteUrl("/media/flags/111-afghanistan.svg"),
  },
  {
    lang: "swaheli",
    name: "Swaheli",
    flag: toAbsoluteUrl("/media/flags/006-tanzania.svg"),
  },
  {
    lang: "arabisk",
    name: "Arabisk",
    flag: toAbsoluteUrl("/media/flags/133-saudi-arabia.svg"),
  },
  {
    lang: "tigrinja",
    name: "Tigrinja",
    flag: toAbsoluteUrl("/media/flags/005-ethiopia.svg"),
  },
  {
    lang: "punjabi",
    name: "Punjabi",
    flag: toAbsoluteUrl("/media/flags/246-india.svg"),
  },
  {
    lang: "svensk",
    name: "Svensk",
    flag: toAbsoluteUrl("/media/flags/184-sweden.svg"),
  },
  {
    lang: "norsk",
    name: "Norsk",
    flag: toAbsoluteUrl("/media/flags/143-norway.svg"),
  },
  {
    lang: "turkish",
    name: "Tyrkisk",
    flag: toAbsoluteUrl("/media/flags/218-turkey.svg"),
  },
];

type LangProps = {
  _key: string;
};

const Language: FC<LangProps> = (props) => {
  const classes = useStyles();

  const getLang = (
    _l: string
  ): { lang: string; name: string; flag: string } | undefined => {
    return languages.find((l) => l.lang === _l);
  };

  return (
    <img
      className={classes.flag}
      src={getLang(props._key)?.flag}
      alt={getLang(props._key)?.name}
    />
  );
};

export default Language;
