import React, {Dispatch, FC, SetStateAction} from 'react';
import Snackbar from "../../../widgets/Snackbar";
import {caseService} from "../../../services";
import TodoStoreDialogue, {FormStateValues} from "../../../partials/dialogues/store/todo"
import {useIntl} from "react-intl";

interface IFormProps {
    _id: string,
    open: boolean,
    setDialogue: Dispatch<SetStateAction<boolean>>
    onDone: Function
}

const TodoStore: FC<IFormProps> = (props) => {
    const intl = useIntl();

    const handleSave = (validated: FormStateValues, callback: () => void) => {

        if (!validated){
            Snackbar.error(intl.formatMessage({id: 'SNACKBAR.STOREERROR'}))
            return;
        }

        caseService.postTodo(props._id, validated).then((data) => {
            if ("error" in data){
                throw Error(data.error.message)
            }

            Snackbar.success(intl.formatMessage({id: 'SNACKBAR.ADDED'}))

            setTimeout(() => {
                props.setDialogue(false);
                props.onDone();
                callback();
            }, 1000)

        }).catch((e) => {
            Snackbar.error(e.message)
        });
    }

    return(
        <TodoStoreDialogue _id={props._id}
                           open={props.open}
                           setDialogue={props.setDialogue}
                           case_needed={false}
                           save={handleSave}/>
    )
}

export default TodoStore;
