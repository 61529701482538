import React, { FC, useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { connect, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridRowId } from "@mui/x-data-grid";
import snackbar from "../../widgets/Snackbar";
import { logService } from "../../services";
import { useIntl } from "react-intl";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import { PermissionsGate } from "../../permission/PermissionsGate";
import { RootState } from "../../store/store";
import EditIcon from "../../partials/icons/EditIcon";

type Props = {
  lang: object;
  users: Array<any>;
  focus_areas: Array<any>;
  focus_types: Array<any>;
  settings_loading: boolean;
};

const Logs: FC<Props> = (props) => {
  const intl = useIntl();
  const { data, xhrLoading, refresh, remove, updateQuery } = useTable<any>({
    fetch: logService.getAll,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const locale = useSelector(({ i18n }: RootState) => i18n.lang);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"logs"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"logs"} scope={"delete"}>
              <IconButton onClick={() => remove(params.row?._id)}>
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (_id: string) => {
    snackbar.info("Coming soon");
  };

  return (
    <PermissionsGate section={"logs"} scope={"read"}>
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
      />
    </PermissionsGate>
  );
};

const mapStateToProps = ({ i18n, settingReducer }: any) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
