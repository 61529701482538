import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import moment from "moment";
import { Chip, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { timeTrackService } from "../../services";
import TimeTrackStore from "./store";
import { useTable } from "../../hooks/useTable";
import { ITimeTrack } from "../../services/timeTrack.service";
import { GridTable } from "../../partials/layout/GridTable";
import ShowTimeTrack from "./show";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import { usePermissions } from "app/hooks/useRole";
import TimeTrackFilter from "./filter";
import EditIcon from "../../partials/icons/EditIcon";
import { IUser } from "../../interfaces";
import { useHistory } from "react-router-dom";
import UserAvatar from "../../partials/layout/UserAvatar";

const TimeTracks = () => {
  const intl = useIntl();
  const history = useHistory();
  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<ITimeTrack>({
      fetch: timeTrackService.getAll,
      delete: timeTrackService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const permissions = usePermissions();

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 50,
    },
    {
      field: "case_number",
      headerName: intl.formatMessage({ id: "CASE.CASENUMBER" }),
      valueGetter: (params) => {
        return params.row?.case?.key;
      },
    },
    {
      headerName: intl.formatMessage({ id: "CASE.USERS" }),
      field: "users",
      renderCell: (params) => {
        return params.row?.case ? (
          params.row?.case?.users?.map((user: IUser) => {
            return <UserAvatar user={user} />;
          })
        ) : (
          <UserAvatar user={params.row?.created_by} />
        );
      },
      minWidth: 200,
      sortable: false,
    },
    {
      headerName: intl.formatMessage({ id: "USERS.PLACE" }),
      field: "place",
      renderCell: (params) => {
        return (
          <div className="kt-timeline-v2 todo-timeline ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-warning" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  <Tooltip title={params.row?.start_location}>
                    <Typography variant="inherit">
                      {params.row?.start_location?.substring(0, 16) + ".."}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-primary todo-timeline-circle-icon" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  <Tooltip title={params.row?.end_location}>
                    <Typography variant="inherit">
                      {params.row?.end_location?.substring(0, 16) + ".."}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      renderCell: (params) => {
        return (
          <div className="kt-timeline-v2 todo-timeline ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-success" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {moment
                    .unix(params.row?.start_date_time)
                    .local()
                    .format("DD/MM/YYYY HH:mm")}
                </div>
              </div>
              <div className="kt-timeline-v2__item todo-timeline-item">
                <div className="kt-timeline-v2__item-cricle todo-timeline-circle">
                  <i className="fa fa-circle kt-font-danger todo-timeline-circle-icon" />
                </div>
                <div className="kt-timeline-v2__item-text todo-timeline-item-text">
                  {moment
                    .unix(params.row?.end_date_time)
                    .local()
                    .format("DD/MM/YYYY HH:mm")}
                </div>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 150,
    },
    {
      field: "elapse",
      headerName: intl.formatMessage({ id: "CASE.TIMETRACK.ELLAPSETIME" }),
      renderCell: (params) => {
        let elapse = params.row?.end_date_time - params.row?.start_date_time;
        let second = elapse % 60;
        let minute = Math.floor((elapse % 3600) / 60);
        let hour = Math.floor((elapse % 86400) / 3600);
        let result = "";

        result += hour >= 1 ? hour + "h " : "";
        result += minute > 0 ? minute + "m " : "";
        result += second > 0 ? second + "s " : "";

        return result;
      },
      minWidth: 120,
    },
    {
      field: "type",
      headerName: intl.formatMessage({ id: "STANDARD.TYPE" }),
      renderCell: (params) => {
        return (
          params.row?.type?.title && (
            <Tooltip title={params.row?.type?.title[intl.locale]}>
              <Typography variant="inherit">
                {params.row?.type?.title[intl.locale]?.substring(0, 16) + ".."}
              </Typography>
            </Tooltip>
          )
        );
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"time_records"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"time_record_show"}
                data-cy-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"time_records"} scope={"delete"}>
              <IconButton
                onClick={() => handleClick(params.row?._id)}
                data-cy-class={"time_record_delete"}
                data-cy-id={params.row?._id}
              >
                <DeleteIcon sx={{ color: "error.main" }} />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [timeTrackId, setTimeTrackId] = useState<string | null>(null);

  const show = (_id: string) => {
    setTimeTrackId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"time_records"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete time registration?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(confirmId);
            }}
          >
            Are you sure you want to delete this time registration?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"time_records"} scope={"read"}>
        {timeTrackId ? (
          <ShowTimeTrack
            _id={timeTrackId}
            open={Boolean(timeTrackId)}
            setDialogue={() => setTimeTrackId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"time_records"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions &&
              hasPermission(permissions, "time_records", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <TimeTrackStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            filters: (props: { show: boolean }) => {
              return (
                <TimeTrackFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default TimeTracks;
