import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../../partials/layout/DataTable";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import { userService } from "../../../services";
import moment from "moment";
import { useTable } from "../../../hooks/subresource/useTable";
import { GridTable } from "../../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { INote } from "../../../services/note.service";
import {
  hasPermission,
  PermissionsGate,
} from "../../../permission/PermissionsGate";
import UserNoteStore from "../store/noteStore";
import ShowUserNote from "./showNote";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import { usePermissions } from "../../../hooks/useRole";
import UserNoteFilter from "../filters/noteFilter";
import EditIcon from "../../../partials/icons/EditIcon";
import DeleteIcon from "../../../partials/icons/DeleteIcon";
import { stringAvatar } from "../../../widgets/AvatarBackground";
import UserAvatar from "../../../partials/layout/UserAvatar";

const UserNotes = () => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const permissions = usePermissions();
  const { data, xhrLoading, remove, refresh, updateQuery, queries, setQuery } =
    useTable<INote>({
      fetch: userService.getNotes,
      delete: userService.deleteNote,
      param: id,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      renderCell: (params) => {
        return params.row?.title;
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 160,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.CREATED_BY" }),
      field: "created_by",
      renderCell: (params) => {
        return <UserAvatar user={params.row?.created_by} />;
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => show(params.row?._id)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleClick(params.row?._id)}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [noteId, setNoteId] = useState<string | null>(null);

  const show = (_id: string) => {
    setNoteId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"notes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete note?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(id, confirmId);
            }}
          >
            Are you sure you want to delete this note?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"notes"} scope={"read"}>
        {noteId ? (
          <ShowUserNote
            user={id}
            _id={noteId}
            open={Boolean(noteId)}
            setDialogue={() => setNoteId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"notes"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "contracts", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <UserNoteStore
                        _id={id}
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            filters: (props: { show: boolean }) => {
              return (
                <UserNoteFilter
                  show={props.show}
                  queries={queries}
                  setQuery={setQuery}
                  updateQuery={updateQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default UserNotes;
