import Snackbar from "../widgets/Snackbar";

export function http(
  url: string,
  method: string,
  data?: FormData | null | string
) {
  let config = getConfig(method, data);
  return fetch(url, config).then((response) => {
    return response.json();
  });
}

export function http_multi(requests: Array<Promise<any>>) {
  return Promise.all(requests).then((responses) => {
    return responses.map((r) => r);
  });
}

export const getAccessToken = (): string => {
  const tokenDataString = localStorage.getItem("persist:auth");
  let access_token = "";

  if (tokenDataString && JSON.parse(tokenDataString).authToken) {
    access_token = JSON.parse(tokenDataString).authToken.replace(/['"]+/g, "");
  }

  return access_token;
};

export const getBusiness = (): string => {
  const business = localStorage.getItem("persist:auth");
  let businessId = "";

  if (business && JSON.parse(business).business) {
    businessId = JSON.parse(JSON.parse(business).business)._id;
  }

  return businessId;
};

const getConfig = (method: string, data?: FormData | null | string): any => {
  let access_token = getAccessToken();
  let business = getBusiness();
  let ret = {};

  let headers: { [key: string]: string } = {
    Accept: "*/*",
    // 'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
    Business: business,
  };

  switch (method) {
    case "get":
      ret = {
        method: method,
        headers: headers,
      };
      break;
    case "put":
      headers["Content-Type"] = "application/json";
      ret = {
        method: method,
        headers: headers,
        body: data,
      };
      break;
    case "post":
      ret = {
        method: method,
        headers: headers,
        body: data,
      };
      break;
    case "delete":
      ret = {
        method: method,
        headers: headers,
      };
      break;
  }

  return ret;
};
