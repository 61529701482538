import { IUrlQuery, IRestApiCollection, IRestApiError, IRestApiResource } from "../interfaces";
import {http} from "../lib/http";
import {API_ROUTES, getRoute} from "../config/apiRoutes";
import {generateFormData, queryToString} from "../utils/http";

export interface IMailbox {
  _id: string
  subject: string
  email: string
  phone: string
  is_secure: boolean
  is_read: boolean
  mail_response: boolean
  sms_response: boolean
  content: string
  mail_content: string
  type: string
}

export interface IMailboxForm {
  subject: string
  email: string
  phone: string
  is_secure: boolean
  is_read: boolean
  mail_response: boolean
  sms_response: boolean
  content: string
  mail_content: string
  type: string
}

const INDEX = API_ROUTES.mailboxes;
const SHOW = API_ROUTES.mailbox;
const UPDATE = API_ROUTES.mailbox;
const STORE = API_ROUTES.mailboxes;
const DELETE = API_ROUTES.mailbox_delete;

export interface IMailboxService {
  getAll(queries?: Array<IUrlQuery>): Promise<IRestApiCollection<IMailbox> | IRestApiError>,
  getOne(_id: string): Promise<IRestApiResource<IMailbox> | IRestApiError>,
  update(_id: string, data: IMailboxForm): Promise<IRestApiResource<IMailbox> | IRestApiError>,
  delete(_id: string): Promise<IRestApiCollection<IMailbox> | IRestApiError>,
  add(data: any): Promise<IRestApiResource<any> | IRestApiError>,
  delete(_id: string, queries?: Array<IUrlQuery>): Promise<IRestApiCollection<IMailbox> | IRestApiError>,
}

const mailboxService : IMailboxService = {

  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : '';
    return  http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return  http(`${getRoute(SHOW).replace(':_id', _id.toString())}`, "get");
  },

  async update(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return  http(`${getRoute(UPDATE).replace(':_id', _id.toString())}`, "put", jsonData);
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : '';
    return http(`${getRoute(DELETE.replace(':_id', _id.toString()))}${queriesString}`, "delete");
  },

  async add(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

}

export {mailboxService};
