import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  createRef,
  FC,
  memo,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  IRestApiCollection,
  IRestApiError,
  IRole,
  IUser,
} from "app/interfaces";
import Snackbar from "app/widgets/Snackbar";
import { userService } from "app/services";
import { http_multi } from "app/lib/http";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import SelectUsers, { SelectedUsers } from "../../../../partials/SelectUsers";
import { makeStyles } from "@material-ui/core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useParams } from "react-router";
import { ICase } from "../../../../services/case.service";
import { useHistory } from "react-router-dom";
import { stringAvatar } from "../../../../widgets/AvatarBackground";
import UserAvatar from "../../../../partials/layout/UserAvatar";
import FolderIcon from "@mui/icons-material/Folder";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export interface IFormUsersValues {
  users?: Array<string>;
  users_titles?: Array<{ _id: string; title: string }>;
}

interface IRow {
  rowIndex: number;
  role?: IRole | null;
  user?: IUser | null;
  title?: string | null;
}

interface IOption<T> {
  rowIndex: number;
  limit: number;
  total?: number;
  options: Array<{
    data: T;
    _id: string;
    title: string;
  }>;
}

interface IFormUsersProps {
  case?: ICase;
  title?: boolean;
  rows?: Array<IRow>;
  count?: number;
  values: IFormUsersValues;
  handleChange: any;
  getUsers?: Function;
}

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    minWidth: 330,
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.25)",
    height: 136,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    padding: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
  iconButtonMessage: {
    "&:hover": {
      backgroundColor: "#556EE6",
    },
  },
  iconButtonProfile: {
    "&:hover": {
      backgroundColor: "#E1E6FB",
    },
  },
  popper: {
    left: "-5px!important",
  },
}));

const Users: FC<IFormUsersProps> = (props) => {
  const [rows, setRows] = useState<Array<IRow>>(
    props.rows ?? ([] as Array<IRow>)
  );
  const history = useHistory();
  const intl = useIntl();
  const params = useParams<{ id: string }>();
  const [users, setUsers] = useState<Array<IOption<IUser>>>([]);
  const [s_users, setSelectedUsers] = useState<Array<SelectedUsers>>([]);
  const classes = useStyles();
  const [selectUsersDialog, setSelectUsersDialog] = useState<boolean | null>(
    false
  );
  const { roles } = useSelector((state: RootState) => state.permission);

  // uncontrolled refs for value manipulations
  const rolesRef = useRef<
    Array<{ rowIndex: number; element: RefObject<HTMLInputElement> }>
  >([]);
  const usersRef = useRef<
    Array<{ rowIndex: number; element: RefObject<HTMLInputElement> }>
  >([]);
  const titlesRef = useRef<
    Array<{ rowIndex: number; element: RefObject<HTMLInputElement> }>
  >([]);

  useEffect(() => {
    if (s_users) props.values.users = s_users.map((s_user) => s_user._id);
  }, [s_users]);

  useEffect(() => {
    if (rows.length === 0 && props.count) {
      let _i = 0;
      let _rows = [];
      let _users = [];
      while (_i < props.count) {
        _rows.push({ rowIndex: _i } as IRow);
        _users.push({ rowIndex: _i, options: [], limit: 10 } as IOption<IUser>);

        rolesRef?.current?.push({ rowIndex: _i, element: createRef() });
        usersRef?.current?.push({ rowIndex: _i, element: createRef() });
        titlesRef?.current?.push({ rowIndex: _i, element: createRef() });

        _i++;
      }
      setRows(_rows);
      setUsers(_users);
    }
  }, []);

  useEffect(() => {
    if (props.case) {
      let users: SelectedUsers[] = [];
      props.case.users?.map((user) => {
        users.push({
          _id: user._id,
          firstname: user.firstname,
          lastname: user.lastname,
          role: user.role,
          file: user.file ?? undefined,
        });
      });

      setSelectedUsers(users);
    }
  }, [props.case]);

  useEffect(() => {
    if (props.rows) {
      let requests = rows.map((r) => {
        return new Promise((resolve) => {
          resolve(
            userService.getAll([
              {
                name: "role",
                value: r.role?._id ?? 0,
              },
              {
                name: "pagination",
                value: "0", //TODO change it to false
              },
            ])
          );
        });
      });

      http_multi(requests).then(
        (data: Array<IRestApiCollection<IUser>> | IRestApiError) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          let allUsers: Array<Array<IUser>> = [];
          data.map((d) => {
            const _users = d.data;
            allUsers.push(_users);
          });

          setUsers(
            allUsers.map((users, index) => ({
              rowIndex: index,
              options: users.map((u) => ({
                _id: u._id,
                title: u.firstname,
                data: u,
              })),
              limit: 10,
            }))
          );
        }
      );
    }
  }, []);

  useEffect(() => {
    rows.map((r, i) => {
      if (!r.user) return;

      if (props.values.users) {
        props.values.users[i] = r.user._id;
      } else {
        let newUsers = [] as Array<string>;
        newUsers[i] = r.user._id;
        props.values.users = newUsers;
      }

      if (r.title && props.title) {
        if (props.values.users_titles) {
          props.values.users_titles[i] = {
            _id: r.user._id,
            title: r.title,
          };
        } else {
          let newTitles = [] as Array<{ _id: string; title: string }>;
          newTitles[i] = {
            _id: r.user._id,
            title: r.title,
          };
          props.values.users_titles = newTitles;
        }
      }
    });
  }, [rows]);

  const UserTooltipCard = (user: any) => {
    return (
      <>
        <Grid
          sx={{
            width: "100%",
            maxWidth: 360,
            backgroundColor: "#fff",
            color: "#4A4A4A",
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
          }}
        >
          <Grid sx={{ mt: 1, mx: 2 }}>
            <Grid container alignItems="center">
              <Grid item sm={2} xs={2} md={2} lg={2} xl={2}>
                <Badge
                  color={"primary"}
                  overlap="circular"
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#44b700",
                      color: "#44b700",
                      right: 0,
                      bottom: 2,
                      // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                      "&::after": {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        animation: "ripple 1.2s infinite ease-in-out",
                        border: "1px solid currentColor",
                        content: '""',
                      },
                    },
                    "@keyframes ripple": {
                      "0%": {
                        transform: "scale(.8)",
                        opacity: 1,
                      },
                      "100%": {
                        transform: "scale(2.4)",
                        opacity: 0,
                      },
                    },
                  }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <IconButton
                    sx={{ padding: "0px!important" }}
                    onClick={() => {
                      return setSelectedUsers(
                        s_users.filter((s_user) => {
                          return s_user._id !== user._id;
                        })
                      );
                    }}
                  >
                    <Avatar
                      // src={"/media/users/100_5.jpg"}
                      style={{
                        backgroundColor: user?.role?.color ?? "#0D99FF",
                      }}
                      alt={user.firstname}
                      sx={{
                        width: 36,
                        height: 36,
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      variant="square"
                    >
                      {user.firstname.charAt(0)}
                      {user.lastname.charAt(0)}
                    </Avatar>
                  </IconButton>
                </Badge>
              </Grid>
              <Grid item sm={10} xs={10} md={10} lg={10} xl={10}>
                <Grid
                  sx={{
                    height: 36,
                    marginLeft: 2,
                  }}
                >
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 13, fontWeight: 400 }}
                  >
                    {user.firstname} {user.lastname}
                  </Typography>
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 12, fontWeight: 400, color: "#9FA5BB" }}
                  >
                    {user.role}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ m: 2 }}>
            <Grid container spacing={1}>
              <Grid item sm={5} xs={5} md={5} lg={5} xl={5}>
                <IconButton
                  onClick={() => {}}
                  className={classes.iconButtonMessage}
                  sx={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    backgroundColor: "#556EE6",
                  }}
                >
                  <svg
                    className={"mr-1"}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4982 2.28802L4.21497 12.5685C4.43728 12.6769 4.68112 12.7338 4.92842 12.7351H6.68957C6.8369 12.7347 6.97825 12.7933 7.08212 12.8978L8.03655 13.8516C8.55413 14.3728 9.2581 14.6661 9.99258 14.6667C10.2958 14.6664 10.597 14.6167 10.8843 14.5196C11.8746 14.1949 12.5948 13.3364 12.7426 12.3048L14.6187 3.39348C14.7083 3.02217 14.6657 2.63128 14.4982 2.28802Z"
                      fill="white"
                    />
                    <path
                      d="M12.6216 1.37634L3.73191 3.24912C2.21298 3.4578 1.1508 4.85831 1.35948 6.37724C1.44191 6.97709 1.71817 7.53358 2.1462 7.96184L3.10007 8.91572C3.20434 9.01995 3.26287 9.16137 3.26276 9.30881V11.07C3.26404 11.3173 3.32098 11.5611 3.42933 11.7834L13.7109 1.50018C13.373 1.33413 12.9881 1.29038 12.6216 1.37634Z"
                      fill="white"
                    />
                  </svg>
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 12, fontWeight: 400, color: "#fff" }}
                  >
                    Message
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item sm={5} xs={5} md={5} lg={5} xl={5}>
                <IconButton
                  onClick={(e) => {
                    history.push(`/users/${user._id}`);

                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className={classes.iconButtonProfile}
                  sx={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    backgroundColor: "#E1E6FB",
                  }}
                >
                  <svg
                    className={"mr-1"}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.2381 8.00001C10.044 8.00001 11.5079 6.50763 11.5079 4.66668C11.5079 2.82573 10.044 1.33334 8.2381 1.33334C6.43222 1.33334 4.96826 2.82573 4.96826 4.66668C4.96826 6.50763 6.43222 8.00001 8.2381 8.00001Z"
                      fill="#2A3042"
                    />
                    <path
                      d="M8.23807 9.11118C5.53049 9.11425 3.33633 11.351 3.33331 14.1112C3.33331 14.418 3.5773 14.6667 3.87828 14.6667H12.5978C12.8988 14.6667 13.1428 14.418 13.1428 14.1112C13.1398 11.351 10.9456 9.11422 8.23807 9.11118Z"
                      fill="#2A3042"
                    />
                  </svg>
                  <Typography
                    variant={"inherit"}
                    sx={{ fontSize: 12, fontWeight: 400, color: "#2A3042" }}
                  >
                    Go to profile
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item sm={2} xs={2} md={2} lg={2} xl={2}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className={classes.iconButtonProfile}
                  sx={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    backgroundColor: "#E1E6FB",
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleRoleSelection = (value: string, rowIndex: number): void => {
    const role = roles.find((r) => r._id.toString() === value);

    let _rows = rows.map((r) => {
      if (r.rowIndex !== rowIndex) return r;
      else {
        return { rowIndex: rowIndex, user: null, role: role, title: null };
      }
    });

    if (_rows) setRows(_rows);

    if (!role) return;

    fetchUsers(role._id, rowIndex);
  };

  const handleUserSelection = (value: string, rowIndex: number): void => {
    const user = users
      .find((u) => u.rowIndex === rowIndex)
      ?.options.find((o) => o._id === value)?.data;

    let _rows = rows.map((r) => {
      if (r.rowIndex !== rowIndex) return r;
      else return { ...r, user: user, title: null };
    });

    if (_rows) setRows(_rows);
  };

  const handleTitleChange = (value: string, rowIndex: number): void => {
    let _rows = rows.map((r) => {
      if (r.rowIndex !== rowIndex) return r;
      else return { ...r, title: value };
    });

    if (_rows) setRows(_rows);
  };

  const fetchUsers = (
    _id: string,
    rowIndex: number,
    limit: number | undefined = 10
  ) => {
    let option: IOption<IUser> | undefined = users.find(
      (u) => u.rowIndex === rowIndex
    );

    userService
      .getAll([
        {
          name: "role",
          value: _id,
        },
        {
          name: "per_page",
          value: limit,
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const _users = data.data;
        const mappedUsers = users.map((u) => {
          if (!u) {
            return {
              rowIndex: rowIndex,
              options: [],
              limit: limit,
            };
          }
          if (u.rowIndex === rowIndex) {
            return {
              rowIndex: rowIndex,
              options: _users.map((u) => ({
                _id: u._id,
                title: u.firstname,
                data: u,
              })),
              limit: limit,
              total: data.total,
            };
          } else return u;
        });
        setUsers(mappedUsers);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const changeOptionsLimit = (index: number) => {
    let { role } = rows.find((u) => u.rowIndex === index) ?? {};
    let userIOption = users.find((u) => u.rowIndex === index);

    if (
      role &&
      userIOption &&
      userIOption.total &&
      userIOption.total > userIOption.limit
    ) {
      fetchUsers(role._id, index, userIOption.limit + 10);
    }
  };

  const saveUsers = (selectedUsers: Array<SelectedUsers>) => {
    props.getUsers && props.getUsers(selectedUsers);
    setSelectedUsers(selectedUsers);
  };

  return (
    <>
      <SelectUsers
        Title={
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage id={"CASE.USERS"} defaultMessage={"CASE.USERS"} />
          </Typography>
        }
        open={Boolean(selectUsersDialog)}
        handleClose={() => {
          setSelectUsersDialog(null);
        }}
        handleSave={() => {}}
        onUserChange={saveUsers}
        users={s_users}
      />
      <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
        <Box>
          <Typography variant={"body1"} sx={{ mb: 0.5 }}>
            <FormattedMessage
              id={"LABEL.SELECTUSER"}
              defaultMessage={"LABEL.SELECTUSER"}
            />
          </Typography>
          <Stack direction="row" spacing={1}>
            {s_users.map((user) => {
              return (
                <Tooltip
                  classes={{ tooltip: classes.tooltip, popper: classes.popper }}
                  placement={"bottom-start"}
                  disableFocusListener
                  title={
                    <Grid
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        backgroundColor: "#fff",
                        color: "#4A4A4A",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 2,
                      }}
                    >
                      <Grid sx={{ mt: 1, mx: 2 }}>
                        <Grid container alignItems="center">
                          <Grid item sm={2} xs={2} md={2} lg={2} xl={2}>
                            <Avatar
                              style={{
                                backgroundColor: user?.role?.color,
                              }}
                              src={user?.file?.base64 ?? ""}
                              alt={""}
                              sx={{
                                width: 36,
                                height: 36,
                                fontSize: 80,
                                borderRadius: "8px",
                              }}
                              variant="square"
                            />
                          </Grid>
                          <Grid item sm={10} xs={10} md={10} lg={10} xl={10}>
                            <Grid
                              sx={{
                                height: 36,
                                marginLeft: 2,
                              }}
                            >
                              <Typography
                                variant={"inherit"}
                                sx={{ fontSize: 13, fontWeight: 400 }}
                              >
                                {user?.firstname + " " + user?.lastname}
                              </Typography>
                              <Typography
                                variant={"inherit"}
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  color: "#9FA5BB",
                                }}
                              >
                                {user?.role?.title[intl.locale]}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid sx={{ m: 2 }}>
                        <Grid container spacing={1}>
                          <Grid item sm={5} xs={5} md={5} lg={5} xl={5}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                              className={classes.iconButtonMessage}
                              sx={{
                                width: "100%",
                                height: 32,
                                borderRadius: 2,
                                backgroundColor: "#556EE6",
                              }}
                            >
                              <svg
                                className={"mr-1"}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.4982 2.28802L4.21497 12.5685C4.43728 12.6769 4.68112 12.7338 4.92842 12.7351H6.68957C6.8369 12.7347 6.97825 12.7933 7.08212 12.8978L8.03655 13.8516C8.55413 14.3728 9.2581 14.6661 9.99258 14.6667C10.2958 14.6664 10.597 14.6167 10.8843 14.5196C11.8746 14.1949 12.5948 13.3364 12.7426 12.3048L14.6187 3.39348C14.7083 3.02217 14.6657 2.63128 14.4982 2.28802Z"
                                  fill="white"
                                />
                                <path
                                  d="M12.6216 1.37634L3.73191 3.24912C2.21298 3.4578 1.1508 4.85831 1.35948 6.37724C1.44191 6.97709 1.71817 7.53358 2.1462 7.96184L3.10007 8.91572C3.20434 9.01995 3.26287 9.16137 3.26276 9.30881V11.07C3.26404 11.3173 3.32098 11.5611 3.42933 11.7834L13.7109 1.50018C13.373 1.33413 12.9881 1.29038 12.6216 1.37634Z"
                                  fill="white"
                                />
                              </svg>
                              <Typography
                                variant={"inherit"}
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  color: "#fff",
                                }}
                              >
                                Message
                              </Typography>
                            </IconButton>
                          </Grid>
                          <Grid item sm={5} xs={5} md={5} lg={5} xl={5}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                history.push(`/users/${user?._id}`);
                              }}
                              className={classes.iconButtonProfile}
                              sx={{
                                width: "100%",
                                height: 32,
                                borderRadius: 2,
                                backgroundColor: "#E1E6FB",
                              }}
                            >
                              <svg
                                className={"mr-1"}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.2381 8.00001C10.044 8.00001 11.5079 6.50763 11.5079 4.66668C11.5079 2.82573 10.044 1.33334 8.2381 1.33334C6.43222 1.33334 4.96826 2.82573 4.96826 4.66668C4.96826 6.50763 6.43222 8.00001 8.2381 8.00001Z"
                                  fill="#2A3042"
                                />
                                <path
                                  d="M8.23807 9.11118C5.53049 9.11425 3.33633 11.351 3.33331 14.1112C3.33331 14.418 3.5773 14.6667 3.87828 14.6667H12.5978C12.8988 14.6667 13.1428 14.418 13.1428 14.1112C13.1398 11.351 10.9456 9.11422 8.23807 9.11118Z"
                                  fill="#2A3042"
                                />
                              </svg>
                              <Typography
                                variant={"inherit"}
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  color: "#2A3042",
                                }}
                              >
                                Go to profile
                              </Typography>
                            </IconButton>
                          </Grid>
                          <Grid item sm={2} xs={2} md={2} lg={2} xl={2}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                              className={classes.iconButtonProfile}
                              sx={{
                                width: "100%",
                                height: 32,
                                borderRadius: 2,
                                backgroundColor: "#E1E6FB",
                              }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ marginRight: "5px", position: "relative" }}
                  >
                    <Avatar
                      style={{
                        backgroundColor: user?.role?.color ?? "#0D99FF",
                        border: "2px solid " + user?.role?.color ?? "#0D99FF",
                      }}
                      alt={user?.firstname + " " + user.lastname}
                      sx={{
                        backgroundColor: "#0D99FF",
                        lineHeight: "0!important",
                        width: 44,
                        height: 44,
                      }}
                      src={user?.file?.base64}
                    >
                      {user?.firstname.charAt(0) + user.lastname.charAt(0)}
                    </Avatar>
                    <IconButton
                      onClick={() => {
                        setSelectedUsers(
                          s_users.filter((s_user) => s_user._id !== user._id)
                        );
                      }}
                      sx={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        padding: "5px",
                        backgroundColor: "#EC3033",
                        "&:hover": { backgroundColor: "#EC3033" },
                      }}
                    >
                      <CloseOutlinedIcon
                        sx={{ color: "#fff", width: 10, height: 10 }}
                      />
                    </IconButton>
                  </Stack>
                </Tooltip>
              );
            })}
            <Button
              onClick={() => {
                setSelectUsersDialog(true);
              }}
              sx={{
                minWidth: "1px!important",
                maxWidth: "44px!important",
                height: 44,
                borderRadius: "100%",
                mt: 0.5,
                p: "14px!important",
                backgroundColor: "#0D99FF",
              }}
              variant="contained"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.75 10.75H13.25V3.25C13.25 2.55965 12.6904 2 12 2C11.3096 2 10.75 2.55965 10.75 3.25V10.75H3.25C2.55965 10.75 2 11.3096 2 12C2 12.6904 2.55965 13.25 3.25 13.25H10.75V20.75C10.75 21.4404 11.3096 22 12 22C12.6904 22 13.25 21.4404 13.25 20.75V13.25H20.75C21.4404 13.25 22 12.6904 22 12C22 11.3096 21.4404 10.75 20.75 10.75Z"
                  fill="white"
                />
              </svg>
            </Button>
          </Stack>
        </Box>
      </Grid>
    </>
  );
};

export default memo(Users);
