import React, { useRef } from "react";
import { Box, Grid } from "@mui/material";
import Controls from "../../../../widgets/uncontrolled";
import { Space, DatePicker } from "antd";
import { makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const useStyles = makeStyles(() => ({
  calendar: {
    zIndex: 99999,
  },
  dateRangeInput: {
    width: "100%",
    borderRadius: 8,
    border: "none!important",
    "& .ant-picker-focused": {
      boxShadow: "none!important",
    },
    "& .ant-picker": {
      border: "1px solid #E6E6E6!important",
      height: 40,
      backgroundColor: "#fff",
      borderRadius: 8,
      width: "100%",
      color: "#000000!important",
      "& .ant-picker-input input::placeholder": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "11px!important",
        },
      },
      "& .MuiInputLabel-root": {
        top: "-5px",
      },
      height: 40,
      backgroundColor: "#fff",
      border: "1px solid #E6E6E6",
      borderRadius: 8,
      m: () => ({}),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
    },
  },
}));

const TimeTrackFilter = (props: { show: boolean }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { RangePicker } = DatePicker;
  const intl = useIntl();
  const { time_track_types } = useSelector(
    (state: RootState) => state.settingReducer
  );

  return (
    <Box
      sx={{
        overflow: "hidden",
        transition: ".2s all",
        height: props.show ? contentRef?.current?.scrollHeight + "px" : 0,
      }}
      ref={contentRef}
    >
      <Grid container spacing={1} sx={{ marginBottom: 1 }}>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Controls.Select
            labelSx={{ top: "-5px" }}
            sx={{
              height: 40,
              backgroundColor: "#fff",
              border: "1px solid #E6E6E6",
              borderRadius: 2,
              m: () => ({}),
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset",
              },
            }}
            name={"title"}
            options={time_track_types?.map((type) => {
              return {
                id: type._id,
                title: type.title[intl.locale],
              };
            })}
            label={intl.formatMessage({ id: "STANDARD.TYPE" })}
            onChange={(event) => {
              if (!event.target.value) return;
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Box className={classes.input}>
            <Controls.Input
              name={"title"}
              label={intl.formatMessage({ id: "CASE.TIMETRACK.ENDLOCATION" })}
              onChange={(event) => {
                if (!event.target.value) return;
              }}
            />
          </Box>
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Box className={classes.input}>
            <Controls.Input
              name={"title"}
              label={intl.formatMessage({
                id: "CASE.TIMETRACK.STARTLOCATION",
              })}
              onChange={(event) => {
                if (!event.target.value) return;
              }}
            />
          </Box>
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
          <Space
            direction="vertical"
            size={12}
            className={classes.dateRangeInput}
          >
            <RangePicker dropdownClassName={classes.calendar} />
          </Space>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimeTrackFilter;
