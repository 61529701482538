import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import { connect } from "react-redux";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridRowId } from "@mui/x-data-grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { offerService } from "app/services";
import moment from "moment";
import { useTable } from "app/hooks/subresource/useTable";
import { GridTable } from "app/partials/layout/GridTable";
import { useParams } from "react-router";
import OfferNoteStore from "./store";
import ShowOfferNote from "./show";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import {
  hasPermission,
  PermissionsGate,
} from "../../../permission/PermissionsGate";
import { Ii18nState } from "../../../store/ducks/i18n.duck";
import { ISettingsState } from "../../../store/ducks/setting.duck";
import { usePermissions } from "../../../hooks/useRole";
import OfferNoteFilter from "./filter";
import EditIcon from "../../../partials/icons/EditIcon";

type Props = {
  lang: string;
  settings_loading: boolean;
};

const OfferNotes = (props: Props) => {
  const intl = useIntl();
  const { offerId } = useParams<{ offerId: string }>();
  const permissions = usePermissions();
  const { data, xhrLoading, refresh, remove, updateQuery, setQuery, queries } =
    useTable<any>({
      fetch: offerService.getNotes,
      delete: offerService.deleteNote,
      param: offerId,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      field: "title",
      valueGetter: (params) => {
        return params.row?.title;
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      field: "date",
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => show(params.row?._id)}
              data-cy-class={"note_show"}
              data-cy-offer-id={offerId}
              data-cy-note-id={params.row?._id}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleClick(params.row?._id)}
              data-cy-class={"note_delete"}
              data-cy-offer-id={offerId}
              data-cy-note-id={params.row?._id}
            >
              <DeleteIcon sx={{ color: "error.main" }} />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [noteId, setNoteId] = useState<string | null>(null);

  const show = (_id: string) => {
    setNoteId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      <PermissionsGate section={"cases"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete offer note?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(offerId, confirmId);
            }}
          >
            Are you sure you want to delete this offer note?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      {noteId ? (
        <ShowOfferNote
          offerId={offerId}
          _id={noteId}
          open={Boolean(noteId)}
          setDialogue={() => setNoteId(null)}
          onDone={refresh}
        />
      ) : (
        <></>
      )}
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
        search={(queries) => updateQuery(queries)}
        filterToolbar={{
          actionPanel:
            permissions && hasPermission(permissions, "notes", "create")
              ? (props: { open: boolean; close: () => void }) => {
                  return (
                    <OfferNoteStore
                      _id={offerId}
                      open={props.open}
                      setDialogue={props.close}
                      onDone={refresh}
                    />
                  );
                }
              : undefined,
          filters: (props: { show: boolean }) => {
            return (
              <OfferNoteFilter
                show={props.show}
                updateQuery={updateQuery}
                queries={queries}
                setQuery={setQuery}
              />
            );
          },
        }}
      />
    </>
  );
};
const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: ISettingsState;
}) => {
  return {
    lang: i18n.lang,
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferNotes);
